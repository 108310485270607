import React from "react";
import EventPageTitleWidget from "../../../components/eventpage/EventPageTitleWidget";
import EventPageAboutEventWidget from "../../../components/eventpage/EventPageAboutEventWidget";
import JobPageAboutCompanyWidget from "../../../components/jobpage/JobPageAboutCompanyWidget";
import { connect } from "react-redux";

function mapStateToProps(state) {
    return {
        recruiterCompany: state.userReducer.recruiterCompany,
        user: state.userReducer.user,
        authUser: state.userReducer.authUser
    }
}

function CreateEventPreviewStep({ listingState, user, authUser, recruiterCompany }) {
    const listing = {
        ...listingState,
        company_id: recruiterCompany.id,
        company_name: recruiterCompany.name,
        company: recruiterCompany
    }
    return (
        <div>
            <EventPageTitleWidget
                listing={listing}
                user={user}
                authUser={authUser}
            />
            <EventPageAboutEventWidget listing={listing} loading={false}/>
            <JobPageAboutCompanyWidget authUser={authUser} listing={listing} user={user} hideFollowButton setUser={() => {}}/>
        </div>
    );
}

export default connect(mapStateToProps)(CreateEventPreviewStep);

