import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-archive"
            {...props}
        >
            <path d="M21 8L21 21 3 21 3 8" />
            <path d="M1 3H23V8H1z" />
            <path d="M10 12L14 12" />
        </svg>
    )
}

export default SvgComponent
