import axios from '../axios';

export const getClubStatistics = async (user, club_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {club_id}
        };
        const res = await axios.get("/recruiter/stats/club", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}