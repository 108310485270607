import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="30px"
            height="30px"
            {...props}
            fill={'currentColor'}
        >
            <path d="M8.006 0A3.969 3.969 0 004.12 2.996c-.365 1.491.11 2.873 1.041 3.805C5.497 7.136 6 8 6 9h4c0-1 .516-1.875.854-2.217A3.943 3.943 0 0012 4a4 4 0 00-3.994-4zm6.275 2.004c-.21-.005-.417.008-.625.014A5.95 5.95 0 0114 4a5.915 5.915 0 01-1.709 4.174C12.216 8.26 12 8.633 12 9c0 .179-.003.9-.012 1.098-.095 1.93-1.495 3.548-3.332 3.85A4.006 4.006 0 014 10V8.999a1.3 1.3 0 00-.076-.412 10.537 10.537 0 00-.778 2.596c-.642 4.008.885 7.689 3.588 10.068.83.73 1.266 1.81 1.266 2.916V28h10v-1.559c0-.86.55-1.624 1.367-1.896l3.266-1.088A2.003 2.003 0 0024 21.559v-3.815l2.268-.781A1 1 0 0027 16a1 1 0 00-.227-.63l-.027-.034a1 1 0 00-.03-.033l-2.757-3.305c-.4-5.513-4.097-9.854-9.678-9.994zM6 10a2.002 2.002 0 002.336 1.973c.948-.155 1.607-1.019 1.654-1.973H6z" />
        </svg>
    )
}

export default SvgComponent
