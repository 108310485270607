import React, {Component} from "react";
import {connect} from "react-redux";
import {getAllQuestions} from "../../api/student/questions";
import ResponsesCategoryGroup from "./ResponsesCategoryGroup";
import ResourcesZeroState from "./ResourcesZeroState";
import {question_categories_array} from "../../utils/constants";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
    };
}


class ResourcesQuestionBankNew extends Component {
    state = {
        grid: true,
        submissions: [],
        category: "",
        loading: true,
        activeTab: 'All Submissions',
        categorizedAnswers: [],
        keyedAnswers: {},
        categorizedQuestions: {},
        categoryKeyedAnswers: [],
        selectedTopic: this.props.selectedTopic || 'Private Equity / Buyside Recruiting'
    }

    componentDidMount = async () => {
        this.categorizeAnswers();
        const res = await getAllQuestions(this.props.authUser);
        this.setState({categorizedQuestions: res.questions})
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.answers !== this.props.answers) {
            this.categorizeAnswers();
        }
    }

    categorizeAnswers = () => {
        const answers = this.props.answers
        const categories = question_categories_array

        // Map each category to its answers
        let categorizedAnswers = categories.map(category => ({
            category,
            answers: answers.filter(answer => answer.question.category === category)
        }));

        // Sort the mapped categories by the length of their answers array, from longest to shortest
        categorizedAnswers.sort((a, b) => b.answers?.length - a.answers?.length);

        // The dictionary of categorized answers
        const categoryKeyedAnswers = categories.reduce((acc, category) => {
            acc[category] = answers.filter(answer => answer.question.category === category);
            return acc;
        }, {});

        const keyedAnswers = {}
        for (const answer of this.props.answers) {
            if (!(answer.question_id in keyedAnswers)) keyedAnswers[answer.question_id] = 0
            if (answer.score > keyedAnswers[answer.question_id]) keyedAnswers[answer.question_id] = answer.score
        }

        this.setState({keyedAnswers, categorizedAnswers, categoryKeyedAnswers});
    };

    selectTopic = (selectedTopic) => {
        if (selectedTopic === this.state.selectedTopic) {
            this.setState({selectedTopic: ''})
        } else {
            this.setState({selectedTopic});
        }
    };

    changeTab = (tab) => {
        this.setState({activeTab: tab});
    };

    render() {
        return (
            <div className='flex flex-col flex-1 overflow-y-scroll w-full'>
                <div className='flex flex-col gap-1 mt-5 pb-5'>
                    {Object.keys(this.state.categorizedQuestions).map(category =>
                        <ResponsesCategoryGroup
                            category={category}
                            keyedAnswers={this.state.keyedAnswers}
                            answers={this.state.categoryKeyedAnswers[category]}
                            questions={this.state.categorizedQuestions[category]}
                            changeTab={this.props.changeTab}
                            selectTopic={this.selectTopic}
                            selectedTopic={this.state.selectedTopic}
                        />
                    )}
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(ResourcesQuestionBankNew);
