import React, {PureComponent} from "react";
import SvgClose from "../icons/SvgClose";

class TagComponent extends PureComponent {
    state = {
        hovering: false
    }
    switchType = () => {
        switch (this.props.type) {
            case 'primary':
                return 'bg-primary/20 text-primary'
            case 'job-type':
                return 'bg-primary/10 text-primary'
            case 'random':
                return 'bg-orange-100 text-orange-500'
            case 'rank':
                return 'bg-emerald-100 text-emerald-600'
            default:
                return 'bg-slate-200 text-slate-600'
        }
    }

    render() {
        if (this.props.label) {
            return (
                <div
                    id={this.props.label}
                    onMouseEnter={() => this.props.removable && this.setState({hovering: true})}
                    onMouseLeave={() => this.props.removable && this.setState({hovering: false})}
                    className={`${this.switchType()} ${this.props.sm ? 'body4-bold' : 'body3-bold'} px-2 rounded-md flex flex-row items center capitalize`}>
                    {this.props.label}
                    {this.state.hovering &&
                        <div className='cursor-pointer' onClick={() => this.props.deleteTag(this.props.label)}>
                            <SvgClose className={'w-4 h-4 ml-1'}/>
                        </div>
                    }
                </div>
            )
        }
    }
}

export default TagComponent
