import * as React from "react";

const SvgChevronDown = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.233 7.742L10 10.975 6.767 7.742a.83.83 0 10-1.175 1.175l3.825 3.825a.83.83 0 001.175 0l3.825-3.825a.83.83 0 000-1.175.848.848 0 00-1.184 0z"
      fill={"currentColor"}
    />
  </svg>
);

export default SvgChevronDown;
