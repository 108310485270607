import React, {Component} from "react";
import {rankColor, truncateString} from "../utils/strings";
import Tooltip from "../components/atoms/modals/Tooltip";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {DateTime} from 'luxon';
import SchoolLogo from "./atoms/SchoolLogo";
import ClubLogo from "./atoms/ClubLogo";
import ProfilePicture from "./atoms/ProfilePicture";
import RecruiterSideStatusDisplay from "./atoms/RecruiterSideStatusDisplay";

function mapDispatchToProps(dispatch) {
    return {
        toggleStudentPeek: () => {
            dispatch({
                type: "TOGGLE_STUDENT_PEEK",
            })
        },
        setStudentPeekStudent: (user) => {
            dispatch({
                type: "SET_STUDENT_PEEK_STUDENT",
                user,
            })
        },
    }
}

class RecruiterEventAttendeeTableItem extends Component {
    state = {
        profileError: false
    }

    openStudentPeek = () => {
        this.props.toggleStudentPeek();
        this.props.setStudentPeekStudent(this.props.item);
    }

    openStudentPage = (profileId) => {
        this.props.history.push(`/profile/${profileId}`);
    }

    render() {
        const columns = this.props.columns
        const user = this.props.item || {};
        const clubs = user.clubs || [];
        const education = user.education || [];
        const companyStudent = user.company_student || {};
        return (
            <div className='flex flex-row items-center text-slate-500 cursor-pointer' onClick={() => this.openStudentPeek()}>
                <div
                    className={'flex flex-row items-center gap-2'}
                    style={{flex: columns[0].flex}}>
                    <ProfilePicture
                        image={user.profile_pic_url}
                        userID={user.id}
                        size={40}
                        className={'border border-slate-200'}
                    />
                    <div className={'flex flex-col gap-0.5 flex-1 mr-3'}>
                        <p className=' text-slate-800 font-semibold text-sm'>
                            {user.first_name} {user.last_name}
                        </p>
                        {user.tag &&
                            <div
                                className={`text-xs text-${user.tag.color}-500 bg-${user.tag.color}-100 px-2 rounded-full`}>
                                {user.tag.label}
                            </div>
                        }
                    </div>
                </div>
                <div style={{flex: columns[1].flex}}>
                    <Tooltip
                        toggleComponent={
                            <SchoolLogo image={education[0]?.logo_url} className={'border border-slate-200'} college={user.college}/>
                        }
                        label={!!education[0] ? truncateString(education[0].school, 30) : "College"}
                    />
                </div>
                <div style={{flex: columns[2].flex}} className='flex flex-row items-center'>
                    <div className='flex flex-row items-center relative'>
                        {clubs.length === 0 ?
                            <div
                                className='w-10 w-10 text-xs text-slate-300 text-center flex flex-col items-center justify-center aspect-square border-slate-100 bg-slate-50 border rounded-xl'
                            >
                                <p>
                                    N/A
                                </p>
                            </div>
                            :
                            clubs.slice(0, 2).map((club, index) =>
                                <Tooltip
                                    toggleComponent={
                                        // <img src={club.logo_url}
                                        //      style={{marginLeft: index !== 0 && -16}}
                                        //      className='w-10 h-10 rounded-full border border-slate-200 cursor-pointer bg-white object-contain'/>
                                        <ClubLogo clubId={club}
                                                  className={`${index !== 0 && '-ml-5'} border border-slate-200`}/>

                                    }
                                    label={club?.name}
                                />
                            )}
                        {clubs.length > 2 &&
                            <div
                                className='absolute bg-primary bottom-[-4px] right-[-4px] rounded-full px-1 cursor-pointer'>
                                <p className='text-xs font-bold text-white'>
                                    +{clubs.length - 2}
                                </p>
                            </div>
                        }
                    </div>
                </div>
                <div style={{flex: columns[3].flex}}>
                    <p className='text-xs'>
                        {user.major.join(', ')}
                    </p>
                </div>
                <div style={{flex: columns[4].flex}}>
                    <p className='text-xs'>
                        {user.gpa}
                    </p>
                </div>
                <div style={{flex: columns[5].flex}}>
                    <p className='text-xs'>
                        {user.grade}
                    </p>
                </div>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(withRouter(RecruiterEventAttendeeTableItem));
