import React, {Component} from "react";
import {Button} from "../atoms/Button";
import SvgQuiz from "../icons/SvgQuiz";


class ResourcesZeroState extends Component {
    render() {
        return (
            <div
                className='w-2/3 mx-auto mt-10 hidden only:flex flex-col items-center justify-center min-h-[120px] border bg-slate-50 border-slate-200 rounded-lg p-6 mb-12'>
                <>
                    <div className='mx-auto text-center text-balance mb-5'>
                        <div className='w-3/4 text-center mx-auto'>
                            <p className='text-lg font-semibold'>
                                You haven't submit any responses for feedback yet.
                            </p>
                            <p className='text-slate-500'>
                                Check out the full Question Bank to get
                                started.
                                As
                                you answer questions, your responses will populate here.
                            </p>
                        </div>
                    </div>
                    <Button onClick={() => this.props.changeTab("Question Bank")} icon={SvgQuiz}
                            iconPosition={'left'}>
                        Full Questions Bank
                    </Button>
                </>
            </div>
        )
    }
}

export default ResourcesZeroState
