import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {connect} from "react-redux";
import {Button} from "../../components/atoms/Button";
import WrappedTextInput from "../atoms/WrappedTextInput";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class PlainTextInputModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
        };
    }

    save = async () => {
        this.props.save(this.state.text); 
        this.setState({text: ""})
        this.props.closeModal();
    }

    render() {
        const disabled = !this.state.text
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={this.props.title}
                headerToggle
                size={'md'}
                toggle={this.props.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={this.props.closeModal} variant='secondary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={disabled}
                            onClick={this.save}>
                            Save
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div className={`flex flex-col gap-1.5`}>
                <WrappedTextInput
                    type="text"
                    placeholder={'...'}
                    className='input-light px-3 w-full'
                    value={this.state.text}
                    onChange={(e) => this.setState({text: e.target.value})}
                />
                </div>
            </ModalComponent>
        );
    }
}

export default connect(mapStateToProps)(PlainTextInputModal);
