import React, {PureComponent} from "react";
import NetworkStatusTrackerComponet from "../clubpage/NetworkStatusTrackerComponent";
import Tooltip from "../atoms/modals/Tooltip";
import SvgUnclaimed from "../icons/SvgUnclaimed";
import SvgClaimed from "../icons/SvgClaimed";
import {withRouter} from "react-router-dom";
import Spinner from "../Spinner";
import SvgLinkedIn from "../icons/SvgLinkedIn";
import SvgEdit from "../icons/SvgEdit";
import SvgMail from "../icons/SvgMail";
import {connect} from "react-redux";
import RecruiterFavoritedButtonComponent from "../../components-recruiter/atoms/RecruiterFavoritedButtonComponent";
import TagComponent from "../atoms/TagComponent";

function mapDispatchToProps(dispatch) {
    return {
        toggleStudentPeek: () => {
            dispatch({
                type: "TOGGLE_STUDENT_PEEK",
            })
        },
        setStudentPeekStudent: (user) => {
            dispatch({
                type: "SET_STUDENT_PEEK_STUDENT",
                user,
            })
        },
    }
}


class ClubPageMemberListItem extends PureComponent {
    state = {
        hovering: false
    }

    onMouseEnter = () => {
        this.setState({hovering: true})
    }

    onMouseLeave = () => {
        this.setState({hovering: false})
    }

    openStudentPeek = () => {
        this.props.toggleStudentPeek();
        this.props.setStudentPeekStudent(this.props.item);
    }

    openStudentPage = (profileId) => {
        this.props.history.push(`/profile/${profileId}`);
    }


    render() {
        const member = this.props.item || {};
        const profilePic = member.profile_pic_url ? member.profile_pic_url : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fblank-person.jpeg?alt=media&token=ac3b0019-71c8-4830-932e-de437faf5fc8";
        const leadership = this.props.leadership || {};
        const position = leadership[member.username];
        const club = this.props.club
        const pocEmails = club.points_of_contact.map(poc => poc.email);
        const isPointOfContact = pocEmails.includes(member.email)
        return (
            <div onClick={() => this.openStudentPeek()}
                 id={member.username}
                 className={`row-ac-jb py-4 px-2 rounded border-b border-b-neutral-100 last:border-none cursor-pointer hover:bg-slate-100`}
                 onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                <div className='flex flex-row items-center gap-1' style={{flex: .75}}>
                    <img className='bg-base-white-20 br-32 border border-neutral-200' alt={"profile pic"}
                         onError={() => this.setState({profileError: true})}
                         style={{width: 28, height: 28, marginRight: 10}}
                         src={!this.state.profileError ? profilePic : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fblank-person.jpeg?alt=media&token=ac3b0019-71c8-4830-932e-de437faf5fc8"}/>
                    <p className={`body1-bold base-black-70 ${!member.claimed && 'text-neutral-400'}`}>
                        {member.first_name} {member.last_name}
                    </p>
                    {member.linkedin &&
                        <a href={member.linkedin}
                           target={"_blank"}>

                            <SvgLinkedIn className={'base-black-30 h-base-black-70 pointer w-4 h-4'}/>
                        </a>
                    }
                    {this.props.recruiter ?
                        <>
                            {member.claimed ?
                                <div className={'flex flex-row gap-2 items-center'}>
                                    <Tooltip
                                        toggleComponent={
                                            <div className='flex flex-row items-center gap-1 body3 text-green-500'>
                                                <SvgClaimed className={'w-4 h-4'}/>
                                            </div>
                                        }
                                        label={'Profile is claimed'}
                                    />
                                </div>
                                :
                                <>
                                    <div className={'flex flex-row gap-1 items-center'}>
                                        <Tooltip
                                            toggleComponent={
                                                <div
                                                    className='flex flex-row items-center gap-1 body3 text-orange-400 cursor-pointer'>
                                                    <SvgUnclaimed className={'w-4 h-4'}/>
                                                </div>
                                            }
                                            label={`${member.first_name} has not claimed their account`}
                                        />
                                    </div>
                                </>
                            }
                        </>
                        : null}
                    {isPointOfContact &&
                        <TagComponent
                            label={'Point of contact'}
                            size={'sm'}
                            type={'primary'}
                        />
                    }
                </div>
                <div className='row-ac primary hidden xl:block' style={{flex: .5}}>
                    <p className={`body4 ${!member.claimed ? 'base-black-50' : 'base-black-100'} ${isPointOfContact && 'text-primary'}`}>
                        {member.email}
                    </p>
                </div>
                <div className='row-ac primary block xl:hidden' style={{flex: .2}}>
                    <Tooltip
                        toggleComponent={
                            <div className='flex flex-col items-center'>
                                <SvgMail className={'w-4 h-4'}/>
                            </div>
                        }
                        label={`${member.email}`}
                    />
                </div>
                <div style={{flex: .3}}>
                    <p className={`body3 ${!member.claimed ? 'base-black-50' : 'base-black-100'}`}>
                        {position || 'Member'}
                    </p>
                </div>
                <div style={{flex: .25}}>
                    <p className={`body3 ${!member.claimed ? 'base-black-50' : 'base-black-100'}`}>
                        {member.grade || '--'}
                    </p>
                </div>
                <div className='row-ac primary hidden lg:block' style={{flex: .3}}>
                    <p className={`body3 ${!member.claimed ? 'base-black-50' : 'base-black-100'}`}>
                        {member.major.join(', ') || '--'}
                    </p>
                </div>
                <div className={'flex flex-[0.5] min-w-[110px] flex-row items-center justify-end gap-3 h-3'}>
                    {this.props.recruiter && member.claimed ?
                        <div className='row-ac primary'>
                            <RecruiterFavoritedButtonComponent sm candidate={member}/>
                        </div>
                        : null
                    }
                </div>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(withRouter(ClubPageMemberListItem))
