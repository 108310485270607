import React, {Component} from "react";
import SvgEditFeather from "../../components/icons/SvgEditFeather";
import RecruiterCompanyEditImages from "../modals/RecruiterCompanyEditImages";
import zerostate from "../../img/zerostates/Life at Morgan Pictures.png";
import {connect} from "react-redux";
import SvgEyeOff from "../../components/icons/SvgEyeOff";
import SvgVisibility from "../../components/icons/SvgVisibility";
import {Button} from "../../components/atoms/Button";
import SvgRemove from "../../components/icons/SvgRemove";


function mapDispatchToProps(dispatch) {
    return {
        setImageViewerModalOpen: (images) => {
            dispatch({
                type: "SET_IMAGE_VIEWER_MODAL_OPEN",
                images
            })
        },
    }
}

class RecruiterCompanyPageLifeAtSection extends Component {
    state = {
        imagesModalOpen: false,
    }

    openEditImagesModal = () => {
        this.setState({imagesModalOpen: true})
    }

    closeEditImagesModal = () => {
        this.setState({imagesModalOpen: false})
    }

    openImageLightbox = (imageURLs, index) => {
        console.log('pressed')
        this.props.setImageViewerModalOpen(imageURLs, index);
    }

    render() {
        const company = this.props.company || {};
        const images = company.images || [];
        const hidden = this.props.hidden || [];

        return (
            <div
                className={`bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl ${hidden.includes("images") && 'opacity-50'}`}>
                <div className='flex flex-row items-center gap-2 mb-2'>
                    <p className='text-xl font-semibold text-slate-800'>
                        Life at {company.name}
                    </p>
                    {images.length > 0 &&
                        <button
                            onClick={this.openEditImagesModal}
                            className='text-slate-500 hover:text-primary cursor-pointer'>
                            <SvgEditFeather className={'w-4 h-4'}/>
                        </button>
                    }
                    <button
                        onClick={() => this.props.hide("images")}
                        className='text-slate-500 hover:text-primary cursor-pointer'>
                        {hidden.includes("images") ?
                            <SvgVisibility className={'w-4 h-4'}/>
                            :
                            <SvgEyeOff className={'w-4 h-4'}/>
                        }
                    </button>
                </div>
                <p className='text-slate-500 text-md mb-5'>
                    Step inside our world and see what makes us different
                </p>
                {images.length === 0 &&
                    <>
                        <div className='w-full relative cursor-pointer' onClick={this.openEditImagesModal}>
                            <img src={zerostate} className='w-full blur-sm mb-5 saturate-50 opacity-50'/>
                            <div
                                className='absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center'>
                                <Button>
                                    Add images
                                </Button>
                            </div>
                        </div>
                    </>
                }
                <div className='grid grid-cols-4 gap-3 mt-5'>
                    {images.map((image, index) =>
                        <div key={image} className={'relative flex flex-col items-start group'}>
                            <img
                                onClick={() => this.openImageLightbox(images, index)}
                                className='h-44 w-full rounded-lg bg-slate-50 object-contain  cursor-pointer border border-transparent hover:border-primary'
                                src={image}
                            />
                            <div onClick={() => this.props.removeItem("images", index)}
                                className={'p-2 cursor-pointer rounded-full bg-slate-50 hover:bg-red-400 hover:text-white text-red-400 absolute -top-3 right-2'}>
                                <SvgRemove className={'w-5 h-5'}/>
                            </div>
                        </div>
                    )}
                </div>
                <RecruiterCompanyEditImages
                    isOpen={this.state.imagesModalOpen}
                    closeModal={this.closeEditImagesModal}
                    company={company}
                />
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(RecruiterCompanyPageLifeAtSection);
