import React, {PureComponent} from "react";
import {connect} from "react-redux";
import SvgSearch from "../icons/SvgSearch";
import WrappedTextInput from "../atoms/WrappedTextInput";
import CollapseComponent from "../atoms/CollapseComponent";
import FadeInOnScroll from "../atoms/FadeInOnScroll";
import CompanyLogo from "../atoms/CompanyLogo";
import {Button} from "../atoms/Button";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

class SearchCompany extends PureComponent {
    state = {
        search: '',
        openCollapse: false,
    }

    handleSearchChange = (event) => {
        const text = event.target.value;
        this.setState({search: text, openCollapse: true});

        this.props.companyChanged(text);
    }

    render() {
        const searchResults = this.props.searchResults || [];
        return (
            <div className="col-plain w-full relative">
                <p className="body3-bold text-neutral-400 mb-1">
                    Company (*)
                </p>
                <WrappedTextInput
                    type="text"
                    placeholder={'Find a company...'}
                    className='pl-44 input-light px-3 body2 w-full'
                    value={this.state.search}
                    onChange={this.handleSearchChange}
                    icon={<SvgSearch width={24} height={24}
                                     className={'absolute text-slate-500'}
                                     style={{left: 12, top: 10}}/>}
                />
                <div className='flex flex-col top-16 left-0 right-0 z-[99] overflow-y-scroll'>
                    <CollapseComponent isOpen={this.state.search.length !== 0 && this.state.openCollapse}
                                       className='bg-white shadow-lg border border-neutral-200 rounded-lg'>
                        {searchResults.map((res) =>
                            <FadeInOnScroll triggerImmediately delay={10} key={res.id}>
                                <div
                                    onClick={() => {
                                        this.props.setCompany(res);
                                        this.setState({search: res.name, openCollapse: false})
                                    }}
                                    className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2'>
                                    <CompanyLogo id={res.id} className={'w-16 h-16'}/>
                                    <div className='flex flex-col'>
                                        <p className='body1-bold text-slate-800'>
                                            {res.name}
                                        </p>
                                    </div>
                                </div>
                            </FadeInOnScroll>
                        )}
                        <div
                            onClick={() => {
                                this.props.setCompany({name: this.state.search});
                                this.setState({search: this.state.search, openCollapse: false})
                            }}
                            className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2'>
                            <CompanyLogo id={""} className={'w-16 h-16'}/>
                            <div className='flex flex-col'>
                                <p className='body1-bold text-slate-800'>
                                    {this.state.search}
                                </p>
                            </div>
                        </div>
                    </CollapseComponent>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(SearchCompany)
