import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'} d="M200-120v-725h560v725L480-240 200-120zm60-91l220-93 220 93v-574H260v574zm0-574h440-440z" />
        </svg>
    )
}

export default SvgComponent
