import React, {PureComponent} from "react";
import {APP_ADMINS} from "../../utils/constants";
import NoteComponent from "../NoteComponent";
import SvgNoteAdd from "../icons/SvgNoteAdd";
import Dialog from "../atoms/modals/Dialog";
import {addNote, deleteNetwork, deleteNote, editNetwork} from "../../api/student/networks";
import {connect} from "react-redux";
import SvgRemove from "../icons/SvgRemove";
import EditContactModal from "../atoms/modals/EditContactModal";
import SvgEdit from "../icons/SvgEdit";
import {TOAST_OPTIONS} from "../../utils/constants";
import {toast} from "react-toastify";
import {wait} from "@testing-library/user-event/dist/utils";
import {SIDEBAR_CARD} from "../../utils/dummy";
import ContactDetails from "../ContactDetails";
import Checkbox from "../atoms/Checkbox";
import {Button} from "../atoms/Button";
import SidebarOverlayWrapper from "../atoms/SidebarOverlayWrapper";
import NotetakingWidget from "../../components-recruiter/atoms/NotetakingWidget";

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
    };
}


class NetworkingPeek extends PureComponent {
    state = {
        note: "",
        notes: [],
        editContactModalOpen: false
    }

    updateStatus = async () => {
        try {
            const newNetwork = {...this.props.network};
            const newStatus = newNetwork.status === "Contacted" ? "Not contacted" : "Contacted";
            newNetwork.status = newStatus;
            this.setState({status: newStatus});
            this.props.updateNetwork(newNetwork);
            await editNetwork(this.props.authUser, this.props.network.id, {status: newStatus});

            toast.promise(
                Promise.resolve('Status updated!'),
                {
                    pending: 'Updating status...',
                    success: 'Status updated!',
                    error: 'Failed to update status!',
                },
                TOAST_OPTIONS
            );
        } catch (error) {
            toast.error('Failed to update status!', TOAST_OPTIONS);
        }
    };

    updateNote = (e) => {
        this.setState({note: e.target.value})
    }

    saveNote = async (note) => {
        try {
            const savePromise = addNote(this.props.authUser, this.props.network.id, note);

            toast.promise(
                savePromise,
                {
                    pending: 'Adding note...',
                    success: 'Note added!',
                    error: 'Failed to add note!',
                },
                TOAST_OPTIONS
            );
            const saveResult = await savePromise;
            if (saveResult) {
                const newNetwork = {...this.props.network};
                newNetwork.notes.push({text: note, created_at: new Date()});
                this.props.updateNetwork(newNetwork);
            }
        } catch (error) {
            toast.error('Failed to add note!', TOAST_OPTIONS);
        }
    };

    deleteNote = async (index) => {
        try {
            const deletePromise = deleteNote(this.props.authUser, this.props.network.id, index);
            toast.promise(
                deletePromise,
                {
                    pending: 'Deleting note...',
                    success: 'Note deleted!',
                    error: 'Failed to delete note!',
                },
                TOAST_OPTIONS
            );

            const deleteResult = await deletePromise;
            if (deleteResult) {
                const newNetwork = {...this.props.network};
                newNetwork.notes.splice(index, 1);
                this.props.updateNetwork(newNetwork);
            }
        } catch (error) {
            console.error(error);
        }
    };

    componentDidMount = () => {
        this.setNotes();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.network !== this.props.network) {
            this.setNotes();
        }
        if (!prevProps.open && this.props.open) {
            wait(10).then(() =>
                this.setState({opened: true})
            )
        }

        if (prevProps.open && !this.props.open) {
            wait(10).then(() =>
                this.setState({opened: false})
            )
        }
    }

    setNotes = () => {
        const network = this.props.network || {};
        const notes = network.notes || [];
        this.setState({notes});
    }

    deleteNetwork = async () => {
        try {
            this.close();
            const networkPromise = deleteNetwork(this.props.authUser, this.props.network.id);
            toast.promise(
                networkPromise,
                {
                    pending: 'Deleting contact...',
                    success: 'Contact deleted.',
                    error: 'Failed to delete contact!',
                },
                TOAST_OPTIONS
            );
            const networkResult = await networkPromise;
            if (networkResult) {
                this.props.deleteNetwork(this.props.network.person_id);
            }
        } catch (error) {
            toast.error('Failed to remove network', TOAST_OPTIONS);
        }
    };

    close = () => {
        this.setState({opened: false})
        wait(150).then(() => {
            this.props.close()
        })
    }

    openEditContactModal = () => {
        this.setState({editContactModalOpen: true})
    }

    closeEditContactModal = () => {
        this.setState({editContactModalOpen: false})
    }

    render() {
        const network = this.props.network || {};
        const person = network.person || {};
        const notes = this.state.notes || [];
        const source =  person?.source === 'club' ? 'Club' : person?.source === 'recruitu.io' || person?.source === "apollo" ? "RecruitU" : "Personal";

        return (
            <SidebarOverlayWrapper label={'Contact Details'} onClose={this.close} open={this.props.open} width={'40vw'}>
                <div className='flex flex-col flex-1 py-3 overflow-y-scroll w-full gap-3'>
                    <ContactDetails
                        person={person}
                    />
                    <div className={`flex flex-col gap-2 ${SIDEBAR_CARD}`}>
                        <div className='flex flex-row items-center justify-between'>
                            <p className='text-lg font-bold'>
                                Status
                            </p>
                            <div className="">
                                <Checkbox
                                    checked={network.status === "Contacted"}
                                    onClick={this.updateStatus}
                                    label={'Networking call'}
                                />
                            </div>
                        </div>
                        {/* <HorizontalStepperList
                            updateStatus={this.updateStatus}
                            steps={[{title: "Not contacted",}, {title: "Contacted",}, {title: "Response received"}, {title: "Scheduled",}, {title: "Thank you sent",}]}
                            status={network.status}
                        /> */}
                    </div>
                    <NotetakingWidget
                        notes={notes}
                        onSaveNote={this.saveNote}
                        onDeleteNote={this.deleteNote}
                    />
                </div>
                <div className='flex flex-row gap-3'>
                    {(APP_ADMINS.includes(this.props.authUser?.uid) || source === "Personal") &&
                        <Button onClick={this.openEditContactModal} variant={'secondary'} icon={SvgEdit}
                                className='flex-1'>
                            Edit Details
                        </Button>
                    }
                    <Button onClick={this.deleteNetwork} variant={'secondary'} icon={SvgRemove} className='flex-1'>
                        Remove
                    </Button>
                </div>
                <EditContactModal
                    open={this.state.editContactModalOpen}
                    person={person}
                    updateNetwork={this.props.updateNetwork}
                    network={network}
                    close={this.closeEditContactModal}
                />
            </SidebarOverlayWrapper>
        )
    }
}

export default connect(mapStateToProps, null)(NetworkingPeek);
