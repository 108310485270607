import React, {useEffect, useState} from "react";
import OnboardingStepHeader from "./OnboardingStepHeader";
import OnboardingStepWrapper from "./OnboardingStepWrapper";
import FadeInOnScroll from "../atoms/FadeInOnScroll";
import OnboardingMatchResult from "./OnboardingMatchResult";
import {Button} from "../atoms/Button";
import SvgRestart from "../icons/SvgRestart";
import ConfettiComponent from "../atoms/ConfettiComponent";
import Spinner from "../Spinner";
import { discoverJobs } from "../../api/student/jobs";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
    authUser: state.userReducer.authUser,
})

function OnboardingMatchesStep({goNextStep, jobs, setJobs, grade, industries, companies, authUser}) {
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nextOffset, setNextOffset] = useState(null);

    const getRandomMatches = async (offset=0) => {
        let classYear = grade;
        if (!(["2025", "2026", "2027", "2028"].includes(grade))) classYear = null
        const {jobs, next_offset} = await discoverJobs(authUser, {query: "", limit: 3, company_id: JSON.stringify(companies), class_year: classYear, offset})
        setMatches(jobs);
        setNextOffset(next_offset);
        return jobs.length
    }

    const handleGenerateMore = async () => {
        setLoading(true);
        getRandomMatches(nextOffset);
        setLoading(false);
    };

    useEffect(() => {
        firstLoad();
    }, [])

    const firstLoad = async () => {
        const numMatches = await getRandomMatches();
        if (numMatches === 0) {
            let classYear = grade;
            if (!(["2025", "2026", "2027", "2028"].includes(grade))) classYear = null
            const shuffled = await discoverJobs(authUser, {query: "", limit: 3, class_year: classYear}).then(res => res.jobs);
            setMatches(shuffled);
        }
    }

    return (
        <OnboardingStepWrapper>
            <ConfettiComponent/>
            <OnboardingStepHeader
                title={'Your top matches are in!'}
                description={'See an opportunity you like? Save it to track your progress towards an offer'}
            />
            <div className='flex flex-col gap-1'>
                {loading ? (
                    <div className='text-center py-4 min-h-[360px] flex flex-col items-center justify-center'>
                        <Spinner/>
                        <p className='text-slate-500 text-sm mt-5'>Generating new matches...</p>
                    </div>
                ) : (
                    matches.map((item, index) => (
                        <FadeInOnScroll key={item.id} triggerImmediately delay={(index * 50) + 10}>
                            <OnboardingMatchResult key={item.id} item={item} jobs={jobs} setJobs={setJobs}/>
                        </FadeInOnScroll>
                    ))
                )}
                {matches.length > 0 ?
                
                <div className='flex flex-row items-center gap-1 justify-center text-sm'>
                    <p className='text-slate-500'>
                        Don't like any of these?
                    </p>
                    <div
                        className='flex flex-row items-center gap-1 cursor-pointer hover:saturate-200'
                        onClick={handleGenerateMore}
                    >
                        <p className='text-primary font-semibold'>
                            Generate more
                        </p>
                        <SvgRestart className={'text-primary w-5 h-5'}/>
                    </div>
                </div>
                :
                <div className='flex flex-row items-center gap-1 justify-center text-sm'>
                    <p className='text-slate-500'>
                        RecruitU does not currently have any more jobs for your exact preferences. 
                        Hit 'Finish' below to enter the platform and find similar jobs!
                    </p>
                </div>
                }
            </div>
            <Button size={'lg'} onClick={() => goNextStep()}>
                Finish
            </Button>
        </OnboardingStepWrapper>
    );
}

export default connect(mapStateToProps)(OnboardingMatchesStep);
