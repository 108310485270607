import React, {PureComponent} from "react";
import "react-pdf/dist/esm/Page/TextLayer.css";

import {wait} from "@testing-library/user-event/dist/utils";
import ClubPageLifeAtSection from "../../components/clubpage/ClubPageLifeAtSection";
import ClubPageStepsToApplyWidget from "../../components/clubpage/ClubPageStepsToApplyWidget";
import SvgSchool from "../../components/icons/SvgSchool";
import ClubPageSponsorsWidget from "../../components/clubpage/ClubPageSponsorsWidget";
import parse from "html-react-parser";
import Spinner from "../../components/Spinner";
import PointsOfContactWidget from "../../components/clubpage/PointsOfContactWidget";
import ClubLogo from "../atoms/ClubLogo";
import SidebarOverlayWrapper from "../../components/atoms/SidebarOverlayWrapper";
import {Button} from "../../components/atoms/Button";
import MessageStudentFlow from "../MessageStudentFlow";
import SelectClubListModal from "../modals/SelectClubListModal";

class RecruiterClubPeek extends PureComponent {
    state = {
        opened: false,
        state: "",
        status: 'To apply',
        favorite: false,
        isSelectListModalOpen: false,
        // statistics: {},
        // statisticsLoading: true,
    }

    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        if (!prevProps.open && this.props.open) {
            wait(10).then(() =>
                this.setState({opened: true})
            )
            // this.setState({statisticsLoading: true})
            // const statistics = await getClubStatistics(this.props.authUser, this.props.club.id);
            // this.setState({
            //     statistics: statistics,
            //     statisticsLoading: false
            // })
        }
    }

    close = () => {
        this.setState({opened: false})
        wait(150).then(() => {
            this.props.close()
        })
    }


    render() {
        const club = this.props.club || {};
        const hidden = club.hidden || [];
        const description = club.description || "";
        const pocs = club.points_of_contact || [];
        return (
            <SidebarOverlayWrapper
                label={'Club Details'}
                open={this.props.open}
                onClose={this.close}
                width={'40vw'}
            >
                <div className='flex flex-row items-center'>
                    <ClubLogo clubId={club.id} backupUrl={club.logo_url} size={72} className={'border border-slate-200'}/>
                    <div className='ml-3 flex flex-col gap-1'>
                        <p className='text-xl font-semibold blacks-1'>
                            {club.name}
                        </p>
                        <div className='flex flex-row items-center gap-x-3 gap-y-1 flex-wrap'>
                            <div className='flex flex-row items-center gap-1 text-gray-500'>
                                <SvgSchool className={'w-4 h-4'}/>
                                <p className='body2-bold m-0'>
                                    {club.college}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col flex-1 overflow-y-scroll'>
                    <div className='flex flex-row flex-w items-center gap-10 flex-wrap gap-y-3 mt-5'>
                        <div>
                            <p className='text-sm text-slate-500'>
                                Website
                            </p>
                            <p className='text-sm font-semibold text-primary'>
                                {club.website || "--"}
                            </p>
                        </div>
                        <div>
                            <p className='text-sm text-slate-500'>
                                Industry
                            </p>
                            <p className='text-sm font-semibold text-primary'>
                                {club.industry || "--"}
                            </p>
                        </div>
                        <div>
                            <p className='text-sm text-slate-500'>
                                Founded
                            </p>
                            <p className='text-sm font-semibold text-primary'>
                                {club.founded || "--"}
                            </p>
                        </div>
                        <div>
                            <p className='text-sm text-slate-500'>
                                Members
                            </p>
                            <p className='text-sm font-semibold text-primary'>
                                {club.num_members_range || '--'}
                            </p>
                        </div>
                        <div>
                            <p className='text-sm text-slate-500'>
                                Application only
                            </p>
                            <p className='text-sm font-semibold text-primary'>
                                {club.application_only ? "Yes" : "No"}
                            </p>
                        </div>
                    </div>
                    {!!club.mission ?
                        <>
                            <p className='text-sm mb-2 text-slate-500 mt-5'>
                                Club mission statement
                            </p>
                            <div className='flex flex-col mb-3'>
                                <p className='text-sm  mt-0 text-slate-700'>
                                    {parse(club.mission)}
                                </p>
                            </div>
                        </>
                        : null}
                    {!!description ?
                        <>
                            <p className='text-sm mb-2 text-slate-500 mt-5'>
                                About this club
                            </p>
                            <div className='flex flex-col mb-3'>
                                <p className='text-sm mt-0 text-slate-700'>
                                    {parse(description)}
                                </p>
                            </div>
                        </>
                        : null}
                    {/* {!hidden.includes("images") &&
                        <ClubPageLifeAtSection club={club} loading={false} getClubInfo={() => {}}
                                                isAdmin={false}/>}
                    {!hidden.includes("application_process") &&
                        <ClubPageStepsToApplyWidget club={club} loading={false} getClubInfo={() => {}}
                                                    adminIds={[]}/>}
                    {!hidden.includes("sponsors") &&
                        <ClubPageSponsorsWidget club={club} loading={false} getClubInfo={() => {}}
                                                adminIds={[]}/>} */}
                    {!hidden.includes("points_of_contact") &&
                        <PointsOfContactWidget club={club} loading={false} getClubInfo={() => {}}
                                                isAdmin={false} hideEmails/>}
                </div>
                <div className="flex flex-row gap-2 mt-2 items-center justify-center">
                    <MessageStudentFlow users={pocs.filter(poc => !!poc.email)} pocs/>
                    <Button onClick={(e) => {
                        e.stopPropagation();
                        this.setState({isSelectListModalOpen: true})
                    }}>
                        Add to list
                    </Button>
                </div>
                <SelectClubListModal
                    items={[club]}
                    type={"club"}
                    isOpen={this.state.isSelectListModalOpen}
                    authUser={this.props.authUser}
                    closeModal={() => this.setState({isSelectListModalOpen: false})}
                />
            </SidebarOverlayWrapper>
        )
    }
}

export default RecruiterClubPeek;
