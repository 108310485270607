import React, {Component} from "react";
import DatePicker from "react-datepicker";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import TimePicker from "react-time-picker";
import Switch from "react-switch";
import WrappedTextInput from "../../../components/atoms/WrappedTextInput";
import SelectComponent from "../../../components/atoms/SelectComponent";
import {STATES} from "../../../utils/constants";

class CreateEventDateAndLocationStep extends Component {

    render() {
        const STATE_OPTIONS = STATES.map(state => {
            return ({value: state, title: state})
        });
        return (
            <div className='flex flex-col gap-5 ml-2'>
                {!this.props.hideTutorial &&
                    <p className='text-lg text-slate-800 font-semibold'>
                        When and where will it take place?
                    </p>
            }
                <div className='flex flex-row items-center gap-3'>
                    <div className={`flex flex-col gap-1.5 flex-1`}>
                        <p className='text-md text-slate-500 font-semibold text-slate-800'>
                            Date
                        </p>
                        <DatePicker
                            className={'px-3 input-light body2 w-full'}
                            placeholderText={'Set date'}
                            selected={this.props.date}
                            showYearDropdown
                            onChange={(e) => this.props.handleDateChange(e, 'date')}
                        />
                    </div>
                    <p className='text-md font-semibold text-slate-800 mt-7 mx-3'>
                        From
                    </p>
                    <div className={`flex flex-col gap-1.5 flex-1 mt-[30px]`}>
                        <TimePicker
                            className='input-light px-3 outline-none text-slate-800'
                            value={this.props.startTime}
                            disableClock
                            showTime={{use12Hours: true, format: "HH:mm A"}}
                            onChange={(value) => this.props.handleDateChange(value, 'startTime')}
                        />
                    </div>
                    <p className='text-md font-semibold text-slate-800 mt-7 mx-3'>
                        To
                    </p>
                    <div className={`flex flex-col gap-1.5 flex-1 mt-[30px]`}>
                        <TimePicker
                            className='input-light px-3 outline-none text-slate-800'
                            value={this.props.endTime}
                            disableClock
                            showTime={{use12Hours: true, format: "HH:mm A"}}
                            onChange={(value) => this.props.handleDateChange(value, 'endTime')}
                        />
                    </div>
                </div>
                <div className='flex flex-col gap-3'>
                    <p className='text-md text-slate-500 font-semibold'>
                        Set an RSVP Deadline
                    </p>
                    <div>
                    <div className="flex">
                        <div className='flex flex-row gap-5'>
                            <Switch
                                onColor={'#4848F2'}
                                offColor={'#A3A3A3'}
                                onChange={() => this.props.toggleInput('hasRsvpDate')}
                                checked={this.props.hasRsvpDate}
                            />
                            <div>
                                <p className='text-lg text-slate-800 font-semibold'>
                                    Set an RSVP Deadline
                                </p>
                            </div>
                        </div>
                        {this.props.hasRsvpDate &&
                            <div className='flex flex-row items-center gap-5'>
                                <p className='ml-76 text-slate-500 text-md'>
                                    RSVP Date
                                </p>
                                <DatePicker
                                    className={'px-3 input-light body2 w-full'}
                                    placeholderText={''}
                                    selected={this.props.rsvpDate}
                                    showYearDropdown
                                    onChange={(e) => this.props.handleDateChange(e, 'rsvpDate')}
                                />
                            </div>
                        }
                        </div>
                    </div>
                </div>
                <div>
                    <div className='flex flex-col gap-4'>
                        <div>
                            <div className='flex flex-row gap-3 items-center'>
                                <div
                                    onClick={() => this.props.handleVirtualChange(false)}
                                    className={`h-4 w-4 rounded-full outline ${this.props.virtual ? 'outline-slate-200 cursor-pointer hover:bg-slate-200' : 'bg-primary outline-offset-2 outline-primary'}`}/>
                                <p className='text-md text-slate-800 font-semibold'>
                                    Physical
                                </p>
                            </div>
                            {!this.props.virtual ?
                                <div>
                                    <div className='flex flex-row items-center gap-3 mt-3'>
                                        <div className='flex-1'>
                                            <WrappedTextInput
                                                type="text"
                                                name="address"
                                                outerLabel={'Address / Building Name'}
                                                placeholder={'123 RecruitU Rd.'}
                                                className='input-light px-3 w-full '
                                                value={this.props.address}
                                                onChange={(e) => this.props.handleInputChange(e, 'address')}
                                            />
                                        </div>
                                        <div className='flex-1'>
                                            <WrappedTextInput
                                                type="text"
                                                outerLabel={'City'}
                                                name={'city'}
                                                placeholder={'ie. New York'}
                                                className='input-light px-3 w-full '
                                                value={this.props.city}
                                                onChange={(e) => this.props.handleInputChange(e, 'city')}
                                            />
                                        </div>
                                        <SelectComponent
                                            className={'w-1/5'}
                                            outerLabel={'State'}
                                            label={'ie. NY'}
                                            value={this.props.state}
                                            light
                                            setValue={this.props.handleSelectChange}
                                            clearFilter={() => this.props.handleSelectChange('state', null)}
                                            stateName={'state'}
                                            options={STATE_OPTIONS}
                                            scrollable
                                            node={document.getElementById('add-joblisting-modal')}
                                        />
                                    </div>
                                    <div className='mt-3'>
                                        <WrappedTextInput
                                            type="text"
                                            name={"meetingLink"}
                                            outerLabel={'Website or Meeting Link (optional)'}
                                            placeholder={'ie. Zoom or Event URL'}
                                            className='input-light px-3 w-full '
                                            value={this.props.meetingLink}
                                            onChange={(e) => this.props.handleInputChange(e, 'meetingLink')}
                                        />
                                    </div>
                                </div>
                                : null
                            }
                        </div>
                        <div>
                            <div className='flex flex-row gap-3 items-center'>
                                <div
                                    onClick={() => this.props.handleVirtualChange(true)}
                                    className={`h-4 w-4 rounded-full outline ${!this.props.virtual ? 'outline-slate-200 cursor-pointer hover:bg-slate-200' : 'bg-primary outline-offset-2 outline-primary'}`}/>
                                <p className='text-md text-slate-800 font-semibold'>
                                    Virtual
                                </p>
                            </div>
                            {this.props.virtual &&
                                <div className='mt-3'>
                                    <WrappedTextInput
                                        type="text"
                                        name={"meetingLink"}
                                        outerLabel={'Website or Meeting Link'}
                                        placeholder={'ie. Zoom or Event URL'}
                                        className='input-light px-3 w-full '
                                        value={this.props.meetingLink}
                                        onChange={(e) => this.props.handleInputChange(e, 'meetingLink')}
                                    />
                                </div>
                            }
                        </div>

                        {/* <div className='flex flex-col gap-3'>
                            <p className='text-md text-slate-500 font-semibold text-slate-800'>
                                How do candidates register
                            </p>
                            <div className='flex flex-row gap-5'>
                                <Switch
                                    onColor={'#4848F2'}
                                    offColor={'#A3A3A3'}
                                    onChange={() => this.props.toggleInput('registerOnRecruit')}
                                    checked={this.props.registerOnRecruit}
                                />
                                <div>
                                    <p className='text-lg text-slate-800 font-semibold'>
                                        Directly on RecruitU
                                    </p>
                                    <p className='text-sm text-slate-500'>
                                        Students will be able to register directly on the platform
                                    </p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateEventDateAndLocationStep
