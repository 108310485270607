import React, {PureComponent} from "react";
import NetworkingListShimmers from "../../loading/NetworkingListShimmers";
import ClubPageAlumniListItem from "../ClubPageAlumniListItem";
import DiscoverPersonItem from "../../discover/DiscoverPersonItem";

class ClubAlumniTab extends PureComponent {
    render() {
        const { loading, alumni, search, classYear, openPeek, updateNetwork } = this.props;

        return (
            <div className='flex flex-col flex-1 overflow-y-scroll pt-2 px-5'>
                {loading ?
                    <>
                        <NetworkingListShimmers />
                    </>
                    :
                    alumni.length === 0 ?
                        <div className='flex flex-col items-center justify-center py-32 body2 text-neutral-400'>
                            No alumni members {search === "" && classYear === "" ? "added" : "for this search"}
                        </div>
                        :
                        <>
                            {alumni.map(item =>
                                // <ClubPageAlumniListItem
                                //     key={item.name} item={item}
                                //     club={club}
                                //     authUser={authUser}
                                //     isAdmin={isAdmin}
                                //     getClubInfo={getClubInfo}
                                //     openViewAlumniModal={openViewAlumniModal}
                                // />
                                <DiscoverPersonItem 
                                    item={item} 
                                    openPeek={openPeek} 
                                    updateNetwork={updateNetwork}
                                    clubAlumni/>
                            )}
                        </>
                }
            </div>
        );
    }
}

export default ClubAlumniTab;
