import * as React from "react";

const SvgChevronUp = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.233 12.742L10 9.508l-3.233 3.234a.83.83 0 11-1.175-1.175l3.825-3.825a.83.83 0 011.175 0l3.825 3.825a.83.83 0 010 1.175.848.848 0 01-1.184 0z"
      fill={props.fill || "currentColor"}
    />
  </svg>
);

export default SvgChevronUp;
