import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill='currentColor' d="M319-250h322v-60H319v60zm0-170h322v-60H319v60zM160-80v-800h421l219 219v581H160zm391-554v-186H220v680h520v-494H551zM220-820v186-186 680-680z" />
        </svg>
    )
}

export default SvgComponent
