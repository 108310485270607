import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 96 960 960"
            width={48}
            {...props}
        >
            <path
                fill={props.fill || 'currentColor'}
                d="M40 896V736q0-29 20.5-49.5T110 666h141q17 0 32.5 8.5T310 698q29 42 74 65t96 23q51 0 96-23t75-65q11-15 26-23.5t32-8.5h141q29 0 49.5 20.5T920 736v160H660V777q-36 33-82.5 51T480 846q-51 0-97-18t-83-51v119H40zm440-170q-35 0-67.5-16.5T360 664q-16-23-38.5-37T273 608q29-30 91-46t116-16q54 0 116.5 16t91.5 46q-26 5-48.5 19T601 664q-20 29-52.5 45.5T480 726zM160 596q-45 0-77.5-32.5T50 486q0-46 32.5-78t77.5-32q46 0 78 32t32 78q0 45-32 77.5T160 596zm640 0q-45 0-77.5-32.5T690 486q0-46 32.5-78t77.5-32q46 0 78 32t32 78q0 45-32 77.5T800 596zM480 476q-45 0-77.5-32.5T370 366q0-46 32.5-78t77.5-32q46 0 78 32t32 78q0 45-32 77.5T480 476z" />
        </svg>
    )
}

export default SvgComponent
