import React, {Component} from "react";
import {connect} from "react-redux";
import PaginationComponent from "../../components/atoms/PaginationComponent";
import WrappedTextInput from "../../components/atoms/WrappedTextInput";
import SvgSearch from "../../components/icons/SvgSearch";
import SvgAddCircle from "../../components/icons/SvgAddCircle";
import ScrapedNetworkingPeek from "../../components/networkingpage/ScrapedNetworkingPeek";
import CollapseComponent from "../../components/atoms/CollapseComponent";
import CompanyLogo from "../../components/atoms/CompanyLogo";
import SelectComponent from "../../components/atoms/SelectComponent";
import AddAlumniModal from "../../components/modals/AddAlumniModal";
import {filteredPersonSearch, searchCompanies} from "../../utils/typesense";
import FadeInOnScroll from "../../components/atoms/FadeInOnScroll";
import DiscoverPersonItem from "../../components/discover/DiscoverPersonItem";
import {RectangleStackIcon} from "@heroicons/react/24/outline";

const START = 1;
const END = 20;

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        networks: state.userReducer.networks,
    };
}

const AddAlumni = ({club}) => {
    return (
        <AddAlumniModal club={club}>
            <div
                className='row-ac bg-primary-light px-4 cursor-pointer py-1 rounded-full primary'>
                <SvgAddCircle className={'w-5 h-5'}/>
                <button className='m-0 ml-8'>
                    Add Alumni
                </button>
            </div>
        </AddAlumniModal>
    )
}

class ClubAdminAlumniPage extends Component {
    state = {
        alumni: [],
        page: 1,
        numAlumni: 0,
        selectedPersonId: null,
        selectedPerson: {},
        year: "",
        alumniSearch: "",
        jobStatus: null,
        companyId: null,
        companyName: null,
        openCollapse: false,
        networkingPeekOpen: false,
        companySearch: "",
        companySearchResults: [],
        start: START,
        end: END,
    }

    componentDidMount = () => {
        this.alumniSearch();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.club !== this.props.club) {
            this.alumniSearch();
        }
    }


    setBounds = (start, end) => {
        if (start > this.state.end) {
            this.alumniSearch(this.state.alumniSearch, this.state.year, this.state.companyId, this.state.position, this.state.page + 1, this.state.sortBy);
        } else if (this.state.alumni.length) {
            this.alumniSearch(this.state.alumniSearch, this.state.year, this.state.companyId, this.state.position, this.state.page - 1, this.state.sortBy);
        }
        this.setState({start, end});
    }

    handleAlumniSearchChange = (event) => {
        const text = event.target.value;
        this.setState({alumniSearch: text});

        this.alumniSearch(text, this.state.year, this.state.companyId, this.state.position, this.state.page, this.state.sortBy);
    }

    alumniSearch = (text = "", class_year = null, companyId = null, position = null, page = 1, sort_by = null) => {
        let sb = null;
        if (!!sort_by) sb = sort_by === "Class year" ? "grade:desc" : "created_at:desc"


        filteredPersonSearch(text, this.props.club.id, page, class_year, [], null, companyId, null, !!position ? [position] : [], true, sb).then(peopleResults => {
            this.setState({alumni: peopleResults.results, loading: false, numAlumni: peopleResults.total, page});
        })
    }

    yearSearch = (year) => {
        this.setState({year})
        this.alumniSearch(this.state.alumniSearch, year, this.state.companyId, this.state.position, this.state.page, this.state.sortBy);
    }

    handleGradChange = (event) => {
        const text = event.target.value;
        const year = String(text)
        if (year.length === 0) this.yearSearch(year);

        if (year.length <= 4) this.setState({year: text});
        if (year.length === 4 || year.length === 2) {
            this.yearSearch(year);

        }
    }

    handlePositionChange = (position) => {
        this.setState({position})
        this.alumniSearch(this.state.alumniSearch, this.state.year, this.state.companyId, position, this.state.page, this.state.sortBy);
    }

    handleSortByChange = (sortBy) => {
        this.setState({sortBy})
        this.alumniSearch(this.state.alumniSearch, this.state.year, this.state.companyId, this.state.position, this.state.page, sortBy);
    }

    handleSearchChange = (event) => {
        const text = event.target.value;
        if (!text) {
            this.setCompany({id: null, name: null})
        }

        this.setState({companySearch: text, openCollapse: true});

        this.companySearchChanged(text);
    }

    companySearchChanged = async (text) => {
        const companySearchResults = await searchCompanies(text);
        this.setState({companySearchResults});
    }

    setCompany = (result) => {
        this.setState({
            companySearchResults: [],
            companySearch: result.name
        })
        this.alumniSearch(this.state.alumniSearch, this.state.year, result.id, this.state.position, this.state.page, this.state.sortBy);
    }

    closePositionModal = () => {
        this.setState({positionModalOpen: false})
    }

    openPositionModal = (selectedPerson) => {
        this.setState({positionModalOpen: true, selectedPerson})
    }

    toggleAddClubMemberModal = () => {
        this.setState({addClubMemberModalOpen: !this.state.addClubMemberModalOpen});
    }

    toggleMemberInviteModal = () => {
        this.setState({memberInviteModalOpen: !this.state.memberInviteModalOpen})
    }

    openPeek = (personId, person) => {
        this.setState({selectedPersonId: personId, selectedPerson: person, networkingPeekOpen: true})
    }

    closePeek = () => {
        this.setState({networkingPeekOpen: false, scrapedNetworkingPeekOpen: false});
    }

    render() {
        const club = this.props.club || {};
        const leadership = club.leadership || {};
        const adminIds = this.props.user.admin.map(club => club.id);
        return (
            <div className={`flex flex-col flex-1 p-5 gap-3 relative overflow-hidden`}>
                <div className="flex flex-row justify-between">
                    <div className='flex flex-row items-center justify-between'>
                        <p className='text-slate-800 my-1 text-2xl font-semibold'>
                            Alumni
                        </p>
                    </div>
                    <div className='body2-bold blacks-1 row-ac gap-6 mr-12'>
                        <AddAlumni club={club}/>
                    </div>
                </div>
                <div className='flex flex-row items-center w-full mb-4 gap-2'>
                    <div className='flex flex-row items-center gap-2 flex-wrap'>
                        <WrappedTextInput
                            type="text"
                            placeholder={"Search alumni name, company name..."}
                            className='pl-44 input-light body2 w-[240px] lg:w-[330px]'
                            value={this.state.alumniSearch}
                            onChange={this.handleAlumniSearchChange}
                            icon={<SvgSearch width={24} height={24} className={'absolute base-black-50'}
                                             style={{left: 12, top: 10}}/>}
                        />
                    </div>
                    <div className='relative'>
                        <WrappedTextInput
                            type="text"
                            placeholder={'Search by company...'}
                            className='pl-16 input-light px-3 body2 w-full'
                            value={this.state.companySearch}
                            onChange={this.handleSearchChange}
                        />
                        <div className='absolute top-12 left-0 right-0 z-[99]'>
                            <CollapseComponent
                                isOpen={this.state.companySearch.length !== 0 && this.state.openCollapse}
                                className='bg-white shadow-lg border border-neutral-200 rounded-lg'>
                                {this.state.companySearchResults.map((res) =>
                                    <FadeInOnScroll triggerImmediately delay={10} key={res.id}>
                                        <div
                                            onClick={() => {
                                                this.setCompany(res);
                                                this.setState({
                                                    companySearch: res.name,
                                                    openCollapse: false
                                                })
                                            }}
                                            className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2'>
                                            <CompanyLogo id={res.id} className={'w-12 h-12'}/>
                                            <div className='flex flex-col'>
                                                <p className='text-sm text-slate-800 line-clamp-2'>
                                                    {res.name}
                                                </p>
                                            </div>
                                        </div>
                                    </FadeInOnScroll>
                                )}
                            </CollapseComponent>
                        </div>
                    </div>
                    <div className='flex flex-row items-center gap-2 flex-wrap'>
                        <WrappedTextInput
                            type="text"
                            placeholder={"Filter by class year..."}
                            className='input-light pl-16 body2 w-[120px] lg:w-[180px]'
                            value={this.state.year}
                            onChange={this.handleGradChange}
                        />
                    </div>
                    <div className='flex flex-row items-center gap-2 flex-wrap'>
                        <SelectComponent
                            label={'Filter by position...'}
                            className={'w-full'}
                            value={this.state.position}
                            light
                            setValue={this.handlePositionChange}
                            clearFilter={() => this.handlePositionChange(null)}
                            options={[{title: "Analyst"}, {title: "Associate"}, {title: "Vice President"}, {title: "Managing Director"}]}
                        />
                    </div>
                    {/*<div className='flex flex-row items-center gap-2 flex-wrap'>*/}
                    {/*    <SelectComponent*/}
                    {/*        label={'Sort by...'}*/}
                    {/*        className={'w-full'}*/}
                    {/*        value={this.state.sortBy}*/}
                    {/*        light*/}
                    {/*        setValue={this.handleSortByChange}*/}
                    {/*        clearFilter={() => this.handleSortByChange(null)}*/}
                    {/*        options={[{title: "Class year"}, {title: "Date created"}]}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
                <div className='flex flex-col gap-3 flex-1 overflow-hidden'>
                    {this.state.alumni.length === 0 ?
                        <div className='my-8 flex text-neutral-400 flex-col items-center justify-center flex-1'>
                            <div className="text-center mb-8">
                                <RectangleStackIcon className="mx-auto h-20 w-20 text-slate-400"/>
                                <h3 className="mt-2 text-lg font-semibold text-slate-900">
                                    No alumni added
                                </h3>
                            </div>
                            <AddAlumni club={club}/>
                        </div>
                        :
                        <div className={`flex flex-col flex-1 overflow-scroll`}>
                            {this.state.alumni.map((item, index) =>
                                <div onClick={() => this.openPeek(item.id, item)}>
                                    <FadeInOnScroll key={item.id} triggerImmediately
                                                    delay={(index * 50) + 10}>
                                        <DiscoverPersonItem item={item} openPeek={this.openPeek} adminPortal
                                                            updateNetwork={this.props.updateNetwork}/>
                                    </FadeInOnScroll>
                                </div>
                            )}
                        </div>
                    }
                    <PaginationComponent
                        className={'px-5 bg-white'}
                        numResults={this.state.numAlumni}
                        setBounds={this.setBounds}
                        pageLength={20}
                        loading={this.props.tableLoading}
                    />
                </div>
                <ScrapedNetworkingPeek
                    open={this.state.networkingPeekOpen}
                    network={this.props.networks[this.state.selectedPersonId]}
                    isSaved={(this.state.selectedPersonId in this.props.networks)}
                    person={this.state.selectedPerson}
                    close={this.closePeek}
                    deleteNetwork={this.props.deleteNetwork}
                    updateNetwork={this.props.updateNetwork}
                    adminPortal
                />
            </div>
        )
    }
}

export default connect(mapStateToProps)(ClubAdminAlumniPage);
