import React, {PureComponent} from "react";
import SvgSearch from "../../components/icons/SvgSearch"
import SvgAssignment from "../icons/SvgAssignnment";
import AnimatedTabs from "../atoms/AnimatedTabs";
import {Button} from "../atoms/Button";


class SelectManualFlow extends PureComponent {
    select = (res) => {
        
        if (res === 'Enter details manually') {
            this.props.nextStep(4)
            this.props.setCustom(true)
        } else {
            this.props.nextStep();
        }
    }

    render() {
        const options = [
            {title: `Find a${this.props.type === "event" ? "n" : ""} ${this.props.type} on RecruitU`, icon: SvgSearch},
            {title: 'Enter details manually', icon: SvgAssignment},
        ]
        return (
            <div className="col-plain w-full gap-3">
                <div className='flex flex-col gap-1'>
                    {options.map((option, idx) => 
                        <div className='card mb-0 flex flex-row items-center justify-between hover-card gap-2' onClick={() => this.select(option.title)}>
                            <div className='mb-0 flex flex-row items-center gap-2'>
                                <div className='flex flex-row items-center gap-2 flex-1'>
                                    <option.icon
                                        style={{transition: 'all 100ms ease'}}
                                        className={`w-5 h-5 mr-1 opacity-100 text-neutral-400`}
                                        key={`select-manual-flow-option-${idx}`}

                                    />
                                    <div className='flex flex-1'>
                                        <p className='body1-bold'>
                                            {option.title}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default SelectManualFlow
