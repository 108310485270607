import React, {Component} from "react";
import SvgEditFeather from "../../components/icons/SvgEditFeather";
import zerostate from "../../img/zerostates/Life at Morgan Pictures.png"
import {connect} from "react-redux";
import {Button} from "../atoms/Button";
import ClubPageEditImages from "./modals/ClubPageEditImages";
import SvgPlus from "../icons/SvgPlus";
import SvgVisibility from "../icons/SvgVisibility";
import SvgEyeOff from "../icons/SvgEyeOff";
import Tooltip from "../atoms/modals/Tooltip";
import SvgRemove from "../icons/SvgRemove";
import { editClub } from "../../api/student/clubs";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        setImageViewerModalOpen: (images) => {
            dispatch({
                type: "SET_IMAGE_VIEWER_MODAL_OPEN",
                images
            })
        },
    }
}

class ClubPageLifeAtSection extends Component {
    state = {
        imagesModalOpen: false,
    }

    openEditImagesModal = () => {
        this.setState({imagesModalOpen: true})
    }

    closeEditImagesModal = () => {
        this.setState({imagesModalOpen: false})
    }

    openImageLightbox = (imageURLs, index) => {
        this.props.setImageViewerModalOpen(imageURLs, index);
    }

    removeImage = async (index) => {
        const club = this.props.club || {};
        const images = [...club.images];
        images.splice(index, 1);
        await editClub(this.props.authUser, this.props.club.id, {images});
        this.props.getClubInfo();
    }

    render() {
        const club = this.props.club || {};
        const images = club.images || [];
        const hidden = this.props.hidden || [];
        return (
            <div
                className={`bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl mt-5 ${hidden.includes("images") && 'opacity-50'}`}>
                <div className='flex flex-row items-center gap-2 mb-2'>
                    <p className='text-xl font-semibold text-slate-800'>
                        Life at {club.name}
                    </p>
                    {!this.props.loading && this.props.isAdmin &&
                        <>
                            {images.length > 0 &&
                                <button
                                    onClick={this.openEditImagesModal}
                                    className='text-slate-500 hover:text-primary cursor-pointer'>
                                    <SvgPlus className={'w-4 h-4'}/>
                                </button>
                            }
                            <Tooltip
                                toggleComponent={
                                    <button
                                        onClick={() => this.props.hide("images")}
                                        className='text-slate-500 hover:text-primary cursor-pointer'>
                                        {hidden.includes("images") ?
                                            <SvgVisibility className={'w-4 h-4'}/>
                                            :
                                            <SvgEyeOff className={'w-4 h-4'}/>
                                        }
                                    </button>
                                }
                                label={`${hidden.includes("images") ? "Show" : "Hide"} this section when recruiters are viewing your club.`}
                            />
                        </>}
                </div>
                <p className='text-slate-500 text-md mb-5'>
                    Step inside our world and see what makes us different
                </p>
                {images.length === 0 &&
                    <>
                        {!this.props.loading && this.props.isAdmin ?

                            <div className='w-full relative cursor-pointer' onClick={this.openEditImagesModal}>
                                <img src={zerostate} className='w-full blur-sm mb-5 saturate-50 opacity-50'/>
                                <div
                                    className='absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center'>
                                    <Button>
                                        Add images
                                    </Button>
                                </div>
                            </div>
                            :
                            <p>
                                No images uploaded yet
                            </p>
                        }
                    </>
                }
                <div className='grid grid-cols-4 gap-3 mt-5'>
                    {images.map((image, index) =>
                        <div key={image} className={'relative flex flex-col items-start group'}>
                            <img
                                onClick={() => this.openImageLightbox(images, index)}
                                className='h-44 w-full rounded-lg bg-slate-50 object-contain  cursor-pointer border border-transparent hover:border-primary'
                                src={image}
                            />
                            {this.props.isAdmin &&
                                <div onClick={() => this.removeImage(index)}
                                    className={'p-2 cursor-pointer rounded-full bg-slate-50 hover:bg-red-400 hover:text-white text-red-400 absolute -top-3 right-2'}>
                                    <SvgRemove className={'w-5 h-5'}/>
                                </div>
                            }
                        </div>
                    )}
                </div>
                <ClubPageEditImages
                    isOpen={this.state.imagesModalOpen}
                    closeModal={this.closeEditImagesModal}
                    club={club}
                    getClubInfo={this.props.getClubInfo}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClubPageLifeAtSection);
