import React, { useState, useEffect, useRef } from 'react';
import OnboardingStepHeader from './OnboardingStepHeader';
import OnboardingStepWrapper from './OnboardingStepWrapper';
import OnboardingStepButtons from './OnboardingStepButtons';
import SelectableItem from './SelectableItem';
import SvgClose from "../icons/SvgClose";
import classnames from 'classnames';
import { PRIMARY_TAGS } from '../../utils/dummy';
import SelectedTag from './SelectedTag';
import useIsMobile from '../../hooks/isMobile';
import * as Accordion from "@radix-ui/react-accordion";
import SvgChevronDown from "../icons/ChevronDown";

function OnboardingIndustriesStep({ goNextStep, goPreviousStep, onIndustriesSelected, onRolesSelected, industries, roles, constants }) {
    const [selectedIndustries, setSelectedIndustries] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const roleContainerRef = useRef(null);
    const isMobile  = useIsMobile();
    const [activeIndustry, setActiveIndustry] = useState(null);

    const isConsultingRole = (role) => {
        return role.endsWith('Consulting');
    }
    
    const handleIndustryClick = (label) => {
        if (selectedIndustries.includes(label)) {
            setSelectedIndustries(selectedIndustries.filter(item => item !== label));
        } else {
            setSelectedIndustries([...selectedIndustries, label]);
            setActiveIndustry(label);
        }
    };

    const handleRoleClick = (label, isFromSelectedTag = false) => {
        if (selectedRoles.includes(label)) {
            setSelectedRoles(selectedRoles.filter(item => item !== label));
        } else {
            setSelectedRoles([...selectedRoles, label]);
        }

        if (isMobile && roleContainerRef.current && !isFromSelectedTag) {
            roleContainerRef.current.scrollTo({
                left: roleContainerRef.current.scrollWidth,
                behavior: 'smooth'
            });
        }
    };


    const handleNext = () => {
        onIndustriesSelected(selectedIndustries);
        onRolesSelected(selectedRoles);
        goNextStep();
    };

    useEffect(() => {
        setSelectedIndustries(industries);
        setSelectedRoles(roles);
    }, [])

    const INDUSTRY_TAGS = constants.industry_tags.map(tag => ({title: tag, value: tag}));
    const FINANCE_ROLES = constants.secondary_tags.filter(tag => !isConsultingRole(tag)).map(tag => ({title: tag, value: tag}));
    const CONSULTING_ROLES = constants.secondary_tags.filter(tag => isConsultingRole(tag)).map(tag => ({title: tag, value: tag}));
    return (
        <OnboardingStepWrapper>
            <OnboardingStepHeader
                title={'What fields are you interested in?'}
                description={'This helps us find the most relevant jobs for you and your skillset'}
            />
            <div className='flex flex-col gap-3 md:gap-5'>
                <p className='text-lg md:text-xl font-semibold'>Your industry picks</p>
                <div className='flex flex-wrap gap-2 max-h-[10vh] md:max-h-[auto] overflow-y-auto'>
                    <p className='text-slate-500 hidden only:flex text-sm md:text-base'>
                        Select industries below...
                    </p>
                    {selectedIndustries.map(industry => (
                        <SelectedTag key={industry} label={industry} onClose={handleIndustryClick}/>
                    ))}
                </div>
                <div className='flex flex-row items-center justify-between'>
                    <p className='text-lg md:text-xl font-semibold'>Industries</p>
                </div>
                <div>
                    <div
                        className='grid grid-cols-2 md:grid-cols-3 gap-2'>
                        {INDUSTRY_TAGS.map(industry => (
                            <SelectableItem
                                key={industry.value}
                                label={industry.title}
                                isSelected={selectedIndustries.includes(industry.title)}
                                onClick={handleIndustryClick}
                            />
                        ))}
                    </div>
                </div>
                {selectedIndustries.length > 0 && (
                    <>
                        <p className='text-lg md:text-xl font-semibold'>Your role picks</p>
                        <div className='flex md:flex-wrap gap-2 overflow-x-auto relative' ref={roleContainerRef}>
                            <p className='text-slate-500 hidden only:flex text-sm md:text-base'>
                                Select roles below...
                            </p>
                            {selectedRoles.map(role => (
                                <SelectedTag key={role} label={role} onClose={() => handleRoleClick(role, true)}/>
                            ))}
                        </div>
                    </>
                )}
                <div>
                    <Accordion.Root type="single" collapsible value={activeIndustry} onValueChange={setActiveIndustry}>
                        {selectedIndustries.map(industry => {
                            const industryOptions = industry === 'Finance' ? FINANCE_ROLES : CONSULTING_ROLES;
                            return (
                                <Accordion.Item key={industry} value={industry}>
                                    <div key={industry} className='mb-4 relative'>
                                        <Accordion.Trigger className='w-full bg-slate-100 mb-2 rounded-xl flex items-center justify-between px-4 py-2'>
                                            <p className='text-md md:text-lg font-medium '>{industry} Roles</p>
                                            <SvgChevronDown className={`w-4 h-4 ${activeIndustry === industry ? 'rotate-180' : 'rotate-0'}`}/> 
                                        </Accordion.Trigger>
                                        <Accordion.Content className='md:overflow-y-auto'>
                                            {industry === 'Finance' && (
                                                <div className="absolute right-0 top-0 bottom-0 w-16 pointer-events-none bg-gradient-to-l from-white to-transparent md:hidden"/>
                                            )}
                                            <div className={classnames(
                                                'flex flex-nowrap overflow-x-auto flex-row md:grid md:grid-cols-3 gap-2 md:overflow-y-auto'
                                            )}>
                                                {industryOptions.map(option => (
                                                    <SelectableItem
                                                        key={option.value}
                                                        label={option.title}
                                                        isSelected={selectedRoles.includes(option.value)}
                                                        onClick={handleRoleClick}
                                                    />
                                                ))}
                                            </div>
                                        </Accordion.Content>
                                    </div>
                                </Accordion.Item>
                            );
                        })}
                    </Accordion.Root>
                </div>
            </div>
            <OnboardingStepButtons
                goNextStep={handleNext}
                goPreviousStep={goPreviousStep}
            />
        </OnboardingStepWrapper>
    );
}

export default OnboardingIndustriesStep;
