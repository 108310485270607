import React, {PureComponent} from "react";
import NetworkStatusTrackerComponet from "../clubpage/NetworkStatusTrackerComponent";
import SvgLinkedIn from "../icons/SvgLinkedIn";
import CompanyLogo from "../atoms/CompanyLogo";
import SvgChevronRight from "../icons/SvgChevronRight";
import {Link} from "react-router-dom";


class NetworkingListItem extends PureComponent {
    state = {
        tracking: false
    }

    render() {
        const person = this.props.person || {};
        return (
            <div className='flex flex-col border border-neutral-200 rounded-xl p-3'>
                <div className='row-as mb-3'>
                    <div className='col-jb'>
                        <div>
                            <Link to={{pathname: '/company/'+person.company_id, state: {company_id: person.company_id}}}
                                  className={!person.company_id && 'pointer-events-none'}>
                                <CompanyLogo id={person.company_id} className={'w-12 h-12 mb-2'}/>
                            </Link>
                            <div className='flex-row flex flex-wrap gap-1'>
                                <p className='body2-bold base-black-70 m-0'>
                                    {person.first_name} {person.last_name}
                                </p>
                                {person.linkedin &&
                                    <a href={person.linkedin}
                                       target={"_blank"}>

                                        <SvgLinkedIn
                                            className={'base-black-30 h-base-black-70 pointer w-4 h-4 mt-0.5'}/>
                                    </a>
                                }
                            </div>
                            <p className='body3 base-black-50 m-0'>
                                {person.title}
                            </p>
                        </div>
                        <Link to={{pathname: '/company/'+person.company_id, state: {company_id: person.company_id}}}
                              className={!person.company_id && 'pointer-events-none'}>
                            <div
                                className={`flex flex-row flex-wrap items-center gap-2 ${person.company_id ? 'primary' : 'text-slate-500'} cursor-pointer`}>
                                <div className='flex flex-row items-center flex-wrap gap-0'>
                                    <p className='body3-bold'>
                                        {person.company_name}
                                    </p>
                                    {person.company_id &&
                                        <SvgChevronRight className={'w-5 h-5'}/>
                                    }
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <NetworkStatusTrackerComponet sm personId={person.id} person={person}/>
            </div>
        )
    }
}

export default NetworkingListItem
