import React, {useEffect, useState} from 'react';
import SelectableItem from "../onboarding/SelectableItem";
import {ONBOARDING_CITIES} from "../onboarding/onboarding-constants";
import SelectedTag from "../onboarding/SelectedTag";
import EditProfileWrapper from "./EditProfileWrapper";
import EditProfileCtaButton from "./EditProfileCtaButton";
import {editUser, getUser} from '../../api/student/users';
import useUser from '../../hooks/useUser';
import {toast} from 'react-toastify';
import {Button} from "../atoms/Button";

function EditProfileLocation({goToNextSection, hasNextSection, loading, setLoading}) {
    const {user, setUser, authUser} = useUser();
    const [selectedCities, setSelectedCities] = useState(() => {
        return user.locations ?? [];
    });
    const [sortOrder, setSortOrder] = useState('popularity');

    const allCities = ONBOARDING_CITIES.map(({city, state}) => `${city}, ${state}`);

    const handleCityClick = (label) => {
        if (selectedCities.includes(label)) {
            setSelectedCities(selectedCities.filter(item => item !== label));
        } else {
            setSelectedCities([...selectedCities, label]);
        }
    };

    const handleSortChange = (e) => {
        setSortOrder(e.target.value);
    };

    const updateProfile = async () => {
        try {
            setLoading(true);
            await editUser(authUser, {
                locations: selectedCities
            });
            const {user: userUpdated} = await getUser(authUser);
            setUser(userUpdated);
            if (hasNextSection) {
                goToNextSection();
            }
        } catch (error) {
            console.log(error);
            throw error;
        } finally {
            setLoading(false);
        }
    }

    const selectAllCities = () => {
        setSelectedCities(allCities);
    };

    const deselectAllCities = () => {
        setSelectedCities([]);
    };

    function handleSubmit() {
        if (selectedCities.length === 0) {
            toast.error('Please select at least one city');
            return;
        }
        toast.promise(updateProfile, {
            pending: 'Updating profile...',
            success: 'Profile updated successfully!',
            error: 'Failed to update profile'
        });
    }

    const sortedCities = [...ONBOARDING_CITIES].sort((a, b) => {
        if (sortOrder === 'alphabetical') {
            return a.city.localeCompare(b.city);
        }
        return 0; // Replace with actual popularity sorting logic if available
    });

    return (
        <>
            <EditProfileWrapper>
                <div className='flex flex-col gap-3 md:gap-5'>
                    <p className='text-lg md:text-xl font-semibold'>Your picks</p>
                    <div className='flex flex-wrap gap-2 max-h-[10vh] md:max-h-[auto] overflow-y-auto'>
                        <p className='text-slate-500 text-sm md:text-base hidden only:flex h-9'>
                            Select cities below...
                        </p>
                        {selectedCities?.map(cityKey => (
                            <SelectedTag key={cityKey} label={cityKey} onClose={handleCityClick}/>
                        ))}
                    </div>
                    <div className='flex flex-row items-center justify-between'>
                        <p className='text-lg md:text-xl font-semibold'>Cities</p>
                        <div className="flex gap-3 mb-4">
                            <select onChange={handleSortChange} value={sortOrder}
                                    className='text-xs md:text-sm cursor-pointer font-medium border border-slate-300 rounded-lg'>
                                <option value="popularity">Sort by popularity</option>
                                <option value="alphabetical">Sort alphabetically</option>
                            </select>
                            <Button
                                onClick={selectAllCities}
                            >
                                Select All
                            </Button>
                            <Button
                                onClick={deselectAllCities}
                                variant={'secondary'}
                            >
                                Deselect All
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div
                            className='grid grid-cols-2 md:grid-cols-3 gap-2 pb-20 overflow-y-scroll border-b border-slate-200'>
                            {sortedCities.map(({city, state}) => (
                                <SelectableItem
                                    key={`${city}-${state}`}
                                    label={`${city}, ${state}`}
                                    onClick={handleCityClick}
                                    isSelected={selectedCities.includes(`${city}, ${state}`)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </EditProfileWrapper>
            <EditProfileCtaButton onClick={handleSubmit} disabled={loading}>
                {hasNextSection ? 'Save & continue' : 'Save'}
            </EditProfileCtaButton>
        </>
    );
}

export default EditProfileLocation;
