import React, {PureComponent} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {APP_ADMINS, TOAST_OPTIONS} from "../../utils/constants";
import CompanyLogo from "../atoms/CompanyLogo";
import SvgClock from "../icons/SvgClock";
import SvgRemove from '../icons/SvgRemove';
import SvgCalendar from '../icons/SvgCalendar';
import {deleteEvent} from '../../api/student/events';
import TagComponent from '../atoms/TagComponent';
import {DateTime} from 'luxon';
import Checkbox from "../atoms/Checkbox";
import RuDirectLabel from "../atoms/RuDirectLabel";
import {SIDEBAR_CARD} from "../../utils/dummy";
import {toast} from "react-toastify";
import RsvpNowButton from "../RsvpNowButton";
import {addNote, deleteNote, editTracker} from "../../api/student/trackers";
import SidebarOverlayWrapper from "../atoms/SidebarOverlayWrapper";
import {Button} from "../atoms/Button";
import SvgCheckCircle from "../icons/SvgCheckCircle";
import Tooltip from "../atoms/modals/Tooltip";
import NotetakingWidget from "../../components-recruiter/atoms/NotetakingWidget";

var parse = require('html-react-parser');


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateNetwork: (network) => {
            dispatch({
                type: "UPDATE_NETWORK",
                network,
            })
        },
        updateTracker: (tracker) => {
            dispatch({
                type: "UPDATE_TRACKER",
                tracker,
            })
        }
    }
}

class EventPeek extends PureComponent {
    state = {
        location: "",
        status: 'To apply',
        people: [],
        networkingLoading: true,
        rsvp: false,
        note: "",
        rsvpProcessing: false,
    }

    toggleCheckbox = async () => {
        try {
            const newTracker = {...this.props.tracker};
            const newStatus = !newTracker.status
            newTracker.status = newStatus
            this.props.updateTracker(newTracker);
            await editTracker(this.props.authUser, newTracker.id, {status: newStatus});
            toast.promise(
                Promise.resolve('Status updated!'),
                {
                    pending: 'Updating status...',
                    success: 'Status updated!',
                    error: 'Failed to update status!',
                },
                TOAST_OPTIONS
            );
        } catch (error) {
            toast.error('Failed to update status!', TOAST_OPTIONS);
        }
    }


    deleteEvent = async () => {
        await deleteEvent(this.props.authUser, this.props.event.id);
    }

    updateNote = (e) => {
        this.setState({note: e.target.value})
    }

    saveNote = async (note) => {
        try {
            const savePromise = addNote(this.props.authUser, this.props.tracker.id, note);
            toast.promise(
                savePromise,
                {
                    pending: 'Adding note...',
                    success: 'Note added!',
                    error: 'Failed to add note!',
                },
                TOAST_OPTIONS
            );
            const saveResult = await savePromise;
            if (saveResult) {
                const newTracker = {...this.props.tracker};
                newTracker.notes.push({text: note, created_at: new Date()});
                this.props.updateTracker(newTracker);
            }
        } catch (error) {
            toast.error('Failed to add note!', TOAST_OPTIONS);
        }
    };

    deleteNote = async (index) => {
        try {
            const deletePromise = deleteNote(this.props.authUser, this.props.tracker.id, index);
            toast.promise(
                deletePromise,
                {
                    pending: 'Deleting note...',
                    success: 'Note deleted!',
                    error: 'Failed to delete note!',
                },
                TOAST_OPTIONS
            );

            const deleteResult = await deletePromise;
            if (deleteResult) {
                const newTracker = {...this.props.tracker};
                newTracker.notes.splice(index, 1);
                this.props.updateTracker(newTracker);
            }
        } catch (error) {
            console.error(error);
        }
    };

    render() {
        const listing = this.props.listing || {};
        const description = listing.description || "";
        const datePosted = DateTime.fromISO(listing.created_at);

        const event = this.props.event || {};
        const deadline = DateTime.fromISO(event.event_date);
        const rsvpDate = event.rsvp_date ? DateTime.fromISO(event.rsvp_date) : null;

        const classYears = event.class_years || [];
        classYears.sort();
        let yearText = '';

        if (classYears.length === 1) {
            yearText = `Class of '${classYears[0].toString().slice(-2)}`;
        } else if (classYears.length > 1) {
            const minYear = classYears[0].toString().slice(-2);
            const maxYear = classYears[classYears.length - 1].toString().slice(-2);
            yearText = `Class of '${minYear} - '${maxYear}`;
        }

        const tracker = this.props.tracker || {};
        const notes = tracker.notes || [];

        return (
            <SidebarOverlayWrapper label={'Event Details'} onClose={this.props.close} open={this.props.open}
                                   width={'40vw'}>
                <div className='col-jb flex-1 overflow-hidden mb-0' style={this.props.style}>
                    <div className='row-jb mb-3'>
                        <div className='flex flex-row'>
                            <Link to={{
                                pathname: '/company/' + event.company_id,
                                state: {company_id: event.company_id}
                            }}
                                  className={'w-16 h-16'}
                            >
                                <CompanyLogo id={event.company_id} className={'w-16 h-16'}/>
                            </Link>
                            <div className='ml-3 flex flex-1'>
                                <div className='flex flex-row items-center'>
                                    {!!yearText ?
                                        <div className='flex flex-row text-primary items-center gap-1.5'>
                                            <SvgCalendar className='w-3.5 h-3.5'/>
                                            <p className='text-sm font-semibold'>
                                                {yearText}
                                            </p>
                                        </div>
                                        : null}
                                </div>
                                <p className='text-xl font-bold blacks-1 mb-2'>
                                    {listing.name}
                                </p>
                                {(event) ?
                                    <div className='mr-a flex flex-row items-center gap-2 wrap mb-3'>
                                        <TagComponent label={event.type}/>
                                        <TagComponent label={event.industry}/>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row items-center gap-3'>
                        <div className='flex-1'>
                            <RsvpNowButton
                                event={event}
                            />
                        </div>
                        {!event.attendees?.includes(this.props.authUser.uid) ?
                            <></>
                            :
                            <div className='flex flex-1'>
                                <Button
                                    disabled={true}
                                    variant='secondary'
                                    icon={SvgCheckCircle}
                                >
                                    Saved
                                </Button>
                            </div>
                        }
                    </div>
                    <div className='flex flex-row items-center justify-between mt-3'>
                        <Link to={{
                            pathname: '/company/' + event.company_id,
                            state: {company_id: event.company_id}
                        }}>
                            <div className='flex flex-row items-center gap-2 flex-1'>
                                <div>
                                    <p className='text-sm text-slate-500'>
                                        Event hosted by
                                    </p>
                                    <p className='text-md font-semibold'>
                                        {event.company_name}
                                    </p>
                                </div>
                            </div>
                        </Link>
                       
                        <div className='flex flex-row-reverse items-center gap-3'>
                            {(!!event.event_date && !!deadline)  &&
                                <div className='flex flex-col gap-1 items-center'>
                                    <div
                                        className='flex flex-col items-center rounded-lg border-neutral-200 border'>
                                        <p className='body4-bold uppercase p-0.5 px-3 bg-neutral-100'>
                                            {deadline.toFormat('MMM') || 'Error'}
                                        </p>
                                        <p className='body2-bold p-1'>
                                            {deadline.toFormat('dd') || 'Error'}
                                        </p>
                                    </div>
                                    <p className='text-neutral-500 text-2xs'>
                                        Event Date
                                    </p>
                                </div>
                            }
                            {rsvpDate &&
                                <div className='flex flex-col gap-1 items-center'>
                                    <div
                                        className='flex flex-col items-center rounded-lg border-neutral-200 border'>
                                        <p className='body4-bold uppercase p-0.5 px-3 bg-neutral-100'>
                                            {rsvpDate.toFormat('MMM') || 'Error'}
                                        </p>
                                        <p className='body2-bold p-1'>
                                            {rsvpDate.toFormat('dd') || 'Error'}
                                        </p>
                                    </div>
                                    <p className='text-neutral-500 text-2xs'>
                                        RSVP By
                                    </p>
                                </div>
                            }
                        </div>
                        
                    </div>
                    <div className='flex flex-row items-center gap-3 mt-1 mb-3'>
                        {event.exclusive &&
                            <RuDirectLabel className={'mt-1'} label={"RecruitU Partner"}/>
                        }
                        {datePosted.isValid &&
                            <div className='body2-bold blacks-1 flex flex-row justify-between'>
                                <div className='row-ac'>
                                    <SvgClock width={16} height={16}/>
                                    <p className='m-0 ml-8'>
                                        {datePosted.isValid ? `Posted ${datePosted.toRelative()}` : null}
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='col-plain overflow-scroll flex flex-1 gap-3'>
                        {!!Object.values(tracker).length ?
                            <div className={`${SIDEBAR_CARD} mt-5`}>
                                <div className='flex flex-row items-center justify-between'>
                                    <p className='text-lg font-bold'>
                                        Status
                                    </p>
                                    <Tooltip
                                        toggleComponent={
                                            <div className=''>
                                                <Checkbox
                                                    checked={tracker.status}
                                                    onClick={this.toggleCheckbox}
                                                    label={'Attended'}
                                                />
                                            </div>
                                        }
                                        label={'Update the status of this event to track your progress'}
                                    />
                                </div>
                            </div>
                            : null}
                        {!!Object.values(tracker).length &&
                            <NotetakingWidget
                                notes={notes}
                                onSaveNote={this.saveNote}
                                onDeleteNote={this.deleteNote}
                            />
                        }
                        {!!description ?
                            <div className='flex flex-col gap-2'>
                                <p className='text-lg font-bold'>
                                    About this event
                                </p>
                                <div className='flex flex-col flex-1 mb-3'>
                                    <p className='text-sm mt-0 text-slate-500'>
                                        {parse(description)}
                                    </p>
                                </div>
                            </div>
                            : null}
                    </div>
                    <div className='flex-col mt-2 space-y-3'>
                        <div className='row-ac gap-3 flex flex-row items-center w-full'>
                            <Link to={{
                                pathname: '/event/' + listing.id,
                                state: {previousPage: {location: "eventboard", data: {}}}
                            }} className={'flex-1'}>
                                <Button>
                                    View Full Event Page
                                </Button>
                            </Link>
                        </div>
                        {APP_ADMINS.includes(this.props.authUser.uid) && !Object.values(tracker).length ?
                            <Button
                                variant={'secondary'}
                                icon={SvgRemove}
                                className={'w-full'}
                                onClick={this.deleteEvent}>
                                Delete Event
                            </Button>
                            :
                            null
                        }
                    </div>
                </div>
            </SidebarOverlayWrapper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPeek);
