import React, {PureComponent} from "react";
import SvgMagic from "../icons/SvgMagic";
import SvgDocument from "../icons/SvgDocument";
import SvgSeeking from "../icons/SvgSeeking";
import SvgLinkedIn from "../icons/SvgLinkedIn";
import ExperienceListItem from "./ExperienceListItem";
import EducationListItem from "./EducationListItem";
import SvgAddCircle from "../icons/SvgAddCircle";

class ProfilePageExperienceWidget extends PureComponent {

    render() {
        return (
            <div className={!this.props.inPeek && 'card'}>
                {!this.props.inPeek &&
                    <div className='flex flex-row items-center justify-between'>
                        <h3 className={`mt-0 ${this.props.inPeek ? 'body1-bold' : 'title2'} text-neutral-900 mb-2`}>
                            Education
                        </h3>
                    </div>
                }
                {this.props.education?.map((education, i) =>
                    <EducationListItem
                        key={education}
                        education={education}
                        last={i === this.props.education.length - 1}
                        sm={this.props.inPeek}
                        i={i}
                        studentProfilePage={this.props.studentProfilePage}
                        recruiter={this.props.recruiter}/>
                )}
            </div>
        )
    }
}

export default ProfilePageExperienceWidget
