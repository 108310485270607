import axios from './axios';

export const getConstants = async () => {
    try {
        const config = {
            headers: {mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/constants", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}
