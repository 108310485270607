import React, {Component} from "react";
import {connect} from "react-redux";
import VerticalTabs from "./atoms/VerticalTabs";
import {Button} from "../components/atoms/Button";
import WrappedTextInput from "../components/atoms/WrappedTextInput";
import WorkspaceMemberItem from "../components/atoms/WorkspaceMemberItem";
import {getCompany, inviteRecruiter} from "../api/recruiter/companies";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
    };
}

class RecruiterSettingsPage extends Component {
    state = {
        activeTab: 'Workspace',
        company: {},
        newEmail: "",
        errorMessage: null,
    }

    componentDidMount = async () => {
        this.setInfo();
        window.analytics.page("Recruiter Settings Page");
    }

    setInfo = async () => {
        const user = this.props.user || {};
        const res = await getCompany(this.props.authUser);
        this.setState({
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            company: res.company,
        });
    }

    changeActiveTab = (activeTab) => {
        this.setState({activeTab});
    }

    handleInputChange = (field, value) => {
        this.setState({[field]: value});
    }

    checkDataChange = () => {
        const {name, username, email, initialData} = this.state;
        const isDataChanged = name !== initialData.name || username !== initialData.username || email !== initialData.email;
        this.setState({isDataChanged});
    }

    handleSave = () => {
        // Implement save logic here
    }

    handleWorkspaceSave = () => {
        // Implement workspace save logic here
    }

    removeRecruiterInvite = async (recruiter) => {
        if (this.state.company.invites.includes(recruiter.email)) {
            await inviteRecruiter(this.props.authUser, recruiter.email);
            this.setInfo();
        }
    }

    addMember = async () => {
        const company = this.state.company;
        const memberEmails = company.members.map(member => member.email);
        const email = this.state.newEmail + "@" + company.domain;
        if (company.invites.includes(email) || memberEmails.includes(email)) {
            this.setState({errorMessage: "Email has already been invited.", newEmail: ""})
        } else {
            await inviteRecruiter(this.props.authUser, email.replace(" ", ""));
            this.setState({newEmail: ""});
            this.setInfo();
        }
    }

    render() {
        const {firstName, lastName, email, company, newEmail, errorMessage} = this.state;
        const mems = company.members || [];
        const invites = company.invites || [];
        const members = mems.concat(invites.map(invite => {
            return {email: invite}
        }));
        return (
            <div className={`flex flex-col flex-1 p-5 gap-8`}>
                <div className='pt-12 w-full max-w-[800px] mx-auto'>
                    <div className={`flex flex-row min-h-[80vh]`}>
                        <div className='flex flex-col gap-3 min-w-[13vw] relative mr-4'>
                            <div className='absolute flex flex-col flex-1 right-0 left-0 items-stretch'>
                                <div className='fixed'>
                                    <div className='flex flex-row items-center gap-2 mb-8'>
                                        <p className='text-slate-800 text-2xl font-semibold'>
                                            Settings
                                        </p>
                                    </div>
                                    <VerticalTabs
                                        activeTab={this.state.activeTab}
                                        changeTab={this.changeActiveTab}
                                        tabs={['Workspace', 'Personal']}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className=' w-[1px] mb-16 bg-neutral-200 ml-auto mr-5'/>
                        <div className='flex flex-col flex-1 gap-8 min-h-[80vh] overflow-y-scroll items-start pb-32'>
                            {this.state.activeTab === 'Personal' &&
                                <div className='flex flex-col gap-3 w-full '>
                                    <p className='text-xl text-gray-900 mb-2 font-semibold'>
                                        Personal Details
                                    </p>
                                    <div className='flex flex-col border-t border-neutral-200 pt-5 gap-5'>
                                        <WrappedTextInput
                                            outerLabel={'First Name'}
                                            value={firstName}
                                            placeholder={'John'}
                                            className='input-light px-3 w-full'
                                            onChange={(e) => this.handleInputChange('firstName', e.target.value)}
                                            light
                                            disabled
                                        />
                                        <WrappedTextInput
                                            outerLabel={'Last Name'}
                                            value={lastName}
                                            placeholder={'Doe'}
                                            className='input-light px-3 w-full'
                                            onChange={(e) => this.handleInputChange('lastName', e.target.value)}
                                            light
                                            disabled
                                        />
                                        <div>
                                            <WrappedTextInput
                                                outerLabel={'Email'}
                                                value={email}
                                                type={'email'}
                                                placeholder={'johndoe@example.com'}
                                                className='input-light px-3 w-full'
                                                onChange={(e) => this.handleInputChange('email', e.target.value)}
                                                light
                                                disabled
                                            />
                                            {/* <p className='text-xs text-neutral-500 mt-2 dark:text-darkMain-darker'>
                                                Please contact help@recruitu.io to change your login email.
                                            </p> */}
                                        </div>
                                        {/* <Button variant={''}
                                                className='mt-4 mr-auto'
                                                disabled={!isDataChanged}
                                                onClick={null}>
                                            Save
                                        </Button> */}
                                    </div>
                                </div>
                            }

                            {this.state.activeTab === 'Workspace' &&
                                <div className='flex flex-col gap-3 w-full '>
                                    <p className='text-xl text-gray-900 mb-2 font-semibold'>
                                        Workspace Details
                                    </p>
                                    <div className='flex flex-col border-t border-neutral-200 pt-5 gap-5'>
                                        <WrappedTextInput
                                            outerLabel={'Company'}
                                            value={company.name}
                                            placeholder={'Your company name'}
                                            className='input-light px-3 w-full'
                                            onChange={(e) => this.handleInputChange('xxxx', e.target.value)}
                                            light
                                            disabled
                                        />
                                        <WrappedTextInput
                                            outerLabel={'Domain'}
                                            value={company.domain}
                                            placeholder={'Your team domain'}
                                            className='input-light px-3 w-full'
                                            light
                                            disabled
                                        />
                                        {/* <Button variant={''}
                                                className='mt-4 mr-auto'
                                                disabled={!isWorkspaceDataChanged}
                                                onClick={this.handleWorkspaceSave}>
                                            Save
                                        </Button> */}
                                    </div>
                                </div>
                            }
                            {this.state.activeTab === 'Workspace' &&
                                <div className='flex flex-col gap-3 w-full mt-8 '>
                                    <p className='text-lg text-gray-900 mb-2 font-semibold'>
                                        Workspace Members
                                    </p>
                                    <div
                                        className='flex flex-col bg-white border border-neutral-100 rounded-xl p-3 gap-3'>
                                        {members.length === 0 &&
                                            <p className='text-sm text-slate-500 text-center my-3'>
                                                No other members in this workspace yet
                                            </p>
                                        }
                                        {members.map((member, index) =>
                                            <WorkspaceMemberItem key={`member${index}`} member={member}
                                                                 authUser={this.props.authUser} recruiter removeAdmin={this.removeRecruiterInvite}/>
                                        )}
                                    </div>
                                </div>
                            }

                            {this.state.activeTab === 'Workspace' &&
                                <div className='flex flex-col gap-1 w-full mt-8 md:w-3/2'>
                                    <p className='text-lg text-gray-900 mb-2 font-semibold'>
                                        Add Member
                                    </p>
                                    <div className='flex flex-row items-center gap-2'>
                                        <WrappedTextInput
                                            outerLabel={'Member email'}
                                            value={newEmail}
                                            placeholder={'e.g. john'}
                                            className='input-light px-3 w-full'
                                            onChange={(e) => this.handleInputChange('newEmail', e.target.value)}
                                            light
                                        />
                                        <p className='text-sm text-slate-500 mt-5'>
                                            {"@ " + company.domain}
                                        </p>
                                    </div>
                                    <p className='text-sm' style={{color: "red"}}>
                                        {errorMessage}
                                    </p>
                                    <Button variant={''}
                                            className='mt-5 mr-auto'
                                            disabled={!this.state.newEmail}
                                            onClick={this.addMember}>
                                        Send invite
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(RecruiterSettingsPage);
