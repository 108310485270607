import SvgZap from "../../components/icons/SvgZap";
import LinksList from "./LinksList";
import {Link} from "react-router-dom";
import SvgLightbulb from "../../components/icons/SvgLightbulb";
import SvgStars from "../../components/icons/SvgStars";

export const RecruiterDashboardSummary = ({company, jobs, events, recentApplicants, recentAttendees, links, stats}) => {
    return (
        <div className='flex flex-row h-72 rounded-lg gap-2 mt-[-8px]'>
            <div className="flex flex-col flex-[1.25] max-w-[700px] gap-2">
                <Link
                    to={'/company-page'}
                    className='bg-white flex flex-col cursor-pointer hover:bg-white/25 flex-[0.7] shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl'>
                    <div className='flex flex-row flex-wrap items-center justify-between w-full mb-5 gap-3'>
                        <div className='flex flex-row flex-1 items-center gap-2 truncate'>
                                {!company.logo_url ?
                                    <div
                                        className='w-10 h-10 rounded-lg border bg-slate-50 border-slate-200'
                                    />
                                    :
                                    <img
                                        className='w-10 h-10 rounded-lg border border-slate-200'
                                        src={company.logo_url}
                                        alt={'Logo'}
                                    />
                                }
                            <p className='text-3xl text-slate-800 font-semibold truncate mt-3 mb-2'>
                                {company.name}
                            </p>
                        </div>
                        <div className='flex flex-row items-center gap-y-2 ml-auto gap-3'>
                            <div>
                                <p className='text-sm text-slate-500'>
                                    Industry
                                </p>
                                <p className='text-sm font-semibold text-primary'>
                                    {(company.sector || "").slice(0,18) + ((company.sector || "").length > 18 ? '...' : '') || "--"}
                                </p>
                            </div>
                            <div>
                                <p className='text-sm text-slate-500'>
                                    Founded
                                </p>
                                <p className='text-sm font-semibold text-primary'>
                                    {company.founded || "--"}
                                </p>
                            </div>
                            <div>
                                <p className='text-sm text-slate-500'>
                                    People
                                </p>
                                <p className='text-sm font-semibold text-primary'>
                                    {company.num_employees}
                                </p>
                            </div>
                        </div>
                    </div>
                </Link>
                <div className='flex flex-row gap-2 flex-[1]'>
                    <Link to={'/saved-lists?active_tab=Students'}
                        className='bg-white flex flex-col justify-end flex-[1] cursor-pointer hover:bg-white/25 shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl'>
                        <p className='text-slate-800 text-5xl'>
                            {stats.num_users}
                        </p>
                        <p className='text-slate-500 text-sm'>
                            Student Lists
                        </p>
                    </Link>
                    <Link to={'/saved-lists?active_tab=Clubs'}
                        className='bg-white flex flex-col justify-end flex-[1] cursor-pointer hover:bg-white/25 shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl'>
                        <p className='text-slate-800 text-5xl'>
                            {stats.num_clubs}
                        </p>
                        <p className='text-slate-500 text-sm'>
                            Club Lists
                        </p>
                    </Link>
                    <Link to={'/saved-lists?active_tab=Smart+Lists'}
                        className='bg-white flex flex-col justify-end flex-[1] cursor-pointer hover:bg-white/25 shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl'>
                        <p className='text-slate-800 text-5xl'>
                            {stats.num_smart}
                        </p>
                        <p className='text-slate-500 text-sm'>
                            Smart Lists
                        </p>
                    </Link>            
                </div>
            </div>

                {/* <div
                    className={` flex-col flex-1 p-5 bg-slate-100 rounded-lg`}>
                    <div className='flex flex-row items-center justify-between mb-3 border-b border-slate-200 pb-2'>
                        <div className='flex flex-row gap-1 text-primary'>
                            <SvgLightbulb className={'w-4 h-4'}/>
                            <p className='font-semibold -mt-[4px]'>
                                Insights
                            </p>
                        </div>
                    </div>
                    <p className='mb-1'>
                        <span className='text-primary font-semibold'>{stats.num_user}</span> Student Lists
                    </p>
                    <p>
                        <span className='text-primary font-semibold'>{stats.num_club}</span> Club Lists
                    </p>
                    <p className="flex flex-row gap-1">
                        <SvgStars className={'text-primary'}/>
                        <span className='text-primary font-semibold'>{stats.num_smart}</span> Smart Lists
                    </p>
                </div> */}
            
            <div className='flex flex-col gap-2 flex-[.4]'>
                <Link to={'/engage'}
                      className='bg-white flex flex-col justify-end flex-[1] cursor-pointer hover:bg-white/25 shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl'>
                    <p className='text-slate-800 text-5xl'>
                        {events.length}
                    </p>
                    <p className='text-slate-500 text-sm'>
                        Listed Events
                    </p>
                </Link>
                <Link to={'/engage'}
                      className='bg-white flex flex-col justify-end flex-[1] cursor-pointer hover:bg-white/25 shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl'>
                    <p className='text-slate-800 text-5xl'>
                        {jobs.length}
                    </p>
                    <p className='text-slate-500 text-sm'>
                        Listed Jobs
                    </p>
                </Link>
                
            </div>
            <div className='flex flex-col gap-2 flex-[.4]'>
                <Link to={'/events'}
                      className='bg-white flex-[1] flex flex-col justify-end cursor-pointer hover:bg-white/25 shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl'>
                    <p className='text-slate-800 text-5xl'>
                        {recentAttendees.length}
                    </p>
                    <p className='text-slate-500 text-sm'>
                        Candidate RSVPs
                    </p>
                </Link>
                <Link to={'/jobs'}
                      className='bg-white flex-[1] flex flex-col justify-end cursor-pointer hover:bg-white/25 shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl'>
                    <p className='text-slate-800 text-5xl'>
                        {recentApplicants.length}
                    </p>
                    <p className='text-slate-500 text-sm'>
                    Candidates Applied
                    </p>
                </Link>
            </div>
            <div
                className='bg-white flex-[.7] flex-col gap-3 shadow-lg shadow-slate-200 p-5 border border-primary outline outline-offset-2 outline-slate-200 rounded-xl hidden lg:flex'>
                <div className='flex flex-row items-center gap-2 text-primary'>
                    <SvgZap className={'w-5 h-5'}/>
                    <p className='text-xl font-semibold'>
                        Quick links
                    </p>
                </div>
                <div className='flex flex-col gap-2 flex-1 overflow-y-scroll'>
                    <LinksList links={links}/>
                </div>
            </div>
        </div>
    );
};
