import React from "react";


function OnboardingStepWrapper({children}) {
    return (
        <div className='md:mx-auto md:w-5/6 flex flex-col gap-3 md:gap-10 overflow-x-hidden overflow-y-auto md:overflow-auto'>
            {children}
        </div>
    )
}

export default OnboardingStepWrapper
