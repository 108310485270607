import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import WrappedTextInput from "../../components/atoms/WrappedTextInput";
import DatePicker from "react-datepicker";
import {uploadDoc} from "../../api/firebase";
import Dropzone from "react-dropzone";
import SvgPlus from "../../components/icons/SvgPlus";
import {editCompany} from "../../api/recruiter/companies";
import {connect} from "react-redux";
import {v4 as uuid} from 'uuid';
import {Button} from "../../components/atoms/Button";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class RecruiterCompanyAddUpdate extends Component {
    state = {
        title: '',
        description: '',
        category: '',
        date: '',
        coverPhoto: '',
    }

    closeModal = () => {
        this.props.closeModal()
    }

    handleInputChange = (e, inputName) => {
        this.setState({[inputName]: e.target.value});
    };

    handleDateChange = (e, stateKey) => {
        this.setState({[stateKey]: e})
    };

    save = async () => {
        const updates = this.props.company.updates || [];
        updates.push({
            date: this.state.date,
            category: this.state.category,
            cover_photo: this.state.coverPhoto,
            title: this.state.title,
            description: this.state.description,
        })
        await editCompany(this.props.authUser, {updates});
        this.props.closeModal();
        window.location.reload()
    }

    onDrop = async (acceptedFiles) => {
        const newUuid = await uuid()
        const url = await uploadDoc("companies/updates/" + this.props.company.id, newUuid, acceptedFiles[0]);
        this.setState({coverPhoto: url});
    }

    render() {
        const saveDisabled = false
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Add update'}
                headerToggle
                size={'lg'}
                toggle={this.closeModal}
                showScrollBar
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={this.closeModal} variant='secondary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={saveDisabled}
                            onClick={this.save}>
                            Save Changes
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div className='flex flex-col gap-5'>
                    <Dropzone onDrop={(files) => this.onDrop(files)}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className='cursor-pointer'>
                                <input {...getInputProps()} />
                                <div style={{}} className=''>
                                    {!!this.state.coverPhoto ? (
                                        <div
                                            className='h-36 w-1/2 bg-slate-50 hover:border-blue-500 cursor-pointer overflow-hidden flex flex-col items-center justify-center border border-gray-200 rounded-lg'
                                        >
                                            <img
                                                className='h-36 w-1/2  bg-white cursor-pointer object-cover  rounded-lg'
                                                src={this.state.coverPhoto}
                                                alt="profile-pic"
                                            />
                                            {/* <div className='absolute flex flex-col items-center justify-center'>
                                                        <SvgEdit className={'text-blue-500'}/>
                                                        <p className='text-xs mt-2'>
                                                            Edit image
                                                        </p>
                                                    </div> */}
                                        </div>
                                    ) : (
                                        <div
                                            className='h-32 w-1/2 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square flex flex-col items-center justify-center border border-gray-200 rounded-lg'
                                        >
                                            <SvgPlus className={'text-blue-500'}/>
                                            <p className='text-xs mt-2'>
                                                Add cover photo
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </Dropzone>
                    <WrappedTextInput
                        type="text"
                        outerLabel={'Title'}
                        placeholder={'Update title'}
                        className='input-light px-3 w-full'
                        value={this.state.title}
                        onChange={(e) => this.handleInputChange(e, 'title')}
                    />
                    <div className='flex flex-col gap-1.5'>
                        <div className='text-xs font-medium text-slate-500'>
                            Description
                        </div>
                        <textarea
                            name="description"
                            value={this.state.description}
                            onChange={(e) => this.handleInputChange(e, 'description')}
                            placeholder={'Update description'}
                            className='input-light text-sm px-3 py-3 base-black-50 w-full min-h-[120px]'
                        />
                    </div>
                    <div className='flex flex-row items-center gap-3'>
                        <div className='w-full flex flex-1'>
                            <div className='text-xs font-medium text-slate-500 mb-1'>
                                Date
                            </div>
                            <DatePicker
                                className={'px-3 input-light body2 w-full flex-1'}
                                placeholderText={'Update date'}
                                selected={this.state.date}
                                showYearDropdown
                                onChange={(e) => this.handleDateChange(e, 'date')}
                            />
                        </div>
                        <div className='w-full flex flex-1'>
                            <WrappedTextInput
                                type="text"
                                outerLabel={'Category'}
                                placeholder={'e.g. Recruiting'}
                                className='input-light px-3 w-full'
                                value={this.state.company}
                                onChange={(e) => this.handleInputChange(e, 'category')}
                            />
                        </div>
                    </div>
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(RecruiterCompanyAddUpdate);
