import React, {useEffect, useRef, useState} from "react";
import {Button} from "../atoms/Button";
import SvgChevronDown from "../icons/ChevronDown";
import SvgLightbulb from "../icons/SvgLightbulb";

function CompanyFunFacts({items, name}) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [startPosition, setStartPosition] = useState(0);
    const containerRef = useRef(null);
    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
    };

    const handleTouchStart = (e) => {
        setStartPosition(e.touches[0].clientX);
        setIsDragging(true);
    };

    const handleTouchMove = (e) => {
        if (!isDragging) return;
        const currentPosition = e.touches[0].clientX;
        const delta = startPosition - currentPosition;
        containerRef.current.style.transform = `translateX(calc(-${currentIndex * 75}% - ${delta}px))`;
    };

    const handleTouchEnd = (e) => {
        setIsDragging(false);
        const currentPosition = e.changedTouches[0].clientX;
        const delta = startPosition - currentPosition;
        if (delta > 50) {
            handleNext();
        } else if (delta < -50) {
            handlePrev();
        } else {
            containerRef.current.style.transform = `translateX(-${currentIndex * 75}%)`;
        }
    };

    useEffect(() => {
        if (!isDragging) {
            containerRef.current.style.transition = 'transform 0.5s';
            containerRef.current.style.transform = `translateX(-${currentIndex * 75}%)`;
        } else {
            containerRef.current.style.transition = 'none';
        }
    }, [currentIndex, isDragging]);
    return (
        <div className='relative w-full p-5 rounded-lg card bg-primary text-white'>
            <div className='flex flex-row items-center justify-between mb-3 -mt-1'>
                <div className='flex flex-row items-center gap-1'>
                    <SvgLightbulb className={'text-white'}/>
                    <p className='font-bold text-lg text-white'>
                        Fun Facts
                    </p>
                </div>
                <div className='flex flex-row items-center justify-center gap-3'>
                    <div className='left-0 top-1/2 transform'>
                        <Button onClick={handlePrev} variant={'white'}><SvgChevronDown
                            className='rotate-90 scale-[1.5]'/></Button>
                    </div>
                    <div className='right-0 top-1/2 transform'>
                        <Button onClick={handleNext} variant={'white'}><SvgChevronDown
                            className='-rotate-90 scale-[1.5]'/></Button>
                    </div>
                </div>
            </div>
            <div className='relative overflow-hidden'>
                <div
                    ref={containerRef}
                    className='flex transition-transform duration-500 relative'
                    style={{transform: `translateX(${currentIndex * 75}%)`}}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    {items.map((item, index) => (
                        <div key={index} className='w-3/4 flex-shrink-0 p-5 ml-20 border border-slate-200 rounded-lg'
                             style={{opacity: index === currentIndex ? 1 : 0.5}}>
                            <p className='text-base mb-1'>{item}</p>
                        </div>
                    ))}
                </div>
                <div className='absolute top-0 bottom-0 right-0 w-20'
                     style={{background: 'linear-gradient(to right, #3043A700, #3043A7)'}}
                />
                <div className='absolute top-0 bottom-0 left-0 w-12'
                     style={{background: 'linear-gradient(to left, #3043A700, #3043A7)'}}
                />
            </div>
        </div>
    );
}

export default CompanyFunFacts;
