import React, {PureComponent} from "react";

class RecruiterSideStatusDisplay extends PureComponent {


    switchColor = (value) => {
        switch (value) {
            case 'Applied':
                return 'blue'
            case 'Interviewing':
                return 'emerald'
            case 'Rejected':
                return 'orange'
            case 'Offered':
                return 'pink'
            default:
                return 'slate'
        }
    }

    render() {
        const color = this.switchColor(this.props.value);
        return (

            <div className='flex flex-row items-start cursor-pointer'>
                <div className={`
                   text-${color}-500 bg-${color}-500 bg-opacity-[0.1] border-[0.5px] border-${color}-500 border-opacity-[.3]
                    text-xs font-semibold px-2 py-0 flex flex-row items-center gap-2 rounded-full 
                    `}>
                    <p className=' whitespace-nowrap'>
                        {this.props.value || this.props.placeholder || 'Not saved'}
                    </p>
                </div>
            </div>
        )
    }
}

export default RecruiterSideStatusDisplay
