import React from "react";
import { useSelector } from "react-redux";
import { Button } from "./Button";
import SvgCopy from "../icons/SvgCopy";
import SvgCheck from "../icons/SvgCheck";

export default function CopyReferralButton({size, className, variant = 'secondary'}) {
    const [copied, setCopied] = React.useState(false);
    const user = useSelector((state) => state.userReducer.user);

    const username = user?.username;

    const copyLink = () => {
        if (!username) {
            return;
        }
        setCopied(true);
        navigator.clipboard.writeText(
            `https://app.recruitu.com/authentication?activeTab=signup&referred=${username}`
        );
        setTimeout(() => setCopied(false), 1000);
    };

    return (
        <div className="flex flex-row items-center gap-3">
            <Button variant={variant} className={className} onClick={copyLink} size={size}>
                {copied ? "Link copied!" : "Copy my link"}
                {copied ? (
                    <SvgCheck className={"w-5 h-5"} />
                ) : (
                    <SvgCopy className={"w-5 h-5"} />
                )}
            </Button>
        </div>
    );
}
