

import axios from '../axios';

export const getCollege = async (user, college_name) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {college_name}
        };
        const res = await axios.get("/users/college", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const admitUserToClub = async (user, user_id, club_id, application_submission_id, approval_decision) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            user_id, 
            club_id, 
            application_submission_id, 
            approval_decision
        }
        const res = await axios.put("/users/club/admit", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}