import SvgThumbUp from "../icons/SvgThumbUp";
import SvgClock from "../icons/SvgClock";
import SvgThumbDown from "../icons/SvgThumbDown";
import SvgNot from "../icons/SvgNot";
import SvgBriefcase from "../icons/SvgBriefcase";
import SvgBinoculars from "../icons/SvgBinoculars";

export const HAS_JOB_OPTIONS = [
    {
        label: 'I have a job or internship',
        Icon: SvgBriefcase
    },
    {
        label: "I do not",
        Icon: SvgNot
    },
];

export const ACTIVELY_LOOKING_OPTIONS = [
    {
        label: 'Actively looking for a job',
        title: 'Actively looking for a job',
        Icon: SvgBinoculars
    },
    {
        label: 'Open to opportunities',
        title: 'Open to opportunities',
        Icon: SvgClock
    },
    {
        label: 'Not looking right now',
        title: 'Not looking right now',
        Icon: SvgNot
    },
];

export const GET_MATCHES_STEPS = ['Tell us about your job preferences', 'Add some info about you', 'Get matched with top roles in finance']

export const SCHOOLS = [
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnl4pQV9mHo6lsYfa3R-W51pcArmLk94f_95PShZe1g1PrzimCslKCO2fRJfjtj6Q0UQg&usqp=CAU',
    'https://ih1.redbubble.net/image.2646381103.7570/raf,360x360,075,t,fafafa:ca443f4786.jpg',
    'https://styles.redditmedia.com/t5_2s3vu/styles/communityIcon_r79v5z6jol331.jpg?format=pjpg&s=6fe1135d18909f4df9875c148bc4033bffae81bf',
    'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/License_icon-mit.svg/256px-License_icon-mit.svg.png',
    'https://d2q79iu7y748jz.cloudfront.net/s/_squarelogo/256x256/2553df01b37e7cd3be0469a91037da30'
]

export const COMPANY_IDS_1 = [
    "37241",
    "20187",
    "12493",
    "25264",
    "34210",
    "28640",
    "14620",
    "119199"
];

export const COMPANY_IDS_2 = [
    "26803",
    "8757",
    "570886",
    "18064",
    "13661",
    "14072",
    "22502",
    "43185"
];

export const SEEKING_STEPS = [
    {label: 'Your background', startStep: 4, untilStep: 9},
    {label: 'Your preferences', startStep: 9, untilStep: 11},
    {label: 'See your matches', startStep: 11, untilStep: 13}
]

export const TESTIMONIALS = [
    {
        text: "RecruitU addresses all my needs, offering excellent capabilities for tracking my network, applications and deadlines seamlessly. RecruitU’s club featurs allowed me to connect with peers and alumni to expand my network. Thanks to RecruitU, I easily managed every step up to the Superday. I strongly believe RecruitU is an essential tool for anyone pursuing a career in high finance.",
        name: "Parker Duncan",
        since: "Auburn '26, IB Summer Analyst at Piper Sandler"
    },
    {
        text: "To anybody thinking about recruiting for a role in finance, I wouldn't even proceed without first signing up for RecruitU. RecruitU seamlessly integrates all of the aspects of recruitment for competive roles in a one-stop-shop. I cannot recommend this platform more and wish I knew about it earlier in my recruitment process.",
        name: "Jake Zober",
        since: "USC '25, IB Summer Analyst at Houlihan Lokey"
    },
    {
        text: "RecruitU is the first platform that understands how recruitment truly works - not only do they find personalized opportunities for users, but they also track your network and keep you on top of deadlines. Now as a club president and mentor to many underclassmen, I’ve seen firsthand the impact of RecruitU.",
        name: "Fiona L",
        since: "Pomona College '25, IB Summer Analyst at Greenhill"
    },
    {
        text: "From streamlined applications to personalized job suggestions and intelligent networking tools, RecruitU equips students to find the perfect fit and build connections in their field. We highly recommend RecruitU for any club or organization looking to empower their student members' career success.",
        name: "Dag Workayehu",
        since: "Virginia Tech '25, Global Markets Sales & Trading at BoA"
    },
    {
        text:'The standout feature of RecruitU is its innovative networking component, which allows users to systematically maintain connections and track contacts with specific firms, all in one location. RecruitU helped me not only get the perfect job, but also helped me cultivate strong relationships.',
        name: 'Sabrina Katz',
        since:"UT Austin '26, Investment Banking Summer Analyst at Morgan Stanley"
    }
];


export const ONBOARDING_CITIES = [
    {city: 'New York', state: 'NY'},
    {city: 'Boston', state: 'MA'},
    {city: 'San Francisco', state: 'CA'},
    {city: 'Chicago', state: 'IL'},
    {city: 'Los Angeles', state: 'CA'},
    {city: 'Washington', state: 'DC'},
    {city: 'Houston', state: 'TX'},
    {city: 'Dallas', state: 'TX'},
    {city: 'Atlanta', state: 'GA'},
    {city: 'Charlotte', state: 'NC'},
    {city: 'Miami', state: 'FL'},
    {city: 'Philadelphia', state: 'PA'},
    {city: 'Seattle', state: 'WA'},
    {city: 'Denver', state: 'CO'},
    {city: 'San Diego', state: 'CA'},
    {city: 'Minneapolis', state: 'MN'},
    {city: 'Phoenix', state: 'AZ'},
    {city: 'Pittsburgh', state: 'PA'},
    {city: 'Detroit', state: 'MI'},
    {city: 'Tampa', state: 'FL'},
    {city: 'St. Louis', state: 'MO'},
    {city: 'Cleveland', state: 'OH'},
    {city: 'Indianapolis', state: 'IN'},
    {city: 'Baltimore', state: 'MD'},
    {city: 'Kansas City', state: 'MO'},
    {city: 'Nashville', state: 'TN'},
    {city: 'Portland', state: 'OR'},
    {city: 'San Antonio', state: 'TX'},
    {city: 'Raleigh', state: 'NC'},
    {city: 'Salt Lake City', state: 'UT'},
    {city: 'Columbus', state: 'OH'},
    {city: 'Orlando', state: 'FL'},
    {city: 'Milwaukee', state: 'WI'},
    {city: 'Austin', state: 'TX'},
    {city: 'Richmond', state: 'VA'},
    {city: 'Cincinnati', state: 'OH'},
    {city: 'Las Vegas', state: 'NV'},
    {city: 'Louisville', state: 'KY'},
    {city: 'Buffalo', state: 'NY'},
    {city: 'Hartford', state: 'CT'},
    {city: 'New Orleans', state: 'LA'},
    {city: 'Birmingham', state: 'AL'},
    {city: 'Des Moines', state: 'IA'},
    {city: 'Memphis', state: 'TN'},
    {city: 'Oklahoma City', state: 'OK'},
    {city: 'Providence', state: 'RI'},
    {city: 'Jacksonville', state: 'FL'},
    {city: 'Norfolk', state: 'VA'},
    {city: 'Albany', state: 'NY'},
    {city: 'Rochester', state: 'NY'}
];

export const INDUSTRY_OPTIONS = [
    "Investment Banking",
    "Sales & Trading",
    "Equity Research",
    "Private Equity / Venture Capital",
    "Asset Management",
    "Management Consulting",
    "Wealth Management",
    "Quant",
    "Other"
];

export const POPULAR_COMPANIES = [
    {id: 24986, name: 'Evercore'},
    {id: 13871, name: 'Guggenheim Partners'},
    {id: 39593, name: 'The Carlyle Group'},
    {id: 18383, name: 'JPMorgan Chase & Co.'},
    {id: 14072, name: 'Goldman Sachs'},
    {id: 17420, name: 'Deutsche Bank'},
    {id: 12493, name: 'Bank of America'},
    {id: 13661, name: 'Morgan Stanley'},
    {id: 26803, name: 'Centerview Partners'},
    {id: 25264, name: 'Lazard, Inc.'},
    {id: 42487, name: 'PJT Partners'},
    {id: 29595, name: 'Moelis & Company'},
    {id: 44145, name: 'Qatalyst Partners'},
    {id: 39292, name: 'Jefferies'},
    {id: 22502, name: 'Citi'},
    {id: 37241, name: 'Barclays'},
    {id: 30881, name: 'Rothschild & Co'},
    {id: 17758, name: 'Greenhill & Co.'},
    {id: 28461, name: 'Houlihan Lokey'},
    {id: 56420, name: 'UBS'},
    {id: 16634, name: 'William Blair'},
    {id: 17839, name: 'Piper Sandler'},
    {id: 85719, name: 'LionTree'},
    {id: 17236, name: 'RBC Capital Markets'}
];

export const MATCHES = [
    {
        "application_questions": [],
        "apply_direct": "",
        "candidate_reqs": {},
        "city": [
            "Franklin"
        ],
        "class_years": [
            "2025"
        ],
        "club_id": "",
        "clubs": [],
        "company_id": "119199",
        "company_name": "Northwestern Mutual",
        "created_at": "2024-06-17T17:12:00.030115+00:00",
        "creator": "",
        "date_posted": "2024-06-17T13:10:27.434-0400",
        "deadline": "",
        "deprecated": false,
        "description": "As an Investment Operations Intern, you will work alongside your hiring manager, mentor, and team to complete tasks critical for Northwestern Mutual's success. We are here to help you both identify and continue to develop your future career goals and passions. nternship candidates can expect a part-time (minimum of 10 hours per week) onsite internship program, running from September 2024 to May 2025. Internship candidates must be attending a Milwaukee area college or university to continue part-time during the school year in Fall.",
        "exclusive": false,
        "gpa": "",
        "id": "df4060c2-f651-dc01-3c1d-6e1fdb10bbca381954b201b9697b5eaddf05b91cbcfc",
        "industry": "Other",
        "link": "https://careers.northwesternmutual.com/corporate-careers/job/investment-operations-intern-fall-2024-franklin-wi-corporate-jr-41011/",
        "majors": [],
        "name": "INVESTMENT OPERATIONS INTERN, FALL 2024",
        "openings": 0,
        "salary": "",
        "schools": [],
        "scope": "public",
        "state": [
            "WI"
        ],
        "status": "",
        "tags": [],
        "type": "Internship",
        "work_model": ""
    },
    {
        "application_questions": [],
        "apply_direct": "",
        "candidate_reqs": {},
        "city": [
            "Milwaukee"
        ],
        "class_years": [
            "2025"
        ],
        "club_id": "",
        "clubs": [],
        "company_id": "119199",
        "company_name": "Northwestern Mutual",
        "created_at": "2024-06-17T17:10:26.800426+00:00",
        "creator": "",
        "date_posted": "2024-06-17T13:00:25.857-0400",
        "deadline": "",
        "deprecated": false,
        "description": "As a Public Investments intern, you will work alongside your hiring manager, mentor, and team to complete tasks critical for Northwestern Mutual's success. We are here to help you both identify and continue to develop your future career goals and passions. The   Public Investments Department   within Northwestern Mutual Investment Management Company, LLC (NMIMC) manages the public markets investments of Northwestern Mutual, with more than $100 billion under management in Investment Grade Corporate, Structured Product, Emerging Market, High Yield Corporate, Government, Municipal, and related Derivative assets.  Compensation Range:\n\nMinimum:\n\n$16.50\nMidpoint:\n\n$23.25\nMaximum:\n\n$30.00\nPay rates for internships are determined based on academic tenure and major.",
        "exclusive": false,
        "gpa": "",
        "id": "11c486b9-c658-523e-66ab-38e325aea1b48213ba60724520f83043dcea638f9238",
        "industry": "Other",
        "link": "https://careers.northwesternmutual.com/corporate-careers/job/public-investments-credit-investment-internship-summer-2025-milwaukee-wi-corporate-jr-40868/",
        "majors": [],
        "name": "PUBLIC INVESTMENTS - CREDIT INVESTMENT INTERNSHIP, SUMMER 2025",
        "openings": 0,
        "salary": "",
        "schools": [],
        "scope": "public",
        "state": [
            "WI"
        ],
        "status": "",
        "tags": [],
        "type": "Internship",
        "work_model": ""
    },
    {
        "application_questions": [],
        "apply_direct": "",
        "candidate_reqs": {},
        "city": [
            "Whippany"
        ],
        "class_years": [
            "2025"
        ],
        "club_id": "",
        "clubs": [],
        "company_id": "37241",
        "company_name": "Barclays",
        "created_at": "2024-06-17T17:00:25.235028+00:00",
        "creator": "",
        "date_posted": "2024-06-17T12:59:09.694-0400",
        "deadline": "",
        "deprecated": false,
        "description": "As a Barclays Technical Business Analyst, you will be joining the global team responsible for executing transformational initiatives that help Markets Sales realize aggressive client growth targets. You will collaborate with business users and senior managers to clarify requirements and drive delivery through the organization. You will face off directly to business and technology stakeholders while also leading various Trading technology initiatives to ensure Sales technology strategy is consistent.\n\nBarclays is one of the world's largest and most respected financial institutions, established in 1690, with a legacy of success, quality, and innovation. We've helped millions of individuals and businesses thrive, creating financial and digital solutions that the world now takes for granted. An important and growing presence in the USA, we offer careers providing endless opportunity.",
        "exclusive": false,
        "gpa": "",
        "id": "1d2ed108-7042-4a55-c0b3-a24832af8bc91314049b485577f88400d8e3950e3c72",
        "industry": "Other",
        "link": "https://search.jobs.barclays/job/whippany/technical-business-analyst/13015/63791087984#anchor-description",
        "majors": [],
        "name": "Technical Business Analyst",
        "openings": 0,
        "salary": "",
        "schools": [],
        "scope": "public",
        "state": [
            "NJ"
        ],
        "status": "",
        "tags": [],
        "type": "Full-Time",
        "work_model": ""
    },
    {
        "application_questions": [],
        "apply_direct": "",
        "candidate_reqs": {},
        "city": [
            "New York"
        ],
        "class_years": [
            "2025"
        ],
        "club_id": "",
        "clubs": [],
        "company_id": "37241",
        "company_name": "Barclays",
        "created_at": "2024-06-17T16:59:09.142353+00:00",
        "creator": "",
        "date_posted": "2024-06-17T12:58:16.025-0400",
        "deadline": "",
        "deprecated": false,
        "description": "As a Barclays Equity Research Analyst, Software, you’ll be responsible for reviewing business and trade publications, annual reports, financial filings, and other sources to gather, synthesize and interpret data on companies followed by the business. This is an exciting opportunity to enjoy a diverse range of work and expand your experience as you support and prepare research reports that vary in size and scope. With the experience and depth of resources, Global Equity Research provides insightful analysis of global stock markets and companies.\n\nSalary / Rate Minimum: 125,000\nSalary / Rate Maximum: 175,000",
        "exclusive": false,
        "gpa": "",
        "id": "d2f68e23-cad6-f4ea-6294-98fb678e99dbaa10760d1f2f3f9e50dd970d7a0b69cc",
        "industry": "Equity Research",
        "link": "https://search.jobs.barclays/job/new-york/equity-research-analyst/13015/65163379744",
        "majors": [],
        "name": "Equity Research Analyst",
        "openings": 0,
        "salary": "",
        "schools": [],
        "scope": "public",
        "state": [
            "NY"
        ],
        "status": "",
        "tags": [],
        "type": "Full-Time",
        "work_model": ""
    },
    {
        "application_questions": [],
        "apply_direct": "",
        "candidate_reqs": {},
        "city": [
            "New York"
        ],
        "class_years": [
            "2025"
        ],
        "club_id": "",
        "clubs": [],
        "company_id": "37241",
        "company_name": "Barclays",
        "created_at": "2024-06-17T16:58:15.476433+00:00",
        "creator": "",
        "date_posted": "2024-06-17T12:57:27.641-0400",
        "deadline": "",
        "deprecated": false,
        "description": "As a Barclays Special Situations Analyst, you’ll support Senior Analysts within certain industry verticals. This is a career-track opportunity with the possibility of developing into a revenue-generating Senior Analyst on our team. You will collaborate with internal and external parties to support the business, including our sales and/or sourcing teams, or working across product areas. External interactions include working with clients, management teams, financial advisors and legal advisors to maintain up-to-date analysis of relevant credits.\n\nSalary / Rate Minimum: $155,000\nSalary / Rate Maximum: $250,000",
        "exclusive": false,
        "gpa": "",
        "id": "d709c22f-11ee-4884-199e-61197a302dd8589a243b0ec2938cfae83af4e5669562",
        "industry": "Other",
        "link": "https://search.jobs.barclays/job/new-york/special-situations-analyst/13015/63729665344",
        "majors": [],
        "name": "Special Situations Analyst",
        "openings": 0,
        "salary": "",
        "schools": [],
        "scope": "public",
        "state": [
            "NY"
        ],
        "status": "",
        "tags": [],
        "type": "Full-Time",
        "work_model": ""
    },
    {
        "application_questions": [],
        "apply_direct": "",
        "candidate_reqs": {},
        "city": [
            "New York"
        ],
        "class_years": [
            "2025"
        ],
        "club_id": "",
        "clubs": [],
        "company_id": "37241",
        "company_name": "Barclays",
        "created_at": "2024-06-17T16:57:27.056520+00:00",
        "creator": "",
        "date_posted": "2024-06-17T12:56:31.212-0400",
        "deadline": "",
        "deprecated": false,
        "description": "As a Barclays Assistant Vice President, Strategy and Business Development, you will be focusing on Business Development and supporting the Head of Business Management Americas. This role will be critical to building Corporate Banking capabilities and growing the US ICB franchise. You will focus on strategy, high profile projects/activity, management information, and governance environment. International Corporate Banking (\"ICB\") has diverse globally connected relationship managers based primarily based in UK, Europe, US, Asia and Middle East.  \n\nSalary / Rate Minimum: $105,000\nSalary / Rate Maximum: $160,000",
        "exclusive": false,
        "gpa": "",
        "id": "543ffcc0-cb9d-1170-9967-3f34fde500dcfa0b498ce1374e66e490d11e56a5d32c",
        "industry": "Other",
        "link": "https://search.jobs.barclays/job/new-york/strategy-and-business-development/13015/65191386432",
        "majors": [],
        "name": "Strategy and Business Development",
        "openings": 0,
        "salary": "",
        "schools": [],
        "scope": "public",
        "state": [
            "NY"
        ],
        "status": "",
        "tags": [],
        "type": "Full-Time",
        "work_model": ""
    },
    {
        "application_questions": [],
        "apply_direct": "",
        "candidate_reqs": {},
        "city": [
            "New York"
        ],
        "class_years": [
            "2025"
        ],
        "club_id": "",
        "clubs": [],
        "company_id": "37241",
        "company_name": "Barclays",
        "created_at": "2024-06-17T16:56:30.545540+00:00",
        "creator": "",
        "date_posted": "2024-06-17T12:33:22.725-0400",
        "deadline": "",
        "deprecated": false,
        "description": "As a Barclays Portfolio Manager Sector Analyst, you will join the Portfolio Management Team within the Corporate and Investment Bank Lending business. This team manages the bank’s corporate loan exposures across all asset classes, and this critical team acts as a gatekeeper and voting member within the firm’s capital commitment process. You will have the opportunity to participate in underwriting diligence processes, and master fundamental credit analysis skills.  Salary / Rate Minimum: 125,000\nSalary / Rate Maximum: 175,000",
        "exclusive": false,
        "gpa": "",
        "id": "7f473ca9-8f69-0ea4-c1eb-30ebfdb5870bd2c39a77dca65617c43a718e9815e060",
        "industry": "Other",
        "link": "https://search.jobs.barclays/job/new-york/portfolio-management-sector-analyst/13015/65152864464",
        "majors": [],
        "name": "Portfolio Management Sector Analyst",
        "openings": 0,
        "salary": "",
        "schools": [],
        "scope": "public",
        "state": [
            "NY"
        ],
        "status": "",
        "tags": [],
        "type": "Full-Time",
        "work_model": ""
    },
    {
        "application_questions": [],
        "apply_direct": "",
        "candidate_reqs": {},
        "city": [
            "Clark"
        ],
        "class_years": [
            "2025"
        ],
        "club_id": "",
        "clubs": [],
        "company_id": "13070",
        "company_name": "Loop Capital Markets, LLC",
        "created_at": "2024-06-17T16:33:22.069183+00:00",
        "creator": "",
        "date_posted": "2024-06-17T12:32:18.031-0400",
        "deadline": "",
        "deprecated": false,
        "description": "Loop Capital is a full-service investment bank, brokerage and advisory firm that provides creative capital solutions for corporate, governmental and institutional entities across the globe. Our reputation for integrity and service - coupled with our track record of success - has allowed us to serve an expanding number of clients from coast to coast. We continue to grow because clients continue to ask us to do more for them, and our uncompromising commitment to excellence means that clients get superior, focused service across our entire platform. The objective of this position is to be a Junior Trader on the Municipal Bond Desk working with other Traders, Salespeople and interacting with clients.",
        "exclusive": false,
        "gpa": "",
        "id": "5606b3ca-5298-8e02-d385-12f8d046b6775d2129c5c4c4c29ec196b497fe8c28d0",
        "industry": "Other",
        "link": "https://www.loopcapital.com/careers/professionals/?gnk=job&gni=8a7887a18f504183018f72ff8b05150c&lang=en",
        "majors": [],
        "name": "Junior Trader – Fixed Income, Municipal Trading",
        "openings": 0,
        "salary": "",
        "schools": [],
        "scope": "public",
        "state": [
            "NJ"
        ],
        "status": "",
        "tags": [],
        "type": "Full-Time",
        "work_model": ""
    },
    {
        "application_questions": [],
        "apply_direct": "",
        "candidate_reqs": {},
        "city": [
            "Chicago"
        ],
        "class_years": [
            "2025"
        ],
        "club_id": "",
        "clubs": [],
        "company_id": "13070",
        "company_name": "Loop Capital Markets, LLC",
        "created_at": "2024-06-17T16:32:17.471588+00:00",
        "creator": "",
        "date_posted": "2024-06-17T12:30:55.773-0400",
        "deadline": "",
        "deprecated": false,
        "description": "The objective of this position is to provide in-depth technical analysis and support in the delivery of valuation, due diligence and litigation related services for management planning, mergers and acquisitions (M&A), financial reporting, tax and regulatory compliance, dispute advisory, restructuring, reorganization and corporate strategy.",
        "exclusive": false,
        "gpa": "",
        "id": "8d1ab677-da78-deda-0944-f9889d26039450c98fc3d0bb9117afe2aa29726cd874",
        "industry": "Management Consulting",
        "link": "https://www.loopcapital.com/careers/professionals/?gnk=job&gni=8a78839f8eee94ec018f2b1a3e28196e&lang=en",
        "majors": [],
        "name": "Analyst – Loop Capital Financial Consulting Services",
        "openings": 0,
        "salary": "",
        "schools": [],
        "scope": "public",
        "state": [
            "IL"
        ],
        "status": "",
        "tags": [],
        "type": "Full Time",
        "work_model": ""
    },
    {
        "application_questions": [],
        "apply_direct": "",
        "candidate_reqs": {},
        "city": [
            "Chicago"
        ],
        "class_years": [
            "2025"
        ],
        "club_id": "",
        "clubs": [],
        "company_id": "13070",
        "company_name": "Loop Capital Markets, LLC",
        "created_at": "2024-06-17T16:30:55.146866+00:00",
        "creator": "",
        "date_posted": "2024-06-17T12:28:06.477-0400",
        "deadline": "",
        "deprecated": false,
        "description": "The objective of this position is to provide support to senior bankers and associates on all aspects of business development and deal acquisition. This includes, but is not limited to, ongoing client coverage, relationship management, deal management and execution and revenue generation. Under the supervision of Senior Bankers, Analysts assist state and local governments access the capital markets through the sale of tax-exempt and taxable securities.",
        "exclusive": false,
        "gpa": "",
        "id": "dc5d423d-413d-808d-741e-7b8b37f6e142e0a85eab932f22efa5787daccf824e1a",
        "industry": "Investment Banking",
        "link": "https://www.loopcapital.com/careers/professionals/?gnk=job&gni=8a7885ac8df2156e018e347f79d54e72&lang=en",
        "majors": [],
        "name": "Analyst – Investment Banking, Public Finance",
        "openings": 0,
        "salary": "",
        "schools": [],
        "scope": "public",
        "state": [
            "IL"
        ],
        "status": "",
        "tags": [],
        "type": "Full Time",
        "work_model": ""
    },
    {
        "application_questions": [],
        "apply_direct": "",
        "candidate_reqs": {},
        "city": [
            "Chicago"
        ],
        "class_years": [
            "2026"
        ],
        "club_id": "",
        "clubs": [],
        "company_id": "13070",
        "company_name": "Loop Capital Markets, LLC",
        "created_at": "2024-06-17T16:28:00.938883+00:00",
        "creator": "",
        "date_posted": "2024-06-17T12:21:29.612-0400",
        "deadline": "",
        "deprecated": false,
        "description": "Loop Capital Financial Consulting Services (LCFCS) provides valuation, financial due diligence, forensic, restructuring and other key financial consulting services to public and private firms, and municipal and other public sector clients.\n \nLCFCS seeks a Business Valuation Intern to join the team to assist in providing consulting services for projects related to mergers and acquisitions (M&A), financial reporting, tax and regulatory compliance, dispute advisory, restructuring and reorganization, and corporate strategy.  Throughout the internship, participants will gain a better understanding of valuation concepts and professional service delivery.  Additionally, the intern will receive hands-on development and opportunities to gain mentoring from members within the firm.",
        "exclusive": false,
        "gpa": "",
        "id": "fb8c124b-411a-1251-13f1-1dbd967e1491e7fe83d530fdc367b62ae55d07e0dbff",
        "industry": "Other",
        "link": "https://www.loopcapital.com/careers/professionals/?gnk=job&gni=8a78839f8cf53469018cfa92de5536d6&lang=en",
        "majors": [],
        "name": "2025 Summer Intern - Business Valuation",
        "openings": 0,
        "salary": "",
        "schools": [],
        "scope": "public",
        "state": [
            "IL"
        ],
        "status": "",
        "tags": [],
        "type": "Internship",
        "work_model": ""
    },
    {
        "application_questions": [],
        "apply_direct": "",
        "candidate_reqs": {},
        "city": [
            "Jersey City"
        ],
        "class_years": [
            "2025"
        ],
        "club_id": "",
        "clubs": [],
        "company_id": "813025",
        "company_name": "Corebridge Financial",
        "created_at": "2024-06-17T16:21:29.073144+00:00",
        "creator": "",
        "date_posted": "2024-06-17T12:18:08.933-0400",
        "deadline": "",
        "deprecated": false,
        "description": "Corebridge Financial is a leading global insurance organization. Building on 100 years of experience, today Corebridge Financial member companies provide a wide range of property casualty insurance, life insurance, retirement solutions, and other financial services to customers in more than 80 countries and jurisdictions. These diverse offerings include products and services that help businesses and individuals protect their assets, manage risks and provide for retirement security. For positions based in New York, NY, the base salary range is $97,000-$121,000 and the position is eligible for a bonus in accordance with the terms of the applicable incentive plan.",
        "exclusive": false,
        "gpa": "",
        "id": "28a6a3e9-cf2a-52de-fd54-97378b6999406d763ac58608c92615d04440d070670a",
        "industry": "Other",
        "link": "https://corebridgefinancial.wd1.myworkdayjobs.com/CorebridgeFinancial/job/NJ-Jersey-City/Pension--Risk-Transfer--Actuary--Analyst--Administrator_JR2400701-1",
        "majors": [],
        "name": "Pension (Risk Transfer) Actuary, Analyst, Administrator",
        "openings": 0,
        "salary": "",
        "schools": [],
        "scope": "public",
        "state": [
            "NJ"
        ],
        "status": "",
        "tags": [],
        "type": "Full-Time",
        "work_model": ""
    },
    {
        "application_questions": [],
        "apply_direct": "",
        "candidate_reqs": {},
        "city": [
            "New York"
        ],
        "class_years": [
            "2025"
        ],
        "club_id": "",
        "clubs": [],
        "company_id": "44829",
        "company_name": "Leerink Partners",
        "created_at": "2024-06-17T16:18:08.416224+00:00",
        "creator": "",
        "date_posted": "2024-06-17T12:09:11.743-0400",
        "deadline": "",
        "deprecated": false,
        "description": "Leerink Partners is a leading investment bank providing a complete suite of financial solutions comprising M&A advisory, equity, debt, and derivative capital markets, equity research, and sales and trading capabilities. The Investment Banking Analyst will be a key member of an experienced team of investment bankers focused on healthcare companies. The successful candidate will provide support to senior bankers in executing M&A and financing transactions for clients across all sub-verticals within Healthcare Services and Technology. This position offers direct CEO, CFO and Board level contact, as well as significant responsibility and opportunity for advancement. The ideal candidate will have previous healthcare investment banking experience at the Analyst level. Salary Range: $110,000-$125,000 .",
        "exclusive": false,
        "gpa": "",
        "id": "418328f5-1274-a50c-5a0d-d7e28737101dcee758be7de8b2a8befd9a5a7eb5ac3b",
        "industry": "Investment Banking",
        "link": "https://leerink.wd5.myworkdayjobs.com/en-US/leerinkpartners/job/Investment-Banking-Analyst--Healthcare-Services-and-Technology_R00453",
        "majors": [],
        "name": "Investment Banking Analyst, Healthcare Services and Technology",
        "openings": 0,
        "salary": "",
        "schools": [],
        "scope": "public",
        "state": [
            "NY"
        ],
        "status": "",
        "tags": [],
        "type": "Full-Time",
        "work_model": ""
    },
    {
        "application_questions": [],
        "apply_direct": "",
        "candidate_reqs": {},
        "city": [
            "Irvine"
        ],
        "class_years": [
            "2025"
        ],
        "club_id": "",
        "clubs": [],
        "company_id": "17454",
        "company_name": "Capital Group",
        "created_at": "2024-06-17T16:09:11.211731+00:00",
        "creator": "",
        "date_posted": "2024-06-17T12:06:21.879-0400",
        "deadline": "",
        "deprecated": false,
        "description": "As an Enterprise Risk Analyst, you will play a key role in supporting business areas with the identification and evaluation of key risks and opportunities to strengthen the control environment. You will be responsible for supporting operational event management including Sarbanes-Oxley compliance, SOC 1 audit coordination, trend analysis, and other risk management initiatives/projects. To thrive in this role, you’ve been successful in a risk management and/or audit-related position where you’ve had experience with risk and control self-assessments, SOC 1 internal control audits, and risk reporting. Orange County Base Salary Range: $86,424-$138,278.",
        "exclusive": false,
        "gpa": "",
        "id": "9007cdaa-e6fc-5ab5-d8b5-6a165956ba48fbe0db85b55be2f19f8f1f96df5ff30b",
        "industry": "Other",
        "link": "https://capgroup.wd1.myworkdayjobs.com/capitalgroupcareers/job/Risk-Analyst_JR2570?utm_source=LinkedIn",
        "majors": [],
        "name": "Enterprise Risk Analyst",
        "openings": 0,
        "salary": "",
        "schools": [],
        "scope": "public",
        "state": [
            "CA"
        ],
        "status": "",
        "tags": [],
        "type": "Full-Time",
        "work_model": ""
    },
    {
        "application_questions": [],
        "apply_direct": "",
        "candidate_reqs": {},
        "city": [
            "Los Angeles"
        ],
        "class_years": [
            "2025"
        ],
        "club_id": "",
        "clubs": [],
        "company_id": "23206",
        "company_name": "MUFG",
        "created_at": "2024-06-17T16:06:21.382075+00:00",
        "creator": "",
        "date_posted": "2024-06-17T12:03:04.007-0400",
        "deadline": "",
        "deprecated": false,
        "description": "The Entertainment Finance Group (EF) structures and underwrites loan facilities for a variety of businesses who are in the entertainment industry.  As an entry level corporate finance professional, you will be part of the Portfolio Management Team supporting the EF lending business. Critical functions of this position include assessing risk factors associated with a credit and determining appropriate ratings based on established policies and guidelines. You will be responsible for active credit management of a designated portfolio utilizing solid credit and analytical skills, providing continuous oversight of portfolio names, monitoring for potential risk and regulatory rating changes and proactively identifying credit deterioration with the goal of maximizing net income and reducing credit costs. The typical base pay range for this role is between $100K - $110K depending on job-related knowledge, skills, experience and location.",
        "exclusive": false,
        "gpa": "",
        "id": "55517871-4e63-f241-c9f2-7e5943a1a1bb934e429d71e8eaee9a23975a92d49a58",
        "industry": "Other",
        "link": "https://mufgub.wd3.myworkdayjobs.com/MUFG-Careers/job/Los-Angeles-CA/Credit-Analyst---Portfolio-Management-Group--Entertainment-Finance_10064630-WD?source=RS_LinkedIn",
        "majors": [],
        "name": "Credit Analyst - Portfolio Management Group, Entertainment Finance",
        "openings": 0,
        "salary": "",
        "schools": [],
        "scope": "public",
        "state": [
            "CA"
        ],
        "status": "",
        "tags": [],
        "type": "Internship",
        "work_model": ""
    }
]

