import React, {Component} from "react";


class UpgradedCompanyPageHeader extends Component {

    render() {
        const company = this.props.company || {};
        return (
            <div
                className='bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl'>
                <div className='flex flex-row gap-8'>
                    <img
                        className='w-24 h-24 rounded-lg'
                        src={company.logo_url}/>
                    <div className='flex flex-col gap-3'>
                        <div className='flex flex-row items-center gap-2'>
                            <p className='text-3xl text-slate-800 font-semibold'>
                                {company.name}
                            </p>
                        </div>
                        <div className='flex flex-row items-center gap-10'>
                            <div>
                                <p className='text-sm text-slate-500'>
                                    Website
                                </p>
                                <p className='text-sm font-semibold text-primary'>
                                    {company.website || "--"}
                                </p>
                            </div>
                            <div>
                                <p className='text-sm text-slate-500'>
                                    Industry
                                </p>
                                <p className='text-sm font-semibold text-primary'>
                                    {company.sector || "--"}
                                </p>
                            </div>
                            <div>
                                <p className='text-sm text-slate-500'>
                                    Founded
                                </p>
                                <p className='text-sm font-semibold text-primary'>
                                    {company.founded || "--"}
                                </p>
                            </div>
                            <div>
                                <p className='text-sm text-slate-500'>
                                    Employees
                                </p>
                                <p className='text-sm font-semibold text-primary'>
                                    {company.num_employees}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default (UpgradedCompanyPageHeader);
