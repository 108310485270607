import React from "react";

function SvgDownload(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="feather feather-download"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4"></path>
      <path d="M7 10L12 15 17 10"></path>
      <path d="M12 15L12 3"></path>
    </svg>
  );
}

export default SvgDownload;

