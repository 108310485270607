import React, { Component } from "react";
import WrappedTextInput from "../../../components/atoms/WrappedTextInput";
import { Button } from "../../../components/atoms/Button";
import SvgTrash from "../../../components/icons/SvgTrash";

class CreateApplicationQuestions extends Component {
  render() {
    return (
      <div className="flex flex-col gap-5">
        {!this.props.hideTutorial && (
          <p className="text-lg text-slate-800 font-semibold">
            Would you like to add any required questions in order to apply?
          </p>
        )}
        {this.props.applicationQuestions.map((question, i) => {
          return (
            <div key={i} className="flex flex-col">
              <p className="text-xs font-medium text-slate-500">{`Question ${i + 1}.`}</p>
              <div className="flex flex-row items-center justify-center w-full mt-1">
                <div className="w-full">
                  <WrappedTextInput
                    type="text"
                    placeholder={
                      this.props.placeholder
                        ? this.props.placeholder
                        : "ie. Why are you interested in working at our company?"
                    }
                    className="input-light px-3 w-full"
                    value={question.questionText}
                    onChange={(e) =>
                      this.props.updateApplicationQuestions(e.target.value, i)
                    }
                  />
                </div>
                <SvgTrash
                  onClick={() => this.props.removeApplicationQuestion(i)}
                  className="text-red-500 h-6 w-6 ml-2 hover:text-red-300 cursor-pointer"
                />
              </div>
            </div>
          );
        })}
        <Button
          variant="blueLight"
          onClick={() =>
            this.props.updateApplicationQuestions(
              "",
              this.props.applicationQuestions.length
            )
          }
          disabled={this.props.applicationQuestions.length === 5}
        >
          {this.props.applicationQuestions.length === 0
            ? "Add the first question"
            : "Add another question"}
        </Button>
      </div>
    );
  }
}

export default CreateApplicationQuestions;
