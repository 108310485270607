import {useEffect, useState} from 'react';
import {Button} from '../atoms/Button';
import { useHistory } from "react-router-dom";
import { hasAllSectionsComplete } from "../../utils/profileUtils";
import { useSelector } from 'react-redux';

export default function ProfileMyPreferencesSection({user}) {
    const trackers = useSelector(state => state.userReducer.trackers);
    const history = useHistory();
    const {job_status, seeking_role, locations = [], role_interests = []} = user;
    const companies = Object.values(trackers ?? {}).filter(tracker => tracker.type === 'company').map(tracker => tracker.company?.name);
    const [status, setStatus] = useState('Incomplete');

    useEffect(() => {
        if (!hasAllSectionsComplete(user, trackers)) {
            setStatus('Incomplete');
        } else {
            setStatus('Complete');
        }
    }, [user, trackers]);

    const renderList = (items, limit) =>
        items.length === 0
            ? 'None'
            : items.slice(0, limit).join(', ') +
            (items.length > limit ? ` +${items.length - limit} more` : '');

    const goToEditProfile = () => {
        window.analytics.track('Press Edit Preferences', {section: 'My Preferences'});
        history.push('/edit-profile');
    }
    
    return (
        <div className="card border-2 p-5 rounded-xl mb-5 flex flex-col border-primary">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">My Preferences</h2>
            </div>

            <div className="mb-4">
                {status === 'Incomplete' ? (
                    <div>
                        <div className='bg-red-50 p-3 border border-red-500 mb-3 rounded-lg'>
                            <p className="text-red-500 font-semibold">Preferences incomplete</p>
                            <p className="text-sm text-red-500 font-medium">
                                Complete your profile to personalize your experience and boost visibility!
                            </p>
                        </div>
                        <Button className="w-full" onClick={goToEditProfile}>
                            Complete My Preferences
                        </Button>
                    </div>
                ) : (
                    <div>
                        <div className='bg-primary/10 p-3 border border-primary mb-3 rounded-lg'>
                            <p className="text-primary font-semibold">Preferences complete</p>
                            <p className="text-sm text-primary font-medium">
                                Your profile looks great! Update your preferences at any time.
                            </p>
                        </div>
                        <Button className="w-full" onClick={goToEditProfile}>
                            Update My Profile
                        </Button>
                    </div>
                )}
            </div>

            {seeking_role && (
                <>
                    <div className="mb-2">
                        <p className="font-medium">Job status:</p>
                        <p className="text-gray-700">{job_status}</p>
                    </div>

                    <div className="mb-2">
                        <p className="font-medium">Preferred locations:</p>
                        <p className="text-gray-700">{renderList(locations, 3)}</p>
                    </div>

                    <div className="mb-2">
                        <p className="font-medium">Preferred roles:</p>
                        <p className="text-gray-700">{renderList(role_interests, 3)}</p>
                    </div>

                    <div className="mb-2">
                        <p className="font-medium">Preferred companies:</p>
                        <p className="text-gray-700">{renderList(companies, 5)}</p>
                    </div>
                </>
            )}

            {!seeking_role && (
                <>
                    <div className="mb-2">
                        <p className="font-medium">Job status:</p>
                        <p className="text-gray-700">{job_status}</p>
                    </div>
                </>
            )}
        </div>
    );
}
