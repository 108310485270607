import { Link } from "react-router-dom";
import {
  LinkIcon,
  ArrowTopRightOnSquareIcon,
  PencilSquareIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { getApplicationSubmissions } from "../api/clubAdmin/applications";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { Button } from "../components/atoms/Button";
import Tooltip from "../components/atoms/modals/Tooltip";
import SvgVisibility from "../components/icons/SvgVisibility";
import SvgDownload from "../components/icons/SvgDownload";
import SvgPeopleFeather from "../components/icons/SvgPeopleFeather";
import { downloadAllResumes } from "../api/student/clubs";
import { ViewApplicationSubmissionModal } from "../components-club-admin/modals/ViewApplicationSubmissionModal";
import Spinner from "../components/Spinner";
import { admitUserToClub } from "../api/clubAdmin/user";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../utils/constants";
import SvgArrowOutward from "../components/icons/SvgArrowOutward";
import SvgArrow from "../components/icons/SvgArrow";

export const ClubAdminApplicationPreviewCard = ({
  authUser,
  club_id,
  user,
  setApplicationToEdit,
  setCreateApplicationModalOpen,
  application_id,
  application,
  application_questions,
  setSubmissionCount,
  history,
}) => {
  const application_link = `/club/${club_id}/application/${application_id}`;
  const application_webpage = window.location.origin + application_link;
  const [submissions, setSubmissions] = useState([]);
  const [viewApplicationModalOpen, setViewApplicationModalOpen] =
    useState(false);
  const [applicationToView, setApplicationToView] = useState(null);
  const [isLoadingAdmit, setIsLoadingAdmit] = useState(false);
  const [isLoadingDecline, setIsLoadingDecline] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function fetchData() {
    setIsLoading(true);
    const { application_submissions, submission_count } =
      await getApplicationSubmissions(authUser, application_id);
    setSubmissionCount(submission_count);
    setSubmissions(application_submissions);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [authUser, application_id]);

  async function admitUser(submission, approval_decision) {
    if (approval_decision) {
      setIsLoadingAdmit(true);
    } else {
      setIsLoadingDecline(true);
    }
    await admitUserToClub(
      authUser,
      submission.user.username,
      submission.club_id,
      submission.id,
      approval_decision
    );
    setIsLoadingAdmit(false);
    setIsLoadingDecline(false);
    setSubmissions((submissions) =>
      submissions.filter((s) => s.id !== submission.id)
    );
    setViewApplicationModalOpen(false);
  }

  const downloadCsv = () => {
    const applicants = submissions.map((sub) => sub.user);
    const people = applicants
      .map((row, i) => {
        let basicInfo = [
          escapeCsvValue(`${row.first_name} ${row.last_name}`),
          escapeCsvValue(row.email),
          escapeCsvValue(row.grade),
          escapeCsvValue(row.college),
          escapeCsvValue(row.gpa),
          escapeCsvValue(row.resume),
        ].join(",");

        for (const answer of submissions[i]["answers"]) {
          basicInfo += `,${escapeCsvValue(answer.answerText)}`;
        }
        return basicInfo;
      })
      .join("\n");

    // Create a Blob object from the CSV data
    let header = `name,email,class year,college,gpa,resume`;
    for (const question of application_questions) {
      header += `,${escapeCsvValue(question.questionText)}`;
    }
    header += "\n";
    const csv = header + people;
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);

    // Create a link element and click it programmatically to trigger download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "applicants.csv");
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  function escapeCsvValue(value) {
    if (!value) return ""; // Handle null or undefined
    value = value.toString().replace(/"/g, '""'); // Escape double quotes by doubling them
    // If value contains commas, newlines, or quotes, wrap in double quotes
    if (/[,"\n]/.test(value)) {
      return `"${value}"`;
    }
    return value;
  }

  const resumeBooklet = async () => {
    await downloadAllResumes(
      authUser,
      submissions.map((sub) => sub.user)
    );
  };

  const navigateApplicantsPage = () => {
    history.push({
      pathname: `/clubadmin/applicants/` + application_id,
      state: { application, application_id, application_questions },
    });
  };

  const handleEditClick = () => {
    const newApplication = { ...application };
    setApplicationToEdit(newApplication);
    setCreateApplicationModalOpen(true);
  };

  const submissionsUsers = submissions.map((sub) => sub.user);
  return (
    <div className="bg-white shadow-lg shadow-slate-200 p-5 flex flex-col justify-between border border-slate-200 rounded-xl w-full mb-2">
      <div className="flex flex-row items-center w-full justify-between pb-2 overflow-hidden">
        <div
          className="group cursor-pointer flex flex-1"
          onClick={navigateApplicantsPage}
        >
          <div className="flex flex-row items-center gap-1">
            <p className="text-xl font-semibold group-hover:underline text-slate-800 truncate">
              {application.title}
            </p>
            <SvgArrowOutward className={"hidden w-4 h-4 group-hover:flex"} />
          </div>
          <div className="flex flex-row items-center gap-2 text-slate-500 -hidden">
            <p className="text-md line-clamp-1">{application.description} </p>
          </div>
        </div>
        <div className="flex flex-row justify-end items-center">
          <div className="flex flex-row items-center cursor-pointer">
            <Tooltip
              toggleComponent={
                <div
                  onClick={handleEditClick}
                  className="px-2 py-2 rounded-3xl bg-primary/20 flex flex-row items-center justify-center hover:opacity-50 cursor-pointer"
                >
                  <PencilSquareIcon className="w-5 h-5 text-primary" />
                </div>
              }
              label={"Edit application"}
            />
            <Tooltip
              toggleComponent={
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(application_webpage);
                    toast.success("Link copied to clipboard!", TOAST_OPTIONS);
                  }}
                  className="px-2 py-2 rounded-3xl bg-primary/20 flex flex-row items-center justify-center hover:opacity-50 cursor-pointer mx-3"
                >
                  <LinkIcon className="w-5 h-5 text-primary" />
                </div>
              }
              label={"Copy application link"}
            />
            <Tooltip
              toggleComponent={
                <Link
                  to={application_link}
                  className="px-2 py-2 rounded-3xl bg-primary/20 flex flex-row items-center justify-center hover:opacity-50 cursor-pointer"
                >
                  <ArrowTopRightOnSquareIcon className="w-5 h-5 text-primary" />
                </Link>
              }
              label={"Preview application"}
            />

            {submissions.length ? (
              <>
                <Tooltip
                  toggleComponent={
                    <div
                      onClick={resumeBooklet}
                      className="px-2 py-2 rounded-3xl bg-primary/20 flex flex-row items-center justify-center hover:opacity-50 cursor-pointer mx-3"
                    >
                      <SvgDownload className="w-5 h-5 text-primary" />
                    </div>
                  }
                  label={"Download resume booklet"}
                />
                <Tooltip
                  toggleComponent={
                    <div
                      onClick={downloadCsv}
                      className="px-2 py-2 rounded-3xl bg-primary/20 flex flex-row items-center justify-center hover:opacity-50 cursor-pointer"
                    >
                      <SvgPeopleFeather className="w-5 h-5 text-primary" />
                    </div>
                  }
                  label={"Export users to CSV"}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="w-full mx-auto text-center my-14">
          <Spinner size={24} />
        </div>
      )}
      {submissions.length === 0 && !isLoading && (
        <div className="w-full mx-auto text-center my-14">
          <p className="text-base font-semibold text-slate-500 text-center">
            No more applicants to review
          </p>
          <div
            onClick={() => fetchData()}
            className="flex flex-row items-center justify-center hover:opacity-50 cursor-pointer mt-3"
          >
            <p className="text-sm font-semibold text-primary mr-2">
              Refresh List
            </p>
            <ArrowPathIcon className="w-4 h-4 text-primary" />
          </div>
        </div>
      )}
      <ul role="list" className="divide-y divide-gray-100">
        {!isLoading &&
          submissions.slice(0, 5).map((submission) => (
            <li
              key={submission.id}
              className="flex justify-between gap-x-6 py-5"
            >
              <div className="flex min-w-0 gap-x-4">
                <img
                  src={
                    submission.user.profile_pic_url
                      ? submission.user.profile_pic_url
                      : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fblank-person.jpeg?alt=media&token=ac3b0019-71c8-4830-932e-de437faf5fc8"
                  }
                  className="h-12 w-12 flex-none rounded-full bg-gray-50"
                />
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    <a className="">
                      {submission.user.first_name} {submission.user.last_name}
                    </a>
                  </p>
                  <p className="flex text-xs leading-5 text-slate-500">
                    <a
                      href={`mailto:${submission.user.email}`}
                      className="truncate hover:underline"
                    >
                      {submission.user.email}
                    </a>
                  </p>
                </div>
              </div>
              <div className="flex shrink-0 items-center gap-x-6">
                <Button
                  onClick={() => {
                    navigateApplicantsPage();
                  }}
                  icon={SvgArrow}
                  variant="outline"
                >
                  View Application
                </Button>
                {/* <Menu as="div" className="relative flex-none">
                  <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon
                      aria-hidden="true"
                      className="h-5 w-5"
                    />
                  </MenuButton>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-52 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <MenuItem>
                      <div
                        onClick={() => admitUser(submission, false)}
                        className="flex items-center justify-start px-3 py-1  data-[focus]:bg-gray-50 cursor-pointer"
                      >
                        <p className="mr-2 text-sm leading-6 text-gray-900">
                          Decline Application
                        </p>
                        {isLoadingDecline && <Spinner size={20} />}
                      </div>
                    </MenuItem>
                    <MenuItem>
                      <div
                        onClick={() => admitUser(submission, true)}
                        className="flex items-center justify-start px-3 py-1  data-[focus]:bg-gray-50 cursor-pointer"
                      >
                        <p className="text-sm leading-6 text-primary mr-2">
                          Approve as member
                        </p>
                        {isLoadingAdmit && <Spinner size={20} />}
                      </div>
                    </MenuItem>
                  </MenuItems>
                </Menu> */}
              </div>
            </li>
          ))}
      </ul>
      <ViewApplicationSubmissionModal
        isOpen={viewApplicationModalOpen}
        closeModal={() => setViewApplicationModalOpen(false)}
        application={applicationToView}
        application_questions={application_questions}
        user={user}
        authUser={authUser}
        setSubmissions={setSubmissions}
        history={history}
      />
    </div>
  );
};
