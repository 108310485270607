import React, {PureComponent} from "react";
import SvgAddCircle from "../icons/SvgAddCircle";
import Tooltip from "../atoms/modals/Tooltip";
import {connect} from "react-redux";
import {createTracker} from "../../api/student/trackers";
import {Button} from "./Button";
import SvgCheckCircle from "../icons/SvgCheckCircle";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        trackers: state.userReducer.trackers
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateTracker: (tracker) => {
            dispatch({
                type: "UPDATE_TRACKER",
                tracker,
            })
        }
    }
}


class CompanyStatusTrackerComponent extends PureComponent {

    addCompany = async () => {
        if (this.props.companyId in this.props.trackers) {
            throw new Error("Already saved this company.")
        }
        const res = await createTracker(this.props.authUser, {type: "company", company_id: this.props.companyId});
        if (!res)
          throw new Error("Unable to save company")

        this.props.updateTracker(res.tracker);
        window.analytics.track("Saved Company", {
          company_id: this.props.companyId
        })
    }

    render() {
        const tracking = this.props.companyId in this.props.trackers;

        return (
            <div>
                {tracking ?
                    <Button variant={'secondary'} icon={SvgCheckCircle}
                            disabled={tracking}>
                        Following
                    </Button>
                    :
                    <Tooltip
                        toggleComponent={
                            <Button onClick={(e) => {
                                e.stopPropagation()
                                this.addCompany()
                            }}
                                    variant={'secondary'}
                                    icon={SvgAddCircle}
                            >
                                Follow
                            </Button>
                        }
                        label={'Follow this company to add it to your saved companies'}
                    />
                }
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyStatusTrackerComponent);
