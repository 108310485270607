import {Button} from "../Button";
import React, {PureComponent} from "react";
import SvgAddCircle from "../../icons/SvgAddCircle";
import Dropzone from 'react-dropzone'
import SvgEdit from "../../icons/SvgEdit";
import {connect} from "react-redux";
import {editClub} from "../../../api/student/clubs";
import {uploadDoc} from "../../../api/firebase";
import ToggleButton from "../ToggleButton";
import SelectComponent from '../SelectComponent';
import MultiSelectComponent from "../MultiSelectComponent";
import ModalComponent from './ModalComponent';
import {wait} from "@testing-library/user-event/dist/utils";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        constants: state.userReducer.constants,
    };
}

class EditClubModal extends PureComponent {
    state = {
        name: "", // Separate states for each input field
        founded: "",
        website: "",
        email: "",
        logo_url: "",
        applicationOnly: false,
        numMembers: "",
    }

    handleInputChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.isOpen !== this.props.isOpen) {
            wait(10).then(() => {
                this.setInfo();

            })
        }
    }

    setInfo = () => {
        const club = this.props.club || {};
        this.setState({
            name: club.name,
            founded: club.founded,
            website: club.website,
            logo_url: club.logo_url,
            email: club.email,
            industry: club.specialties,
            applicationOnly: club.application_only,
            numMembers: club.num_members_range,
        })
    }

    updateClub = async () => {
        await editClub(this.props.authUser, this.props.club.id, {
            name: this.state.name,
            founded: this.state.founded,
            website: this.state.website,
            email: this.state.email,
            logo_url: this.state.logo_url,
            specialties: this.state.industry,
            application_only: this.state.applicationOnly,
            num_members_range: this.state.numMembers,
        });
        this.props.getClubInfo();
        this.props.closeModal();
        window.analytics.track("Club Edited", {
            "club_id": this.props.club.id,
            "name": this.state.name,
            "founded": this.state.founded,
            "email": this.state.email,
            "website": this.state.website,
            "logo_url": this.state.logo_url,
            "industry": this.state.industry,
            "application_only": this.state.applicationOnly,
            "num_members_range": this.state.numMembers
        })
    }

    onDrop = async (acceptedFiles) => {
        const url = await uploadDoc("clubs", this.props.club.id, acceptedFiles[0]);
        this.setState({logo_url: url});
    }

    toggleApplicationOnly = () => {
        this.setState({applicationOnly: !this.state.applicationOnly})
    }

    handleSelectChange = (inputName, value) => {
        console.log(inputName, value)
        this.setState({[inputName]: value});
    };

    handleMultiSelectChange = (inputName, selectedValue) => {
        const array = [...this.state[inputName]];

        let updatedArray;
        if (array.includes(selectedValue)) {
            updatedArray = array.filter((item) => item !== selectedValue);
        } else if (selectedValue === null) {
            updatedArray = [];
        } else {
            updatedArray = [...array, selectedValue]
        }
        this.setState({[inputName]: updatedArray});
    }


    render() {

        const club = this.props.club;
        const saveEnabled = this.state.name !== club.name || this.state.founded !== club.founded || this.state.website !== club.website || this.state.description !== club.description || this.state.logo_url !== club.logo_url || this.state.numMembers !== club.num_members_range;
        const NUM_MEMBERS_OPTIONS = ['1-25', '26-50', '51-75', '76-100', '100+'];
        const INDUSTRY_TAGS = this.props.constants.industry_tags.map(tag => ({title: tag, value: tag}));

        return (
            <ModalComponent isOpen={this.props.isOpen}
                            header={"Edit club information"}
                            backgroundColor={'white'}
                            headerToggle
                            size={'lg'}
                            toggle={this.props.closeModal}
                            FooterComponent={
                                <div className='flex flex-row items-center justify-end gap-3'>
                                    <Button variant={'secondary'} onClick={this.props.closeModal}>
                                        Cancel
                                    </Button>
                                    <Button onClick={this.updateClub} disabled={!saveEnabled}>
                                        Save
                                    </Button>
                                </div>
                            }
                            footerAlignment={'right'}
                            id={"edit-club-modal"}
            >
                <div id={"edit-club-modal"}>
                    <div className='flex flex-col gap-5 mt-5'>
                        <div className='flex flex-row items-center gap-3'>
                            {!this.state.logo_url ?
                                <div
                                    className='br-12 bg-neutral-200 flex flex-col items-center border border-neutral-200'
                                    style={{width: 80, height: 80}}/>
                                :
                                <img alt={'company'} src={this.state.logo_url}
                                     className='br-12 object-contain border bg-white border-neutral-200 '
                                     style={{width: 80, height: 80}}/>
                            }

                            <Dropzone onDrop={this.onDrop}>
                                {({getRootProps, getInputProps}) => (
                                    <div {...getRootProps()} className='cursor-pointer'>
                                        <input {...getInputProps()} />
                                        {/* <div
                                            className='w-full flex flex-row items-center justify-center rounded-xl h-26 border border-neutral-300 border-dashed p-6 text-neutral-400 hover:bg-neutral-100'>
                                            <p className='body3 ml-2'>
                                                hi
                                            </p>
                                        </div> */}
                                        {!this.state.logo_url ?
                                            <div
                                                className='text-indigo-500 cursor-pointer hover:text-indigo-800 body2-bold flex flex-row items-center gap-2'>
                                                <SvgAddCircle className={'w-4 h-4'}/> Add Image
                                            </div>
                                            :
                                            <div
                                                className='text-indigo-500 cursor-pointer hover:text-indigo-800 body2-bold flex flex-row items-center gap-2'>
                                                <SvgEdit className={'w-4 h-4'}/> Edit Image
                                            </div>
                                        }
                                    </div>
                                )}
                            </Dropzone>

                        </div>
                        <div className="col-plain w-full">
                            <p className="body3-bold text-neutral-400 mb-1">
                                Name
                            </p>
                            <input
                                name="name"
                                value={this.state.name || ''}
                                onChange={this.handleInputChange}
                                placeholder="Club name"
                                type="text"
                                className='input-light px-3 body2 base-black-50 w-full'
                            />
                        </div>
                        <div className='flex flex-row gap-3'>
                            <div className="col-plain flex-1">
                                <p className="body3-bold text-neutral-400 mb-1">
                                    Industry
                                </p>
                                <MultiSelectComponent
                                    label={'Select industry...'}
                                    className={'w-full'}
                                    value={this.state.industry || []}
                                    light
                                    stateName={"industry"}
                                    setValue={(e) => this.handleMultiSelectChange('industry', e)}
                                    clearFilter={() => this.handleMultiSelectChange('industry', null)}
                                    options={INDUSTRY_TAGS}
                                    node={document.getElementById("edit-club-modal")}
                                />
                            </div>
                            <div className="col-plain flex-1">
                                <p className="body3-bold text-neutral-400 mb-1">
                                    Number of Members
                                </p>
                                {/* <input
                                    name="website"
                                    disabled={true}
                                    //todo
                                    value={this.props.numMembers}
                                    // onChange={this.handleInputChange}
                                    placeholder="Number of members"
                                    className='input-light px-3 body2 base-black-50 w-full'
                                /> */}
                                <SelectComponent
                                    label={'Select range of members...'}
                                    className={'w-full'}
                                    value={this.state.numMembers}
                                    light
                                    stateName={"numMembers"}
                                    setValue={this.handleSelectChange}
                                    clearFilter={() => this.handleSelectChange('numMembers', null)}
                                    options={NUM_MEMBERS_OPTIONS.map(num_members => ({title: num_members}))}
                                    node={document.getElementById("edit-club-modal")}
                                />
                            </div>
                        </div>
                        <div className='flex flex-row gap-3'>
                            <div className="col-plain flex-1">
                                <p className="body3-bold text-neutral-400 mb-1">
                                    Founded
                                </p>
                                <input
                                    name="founded"
                                    value={this.state.founded || ''}
                                    onChange={this.handleInputChange}
                                    placeholder="Founded"
                                    type="text"
                                    className='input-light px-3 body2 base-black-50 w-full'
                                />
                            </div>
                            <div className="col-plain flex-1">
                                <p className="body3-bold text-neutral-400 mb-1">
                                    Website
                                </p>
                                <input
                                    name="website"
                                    value={this.state.website || ''}
                                    onChange={this.handleInputChange}
                                    placeholder="Website"
                                    type="text"
                                    className='input-light px-3 body2 base-black-50 w-full'
                                />
                            </div>
                            <div className="col-plain flex-1">
                                <p className="body3-bold text-neutral-400 mb-1">
                                    Email
                                </p>
                                <input
                                    name="email"
                                    value={this.state.email || ''}
                                    onChange={this.handleInputChange}
                                    placeholder="Email"
                                    type="email"
                                    className='input-light px-3 body2 base-black-50 w-full'
                                />
                            </div>
                        </div>
                        <div
                            className={`flex flex-row items-center justify-between`}>
                            <p className="body3-bold text-neutral-400">
                                Is this club application only?
                            </p>
                            <div className='flex flex-row items-center'>
                                <p className={`mr-2 body4-bold uppercase text-slate-500`}>
                                    {!this.state.applicationOnly ? 'No' : 'Yes'}
                                </p>
                                <ToggleButton
                                    active={this.state.applicationOnly}
                                    onClick={this.toggleApplicationOnly}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(EditClubModal);
