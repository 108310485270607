import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={24}
            viewBox="0 -960 960 960"
            width={24}
            {...props}
        >
            <path fill={'currentColor'} d="M80-200v-560q0-33 23.5-56.5T160-840h240q33 0 56.5 23.5T480-760v80h320q33 0 56.5 23.5T880-600v400q0 33-23.5 56.5T800-120H160q-33 0-56.5-23.5T80-200zm80 0h80v-80h-80v80zm0-160h80v-80h-80v80zm0-160h80v-80h-80v80zm0-160h80v-80h-80v80zm160 480h80v-80h-80v80zm0-160h80v-80h-80v80zm0-160h80v-80h-80v80zm0-160h80v-80h-80v80zm160 480h320v-400H480v80h80v80h-80v80h80v80h-80v80zm160-240v-80h80v80h-80zm0 160v-80h80v80h-80z" />
        </svg>
    )
}

export default SvgComponent
