import React, {PureComponent} from "react";
import {connect} from "react-redux";
import PageContentWrapper from "../wrappers/PageContentWrapper";
import {withRouter} from "react-router-dom";
import PageHeaderWrapper from "../wrappers/PageHeaderWrapper";
import DiscoverPeople from "../discover/DiscoverPeople";
import AnimatedTabs from "../atoms/AnimatedTabs";
import SvgSearch from "../icons/SvgSearch";
import SvgBriefcase from "../icons/SvgBriefcase";
import NetworkingPage from "./NetworkingPage";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        applications: state.userReducer.applications,
        user: state.userReducer.user,
    };
}

class NetworksPage extends PureComponent {
    state = {
        companyName: "",
        activeTab: "Search"

    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const activeTab = queryParams.get("activeTab");
        const companyId = queryParams.get("companyId")
        const companyName = queryParams.get("companyName")
        if (!!activeTab) this.changeTab(activeTab, true);
        window.analytics.page("Network Tab Viewed")
        if (!!companyId) this.setState({companyId});
        if (!!companyName) this.setState({companyName})
    }


    changeTab = (tab) => {
        this.setState({activeTab: tab});
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("activeTab", tab);

        const params = `activeTab=${tab}`
        const newUrl = `${window.location.pathname}?${params}`;
        this.props.history.replace(newUrl);
    };

    render() {
        const activeTab = this.state.activeTab;
        return (
            <PageContentWrapper>
                <PageHeaderWrapper className={'pt-3 pb-0'}>
                    <div className='relative -mb-2 -mt-2'>
                        <AnimatedTabs
                            activeTab={this.state.activeTab}
                            changeTab={this.changeTab}
                            xl
                            tabs={[
                                {title: 'Search', icon: SvgSearch},
                                {title: 'Saved', icon: SvgBriefcase},
                            ]}
                        />
                    </div>
                </PageHeaderWrapper>
                <div className='pt-3 flex flex-1 overflow-hidden'>
                    {activeTab === "Search" &&
                        <DiscoverPeople history={this.props.history} companyName={this.state.companyName}/>
                    }
                    {activeTab === "Saved" &&
                        <NetworkingPage history={this.props.history} changeTab={this.changeTab}/>
                    }
                </div>
            </PageContentWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps)(NetworksPage));
