import React, {Component} from "react";
import { DateTime } from 'luxon';

class UpgradedCompanyPageDeals extends Component {

    render() {
        const company = this.props.company || {};
        const deals = company.deals || [];
        return (
            <div
                className='bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl'>
                <div className='flex flex-row items-center justify-between gap-2 mb-2'>
                    <p className='text-xl font-semibold text-slate-800'>
                        Deals
                    </p>
                </div>
                <div className='flex flex-col gap-8'>
                    {deals.length === 0 &&
                        <p className='text-slate-500'>
                            No deals listed yet
                        </p>
                    }
                    {deals.map((item, index) =>
                        <div className='flex flex-col gap-2'>
                            <p className='text-slate-500 text-sm'>
                                {DateTime.fromISO(item.date).isValid ? DateTime.fromISO(item.date).toFormat("MMM d, yyyy") + " - ": ""}{item.company_name}
                            </p>
                            <img
                                alt={'deal'}
                                src={item.logo_url}
                                className='w-1/3 object-contain'
                            />
                            <div>
                                <p className='text-slate-800 font-semibold text-md'>
                                    {item.title}
                                </p>
                                <p className='text-slate-500 text-sm'>
                                    {item.description}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default UpgradedCompanyPageDeals;
