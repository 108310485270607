import React, {Component} from "react";
import ModalComponent from "../../../components/atoms/modals/ModalComponent";
import WrappedTextInput from "../../../components/atoms/WrappedTextInput";
import DatePicker from "react-datepicker";
import Dropzone from "react-dropzone";
import SvgPlus from "../../../components/icons/SvgPlus";
import {connect} from "react-redux";
import {v4 as uuid} from 'uuid';
import {Button} from "../../atoms/Button";
import {editClub} from "../../../api/student/clubs";
import {uploadDoc} from "../../../api/firebase";
import { validateEmail } from "../../../utils/strings";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class ClubPageAddPocModal extends Component {
    state = {
        name: '',
        email: '',
        error: ""
    }

    closeModal = () => {
        this.props.closeModal()
    }

    handleInputChange = (e, inputName) => {
        this.setState({[inputName]: e.target.value});
    };

    save = async () => {
        const pocs = this.props.club.points_of_contact || [];
        pocs.push({
            name: this.state.name,
            email: this.state.email,
        })
        await editClub(this.props.authUser, this.props.club.id, {points_of_contact: pocs});
        this.props.closeModal();
        this.props.getClubInfo();
    }


    render() {
        const saveDisabled = false;
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Add Point of Contact'}
                headerToggle
                size={'lg'}
                toggle={this.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={this.closeModal} variant='secondary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={saveDisabled}
                            onClick={this.save}>
                            Add
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div className='flex flex-row gap-5 items-center'>
                    <div className='flex flex-col w-full gap-3'>
                        <WrappedTextInput
                            type="text"
                            outerLabel={'Name'}
                            placeholder={'e.g. John Smith'}
                            className='input-light px-3 w-full'
                            value={this.state.name}
                            onChange={(e) => this.handleInputChange(e, 'name')}
                        />
                         <WrappedTextInput
                            type="text"
                            outerLabel={'Email (*)'}
                            placeholder={'e.g. john.smith@gmail.com'}
                            className='input-light px-3 w-full'
                            value={this.state.email}
                            onChange={(e) => this.handleInputChange(e, 'email')}
                        />
                    </div>
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(ClubPageAddPocModal);
