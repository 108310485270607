import React, {Component} from 'react';
import {VictoryPie} from 'victory';

class DonutChart extends Component {
    render() {
        const colors = ['#4848F2', '#488CF2', '#5553A2', '#7448F2', '#BC48F2', '#9178d9']
        return (
            <div className={`w-full flex ${!this.props.inPeek ? 'items-center flex-col' : 'flex-row items-center'} ${this.props.className}`}>
                <div className='w-3/4'>
                    <VictoryPie
                        data={this.props.data}
                        colorScale={colors}
                        // innerRadius={80}
                        style={{
                            labels: {fill: "transparent", fontSize: 14, fontWeight: "bold"},
                            data:{stroke:'white', strokeWidth:2}
                        }}
                        // animate={{
                        //     duration: 2000
                        // }}
                        // padAngle={({ datum }) => 2}
                    />
                </div>
                <div className={`w-full flex ${this.props.inPeek ? 'flex-col' : 'flex-row justify-center'}  gap-x-5 gap-y-3 flex-wrap`}>
                    {this.props.data.map((item, i) =>
                        <div className='flex flex-row items-center gap-1'>
                            <div className='w-4 h-4 rounded-full' style={{backgroundColor:colors[i]}}/>
                            <p className={'body2-bold text-gray-500'}>
                                {item.x}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default DonutChart;
