import SvgMoney from "../icons/SvgMoney";
import SvgUserCheck from "../icons/SvgUserCheck";
import SvgUsers from "../icons/SvgUsers";
import React from "react";
import SvgBriefcase from "../icons/SvgBriefcase";

function CompanyInfoBlock({company, inPeek}) {
    return (
        <div className={`flex flex-col bg-slate-100 p-3 px-5 rounded-xl ${!inPeek && 'flex-[.75]'}`}>
            <p className='text-lg font-bold mb-2'>
                Info
            </p>
            <div className='flex flex-col gap-3'>
                <div className='flex flex-row items-center text-sm gap-1'>
                    <p className='mr-auto opacity-50'>
                        Sector
                    </p>
                    {company.revenue &&
                        <SvgBriefcase className={'w-4 h-4'}/>
                    }
                    <p className='font-semibold ml-1'>
                        {company.sector || "--"}
                    </p>
                </div>
                <div className='flex flex-row items-center text-sm gap-1'>
                    <p className='mr-auto opacity-50'>
                        Revenue
                    </p>
                    {company.revenue &&
                        <SvgMoney className={'w-4 h-4'}/>
                    }
                    <p className='font-semibold ml-1'>
                        {company.revenue || "--"}
                    </p>
                </div>
                <div className='flex flex-row items-center text-sm gap-1'>
                    <p className='mr-auto opacity-50'>
                        AUM
                    </p>
                    {company.aum &&
                        <SvgMoney className={'w-4 h-4'}/>
                    }
                    <p className='font-semibold ml-1'>
                        {company.aum || "--"}
                    </p>
                </div>
                <div className='flex flex-row items-center text-sm gap-1'>
                    <p className='mr-auto opacity-50'>
                        CEO
                    </p>
                    {company.ceo &&
                        <SvgUserCheck className={'w-4 h-4'}/>
                    }
                    <p className='font-semibold ml-1'>
                        {company.ceo || '--'}
                    </p>
                </div>
                <div className='flex flex-row items-center text-sm gap-1'>
                    <p className='mr-auto opacity-50'>
                        Employees
                    </p>
                    {company.num_employees &&
                        <SvgUsers className={'w-4 h-4'}/>
                    }
                    <p className='font-semibold ml-1'>
                        {company.num_employees}
                    </p>
                </div>
                <div className='flex flex-row items-center text-sm gap-1'>
                    <p className='mr-auto opacity-50'>
                        Class Size
                    </p>
                    {company.class_size &&
                        <SvgUsers className={'w-4 h-4'}/>
                    }
                    <p className='font-semibold ml-1'>
                        {company.class_size || "--"}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default CompanyInfoBlock
