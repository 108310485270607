import React, { useEffect, useMemo, useState } from "react";
import InputWrapper from "../atoms/inputs/InputWrapper";
import MultiSelectComponent from "../atoms/MultiSelectComponent";
import { MAJOR_OPTIONS } from "../../utils/dummy";
import SvgCheck from "../icons/SvgCheck";
import { getSchoolClubs } from "../../api/student/clubs";
import { connect } from "react-redux";
import EditProfileWrapper from "./EditProfileWrapper";
import EditProfileCtaButton from "./EditProfileCtaButton";
import { SPORT_OPTIONS } from "../../utils/constants";
import { editUser, getUser } from "../../api/student/users";
import { useDispatch } from "react-redux";
import Switch from "react-switch";
import { toast } from "react-toastify";
import SelectComponent from "../atoms/SelectComponent";
import SearchMajors from "../profile/SearchMajors";
import { educationRequiredProperties } from "../../utils/profileUtils";
import get from "lodash.get";

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
    };
}


function EditProfileEducation({ authUser, user, setLoading, loading, goToNextSection, hasNextSection}) {
    const [formData, setFormData] = useState(() => ({
        gpa: user.gpa ?? "",
        classYear: user.grade ?? "",
        clubs: user.clubs?.map(club => club.id) ?? [],
        graduationSeason: user.graduation_season ?? "",
    }));

    const [selectedMajors, setSelectedMajors] = useState(
        () => user.major ?? []
    );
    const [selectedMinors, setSelectedMinors] = useState(
        () => user.minor ?? []
    );

    const [noGpa, setNoGpa] = useState(false);
    const [errors, setErrors] = useState(() => {
        return educationRequiredProperties.reduce((acc, prop) => {
            const isValid = Array.isArray(get(user, prop))
                ? get(user, prop).length > 0
                : !!get(user, prop);
            acc[prop] = isValid ? "" : "Required field";
            return acc;
        }, {});
    });
    const [isFormValid, setIsFormValid] = useState(false);
    const [clubOptions, setClubOptions] = useState([]);
    const [sportsMap, setSportsMap] = useState(() => {
        const initialMap = {};
        user.sports?.forEach(sport => {
            initialMap[sport.sport] = sport.type === 'varsity';
        });
        return initialMap;
    });

    const dispatch = useDispatch();

    const setUser = (user) => {
        dispatch({
            type: "SET_USER",
            user
        });
    }

    const clubsNamesMap = useMemo(() => {
        return Object.fromEntries(
            clubOptions.map((club) => [club.id, club.name])
        );
    }, [clubOptions]);


    const clubsIdsMap = useMemo(() => {
        return Object.fromEntries(
            clubOptions.map((club) => [club.name, club.id])
        );
    }, [clubOptions]);

    const selectedClubNames = useMemo(() => {
        return formData.clubs.map((clubId) => clubsNamesMap[clubId]);
    }, [formData.clubs, clubsNamesMap]);

    useEffect(() => {
        const fetchClubs = async () => {
            const res = await getSchoolClubs(authUser, user.college);
            setClubOptions(res.clubs.filter((club) => club.demo !== true));
        };
        fetchClubs();
    }, [authUser, user.college]);


    const handleGradYearChange = (e) => {
        const text = e.target.value;
        if (
            text === "" ||
            (text.length <= 4 && /^\d*$/.test(text) && Number(text) <= 2030)
        ) {
            setFormData({ ...formData, classYear: text });
            setErrors({ ...errors, classYear: "" });
        }
    };

    const handleGPAChange = (e) => {
        const text = e.target.value;
        // if (text === "" || /^\d*\.?\d*$/.test(text)) {
        setFormData({ ...formData, gpa: text });
        // }
    };

    const handleClubsChange = (selectedClubName) => {
        let updatedClubs;
        if (selectedClubNames.includes(selectedClubName)) {
            updatedClubs = formData.clubs.filter(
                (clubId) => clubsNamesMap[clubId] !== selectedClubName
            );
        } else if (selectedClubName === null) {
            updatedClubs = [];
        } else {
            updatedClubs = [...formData.clubs, clubsIdsMap[selectedClubName]];
        }
        setFormData({ ...formData, clubs: updatedClubs });
    };

    const handleSportsChange = (selectedSport) => {
        if (selectedSport === null) {
            setSportsMap({});
        } else {
            if (selectedSport in sportsMap) {
                const { [selectedSport]: removed, ...rest } = sportsMap;
                setSportsMap(rest);
            } else {
                setSportsMap({
                    ...sportsMap,
                    [selectedSport]: true
                });
            }
        }
    };

    const handleSportSwitchChange = (checked, sport) => {
        setSportsMap({
            ...sportsMap,
            [sport]: checked
        });
    };

    const toggleCheckbox = () => {
        setNoGpa(!noGpa);
        if (!noGpa) {
            setFormData({ ...formData, gpa: "" });
            setErrors({ ...errors, gpa: "" });
        }
    };

    useEffect(() => {
        validateForm();
    }, [formData, noGpa, selectedMajors]);

    const validateForm = () => {
        let formErrors = {};

        if (
            !formData.classYear ||
            formData.classYear.length !== 4 ||
            !/^\d*\.?\d*$/.test(formData.classYear)
        )
            formErrors.classYear = "Valid class year is required";

        if (!noGpa && (!formData.gpa || parseFloat(formData.gpa) < 0)) {
            formErrors.gpa = "Valid GPA is required";
        }

        if (selectedMajors.length === 0) {
            formErrors.major = "At least one major is required";
        }

        setErrors(formErrors);
        setIsFormValid(Object.keys(formErrors).length === 0);
    };

    const handleGradSeasonChange = (selectedSeason) => {
        setFormData({ ...formData, graduationSeason: selectedSeason });
    };

    const updateAndSetProfile = async () => {
        const params = {
            major: selectedMajors,
            minor: selectedMinors,
            gpa: !!formData.gpa ? Number(formData.gpa.replace(',', '.')) : 0,
            grade: formData.classYear,
            clubs: formData.clubs,
            sports: Object.entries(sportsMap).map(([sport, isVarsity]) => ({ sport, type: isVarsity ? 'varsity' : 'club' })),
            graduation_season: formData.graduationSeason
        }
        try {
            setLoading(true);
            await editUser(authUser, params);
            const { user: updatedUser } = await getUser(authUser);
            setUser(updatedUser);
            setLoading(false);
            if (hasNextSection) {
                goToNextSection();
            }
        } catch (error) {
            console.log(error);
            throw error;
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = () => {
        if (isFormValid) {
            toast.promise(updateAndSetProfile, {
                pending: "Updating profile...",
                success: "Profile updated successfully",
                error: "Failed to update profile"
            });
        } else {
            toast.error("Please fill in all required fields");
        }
    };

    return (
        <>
            <EditProfileWrapper>
                <div className="flex flex-col gap-3 md:gap-5">
                    <SearchMajors selectedMajors={selectedMajors} setSelectedMajors={setSelectedMajors} outerLabel="Major(s)" limit={5} error={errors.major} placeholder={"Search any major..."}/>
                    <SearchMajors selectedMajors={selectedMinors} setSelectedMajors={setSelectedMinors} outerLabel="Minor(s)" limit={5} placeholder={"Search any minor..."}/>
                    {!noGpa && (
                        <InputWrapper
                            label="GPA*"
                            type="number"
                            name="gpa"
                            placeholder="ie. 3.6"
                            value={formData.gpa}
                            onChange={handleGPAChange}
                            error={errors.gpa}
                        />
                    )}
                    <div className="flex-1">
                        <button
                            onClick={toggleCheckbox}
                            className={`flex flex-row items-center ml-2 mt-1 w-max`}
                        >
                            <div
                                className={`${
                                    noGpa
                                        ? "checkbox-active"
                                        : "checkbox border-slate-200"
                                } mr-2`}
                            >
                                {noGpa ? (
                                    <SvgCheck className={"base-white-100"} />
                                ) : null}
                            </div>
                            <p className="body2 text-slate-500">
                                I don't have a GPA yet
                            </p>
                        </button>
                    </div>
                    <InputWrapper
                        label="Class Year*"
                        type="text"
                        name="classYear"
                        placeholder="ie. 2024"
                        value={formData.classYear}
                        onChange={handleGradYearChange}
                        error={errors.classYear}
                    />
                    <div className='flex-1'>
                    <p className='text-sm text-slate-500 font-semibold mb-1'>Graduation Season</p>
                        <SelectComponent
                            label={'Select graduation season'}
                            value={formData.graduationSeason}
                            light
                            setValue={handleGradSeasonChange}
                            options={[
                                { title: 'Fall', value: 'Fall' },
                                { title: 'Spring', value: 'Spring' }
                            ]}
                        />
                    </div>
                    {clubOptions.length > 0 && (
                        <div className="flex-1">
                            <p className="text-sm text-slate-500 font-semibold mb-1">
                                Club(s)
                            </p>
                            <MultiSelectComponent
                                label={"Select your clubs"}
                                value={selectedClubNames}
                                light
                                setValue={handleClubsChange}
                                clearFilter={() => handleClubsChange(null)}
                                options={clubOptions.map((club) => ({
                                    title: club.name,
                                    club: club,
                                }))}
                                scrollable={true}
                            />
                        </div>
                    )}
                    <div className="flex-1">
                        <p className="text-sm text-slate-500 font-semibold mb-1">
                            Sport(s)
                        </p>
                        <MultiSelectComponent
                            label={"Select your sports"}
                            value={Object.keys(sportsMap)}
                            light
                            setValue={handleSportsChange}
                            clearFilter={() => handleSportsChange(null)}
                            options={SPORT_OPTIONS.map((sport) => ({ title: sport }))}
                            scrollable={true}
                        />
                    <div className="flex flex-row gap-3 pt-3 items-center overflow-x-auto">
                        {Object.entries(sportsMap).map(([sport, isVarsity], index) => (
                            <React.Fragment key={sport}>
                                <div key={sport} className="flex flex-row items-center gap-2 flex-nowrap bg-white p-2 rounded-lg border border-slate-200">
                                    <label className="text-sm text-slate-800 whitespace-nowrap">{`${sport} :`}</label>
                                    <span className={`text-xs ${!isVarsity ? 'text-primary font-bold' : 'text-slate-800'}`}>Club</span>
                                    <Switch
                                        size={15}
                                        onColor={'#4848F2'}
                                        offColor={'#4848F2'}
                                        checkedIcon={null}
                                        uncheckedIcon={null}
                                        onChange={(checked) => handleSportSwitchChange(checked, sport)}
                                        checked={isVarsity}
                                    />
                                    <span className={`text-xs ${isVarsity ? 'text-primary font-bold' : 'text-slate-800'}`}>Varsity</span>
                                </div>
                                {index !== Object.entries(sportsMap).length - 1 && <div className="w-px h-6 bg-slate-200"/>}
                            </React.Fragment>
                        ))}
                    </div>

                    </div>
                </div>
            </EditProfileWrapper>
            <EditProfileCtaButton onClick={handleSubmit} disabled={loading} >
                {hasNextSection ? "Save & continue" : "Save"}
            </EditProfileCtaButton>
        </>
    );
}

export default connect(mapStateToProps)(EditProfileEducation);
