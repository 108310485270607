import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="30px"
            height="30px"
            {...props}
            fill={'currentColor'}
        >
            <path d="M4 5a2 2 0 00-2 2v18h26V7a2 2 0 00-2-2H4zm1 3l1 1 1-1 1 1 1-1 1 1 1-1 1 1 1-1 1 1 1-1 1 1 1-1 1 1 1-1 1 1 1-1 1 1 1-1 1 1 1-1 1 1v14H4V9l1-1zm15.092 3v1.016c-1.26.28-2.049 1.132-2.049 2.326 0 1.187.695 1.923 2.113 2.22l1.014.217c.955.205 1.344.49 1.344.987 0 .588-.599.998-1.446.998-.917 0-1.553-.404-1.629-1.035h-1.576c.05 1.2.89 2.022 2.229 2.273V21h1.816v-1.002c1.393-.265 2.228-1.137 2.227-2.436 0-1.207-.675-1.881-2.239-2.21l-.937-.202c-.912-.194-1.283-.469-1.283-.949 0-.593.54-.97 1.353-.97.788 0 1.36.41 1.436 1.025h1.531c-.04-1.13-.86-1.965-2.088-2.24V11h-1.816zM7 13a1 1 0 100 2h7a1 1 0 100-2H7zm0 4a1 1 0 100 2h3a1 1 0 100-2H7z" />
        </svg>
    )
}

export default SvgComponent
