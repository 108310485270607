import React, {Component} from "react";
import zerostate from "../../img/zerostates/Life at Morgan Pictures.png";
import {connect} from "react-redux";


function mapDispatchToProps(dispatch) {
    return {
        setImageViewerModalOpen: (images) => {
            dispatch({
                type: "SET_IMAGE_VIEWER_MODAL_OPEN",
                images
            })
        },
    }
}

class UpgradedCompanyPageLifeAtSection extends Component {
    openImageLightbox = (imageURLs, index) => {
        this.props.setImageViewerModalOpen(imageURLs, index);
    }

    render() {
        const company = this.props.company || {};
        const images = company.images || [];
        return (
            <div
                className='bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl'>
                <div className='flex flex-row items-center gap-2 mb-2'>
                    <p className='text-xl font-semibold text-slate-800'>
                        Life at {company.name}
                    </p>
                </div>
                <p className='text-slate-500 text-md mb-5'>
                    Step inside our world and see what makes us different
                </p>
                {images.length === 0 &&
                    <>
                        <div className='w-full relative'>
                            <img src={zerostate} className='w-full blur-sm mb-5 saturate-50 opacity-50'/>
                        </div>
                    </>
                }
                <div className='grid grid-cols-4 gap-3 mt-5'>
                    {images.map((image, index) =>
                        <div key={image} className={'relative flex flex-col items-start group'}>
                            <img
                                onClick={() => this.openImageLightbox(images, index)}
                                className='h-44 w-full rounded-lg bg-slate-50 object-contain  cursor-pointer border border-transparent hover:border-primary'
                                src={image}
                            />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(UpgradedCompanyPageLifeAtSection);
