import axios from '../axios';

export const getCompanyPeople = async (user, company_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {company_id}
        };
        const res = await axios.get("/people/company", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getPerson = async (user, person_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {person_id}
        };
        const res = await axios.get("/people", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getClubPeople = async (user, club_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {club_id}
        };
        const res = await axios.get("/people/club", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const editPerson = async (user, person_id, params_to_edit) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            updated_params: params_to_edit,
            person_id,
        }
        const res = await axios.put("/people", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}
