import React, {PureComponent} from "react";

const selectedClassName = "relative z-10 inline-flex items-center bg-neutral-200 rounded-lg text-neutral-900 px-4 py-2 text-sm font-semibold focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brandCoral ";
const unSelectedClassName = "relative inline-flex items-center px-4 py-2 text-sm font-semibold rounded-lg text-neutral-500 focus:z-20";

class PaginationComponent extends PureComponent {
    state = {
        selectedPage: 1,
        allPages: [1],
        start: 1,
        end: 10,
        numPages:1,
    }

    componentDidMount = () => {
        this.setInfo();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.numResults !== this.props.numResults) {
            this.setInfo();
        }
    }

    setInfo = () => {
        const start = this.props.numResults === 0 ? 0 : 1;
        const end = this.props.pageLength < this.props.numResults ? this.props.pageLength : this.props.numResults;
        this.setState({
            start,
            end,
            numPages: Math.ceil(this.props.numResults / this.props.pageLength),
            allPages: Array( Math.ceil(this.props.numResults / this.props.pageLength)).fill().map((_, i) => i+1),
            selectedPage: 1,
        })
    }

    goToPage = (pageNumber) => {
        if (this.props.containerRef) {
            this.props.containerRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
        }
        if (pageNumber === 0 || pageNumber === this.state.numPages + 1) {
            return
        }
        const start = (pageNumber * this.props.pageLength) - (this.props.pageLength-1);
        const end = pageNumber * this.props.pageLength < this.props.numResults ? pageNumber * this.props.pageLength : this.props.numResults;
        this.props.setBounds(start, end);
        this.setState({selectedPage: pageNumber, start, end});
    }

    render() {
        const selectedPage = this.state.selectedPage;

        const surroundingPages = [];
        for (let i = selectedPage - 2; i < selectedPage + 3; i++) {
            if (i > 0 && i <= this.state.numPages) {
                surroundingPages.push(i);
            }
        }
        return (
            <div className={`flex items-center justify-between border-t border-gray-200 py-3 ${this.props.className}`}>
                {/*Previous & next only visible on mobile*/}
                <div className="flex flex-1 justify-between sm:hidden">
                    <button onClick={() => this.goToPage(this.state.selectedPage-1)}
                       className="relative inline-flex items-center rounded-md bg-slate-100 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                        Previous
                    </button>
                    <button  onClick={() => this.goToPage(this.state.selectedPage+1)}
                       className="relative ml-3 inline-flex items-center rounded-md bg-slate-100 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                        Next
                    </button>
                </div>
                <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                    <div>
                        <p className="body2 text-gray-700">
                            <span className="font-bold">{this.state.start}</span>
                            -
                            <span className="font-bold">{this.state.end} </span>
                            of
                            <span className="font-bold"> {this.props.numResults} </span>
                            results
                        </p>
                    </div>
                    <div>
                        <nav className="isolate inline-flex -space-x-px rounded-md gap-1" aria-label="Pagination">
                            <button onClick={() => this.goToPage(this.state.selectedPage-1)}
                            className="relative inline-flex items-center rounded-l-md px-2 py-2 rounded-lg text-neutral-500 hover:bg-neutral-100" disabled={this.props.loading}>
                                <span className="sr-only">Previous</span>
                                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd"
                                        d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                                        clipRule="evenodd"/>
                                </svg>
                            </button>
                            {this.state.allPages.length <= 6 ?
                                <>
                                    {this.state.allPages.map(pageNumber => {return (
                                        <button aria-current="page"
                                        // onClick={() => this.goToPage(pageNumber)}
                                            className={pageNumber === this.state.selectedPage ? selectedClassName : unSelectedClassName}>
                                            {pageNumber}
                                        </button>
                                    )})}
                                </>
                            :
                                <>
                                    {this.state.selectedPage > 3 ?
                                        <>
                                            <button aria-current="page"
                                            //  onClick={() => this.goToPage(1)}
                                                className={1 === this.state.selectedPage ? selectedClassName : unSelectedClassName}>
                                                1
                                            </button>
                                            <button aria-current="page" className={unSelectedClassName}>
                                                ...
                                            </button>
                                        </>
                                    : null }
                                    {surroundingPages.map(pageNumber => {return (
                                        <button aria-current="page"
                                        // onClick={() => this.goToPage(pageNumber)}
                                            className={pageNumber === this.state.selectedPage ? selectedClassName : unSelectedClassName}>
                                            {pageNumber}
                                        </button>
                                    )})}
                                    {this.state.selectedPage < this.state.numPages-2 ?
                                        <>
                                            <button aria-current="page" className={unSelectedClassName}>
                                                ...
                                            </button>
                                            <button aria-current="page"
                                            //  onClick={() => this.goToPage(this.state.numPages)}
                                                className={this.state.numPages === this.state.selectedPage ? selectedClassName : unSelectedClassName}>
                                                {this.state.numPages}
                                            </button>
                                        </>
                                    : null }
                                </>
                            }
                            <button onClick={() => this.goToPage(this.state.selectedPage+1)}
                            className="relative inline-flex items-center rounded-r-md px-2 py-2 rounded-lg text-neutral-500 hover:bg-neutral-100" disabled={this.props.loading}>
                                <span className="sr-only">Next</span>
                                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd"
                                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                        clipRule="evenodd"/>
                                </svg>
                            </button>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
}

export default PaginationComponent
