import React, { useState } from "react";
import { Button } from "../atoms/Button";
import { uploadDoc } from "../../api/firebase";
import Dropzone from "react-dropzone";
import SvgPostAdd from "../icons/SvgPostAdd";
import { v4 as uuid } from "uuid";
import SvgInfo from "../icons/SvgInfo";
import InputWrapper from "../atoms/inputs/InputWrapper";
import EditProfileWrapper from "./EditProfileWrapper";
import EditProfileCtaButton from "./EditProfileCtaButton";
import useUser from "../../hooks/useUser";
import { editUser, getUser } from "../../api/student/users";
import { toast } from "react-toastify";
import AlertModal from "../atoms/modals/AlertModal";

function EditProfileResumeLinkedin({
    goToNextSection,
    hasNextSection,
    handleResync,
    setLoading,
    loading,
}) {
    const { user, setUser, authUser } = useUser();
    const [resumeUrl, setResumeUrl] = useState(() => user.resume || "");
    const [uploadingResume, setUploadingResume] = useState(false);
    const [buttonMessage, setButtonMessage] = useState(() =>
        user.resume
            ? "Resume already uploaded. Drop a new file to replace it"
            : "Select a file or drag and drop your resume here"
    );
    const [error, setError] = useState("");
    const [linkedin, setLinkedin] = useState(() => user.linkedin || "");

    const hasDifferentLinkedin = user.linkedin !== linkedin;

    const updateProfile = async () => {
        try {
            setLoading(true);
            await editUser(authUser, {
                resume: resumeUrl,
            });
            if (hasDifferentLinkedin) {
                await handleResync(linkedin);
            }
            if (hasNextSection) {
                goToNextSection();
            }
            const { user: userUpdated } = await getUser(authUser);
            setUser(userUpdated);
        } catch (error) {
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = () => {
        const validationResult = validateLinkedinUrl(linkedin);
        if (validationResult === true) {
            toast.promise(updateProfile, {
                pending: "Updating profile...",
                success: "Profile updated!",
                error: "Failed to update profile",
            });
        } else {
            setError(validationResult);
        }
    };

    const validateLinkedinUrl = (url) => {
        const linkedinUrlPattern = /^(https?:\/\/)?(www\.)?linkedin\.com\/.+/;
        if (linkedinUrlPattern.test(url)) {
            return true;
        }
        return 'The LinkedIn URL must start with "http://www.linkedin.com" or "https://www.linkedin.com"';
    };

    const onDrop = async (acceptedFiles) => {
        const newUuid = uuid();
        setUploadingResume(true);
        const url = await uploadDoc("resumes", newUuid, acceptedFiles[0]);
        setButtonMessage("Resume uploaded!");
        setResumeUrl(url);
        setUploadingResume(false);
    };

    return (
        <>
            <EditProfileWrapper>
                <div className="w-full ">
                    <Dropzone
                        onDrop={onDrop}
                        accept={{
                            "image/png": [".png", ".jpeg"],
                            "text/html": [".pdf"],
                        }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="cursor-pointer">
                                <input {...getInputProps()} />
                                <div className="w-full flex flex-col gap-3 items-center justify-center rounded-xl h-40 md:h-52 border border-slate-300 border-dashed px-6 text-slate-500 hover:bg-slate-50">
                                    <SvgPostAdd className={"w-8 h-8"} />
                                    <p className="text-slate-500 text-sm ml-2">
                                        {buttonMessage}
                                    </p>
                                    <Button variant={"secondary"} size={"sm"}>
                                        {user.resume
                                            ? "Replace file"
                                            : "Select file"}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                    <div className="flex flex-row md:items-center justify-center text-primary gap-1 text-sm mt-2">
                        <SvgInfo className={"w-4 h-4 mt-.5 md:mt-0"} />
                        <p className="">
                            A resume is essential for recruiters looking to find
                            candidates.
                        </p>
                    </div>
                </div>
                <div className="mt-20">
                    <>
                        <div>
                            <InputWrapper
                                label="Full LinkedIn URL"
                                type="text"
                                name="linkedin"
                                placeholder="Paste the full URL to your LinkedIn here (e.g. https://www.linkedin.com/in/john-smith)"
                                value={linkedin}
                                onChange={(e) => setLinkedin(e.target.value)}
                            />
                            {error && (
                                <p className="text-red-500 text-xs mt-2">
                                    {error}
                                </p>
                            )}
                        </div>
                    </>
                </div>
            </EditProfileWrapper>
            {hasDifferentLinkedin ? (
                <AlertModal
                    title="Are you sure you want to re-sync your LinkedIn profile? "
                    description="This will automatically update your Professional Experience from LinkedIn. Note: you can only re-sync your LinkedIn once every 30 days"
                    onConfirm={() => onSubmit()}
                    disabled={!linkedin || loading || uploadingResume}
                >
                    <EditProfileCtaButton>
                        {hasNextSection ? "Save & continue" : "Save"}
                    </EditProfileCtaButton>
                </AlertModal>
            ) : (
                <>
                    <EditProfileCtaButton
                        onClick={onSubmit}
                        disabled={!linkedin || loading || uploadingResume}
                    >
                        {hasNextSection ? "Save & continue" : "Save"}
                    </EditProfileCtaButton>
                </>
            )}
        </>
    );
}

export default EditProfileResumeLinkedin;
