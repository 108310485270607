import React, {useState} from 'react';
import SvgUsers from "../components/icons/SvgUsers";
import ProfilePicture from "../components-recruiter/atoms/ProfilePicture";
import CircularProgress from "./atoms/CircularProgress";
import RecruitPremium from './atoms/RecruitPremium';
import CollapseComponent from './atoms/CollapseComponent';
import SvgChevronDown from './icons/ChevronDown';

function ReferralWidget({user}) {
    const [inviteLink, setInviteLink] = useState("https://app.recruitu.com/authentication?activeTab=signup&referred=" + user.username);
    const [copyStatus, setCopyStatus] = useState('idle'); // 'idle', 'copied'
    const [isCollapsed, setIsCollapsed] = useState(true);
    const referrals = user.referrals || [];


    function handleCopyLink() {
        navigator.clipboard.writeText(inviteLink)
            .then(() => setCopyStatus('copied'))
            .catch(() => setCopyStatus('idle'));

        setTimeout(() => setCopyStatus('idle'), 3000); // Reset after 3 seconds
    }

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className='bg-primary flex flex-col gap-4 shadow-lg shadow-slate-200 p-5 text-white border border-slate-300 rounded-xl'>
            <div className='flex flex-col'>
                <div className='flex flex-row items-center gap-2'>
                    <SvgUsers className={'w-5 h-5'}/>
                    <p className='text-xl font-semibold'>
                        Your Referrals
                    </p>
                </div>
                <RecruitPremium user={user} />
            </div>
            <div className='flex flex-row gap-4 items-center'>
                <div className='flex-1'>
                    <p className='text-md font-medium'>Completed referrals</p>
                </div>
                <div className='flex flex-row gap-2 items-center justify-center'>
                    <CircularProgress value={referrals.length} target={3} size={30}/>
                    <p className='text-lg font-bold'>
                        <span className='text-lg'>{referrals.length} / 3</span> 
                    </p>
                </div>
                {referrals.length > 0 && (
                    <button onClick={toggleCollapse} className="flex items-center">
                        <SvgChevronDown className={`w-5 h-5 transition-transform ${isCollapsed ? '' : 'rotate-180'}`} />
                    </button>
                )}
            </div>
            {referrals.length > 0 && (
                <CollapseComponent isOpen={!isCollapsed}>
                    <div className='flex flex-col bg-white/10 rounded-xl p-3 gap-2 mt-1 flex-1'>
                        {referrals.length === 0 ? (
                            <p className='opacity-50 text-sm'>No referrals yet.</p>
                        ) : (
                            <ul className='flex flex-col gap-2 max-h-[150px] overflow-y-scroll'>
                                {referrals.map((referral, index) => (
                                    <li key={index} className='flex flex-row items-center gap-2 font-medium'>
                                        <ProfilePicture size={32} id={referral.username}
                                                        className={'border border-white/20'}/>
                                        {referral.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </CollapseComponent>
            )}
        </div>
    );
}

export default ReferralWidget;
