import React, {useState} from "react";
import SvgAwardStar from "../icons/SvgAwardStar";
import NewFeatureModal from "../modals/NewFeatureModal";
import { editUser } from "../../api/student/users";

const SAMPLE_NOTIFICATIONS = [
    {
        label: 'New Student Side Overhaul',
        description: "Take a look at what's changed",
        Icon: SvgAwardStar,
        id: 1,
        type: 'feature',
        feature: {
            title: 'New Student Side Overhaul',
            description: 'We have made significant optimizations to the platform with a new information architecture that makes it easier to keep track of things. We also added quick links to the home that lets users easily get help from the RecruitU team or find educational content about RecruitU.',
            bulletPoints: [
                'Fresh new logo and rebrand',
                'Overhauled dashboard',
                'New detailed company information',
                'This notification feature'
            ],
            images: ['/path/to/image1.jpg', '/path/to/image2.jpg'], // Add actual image paths
        }
    }
];


function NotificationsPanel({setUser, user, authUser}) {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState(null);

    const handleNotificationClick = async (feature) => {
        setSelectedFeature(feature);
        setModalOpen(true);

        const newUser = {...user};
        newUser.unread_notification = false;
        setUser(newUser);
        await editUser(authUser, {unread_notification: false})

    };

    const closeModal = () => {
        setModalOpen(false);
        setSelectedFeature(null);
    };

    return (
        <div className='bg-white shadow-xl rounded-xl border-slate-300 border overflow-hidden'>
            <div className='flex flex-row items-center justify-between p-3 min-w-[320px]' style={{zIndex: 1000}}>
                <p className='font-semibold'>My notifications</p>
                {/*<div className='opacity-50 cursor-pointer hover:opacity-100'>Clear</div>*/}
            </div>
            <div className='flex flex-col'>
                <p className='my-5 px-3 text-sm text-slate-500 hidden only:flex'>No notifications to show...</p>
                {SAMPLE_NOTIFICATIONS.map(item => (
                    <div
                        key={item.id}
                        className='flex flex-row gap-2 py-2 m-1 px-3 hover:bg-slate-100 rounded-lg cursor-pointer'
                        onClick={() => handleNotificationClick(item.feature)}
                    >
                        <item.Icon className='w-5 h-5 text-primary'/>
                        <div>
                            <p className='font-semibold text-primary -mt-0.5'>{item.label}</p>
                            <p className='text-slate-500 text-sm'>{item.description}</p>
                        </div>
                        {/* <div className='w-2 h-2 rounded-full bg-red-400 ml-auto my-auto animate-pulse'/> */}
                    </div>
                ))}
            </div>
            <NewFeatureModal
                isOpen={modalOpen}
                closeModal={closeModal}
                feature={selectedFeature}
            />
        </div>
    );
}

export default NotificationsPanel;
