import { smartListsIndex, jobsIndex, clubListsIndex, userListsIndex, eventsIndex } from "./typesense";
import { NUM_LISTS_SHOWN } from "./constants";
import { DateTime } from 'luxon';



export const searchSmartLists = async (query, page=1, company_id) => {
    const filter = `company_id:=${company_id}`;
    const searchParams = {
        q: query,
        query_by: 'name',
        per_page: NUM_LISTS_SHOWN,
        page: page,
        filter_by: filter,
    }
    const response = await smartListsIndex.search(searchParams);
    const results = response.hits.map((hit) => {
        return hit.document;
    })
    return results;
}


export const searchUserLists = async (query, page=1, company_id) => {
    const filter = `company_id:=${company_id}`;
    const searchParams = {
        q: query,
        query_by: 'name',
        per_page: NUM_LISTS_SHOWN,
        page: page,
        filter_by: filter,
    }
    const response = await userListsIndex.search(searchParams);
    const results = response.hits.map((hit) => {
        return hit.document;
    })
    return results;
}


export const searchClubLists = async (query, page=1, company_id) => {
    const filter = `company_id:=${company_id}`;
    const searchParams = {
        q: query,
        query_by: 'name',
        per_page: NUM_LISTS_SHOWN,
        page: page,
        filter_by: filter,
    }
    const response = await clubListsIndex.search(searchParams);
    const results = response.hits.map((hit) => {
        return hit.document;
    })
    return results;
}



export const recruiterJobSearch = async (query, companyId, page=1) => {
    const filter = `deprecated:=${false} && creator:=${companyId}`;
    const searchParams = {
        q: query,
        query_by: 'name,company_name',
        filter_by: filter,
        per_page: 3,
        page: page,
        sort_by: "exclusive:desc,created_at(missing_values: last):desc",

    }
    
    const response = await jobsIndex.search(searchParams);
    const jobsResults = response.hits.map((hit) => {
        return hit.document;
    })
    return jobsResults;
}


export const recruiterEventSearch = async (query, companyId, page=1) => {
    const filter = `deprecated:=false && creator:=${companyId}`;
    const searchParams = {
        q: query,
        per_page: 3,
        filter_by: filter,
        page,
        query_by: 'name,company_name',
        sort_by: 'exclusive:desc,event_date(missing_values: last):asc',
    }
    const response = await eventsIndex.search(searchParams);
    const eventsResults = response.hits
        .map((hit) => {
        return hit.document;
    })
    return eventsResults;
}