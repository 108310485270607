import React from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {connect} from "react-redux";
import {Button} from "../atoms/Button";
import SvgLogoPrimary from "../icons/SvgLogoPrimary";
import SvgStars from "../icons/SvgStars";
import SvgAwardStar from "../icons/SvgAwardStar";
import SvgPlus from "../icons/SvgPlus";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class NewFeatureModal extends React.Component {
    render() {
        const {feature, isOpen, closeModal} = this.props;

        if (!feature) {
            return null;
        }

        return (
            <ModalComponent
                isOpen={isOpen}
                backgroundColor={'white'}
                size={'md'}
                toggle={closeModal}
                FooterComponent={
                    <div className='flex w-full flex-row items-center justify-center gap-3'>
                        <Button onClick={closeModal} variant='secondary'>
                            Close
                        </Button>
                    </div>
                }
            >
                <div className='flex flex-col items-center'>
                    <SvgLogoPrimary className={'w-24 h-8'}/>
                    <p className='text-xs uppercase font-black mt-5 text-primary mb-1'>
                        UPDATE NOTES
                    </p>
                    <p className='text-3xl font-bold mb-2'>{feature.title}</p>
                    <p className='text-center text-slate-500'>{feature.description}</p>
                    <div className='flex flex-row items-center gap-2 font-semibold text-primary mt-10'>
                        <SvgAwardStar/>
                        Key Additions:
                    </div>
                    <ul className='flex flex-col items-center gap-2 py-3'>
                        {feature.bulletPoints.map((point, index) => (
                            <li key={index} className='flex flex-row font-medium text-sm items-center gap-2'>
                                <SvgPlus className={'w-4 h-4'}/>
                                {point}
                            </li>
                        ))}
                    </ul>
                    {/*<div className='flex flex-row flex-wrap gap-2'>*/}
                    {/*    {feature.images.map((image, index) => (*/}
                    {/*        <img key={index} src={image} alt={`Feature image ${index + 1}`} className='w-1/3 rounded'/>*/}
                    {/*    ))}*/}
                    {/*</div>*/}
                </div>
            </ModalComponent>
        );
    }
}

export default connect(mapStateToProps)(NewFeatureModal);
