import React, {Component} from "react";
import {calculateStatistics, renderQuestionCategoryIcon, scoreColor} from "../../utils/stats";
import CollapseComponent from "../atoms/CollapseComponent";
import ResourcesQuestionCard from "./ResourcesQuestionCard";
import ResourcesZeroState from "./ResourcesZeroState";
import {roundTo} from "../../utils/strings";


class ResponsesCategoryGroupDashboard extends Component {
    state = {
        isCollapsed: true,
    }

    countCompletedQuestions = () => {
        const {keyedAnswers, questions} = this.props;
        return questions.filter(question => keyedAnswers[question.id] != null).length;
    }

    render() {
        const category = this.props.category
        const answers = this.props.answers
        const Icon = renderQuestionCategoryIcon(category);
        const completedCount = this.countCompletedQuestions();
        const completedPercent = (completedCount / this.props.questions?.length) * 100
        return (
            <div className='border overflow-hidden border-slate-200 hover:border-primary/50 rounded-xl'>
                <div className='p-5 flex flex-row hover:bg-slate-50 cursor-pointer items-center gap-3 justify-between'
                     onClick={() => this.props.selectTopicToPreview(category)}>
                    <div className='flex flex-row gap-3'>
                        <Icon className={'w-10 h-10 mt-2 text-slate-700'}/>
                        <div>
                            <div className='flex flex-row items-center'>
                                <p className='text-slate-900 text-lg font-bold truncate'>
                                    {category}
                                </p>
                                <p className='text-sm text-slate-500 font-semibold ml-2'>
                                    ({completedCount}/{this.props.questions.length} complete)
                                </p>
                            </div>
                            <div className='flex flex-row items-center gap-3'>
                                <p className='text-sm font-semibold text-primary'>
                                    {roundTo(completedPercent, 0)}%
                                </p>
                                <div className='bg-primary/10 mt-1 h-min rounded-full w-60 overflow-hidden'>
                                    <div className={'h-2 bg-primary'} style={{width: completedPercent + '%'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <p className={`m-0 text-xl font-bold`}
                           style={{color: scoreColor(calculateStatistics(answers).averageScore)}}
                        >
                            {calculateStatistics(answers).averageScore}
                        </p>
                        <p className=' text-xs font-semibold text-slate-400'>
                            Average<br/> Score
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResponsesCategoryGroupDashboard
