import axios from '../axios';


export const getResources = async (authUser) => {
  try {
    const token = await authUser.getIdToken();
    const response = await axios.get(`/resources`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;
    const resources = mapResourcesToTopics(data);
    return resources;
  } catch (error) {
    console.error('Error fetching resources:', error);
    throw error;
  }
};


function mapResourcesToTopics(resources) {
    const orderedResources = resources.sort((a, b) => a.order - b.order);

    const resourcesTableOfContents = {};
    const resourceAccessMap = {};

    orderedResources.forEach((resource) => {
        const { topic, subtopic: subTopic, name, description, images, pdfs, footnote } = resource;

        if (!topic) return;

        // Handle table of contents
        if (!resourcesTableOfContents[topic]) {
            resourcesTableOfContents[topic] = {};
        }

        const tocKey = subTopic || name;
        resourcesTableOfContents[topic][tocKey] = `${topic}%%${tocKey}`;

        // Handle resource access map
        const accessKey = `${topic}%%${subTopic || name}`;
        if (!resourceAccessMap[accessKey]) {
            resourceAccessMap[accessKey] = [];
        }

        resourceAccessMap[accessKey].push({
            title: name,
            content: getHtmlContent(description, images, footnote, pdfs),
        });
    });

    return  {resourceAccessMap, resourcesTableOfContents};
    
}

function getHtmlContent(description, images, footnote, pdfs) {
    let htmlContent = description ?? ''


    if (images) {
        images.forEach((image) => {
            htmlContent += `<img src="${image.url}" alt="image" />`;
        });
    }

    if (pdfs) {
        pdfs.forEach((pdf) => {
            htmlContent += `<iframe src="${pdf.url}" width="100%" height="600px"></iframe>`;
        });
    }

    if (footnote) {
        htmlContent += footnote
    }

    return htmlContent;
}