import React, {PureComponent} from "react";
import SvgLinkedIn from "../icons/SvgLinkedIn";
import NetworkStatusTrackerComponet from "./NetworkStatusTrackerComponent";
import SvgChevronRight from "../icons/SvgChevronRight";
import {Link} from "react-router-dom";
import SvgEdit from "../icons/SvgEdit";
import SvgRemove from "../icons/SvgRemove";
import { editPerson } from "../../api/student/people";
import { APP_ADMINS } from "../../utils/constants";
import {removeAlumniMember} from "../../api/student/admin";
import EditContactModal from "../atoms/modals/EditContactModal";
import CompanyLogo from "../atoms/CompanyLogo";
import SvgCheck from "../icons/SvgCheck";

class ClubPageAlumniListItem extends PureComponent {
    state = {
        editContactModalOpen: false
    }
    removeAlumni = async () => {
        await removeAlumniMember(this.props.authUser, this.props.club.id, this.props.item.id)
        this.props.getClubInfo();
    }

    openEditContactModal = () => {
        this.setState({editContactModalOpen: true})
    }

    closeEditContactModal = () => {
        this.setState({editContactModalOpen: false})
    }

    render() {
        const member = this.props.item;
        return (
            <div
                className='row-ac-jb py-4 border-b border-b-neutral-100 last:border-none'>
                <div className='flex flex-row items-center wrap gap-1' style={{flex: .7}}>
                    <p className='body2-bold base-black-70'>
                        {member.first_name} {member.last_name} {!!member.grade && ("'" + member.grade.slice(-2))}
                    </p>
                    {!!member.linkedin ?
                        <a className='row-ac text-neutral-400 hover:text-neutral-600 cursor-pointer' target="_blank"
                           href={member.linkedin}>
                            <SvgLinkedIn className={'w-5 h-5'}/>
                        </a>
                        : null}
                </div>
                {member.company_id ?
                    <div className='flex flex-row items-center gap-2 primary cursor-pointer' style={{flex: .6}}>
                        <CompanyLogo id={member.company_id} className={'w-8 h-8'}/>
                        <Link to={{pathname: '/company/'+member.company_id, state: {company_id: member.company_id}}}>
                            <div className='flex flex-row wrap items-center gap-0'>
                                <p className='body3-bold primary'>
                                    {member.company_name.length > 15 ? member.company_name.slice(0, 15) + '...' : member.company_name}
                                </p>
                                <SvgChevronRight className={'w-5 h-5'}/>
                            </div>
                        </Link>
                    </div>
                    :
                    <div className='row-ac primary' style={{flex: .6}}>
                        <p className='body3-bold text-slate-500'>
                            {member.company_name}
                        </p>
                    </div>
                }
                <div className='row-ac primary' style={{flex: .5}}>
                    <p className='body4 base-black-50'>
                        {member.title || member.industry || '--'}
                    </p>
                </div>
                <div className='flex flex-row items-center gap-2 justify-end' style={{flex: .55}}>
                    <NetworkStatusTrackerComponet personId={member.id} clubId={this.props.club.id} person={member} openPeek={() => {}} sm/>
                    <div
                        onClick={() => this.props.openViewAlumniModal(member)}
                        className={`px-3 py-1.5 rounded-lg bg-primary-light primary body3-bold cursor-pointer`}>
                        View
                    </div>
                    {APP_ADMINS.includes(this.props.authUser?.uid) &&
                        <SvgEdit onClick={this.openEditContactModal}
                                className={'w-5 h-5 text-neutral-400 hover:text-neutral-600 cursor-pointer'}/>
                    }
                    {/* <SvgRemove onClick={this.removeAlumni}
                                 className={'w-5 h-5 text-neutral-400 hover:text-neutral-600 cursor-pointer'}/> */}
                </div>
                <EditContactModal
                    open={this.state.editContactModalOpen}
                    person={member}
                    getClubInfo={this.props.getClubInfo}
                    close={this.closeEditContactModal}
                />
            </div>
        )
    }
}

export default ClubPageAlumniListItem
