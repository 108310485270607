import React, {PureComponent} from "react";
import SvgArrowDropDown from "../icons/SvgArrowDropDown";
import DropdownComponent from "./DropdownComponent";
import StatusDisplay from "./StatusDisplay";

class StatusSelect extends PureComponent {
    render() {
        return (
            <DropdownComponent
                ToggleComponent={
                    <StatusDisplay
                        value={this.props.value}
                        label={this.props.label}
                        dropDown
                        placeholder={this.props.placeholder}
                        sm={this.props.sm}
                        table
                    />
                }
                disabled={this.props.disabled}
                value={this.props.value}
                selectDropdown
                setValue={this.props.setValue}
                options={this.props.options}
                statusSelect
                light={this.props.light}
            />
        )
    }
}

export default StatusSelect
