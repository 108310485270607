import React, {PureComponent} from "react";
import JobPageAboutCompanyWidget from "../components/jobpage/JobPageAboutCompanyWidget";
import JobPageAboutJobWidget from "../components/jobpage/JobPageAboutJobWidget";
import JobPageTitleWidget from "../components/jobpage/JobPageTitleWidget";
import {getJob} from "../api/recruiter/jobs";
import {connect} from "react-redux";
import {wait} from "@testing-library/user-event/dist/utils";
import PageContentWrapper from "../components/wrappers/PageContentWrapper";
import GoBack from "../components/atoms/GoBack";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        constants: state.userReducer.constants,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateNetwork: (network) => {
            dispatch({
                type: "UPDATE_NETWORK",
                network,
            })
        },
        setPath: (path) => {
            dispatch({
                type: "SET_PATH",
                path,
            })
        },
    }
}

class RecruiterJobPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            job: {},
            application: {},
            previousPage: null,
            processingPrev: false,
            processingNext: false,
            people: [],
            jobLoading: true,
            noAccess: false,
        };
    }

    componentDidMount() {
        this.fetchJob();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.fetchJob();
        }
    }

    fetchJob = async () => {
        this.setState({jobLoading: true})
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const jobId = lastArg.split("?")[0];
        const result = await getJob(this.props.authUser, jobId);
        this.setState({job: result.job, jobLoading: false, noAccess: result.job.company_id !== this.props.user.company_id});
    }

   

    startApplication = async () => {
        
    };

    navigateNextJob = () => {
        
    }

    navigatePrevJob = () => {

    }


    render() {
        const listing = this.state.job || {};
        const previousPage = this.props.location.state?.previousPage.location;
        return (
            <PageContentWrapper>
                {this.state.noAccess ?
                    <div className='flex h-full justify-center items-center'>
                        <p>404: This job does not exist or you don't have access to it.</p>
                    </div>
                    :
                    null
                }
                <div className={'pt-2 w-full max-w-[1100px] mx-auto px-4 pt-5 overflow-y-scroll mb-4'}>
                    <div className='flex flex-row items-center gap-3 mb-3'>
                        <GoBack/>
                    </div>
                    <JobPageTitleWidget listing={listing} previousPage={previousPage} user={this.props.user}
                                        loading={this.state.jobLoading} constants={this.props.constants}
                                        authUser={this.props.authUser} fetchJob={this.fetchJob}/>
                    <div className='card flex flex-row gap-3'>
                        <div className='flex flex-1'>
                            <div className="w-full bg-zinc-100 text-center py-2 rounded-lg">
                                <p>
                                This is a preview of the job as it will appear to
                                students.
                                </p>
                            </div>
                        </div>
                        
                    </div>
                    <JobPageAboutJobWidget listing={listing} loading={this.state.jobLoading}/>
                    {!!listing.company_id ?
                        <JobPageAboutCompanyWidget listing={listing} authUser={this.props.authUser}
                                                   user={this.props.user} setUser={this.props.setUser} hideFollowButton/>
                        : null}
                </div>


            </PageContentWrapper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterJobPage);
