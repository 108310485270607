import React, {Component} from "react";
import CompanyEventItem from "../jobboard/CompanyEventItem";
import {getRecommendedEvents} from "../../api/student/events";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {NUM_EVENTS_SHOWN} from "../../utils/constants";
import {Button} from "../atoms/Button";
import {discoverEvents} from "../../api/student/events";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

class CalendarSuggestedJobsSidebar extends Component {
    state = {
        recommendedEvents: [],
        selectedEvent: {},
        eventModalOpen: false,
    }

    componentDidMount = () => {
        this.recommendedEvents();
    }

    navigateToDiscover = () => {
        this.props.history.push({
            pathname: `/discover`,
            state: {
                activeTab: 'Events',
            }
        })
    }

    recommendedEvents = async () => {
        const {events} = await discoverEvents(this.props.authUser, {query: "", limit: NUM_EVENTS_SHOWN})
        this.setState({recommendedEvents: events, eventsLoading: false});
    }

    openEventPage = (selectedEvent) => {
        this.props.history.push(`/event/${selectedEvent.id}`);
    }

    render() {
        const userEvents = this.props.userEvents || [];
        const userEventIds = userEvents.map(event => event.id);
        const events = this.state.recommendedEvents.filter(event => !userEventIds.includes(event.id))
        return (
            <div className={`${this.props.open ? 'w-[20vw]' : 'w-[0px]'} flex flex-col overflow-hidden ml-3`}
                 style={{transition: 'all 150ms ease-in-out',}}
            >
                <div className='w-[20vw] flex flex-col flex-1 gap-3 overflow-hidden'>
                    <p className='text-xl text-slate-800 font-semibold'>
                        Suggested Events
                    </p>
                    <div className='flex flex-col flex-1 gap-3 overflow-scroll'>
                        {events.slice(0, 3).map((item, index) =>
                            <CompanyEventItem
                                key={item.id}
                                item={item}
                                openEventPage={this.openEventPage}
                                noShadow
                            />
                        )}
                    </div>
                </div>
                <div className='flex flex-col'>
                    <Button onClick={this.navigateToDiscover}>
                        Search All Events
                    </Button>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(CalendarSuggestedJobsSidebar))
