import React, { useState } from "react";
import { connect } from "react-redux";
import DiversityQuestions from "../personalizationflow/DiversityQuestions";
import { editUser, getUser } from "../../api/student/users";
import { TOAST_OPTIONS } from "../../utils/constants";
import { toast } from 'react-toastify';
import EditProfileWrapper from "./EditProfileWrapper";
import EditProfileCtaButton from "./EditProfileCtaButton";
import { hasPersonalizationComplete } from "../../utils/profileUtils";
import get from "lodash.get";
import { equalEmploymentRequiredProperties } from "../../utils/profileUtils";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
    }
}

function EditProfileEqualEmployment({ authUser, setUser, user, loading, setLoading, goToNextSection, hasNextSection }) {
    const [state, setState] = useState(() => {
        return {
           gender: user.diversity?.gender ?? '',
           race: user.diversity?.race ?? [],
           veteran: user.diversity?.veteran ?? '',
           disabled: user.diversity?.disabled ?? '',
           firstGeneration: user.diversity?.first_generation ?? '',
           lgbtq: user.diversity?.lgbtq ?? '',
           secondaryEmail: user.secondary_email ?? '',
        }
    });

    const [errors, setErrors] = useState(() => {
        return equalEmploymentRequiredProperties.reduce((acc, prop) => {
            const isValid = Array.isArray(get(user, prop))
                ? get(user, prop).length > 0
                : !!get(user, prop);
            acc[prop] = isValid ? "" : "Required field";
            return acc;
        }, {});
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState(prev => ({ ...prev, [name]: value }));
    };

    const handleMultiSelectChange = (inputName, selectedValue) => {
        const array = [...state[inputName]];

        let updatedArray;
        if (array.includes(selectedValue)) {
            updatedArray = array.filter((item) => item !== selectedValue);
        } else if (selectedValue === null) {
            updatedArray = [];
        } else {
            updatedArray = [...array, selectedValue]
        }
        setState(prev => ({ ...prev, [inputName]: updatedArray }));
    }

    const updateProfile = async () => {
        try {
            setLoading(true);
            const params = {
                diversity: {
                    ...user.diversity ?? {},
                    gender: state.gender,
                    race: state.race,
                    veteran: state.veteran,
                    disabled: state.disabled,
                    first_generation: state.firstGeneration,
                    lgbtq: state.lgbtq,
                },
                secondary_email: state.secondaryEmail,
            }
            // because the steps are now in different sections the completed_personalization depends of both sections props
            if (hasPersonalizationComplete({...user, ...params})) {
                params.completed_personalization = true
            }
            await editUser(authUser, params);
            const { user: updatedUser } = await getUser(authUser);

            setUser(updatedUser);
            if (hasNextSection) {
                goToNextSection();
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = async () => {
        if (!state.gender || !state.race.length || !state.veteran || !state.disabled || !state.firstGeneration || !state.lgbtq) {
            toast.error('Please fill out all fields!', TOAST_OPTIONS);
            return;
        }
        toast.promise(
            updateProfile,
            {
                pending: 'Updating preferences...',
                success: 'Demographics and preferences updated!',
                error: 'Failed to update preferences!',
            }
        );
    }

    const handleSelectChange = (inputName, value) => {
        setState(prev => ({ ...prev, [inputName]: value }));
    };

    const handleEmailChange = (e) => {
        setState(prev => ({ ...prev, secondaryEmail: e.target.value }));
        setErrors({ ...errors, secondary_email: "" });
    }

    return (
        <>
            <EditProfileWrapper>
                <DiversityQuestions
                    gender={state.gender}
                    race={state.race}
                    veteran={state.veteran}
                    disabled={state.disabled}
                    firstGeneration={state.firstGeneration}
                    lgbtq={state.lgbtq}
                    handleMultiSelectChange={handleMultiSelectChange}
                    handleSelectChange={handleSelectChange}
                    handleEmailChange={handleEmailChange}
                    secondaryEmail={state.secondaryEmail}
                    errors={errors}
                />
            </EditProfileWrapper>
            <EditProfileCtaButton onClick={handleSubmit} disabled={loading} >
                {hasNextSection ? 'Save & continue' : 'Save'}
            </EditProfileCtaButton>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileEqualEmployment);
