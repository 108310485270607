import { PROFILE_UPDATE_REMINDER_INTERVAL } from "./constants";
import { DateTime } from "luxon";
import get from "lodash.get";

export const educationRequiredProperties = ["major", "grade"];

export const equalEmploymentRequiredProperties = [
    "secondary_email",
    "diversity.gender",
    "diversity.disabled",
    "diversity.first_generation",
    "diversity.veteran",
    "diversity.race",
    "diversity.lgbtq",
];

export const authorizationSponsorshipRequiredProperties = [
    "diversity.authorized",
    "diversity.require_sponsorship",
];

export const isProfileUpdateNeeded = (user) => {
    if (!user || !user.profile_updated_at) {
        return false;
    }

    const now = DateTime.now().toMillis();
    const lastUpdateTime = DateTime.fromISO(user.profile_updated_at).toMillis();

    return now - lastUpdateTime > PROFILE_UPDATE_REMINDER_INTERVAL;
};

// users can resync their linkedin after a month since their last update on their profile
export const canResyncLinkedIn = (user) => {
    if (!user.profile_updated_at || !user.linkedin) {
        return {
            ableToResync: true,
            resyncMessage: "You can resync your LinkedIn"
        }
    }
    const ableToResync =
        DateTime.fromISO(user.profile_updated_at)
            .plus({ months: 1 })
            .toMillis() < DateTime.now().toMillis();
    const daysUntilResync = Math.ceil(
        (DateTime.fromISO(user.profile_updated_at)
            .plus({ months: 1 })
            .toMillis() -
            DateTime.now().toMillis()) /
            (1000 * 60 * 60 * 24)
    );
    const hoursUntilResync = Math.ceil(
        (DateTime.fromISO(user.profile_updated_at)
            .plus({ months: 1 })
            .toMillis() -
            DateTime.now().toMillis()) /
            (1000 * 60 * 60)
    );
    const message =
        daysUntilResync === 1
            ? `${hoursUntilResync} hours`
            : `${daysUntilResync} days`;
    return { ableToResync, resyncMessage: message };
};

export const sections = [
    { id: "profile", title: "Profile Details" },
    { id: "experience", title: "Professional Experience" },
    { id: "education", title: "Education Details" },
    { id: "resume", title: "Resume/LinkedIn" },
    { id: "location", title: "Location Preferences" },
    { id: "role", title: "Role Preferences" },
    { id: "company", title: "Company Preferences" },
    { id: "employment", title: "Equal Employment" },
    { id: "sponsorship", title: "Authorization/Sponsorship" },
];

const sectionsWithUpdateStatus = ["education", "location", "role", "company"];

export const hasAllSectionsComplete = (user, trackers) => {
    return sections.every((section) =>
        isSectionComplete(section.id, user, trackers)
    );
};

export const getSectionStatus = (section, user, trackers) => {
    return !isSectionComplete(section, user, trackers)
        ? "incomplete"
        : isProfileUpdateNeeded(user) &&
          sectionsWithUpdateStatus.includes(section)
        ? "update"
        : "complete";
};

export const isSectionComplete = (section, user, trackers) => {
    switch (section) {
        case "profile":
            return isProfileComplete(user);
        case "experience":
            return isExperienceComplete(user);
        case "education":
            return isEducationComplete(user);
        case "resume":
            return isResumeOrLinkedInComplete(user);
        case "location":
            return isLocationComplete(user);
        case "role":
            return isRoleComplete(user);
        case "company":
            return isCompanyComplete(trackers);
        case "employment":
            return isEqualEmploymentComplete(user);
        case "sponsorship":
            return isAuthorizationSponsorshipComplete(user);
        default:
            return false;
    }
};

export const isProfileComplete = (user) => {
    return (
        !!user.first_name &&
        !!user.last_name &&
        !!user.headline &&
        !!user.city &&
        user.seeking_role != null
    );
};

export const isExperienceComplete = (user) => {
    return user.experiences.length > 0;
};

export const isCompanyComplete = (trackers) => {
    return (
        Object.values(trackers ?? {}).filter(
            (tracker) => tracker.type === "company"
        ).length > 0
    );
};

export const isEducationComplete = (user) => {
    return educationRequiredProperties.every((prop) =>
        Array.isArray(get(user, prop))
            ? get(user, prop).length > 0
            : !!get(user, prop)
    );
};

export const isResumeOrLinkedInComplete = (user) => {
    return !!user.resume && !!user.linkedin;
};

export const isRoleComplete = (user) => {
    return user.role_interests?.length > 0 || user.industries?.length > 0;
};

export const isLocationComplete = (user) => {
    return user.locations.length > 0;
};

export const isEqualEmploymentComplete = (user) => {
    return equalEmploymentRequiredProperties.every((prop) =>
        Array.isArray(get(user, prop))
            ? get(user, prop).length > 0
            : !!get(user, prop)
    );
};

export const isAuthorizationSponsorshipComplete = (user) => {
    return authorizationSponsorshipRequiredProperties.every((prop) =>
        Array.isArray(get(user, prop))
            ? get(user, prop).length > 0
            : !!get(user, prop)
    );
};

export const hasPersonalizationComplete = (user) => {
    return (
        isEqualEmploymentComplete(user) &&
        isAuthorizationSponsorshipComplete(user)
    );
};
