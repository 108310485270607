import axios from '../axios';

export const getLists = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/recruiter/lists", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getSingleList = async (user, list_id, type) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {list_id, type}
        };
        const res = await axios.get("/recruiter/lists/single", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const createList = async (user, params) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/recruiter/lists", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const createSmartList = async (user, params) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params,
        };
        const res = await axios.post("/recruiter/lists/smart", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const editList = async (user, list_id, type, params_to_edit) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            updated_params: params_to_edit,
            list_id,
            type
        }
        const res = await axios.put("/recruiter/lists", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const deleteList = async (user, list_id, type) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {list_id, type}
        };
        const res = await axios.delete("/recruiter/lists", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}