import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import WrappedTextInput from "../../components/atoms/WrappedTextInput";
import {connect} from "react-redux";
import {Button} from "../../components/atoms/Button";
import json from "../../img/lottie/check.json";
import LottieWrapper from "../../components/atoms/LottieWrapper";
import TooltipComponent from "../../components/atoms/TooltipComponent";
import { emailUsers } from "../../api/recruiter/companies";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        recruiter: state.userReducer.user,
    }
}

const ZERO_STATE = {
    subject: '',
    body: '',
    category: '',
    date: '',
    coverPhoto: '',
    processing: false,
    successModalOpen: false
}
class RecruiterMessageStudentModal extends Component {
    state = ZERO_STATE

    closeModal = () => {
        this.props.closeModal()
    }

    closeAllModals = () => {
        this.setState({successModalOpen: false})
        this.props.closeModal()
        this.setState(ZERO_STATE)
    }

    handleInputChange = (e, inputName) => {
        this.setState({[inputName]: e.target.value});
    };


    save = async () => {
        this.setState({processing: true});

        let emails = ''
        if (!!this.props.user?.email) {
            emails = this.props.user.email
        } else {
            emails = this.props.users.map(user => user.email).join(",")
        }
        await emailUsers(this.props.authUser, {
            "emails": emails,
            "subject": this.state.subject,
            "text": this.state.body,
            "recruiter_email": this.props.recruiter.email,
        })        
        this.setState({successModalOpen: true, processing: false})
        
    }
    

    render() {
        const saveDisabled = false
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Send a message'}
                headerToggle
                size={'lg'}
                toggle={this.closeModal}
                showScrollBar
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={this.closeModal} variant='secondary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={saveDisabled}
                            loading={this.state.processing}
                            onClick={this.save}>
                            Send
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div className='flex flex-col gap-5'>
                    <p className='text-slate-700 -mt-3'>
                        Type out a message to send. Your message will be sent from our RecruitU email.
                        Any candidate replies will be sent to your email: <span
                        className='text-primary'>{this.props.recruiter.email}</span>.
                    </p>
                    <div className='flex flex-col gap-1'>
                        <div className='text-xs font-medium text-slate-500'>
                            Recipient{!!this.props.users && 's'} {!!this.props.users &&
                            <span>({this.props.users.length})</span>}
                        </div>
                        <div>
                            {this.props.user &&
                                <p>
                                    {this.props.user.first_name} {this.props.user.last_name} <span
                                    className='text-slate-500'>- {this.props.user.email}</span>
                                </p>
                            }
                            {this.props.users &&
                                <div className='flex flex-row flex-wrap max-h-[136px] overflow-y-scroll'>
                                    {this.props.users.map((user, index) =>
                                        <div key={user.id}>
                                            <TooltipComponent
                                                ToggleComponent={
                                                    <p className='cursor-pointer hover:text-primary'>
                                                        {"name" in user ? `${user.name}` : `${user.first_name} ${user.last_name}`}{index !== this.props.users.length - 1 && ','}&nbsp;
                                                    </p>
                                                }
                                                label={user.email}
                                                hover
                                                placement={'bottom'}
                                            />
                                        </div>
                                    )
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <WrappedTextInput
                        type="text"
                        outerLabel={'Subject'}
                        placeholder={'Add a subject'}
                        className='input-light px-3 w-full'
                        value={this.state.subject}
                        onChange={(e) => this.handleInputChange(e, 'subject')}
                    />
                    <div className='flex flex-col gap-1.5'>
                        <div className='text-xs font-medium text-slate-500'>
                            Body
                        </div>
                        <textarea
                            name="body"
                            value={this.state.body}
                            onChange={(e) => this.handleInputChange(e, 'body')}
                            placeholder={'Type your message here...'}
                            className='input-light text-sm px-3 py-3 base-black-50 w-full min-h-[120px]'
                        />
                    </div>
                </div>
                <ModalComponent
                    isOpen={this.state.successModalOpen}
                    backgroundColor={'white'}
                    header={'Message Sent!'}
                    headerToggle
                    size={'sm'}
                    showScrollBar
                    toggle={this.closeAllModals}
                    FooterComponent={
                        <div className='flex flex-row w-full justify-center items-center gap-3'>
                            <Button
                                variant={'secondary'}
                                onClick={this.closeAllModals}>
                                Close
                            </Button>
                        </div>
                    }
                >
                    <div className='hue-rotate-[120deg]'>
                        <LottieWrapper loop={false} json={json} width={100} height={100}/>
                    </div>
                </ModalComponent>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(RecruiterMessageStudentModal);
