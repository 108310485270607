import React, {PureComponent} from "react";
import TagComponent from "../atoms/TagComponent";
import CompanyLogo from "../atoms/CompanyLogo";
import SvgQuestion from "../icons/SvgQuestion";
import {withRouter} from "react-router-dom";
import {primary, primaryLight} from "../../utils/colors";
import SvgRecommended from "../icons/SvgRecommended";
import Tooltip from "../atoms/modals/Tooltip";
import SvgInfo from "../icons/SvgInfo";
import SvgBarChart from "../icons/SvgBarChart";
import {renderQuestionCategoryIcon, scoreColor} from "../../utils/stats";
import SvgCheck from "../icons/SvgCheck";
import SvgCheckCircle from "../icons/SvgCheckCircle";

class ResourcesQuestionCard extends PureComponent {

    navigateToQuestion = () => {
        this.props.history.push({
            pathname: `/question/${this.props.item.id}`,
            params: {
                item: this.props.item,
                interviewSet: this.props.interviewSet,
            }
        })
    }

    render() {
        const item = this.props.item;
        const score = this.props.score || this.props?.answer?.score;
        const answer = this.props.answer
        const Icon = renderQuestionCategoryIcon(item.category)
        return (
            <div
                key={item.id}
                onClick={this.navigateToQuestion}
                className={`
               flex flex-row gap-3 justify-between items-center
               ${!this.props.disabled && 'hover:bg-slate-50 cursor-pointer'}
               ${this.props.grid ?
                    "border border-slate-200 p-5 px-3 rounded-xl"
                    :
                    "border-b border-slate-200 last:border-transparent px-2 py-3"
                }
                ${this.props.className}
                `}
                style={{transition: '150ms ease'}}
            >
                {(!!score) &&
                    <SvgCheckCircle className={'w-5 h-5 text-primary'}/>
                }
                <div className={'flex flex-col gap-1 flex-1 w-full truncate'}>
                    <p className='text-slate-900 text-lg font-bold truncate'>
                        {item.name}
                    </p>
                    <div className='flex flex-row items-center flex-wrap gap-3 gap-y-1'>
                        {/*<TagComponent*/}
                        {/*    label={item.type}*/}
                        {/*/>*/}
                        <div
                            className='flex gap-1 bg-primary/10 px-2 py-0.5 rounded-lg text-primary text-sm font-bold flex-row items-center'>
                            <Icon className={'w-4 h-4'}/>
                            {item.category || 'Not categorized'}
                        </div>
                        <div className='flex gap-1 text-primary text-sm font-bold flex-row items-center'>
                            <SvgBarChart difficulty={item.difficulty} className={'w-4 h-4'}/>
                            {item.difficulty || 'Not ranked'}
                        </div>
                    </div>
                </div>
                {!!score &&
                    <div className=''>
                        <p className={`m-0 text-xl font-bold`} style={{color: scoreColor(score)}}>
                            {score}
                        </p>
                        <p className='-mt-1 text-xs font-semibold text-slate-400'>
                            Score
                        </p>
                    </div>
                }
            </div>
        )
    }
}

export default withRouter(ResourcesQuestionCard)
