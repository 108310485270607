import React, {PureComponent} from "react";
import SvgStars from "../icons/SvgStars";

class JobBoardLoading extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            ellipsis: ".",
            proTips: [
                "Dress professionally for interviews.",
                "Research the bank thoroughly before applying.",
                "Highlight relevant skills and experiences in your resume.",
                "Prepare for common interview questions.",
                "Practice good body language during interviews.",
                "Network with professionals in the banking industry.",
                "Stay updated with current banking trends.",
                "Demonstrate your interest in the bank during interviews.",
                "Follow up with a thank-you note after interviews.",
                "Develop strong analytical and problem-solving skills.",
                "Seek internships or entry-level positions to gain experience.",
                "Improve your financial knowledge and understanding.",
                "Enhance your communication and teamwork skills.",
                "Attend career fairs and networking events.",
                "Build a professional online presence.",
                "Be prepared to discuss your career goals and aspirations.",
                "Showcase your leadership abilities and initiative.",
                "Develop a strong work ethic and attention to detail.",
                "Stay motivated and persistent throughout the job search process.",
                "Seek mentorship and guidance from professionals in the field.",
            ],
            displayedTip: '',
        };
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState((prevState) => ({
                ellipsis: prevState.ellipsis === "..." ? "." : prevState.ellipsis + ".",
            }));
        }, 900);
        const {proTips} = this.state;
        const randomIndex = Math.floor(Math.random() * proTips.length);
        const randomTip = proTips[randomIndex];

        this.setState({displayedTip: randomTip});
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const {ellipsis, displayedTip} = this.state;
        return (
            <div className='flex flex-col items-center py-16'>
                {this.props.page === 'networking' ?
                    <img
                        className='object-cover w-1/4'
                        src={require('../../img/illustrations/techny-solving-a-mental-health-problem.gif')}/>
                    :
                    <img
                        className='object-cover w-1/4'
                        src={require('../../img/illustrations/techny-searching-the-web-on-tablet.gif')}/>
                }
                {this.props.title ?
                    <p className='heading5 primary mb-2 mt-[-40px] mb-8'>
                        {this.props.title}{ellipsis}
                    </p>
                    :
                    <p className='heading5 primary mb-2 mt-[-40px] mb-8'>
                        Loading {this.props.page === 'networking' ? 'saved contacts' : 'job board'}{ellipsis}
                    </p>
                }
                <p className='body2 flex flex-row text-neutral-400 bg-white px-4 py-3 rounded-xl border border-neutral-200'>
                    <SvgStars className={'w-4 h-4 mr-1 mt-0.5'}/> <span
                    className="body2-bold">Pro Tip:</span>&nbsp;&nbsp;{displayedTip}
                </p>
            </div>
        )
    }
}

export default JobBoardLoading
