import React, {PureComponent} from "react";
import {truncateString} from "../utils/strings";
import Tooltip from "./atoms/modals/Tooltip";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import ClubLogo from "../components-recruiter/atoms/ClubLogo";
import SchoolLogo from "../components-recruiter/atoms/SchoolLogo";
import { Button } from "./atoms/Button";
import SelectClubListModal from "../components-recruiter/modals/SelectClubListModal";
import ProfilePicture from "../components-recruiter/atoms/ProfilePicture";
import Checkbox from "../components/atoms/Checkbox";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        toggleStudentPeek: () => {
            dispatch({
                type: "TOGGLE_STUDENT_PEEK",
            })
        },
        setStudentPeekStudent: (user) => {
            dispatch({
                type: "SET_STUDENT_PEEK_STUDENT",
                user,
            })
        },
    }
}


class StudentSearchListItem extends PureComponent {
    state = {
        profileError: false,
        isSelectListModalOpen: false
    }

    openStudentPeek = () => {
        this.props.toggleStudentPeek();
        this.props.setStudentPeekStudent(this.props.item);
    }

    openStudentPage = (profileId) => {
        this.props.history.push(`/profile/${profileId}`);
    }


    render() {
        const student = this.props.item || {};
        const profilePic = !!student.profile_pic_url && !this.state.profileError ? student.profile_pic_url : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fblank-person.jpeg?alt=media&token=ac3b0019-71c8-4830-932e-de437faf5fc8";
        const clubs = student.clubs || [];
        const club = clubs.length > 0 ? clubs[0] : {};
        const education = student.education || [];
        let college = {};
        if (education.length > 0) {
            college = education[0]
        }
        const columns = this.props.columns
        return (
            <li className='flex bg-white pointer hover:bg-slate-200/50 rounded-lg px-3 mx-[-12px] py-2 flex-row items-center text-slate-500'
                onClick={() => this.openStudentPeek()}
                key={student.id}>
                <div style={{flex: 0.25}} className='flex flex-row items-center justify-center'>
                    <Checkbox
                        checked={this.props.isSelected}
                        onClick={ () => this.props.onSelectStudentChange(student)}
                    />
                </div>
                <div
                    className={'flex flex-row items-center gap-2'}
                    style={{flex: columns[0].flex}}>
                    <ProfilePicture
                        image={student.profile_pic_url}
                        userID={student.id}
                        size={this.props.grid ? 52 : 40}
                        className={'border border-slate-200'}
                    />
                    <div className={'flex flex-col gap-0.5'}>
                        <p className=' text-slate-800 font-semibold text-sm'>
                            {student.first_name} {student.last_name}
                        </p>
                    </div>
                </div>
                <div style={{flex: columns[1].flex}} className='flex flex-row'>
                    <Tooltip
                        toggleComponent={
                            // <img src={education[0].logo_url}
                            //      className='w-10 w-10 rounded-full border border-slate-200 object-contain'/>
                            <SchoolLogo image={education[0]?.logo_url} college={student.college}/>

                        }
                        label={!!education[0] && !!education[0].school ? truncateString(education[0].school, 30) : "College"}
                    />
                </div>
                <div style={{flex: columns[2].flex}} className='flex flex-row items-center'>
                    <div className='flex flex-row items-center relative'>
                        {clubs.length === 0 ?
                            <div
                                className='w-10 w-10 text-xs text-slate-300 text-center flex flex-col items-center justify-center aspect-square border-slate-100 bg-slate-50 border rounded-xl'
                            >
                                <p>
                                    N/A
                                </p>
                            </div>
                            :
                            clubs.slice(0, 2).map((club, index) =>
                                <div key={index}>
                                    <Tooltip
                                        toggleComponent={
                                            <ClubLogo clubId={club}
                                                    className={`${index !== 0 && '-ml-5'} border border-slate-200`}/>
                                        }
                                        label={club.name}
                                    />
                                </div>
                            )}
                        {clubs.length > 2 &&
                            <div
                                className='absolute bg-primary bottom-[-4px] right-[-4px] rounded-full px-1 cursor-pointer'>
                                <p className='text-xs font-bold text-white'>
                                    +{clubs.length - 2}
                                </p>
                            </div>
                        }
                    </div>
                </div>
                <div style={{flex: columns[3].flex}} className='flex flex-row'>
                    <p className='text-xs'>
                        {student.major.join(', ')}
                    </p>
                </div>
                <div style={{flex: columns[4].flex}} className='flex flex-row'>
                    <p className='text-xs'>
                        {!!student.gpa ? student.gpa : "N/A"}
                    </p>
                </div>
                <div style={{flex: columns[5].flex}} className='flex flex-row'>
                    <p className='text-xs'>
                        {student.grade}
                    </p>
                </div>
                {/* <div style={{flex: columns[6].flex}} className='flex flex-row items-end'>
                    <div className='body2-bold primary flex flex-row gap-2 items-center mt-1 ml-auto min-w-[60px]'>
                        <Button size={'sm'} onClick={(e) => {
                            e.stopPropagation();
                            this.setState({isSelectListModalOpen: true})}} className='w-full'>
                            Add to list
                        </Button>
                    </div>
                </div> */}
                <SelectClubListModal
                    items={[student]}
                    type={"user"}
                    isOpen={this.state.isSelectListModalOpen}
                    authUser={this.props.authUser}
                    closeModal={() => this.setState({isSelectListModalOpen: false})}
                />
            </li>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StudentSearchListItem));
