import React, {PureComponent} from "react";
import ClubPageAboutWidget from "../ClubPageAboutWidget";
import ClubPageRecentPlacements from "../ClubPageRecentPlacements";
import { editClub } from "../../../api/student/clubs";
import ClubPageAboutHeader from "../ClubPageAboutHeader";
import ClubPageMissionWidget from "../ClubPageMissionWidget";
import ClubPageLifeAtSection from "../ClubPageLifeAtSection";
import ClubPageStepsToApplyWidget from "../ClubPageStepsToApplyWidget";
import ClubPageSponsorsWidget from "../ClubPageSponsorsWidget";
import GoBack from "../../atoms/GoBack";
import PointsOfContactWidget from "../PointsOfContactWidget";
import {connect} from "react-redux";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}


class ClubAboutTab extends PureComponent {
    state = {
        hidden: []
    }

    componentDidMount = () => {
        this.setState({hidden: this.props.club.hidden})
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.club.hidden !== this.props.club.hidden) {
            this.setState({hidden: this.props.club.hidden})
        }
    }

    hide = async (field) => {
        const hidden = [...this.state.hidden];
        if (hidden.includes(field)) {
            const index = hidden.indexOf(field);
            hidden.splice(index, 1);
        } else {
            hidden.push(field);
        }
        this.setState({hidden})
        await editClub(this.props.authUser, this.props.club.id, {hidden});
    }

    render() {
        const {club, loading, getClubInfo} = this.props;
        const user = this.props.user || {};
        const adminIds = this.props.recruiter ? [] : user.admin.map(club => club.id);
        const isAdmin = adminIds.includes(club.id);
        return (
            <div className='flex flex-col flex-1 overflow-scroll gap-3 px-5 items-center'>
                <div className='max-w-[900px] w-full mt-5 mb-5'>
                    {this.props.recruiter &&
                        <GoBack/>
                    }
                    <ClubPageAboutHeader club={club} loading={loading} adminIds={adminIds} getClubInfo={getClubInfo}
                                         numMembers={this.props.numMembers}/>
                    <ClubPageMissionWidget club={club} loading={loading} getClubInfo={getClubInfo} adminIds={adminIds} hide={this.hide} hidden={this.state.hidden}/>
                    <ClubPageAboutWidget club={club} loading={loading} getClubInfo={getClubInfo} isAdmin={isAdmin} hide={this.hide} hidden={this.state.hidden}/>
                    <ClubPageRecentPlacements club={club} loading={loading} getClubInfo={getClubInfo} isAdmin={isAdmin} openRecentPlacementModal={this.props.openRecentPlacementModal} hide={this.hide} hidden={this.state.hidden}/>
                    <ClubPageLifeAtSection club={club} loading={loading} getClubInfo={getClubInfo} isAdmin={isAdmin} hide={this.hide} hidden={this.state.hidden}/>
                    <ClubPageStepsToApplyWidget club={club} loading={loading} getClubInfo={getClubInfo}
                                                adminIds={adminIds} hide={this.hide} hidden={this.state.hidden}/>
                    <ClubPageSponsorsWidget club={club} loading={loading} getClubInfo={getClubInfo}
                                            adminIds={adminIds} hide={this.hide} hidden={this.state.hidden}/>
                    <PointsOfContactWidget club={club} loading={loading} getClubInfo={getClubInfo}
                                            isAdmin={isAdmin} hide={this.hide} hidden={this.state.hidden}/>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ClubAboutTab);
