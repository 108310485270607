import React, {PureComponent} from "react";


class TextBlockShimmer extends PureComponent {
    render() {
        return (
            <>
                <div className='shimmer mb-4 br-4 w-full' style={{height: 14}}/>
                <div className='shimmer mb-4 br-4 w-full' style={{height: 14}}/>
            </>
        )
    }
}

export default TextBlockShimmer
