import React, {Component} from "react";
import {connect} from "react-redux";
import {page_h_padding_class} from "../../utils/constants";
import {getUserAnswers} from "../../api/student/answers";
import AnimatedTabs from "../atoms/AnimatedTabs";
import SvgDashboard from "../icons/SvgDashboard";
import SvgQuiz from "../icons/SvgQuiz";
import ResourcesPagePlaceholder from "../resources/ResourcesPagePlaceholder";
import ResourcesMyDashboard from "../resources/ResourcesMyDashboard";
import SvgQuestionSet from "../icons/SvgQuestionSet";
import ResourcesInterviewSets from "../resources/ResourcesInterviewSets";
import ResourcesQuestionBankNew from "../resources/ResourcesQuestionBankNew";
import {APP_ADMINS} from "../../utils/constants";
import ResourcesLearnTab from "../resources/ResourcesLearnTab";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        canSeeResources: (state.userReducer.user?.referrals?.length >= 2 && state.userReducer.user?.completed_personalization) || APP_ADMINS.includes(state.userReducer.user?.uid)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setAnswerIds: (answerIds) => {
            dispatch({
                type: "SET_ANSWER_IDS",
                answerIds,
            })
        },
    }
}


class ResourcesPage extends Component {
    state = {
        activeTab: 'Interviews',
        answers: [],
        statistics: {},
        grid: true,
        selectedSet: null,
        selectedTopic: null
    }
    changeTab = (tab) => {
        if (tab !== 'Interviews') {
            this.setState({selectedSet: null})
        }
        if (tab !== 'Question Bank') {
            this.setState({selectedTopic: null})
        }
        this.setState({activeTab: tab});

        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("activeTab", tab);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        this.props.history.replace(newUrl);
    };

    componentDidMount = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const activeTab = queryParams.get("activeTab");
        if (!!activeTab) this.changeTab(activeTab);

        const res = await getUserAnswers(this.props.authUser);
        this.props.setAnswerIds(res.answers.map(answer => answer.question_id))
        this.setState({
            answers: res.answers,
            statistics: res.statistics,
            loading: false
        });

        window.analytics.page("Learn Tab Viewed")
    }

    selectInterviewToPreview = (set) => {
        this.setState({selectedSet: set})
        this.changeTab('Interviews')
    }

    selectTopicToPreview = (topic) => {
        this.setState({selectedTopic: topic})
        this.changeTab('Question Bank')
    }

    render() {
        const activeTab = this.state.activeTab;
        const statistics = this.state.statistics
        return (
            <div className={`flex flex-col flex-1 overflow-hidden relative ${page_h_padding_class}`}>
                <div className='pt-3 w-full bg-white'>
                    <AnimatedTabs
                        activeTab={activeTab}
                        changeTab={this.changeTab}
                        tabs={[
                            {title: 'Interviews', icon: SvgQuestionSet},
                            {title: 'Resources', icon: SvgDashboard},
                            {title: 'Practice', icon: SvgQuiz},
                        ]}
                    />
                </div>
                {this.state.activeTab === 'Resources' &&
                    <>

                        {/* {!this.props.canSeeResources ? (
                            <ResourcesPagePlaceholder page={'resources'}/>
                        ) : ( */}
                            <ResourcesLearnTab/>
                        {/* )} */}


                        {/*Outdated: Old resources page*/}
                        {/*<ResourcesMyDashboard changeTab={this.changeTab} answers={this.state.answers}*/}
                        {/*    statistics={this.state.statistics}*/}
                        {/*    selectTopicToPreview={this.selectTopicToPreview}*/}
                        {/*    selectInterviewToPreview={this.selectInterviewToPreview}/>*/}
                    </>
                }
                {this.state.activeTab === 'Interviews' &&
                    <ResourcesInterviewSets selectedSet={this.state.selectedSet} answers={this.state.answers}
                                            changeTab={this.changeTab} canSeeResources={this.props.canSeeResources}/>

                }
                {this.state.activeTab === 'Practice' &&
                    <>
                        <ResourcesQuestionBankNew selectedTopic={this.state.selectedTopic} answers={this.state.answers}/>
                    </>

                }
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesPage);
