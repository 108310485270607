import axios from '../axios';

export const getClubPosts = async (user, club_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {club_id}
        };
        const res = await axios.get("/posts/club", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getUserPosts = async (user, index=null) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {index}
        };
        const res = await axios.get("/posts/user", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const editPost = async (user, post_id, params_to_edit) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            updated_params: params_to_edit,
            post_id,
        }
        const res = await axios.put("/posts", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const createPost = async (user, params) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/posts", {params}, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const deletePost = async (user, post_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {post_id}
        };
        const res = await axios.delete("/posts", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}