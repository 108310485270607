import SvgCheck from "../icons/SvgCheck";
import React from "react";


function Checkbox(props) {
    return (
        <button onClick={(e) => {e.stopPropagation(); props.onClick()}}
                className={`flex flex-row items-center justify-center ${props.className}`}
        >
            <div
                className={`${props.checked ? 'checkbox-active' : 'checkbox border-[#00000030]'} border-[1px] rounded-md mr-2`}>
                {props.checked &&
                    <SvgCheck className={'base-white-100'}/>
                }
            </div>
            <p className='body2 text-slate-500'>
                {props.label}
            </p>
        </button>
    )
}

export default Checkbox
