import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'}
                  d="M80-80l186-520 337 333L80-80zm101-101l314-112-203-204-111 316zm376-264l-34-34 322-322 97 98-34 34-63-64-288 288zM398-600l-34-34 81-81-79-79 34-34 113 113-115 115zm81 77l-34-34 200-200-110-110 34-34 144 144-234 234zm158 159l-34-34 132-132 134 134-34 34-100-100-98 98zM181-181z"/>
        </svg>
    )
}

export default SvgComponent
