import React from "react";
import Checkbox from "../components/atoms/Checkbox";


const RecruiterDashboardTable = ({ columns, TableBody, hasCheckbox, toggleCheckbox, checked }) => {
    return (
        <div className="bg-white shadow-lg shadow-slate-200 border border-slate-200 rounded-xl overflow-hidden flex-1">
            <div className="flex flex-row bg-slate-50 p-5 py-3">
                {hasCheckbox && (
                    <div style={{ flex: 0.25 }} className="flex items-center">
                        <Checkbox
                              checked={checked}
                              onClick={toggleCheckbox}
                              className={"flex flex-1"}
                          />
                    </div>
                )}
                {columns.map((item) => (
                    <div key={item.label} style={{ flex: item.flex }}>
                        <p className="text-sm text-slate-500 font-semibold">
                            {item.label}
                        </p>
                    </div>
                ))}
            </div>
            {TableBody}
        </div>
    );
};

export default RecruiterDashboardTable;
