import React from 'react'
import Confetti from 'react-confetti'
import {useWindowSize} from "react-use";

function ConfettiComponent() {
    const {width, height} = useWindowSize()
    return (
        <div className='z-50'>
            <Confetti
                width={width}
                height={height}
                recycle={false}
                friction={.99}
                numberOfPieces={400}
                gravity={0.05}
                colors={[
                    '#2196f3', '#349aea', '#0c6cb9',
                ]}
                className={'z-[100]'}
            />
        </div>
    )
}

export default ConfettiComponent
