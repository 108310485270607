import React from "react";
import OnboardingStepHeader from "./OnboardingStepHeader";
import OnboardingStepWrapper from "./OnboardingStepWrapper";
import {ACTIVELY_LOOKING_OPTIONS} from "./onboarding-constants";
import OnboardingStepButtons from "./OnboardingStepButtons";

function OnboardingActivelyLookingStep({goNextStep, goPreviousStep, setJobStatus}) {

    const select = (label) => {
        setJobStatus(label)
        goNextStep(label === "Not looking right now" ? 4 : null);
    }

    return (
        <OnboardingStepWrapper>
            <OnboardingStepHeader
                title={'Are you actively looking for a job?'}
                description={'Knowing what you\'re looking for helps us give you the best experience'}
            />
            <div className='flex flex-col gap-5'>
                {ACTIVELY_LOOKING_OPTIONS.map(item =>
                    <div key={item.label}
                         onClick={() => select(item.label)}
                         className='flex flex-row md:flex-col items-center gap-3 p-5 bg-slate-100 rounded-2xl hover:bg-slate-200 hover:text-primary cursor-pointer'>
                        <item.Icon className='w-6 h-6 md:w-10 md:h-10'/>
                        <p>
                            {item.label}
                        </p>
                    </div>
                )}
            </div>
            <OnboardingStepButtons
                goNextStep={goNextStep}
                goPreviousStep={goPreviousStep}
                hideNext
            />
        </OnboardingStepWrapper>
    );
}

export default OnboardingActivelyLookingStep;
