import React from 'react';
import { useHistory } from 'react-router-dom';
import SvgInfo from "../icons/SvgInfo";
import SvgChevronRight from "../icons/SvgChevronRight";
import CopyReferralButton from '../atoms/CopyReferralButton';

function NUXTopBar({hasPersonalization, hasReferredRequiredFriends, showTopBanner}) {
    const history = useHistory();

    const shouldCopyReferralLink = !hasPersonalization ? undefined : !hasReferredRequiredFriends;
    const message = !hasPersonalization ? 
        "Complete onboarding to boost your profile visibility and personalize your experience" : 
        "Beta Release of RecruitU Learn - Go check it out. If you want access to Interview Sets from firms refer 2 friends.";

    const handleClick = () => {
        if (!hasPersonalization) {
            history.push('/edit-profile');
        }
    };

    if (!showTopBanner) {
        return null;
    }


    return (
        <div
            style={{transition: 'height 150ms ease-in-out'}}
            className={`w-full fixed top-0 left-0 right-0 z-50  ${showTopBanner ? 'h-[48px]' : 'h-[0px]'} overflow-hidden`}>
            <div
                role={!shouldCopyReferralLink ? 'button' : undefined}
                className={`bg-slate-200 ${!shouldCopyReferralLink ? 'cursor-pointer' : ''} text-primary ${!shouldCopyReferralLink ? 'hover:bg-slate-300' : ''} w-full body2-bold flex flex-row h-full px-5 items-center justify-between`}
                onClick={handleClick}>
                <div className='flex flex-row items-center'>
                    <SvgInfo className={'w-5 h-5 mr-2'}/> {message}
                </div>
                <div>
                    {shouldCopyReferralLink ? (
                        <CopyReferralButton size={'xs'} className={'px-4'} variant={'blueLight'}/>
                    ) : (
                        <SvgChevronRight className={'w-5 h-5'}/>
                    )}
                </div>
            </div>
        </div>
    );
}

export default NUXTopBar;
