import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={24}
            viewBox="0 -960 960 960"
            width={24}
            {...props}
        >
            <path fill={'currentColor'} d="M120-120v-720h248q13-36 43.5-58t68.5-22q38 0 68.5 22t43.5 58h248v720H120zm80-80h560v-560H200v560zm80-80h280v-80H280v80zm0-160h400v-80H280v80zm0-160h400v-80H280v80zm200-190q13 0 21.5-8.5T510-820q0-13-8.5-21.5T480-850q-13 0-21.5 8.5T450-820q0 13 8.5 21.5T480-790zM200-200v-560 560z" />
        </svg>
    )
}

export default SvgComponent
