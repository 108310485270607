import * as Dialog from '@radix-ui/react-dialog';
import SvgClose from "../../icons/SvgClose";
import React, {PureComponent} from "react";
import DropdownComponent from "../DropdownComponent";
import DatePicker from "react-datepicker";
import ToggleButton from "../ToggleButton";
import CollapseComponent from "../CollapseComponent";
import ClubSumbitSuccess from "./ClubSumbitSuccess";
import SvgAddCircle from "../../icons/SvgAddCircle";
import SvgSearch from "../../icons/SvgSearch";
import SvgPostAdd from "../../icons/SvgPostAdd";
import {addNetworkManual} from "../../../api/student/networks";
import {connect} from "react-redux";
import {searchCompanies} from "../../../utils/typesense";
import ModalComponent from "./ModalComponent";
import {wait} from "@testing-library/user-event/dist/utils";
import {Button} from "../Button";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        clubs: state.userReducer.user,
    };
}


class ManualContactEntry extends PureComponent {
    state = {
        firstName: '',
        lastName: '',
        title: '',
        linkedin: "",
        companyName: "",
        loading: false,
        email: "",
        phone: "",
        companyId: "",
        searchResults: [],
        modalOpen: false
    }

    titleChanged = e => this.setState({title: e.target.value})
    linkedinChanged = e => this.setState({linkedin: e.target.value});
    firstNameChanged = e => this.setState({firstName: e.target.value});
    lastNameChanged = e => this.setState({lastName: e.target.value});
    emailChanged = e => this.setState({email: e.target.value});
    phoneChanged = e => this.setState({phone: e.target.value});

    companyChanged = async (e) => {
        const text = e.target.value;
        if (text === "") {
            this.setState({companyId: ""});
        }
        this.setState({companyName: text, changed: true});
        const searchResults = await searchCompanies(text);
        this.setState({searchResults});
    }

    onBlur = () => {
        this.setState({companySearchFocused: false});
        wait(300).then(() =>
            this.setState({searchResults: []})
        )
    }

    setCompany = (result) => {
        this.setState({
            searchResults: [],
            companyId: result.id,
            companyName: result.name,
        })
    }
    openModal = () => {
        this.setState({modalOpen: true})
    }

    closeModal = () => {
        this.setState({modalOpen: false})
    }

    navigateClubPage = () => {
        const clubId = this.props.clubs.clubs[0].id;
        this.props.history.push('/club/' + clubId, {club_id: clubId, active_tab: "Alumni"});
    }


    trySaveManualNetwork = async () => {
        this.setState({loading: true})
        const network = await addNetworkManual(this.props.authUser, this.state.firstName, this.state.lastName, this.state.title, this.state.companyName, this.state.companyId, this.state.linkedin, this.state.email, this.state.phone);
        this.props.updateNetwork(network.network);
        window.analytics.track("Contact Manually Added")
        this.setState({
            firstName: '',
            lastName: '',
            title: '',
            linkedin: "",
            companyName: "",
            loading: false,
            email: "",
            phone: "",
            companyId: "",
            searchResults: [],
        })
        this.closeModal()
    }


    render() {
        return (
            <>
                {(this.props.clubs.clubs.length > 0) ?
                    <DropdownComponent
                        ToggleComponent={
                            <Button>
                                <SvgPostAdd className={'w-4 h-4'}/>
                                Add Contact
                            </Button>
                        }
                        value={null}
                        isControlled={false}
                        setValue={() => null}
                        options={
                            [
                                {
                                    title: 'Add new contact',
                                    icon: SvgAddCircle,
                                    onClick: this.openModal
                                },
                                {
                                    title: 'Search club directory',
                                    icon: SvgSearch,
                                    onClick: this.navigateClubPage,
                                    visible: this.props.clubs.clubs.length > 0
                                }
                            ]
                        }
                        direction='bottom-end'
                        light
                    />
                    :
                    <Button
                        onClick={this.openModal}
                    >
                        <SvgPostAdd className={'w-4 h-4'}/>
                        Add Contact
                    </Button>
                }
                <ModalComponent
                    isOpen={this.state.modalOpen}
                    toggle={this.closeModal}
                    backgroundColor={'white'}
                    size={'lg'}
                    header={'Add a contact to your personal network!'}
                    description={'This can only be seen by you. Input a few quick details and add them to your list! You can easily edit or add information later.'}
                    headerToggle
                    FooterComponent={
                        <div className='flex-row gap-3'
                             style={{display: 'flex', marginTop: 25, justifyContent: 'flex-end'}}>
                            <button onClick={this.closeModal}
                                    className="secondary-button flex flex-1 body1-bold">Cancel
                            </button>
                            <button
                                onClick={() => this.trySaveManualNetwork()}
                                className="primary-button flex flex-1 body1-bold">Add contact
                            </button>
                        </div>
                    }
                    footerAlignment={'right'}
                >
                    <div className='flex flex-col w-full gap-5'>
                        <div className='flex flex-row justify-between items-center'>
                            <div className='flex flex-row gap-5 flex-1 mr-5'>
                                <div className='flex flex-col gap-3 flex-1'>
                                    <div className="col-plain w-full">
                                        <p className="body3-bold text-neutral-400 mb-1">
                                            First Name
                                        </p>
                                        <input
                                            value={this.state.firstName}
                                            onChange={this.firstNameChanged}
                                            placeholder={'John'}
                                            className='input-light px-3 body2 base-black-50 w-full'
                                        />
                                    </div>
                                    <div className="col-plain w-full">
                                        <p className="body3-bold text-neutral-400 mb-1">
                                            Last Name
                                        </p>
                                        <input
                                            value={this.state.lastName}
                                            onChange={this.lastNameChanged}
                                            placeholder={'Doe'}
                                            className='input-light px-3 body2 base-black-50 w-full'
                                        />
                                    </div>
                                    
                                    <div className="col-plain w-full">
                                        <p className="body3-bold text-neutral-400 mb-1">
                                            Email
                                        </p>
                                        <input
                                            value={this.state.email}
                                            onChange={this.emailChanged}
                                            placeholder={'ie. John.Doe@doe.com'}
                                            className='input-light px-3 body2 base-black-50 w-full'
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col gap-3 flex-1'>
                                    
                                    {/* <div className="col-plain w-full">
                                        <p className="body3-bold text-neutral-400 mb-1">
                                            Phone
                                        </p>
                                        <input
                                            value={this.state.phone}
                                            onChange={this.phoneChanged}
                                            placeholder={'ie. 1234567890'}
                                            className='input-light px-3 body2 base-black-50 w-full'
                                        />
                                    </div> */}
                                    <div className="col-plain w-full">
                                        <p className="body3-bold text-slate-400 mb-1">
                                            Company
                                        </p>
                                        <input
                                            value={this.state.companyName}
                                            onChange={this.companyChanged}
                                            onFocus={() => this.setState({companySearchFocused: true})}
                                            onBlur={this.onBlur}
                                            placeholder={'ie. Morgan Stanley'}
                                            className='input-light px-3 body2 base-black-50 w-full'
                                        />
                                        <div
                                            className={`relative ${!this.state.companySearchFocused && 'opacity-0'}`}>
                                            <CollapseComponent isOpen={this.state.companySearchFocused}>
                                                <div
                                                    className='absolute left-0 right-0 bg-white border border-slate-200 rounded-xl overflow-hidden'>
                                                    <div className=''>
                                                        {this.state.searchResults.map(result => {
                                                            return (
                                                                <div
                                                                    className='flex flex-row items-center gap-2 py-2 cursor-pointer hover:bg-slate-100 px-3'
                                                                    onClick={() => {
                                                                        this.setCompany(result)
                                                                    }}>
                                                                    <img src={result.logo_url} alt={'club-icon'}
                                                                         className='w-8 h-8 rounded-sm border object-contain bg-white border-slate-200 mr-2'/>
                                                                    <div className='col-plain'>
                                                                        <p className='body3'>{result.name}</p>
                                                                        <p className='body4'>{result.sector}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                        {this.state.companySearchFocused &&
                                                            <div
                                                                className='flex flex-row items-center gap-2 py-2 cursor-pointer text-slate-500 hover:bg-slate-100 px-3'
                                                                onClick={() => this.setCompany(this.state.companyName)}>
                                                                <div
                                                                    className='w-8 h-8 flex flex-col items-center justify-center'>
                                                                    <SvgAddCircle className={'w-5 h-5'}/>
                                                                </div>
                                                                <div className='col-plain'>
                                                                    <p className='body3'>Add missing
                                                                        company: {this.state.companyName}</p>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </CollapseComponent>
                                        </div>
                                    </div>
                                    <div className="col-plain w-full">
                                        <p className="body3-bold text-neutral-400 mb-1">
                                            Title
                                        </p>
                                        <input
                                            value={this.state.title}
                                            onChange={this.titleChanged}
                                            placeholder={'ie. Risk Management Analyst'}
                                            className='input-light px-3 body2 base-black-50 w-full'
                                        />
                                    </div>
                                    <div className="col-plain w-full">
                                        <p className="body3-bold text-neutral-400 mb-1">
                                            Linkedin
                                        </p>
                                        <input
                                            value={this.state.linkedin}
                                            onChange={this.linkedinChanged}
                                            placeholder={'ie. https://www.linkedin.com/in/john-doe/'}
                                            className='input-light px-3 body2 base-black-50 w-full'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalComponent>
            </>
        )
    }
}

export default connect(mapStateToProps)(ManualContactEntry)
