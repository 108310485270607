import React, {PureComponent} from "react";
import NetworkingListShimmers from "../../loading/NetworkingListShimmers";
import ClubPageUploadRosterWidget from "../ClubPageUploadRosterWidget";
import ClubPageMemberListItem from "../ClubPageMemberListItem";
import ClubPageStudentListItem from "../ClubPageStudentListItem";
import {RectangleStackIcon} from "@heroicons/react/24/outline";

class ClubMembersTab extends PureComponent {
    render() {
        const {
            loading,
            members,
            club,
            leadership,
            authUser,
            user,
            recruiter,
            clubAdmin,
            adminIds,
            openPositionModal,
            getClubInfo
        } = this.props;
        const sortedMembers = [...members].sort((a, b) => {
            const pocEmails = club.points_of_contact.map(poc => poc.email);
            // If only one of them is a PoC, prioritize that
            if (pocEmails.includes(a.email) && !pocEmails.includes(b.email)) {
                return -1;
            }
            if (!pocEmails.includes(a.email) && pocEmails.includes(b.email)) {
                return 1;
            }

            if (a.claimed && !b.claimed) {
                return -1;
            }
            if (!a.claimed && b.claimed) {
                return 1;
            }
            return 0;
        });

        return (
            <div className={`flex flex-col flex-1 overflow-scroll ${!this.props.recruiter && 'gap-3 px-5'}`}>
                {loading ?
                    <>
                        <NetworkingListShimmers/>
                    </>
                    :
                    <>
                        {members.length === 0 ?
                            <div
                                className='flex flex-col items-center justify-center py-32 body2 text-neutral-400'>
                                <div className="text-center mt-48">
                                    <RectangleStackIcon className="mx-auto h-20 w-20 text-slate-400"/>
                                    <h3 className="mt-2 text-lg font-semibold text-slate-900">
                                        No active members added
                                    </h3>
                                </div>
                            </div>
                            :
                            <>
                                {sortedMembers?.map(item => {
                                    if (recruiter) {
                                        return <ClubPageMemberListItem key={item.name} item={item}
                                                                       leadership={leadership}
                                                                       authUser={authUser}
                                                                       club={club}
                                                                       user={user}
                                                                       recruiter={recruiter}/>
                                    } else {
                                        return <ClubPageStudentListItem key={item.name} item={item}
                                                                        leadership={leadership}
                                                                        authUser={authUser}
                                                                        club={club}
                                                                        user={user}
                                                                        recruiter={recruiter}
                                                                        adminIds={adminIds}
                                                                        openPositionModal={openPositionModal}
                                                                        getClubInfo={getClubInfo}
                                                                        clubAdmin={clubAdmin}/>
                                    }
                                })}
                            </>
                        }
                    </>
                }
            </div>
        );
    }
}

export default ClubMembersTab;
