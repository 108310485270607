import axios from '../axios';

export const getUserNetworks = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {}
        };
        const res = await axios.get("/networks/user", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const editNetwork = async (user, network_id, params_to_edit) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            updated_params: params_to_edit,
            network_id
        }
        const res = await axios.put("/networks", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const addNetworkManual = async (user, first_name, last_name, title, companyName, companyId, linkedin, email, phone) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            first_name,
            last_name,
            email: email,
            phone: phone,
            title: title,
            company_name: companyName,
            company_id: companyId,
            linkedin
        }
        const res = await axios.post("/networks/manual", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const createNetwork = async (user, params) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/networks", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const addNote = async (user, network_id, text) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const params = {
            text,
            network_id
        }
        const res = await axios.put("/networks/notes", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const deleteNote = async (user, network_id, index) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const params = {
            index,
            network_id
        }
        const res = await axios.post("/networks/notes", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const deleteNetwork = async (user, network_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {network_id}
        };
        const res = await axios.delete("/networks", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}
