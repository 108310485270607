import React, {Component} from "react";
import {wait} from "@testing-library/user-event/dist/utils";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import ProgressStepper from "../atoms/ProgressStepper";
import CreateJobQualificationStep from "./create-job/CreateJobQualificationStep";
import {createEvent} from "../../api/recruiter/events";
import CreateEventDetailsStep from "./create-event/CreateEventDetailsStep";
import CreateEventDateAndLocationStep from "./create-event/CreateEventDateAndLocationStep";
import {withRouter} from "react-router-dom";
import SearchCompany from "../../components/addjobflow/SearchCompany";
import {searchCompanies} from "../../utils/typesense";
import {Button} from "../../components/atoms/Button";
import CreateEventPreviewStep from "./create-event/CreateEventPreviewStep";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import {EditorState} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';


const ZERO_STATE = {
    step: 0,
    eventTitle: '',
    eventDescription: '',
    address:'',
    city: '',
    state: '',
    date: null,
    classYears: [],
    qualifiedOnly: false,
    registerOnRecruit: false,
    majors: [],
    clubs: [],
    schools: [],
    startTime: '10:00',
    endTime: '11:00',
    virtual: false,
    meetingLink: '',
    companyId: '',
    creator: '',
    role: '',
    stepLabels:  ['Event Details', 'Date and Location', 'Attendees', 'Preview'],
    loading: false,
    rsvpDate: null,
    hasRsvpDate: false,
    editorState: EditorState.createEmpty(),
    clubListIds: [],
    smartListIds: [],
    listNames: [],
    gpa: "",
};

class CreateEventModal extends Component {
    state = {
        ...ZERO_STATE,
        gender: null,
        race: [],
        veteran: false,
        lgbtq: false,
        disabled: false,
        authorized: false,
    };

    componentDidMount = () => {
        this.setInfo();

        if (this.props.tier === 'blk') this.setState({stepLabels:  ['Event Details', 'Date and Location', 'Preview']})
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.isOpen !== this.props.isOpen) {
            wait(10).then(() => {
                this.setInfo();
            })
        }
    }

    setInfo = () => {
        const companyId = this.props.user.company_id || ""
        this.setState({creator: companyId, companyId, step: 1, clubs: this.props.currentClubs})
    }

    submit = async () => {
        this.setState({loading: true})
        const tier = this.props.tier;
        const industry = this.state.role?.endsWith('Consulting') ? 'Consulting' : 'Finance';

        const contentState = this.state.editorState.getCurrentContent();
        const htmlDescription = stateToHTML(contentState);

        const combinedClubIds = this.state.clubListIds.reduce((accumulator, currentObject) => {
            return accumulator.concat(currentObject.club_ids);
          }, []);

        const params = {
            name: this.state.eventTitle,
            description: htmlDescription,
            company_id: this.state.companyId,
            creator: this.state.creator,
            location: this.state.virtual ? "Remote" : "In-Person",
            event_date: this.state.date,
            start_time: this.state.startTime,
            end_time: this.state.endTime,
            address:this.state.address,
            city: !!this.state.city ? [this.state.city] : [],
            state: !!this.state.state ? [this.state.state] : [],
            class_years: this.state.classYears,
            scope: tier !== "blk" ? "public" : "club",
            clubs: tier !== "blk" ? combinedClubIds : ["baeZVpmndkm8fCNGFIuZ"],
            schools: this.state.schools,
            link: this.state.meetingLink,
            industry,
            majors: this.state.majors,
            gpa: this.state.gpa,
            qualified_only: this.state.qualifiedOnly,
            exclusive: true,
            register_direct: this.state.registerOnRecruit,
            specialties: [industry],
            secondary_specialties: [this.state.role],
            rsvp_date: this.state.hasRsvpDate ? DateTime.fromJSDate(this.state.rsvpDate).setZone('utc', {keepLocalTime: true}).toISO().replace('Z', '') : "",
            smart_list_ids: this.state.smartListIds,
            club_list_ids: this.state.clubListIds.map(clubList => clubList.id),
            diversity: this.state.smartListIds.length ? {
                gender: this.state.gender,
                race: this.state.race,
                lgbtq: this.state.lgbtq,
                disabled: this.state.disabled,
                veteran: this.state.veteran,
                authorized: this.state.authorized,
            } : {}
        }
        
        toast.promise(createEvent(this.props.authUser, params).then(() => {
            this.props.closeModal();
            window.analytics.track("Recruiter New Event Created");
            this.props.history.push("/events");
            this.setState({loading: false});
        }), {
            pending: "Creating event...",
            success: "Event created successfully!",
            error: "Failed to create event."
        });
    }

    selectSmartList = (res) => {
        if (this.state.smartListIds.includes(res.id)) {
            this.setState({
                smartListIds: [],
                schools: [],
                gpa: "",
                classYears: [],
                majors: [],
                gender: "",
                race: [],
                lgbtq: false,
                disabled: false,
                veteran: false,
                authorized: false,
                listNames: this.state.listNames.filter(name => name.id !== res.id)
            })
        } else {
            this.setState({
                smartListIds: [res.id],
                schools: res.colleges,
                gpa: res.gpa,
                classYears: res.grades,
                majors: res.majors,
                gender: res.gender,
                race: res.race,
                lgbtq: res.lgbtq,
                disabled: res.disabled,
                veteran: res.veteran,
                authorized: res.authorized,
                listNames: [...this.state.listNames, {name: res.name, id: res.id, type: "smart"}]
            })
        }
        
    }

    selectClubList = (item) => {
        const selectedKey = `clubListIds`;
        const isSelected = this.state[selectedKey].some((selectedItem) => selectedItem.id === item.id);

        if (isSelected) {
            this.setState({
                [selectedKey]: this.state[selectedKey].filter((selectedItem) => selectedItem.id !== item.id),
                listNames: this.state.listNames.filter(name => name.id !== item.id)
            });
        } else {
            this.setState({
                [selectedKey]: [...this.state[selectedKey], item],
                listNames: [...this.state.listNames, {name: item.name, id: item.id, type: "club"}]
            });
        }
    };


    nextStep = () => {
        if (this.state.step === this.state.stepLabels.length) {
            this.submit();
            return;
        }
        this.setState({step: this.state.step + 1})
    }

    prevStep = () => {
        if (this.state.step === 1) {
            this.props.closeModal();
            return;
        }
        this.setState({step: this.state.step - 1})
    }


    handleInputChange = (e, inputName) => {
        this.setState({[inputName]: e.target.value});
    };

    handleSelectChange = (inputName, value) => {
        this.setState({[inputName]: value});
    };

    handleQualifiedOnlyChange = (boolean) => {
        this.setState({
            qualifiedOnly: boolean,
        });
    };

    toggleInput = (stateKey) => {
        this.setState((prevState) => ({
            [stateKey]: !prevState[stateKey],
        }));
    }

    handleDateChange = (e, stateKey) => {
        this.setState({[stateKey]: e})
    };

    handleTimeChange = (e, stateKey) => {
        this.setState({[stateKey]: e})
    };

   
    handleVirtualChange = (value) => {
        this.setState({virtual: value})
    };

    setCompany = (result) => {
        this.setState({
            searchResults: [],
            companyId: result.id,
            companyName: result.name,
        })
        this.nextStep();
    }

    companyChanged = async (text) => {
        const searchResults = await searchCompanies(text);
        this.setState({searchResults});
    }

    switchDisabled = () => {
        switch (this.state.step) {
            case 0:
                return true;
            case 1:
                return !this.state.eventTitle;
            case 2:
                return this.state.date === null || (this.state.virtual ? !this.state.meetingLink : (!this.state.city || !this.state.state));
            default:
                return false
        }
    }

    handleQualificationCheckbox = (inputName) => {
        this.setState({[inputName]: !this.state[inputName]});
    }

    handleEditorStateChange = (editorState) => {
        this.setState({ editorState });
    };

    render() {
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Create A New Event'}
                headerToggle
                size={'lg'}
                toggle={this.props.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button variant={'secondary'} onClick={this.prevStep}>
                            {this.state.step === 1 ? "Cancel" : "Back"}
                        </Button>
                        <Button onClick={this.nextStep} disabled={this.switchDisabled()} loading={this.state.loading}>
                            {this.state.step !== this.state.stepLabels.length ? "Next" : "Submit"}
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
                showScrollBar
            >
                <div id={'add-joblisting-modal'}>
                    <ProgressStepper
                        step={this.state.step}
                        stepLabels={this.state.stepLabels}
                    />
                    {/*Todo: START AT STEP 0 FOR STUDENTS OR NO COMPANY PASSED IN*/}
                    {this.state.step === 0 &&
                        <SearchCompany
                            nextStep={this.nextStep}
                            setCompany={this.setCompany}
                            companyChanged={this.companyChanged}
                            searchResults={this.state.searchResults}
                        />
                    }

                    {this.state.step === 1 &&
                        <CreateEventDetailsStep
                            eventTitle={this.state.eventTitle}
                            editorState={this.state.editorState}
                            setEditorState={this.handleEditorStateChange}
                            role={this.state.role}
                            classYears={this.state.classYears}
                            updateClassYears={this.updateClassYears}
                            handleInputChange={this.handleInputChange}
                            handleSelectChange={this.handleSelectChange}
                            constants={this.props.constants}
                        />
                    }
                    {this.state.step === 2 &&
                        <CreateEventDateAndLocationStep
                            date={this.state.date}
                            handleDateChange={this.handleDateChange}
                            startTime={this.state.startTime}
                            endTime={this.state.endTime}
                            handleTimeChange={this.handleTimeChange}
                            virtual={this.state.virtual}
                            meetingLink={this.state.meetingLink}
                            address={this.state.address}
                            state={this.state.state}
                            city={this.state.city}
                            registerOnRecruit={this.state.registerOnRecruit}
                            toggleInput={this.toggleInput}
                            handleVirtualChange={this.handleVirtualChange}
                            handleInputChange={this.handleInputChange}
                            handleSelectChange={this.handleSelectChange}
                            hasRsvpDate={this.state.hasRsvpDate}
                            rsvpDate={this.state.rsvpDate}
                        />
                    }
                    {this.state.step === 3 &&
                        <CreateJobQualificationStep
                            event
                            handleSelectChange={this.handleSelectChange}
                            toggleInput={this.toggleInput}
                            qualifiedOnly={this.state.qualifiedOnly}
                            handleQualifiedOnlyChange={this.handleQualifiedOnlyChange}
                            handleQualificationCheckbox={this.handleQualificationCheckbox}
                            selectSmartList={this.selectSmartList}
                            selectClubList={this.selectClubList}
                            smartListIds={this.state.smartListIds}
                            clubListIds={this.state.clubListIds}
                            listNames={this.state.listNames}
                        />
                    }
                    {this.state.step === 4 &&
                        <CreateEventPreviewStep
                            listingState={{
                                created_at: new Date().toISOString(), 
                                event_date: this.state.date?.toISOString(),
                                class_years: this.state.classYears,
                                name: this.state.eventTitle,
                                location: this.state.virtual ? "Remote" : `${this.state.city}, ${this.state.state}`,
                                attendees: [],
                                exclusive: true,
                                industry: this.state.role.endsWith('Consulting') ? 'Consulting' : 'Finance',
                                secondary_specialties: [this.state.role],
                                description: stateToHTML(this.state.editorState.getCurrentContent()), 
                            }}
                        />
                    }
                </div>
            </ModalComponent>
        )
    }
}

export default withRouter(CreateEventModal);
