import React from 'react';

function CircularProgress({ value, target, size = 100 }) {
    const radius = (size - 10) / 2; // Adjust for stroke width
    const circumference = 2 * Math.PI * radius;
    const progressValue = Math.max(5, (value / target) * 100); // Ensure at least 5%
    const offset = circumference - (progressValue / 100) * circumference;

    return (
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
            <circle
                cx={size / 2}
                cy={size / 2}
                r={radius}
                fill="transparent"
                className={'stroke-white/20'}
                strokeWidth="6"
            />
            <circle
                cx={size / 2}
                cy={size / 2}
                r={radius}
                fill="transparent"
                stroke="white"
                strokeWidth="6"
                strokeDasharray={circumference}
                strokeDashoffset={offset}
                strokeLinecap="round"
                transform={`rotate(-90 ${size / 2} ${size / 2})`} // Start from top
                className="text-primary"
            />
        </svg>
    );
}

export default CircularProgress;
