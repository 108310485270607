import { convertFromHTML, ContentState } from "draft-js";
import { stateToHTML } from 'draft-js-export-html';

/**
 * Converts HTML string to ContentState
 * @param {string} html - The HTML string to be converted
 * @returns {ContentState} The resulting ContentState object
 */
export const convertFromHtml = (html) => {
    const blocksFromHTML = convertFromHTML(html ?? '');
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    return state;
}
/**
 * @param {ContentState} state - The ContentState object to be converted
 * @returns {string} The resulting HTML string
 */
export const convertToHtml = (state) => {
    const html = stateToHTML(state);
    return html;
}