import React, {PureComponent} from "react";
import JobBoardListItem from "../jobboard/JobBoardListItem";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import SvgEmpty from "../icons/SvgEmpty";
import CompanyStatusTrackerComponent from "../atoms/CompanyStatusTrackerComponent";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        applications: state.userReducer.applications,
        user: state.userReducer.user,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
    }
}

class CompanyPageJobs extends PureComponent {
    state = {
        listingPreview: {},
    }


    previewListing = (listing) => {
        this.setState({listingPreview: listing})
    }


    render() {
        const company = this.state.company || {};
        return (
            <>
                {this.props.jobs.length === 0 ?
                    <div
                        className='flex flex-col items-center justify-center text-slate-500 text-sm gap-3 flex-1 mt-10'>
                        <SvgEmpty/>
                        <p className='text-lg text-slate-800 font-semibold'>
                            No jobs listed for this company.
                        </p>
                        <div>
                            <CompanyStatusTrackerComponent companyId={company.id}/>
                        </div>
                    </div>
                    :
                    this.props.jobs.map(item =>
                        <Link to={{
                            pathname: '/job/' + item.id,
                            state: {
                                previousPage: {
                                    location: "company",
                                    data: {company: company, origin: this.props.previousPage}
                                }
                            }
                        }}>
                            <JobBoardListItem hideLogo item={item} previewListing={this.previewListing}
                                            application={this.props.applications[item?.id]}
                                            authUser={this.props.authUser}
                        />
                        </Link>
                    )}
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPageJobs);
