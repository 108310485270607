import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 96 960 960"
            width={48}
            {...props}
        >
            <path
                fill={props.fill || 'currentColor'}
                d="M480 1016L160 776V176h640v600l-320 240zm0-75l260-200V236H220v505l260 200zm-42-245l226-226-43-42-184 184-99-99-42 42 142 141zm42-460H220h520-260z" />
        </svg>
    )
}

export default SvgComponent
