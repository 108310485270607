import React, {Component} from "react";
import RecruiterCompanyAddQuote from "../modals/RecruiterCompanyAddQuote";
import SvgEditFeather from "../../components/icons/SvgEditFeather";
import zerostate from "../../img/zerostates/company testimonials.png";
import {Button} from "../../components/atoms/Button";
import SvgEyeOff from "../../components/icons/SvgEyeOff";
import SvgVisibility from "../../components/icons/SvgVisibility";
import SvgRemove from "../../components/icons/SvgRemove";

class RecruiterCompanyPageTestimonialsSection extends Component {
    state = {
        quotesModalOpen: false,
    }

    openQuotesModal = () => {
        this.setState({quotesModalOpen: true})
    }

    closeQuotesModal = () => {
        this.setState({quotesModalOpen: false})
    }

    render() {
        const company = this.props.company || {};
        const quotes = company.quotes || [];
        const hidden = this.props.hidden || [];
        return (
            <div
                className={`bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl ${hidden.includes("quotes") && 'opacity-50'}`}>
                <div className='flex flex-row items-center gap-2 mb-2'>
                    <p className='text-xl font-semibold text-slate-800'>
                        Don’t just take our word for it
                    </p>
                    {quotes.length > 0 &&
                        <button
                            onClick={this.openQuotesModal}
                            className='text-slate-500 hover:text-primary cursor-pointer'>
                            <SvgEditFeather className={'w-4 h-4'}/>
                        </button>
                    }
                    <button
                        onClick={() => this.props.hide("quotes")}
                        className='text-slate-500 hover:text-primary cursor-pointer'>
                        {hidden.includes("quotes") ?
                            <SvgVisibility className={'w-4 h-4'}/>
                            :
                            <SvgEyeOff className={'w-4 h-4'}/>
                        }
                    </button>
                </div>
                <p className='text-slate-500 text-md mb-5'>
                    Deciding where to work is a big decision. Hear directly from grads about their experience.
                </p>
                {quotes.length === 0 &&
                    <>
                        <div className='w-full relative cursor-pointer' onClick={this.openQuotesModal}>
                            <img src={zerostate} className='w-full blur-sm mb-5 saturate-50 opacity-50'/>
                            <div
                                className='absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center'>
                                <Button>
                                    Add a testimonial
                                </Button>
                            </div>
                        </div>
                    </>
                }
                <div className='grid grid-cols-3 gap-3 mt-5'>
                    {quotes.map((item, index) =>
                        <div key={item} className='rounded-lg border border-slate-100 p-3 gap-3 flex flex-row'>
                            <img
                                src={item.profile_pic}
                                className='object-cover mt-1 w-10 h-10 aspect-square bg-slate-200 rounded-full'
                            />
                            <div className='flex flex-col gap-2'>
                                <p className='text-md text-slate-800 font-medium'>
                                    “{item.quote}”
                                </p>
                                <div className='flex flex-col gap-0'>
                                    <p className='text-sm text-slate-800 font-semibold'>
                                        {item.name}
                                    </p>
                                    <p className='text-sm text-slate-500'>
                                        {item.title}
                                    </p>
                                    <p className='text-sm text-slate-500'>
                                        Graduate of {item.grad_info}
                                    </p>
                                </div>
                            </div>
                            <div onClick={() => this.props.removeItem("quotes", index)}
                                className={'p-2 cursor-pointer rounded-full bg-slate-50 hover:bg-red-400 hover:text-white text-red-400 top-2 right-2'}>
                                <SvgRemove className={'w-5 h-5'}/>
                            </div>
                        </div>
                    )}
                </div>
                <RecruiterCompanyAddQuote
                    isOpen={this.state.quotesModalOpen}
                    closeModal={this.closeQuotesModal}
                    company={company}
                />
            </div>
        )
    }
}

export default RecruiterCompanyPageTestimonialsSection;
