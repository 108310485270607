export const numToMonth = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "June",
    7: "July",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
};

// john@vandy, rowan@yale, Wes (vanderbilt), Wes (recruit), Cory, Phifer, Brooks, Brooks (trin)
export const APP_ADMINS = ["G5ThkfzKWqRXfsDLp9IjjpDFckW2", "JED8e1fxzpx4shYgQRr1","uL6yM6V8DQW9cKuHh1S2IWGJd7I2", "rtcjnR5sjrdg185rgp0EykSoeR42", "9lsEUbCWCbco47zHZegGwkhFBCL2", "k4EwKbPnZUPx3p6V8lQhH2VAHDX2", "smJQo1mN1KMozaPqwV9mc3bz7Jq1", "PDCsoSx2jwNpy3qPihwV6fNgZK13"];

export const NUM_JOBS_SHOWN = 15;
export const NUM_CLUBS_SHOWN = 10;
export const NUM_STUDENTS_SHOWN = 20;
export const NUM_COMPANIES_SHOWN = 20;
export const NUM_PEOPLE_SHOWN = 20;
export const NUM_EVENTS_SHOWN = 10;
export const NUM_COLLEGES_SHOWN = 10;
export const NUM_MAJORS_SHOWN = 10;
export const NUM_QUESTIONS_SHOWN = 10;
export const NUM_LISTS_SHOWN = 3;
export const NUM_POSTS_SHOWN = 15;

export const page_h_padding_class = 'px-4 sm:px-5 md:px-5'

export const STUDENT_TRACKER_STATUSES = [{title: "Tracking",}, {title: "Contacted",}, {title: "Interviewing",}, {title: "Decision",}]

export const STUDENT_TRACKER_DECISIONS = [, {title: "Ongoing",}, {title: "Offered",}, {title: "Rejected",}, {title: "Hired",},]

export const TOAST_OPTIONS = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
}

export const STATES = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DC", "DE", "FL", "GA",
  "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
  "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
  "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
  "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY", 
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland",
  "Nova Scotia",
  "Ontario",
  "PEI",
  "Quebec",
  "Saskatchewan"];

export const CURRENT_YEAR = 24;

export const SPORT_OPTIONS = [
  "Baseball/Softball",
  "Basketball",
  "Cross Country",
  "Football",
  "Golf",
  "Lacrosse",
  "Rowing",
  "Rugby",
  "Soccer",
  "Swimming",
  "Tennis",
  "Track and Field",
  "Volleyball",
  "Water Polo",
  "Wrestling",
  "Other",
]


export const COMPANY_FOLLOW_OPTIONS = [
  {title: "Morgan Stanley", value: "13661", content:'company_logo'},
  {title: "Goldman Sachs", value: "14072", content:'company_logo'},
  {title: "Guggenheim", value: "13871", content:'company_logo'},
  {title: "Evercore", value: "24986", content:'company_logo'},
  {title: "Citi", value: "22502", content:'company_logo'},
  {title: "Lazard", value: "25264", content:'company_logo'},
  {title: "JP Morgan", value: "18383", content:'company_logo'},
  {title: "Houilhan Lokey", value: "28461", content:'company_logo'},
  {title: "Wells Fargo", value: "570886", content:'company_logo'},
  {title: "Blackstone", value: "20187", content:'company_logo'},
  {title: "Bank of America", value: "12493", content:'company_logo'},
  {title: "Barclays", value: "37241", content:'company_logo'},
  {title: "Deutsche Bank", value: "17420", content:'company_logo'},
  {title: "Point72", value: "15819", content:'company_logo'},
  {title: "PJT", value: "42487", content:'company_logo'},
  {title: "Centerview", value: "26803", content:'company_logo'},
  {title: "RBC", value: "17236", content:'company_logo'},
  {title: "Moelis", value: "29595", content:'company_logo'},
  {title: "Piper Sandler", value: "17839", content:'company_logo'},
  {title: "TDCowen", value: "21520", content:'company_logo'},
  {title: "Raymond James", value: "8757", content:'company_logo'},
  {title: "UBS", value: "56420", content:'company_logo'},
  {title: "KKR", value: "22908", content:'company_logo'},
  {title: "Rothschild", value: "30881", content:'company_logo'},
  {title: "Perella Weinberg Partners", value: "17402", content:'company_logo'},
]

export const question_categories_array = [
    "Private Equity / Buyside Recruiting",
    "Leveraged Buyouts (LBOs)",
    "Precedent Transactions",
    "DCF",
    "Accounting",
    // 'Restructuring (Rx) / Distressed M&A',
    // 'Valuation',
    'Comparable Company Analysis',
    // 'Brain Teasers / Misc.'
];

export const DIVERSITY_OPTIONS = [
  {title: "Gender", value: "gender"},
  {title: "Race / Ethnicity", value: "race"},
  {title: "Requires sponsorship", value: "require_sponsorship"},
  {title: "Authorized to work", value: "authorized"},
  {title: "Disability", value: "disabled"},
  {title: "Veteran", value: "veteran"},
  {title: "First generation", value: "first_generation"},
];

export const CURRENT_STUDENT_COLLEGES = ['Alverno College', 'American University', 'Amherst College', 'Amity University', 'Appalachian State University', 'Arizona State University', 'Arkansas State University', 'Auburn University', 'Augustana College (IL)', 'Babson College', 'Bard College', 'Barnard College', 'Bates College', 'Baylor University', 'Beloit College', 'Bennington College', 'Bentley College', 'Berea College', 'Blinn College', 'Boston College',
'Boston University', 'Bowdoin College', 'Bowie State University', 'Brandeis University', 'Brigham Young University', 'Brock University', 'Brown University', 'Bryant College', 'Bryn Mawr College', 'Bucknell University', 'California Institute of Technology', 'California Polytechnic State University, San Luis Obispo', 'California State University, Long Beach', 'California State University, Los Angeles', 'California State University, Monterey Bay',
'Calvin College', 'Campbell University', 'Carleton College', 'Carnegie Mellon University', 'Carthage College', 'Case Western Reserve University', 'Central Michigan University', 'Centre College', 'Cerritos College', 'Chabot-Las Positas Community College District', 'Chapman University', 'City Colleges of Chicago', 'City University of New York', 'Claremont McKenna College', 'Clark Atlanta University', 'Clark University', 'Clemson University', 'Coastal Carolina University',
'Colby College', 'Colgate University', 'College of Charleston', 'College of William and Mary', 'College of Wooster', 'College of the Holy Cross', 'Collin County Community College District', 'Colorado College', 'Colorado State University', 'Columbia University', 'Concordia University', 'Connecticut College', 'Contra Costa Community College District', 'Cooper Union for the Advancement of Science and Art', 'Cornell College', 'Cornell University',
 'Creighton University', 'Dalhousie University', 'Dalton State College', 'Dartmouth College', 'Davidson College', 'DePaul University', 'DePauw University', 'Denison University', 'Dickinson College', 'Drexel University', 'Duke University', 'Eastern Connecticut State University', 'Eastern Michigan University', 'Ecole Supérieure de Commerce de Paris', 'Elon College', 'Emerson College', 'Emory University', 'Endicott College', 'Fairfield University',
  'Fisk University', 'Florida Atlantic University', 'Florida International University', 'Florida State University', 'Foothill College', 'Fordham University', 'Fort Valley State University', 'Frankfurt School of Finance & Management', 'George Mason University', 'George Washington University', 'Georgetown University', 'Georgia Gwinnett College', 'Georgia Institute of Technology', 'Georgia Southern University', 'Georgia State University', 'Gettysburg College',
  'Gonzaga University', 'Grambling State University', 'Grand Canyon University', 'Grenoble Ecole de Management', 'Grinnell College', 'Guilford College', 'Hamilton College', 'Hampden-Sydney College', 'Harper College', 'Harvard University', 'Harvey Mudd College', 'Haverford College in Pennsylvania', 'Hobart and William Smith Colleges', 'Hofstra University', 'Howard University', 'Humber College', 'IE University', 'Illinois State University', 'Illinois Wesleyan University',
  'Indiana University', "Institut d'Etudes Politiques de Paris (Sciences Po)", 'Iowa State University', 'Ithaca College', 'Jackson State University', 'Jacksonville State University', 'James Madison University', 'Johns Hopkins University', 'Juniata College', 'Kansas State University', 'Kean College', 'Kennesaw State University', 'Knox College', 'Lafayette College', 'Lake Forest College', 'Lawrence University', 'Lehigh University', 'Liberty University',
   'Longwood University', 'Loyola College', 'Loyola Marymount University', 'Loyola University Chicago', 'Lynchburg College', 'Macalester College', 'Macomb Community College', 'Marquette University', 'Massachusetts Institute of Technology', 'McGill University', 'McMaster University', 'McNeese State University', 'Mercy College', 'Miami University of Ohio', 'Michigan State University', 'Middlebury College', 'Montclair State University', 'Morehouse',
   'Morgan State University', 'Mount Holyoke College', 'Mount Saint Mary College', 'Mt. Hood Community College', 'Mt. San Antonio College', 'Narsee Monjee Institute of Management Studies', 'National University of Singapore', 'Nevada System of Higher Education', 'New Jersey Institute of Technology', 'New York University', 'North Carolina A&T State University', 'North Carolina State University', 'Northeastern University', 'Northern Arizona University',
    'Northern Kentucky University', 'Northwestern University', 'Oberlin College', 'Occidental College', 'Ohio State University - Columbus', 'Ohio University', 'Oklahoma State University', 'Old Dominion University', 'Oregon State University', 'Pace University', 'Pacific Lutheran University', 'Pennsylvania Institute of Technology', 'Pennsylvania State University', 'Pitzer College', 'Pomona College', 'Portland State University', 'Princeton University',
    'Providence College', 'Purdue University', "Queen's University", 'Quinnipiac College', 'RCSI-Medical University of Bahrain', 'Raritan Valley Community College', 'Rennes School of Business', 'Rhodes College', 'Rice University', 'Riverside Community College District', 'Rochester Institute of Technology', 'Roger Williams University', 'Rollins College', 'Rowan College', 'Rutgers University', 'Sacred Heart University', 'Saddleback College',
    "Saint John's University (NY)", "Saint Joseph's University", "Saint Michael's College", 'Saint Olaf College', 'Salisbury University', 'San Diego State University', 'San Jose State University', 'San Mateo County Community College District', 'Santa Barbara City College', 'Santa Clara University', 'Santa Monica College', 'Scripps College', 'Seattle University', 'Seton Hall University', 'Skidmore College', 'Smith College', 'Southern College',
     'Southern Methodist University', 'St. Francis College', 'St. Lawrence University', 'Stanford University', 'State University of New York at Albany', 'State University of New York at Binghamton', 'State University of New York at Buffalo', 'State University of New York at Stony Brook', 'Stetson University', 'Stevens Institute of Technology', 'Suffolk University', 'Swarthmore College', 'Sweet Briar College', 'Syracuse University', 'Temple University',
      'Tennessee State University', 'Texas A&M University - College Station', 'Texas A&M University - Kingsville', 'Texas Christian University', 'Texas State University', 'Texas Tech University', 'The College of New Jersey', 'The University of Alabama', 'The University of North Carolina at Greensboro', 'The University of Texas at Austin', 'The University of Texas at Rio Grande Valley', 'Toronto Metropolitan University', 'Trinity College (CT)', 'Trinity University',
      'Tufts University', 'Tulane University', 'Union College', 'Universitat Pompeu Fabra', 'University of Akron', 'University of Alabama at Birmingham', 'University of Alberta', 'University of Arizona', 'University of Arkansas - Fayetteville', 'University of British Columbia', 'University of California, Berkeley', 'University of California, Davis', 'University of California, Irvine', 'University of California, Los Angeles', 'University of California, Merced',
      'University of California, Riverside', 'University of California, San Diego', 'University of California, Santa Barbara', 'University of California, Santa Cruz', 'University of Central Arkansas', 'University of Central Florida', 'University of Chicago', 'University of Cincinnati', 'University of Colorado at Boulder', 'University of Connecticut', 'University of Delaware', 'University of Dublin, Trinity College', 'University of Florida', 'University of Georgia',
      'University of Hong Kong', 'University of Houston', 'University of Houston-Downtown', 'University of Idaho', 'University of Illinois at Chicago', 'University of Illinois, Urbana-Champaign', 'University of Iowa', 'University of Kentucky', 'University of La Verne', 'University of Louisiana at Monroe', 'University of Louisville', 'University of Maryland Eastern Shore', 'University of Maryland, Baltimore County', 'University of Maryland, College Park', 'University of Massachusetts Boston',
       'University of Massachusetts at Amherst', 'University of Massachusetts at Lowell', 'University of Miami', 'University of Michigan - Ann Arbor', 'University of Minnesota', 'University of Missouri', 'University of Nebraska, Lincoln', 'University of New Hampshire', 'University of New Mexico', 'University of New Orleans', 'University of North Carolina at Chapel Hill', 'University of North Carolina at Wilmington', 'University of North Texas',
        'University of Notre Dame', 'University of Oklahoma', 'University of Oregon', 'University of Ottawa', 'University of Pennsylvania', 'University of Pittsburgh', 'University of Rochester', 'University of San Diego', 'University of San Francisco', 'University of South Carolina', 'University of South Florida', 'University of Southern California', 'University of Tampa', 'University of Tennessee, Knoxville', 'University of Texas at Arlington',
         'University of Texas at Dallas', 'University of Toronto', 'University of Utah', 'University of Vermont', 'University of Victoria', 'University of Virginia, Charlottesville', 'University of Washington', 'University of Waterloo', 'University of Western Ontario', 'University of Wisconsin - Madison', 'University of Wisconsin - Whitewater', 'Université Libre de Bruxelles', 'Université du Québec à Montréal', 'Utah State University', 'Vanderbilt University',
         'Vassar College', 'Villanova University', 'Virginia Commonwealth University', 'Virginia Community College System', 'Virginia Tech', 'Wake Forest University', 'Wake Technical Community College', 'Washington & Lee University', 'Washington University, Saint Louis', 'Weber State University', 'Wellesley College', 'Wesleyan University', 'West Virginia University', 'Western Michigan University', 'Westminster College of Fulton',
          'Wichita State University', 'Wilfrid Laurier University', 'William Paterson University', 'Williams College', 'Worcester Polytechnic Institute', 'Yale University', 'Yeshiva University', 'York University', 'École des Hautes Études Commerciales']


export const PROFILE_UPDATE_REMINDER_INTERVAL = 90 * 24 * 60 * 60 * 1000; // 90 days in milliseconds

export const COLLEGE_LOGOS_MAP = {
  "Eastern Michigan University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Eastern%20Michigan%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=LtTHQ4BzSQ3NZeT%2FJJDUBU5V45Wz77XqQ2saBDNTv%2FDpFGQ006pSROLkeiwXAOpo1sfctH2ax%2B7Ieyyi1c5u6zFUtfbgo8uoJD0qeyMzcC3csXa4T90NexAiKhSbw%2FGuWV%2FynyYCRBmp7BXALg8ZrjNAfGUW6CH9aDGhf1CUZAgMNsBb1B%2FajvelGXeIIwHcdn1I6vL98DHeXXbMu315ICTt7sVnQQ7kr8lMMxB%2B8PMrrADgJ9algqpGqUMt2%2FM%2BL9DHQHZ7S1Ck5dsdM%2BTWaVeo7o5XIdJfEt9jmy3hMGL79L08uu0LKuXx1ePvswcu7dRlXKRocGNMsjrWS3UAhA%3D%3D",
  "Williams College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Williams%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=gNcan7e%2BLQn4oHHHrc7skROMptxmYY86U%2B0lwprtGxDaypgB8XuISJg9%2F3TYCF0ITG0mMbC0y19Q3TRbKPYvveJtBZsjGqVM4Ohh48ktehqZBUy%2B5wNBnlyriqZ1Fbfb3loY2rkmwMTcjcZzq3h1NXBkcxOTLFtwc72eWuMF8RJV5mdGUcKooXeWkYqhWqpn%2BxlrNI3pHBQZM6i%2BcMcl%2BrRfVBABstmfvxdEObU02tSmjUXVHbnUmYAdr1JmNw0FIH0O9jlKBfdLA2cQX3wRqvV90kqFFMMKuSkJT5jac4wx%2FsuLmay8cZM1OZ6vb9I310wGveltG4BZ2P0Jzt2dug%3D%3D",
  "University of Pennsylvania": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Pennsylvania?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=V7IVfc3ZYxBH0nu9IpAZze6U%2Bd2mZenbMglMQAVU6qzen%2Fx8swxS%2BXUFSY4w7EZxQcc4QfLWBGVF5N4gi88SbPIhGJKWnQ7rpYCHX4fJLfB1Y5IqvlNUniODqtqePRI3C8rDAqGFs6cnLbldNWNXJvzjeKSAkCiX41xxugWkaXNoK83HoBjkOg3kfhGVBOmjhftS2sMFY%2Fq1L17DOXyJe7uvVSncjAhysK%2Fbj14%2FowLKyIf6n6AVT2gzC3DS5JBdxo7nFyXorZ5PoIdY2KqFpwbes8jfv%2FIXkpDNcTnovhxRScX7Ee2mC5axqbffFLp9KJ3z2fd38YeBjIv5dj%2F2Xg%3D%3D",
  "Cornell University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Cornell%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=xM5i6FXU7xkkjh6RfCXE7k0Qo0RkooihiNfxzjLsqDs8WHC31Yrdch0i5jKDg%2Fey9gbs8%2FxG8pMRPoe6NvBAYgbxwI%2Fs2Z%2BPiQMQkvC0TFsurMKJ8P6OYrRqRlfc3QbUtMMH65N%2FbOZgI9XfPqEV3pGdVGIDknXUhoOYK%2FFHVFFpBzdJXGdpL2h0OHyi498AH4GQaosHQIZnYGDaFDMtGpek8NU6uMcIsMU8Bso4E9KWMaAN04DpNA%2BWxubCLIQucgXo4SuxMkzD2sy46U6jEPpBjTYpcCIfZZfhqknEQZmgzHv%2FRxjFaPZz91lFNj5TtLYuy0K3OkSHtiDsGm%2F3Lg%3D%3D",
  "University of California, Berkeley": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20California%2C%20Berkeley?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=EYs330c8CRgwvrOvTwKwmOnPpiBejgoLWpU2dmteJemOlx92Xjys0nfgoXw9aYi%2FVa%2FkkGGwxfwk5oGkEBSHCCVfMJBnAFv2%2F8amJyLdJ9Zsi33F4cPGI7Gkbks4LTX1jSyXzYZrGjwsSvmcro3%2B1Cuvoc80nPL1WuEw2nvIfxJCgdx7Gzf7fJ3X%2F4ZdHMKAaKphlB7vlWGPTEkNY3pVuVRE67nczuLzys09L54IQRfEVs%2Bn%2FGQrUg%2FjbPcT9UHvmAJ%2FSxZYaO%2FQ0325koVpQ77lyKIk%2BhOKrH7%2BsjqLe9CQeQ7KYMpKoPt5sDzeYCE%2Fqaex1kEnPcUBfjnEAsxd8w%3D%3D",
  "Lehigh University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Lehigh%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=CObdQwYt0l1kcjTH7Xh3LGgH4Wwk0TE0euF%2F0Xde8yAMVrKnvfJBwXV93SG5gW8aWSmZMnIs3MY6b2Nr8yGcU2N%2ByauA98BnDEUtbkhr6%2BeXBKlRqWDSM4H%2Bl%2F%2BGmBk6AJVUyg1pNShsYKytu7PKZBYCLrfH6GhOudXmJ8%2FoAaOUmUIkkctLdmWgdWa6kUD%2F1%2B1Wbhcuh%2BOQMdMYWqn7hS5NMshyORVK6F2%2F%2FUX7T9jPZGqCSIoJ7MuQnY7XwP32x9h9vwkHHxZgF1ECX47d7B99MUGUjtM0eY2uIUY6d1TxOI%2BE%2B6woYy1PFLqrFR2RrYmnsP6Fjbw%2FsUvLm21fIg%3D%3D",
  "New York University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/NYU%20Stern%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=V72zWDbmgXd7AexsIn6kV88kGW7oa4ij0%2BbCmI4AOR9w%2Bk6xx75iHzJKs6F8gfWGzkHUVOfn8sh9sIoeRCmWoIURMxZon%2F0Z3k%2FVfGPiS4tUNwpNeZwMKhnyPQKx%2FXWHFWDDeD%2B50tieqKU7fruOlKGes%2FhIPIcxMpMr1aDP1SuD2bpOYSKYVohc92SMhTtkygwFsLvqkz4kTjAubFmzHWtxV9dMRMoxnH3BfBPKok%2B0Eb2l7tmgPEyQ2TEMNXudEm8MSfqREu%2F7XNYPOhv6jCiaTN6vjUH3MDNZ5LqSe5CUkjInyuiCtlbZrEjZI3n%2BDtdefsW%2Bp%2FOr4vVofvwUQA%3D%3D",
  "Tufts University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Tufts%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=bxsHTDSk5U%2FSDdSGsMkV1CTjko3%2B9UbuMmy%2BhwLASIsB1RWtlxPFTRVKSei%2FN4mrhSQ%2Bk5uKHDrbrbCrT4ALrLC%2FKSgGd1pktivJcU0h0Xdavb62OhC%2Fk7n1n96VyMvfvceTUhqCL3vh8Grry%2FO8Mp1kg8DtJ%2B6mmQq60IGKvQHxWWk49ziqseXm4bqfGd92rsmaJUJxxMdWXJPl3cfvrjoj00f9ss%2F83ZYQJcR3Yjbk5HU33xgHcFfINSvA4FJVKhY6165s7%2BVENfyjo5YdKu06kd6qMBJR5Ew0umETBiJEbTVs6hlg%2B4x8payqEyP5C4MgC%2FSX21h4kX5rP45axw%3D%3D",
  "Harvey Mudd College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Harvey%20Mudd%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ALPTSqXDso7Dy5pqLc%2BH5lzbhmRqnzu5wEzMlQy1TwCuny%2F8c1PWSscs13wW8O3r%2BJQUADj1k6GMjzi3dvnPbzz7na%2BQn5xSaYL0Io8mbbF4bJOhpplg1nmDg9N3qmNg6r88q7zgxKdjG76zRtu0lqg%2FZQU7GGKoJ5Dw94%2B2lhpKs%2FIUy6osLUxt0mVDcPhfCN%2FSPObViL6kBNb%2FvLsBt9Gaey37U4RfRgoC7FQY91P8dj9SBr%2FVF1ofAqD9dLdgR2uaIPUNgLr%2F4JIqYpScggZTz7SdEhOATjJB7S3uKUvHGtrhPx87JvO2t3vMP0PCWueXYRh1fYpQqlOcHICimA%3D%3D",
  "Vanderbilt University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Vanderbilt%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=UxYc9XbO61VPngEALnukHqLjzCuum61zw2t%2B0ziDp954Zbvy96X5WG6L235W40Jag2ntmJ1lN4PmreshtBj7xDMkZDFf%2Fo7MFmex689Uqu6MbJ1FK0wLN%2BTJIlfQhWAZpPc3qangHOCwZz8raEs5ULPXQgPt%2FBh3qrVPL%2FvYPfJYhXNVpFg0OCJb7zddYccBFKy8zABO5bIaV%2BC5OUIKicrsOItDUd3azwlGqQWTYiMsrwNDvPkQo8%2BMo2T4unpCx6u%2FrGPpNDR8vj5V2YoP8D9dCcvaVmzwNA5eTLih%2B01cpNtTwJHju8LnMutpA13aSsorW4A475VqBMEHu7Hakg%3D%3D",
  "Case Western Reserve University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Case%20Western%20Reserve%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=wyzSADYWakcPJhdeu%2B6jA9BqdVYAo%2BSlzna6kX2qQ9LHBAeCUYNUxV1FGAL%2BWoUxPXNnaZVCV83Z3c51gCrW20Mj4fFWYG%2Be0QKfVq%2Br9gCTmMrmrlYb%2FzmsXTBvmLskFg5s2ql4mVbfCCwxyFaxho79M6WbvH%2FVGwaAiSz8eqqpQY8sdy%2FeBeez3EXjrYE8vcyvQKq0AyLyE9wG6xtxDEuzvRbURkhEiyn60vM2LIc80PyXxNUQRFH27oJVV8OCi8qNFhbddPO1mQh%2FA2vYJXD9vtdqExXd7VrTSrazU7IZQCSgSrowqSav2QSULAJzgzwjGDNCJ3VNLm0A%2BWE5UA%3D%3D",
  "Colby College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Colby%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=wGIrH8b1RQS68qBAqQMhgfUSiwJXV6xFK8UHVqF9AlW5X7ghDQ%2BdUNuzHRTEhQLIAZLYxaYeiX78VnS2VQzdlvYRuTVsDmn80gFUUIai5A1oL2NHjVdQgtEEfXWqTsimpuMH81KCJCrtdaU5Kuh7Y8M5%2FGrkIk1mR0nGs0jZ9ZfsTmpacOK%2FJngBJVeehhOJZVwfVYq6MMD5dbwqAe3nC7J0GsIorK%2B8kCwaUCcrP3RuS3VpqCEAzIPpjkBwIbGUHnkPslH6vH0OWhbECChUDhQA0kvptArPWDMJtA6nVAasWFwq9SoNn0aVmvMGYyew6QWLRHSPZcjxhKTx8QalaA%3D%3D",
  "Fordham University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Fordham%20Gabelli%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=P3yTpqDi8l57h6zw%2BMbRAEawcoHIYfYUm2BOwTgO7GaDlrejoC%2Frj2m4ckEA4I6qUxkP2Gr5CCatSqZtBsTr3uq1p3IBLKHha%2BSCpA1IQ0awm97cvn5EqnUBnwSH1QdtEe1Mtc8f5lfqbImijmQZi1cdAjjjU%2B%2BmQi08pt6%2Fkjd1nbcLchGuSY14%2FoQOajgUwiKKFoVSF%2F%2BgsIGmD9csmgm9fdy0CuodYfWYpXYKJqA3CvoSTmeAsEJ1%2FfskPm1%2FZ3r3lbWTCD8ThzHvpPIeFbzV2guj7a1ZzN4NMpowy0e1kKEdwrXc9jIqzFZyBvM3wTMUYSPheScHkKq05FWBgA%3D%3D",
  "Auburn University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Auburn%20University%20Harbert%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Qjk8i4pabjcCIsQmDTN1tFSpk7X4OUPv0pWEMd6hjiognYm6NPay0DeO%2BnFCNI%2FG2jB7ACO5algabNJImRxjI5H3ls4RwlKQNpWuVbRhhiLH69cn53y55sMZuemtRErpc9CPVNUn8UF0Jn97A%2FDR39YRJCJkwXkZbgErhFWoO1r48%2FtDqFb04LnE%2By4RK5WHJmJ7O9qAGhPl4hoIo3MU%2FczFicMxTOvYX1doBlZw6zSdR6JFoFUNvump1A5kS1IaQcgLfeAngNKcA6GXLj7sNcA3W%2FxqPa86Dw55cOHH4SbAFdfeqIUR7YT8M%2BsdNlZCdVf18AVFh4z4Z0Hc%2Bvjzkg%3D%3D",
  "University of California, San Diego": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/UC%20San%20Diego?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=SY%2FflI4fcq5PCHLTRP%2FFQ%2Bzw0H2yYbR06hv92SSsQ2V%2FpHQPiItlp6eDNK13XzepjTvyD3gk%2FcR6%2BQvvLQQ9BqlWxdYMcnz%2FJsgoSC9JEq52tejLoDf%2BF4ZiTJsgnMQiJhS0AqLBPUk4hys4GpZXSsBTigMyXmGGDBS94uL%2FldoYoSCB5%2B5%2BVPf6WVvdzCpRE85qmbJOhQO6mMTZ%2BQCVEgXepYpkNHw2NFUYinmb%2FHpEonvsoTtyOyHPJ1l7BRfcHk8ObffwNAFsAGjX%2F8CbJ3zqjlLNuj4t9Nm1mmICcDY9PX8qhOrhI%2FZzXpMQ7%2BXiSCZaDsuxnFLUUumgVjpPoA%3D%3D",
  "Colgate University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Colgate%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=aH2k%2FyCRzfVz10i4GWSgZN4szUqwUTrRG2l2X%2BePLQEMHLcV0byEbhPjwKUfLy6D%2FF81mVfc9pyp7OewVbPp6p9qLox02Sc36NPr02ke58C8V0rfeNl8QUDc3w8w1pq6FpFa%2FfEWmwWkJK4OXRpxFmpFIbVcr5bXPw0MGbpl45DMUlhonLzi4XBFdcYpaPrTwzZkbzIxL2qRqzCYhBBw0lNLGaGbDPFLhVC0jwb31DYsv23vqYObjSoRRAidl74IdJaJAgDpurTLTXVgZlck1N%2FT0TDcqc3PvCEkechWKNhtY%2BEp9NT%2BfKjcQ2cF6XeknO5aVYKV9adjViEVK3k7DQ%3D%3D",
  "University of California, Los Angeles": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/UCLA?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=eiCrXARmf6iHLMq2yr02rwJ8jSbDC4uY0rYba7Cg6Pxp4CKv4z8b%2B76yd9M%2FQzytKfjdAXmsK8f%2FuB5wUxS25HXLkzB6oyi4wr1mtx3oE0vjSj86loyQKOFe0VicRcnjYLPdKJRjhfKJr8L2WOexiskuCFY3yXyqqkLTSAXb2LYgYxgFXgMKKQRWjKhJjMDB%2FgFGc9vjaFbhXCCN%2F9QxfR0QjBcKC%2FmpL3c%2FseSfX2fuGdaem8pjbA9bHFGck4sbc2Djb4fFTz5gx%2B%2Fs6DqTT%2Bg0IyMZ5V5QvR5v5NV%2BwyzcB8ZUyPWn%2BPytuCeE1Gp%2FRgS7lPtqj1DxmBW88wQSoQ%3D%3D",
  "Claremont McKenna College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Claremont%20McKenna%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=E1mVbkd5JKT3%2FGsRWSslNoIwU9GA3R5Q5Gci1QWT%2Bsj5FGEKtpgzScw5xZ7zD%2FNqneYJoMwyxFjwOQB8T%2B95wk2580qUT1taMNuKAqx%2F%2BH5BBcC5oaaDjruBwKcRn9tlb3VrUPCjs%2BaBvUL4BzfwxrrMKdrKrYwSrOgoFsMjtz2Eis0i2L8XwIBlGC%2FJv6b4A9kQWFDQKYJSsqMk%2FiLFTAgGGJJymCuLFcu6snvwEY9q28V09puTUx8XtVEZQiM7yF8jcb%2FdTksRVmljLNic2i%2Bk6HT5Jaz2NY5pD7%2FgFwMOVkCmkfcRBjL4uxNnNgPNV6APcmWQ3Izp9B3VyP%2BTZQ%3D%3D",
  "State University of New York at Binghamton": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Binghamton%20University%20School%20of%20Management?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=P%2BLxc%2F7UCQDMnvW74fu5F1TccaE2Droat%2FDwZrDTvcRJaHMtrZBIBMaiJChAHWer6JXBG5ftijPM9xrqT%2B7%2B26fYbKgN1ZfBdMPcubFEv%2BgdRFRD%2F216%2FdWk8hWqVcaC0oRTxIIraMJN2o9NfGUHbeI8kNqQHrTnkoz1aRLxoLKNSOCmpmG1dmJDJ3wBs3VBH1YFg%2FB9YjOsmknb7r2APg2MITWgM%2BprT%2FDOXXqaiaSI5K2bFL5clvYNM6oaODtkkSg2Bqs2lA0ahx54TiTrd8z0VwYuP3AIA57%2BSvSckcEWpEJYwPv6dsLN6GhAnzqJVAaWJRqaYW2t5%2F9u2lPOkg%3D%3D",
  "George Washington University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20George%20Washington%20University%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=d2%2FQBnnD%2BLx9oCZ13qgHy9gEFx8tTgVih9NZXtWJ%2FrWyvtOuDvRfL1FAmi1AQtjq3grJCuwRTwuXIG6UIn7tXogMrB38kWJzWSVzcu1Sx5Wd3tWTLlleisj7CTciXQoHq9fZTacvx9Ym045wh9ifS6uhYCCxt1KaYF%2Fu0ZafzJv8ZprEVZTTBnd1wuitPFksT6Ybre3lPcT9Nj80pK%2Bnrq5QJBWH3G%2Fpi2e6WtfzyJPa4qxqYaxCNksFOl6IKneafyhxUy53iLtZ7kv49kc1Gd6lP8Vwb%2F76dqEndKxr8vp8D8eNAglJrxm56hx7%2BiI%2Fbaqu0aOe2U9Hsfi5%2FFo%2FNw%3D%3D",
  "Seton Hall University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Seton%20Hall%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=cyw5P3xU3qweU2WtgkiegQdgNQfdG02E6TZjUChLnf2c6TFr87%2FmVH%2FhZjJGcWiy5kvUo96%2FnxfC3oCQMoE2x9D1mvy07p3Gw4550SK%2BuJi9bEsJZ4dXUGbjQ7H2OpX8EXCOmpT4al18v0MmFP9IRzYUhKFW5gxArnT3gJ%2F0gy715%2BPO4OWBd0ALvb0hGV6foB%2F4i7FjL0NUd7Oe97tY1o401TS33rKqnLBNAa7flCXlQx5QhhOqSbx9uoY5mciDftLTw%2B8bx94gnwILCj2NW7XDaByPFSfFROp%2FZ7Vzk%2Fwd4v0qdcX5qt0WfHI8jxuaNNYGiNBP25Aofc7SzEzTvw%3D%3D",
  "University of Minnesota": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Minnesota%20-%20Carlson%20School%20of%20Management?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Z32JvBt0hv5lLl8C2j7DlDtcwJg4JmNWx46NQa0qT7vLxBZs8OD2yl%2BKuZXfwKCGtNBhfIxIqqXtfpuPbXDwSSu3Ht%2BWro0plFYRhlF7ktptDhi2%2BiMRCgEobM%2BSjcsCiV7IezAtKGwhAIjXj1hy67KgdG3MfEKX1TrnlF%2FsM59b1wTK2QqHG%2FcXVTYWxGh1FW0xUayiOGcUqcP53ct2s0JBUrLuNyC%2FqiW%2F7zOMaUIIyHCMnMcav6TF3%2FaWgloxCsIaDIvA3oyStU79NIYhuCru4Woqc5JTBYR4x5svdyaJq4P7N8t6xHgJFMGsl7vw%2BFHXKGsVcBwnprzTQ%2BtOZg%3D%3D",
  "University of Chicago": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Chicago?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ATPY31pr3b86A61mkR4ylI8Xp82WbxoZz9kw%2BM8chCTSZW1PysNBxpdFB29p%2FwAiRfszuUF7Ime%2B%2BqMDJAOUAmODHt34LrCfjdFsHyIPLz6E7pf8XM7ytX6zqDSPTQ%2BEQikWF%2Fs2jfmF%2B%2B6ghBeXpAdHtPARFFr1PGcmWJpgIlfABaROYdwTcZ%2FVSuFi3Z13pjB1sL9e8lzT5LlaYDIVaRTCXEhDU1sUeo1rwk9G53J0MC2nvAoB0pnJxDuOsry3fjarxhg8RpcuBkUOdP%2BFtVFgUy1EIHZVUqBhFhDw%2BniXs9dnopUE6R1irZl%2F7RrjCZmlof7%2B69w6Ku%2BslgDbFg%3D%3D",
  "Michigan State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Michigan%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=a1bjhxHjSCW8b%2BIIaFkaEgMkgN8xPHFO4TegLaZjYoiXstCLMps%2B5Lorwl%2FfihfHOqKAe8t26QmVL12JFkhfYtuLkd2KDf%2Fwis47rzg4sknEt7yfYql4BUW29SZnwHsUyDOPWc9lQ5mAjxaFlDYNOmi0w7TSnUU2sV3y5%2BNLyXTDjtTUvUc48TjIbm%2FBUzzxhGrLw%2FazeqDq4myXvfFXDNDA6ObsyjeOaER8Klesz0lIDWed%2FBS%2FNYUaLtcfwXEPVldkQYyxV09tEYR2ieOvMktHf6kGqAU24tYjVrk%2Fly6qBo7cevlxxqkFoPUCJE9cSeo2QsBhvjBL%2Bo1NP7jJIQ%3D%3D",
  "University of Houston": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Houston%2C%20C.T.%20Bauer%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=VwnnWQXPdec%2F3%2Fg5eQBv%2BIR82SJ1EANxgJ3%2BLsclt90l8zEgq53s6zo4TvDMYyLYx3aV3aXDFFc%2BainuDnn5g%2FNAQt7us4xR5w8bi5y1dWmbM%2BciNNX8ZptnDMUeuQOLQwRsEJB5K7M2WKmfkIgdc4ZpGcfsQuKgMCweAn6qx0crd90pWVrSrOBZh7vgAwXleTrtW8DXw5CRQo7dv5H0DIYDVLyqAcohuIMaQtqxaNUx2cw4Is2yqGxpCRayH65Zd15lcUnwRppjbf08IZbMJ90nEMEiqmCBBlXImBPfb9dOTgEWbzSPeDDW%2F3TXKxLZdbBY%2F4xun0ceUbQeM%2FUXFA%3D%3D",
  "Yale University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Yale%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=h8NzBDtWsffBAlkrZmUnLd2lzTbivTyyMfEC8jda5519joVhdHruCtjeBAduNfAj%2FkEdDIkueLL7CCk7XEh4v5eyZXCDD8eEUvcKruhPJRYjGkqoc5auj0IgybIokCaFIpAZ3uZCQ0U5qQceU752HVYgeaVlz9UHiHDM824cDYynUQcEjY8khubE4zC0hAoYMIKATBpUR%2B8H78dNRrH%2FCy5dt%2Be5XWOro6hBhk3vThy7%2FwQtSIaOBiQBNRPOFtvC1ORFs%2B6zthxn7AOv04DP%2BJadFRdl8SdfXQNp06iKk7Uwz8CVz1VcFcHO4ZdzbE8PRTZrWjM69zUoo5OX7HEnHQ%3D%3D",
  "Boston College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Boston%20College%20Carroll%20School%20of%20Management?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=WEb90oL6poF9ZqEadI4jRVMteUIC35cBJRm5vktEX59IJzrtddAcjuXzW9zfD13z9uv67VdhAlJE3OSCRwQvVvkBmYjpisUa4QVz4WFAB%2B33V6jR%2FOMCn9j8J%2FlN7Ge7tjkqC1LiZRjOfY17ZLPhBjpOlOZfr0f9bxqG546JAI9FdUCAFh%2B%2FPhKthdqCQM%2BgW5vyluyRmjCMbZDGuUM4vfXrNzpUj5hibif0nC1Z4KG5ICFxELJKexa6vudZPYxUsqyXwcBqKa47eMAr5nV1fN9T3C%2Fy2m2YAWMZP0d3XDutISj0aIOQ45yQpnW9zDAfzpb08hmXbwuTsL3yY8SF%2Bw%3D%3D",
  "Drexel University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Drexel%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=eJdTKEIt9r0LmrN0dEFp7jVJW1j%2FGPnTqqXdcBSRM43%2Fl460soOElKObbDyLieKsQ37lHawn8FhApb%2FmANoVxPabX5g0wVAp645gtnw0YQvfSw64rGCPDjyzbk8upy4sQ8FtqbVOIXPocva0VxdQnuTawTuXtQdCn%2Btbzuuda%2FwgzVHP3MWxHt2pVTBaqkgtaVdB8%2FDtDvWmdr8L1lct9I92EeOq40dTAGpcV%2Ba%2BC4jS6QUjsCbTkoQq5oIEkckaemmDFjaDUCNi49aYt%2Bxz%2FlDVDOhWjTs3RX%2BJyK%2FNOv6i2U0KK3D4q1LRa0Nn6HzswiJXf3kAkMaVcaU5Bg7V8g%3D%3D",
  "University of Wisconsin - Madison": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Wisconsin-Madison?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=aR5KUiSOwIO8BfaYlU2jVx%2FkEaPAcIR4Ss%2Bjy10eCEbkgdKnawWmpA3%2FjgwXwI2QbLGmNKVQFaDn9HZRIy4np2ub01EuHEIgVblMc39LzTUr2DBVbqKfl9ttjjT65xzv79BKYQzuS8ytNcJYRvi9hpa7gfRfQk72PqWPIaq5PWS5z%2BH47FJGl%2BL%2FMGYRM%2BqwsHZqLEMLQVwx7JkLey5Lm3noJyVKq1MNy17ixsHwMgAif%2BzK9MmM5Sr9mkzrmvF0EgX2nEKcuz5jCrqEXgVPiXEF%2BLYfK0uDIUE1nRtYTPCE0RuUFaF2%2FmSihee86MVEfUluQ2DEhSTZPNR5QAtDRQ%3D%3D",
  "University of Michigan - Ann Arbor": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Michigan?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=GO37fJa8fz%2BoxNlJpzfespOLWbNqBjxBdJMeh9KNkkj6uYo%2FuWwyw75jP%2F6XkEJcTXTRWZvk8FelcmZHLJnaA3HGFc%2Bhemodsvcz6g9AJM0qGhRN90aI0XwfJNzfI4QtMCwwk7JCKuAqo11YTtV3ZFxdel%2B5sbRt6pleyUoFiNmgUo3%2F5ODOkiCx6wH3no5z7doGJ3STptqo9ovKqkB%2BpvABYjUturz%2BfG5R8bBye37Clp%2FGFMkNUzWLhxQ5BUXCyWk5F54RI%2Fe7i%2B0BxDuKJol%2BZtfoYewv8Rf7tCqSjEzu030y0Gsd1b8sFaXKPVtfGnMm8PjzKs%2ByBvOBpiiqNQ%3D%3D",
  "Vassar College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Vassar%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=aPd23KbqVvn8YPLAT2JN2U3VOA7w%2FKlbM%2F7wAapC3rzqrSJu%2BBRHYhVvw09ZsjsqpR%2FyUH6YF3N4bAwAoJI3qMP2bO1QOpCnvdaZxZZoikvmL0jGT%2BAoMg1aEJx2JpIaRcOa2U5uMYFFpUTVCoAIANTmX%2F5pWPF8iRmKKfu1YYftZiQdR6cm4%2BY264fg945tak3Lk4ima00isupMvkR%2FmC23dyKPOeSch0U1h1CtXdEKkFmRZSNassJSY%2FPCZ3fawOmSksxZpD9IPZDY%2Fe5eqq4ENfb7EGj6AQhk0P1isgrNXGnLhR7TVyUBhDFqqBUHLJ8diRq5v4pgsvb2vFpJTQ%3D%3D",
  "University of California, Davis": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20California%2C%20Davis?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=xPBlCwLqPagT83EqewiZOGsegM7ObpRhEP0k7R228QShrWsv2r5aRHQZ2egsnOW6sRYL55LqVp6AlT2QizYgbecN%2B9H3%2B%2BSprib%2FthBs5kYkkG5lXB12CGkomh3fjdPfTB0e8UrGk4kEFYeUQITbZ%2Bv313ALVfKk4EJoqlh9JlqqqdVORAEnjWa6xXT829YrbDCRBCstDOzbQ5PY8qZQ0boZbaKGomza7K6o6DDkOEpthX6mMv5f5cSnM6tq%2FjvOLeJxQsbjkP7%2FJW%2FI5cPXNyiu8cNxaB9gLfTel5MQWSw6VbV2f95ASi77Rf7HIlYm8IE72Xu4LeGJjRUIGRjviQ%3D%3D",
  "University of California, Santa Barbara": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/UC%20Santa%20Barbara?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=d2EPTdEXoCjD6v8pwBYABAicZQ70CxrG4ow11dp5EJZ0C7FiFedW8wAVV9CJrsRGEozUXsqLB7SAFTClXbtCuIb%2BHy7jNb44Xw4rceaZ5787PUmhdkkn9lphwAPUGU2z2sQa1MMS3ThsrT4hmS%2F23kewB%2FYssqFzSDkWc08ltW01mWjHMQhge%2FqpiNiHZ%2FgK6KEnI5RBrferB1vqkd4zmD4cX%2BJdEVqf%2BRkeclnBQfgWyvS5qEo%2B9al%2Bf9QgpF1H%2FUnIMYFt%2FnYgQWo4Bzvilrlowx9Mp4UKucuLReWqwunNupkj44RxyjsTYIatsGufrBkHC%2BRETZka1h9IiPixRg%3D%3D",
  "University of Arizona": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Arizona?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=bQ1%2BqH4vXX60TuyZebL6iSnYkuFpWD6qZBIeDevjVy4LceoVyyXycsFxqSJBmqASgBqLdpRjyVfdF381MYeHFFgEwyR4F7NRUvHkFnRGfKxmR29cUGwyY%2B%2B4ShfcMdRHZpsY4ckQkRW%2FTSGdiD4z8%2BqAroGRtSIfqg5sz34tuh4blNY1FSsLRbrT1CL0OAtAcKIdu0PCTnj93xZqRz5UIUNLVK2xRvwVJ6AJRYW9d6EF3aOxEviIdtTxlnwYtPDI1zKM85GvTaxbxYppF799Nhmh1dyCDlVlrB93N%2F19NYTgpc7SHuK5JlTVsuBlEuEB27sRCSfvLPvzA1PG9lRufA%3D%3D",
  "Bowdoin College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Bowdoin%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=K9hLKBvryjLelQe55dE8dQfYrrtBSLNSp79WWeLW4ANoO8OFgLovEzR2PXxKgDwjRsCn7CyF176JLg5OqOnTtWWludSFx%2B7JSYp0ubXu78cGwuGVds2eB8KdcNOe%2F4WfkSnF0bChTIjutZ8omDFYRfL1elOyp3lzIwhcEgZ2XK8kRgOAEzqHf%2BO2UxmrJYKnpz4hhSxK1N7FVwKKFom9w86DyPPP1StmsFYMi%2Fp2QOaRF6AxtlT7SPF3cUwK3%2BRkB3tLXSBYVXJkWm1pGFTqi%2BR8yI%2FPv1nRVKoQ5zAF0%2BNzxy1EJfVJmRNK%2F10lG2ZRCb6o5ceQD1mB2ezflRDfbQ%3D%3D",
  "Trinity College (CT)": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Trinity%20College-Hartford?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=NHiZS0CSwqzvBpL2k1uc75eoVWNCZYgGQphwiNKJ5Sl0ioiYZojkZ7YvcrlPu0eRM901Eugzfgwfy8OxlHKvFbnZsMfOQ5d%2FS%2FLLvJz41L0N1SKwguVpbf1BKhL%2F3aP%2BWGz6vE0neu6EQCeM4Rar1Atit%2Bvg%2BOk8D42KJqqUqQRLiGZvjYirqlT3kDLYr1arIofSNHdC%2BJZNa0T%2BqOhiIsTjE3iAJud%2BQMy3x4RxcZs1O5v3mwl%2BB3ej4%2FPUh%2F03SHoPnFpgwIx%2B3SpT1yqruTNka6%2FWq2oNXWgQdhSgWeqCLeziyv7dVlHdGs2wOWBpXWpp07PcPmk75rNgjfx96g%3D%3D",
  "Syracuse University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Syracuse%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=jSz7ldpjzkPK708p8noBPNeaufFh8p47gTVXnAlBF376BvrEeX6rTpgRhtPhVJ57OGQLjuAdMr9qxRVPTMx0%2F8kS2dzE41WLmpXXQtY%2FJpr8bGJIowN5aGMTzqJUt9mgrsgL4b0PyQb8SZ9R2iidUBW1woM5IgH4cBLEvsJfJt9XuFN6EW7%2B8Vgpoqfvxh003tbhkzaYVdXj7Cib%2BnXdrxRIsInTXoMb3i%2FieJNd0Zo3OR8Lx8re68KGuG%2BmVJPkOQXRfpITIhpyepX1HdDKa1y2I63ZI8r%2BiIUlKScJWcgkLfBbGo31lZvxlxhjPBZDLcCO5ehOc8f0UHrpsKaRjA%3D%3D",
  "Fairfield University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Fairfield%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=mekGdH7bSHezcFTOM8TqigwKEJ1XsHjfL%2B%2FKJNrCZ9xapKvySOu6Jzu%2FZ%2FYFkFylHhNvqnky3eud4c2Hk2MzYdGNvw6SsOWvoim2lsUnBYHA8DcuW0jp4EuQj9y1n6ejYCzkzMBCeU12kmkkgnc4ngwX%2B85wajTON%2FuKiRFc%2BzvAOYqjvlUzvVBEwXl21DZT%2Fp06zxNJoPqFFFGIiO0AltZMnzRudBiY7P9BIbahmD8pbd0aQ%2BhNu2Y7xWFSDppuiROCIk%2FsyG5nGPqMTv82XWykKSzAfB59TfLqLSfwiizWHfjRFfOlQWAGIzKnoNKFU7uquM9lQJtNhldI4ZKkNQ%3D%3D",
  "Howard University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Howard%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=fAmz9RnbyZ4oO0RoWGeocy7Y9g3MxJtPI%2FIyoKGC%2BuAo3O12dVOpn0kd2PQjWU50L20xYXV5WQL0BVbJmJ0ujrLmwZG0XAvjzMCcUJQfxypphZH%2Bj7Ur9YGFqXTnG4HUp8PXly6Eq%2B5jrVpkfGZ1JL%2FSWQ9dzCYsYIuOVIUuOMV9Pg2v4ywWAq8%2FNnspp8veM3mQT%2BDHtxAriu7LmDp5ZSIxBWIDT%2FsWQ%2BRddy5aFiItbNV7qiblkRSgb%2F6bpGV4ZbycmL%2BA8JDMn5sXHTNLJGlcjHPGLdF41dyNaCwkrW6dC9YBtZyBKQtBGA3VjTAWH1eJ7nVcVjvD0BsidyWAFw%3D%3D",
  "Rutgers University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Rutgers%20University%E2%80%93New%20Brunswick?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=pLUxGZ1o%2BTapSwJx%2FZHiShct%2B5gYVlJYmublwArQvRuhij3Nh8KLO2Mhjb90a%2BirrUYwzY%2B%2FsnoE5w%2BuPuwdwx8HZfMrFVqCV68lFA6Bma%2F6m7%2FsQXc4IOBEPm3M5oc3Ds3JtOXG7O79%2FnkVOXSCs%2F2aK7ZDqhtzAX2tbv%2FwY5RxbOWY4duPw3BSlXebnexHD091DtZr8fdx88CKz72YcFWQKm%2BI3601S2b6rQ%2B%2BaRjuUM4%2BYHIMOtWgsgfTTWrFA3amo%2BicdY4KlbU%2FnrvvtQKEEQs%2BAYgFWEooU%2BL%2FYbkTKnX%2FSsCDC9yv7wl1v%2BfW9NA0K9zFuDFW%2B0OjsZxb9Q%3D%3D",
  "Dartmouth College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Dartmouth%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=valcI0bqhtfTClz2crgmGt581OiUZbe4c3G7DzHKHJ%2FYuLuYNJsSQ2zZguC9WCSmZIEr7cPpXYeGEQUVAA8ZQYuKHe7jBer8nLqRCcz7x1B5ZA9RL6g0fidb%2Bt1mYOMsDX68rJr3cn4QoX6GB5QWb%2BHvMFkqHt%2BUCX%2BWXNYZ8cXYbDESjPftqd51zI0daUm%2BKV%2B2PZsT6ipFvoiiaDe5JN89l%2F5IkaLZ1ZBy03%2FfHQhEhxT1jiZojlB7fS2N%2FxzAtUrKiJys%2F5j4FDprQXoQjfvT5kFWEihRqEm2%2BDnguKlJ%2Bp9VrPpgtEaeePnPHL6WzGUgimgnO5Quuhswtn%2B%2F4A%3D%3D",
  "Pomona College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Pomona%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=o0FX%2B2YXE1WYhs35%2Bj3wpZ%2BpoSpJaKKSY%2F31HB09jWjdDftvmybdetUYIT6eynBbEUknqw7G7rCIdb0A1JEaRjDU01UDQq%2BdhODzHDhRnjZNNsxNxh5nmT4mT1p%2BHRLR%2BmIr%2FFOdMXsvXR%2Bu7CrqrNUpP%2BQZKjPA%2B9TffzmBOgRfKjj%2FmBb5nBVIJLmXlvRF5pyhemVahLaz5l7mDC0Ge0LISqWfeI2WuNveqHEodrjKcgvSnWK3dGXX3aryZ0KECyYf2Q0SUk6mtW%2Fv7c1yu1CY43FT%2F%2BXT1V5mufgCuH8zwk1C9BurIRZ1uumh%2BwE6csJkLRtWG2Np9kFUy%2Fk5fw%3D%3D",
  "": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Grambling%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Snl2M%2BpmiDxYWyq2L4IXgUmRT3nUjUNxrpvJEz3v74u6njO%2FpGF1iLD4j%2BozEaguKLmYxuVgW7SIT1%2Bfe4zBA81gg4JAO%2BhZzWJgD6zADecD5pWvoFrkV1JQ%2FrnSHHApyam22fFrEZuEeDiL18eeVxmIHkqKWowzxtUSOAUhQyqCXdsXXkhxl0fTP%2Fc%2FAyj0qQu49x%2BkGK%2FSXjsRtCamLyxD1JPQ1vrPT1u%2FUv9As%2BQuiWKWcmpzS4MGZAhM8x8uS5RB%2B8DVKsgmNm0LX%2F9w3%2B5Bu9x9ou6lNdoAZcg9aH9OqT42utc0wmQ%2BmBIUp%2B3lKOv29cgBhkZb3HsLcgzUew%3D%3D",
  "Massachusetts Institute of Technology": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Massachusetts%20Institute%20of%20Technology?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=XrpeSnbA3ken5UoZw4SvrU%2B89AXVivwos9%2BRY%2Bh7iNf%2BRrGYppcRt8Znc7KAjQTIZ7WtPMVPbj%2B5SLcv0850XcDLmNfcJ3HDVyF0AyMDuyXwJREcfPxDef%2BANNZiqn0JpyiVQToY9bqtNpYJNSpqMJj4UiDA%2FY2o5KlNSMrNLz%2FlA9sWGgVcNhvMH9KMJMONNb2NKoXCmIXKsmV2HVUJUBqsWPeSfKC%2F%2BKfid2Hzdd96n%2FrL2qBAHMfEN%2Bj8fmCoIeFFCsNCL4zc1nYQ1w0yOnwNbC4wwsjoNk83iCDIM6kYIgquvssbrqqFsIpU670HWfhslAvd5f19G8wfBinYIw%3D%3D",
  "The University of Texas at Austin": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20University%20of%20Texas%20at%20Austin?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=cpKaebQVD6Qi4X%2FzW7n9h1zHh4R7MMPmz7V0e6el7kUh2%2BaTbCdAcWG%2FLK7YWo0uMwN1tmbEXmdSpiKC44z%2BXLhWIK7T9T%2FdA7FXMELHCmDj8ZRRaPrW6yYi%2Bv7ixIpDzvpFR6kTwCA7A0fSecUsT9DGVrCohyWO371koGyFjFIjhdmHqg1xvfLyzK8WJyGOPWKg7j1MU%2F6EmNsUAYjWs2vCYfxAOe57XjYdOS6Wgqer9SverMPUvs3SbCtjVGKAqjv2BR1tY6Dby78Wf7pgM8mDi5iTJJsWJJ%2FihZHy0WPjDgUilcrH8bGoY8rSR0luIfG5EcN25G8s1E7na3u13g%3D%3D",
  "The University of Alabama": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20University%20of%20Alabama?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=RX9BBKvyEgB%2F%2BT3%2FM5qpNyJSLqROdF6x15trmTOVQ4XVDXXxC1hUyOt6uu7vt2vuj5FsEwiSjE7tcOgA%2FikCDclSdRpw9F6nx8qtrlO3qH262lw7wSCbZvs55IQ9ReJeGk6tAf7vb2G1QmWAYhILDcuJGeXUewbeE6KWo1i5GVja0QmNKwxNOQskkjvxNl%2F1fGnmMS%2FRz5MnwPgCblcSxOoGr6N9nyXzgUjsu%2BeAQu0CM669%2FOu%2FMOpVxHzxTKDTghLMso4VUMWWBaceLvzashz4RLU8XwDCSx32okPJ3HocUOSuK6U%2FJONgdSXchPdc%2BYmHZfya8hvWFg5uz5w5Kg%3D%3D",
  "Indiana University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Indiana%20University%20-%20Kelley%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=v98AYu9Fr9bsw%2FLAd1a41mGMsYMcSlYJ7799Q6JF%2FRlQbcxhMGgRmViccYkPqE%2Ff5dF7Zd1ptq05BN64G1XoSvwM4VSQu2IuaUaEZvkcecUpPoyTTTmksPKBMn%2FHZ%2BrUhfyTDlBVxUDcjZS8lC%2FSVsWwdmqQn84cuDrXzDI7UoNHOUqKhAzjaGN10Zz%2BGnuxnBtMB66zRp0%2FL1gQWe541NSj3THn1TADk6xWOfsO0CQfWjDzuGkeN8Yx4A87i7Ba7MIQbefu%2Fgs%2FDK27tX4wxc4VkndPzza3ZcBTc4hEDO4rnhU%2BSwVm88oOqa9quF1O%2F3c7fGnC4w9AFPHk0mpCeQ%3D%3D",
  "Miami University of Ohio": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Miami%20University%20Farmer%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=qVmhLBwmEG5wMCm6xoroH2AvdaPVA7agzE6MZ3gVuKHrZTCWBiOv4xw0vfZBuz3V4O2jSXUxe%2BzbtwjULwDe%2BwhuFm11OgYqeZvBUZM59nLb7Tf73xjihXymhX9CaWe6simDU7gbePp3CHg9f3gTwaaKpjpSc0XXMz8cGhV7nW9RYoKeWASBNJl47F9KMIAcAkCdpha26KQFpSOwnPxfj82Dp5qh9apt64e0sZhEvdgoMdVS1%2BAPxEN9HtQMfFAIurgzMZ4mSAxMX1XMuq2P%2FNoLhm2UKCefR%2FU565cmoQAqO%2Fh7vhooscd4RHDS8YmtukytZdp3Tp6TPcBBqeF9hQ%3D%3D",
  "University of Tennessee, Knoxville": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Tennessee%2C%20Knoxville?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Rpg2FZ16iYZ8Z%2BPlR7S5dNUXaqYvmUmOZgjBIkiTETjeBDzd1zCweFH8xak2hNPupX%2BgeYLsJ2y8YfPeZN%2Bn18EzdZ5XXFoaroWuZyZ7Wi9W9lyqaWBu1BiukapIaNs4BVguOqvH6CJLVben%2BlvZTMHjHKoFIY2sp%2FpSwg%2FFLAH%2BN07Lzd0E81jv9y4mBANNrmx1%2FPbVGdQt%2FFpctze7E2QNI82rAQyg5xv2YITy1hctGWH0hs%2B8ALwkfbbSewyULSMptUoSsj621f%2FgBksq3kc6mqDrwI6DAmUTHBYinIfCcGioeutxOMvDIOgOfXTNCNryfpc181dP5cTqWMxiQA%3D%3D",
  "York University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Schulich%20School%20of%20Business%20-%20York%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=bZE%2B3pbr3LMn7huJJWa%2FXZbm4kFgcIAf9q8FTbwjv18x7Kx2lsCAvOexHOSKUl%2BG10PqtSqnvs36MBbrLOHyiFNak5Q19XvI7sYPVzel5nTNMaKSCo8WpIzFOlSKYmqUcoGFGxYPwPTxLskfXHajh4t4lLx0QVfxehqDrijBVrVwW8KB%2B414z%2Bg6xzlIoqmbHl9tWH033QH%2FD%2B1hh2kOnknfsAYwmAuyIOCvGo9YQQN4qMbXSWN0RMKvdNKLRSpQ0MblSXIgHOB%2FofA9MJSeGJXoHSClvuVOVAtLyp9uXb4QVR0oZ8z%2FmKXrua4ga31Ez5tiUP1ljmamnh%2FArcbJQg%3D%3D",
  "Bentley College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Bentley%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ZiYLyj7sdlTtUsnfB0D1z6boozVLbaY4vnxrZMP3vX4BUOc409u1%2FJUTtwxdpmAOpyRVsNbY6bpES7veQIyI6hA7JG4NE22gNMiCmm0SKGSuRifKfmJcQvs1V9PLSIdJqb1NrSF5%2BRATP09Y3xwWYiSM%2BxWtN26Mkcj3Wz8pHtrJpH%2F53xlFzerlfJbfSA%2BBTK5Gb9P7fYlrihg1wh0Rk5cUJKq5T4Mjdd%2BESNaizW%2B1xOmUPVGW4NmuZGkjcxAQPT2RMTiAHXdVjwaxiAlGLrOWx%2FUnX0Brv2uXntgmbNGnoayb0MJaEN%2BzNG5QU9zAV1T6q0ZjRCRdDwqxyqtIAA%3D%3D",
  "Roger Williams University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Roger%20Williams%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=iUN%2FJA2iS3OEywLEcjfo%2FHGEh6MIiWGJxRk5sQHQXpSqNZ%2B8uTCNhHQhpem7nEx1yg3%2FxRMPZ5bDTQLoDSN0CaozH7ckmxtfSrvXval4aZFC4o%2FWQfguFSjhW%2BjoQL6QNAig8l6ABznPYwKpHPf%2FfsIS10ZVxuT4noOb1TY0HylMHDK9RJqRFVOnjak9mFqTkeObvvdabZ8CPta8I%2B%2BfJmWA6lG8LTd4jE6Thxb9yyMD8hE0YZV3jt9Jp%2BC6WKn5NPgSHVIlKYIz18L5zVhHjZER%2FknVIUs0cMYUxbvHdfQEXOSVaqFpRKdxgW5jjYchzeSWGp9FrbxopTzgV4EUfg%3D%3D",
  "Texas State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/McCoy%20College%20of%20Business%20at%20Texas%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=s%2F%2F3GKKoL8xzWuIRzyQXGyN0djjcYJnypDAuiBUPOD%2FnNrC9CW6duLOgQ%2Bd22o6Y4ZQh5uTeIC1Z5en9u4n%2F64LIxImoUZ1E%2FV0sQfiOEmtyOId%2Fva6ShtPLN%2BiXdL18iCMJIo9f01sifWwnHa4H0z6CNx5Y7jpK3KKbySGdeROYIwb1eGAxz%2FXUfq7%2BGSkJBuQpofbg%2B%2BpOlJqqLKaVJoFqLvR4qDSIZatigm129EHbwpiLCNYtFFs9R5A6KeRs7yVwRK9GkA6YIO0f4AUWOoy0KxVdS2PYzCStzFOw0FXpFpELSj3kQtMvo83Xc%2FZd1%2Bg5C%2B%2FsgD%2BpR7DycNwB%2BA%3D%3D",
  "State University of New York at Stony Brook": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Stony%20Brook%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=OWHSy9u9v4q%2Fs4WMaEIiJ%2B2ZQDBnDr3CMlAZXcTH03Xz9JGcaM%2BwTpUskvxYkUtfduGg2RczMYlv4KfcF%2B95WP4pu%2BLRC4rptNbho34%2B%2FDWzf80yeszzGyU%2FXb0vVipg4ISEnyKJBa20HnHnx5fqDNEl9qrBs7Y90%2FKC8fPBZlA1sWCq8D90%2FH%2FY9TKo9uovY2JFwN9euQ1erNA0Vxj8mLYBX8oBPVngLGW5090hPuK5mqdlxtY8zFATgVdlF5xucriyOYHt4rH4voOrFTEVjC4wGxvVGpJ1DZktba%2Bc5E%2BU%2F6BaCnsLbqt7PyKaKlsCu8WIrM1MRb9SU2SKvBhmSg%3D%3D",
  "Northeastern University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Northeastern%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=HR7lls6jO58hm2d%2B7hzgfRpvWEIedwjjM9YqyOfGvRVn%2FMa5ntswkc1wJRzl4P2RvC%2F9Rr7vd7rN6VP1l8mKeLzj3i2hd%2BrdLeSVnGSJR47QlV4OfLHvL7X3dj%2BRnDvGB4Z6e0H2bpOFMJF43Z5nr%2BvqxEcuGqINUYngknX9xuXrMVRehCA6uEqzx5Z5QzMNi1kGlMhQHbvVhrwYGHOREPYkwBso9d9ikNr1qeOuqSKU0piR0sNFPgL9HRoPciGWpA464Qf3PyenzSFugg91pQoGxxBIEQLEr2ND9ru%2F0cO2zDqrE4U1I%2Fw3O%2BIkecMz402dAv4Py7ywo9NSkzKIQg%3D%3D",
  "Emory University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Emory%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ZRAYd9tzi46WVMnVPwlF49O%2B5LcQHsPSI7qq%2FIck6Hc0F2qE1gZPIsd4nxfm1oB0azkddA%2BoPhsSiRdyrrRapBvUy0EROYU0m8%2BHpdhaCzkM7pUu%2Bdr0ZRYYXIYIjncYzLAXgyzMVB4Vj5N%2BLebAiJ%2F%2FqNHQ%2Fb3YyuNx0uYbAUhzkbeuVqFJvbToqIIEd%2BiYlhjsYRw4IrlCoKxGwdpTlyh0vcd5rJNba8sGTkCa6SXY2P7DnuZMqtPXmHH23QGE7IrM4Nqg6%2BEU3afuMHc%2Be3sQdK2qj3V7dJLgw4RGErpERcT6SrEWm4waE03AWFJxfmxey1pLdlkMde7Ks0kYbQ%3D%3D",
  "University of Waterloo": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Waterloo?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Fipey17RD%2BamGMK44gDSkyfq93pLxKbRTOwo0WnGQevQj7axpJoDeUMMPqCKAFUaFT2YobbQqG%2FlBHUG8KXLR797YR4k1%2BmDbi%2FxiYvaZI01tEBsh2DkYFt78qYeZ9qrYYqYWu60eRXWuh2BRPwXDnT2mEFdEV0XhHTwVcxBjnnMg9gMkAqyZWM8kA%2FazKHIvf5Sfax7z1UEBmwdO7dWMqTfKySwW6UmI8YgRKTt%2FVbQcSF1d9rAV5RLTqNpMu9u3V8u8m9CfougWqWGWxOtikUin5mO7N3BAuvJK7B4TGTUzdsztLORL2Fwku7ktjuZNJZYqH%2BA%2BKvWwHXKIMtZvg%3D%3D",
  "Duke University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Duke%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=qDp%2Fshq%2BP6KAhGQ61WpZ8s0HIj2bPl5vg2k%2FG93o092aZ8uv0SXBH6bquax0Wc2XhgxpUt6QzCVxzAkjA4JeYQNOIvZOjsWg3NeA41kCv5hyE6hZ7FtlBzlGtoK968l2p%2FKOs4tQcW4xEcmtZRz81uwX8oB2KqBAR2oB6MrY%2FRO4vXok6uenKGqL5Cufktv5M3bpSKZEHI49g5yNZb3shI1SCFy4%2BqQhSgKUDy13TBthJ7MIZY1olNBJXWuuUcaO5aJq4fSttZqTNYFEQYXXEctz7GZbAWk7MtBcdmWCHtl6EMqMUcrB0h1fxmXl9AvoVO5DlsCcSdr1qv%2BaQ1VlBg%3D%3D",
  "University of Southern California": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Southern%20California?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ZvrLEBauqnPIJjDYMRe9BwDwPnx7EdWGvzs5w829kJXkct07aICkncMLyOxAQnSxaV9nGqDDpZzcX1Tbry%2FTdj0JoRk%2Fc4ePPOirYQIjGctNI6V%2F0RjN1uMOMjm4327iCxYSjkdtwL7otmyWYleTj%2F08%2BsSsSsyGDJ5OBKIToPEMEg1uTyWtvMjcT7qtZ82j0ZT7Oir0vOOmL1M1K%2F3aK%2BlsjvpphKKDjmJNucdB%2FBm%2FJtbApEJB%2BgbxMfZ0JMH61d38NaqV0D%2FtLhAhwSQTg2Y17EWoRrruJzN0Nm1otFOz%2F1bLKBEY%2FPTqcswkhUJPWrNYLeVqeicmRsbKvcBVlQ%3D%3D",
  "Brown University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Brown%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=JFwSzdc5%2BPfJlxtUmSNZhFbApOK57qXGV3ycMTHoEXQ8WSbrHYfMdMl81HsLTHVBmd4TJzbUMdurdqkNmtptmKFdoi8GapfZfxzdGHmY29iPlJy3SDd7id60mEkg01jMyxbGfw5mBduKLIYK3yiHwJU8zTWhXgqLw6VrcGaxujmXYKL9xTja7xoA9KUcx3dg%2BxUNOzzwHOTHBL1PcBP5y%2FAWgg2B%2BYKEHPwIvXGFTLdZY%2FcY9SHGIpta77bLBsDeNLGXqd6eZhtbqPRMYkDDpNDtrVh%2BZeaDBMT5ZotPrDTLNmnPasOD1TDVFwLjgg9UJGnj6TqYVFcgZszxKlG6Ew%3D%3D",
  "Georgetown University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Georgetown%20University%20McDonough%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Rbw%2Fz%2BiE0AYcEp9rUNDQTixk2pXAjQIeaIOJdj1V9o2CN7xXsAvBeEMudsyaI1us5npqz7I53NpfaACUjxiBJmQqPZD%2FZBtmblo3zdIxkmOm5vPtUA6vPBTi8Rz7NMqlSHlP2OaXEisA9pEFGwYEZIRgUmY8Gcf2V7M1n8UscavPbw3a78vZCbbmXBUwxoZ6noTqVvlMqRv%2F%2BQmA6rmtb8fQLOrZaUd%2BynrO87C9%2BwI0xtZ8W0MPZY%2FhPRpQGNy7PuY7DPX7r1WfF%2BtEQXqdScKk6myuOAvtRRGkEe15jIC5zkZmklJjbAyGwumesH4o0cWAGZUEk5a0gydDzvw7%2Bg%3D%3D",
  "Carnegie Mellon University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Carnegie%20Mellon%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=oG9n8I9F0%2F0GTtZ0cGvCSQYE9tAuN50QcZWnGqlnHUy5cfMD03dnZ2N1CYIbIG6da5orCIPfjDQpN6Job3SGtXGl%2FPLZ2s6daVASOLQDiQKucY5%2FwI%2FbhYVrf1Ij6ndW2lyerMPmFC28bV97XWW%2B7JThALvL1V7PRyvX9w9zoQ%2Fml1h0ypVBn18BpvUQbdD0KMml4ypQ395ilQ6WBUXEjZfVcFIYv9RKG%2BUqXPCWDzrXZL%2BO6rfSMUv%2F1fekhNQcDr2LI4uWB8XP%2BoR6FljuXTfJnAsIfVg0BXTD%2B87sEEL8MXhFh0iV3ctgeixPZOom7jQrhVp4dt0lP0JJ9Xjttg%3D%3D",
  "Villanova University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Villanova%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=rfzl81n1jpGSXxyoDFH64T3fRsgBC2Tw4anx8yIg9aN%2FbaHWwNjAi%2F2n%2BhcMGCb8lHosMPJJvwm4PIFAFud96h%2Fatck%2F44Qh%2BuWS2J1X%2Bws%2Bye8cw3JOYuEGZXL5ir4rEHCOGDcYU3K%2ByRBgHu3QymqiuZoDIlJJm3fobb0fZ3g%2FSt%2FVRN%2FKcsdhB6eHBIxriFS%2BzLK8V%2FeRkVPQ4%2F%2F6E%2Bb4tqVcTxYPTrBZg2NVAyoU44iykWZNOpZF7mnnf0pGs9VIqkMRT1DfHTGEgKoQyYYnK6%2FjyP1mKW2fXVGX37er%2Bfg4Ysm%2FTkU%2BWAODAPaFXA5BK6HFJDfU4mYq%2F%2BBewg%3D%3D",
  "Boston University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Boston%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=qNpkQX8WKqAFxzx9v6TWk6gtdH7WrG1NH6yK1PQwcGGHsd%2BpfVxpEGtrDvyHTVULrCSDRLiZDp3Isf%2FExkBLZx96v0V8sLK5XzC8mYTMRXaXkFpQcnmZp25gNEfAA1oXD4uAC4uXouSoBDC0gTh5NV%2FT1L4gBIDH91Ll%2FBaxAb9Zwmehic61jXlQC6PSMhcHR4%2FvRYS%2BB%2Fklrh%2FVYLTGSqJr4RmfHWhTEolWbX8kQFaYZT9JTvsuxGss7odDLS4Oq%2BXvEbN9IVS18RLoafZjX81H2WhVfRz34PJecLrVeFg87Hz40QzklXc7p2%2BQOWBx%2FFiNIX4i8eaDGBXHQwZqKQ%3D%3D",
  "Southern College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Southern%20Adventist%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=U06FjV%2F%2FZpXvBclPgfdzFspRWNSR86CH44ajcgjJF5fLHTb7rBULtgFRkgMT6rNjMor8VOxMfxCluYbYxIRrJiZIJv0Ll%2BE0U9%2Bqwr4cQvHMziAwKDhn8O2Cz9ohvJpQ6sg6z%2FRBROpCp3EnBadEXHasXKPUxYxklBSvJvSBkh1db4UI7ZLUfjjcR4MXZBAbFt8aS9uSDv1vNkJnoM6PcmopUAFr%2FgO55IsxglzOWslfajzZ%2FOfELTk5L7eoiYdttGyNxwMwcQZfCL8qo35LfBioQJg3YVwdmY2o6hx3wtSpvtXz7qFHTFhlxDK%2F1RC50WRtE10zHl7NAmBdLodo%2Bg%3D%3D",
  "City University of New York": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Baruch%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=DjmytwIEzNckfS15HRpzMyNdIyFaNV9p7Gw9a7bLcPyz7e6uBEiHA1gHfQur8lrD1FJvYWXyz5ikIIPobtww0vcMFsDfvbmz8fPc1l5HUWjvNZdH1THs8RLFWMr93XkqicdolJVNsRzLLqel6jmJmz0H3oq4oRQymiM6Wbxnd5oSzfEw0BedjDPUnmk7S7a3X%2F5xa8fr8yo5xos3p%2FdgSpvRlbhuS%2FUnUXBe4B7wz3%2FaQwAiXmEqLLOnglxIP7els4Ng3lX7WAOtMmJ8X44XPxL2LS1alinn4%2Fxj843UPkv%2BcTUup7C%2B%2Bb75W8cnxIHyjZIE166fu84W4N8iEQJG9A%3D%3D",
  "Harvard University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Harvard%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=WwmI8apetlMRH87HNk9fn9JU6DWBcEme5a3Ea7FMA9wMI85uEJ5Heqh%2Fz4QFcfIYb0cNyuh45ddY2nmAY3PxfWsm6pKCEZB%2BZkUfX074s6ygcoCfBIDNQ5NqMnIQ6QKOkRYdO%2F%2FbHqL4voO2tLRnlqqPFNNyvlyh7VaDdKrS0mrRWU47ps1QbyQ3u3fpNjcNI0tCDx7VB%2F5ZimUTLIqcgCP6YEqOtwjoW4VqWaC6ti6EjMdpJYilmY3NkxUVjC%2FBHPv6ldwEuiKIeb%2BSV5nr50XveykuqCrJWFB9pDua740ohb6n6xAAO3W1IIa8dQNcDJHaZC7lcUeVffopQ2coWQ%3D%3D",
  "Loyola University Chicago": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Loyola%20University%20Chicago?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=r5srTfZDiow6HG2LSM%2Bsi1BukuX2LyXSDiI7gQiUecC%2FVKJzijxd4unSI7As5q3JpBNkrA%2B9cj%2BBJnHJrEV5YKSt%2F7DMAdt6BKdyu8MLb9P%2F2rovimiB9A3YAKRlmW%2F1CUW%2BWZiv7JE%2B8TkKf37i%2B3kc%2B9e2%2FE7npobV5D%2FPmFSUfzkITHCmpteiHel1dop80IOOlUkHW9OySD%2FnXMUdaW8aCalRdpvRmIPtJGSA3R2%2FR3igN3jfskNCiLlvgdRBg8SuV1GO0CjyEKocoUjOdWwEDrGai%2FCO1GzHzFruXnW%2FhEWcB7vJa6MFe4cJ2D%2FJ%2BtTyEXUkJz3wInzHzthWTg%3D%3D",
  "Babson College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Babson%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=nzyOknQVJhKXfJ1D%2FMu%2Fh18WCsF9xOezdAK%2FUIG3QWwGDNr8X3zI8jN3k6KU8cx7SfWJKuB7Laf9HZcf%2FYHwBLMEw%2BvPZyf53QfmVc3Xx8elBuj9x0Cy%2FT%2FxRKdxAXLPqXxHUDitn78IQlcoQsY2cto18gRarrQrGm%2BpbuBO9Y8YPYcCNbT0qZQDe5LgXINMKLOJHvGQlhGSUrcU9YbK%2B8j6g8XOTqmuoYPN8ZADy4bfEVK2b84Qn8Nhg4rSH57M5jWJyeo0Jw6HJwm%2B7UfbLxit7fNzNwW3SBrQAEAAZVrTzVPw7f5uf9r47wHw1YONJ%2BRzTrZmUGtOK4J6iGa9RQ%3D%3D",
  "Marquette University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Marquette%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Knv0s%2BMmmLWOvyjRAXp9nOqojRv7SaQ9lBNzuQggGOiLLj2cIXR5ev28brN5ieQPnnf0eGNqvQmkrWOhLOsIovMDFZUfNNcf8PF8vSPteq5%2FuO4r3UP2D06y5ybJyObKOCxTIC67JveTV4xFsO2t3herXCPKGqiZkbsoJVmk5h5Dv4CAie%2BcW2wGX9DVoNWkc7Ki5wJt3JrsEikHQ1%2F0mGNe%2FAPEEMatuoFtKGtwgwwu42XprI4rIYtcFu7rSNbIz5ZWNOCK4kZM1RjQqdajOQ6VelZTQcCzNgO%2FF4F3RZ3ymkcIpT6uA8OQPYBe5u%2FLAO5BHb3TkGRfxc1Az909WQ%3D%3D",
  "Rice University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Rice%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=LINPwoC43G%2FNTcB2LRmABd53hiZf52Qu1B%2Fg3tHOmjcGUW1gqWdM37AIgdiiaMfGgpxsd7ov%2FhnL7GRCJZCEFR8dBCUZWpB8v9aTUc8mAerKyAuf2txI3RmG5PDBePLYZXiA0sX84I5bQeTJegnNmqOEk%2BgRXsb40MJlg9c5sGtBZaf39Pjf1O%2BKgPGqRpR2VdjL%2BqTWCGsMBAGvX4A81VByP34dQ%2BliZeNgTrOpuHW7pMXMeCp2qUjWXtEVLXMXSMdTRxNz1dBdtd8vOGdIHTxIljvw0uJCTw1zW8VAnEv1QGKycvnUM7I%2FYls9mkNM9uoZyoIEGT%2FPrYtjJRmH7Q%3D%3D",
  "Queen's University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Queen%27s%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=vTMwcPa9frlU2dNNiDr4pvCUtMani3WyrlT6eQRPqLjk8JLu%2F90TBaWawDHFdAaHGnQJFS1I%2BK6vGK%2Fk9UbrKhQltYDOVeSaHmUEl5U0PEGfpx8xQKdbOI0KEDfLTmY5z15cKl%2B%2Frq9heyD%2B8R0cK7OsboiOCIWoGGpOtPpewa9n7bYwpx2Gv1EIY4AHtjnRAsoQnFcLkBM0AgoXfmiwcW%2FHyiKZ6eV4ELa%2BTYNd7HyMxdetG%2Frd8r6dr5hlDUHeWffA3DY3fBeMhIGaJJP1HjnrBYv9CfIokWsClEWrZet8TjvUuc5Cr%2FiE1YsmQqs8iN5%2F%2BE7C522s8y9T14AvFA%3D%3D",
  "University of Washington": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/UW%20Foster%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=iZIsfTmcY0h7D3%2BH6vrpwestjEGHido9QmQ5kUxEs8tS842sNYjAkOc8MvqH3oB0h47gxbYmLRwnxW9IBgfc7pOgaujEZsao6qJWtMF%2Bojm7TVaZUQH8huyxhLy%2BnVo1VwYGhpTigji0tos0cheImUtLKalfyRkYwMbEiZSXB6%2BqhbNLsCN9VWSnICwJIEFWT3z5aE3dc%2BddmYU8zQbX0jJEYgA7ev30JMZZRBcjQRxJeLHlJc3tf2eXOszSAGtheegDmUlFu6HUdoaFhMrfnNY4uZiZSH0y2DgRFGjblhH07jEjwluKBh1AA0Soi%2Fz8J14DoJWEyhIWf31RrUc2qQ%3D%3D",
  "Universiti Malaysia Sarawak": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Universiti%20Malaysia%20Sarawak?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=S%2BzlA5moAUYt%2BHJ9HXizXOAwuIz5D9mb5%2B7qv1jg2fr62HsElaC1yZkEwInE8iLSUIHRWnceP%2FT1H%2BcBlsMzbvVUNKlE%2BDxd7PHR2IwVQS2a6rVXiEzjq%2B6aDlhi3PangfIHVrCj0OKKBdQbDUHaO9686Y91l29Dv5nHAMapXgBnIg9gRmI70caZ7Et42wpGn5oWYOCGa%2FdPrXp%2BKHNQpnnHnAH4EJ%2FdWGBG9H027xIP9odZkIBeCXexwsWM3Nb7gXlFnaSrwxz8sBX7Zh1r1yg4BFJbbPoXnSga%2BVG24djXSMa1Ibyw4Ba7CpHD8ftcNzTOCHx01tX2hn6hVHib6Q%3D%3D",
  "University of Rochester": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Rochester?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=FauGP2BdzxaLgW7WqmrqZ0nb2ajsecAD6%2FDqP%2Bdcg4%2F38mgQ%2BTFgxZ0OOgIZpCp3nyMhByAItRSfo2cKDYTcgbAZe8KzdM5vL3uWTx37uOx4HYg2xzA5gNfiM0P0OtOopnQnvsSBthSYRkcOttd6vL1fzFWeAXtpf5H6NSnQFjh%2BW619clut0BoGxEixKnH4J16GNXHQkIa9jc%2Ft3CrR4bjk9USmCgTQpAtV56ySQCtvN3ZHwQYY95VfJgL7IG77CmbhSF07PAfPCPwOydIXS%2FMQdAcsuJn9OvYEulps9XAFCgTWHxLTLAYDVlOYzNqdsigqy6Fxufjmb6UqwcqIIA%3D%3D",
  "Sacred Heart University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Sacred%20Heart%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=E3CLevt0eChvu6mOpRSFRTjFqpix6DWLrDwxB1G7bEpt1a4PIL1NoDsQdncp%2B1qTAs6bJndvMpCvc2LM3UsX0BH539TUS8Bh8yhaGGnMe%2B92rlJclydLxDsicuHhY91LWH2QSISVnTQ3wN6kmKLUGkmcqGAEUXPELvZ6kvz8Yc3FS7v0WiLsOk%2BKehk40wUBIcAiGYfgLHDP%2BgA%2B7j2Pq0uG%2BGGKOIUNmx4hS6ahheSaBlNrDccY5eAjDOpJQMDNHhFU4B8MOnHXi%2F3%2B9cceCE75ybWTXsMCV%2BUQjQlgjxDSWa9kNdoJuORNDzCr7bi%2BANDm4Ul0M%2FwB053ZnHIXPw%3D%3D",
  "University of Western Ontario": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Western%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=NRr5dccK08udeP6kqtgnqH894FdLB9iKTZTQW8olGq8TOC0019hZwoCeMsktRjgNEWaF15rFyEg%2BqXJ%2F2LCyCz75D%2Bez1GJhoMVha%2F4GzN94Bv5wFS33D4gp%2BzbsZq9XzbwNf5waqnetmXOcCndDR048Lmn89zy3SkZcX4z4Tx0ZbGAYKLyt8WcRVDh6PBra96WCbmP73kSAnvj1odtDSP99%2B623vLkX8U93dwEt5xJt%2Fi5ebVIoxCLLMTPwf60B3IRV9dCGsmeoAvIr5YLLjxEfDGooYpw3KINEdUzqnQWVoQF2L1HwNDz1oQpvCXQhu4OpA0snkKT6C04C2lUyWA%3D%3D",
  "University of Dublin, Trinity College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Trinity%20College%20Dublin?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=oZvTVrX6Y%2BOgXiyHhwcgUoWnhm4FFcJDuQSLW8SWbLf4eyILSU7SSGjExOz8hbrKiXFmwOeGD6OzMihLy1imimep76%2FUNOMf2vCKoEE%2FBado%2FkIgdXwItwGv6XF8xWDXwLb9jjbX4I8Tc9S65b5cVTservxtjzup3rWGOg1b6J1WiB9qrOi7MXnIgmg%2BZ7EweELgR5JzuTdfBsnZI51CJ79gJ1WH5q1GWONTfaHVhPaGXakbAGB7tn3LKOxonhBGsaU%2BT9UFvN%2FA2TZcJSzAw6zqZ9Glx5A7ewmr28ZMZmnFJjmvRoRpuABWJNDyjNVvdvUZdP4Z9TQ7EFmrc72SbA%3D%3D",
  "University of Oregon": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Oregon%20-%20Charles%20H.%20Lundquist%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=J9QYM4hEZjydTs3SfS5qkOX7xhqjqpoAyvnD%2B1Xy5FtiFmIHU2jo626ohb9ra9O7VuINqp2NYkgZq5XwqmXE5maV6CMHnaHZSv1WmDbIDssK0w3q4Kav3n68Kc%2FlQnOCsDfhXUAr05Wgnmr3KuO1z5Na66G99v9%2Fb88pmfuCYeQ5HxRLKu0yRNi4vX1lI2QU3fDkz9aBhDNHKhdwOlGYRnSTwKIrXdzqTQ2yyZ8AIxQJ%2BKc5zx7cSjj7DW9YTVxox%2FMDc01rSpAy%2BzXso%2BGQ8%2Fj5QJLwQcUjcPpa%2FBo%2BpFU4KHyWQWRQjZ5cJUx8v0Q%2Fs9JR5NdXK1WZeBH5cZV9dQ%3D%3D",
  "Bucknell University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Bucknell%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=In8fKMtr%2BODWjNp4sVhLbmJxem5Zgd9PDby8i%2F%2BjNmSJ%2F5oBNUG43U6Ol%2FE%2FMZl0YZq5tBNSKep2cuiHY%2FTamNvPgcHro13hjokv0yM8R7ysrKidl%2F25BngE3gzGcXkzrNqhlPKn055LU0V7rb8IPXBVIAYXrliyjFOctGgnNy07vdJXbQwZRh4fopERrT19kdG8z5bxqLUD8IXYqLy4J0HeqdIa%2B%2BoHAfiXw%2BIx%2Bv8n1%2BOkht3kbOAsSpW5ALsEvpNC1%2FlwGctAdNRfH2edE7Awz8N4%2F7pTX05uQFIYgSLNkr2utMIqYRR7HF3XWBzBNZ81Ij57YNf075ncTp9Pvg%3D%3D",
  "Princeton University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Princeton%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=HrJdXBbKyq9CK3kVusxPJFucQhG%2F3n0nUMc9CSxANMJwbXIP5HqU0ODQQwXey9TmcjgymBlKi228mGVviTgjm8PMkax4uhcLSl25DNXaMc50DZQGK4PKSXmQ0AwgDEaYHUUASe0bUfbTw%2BeOx42gkzf0cvnbKgghZq8%2BRZCm%2Fb2jJTdzUpqZv3rc3yq%2FJnRY6YPiWbB1KfeTf4CJr1kQMUlpRmZbBYSOR5uNt%2BLNgaFx8JK7sDetunkGD2KeJfJaoSw0O1y3%2BLhRrTd5%2Bi%2Bz2tQ5%2B56GMfDOJrGStoyRTIOK29k%2Bk814VTlwqtl5QyegSSWjsAY4T3w29kPa%2FSrQ%2BQ%3D%3D",
  "College of William and Mary": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/William%20%26%20Mary%20%E2%80%93%20Raymond%20A.%20Mason%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=IsfTfcXcuuu%2BPgSK8d92n9kpiGQO1xm3iJWqUS4yqifFfMhkKNC2EDPfkFdnEAiOlgopLCOkbRZqCFDqSw6EIq9Lt1pRXySZ7uJ%2FUNOFHBiXAz9W8WtrCAKDUifTqe6YUNAQ9XoFL%2FigziL5C9R6bNVMjtz%2FNbfkafeDunWyhIuNsTKoB5mxbLjLLd7QCwhOOhNFxS65neNmzcPnhHh0kmQvzauMV5xRJjUJZqkFjWo3iI8g%2BN3EB%2FOYuSjWPV9xzPR1znUs%2B2i7b%2FQQTuwZMvn688speH2vHPO7oqgYipsUBxR5CCaghkk0A9SVWSHVx2MD0SD9N%2ByzvEtbeHwYlw%3D%3D",
  "University of Virginia, Charlottesville": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Virginia?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=OZZXtmw91xEIhMuqSsy9XDgJmlRrKMLx9OoY25kYWXnhl3TasYT4dHX9z90zILys2bFseV9WCLqB3YA7wtwpOcR6UaIsr5dGk2PybT2lbRdIJ%2FZDh3OcqdoSpn7L8n%2Bpp4hQcXDUfoXsNi4ZKsEIxnJoT0hImHRlW5AgFzmXdjzLrn%2FeboNb4VdQRkjYAOowf5eR67FIUelAwPazmfCVMHcRQkxaOaymIKIKbPpCutELwxBEtQckTmJSaP0uEi03iO6aTGKGA%2BZX8frTXyA9FFITFCbSqwOOf3WaUPYMPthRIgYMV7rz%2FTsbOZN04PRH0s75XFeA4cVxC3ABg5mdKg%3D%3D",
  "Temple University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Avenues%20The%20World%20School?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=YnlcStsDYtnuC6MWBKQh0wrAByd8YRjdcRalhQjYhNQQJ%2F6wKKEI00xxY9qdjQDMCwJGDq5f0hU%2Bb%2FpweJF9dR%2B8LBqvvrZAAaleOcnyHPv%2F0751BHkrVB0jy81GLHJeM8iuoEKjt0pNrXNtfSHGE%2B%2FC1ssrfK81a9k2vuB6GZc4GG%2BWeustQGNQcGTLS91SzMwzLazDbVQL8ZIxDRxbr3N27CA%2BpoATvxg2O2dCo%2BT%2F54pl4dpYeog3AUESmW89kPMsuBEkELdat5Le7%2Ftcr7hUlAA7zBQS%2FgeDg332DUDZdr6gVfXhxMe0GTE8YaeyNG8JX4JlqL0XhPzSL3idWg%3D%3D",
  "University of Tampa": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Tampa?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=GKpsy0VQXZ1DlvqIdOoIZqGnPdr93x%2FC5KAAagc4X%2B6HfMdvArpLdHjsU6YHrkzWGf7QkC0YSxfRxs9vbHT7TzDlAE9FMHxwXGh84DUdDN8slbB%2BfNvmNxVNakNR%2Fxhhr1%2BWVrQUYbUOWyQIxAs7N20DCHu0CwgL8IKaPtxKLUWQW3rEWa1lqxONdSXMH%2FeaCXnhM%2Fg0y9yRThGdYPaTMuE%2FM8CGXEYhdpXzuMcBsXGbcchQMRGjEXE1kPRkzGA%2F%2BaFBxjiQfo7mO0irnJvRRZJ2nQtU3W0AMhzrkGcDbOIA0TMWczz0dzW9omWdRgm%2FNJQYqjJ6z4KZ%2BP8dylCJoQ%3D%3D",
  "Ohio University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Ohio%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=fbM%2F3KMeYfciIt3HEKkmffMoUeOLLUYntIveeZIZIist3ULLQU%2BN2KWBaIQNkYibXhVK%2FTaa821CvmNkp0TajsHS8K9YXhaIijldxwmblA1vPe9PdtYzjdCAC7v%2Br6tg6Jtdw1D9jl9fL21wFAqZb%2F9E8svR6fOnMGSvY5ncQ8WIg3C89HYtCrdfpi4p2jHOsmVsR7lgFd0ld5qCvqGf4Gl%2FM5kJOrn%2FbWHXWHGWnyM1GrHjMRXPWxCXUc%2Br22v5h2gibyyBxPvV5I8YHb3pok48SpiuzMW92CN5kOZhycZsB4usfZNYKkEQcYkHCvUW0F%2BVRrqO%2BK6xMdHLfIa14g%3D%3D",
  "Florida State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Florida%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=M0Uw8kTFmgsci%2FU5h3HMUJ4vCM6XY4D0TYtwKD1q%2B%2FxoGITN8PrrEyZZ0fXtyoQiLnaYoQ1XtRzrsRZri9KNCMD%2Fy7wk4RegbMqaSqQ8tGF894FJTiM0wl4jr7voF7YrgwbTg4%2B7nMzrIzL%2FKMPeP2QJ%2Fy%2BpAod2J6%2FsgY64CSqtqeEskqzMFx6IT%2FRj%2B7TV90fUndzJd8vcoc9Ra0wCtBThBypxeVehcTWGc2qAfxmTh5WorX9Toe5N9Aw8Fv0pEptJOfWbnyfrb7rbS7rNMDLpLhRzsvOI%2BJ%2BMf2SkObj4Xl5V6aQKDY1fCP4TEopA68m0sg8I0kGypVnrJ6sfjg%3D%3D",
  "Davidson College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Davidson%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=PcfudDeKvaWZAxszGbyqaGlgjQKMkLqcfzz6xbbyPmh%2FlZs22a1CNn9ttKxh0XEZZrjtAqDW3QKaKpcBSi5FgF81wlp6uBEJ5KMDaacRYOVgkiz4Rg6bie2fEtw26ukujTWJBdLSMB1qaJAe%2FIQfWGu7qi3BxLNtnpw5iQKBhfO9%2FydHdoVBHpA%2FWfrD9SAijdC%2FuEOky13%2F3%2BOBlRmJo32g0TmHKUh%2FU%2B%2FJPPWeF4aB2f2n43ipYIJBy5INB3NES2WbZSddzWX4YU49pTVKCoyIDmNwMKb7upvct8Y1EZeyK9U3OuL1SA0EEBf8ZaCNvg2RXK1dsdMGG%2B0JGSMr%2FQ%3D%3D",
  "Mount Royal University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Mount%20Royal%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=cBTtMKeKp8KcQFJGuvbl0GrYAHW1n9NPrVFvqmC5601m%2BpORTEn6y%2F%2Bkczk5L1JIGI20AwGxKskshXAf5xLiJh7BhsjEYtr2yXgqL3tCaala9vMYWPTcf97RCty%2FCH4Hy0RjLTh1bl9SI4hC16Bd5IIrSJoTA4CcneSNYAVq46DmR8%2FiO9fJFA16%2BB1FqgQt%2B%2BicSO%2F6AjNmBi1fcq9chOija0RgP1F%2FCeHuOaj2JD5j9SdzffbW%2BD5DNUQgROhlSWth%2FYwgSHnut7t2tQZStRYmRtOEdkN%2Fa3x9KHA6%2FHr3kIDFG%2Fxjxhg%2F%2Fpzt%2BwxQH33ceQfSzRlxXOLGUCzS%2Fw%3D%3D",
  "Wake Forest University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Wake%20Forest%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=tB5t3Me4q2mQ%2Fv2KPH%2F1O31xntvX4RNC7C0VjRtY8Z3m5I5uPQ2CEmN%2BhD7jtxN7J1ZUzwd49SuxnM4oz4viZPnJmW7OrAlaN%2FgTS1Ypz540tXDTxjDqPVKFwgqX4%2FKjnPAO6XkspcAxaMxAgYnrNaBweNoLq5A4sVK6jLRep9I17Q4AZKmgYywqdhaQa7BonJ4g5qyox7a6fbe9hAx%2BGlTK7s5JoKTZ%2B3dfjhdhPAQ%2BO2ycq8YS3A5UBeX92EVZtCnKANVs2GIP2p1bNIMNG1Cm2s9BVrxdK0CSiB0I3CHjWdaDtKssU2H7MFP6917BVpj%2Ft1nrN5SUlqA1rbD03g%3D%3D",
  "Saint John's University (NY)": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/St.%20John%27s%20University%2C%20The%20Peter%20J.%20Tobin%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=q5ziQ2SqbdK3zwisSKgtop994o7RZvgRqK%2Fzx%2BbpJUherdelcYwgMHnJ%2Bd07GYjpvu7PnEFT8OYF5x8tX72mN4jxywwyIu1%2Bobyg1eyfH5iliSnp5bHbhWLiPyZ4%2BGvDi5AS1MTDEHKaKWpx88b3XP%2BWaO2WAZPxSOrUDmJh0uKHwN%2BCwfb5fhgaCKMMRuiq1DjaQLsditFII7s8ZlBX7Apq9%2B0IkzG8ti%2BexLIJmCpiRaUuKimnOIWEoa5665cZ5gcHRjCjtcqriiD7r17Zn3q3859EBg7HDKmlt8A8PkXbIYxKwr4DSFBOzkxffnhwE5OWUldn6NWkSWjNpXu1KA%3D%3D",
  "University of Illinois, Urbana-Champaign": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Foothill%20High%20School?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=m51PO29uNkeITQoLIwA3XRHw2AS%2F8pyvobUPVNA62Xn5vgGzmH2DCD8p0mXfwOylewah%2FlWbIpTGNBZwtW9fgcfwYdrNOpRWytOMsj3g0Abe40UFmmUQZJZBHw6Iy%2BI6xUF9lmRjlmLy6DcBm5zy%2FIP8GiL%2FyZxnFYZTTqardIoXd7oSKkNGzbHDr8wZgJ9umGeue5nuLKO%2Feged1HJ3vtR0yUJHCVw%2Bm%2BieG2ZkcDi4KTQveQz3ubVq7ixE0qGxXwG9cv3ziNvRfJp%2F5CnAFgTvOmZP8NwokGDd3xeLopqcg6rQONT6i%2F1iI1C7X4D7MJSnthO%2BREjKiv0rklvaDg%3D%3D",
  "Hamilton College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Hamilton%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=vUeW7m2ptgAK%2B%2BT0Hb0kCsasDBLJ6z69UXF5mBd5rlLgO2RKDjiGWCtcZUs%2F8jqJ2nUaboF2xhglwW0slT%2B3Q6KEY0Na5kK5CUCm0W0kFDVnwzqPLabnSBIt8b%2BtwLpbc6T%2BvOntDHWJWKGCcU67Miv3DKCCgRSpa9w4t1VeuPgFrTX54EL8QqW%2FPzOqE8bLegCRSQA3AlVbpNuNKsbtsYfODTpDiJXlI1KD7p5hNq%2BHw5GFRdbeCYeQDFV5f%2BXwJdgvhL2AaBsqKs2i7%2FkEEsTtvzfMWZyiGuFdtoiRFCrj8Ry4nf7mtEWPUQvb2xKUR%2BupMAB%2FehvCZ4e09cvuEA%3D%3D",
  "Purdue University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Purdue%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=TmCTPiIbezCFJqHCKTztgM5MiMgW0AJ3nW9owhOmPnr13kW1IWEXSHrR5rNOfv8%2FvPkLdVPX%2FZJMWrz3JKusegwnuB%2FbjWSbLYB3GLHx4svAx6Nqqg%2FkTqVqf326yO63p7klBxkgVuGUFXGmewruhcH5Bcl0q0ZYc6VPDWw2jYhAhfYU23Bb0H%2FmjxOWWHCIIoI697fqdkt7PHb4cHaWQJZtd%2BANchMMNYRh%2FyPHAUzWoytPIKAo%2B%2FisJ8afKwals6mUGXuG6%2FtuaRq3UcKl7x5P9Wm%2BeMPnXzhzJ%2FNQUYGU0ln0nyfGLIvrnsHitbh12dICLz%2BZ1RLZzlRkEy3mTw%3D%3D",
  "Iowa State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Iowa%20State%20University%20-%20Ivy%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=denaCrk0etcuWtwChRkSELJxK%2BGmM5%2BkzTG5nmFkSY343ofld6PKmUMJVK75SMduZ%2BvGRBpYvqkgc6jBD9JefBtzPl%2FzzuJDPtUaxFRbK83eoGfvwh%2B7jI%2BqMhQ%2BdOuJA3zOorZ0TbGNqZ%2BWGNtHTtZT1s9%2FPlo2wxk9Xfo7a4KhipB%2FOiCqegEk6T0Pam7Wj0Ashee4ZcqCYXZ0qdeomxzbaUF7SpMTyvaZMq%2FWveeoDxYbQO8wh70b1RQa7b0H36zMfT9TL%2BBiD49jgwqwn2ZbEgRTEgYiLumzvqFr9I9R3lVqlkVv2617p4l68zk5ojcdHF0WjyAq7upi0ydqZA%3D%3D",
  "Arizona State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/W.%20P.%20Carey%20School%20of%20Business%20%E2%80%93%20Arizona%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=mKbetMtgHiRXosxSYqcth8tRuT9nMXhMhHyKC5StFHXa4ofnrwISswu1P8yYgh6oy%2FjRDeKOCGHjWPGG7b8i%2BODIzeD3KUQEPRbRWazyso4H90I4V13%2BA1f6X8kKkqkRa0l3Ov4lS5qcwR8FEPRDXeEolfAOxRect9eMy%2BhPKbPo5V7f0cYkQPG4A9cPpLEhpGI%2B7uSey%2BLoNI07AV2D0jiBSd5AjAy6B4SDGlXhRWnWpIm5K3WVgggcw%2Bj8RpCfkwRUKP535449niDk3FoUSyk%2FmKsRX3sh%2Fk4rhGc79weikQKBLg5ZsB%2BD9inBaFZ3Jj0b2Pf1SJyqB9kEc%2Be6Og%3D%3D",
  "Virginia Commonwealth University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Virginia%20Commonwealth%20University%20-%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ZVidYHPgVjczWNonMCt71pD5G9buBVwcxNyjgVRuPUqEfKWd2comTsR0BPM7%2FEpZYxKegv6XcqlwjBjODpEBMjxdB3Ujxzn9WmodLzdQzTKopiCMv9HFbvoCpGUYpljmIBY9Km8EotEDdmEZdMdd2OpJYnV5ze38%2BcE1epxlBzn5ISvQ%2BgktJR7v1y%2BUYMiBInF1mgQGxNf349HMp%2BW9hGmmpcXIKMmqgC7PfuDiCt%2F2nMXm3Wp90a88moeeq5wclwrPqYdbu7QmjNBK1019mQnQflvT%2BoI8JWUueVR2%2BhXLtUVuLQhrQx8qeyINHbbmz7HG9bsOpfK9dGwz4nUqRA%3D%3D",
  "North Carolina State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/North%20Carolina%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=eJ9LGVfXtRIHt9OQxT4%2FhtVyJ9Vo7UDTM1KwAIx%2FllDNIfTF8TdrjfegHJRDduFFGfSIjeo3YXT%2FC%2BHRD%2FaIRkur8iBNTmlkALqtdka15vkDAzAGs1QGLMXlTJu2e3J6U2ECoE5RZEUhm%2BUi0khUb6gImtw23CEOpL%2Ff92N%2FXZa4OP%2BacPZeqwtLEoYq8Q6gY0VAIKo8e3zylB2t7CzLPdrfGbMXiUDtZFyeGBvO4%2Fv82A3DXDDcnPuDDjTR5QHnYrNb7Tpte6xJ%2BCc5HTRAxsT%2FNWIJdgUEEHSWU5RziKHPHpDBSitrLx%2BCl%2FuDj3A1xBMwBXEO6MgAc4hhUNJPpA%3D%3D",
  "University of Central Florida": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Central%20Florida?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=gmPdtviNZKsKG07HH7kjpzefrs8YTFlkjhNKtD2gFPFH01wxjZdtFJ3dzF%2B6WqDxX%2BAT6aIUlyqAAPHFCywuCMUe3hU%2BIIpbCYv%2BfE8JW8h4YcahUM%2FFOt%2F6Jf5K17t3p%2BlTlxNzhys3yewIQhmgFHtCr2dE8Q3WDL9CbIkRKItYPuZWkq7%2FNuicWE0D9%2FBnji2T38L4QgCNm1FDiOj0%2FkqzyxDJMqgBTmkzxfoVmAdr8qwdlMsqrSMcOG7zRrgBykRJ%2BmTKL3Y3mPTgA%2BzzxY%2FqTUBPsZyKqPSj24C5hYGQsY9r9vgJR8CRElQdzqiuYa%2BZ%2FejKd7Vc6ZOjyG%2B0wg%3D%3D",
  "Wesleyan University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Wesleyan%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=kecFvQyyIjRabFE8fSnvpchZxanS2GlwmBqzjLfENc95i4JvhwAnBQ9ma6AezAOHJSHjFMKUIsuGWQhjR29MpOLC9FrlwumuZK30tFH8AiZH8tAi69V6OPJxP4fnEtJg0fjo9jSmpHYtdgplnnx2fZ3i4gH%2Ffybi2ChXRfZL4z0X9ToLdiAf1bvHGs20OXQW4npfB4%2Fq9VPjACJQhoRX4FIQNvPS2uE1VwDg9MzNyOURg%2FJu4pDvaYB0WXCFFiYfdqOsu4kaPYeHo4Yy7SJ4SbM01z36sNO8DipaCa%2ByAZF26kELqrTEZ%2BG4%2F2cVLAlcHUBRJLkRmEZ2zlDy6m0YsA%3D%3D",
  "DePauw University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/DePauw%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ikA3LXPv%2B6XB42acG7qc0MVJiBhxgFcAl9m%2Bz4GkPdPPg04taS9m81Qwwosc%2B39JZnRr%2Bw5b%2FtxaXl8qv8gP4mueXT5M0dxILCIH83oS6dYwMIeg4DSfYRRfAFGyexqYwGb7z0bPeIfqgMpmXP%2F9Zv9wzf63c2cagREnXTNzQdavDCXDd2xebvFJewWfO7v1h%2FCV84o0f19%2FwqgK6WPl1sgX6ECUgi3fga07UU042nfMDcYagkx5u7WbKtSkdpzXTdkgt7ysywK2dFapXjTVj3GrqFhFlPESIL8f7int8aE0qDYcgeHsHRFZwlLYleLhnqp8qEhp%2BsI6eFSFxhy6zA%3D%3D",
  "University of Florida": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Florida%20-%20Warrington%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=HYr7jrrY13YW%2BDYl4RlgUAYVfXnKZSNDaFZk8Ej%2BUmd%2Fm6g1PRgShJeH0uJyZ99waIaAIRGxqtWSRoECgVOUdmwezyI8EHwCS6LvdqBbqo8ZDJV7LgJn%2B8Uq7FcMsAFa%2BU67x3UCmxMvqwVFDhWzYqY0PSLhp8cLQom2nrx3568tknY0JQtE6yAZr15oTzY3oX5w0eAI51WRzknlOh1Qetryejc9OD6bCmcKBy2uRoy%2F8KsMmmg2YRiHF2C7nUlzP80mKZ7XUEIs18DkLZN8wsRbhnFU%2Fco5CmoZoWN8m6UblwiQOsu%2BqfRcjRs0yTb7u%2F9kssWkHEEirq8muzzQWw%3D%3D",
  "University of North Carolina at Chapel Hill": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20North%20Carolina%20at%20Chapel%20Hill?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=WQB7ocOjIwpkvP9AFNQxyuPlA3E1v9YkkH8pXRojQC60yd3JVmL0QlgPj9L7h%2FKX0dGWlWErzeCRHFQdT5IlTf2vxhpfo0YWupp6MgbWES7wY6gzVBIWlLuQJtBuOo0Ff3Crm9CHFTZ53VBjCh5ddVBN6s1X%2FYdlSoFd1ZB0KWskCZhkq46N7HckbSGmKN5oDTciAgukj%2BZBo%2B5McGV8tHVk8YB3OKKW3Gy4z5XwFj7yS0nb8sIBhKEqwbr5f3tcFuUGUr7bt8hHsgZ95XnBoWDDge64j7tjt2P6LLwSqB3mfOweEK3JgHrot%2FBjao%2BEZ4tuoe3KnMozqVA9PP1Lmw%3D%3D",
  "Northwestern University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Northwestern%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=d3%2Bdu2i2lsrN8iNGH48AWIp1vVlyL9OMxRAsm6m2mHfT%2BXhxiFvFNifdfojXj59IuMp0ATJPxE5XFcOhrG%2B8nhYdqzAgoVLqkMTJwnJHdCcWN6duxb%2F8yCsC1EawqX7gsKWbg0ZmyYa5Xnm886KQ91ax%2BMPbJ2jgpaPsGkgWnqTik2cpgpjsira8%2BsJC%2FV2pAACn%2FU4gzxgTkeX2gJoQi01laDb4WNKIVk8VzRA0praiiG3delSgrl%2FR2yPY7AKF%2FETMG9Uxw0d0StqFjMtaAl3PTHACs8ll9qgLL6H3mWW48Cg3NIKQL4tPYcweTgnMajbZWH3a6dCmg8cf%2FTnByA%3D%3D",
  "California State University, Northridge": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/California%20State%20University%2C%20Northridge?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=hi2jzVikWdDPZEF5A5XhU%2FKlba2k7os8ZyfJReC0%2FcKMl4lMlEfpPotuMfhe0z9%2FVxyGLEMCPRF5clD6wEwckpzpOwsfj5vuwp3v4YFpO4OllE3nMyhIheZBxCX8Yh3oWa4IdjCyj3PDd2JbT5FWsr8V8u7Oq0onstIXDYuFw6zq5FVXUzsXRnz%2BvwLYGVuUksWpnCazDgWY4jbOIJ4EWCKD0m9d0Bxf5WKagwcb7UCm9dePBntFrg09SPsW7vhER9HyGUuNXNPrPNeZC3w1WEjCD%2B3yV3%2FapwYIQGY6fCq7mnjVL9NunCX27FCO%2B2yXJ5ELRxGrTjxzqlsOLH7IvQ%3D%3D",
  "Washington University, Saint Louis": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Washington%20University%20in%20St.%20Louis?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=aTwywrWJjWIp11DBZWa%2B%2FHUgD4NiRFGuP6FUbWY5n3YXqX1ju5EKWGDxJ9DN8SiECSJoHGH8PB0gG27zxYLclvx3gzaIacfaWiK%2Be6ARMbgv%2FYCypQnwu%2FB3fFc5Wm9jlsl7B3X1tUbMAgvBQNovQLG6K9jTPNzg9d98NFJmW4IRzoh8RIixk0GB%2F6qKgmv3DfgiGP63Fia5e9k0sc4S2RinpnKBDr2Ql5J2d6PKFkALdKZoxAkEIfttNtQeO1iBy8lD6kMnShOVveLUQ5jEGieeO9Zu%2BTcDr6tVcN%2F6TJIYnA7HGaIt7Q8yT988%2BWdQd%2FsY1pSJ7vyz1UCdiuMjRg%3D%3D",
  "Middlebury College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Middlebury%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=tvJmIGBIy1GeabAgXcK324enwJ%2F8%2FetVPQNipHC4kAox%2FvqIuYAz1T6ss4Ye6WdOH9KpjB2WEi8nRAqkHCN5Kkhs4OlwO1ZJvBDGwvGcbKl0ULzK4r6SXCksPTEpplXc7ToA1FTznZEEWasMd9kIJNCCMDh1wTarIOPvAaURggv8axg32EyereFMF%2FwF%2Byfq9AwgmJNb%2F3aiVtT7VugnedN1g5jTFHAbngt8ZWxUbQ33rC54qQImEyPARCw6AjyjKJiMy%2BY7KHqAy1hl80uYD7lTS115Q0T55dgjuk4fhxjbw1FpLhIVTyO%2BbZVJ%2F%2Bz0AdJoAPh0lqEYlOKBL8Z9cw%3D%3D",
  "University of Cincinnati": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Cincinnati?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=CQj0TRqsYbzg9v63%2Fg%2FlU0QusHqsloEuvyUfT2E0Yk0xG4YCrOTGHGXWKiG6SZQKm%2FrGGLUOuhBUNMzZBz%2FxPvOWgvdR%2BLetbuzCPL4QfZXf1veJvQo54NSV%2BseYVWHZfpzjBa9s3vUgT4h5DP3Hzx2n3fUkeGF1y7SXA4vwxumAdisZiRTvNtVbRxlycHwr3Wk02dz01dQq1fR1Wi7LLSz24Gtrz7cHDLsZXePcRAl1l6cLJy06pYIQVh%2BiG5rKqFhGtWBE%2BQLSkCAAFiPdv9ibAR52IFTIkjmEThFf5lePnBerWx9mTFb5hgSMOrtlQpqjFN4eBTZgMENyWZoQqw%3D%3D",
  "Georgia Institute of Technology": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Georgia%20Institute%20of%20Technology?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=qLTGqmolWZfBAQfnJDUCTd04hC9fQoUbdWF%2BT3Wz7iYf2HEVQXPMlf%2FFg%2BotYQ2ZHrER7d3BqawvmQZNDanwdERdZSgMGiVE4B3pN3pA41uCf4LMgVXlt7Ov6QKTPfEWQUc7gXWkNwbRfl2AWItKV61x%2Fqi1SeukiNILrtHijvDjq%2FKZGsoBTmJt1Csj8LvR8Em9H9m6WLUsAhQ93ki1UzGip6XjP2JBjNLbZ6RpsJxXhOdqOwwgCEMmtAB9s7ppViYxj9gGd0v%2Fp0EkuWcoLuiPUVwkj2083FAfim17hBk9g2BtOFfH%2Fgczws1dZcLaxiz%2BmtexfG9SrmJL%2BmjRnA%3D%3D",
  "Oregon State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Oregon%20State%20University%20Honors%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=vyat6YhSFGtUfchiJpC%2FsN1jr3n2XScOMOq5CJRB%2BMvmLLZb2jGCSbgJ0vcCF0l61%2FTkU4e%2F5JxBCg0xDWPimO7gp9RMw3PKix7Fh6%2B%2BN1Y7gzYnf5Ic%2FIyUNu0OpxvUDq7F3v6PcDYAT7LnWGGkGbCm03F1j4T7e46J7GI5Id89lVh73RoEavtRF33KtWkqOs7UXMZ2fJwCpSs8Vau%2Bw6k9hWxmSDtGeXR9Tmf3N4oM1qWz98g%2FFLxcas4g9r65usDOG4z94H2YZUCgBwAZy7H1DLwIZ9f6ET4uwmn785HgQqMEeX7imZ1AHxr11N22gJrZ5VRsyQaUsa9cT9MLwg%3D%3D",
  "University of Toronto": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Toronto?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=H%2FmVRavzvavbDTKOdi2iXFg2zySQ6idzm56bGmPU9Ze8BvEINtAI8QZLzZG9gDaCuxwTfblvgB1Mk%2F0DPrpJ0uoDqpXxE6Dnhg6uv%2BirakCGbH4DLb3GiY3%2FjSbkyNKJlJ1Ld%2FdRVcQLw2UwlgFKuYyBLcMiD%2FFROTBEZ78fWCvcvQmLxfnO3S8RRDCW66QopuZm0%2BVJbqvVa6wuRRejx6YgNxZlRO8CAZTD%2FucpWVZg1Tvt4EoojztW%2B2W21C7FXarb%2Bjs2Dsnq%2FszEIiPUyceDyA1y%2FVF9JIyxr9stt%2Fsmw4mAWfthklyuJCqbLOY6v6O5g82YLlDDlpJvv3575w%3D%3D",
  "Louisiana State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Louisiana%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=cib8aglkgClrhYi8gGwcFcYMJC3slBUZxfVM52yXH3O0JYb3GidYBZC5XQCynuC%2BngdGbGK%2BfdCDEEO%2FltFPNzfKVKuyuAYN1ICc2GLlVd06R0QUerYEdvL5HN9L07Z3xdDse9%2BobT%2Fdpjjn1VSgf877bfO0Qs%2Bjs9cW35RfKRqneStltL0Y%2BEJ%2FP7iaPWOL5eD0fdfyoUIDvoFYpkubsKmQfAWVPKpgWBgXpMFPVnVuqgh3rlG%2FAFEyzKNqDMxZXcjjRuWTpRz2ZzqLoXRvhO0mJinOSqcogcenr1WEDabEc6%2Bp5h5k0%2F2Af69PlpnFIZKjxOprpjc4eGDm5mj5Fg%3D%3D",
  "University of Notre Dame": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Notre%20Dame?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=VHplb7L%2BfwWUupp1evp5zyc1O12BBVVYFJ7ky8emqVn0sVTpvs%2BjNzIOpfWAYsRxMoqzUIBqkBzkWILUnHlUqx%2BHXCwno8a75xu4MBkfANV8oqcOUzTYpvMu7XamRQo6XyNipavNVyGbTR9EsQ4RjFnVgDJkc8J23fYYLDe6vUglXO9HP14nWb%2BERO8YU7IGxVBBSo3BdK6Vh7NdmgeMRzsCijIuye7d9URnzcH%2Fbw4lT%2F2WLlGH%2FYxcZqbTP9zJwdshfxl6IgtY75TnkA7AeZhwSwaKo6WsbA%2FziUW9HdCRtM%2BKpMhqOHFH0JKd5EoUhxgobmQORGPBm7dftPVqIw%3D%3D",
  "DePaul University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/DePaul%20Driehaus%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=bEL1PKPaQ0PVhhSfYEMBIR0WkO93%2Fht%2FIkgJco2cYChDzVdAqTYD2GTG0d%2FRQHHsdZ4RbEcmuZn1JUmKQqvVdYzgCyScwvvdsNQKAqIKDhN%2BQrivvlBMqd3JMLau952QWtygDSJKeU1%2BiDdAVJeylU9N25AQ8NX2F2onkF7StrvDhGrUIG4DrIrWVSMreLKF3ed4t7r%2BUytqBACZzmhKCH7gPGRkD8OEaU4oaOLyP0f%2Ftb7wOko8iMElzde0R4CLrbUnEK57RdNXkhLvE52GLKA%2B%2BiKKG%2FBL9YaUsi08Va61wjjPhEXL1M%2BMKKZMxDvc716ye%2Be3rOXv3csYKQrutw%3D%3D",
  "Loyola Marymount University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Loyola%20Marymount%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ZspfzbLm7DD3464Nj%2B1w6s5w%2BkWFixorFP5TSBnGF7OiJ%2BamaWc%2BVzB3mJ8%2BdNBlCiEyaZJdOPFBfYGfwp%2BCVfUM6%2BVeGGtwACFwnx9RfAh3pVPDQnkIC%2BgKOZunVAHrXKShJ%2FyHMo9alrjez1DmPpohaFYYUzfExN38ykDRG%2FT3G9kp9nCvow7mQRdQpDqwx3PZbsmaYxuiN4l8U9LRZXV%2FI4vLPNMx2iBCOULgyqc%2FN9ucWBVW0ew7cCX9KSikPv6ySPz64w6rjAdDzecKIfUCuC6XxR7eQJ%2BhDTRtRBi5wMliz0OtVKs2iFRDnzNC9mgegcHSZjieixTMROH4xg%3D%3D",
  "University of Maryland, College Park": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Maryland?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=NNOhCYWkMNN3iBRXca2vOSvQihtB3sKfqNBSwP%2F16AydDj99pAy2fBEZ60GbEwET3BQdZ0jChkXH8wEl2ZZbogyGytMWckBirqa9BrDtS2OBiAwPW2bSKWZx0sQCXt0ycQzIB57kPnAh3eTfLDn7z17gAFoKZGqfQPvrw8WQRvQpcxdXZuwncU8UwogpDQz%2BMRys1QXYyOVJ3%2Bh1KU1P7%2F5%2F3bS5Fbj49Bjdlqb53CJsxCs9f8paHDmrvDTcxoHlyPJVet%2BFbI9Kv81dokEOuO15NKg%2BaAYk0Vqbi%2FAImjo40fFCKlUdpRn7OeyMc9fbIatOl6W89x3ifQMx719XTA%3D%3D",
  "New Jersey Institute of Technology": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/New%20Jersey%20Institute%20of%20Technology?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=vEUTXYFdvYNrJibcOO0w7FI5Ss0ySH5iPDmFRxCtQ3YWacscuYq2fqZeWTSookec8UShU5BzOPqVEgly55zPMssX3FE8Bixq5V2LpMQ37pnVhmaqHmqB9djx%2F5beAyAPmoflt9KN7hVOhgxPh1sc7qctVr6vEOua%2B5U%2BLi%2BHZEF54%2Br2%2F96%2B48R0PJwOpdZcZbBV2XoE326rgLnY%2FIX%2FOQo6x4kWFHCfCTzwny%2BfZFjV1X989pPUUTO3ODHE6SucWBdLBEBZDx49UiCmhMksWB1qpP0F8IerFdtvlq43CRIxH8nXMqlafvL9mFEchqjzLDfDcz9ZceCjwmF8BIdQEw%3D%3D",
  "University of Massachusetts at Amherst": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Massachusetts%20Amherst?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=AMYH1urZjUINGx0%2FiWumTIPwbW38jZzQjwW30ixNExghASVluauO9ss9EwlLvuviCf2dwdUXe6ZpXggHitH3lRPMJ5DXyV3cL21FrIuoKhVB%2F5Mt3aWu9DKaq39vTo6FXZ9ds%2Fo%2BxIEL%2BIfcL16AGpaqftYp1gvINGvId2qLEQCAwNIHAfi9%2BpRNO%2B6aNo4xcYGetlJmgCrSLg7zEit5zs52pM03L%2F%2BiW25Tb82JI%2FrlPs%2Bf1ZnH1yfv4S%2FcYuepYzdKDqEw21Uke1LI4BM%2BGzRPYD6npmPmSyp54MSSSGCB9E%2F6A1eShT9SG33pzD04%2BW6PxWSaj9WGnzv%2BZzwWKQ%3D%3D",
  "Gettysburg College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Gettysburg%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=fMqvFazOmlg5C5VGrirOvjsyPp6uuA%2BYGK%2BBh41Z7o22Zu9iKweTNiSo6hJYmUu9DKJuKAt0BT8fHluB%2FwCKumdpqMco%2Brz4QiudA%2BuH1hIFh4x4f2XMMD7H%2BcCbYH4M%2BFq0qBk3LewmKv9Hlgo%2FCIzjMtA%2BIc87mJnlzg%2FJRiOGsWxhAuHNXxslkclekW9S4uw%2F4jbd5gUmy3iBctuULf5y%2Fd86Cd4vuBbgPeZIwjDjWdgJwWmnIk1BNwfSLNrTpY6mbJiTzqWjWtKmsNb4Jdo4UBkNu57RxyPi238XXnbo6SdaE11GSA9whagmlf2aI9t5pxVDkJ6%2FuX7UpYBjnQ%3D%3D",
  "Brandeis University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Brandeis%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=iFE7XcIFaR9%2FoVTxyHmXYFezE6f4fLJ3hNJYr%2FbVey%2Brf1LZ6yARYbc3Dbrlk35rilDU758V9E08HztmhKVF%2BUa1aJ2s0wJbmKQFfwBvCV01QB72ktr4aEBHcz3BIF1JK3oYre0mkUA6r3jGbrN9sPdua3SnF00rU7lHj5i2TjCce%2FIyd4COgnfDz1ldZOoCTYgcQvMNCnvhCaky9kCpPS8r%2FmF65RsG2LDIv3l8iWn9hDchGwyinF%2FTZfrRcSq6UGenxg%2BZHC7lfsSfYngequEEQURQ8j5Ik%2BDaGmxXL%2F%2Fl13cNaT1Z4lzzZg2iVqTnI6lL2AVKqDlH99GKuFpN3g%3D%3D",
  "Morehouse": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Morehouse%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Ijpg%2FZB8Zs4A%2BeguKmXsh%2FtloPouydZTRh0bxF0pIQgfa8j%2BsNO585htBiAt4syVLuWX%2FTOno9P%2BCTd5xzUTZ9Ck7FMKRV%2F8Op%2Fwq%2FYLjHANEgjyeywHvv1pMzvV%2Fa%2FQbdfcRgvQSaAviZsqZP3t%2Fhu9VQ9arTRvHVWSfXnEYmKyRHesO8llJxjyrqG0Sb2SrRatOsEHWQdrDcDaPosLlKP0QtgncntVX0SaZhnVDNj37l4rUX6gw7az1T817rOUe8SO5z9Tbp2fxo3c5Z0iV2z%2FM7V7Rx4muSyYjD%2BM1I5JH8S2l7Y6rsr9%2BnS4b%2FvTy7Al%2Fj1QBzXnBrBpeRlSbQ%3D%3D",
  "Elmhurst College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Elmhurst%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=cUUDprk%2BDxqJvhRYfRmfyxEgGCsM0YXiw1mZkU4uCqsWQnPZejtQQenJIcoDsQwJx%2F0ajKjYskQ4%2FSYvTiWA1SBRIjQf25Klkdj5tpTWftAxcMGTipzSTNWKBl76zhL1NC%2Bphy7P0%2FWmrMQc1GSceUyBpoZV%2FoWGuJuInuYvNr8g8RLI%2Bkx%2F4SfWtPpTzMNI%2BS4qtttrgWNYcYf9%2B06rOFE7RfysihJ7fOfpmFrVDAbYWRUH0cSxGr6VbG%2BB%2FzolhOnqyItvsucJHd%2Bf%2FgLnQhbEDS5Z2WNgn7Ycr34kMcX%2BRsr%2BBN0NewR%2FNdzoC%2By5vb12fvHUm%2FgHdSTrX%2BSAww%3D%3D",
  "California Institute of Technology": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Universidad%20de%20las%20Fuerzas%20Armadas%20-%20ESPE?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=YX%2BnhZGfhlD7DIkWM8SsfbccN%2BDxN1BC%2FssBXCjSJ%2Boiqeno31kCxoK4ZuqswZxIDkJwUAd%2FbULXbLk3QLp9y0mbFWq8XDNLqMF8SaaKMY1i0t5A9J5ixj9US7n09CA3Yvw2ii8SoFRXKPQuI%2BXMy%2B9rLqqhbk4ZhHSPCpviO%2B3HH1hue6vasQtXwJ31jwykvyj91gOvzOSOen%2Fm%2B3T1INt9RMjM98nYljT7fmGSgU%2F0DOB3MXYU2gBFtBu%2BeJDQKWtYIMhswX%2B4KksRyaXsO0BagQAoGFKHXqliAGUvVvsW6CzLirWDx%2FW2PwOTovD6VFajvOV0cfanqvt%2FO%2Bk%2FlQ%3D%3D",
  "Colorado College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Colorado%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=hgHBtAxmJzqS4ELbP%2Bj6rEuatwif%2BcDhB7DEGeOiVbl6HiSZCNmd%2FNOEWtxu5soLuRurE2dvdiFbwMuUggYGalK7F3VKsNaSJD9px273ZFZvYZwbFQvp6Pg994U5m2qkk6D8Y5d1%2FCpC8LrfYJXRxiz06725TroGglsBelVo2MkOrrWeELJP6QnhUXZEmBmK4FcFxL97BfJqm28%2BZQyweyTifGpljAlt2izlYh34ARdlGZ1mQ6qrsnztCfTW5%2FsKB5lWfpAKfnUkuPjYtUnNnZUvYx7svsMRYxryZsKoNISS4DsyVsaLDhNLWjuMFiGOpeRDdD9VZjjsy41EdMiXSA%3D%3D",
  "Pennsylvania State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Penn%20State%20Smeal%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=V1Wj3Je0Gqc46xRQTs0txP5gSoiHBThdwKFvGJWx2q0vRsCMO3egQkqFa72c0SZ29OHUNObzTZyeqviRxs%2Burt5VYOgW%2BXe%2B5QvSTLNyHTmTnX2dWty39V11ZmerrhOpuqm26fE41U6IW6TU4en7ZMmj4WRvdEFpVmp6kd%2FAH1ZnPx9GFcvkljRPmY3nuxycyEaJUhxOAKP1STPAAA%2BksKXmYnVwxpZriNNhhmfYlqEyg4vAXQK7TgF9Bzerw2%2FIQj5qTQey2viIb0casoOOzJjjZWTCyadjibeDZGobjN7mAJM6Pcog1WR5SVP18CAcz9I3JQYrVCtZIjNWeh%2BDLQ%3D%3D",
  "University of South Carolina": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20South%20Carolina?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=t7FoOjGcSFPiX8iMRqu%2FBdMw5Kw9cUIgtG3fERmdOu8r7PK%2FcQ3jkXU1Zlmiwl%2FjNo%2BVRiAlnDqS5w61KvyZqVYJKv8jZZUviT323n3TnNC5mx%2B32pIvaR5uq0RGJZg4OVavYB%2BIt1wgNTul8BGGZP8L12bnliCunX4oLWuF2fCpq9ZUiKlDl3nWecpBwVgaGIqh2L5nc5QBCUhYSyCc9vGMvU1gRrM6pP6zxNjdhQrHO7Xa2CebAWfjiPeM6gErMjm8iGY3QIEmLGgl79MT6tFloP6bFSWgO%2BLCld5n9hTw8eqovbmlk1DHeQoy3K14E3V6XIzMsuFCVSXp3ny0tQ%3D%3D",
  "Virginia Tech": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Virginia%20Tech?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=St%2FGyFhqekGdqH%2BNqo%2FuAJvzOJpyna5JiwYS2kKd%2F%2Bsib6odK%2Fn4lZNJP9oaILkZ9qWLdgseeClGJiNToujaA7rPjlw7xzHZjLttHzlDRkE%2FkOHRUSnaN%2B%2Fkd5v77Ey%2B%2F1YkBJTsNV5mstZhN1ZAgZBX23cCND6jTfWhK05bUosRdDRBNMysSUX3gL0il%2BKAITB0er%2Fnh47YDJNvytJmoC3LAGGKP2GTqx%2Fb8h9Bik7YKMXj972LOpMRQG%2BwaET6zktEpAszMbdei9oNDizkUOirB8rEJqMI5%2FQkOqSO4Cg8uxdH3RjhFatkhXgBpSqV92FW4lCoYSofTAS3bWVS8Q%3D%3D",
  "University of California, Irvine": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20California%2C%20Irvine%20-%20The%20Paul%20Merage%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=oN06cDypO6o5C8po4Ndmo9oZ3BGZGM7eGqBNT6dJW3GQGtBoc5xJzLD4H7VZ5J2s%2BLSwbykM22kg%2F3bMOraZAHp0aJ%2BtMDKr20Djp23aM8iLuutUDoBU01e36p9qs5UxVKuO44KpXhV9BukUl6ne2rnAJu938yxKx2Knt3XwC2jQWooEBq9InDUP7h3l85DFnka9nZ%2Fyvum8Cf2yz1VG0QiLpE4zXA8fW5uaRE5YwtCjJWqb%2BmqJ1wTpM20BxfTUIg%2BDRt4Er9XNdNX6F2zFAo7cpgTrT1FVix1TNR8G6fW0DvrBq0y5D25L6kYC8IXoJJuqMuPYqQzeqreOhgqhuQ%3D%3D",
  "State University of New York at Buffalo": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20at%20Buffalo%20School%20of%20Management%2C%20The%20State%20University%20of%20New%20York?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=KtjjH1gmnGGGk3EtfuPoJ1Ane5M8m9z5F2vfUNIG5WyaJYubztTYlAfGxt0jdgTLlaSN%2FUyTrdHSCktgOnYZLPdC30UtJOkBO3g7eqkhZSQGvNjEhNuBVQ%2BEnRStlypV1ByncytTXO%2FjCn1TOYFrFvRQ%2BeSRRWzfs3qDaHfSd5BH8ZnitmouecoX%2B5IEncndMxgSklAXFSR3QnNthZL0bmVcwYpxRiXR8Dx1OvWpjb5bz%2Fu5O%2FpA74sFEla79ufdCNqm890oODocuNKitUux%2BHKbIghB%2FFu7S6OOjIHmHNGiHK5sTrRcKXq2p%2BtKuIoR1SE4K%2Bn2bwn9JtGmjUCoPw%3D%3D",
  "University of Connecticut": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Connecticut%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=RcuxIusWfflYFMxvUAIKFEL0qIEbVVrEV4JLSO9yVduOWCMYpiTunLkfiaX1Q2tHcivPOZJpDz5Os6liAzyHisvK6AocHq1flpoeavSHWn4l0aeMCRqszyfH7EW3hB2LqJPO%2BsHx10OFmLIe5TJTx%2F9ridVPfXFKLcOHKVFKH6workuEYRNckhNxjrd8r5wbPonvM7M8H0SB8dll06tTCTasbQeIU33ZA%2F4Q40or8NTchquKtn4g%2BZ2vsIRdROuwWndcqMK9bCSwBBhU64NGe4E462ylueLFlwbEJshUIYP0KltQYSSXOQoNLHsgzza6IDkPR4YFuiYhF%2BdhLOZOVw%3D%3D",
  "Columbia University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Columbia%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=VBb9Q3W9g1CM5jIA1%2F2mHqs8J%2Ff7ooEtAm1%2FNVA8WJNVyWjFB%2FMu3oHpIF5KS%2Fe%2FBIHlI7WWr3APhi93Grvq8j7wJ2nqTr%2B1XOzGeDMC34vI2OanpZynx8NmsvNj2gvfkJ0yV7sUSo0dLBl9KRQ%2BCQU7rVTx82TmhuONmfk7d8Y5VugS9KZ8SyU%2Fo994PQ7rn03%2BB65CQrg0ohYsfDXLUxA1IFyiijIOxJ09HpL9cPYyykTxNrhDsqWOqN0yMS%2B8%2FeLt4MTI%2Fi9ozGfikSzy7njEgN%2BmmZpNMv9EygKvTQOuRKwTHEklsHhBWQ9stcNx1EoodTY2PdDe3ahbHLMR5w%3D%3D",
  "Kent State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Kent%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ZJXv3WzMQdDR7Vg2rP7H1pb7QFn3eXuH9NhESybNXCrMwByPfn9kbuRWpQNdp7y%2Bl20aie%2BsBe2AWZmzBy9LCL%2B5PRPSCZltihyq5prZk1CnGtLWfM1jplAIyZ%2BfBA85%2BjOf0Bt%2BYoNSuW3ff556XR%2FQHn9tNzp0nQ%2BWSFAVYI636EOQNkcwz5vjyUtpibMue%2F6%2F6eS%2BtQpR704GiNTZvYG8qKbGyXfcnk%2BsXyO2QvUZ1148zdHfK%2Fwpa0T7PwXWD3uyTaiNsRWzmsAvC%2FIg4pbEvcEQ7cDMvQi9DSj%2Fp7WimjEHf%2FRLtxSiVKjk65%2FBI7qonxmua%2B6zuE9Ec%2FCIkg%3D%3D",
  "University of Georgia": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Georgia%20-%20Terry%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Mu2ExOZkUTC57e6nU30xZ2gbowmHWDzkXteUy5AhQOK%2BRCh%2FIoL7AxdB2y0I45bJIAxZvOU5Ej0kk5fyy4fw5jxIB5AD3WhxgeM0VWUEdqZgywdY6oMhL3WBR2S18bF3242MUK8TEY%2BK56D0y2r1EXWj1RWHcXr0kdoTuBTdUzME6JJWrBT0Crgl3mWt5SjQjIobkZEoxa9Gla6IT1Dk80kgkDrV1ovccEMdjZbK9IKU7i%2FtG3I%2FnHzDgzbqiawkolJEz76HNjaWIcG8zes9IPfi%2BOKaQkva1CqU5PI5%2FKUKuW%2FUtiu0gNA6RePo505S4aV1%2Fd0RIVW1ZFY8ojDwtw%3D%3D",
  "Swarthmore College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Swarthmore%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=mE%2Bh5iA2lkfc77e4WmyIr694%2FLA9yjYeHUnyNaRWBPjJELcxzN7m8yHRXwWQJRoQLVJ6x4u0c2uJCiPofO53ojdBu58X%2B1WQ1Mz6%2B%2F10exOuxhphnEi3Usyq1xhTr9vksyIPVGC1zTgCwZ%2BMDMYdUu19%2FD0Ifx8%2BdhEU5BxzPhInvBrwUXGxfJufTwdhH1dR2ZI59SDcUQwysGH5awb3%2Fp1jGvkqIJAHgDeeFFsi90cIdu66of6P%2FSQNn3DjbBMTLCUC0c7AXs3zqlNYvuR5hzzg3oLy2mRIigi1n9Qu4fOXG%2FbGZphBvYFvIy%2B4cQrUzRI3oarHedQtrzd4R2MqnA%3D%3D",
  "Washington & Lee University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Washington%20and%20Lee%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=SAP3fG6r0Fsjo1g2s6wTzkwEwYqiowXXL3M7CANry%2F5dfoOAHluQ7%2BJhfvyP4yQveGM5MThecTgVMCGG8avEJ4iM3qCYqES9OP7iYFW0ihgDq7xyrvNXZzL2H9pzm8FNWk18xqI5l6Rlf3BkwKoTlbaI6gtoj53u6qEuSlGmcw856Txaq5ad%2FF%2FskczkHhCtlKZZgBck3pxC6qOxlsT%2Bl%2FLQCqmKkAuhcvQU26V09IJ6H%2FPLM0XLtthczsktw4%2FfJNnJd%2BHReHSeOaDzTidUj3doGD7gdsjZ3dU3lD1AxC2S9wqNyqprmfL9Iyvg7%2BEHXM8wogA1vq4Q8bm%2Bq9Eabw%3D%3D",
  "Elon College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Elon%20University%20-%20Martha%20and%20Spencer%20Love%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=FqVLGhdUbU1ludUSH9GfI1mI6bjJdGDd29EOavyEGDI608BZL0RC86JdLDaLB40AKmX848kl%2BSorkVNLKf8uAwhnZnCbIv272QUDvKR%2BAf67DpnFJtgIIAUGfuEOB7AwjuaPWwiDR%2BOUyQexyUvvCaG3zDIfJQYFvXEH51qf4DoFHeKM6YUhYmlLvr2VEJucluTpQyttg63KEqZU7YOEXW7SSSAL9gX5OXTo40G2imrAUCFQnkR9%2FSi0hfCF8qyIOET%2Fn1tcBIybK0HC8OfHv5pleaeFvlwlSbbmCnRvONi5LmMCe3URHsegyF84tpEyLSU5la9S%2Bf23pzdoLzO9zA%3D%3D",
  "University of Massachusetts Boston": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/UMass%20Boston?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=itagXp3qM%2BuLmsgnSRyg09UyKyTZgHY1Rtq0xyIcn4C%2BkVPCV3ziwdX9gXpQzx%2FZuNHJYqHj%2FAQRB4ZkHG9mKqzpoKF82OGjdDTXnpBiyexKXcZKQ8dmIptxRFC7JkachGPZE7moRUlBI1AiRrFzjUJxfUvh8TNKgg0gw2AS5pYgKZxWZ7qeF9Y8nAf6RXe1Fh4RccQ%2FYiX3aTuGOUX5txqXAAeNDAspm2wasNBlbS3UkgwVvuDfZZT9YmtVHje3B2NOOMCC3CybYXa90OQTcjO3BAD3EgtnZI0JGiC1mx0zptGMo1KzrvHbsX5oMoizSG3BSvvBXuf3qne6%2Fkhj9g%3D%3D",
  "Florida International University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Florida%20International%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=KiEed9JUiN3I7rdnnISuG6A5nyqx6NAuvYzNOfm6ATCm0PC7iX15mXzigdtstP4zg2nMJd26oBCP1SLC9GsP50L4fh5GMAEJmQTEJ3JMXfy3%2FBa2o4BF3q6tK9GGvywz1d4vimnE8Htz4lzfDwZDVxj7nasQbbNQ9lIEObsgNMjKZrwBbr4gFH5Zw%2FKIHReaDnrSvSWLQXC9szUY5oUVDOoTht5d3%2F4yg3l2e%2B08yvBJnsKSbKDrnJvY0eFGp6qRz%2FD7q%2Bb2jzSALrmOVR6rMSLeFPJv11c3mkwsVeOso6ZqC67xH%2FUxDwFDoIivjPjV5MedKFfLSh4DmZRli0jmxg%3D%3D",
  "Kenyon College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Kenyon%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=pcsE0PGjS27x3oqba05S0ukz3T8f1A82g%2FRHO%2BXjG9TdUNWc%2BM%2FOVGPbFIzbXsos8xWM%2FoQ89d3CZgD1d7hKGjj49CG7Y09X6pttF8dVxluMM%2Bw56gFa%2BgrTeNYig2IUT%2BHwR5N0Eoyi9aIi4aYvqX1ZBpojwz7XyvMQkBntys5rHT4GbPintIVEqWNIcP%2BLruLklZIiCTQ1Pq7c2P7J%2BOXBhpMsTlWwNTqXnTd3Hxb4beo0VjptCLkrvCSuxaVXNT2anaGOrif6loFNXtEUkIMwdi%2BjbqP6LAaQzuu0IrP1js8ZGih4l7ULv1F5SgK4%2B4FG26F5EG9umvxGG%2FA5PA%3D%3D",
  "University of Akron": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20University%20of%20Akron?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=GvbzNrm7h2nrAa1ojG5kday%2F3iJ%2FdbUv%2F9c9RJ9dshuKMxmxWcfExQw8SAQP1dpjD0XgVnA7%2FAk9bEnxG6S6JHge6G5oa0X1BKEPN%2B7h5o2AjN4eXSnqiLj8vtJfgLMrPmA8RamszxuQ1c7h3nuGjGggPsOX79b9wLa4%2BPHwU8m%2BycwTxT5hx5fcaU7youAF1ISVi44iiGOoWL49pWe7EMx1NEdQEKB%2F8Edd38zUVwbZs1Ov%2BrMJ6caZkZVi7wGzRrAj25QuUfOqm9pMgthyadezRc%2BlmhdxXzsKHyiPHy9j6j%2FMW5MSuTs7DD6ntCvouN465luay1QiZjh0HqVbxg%3D%3D",
  "Jacksonville State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Jacksonville%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=xXjb3pUmE8hwWGE1tE7TDUA16x5idken1GvyCdV1BTI2pr3WYUf1a0ORu6au16C4DWmvo1cvDpSd9C8PCEV3O1BrSYMAM%2B5BvBfBBlxw1T9%2BcGombDMyEu01lASnMgLu9E%2FqvhGYy1dIWDALLtNTWOnITp91YbcLNWH1niMYMzRkkRw9dMS0BpQSpipfZbXuDCGOCrNx5x%2B3uCDdwbPW1QJkIRvYj0IoZmIPJUYmak8aqaJnnYbH6BakuP9vqag8vFrV5bYW66iUfjxNkZVEw75qBREOZb%2Fncef7V9hW4t9qjg3EXt6HvDJ1L8fjHk60w7oHHgAq93em0qp8Sq00MA%3D%3D",
  "Ohio State University - Columbus": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20Ohio%20State%20University%20Fisher%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=EfsJR5yCGsDz5EeRg9fA3pY7NHWh%2B2qmTmYv8ljaHEeQi2MddbsgtIE0o8c%2F59iZHVXerNHWUS1F64tiNiMo6q%2B6TxX3VoQN2lythjiO6g%2BWbm%2BqV%2BHScAE%2FrpoNcADCEYcZudNI46rX2339eAFxReMkPA3cNUeWUymDC5MtkwSLFhqVR%2BuDJYHaTgICBQwreI6TSzFmUKS1%2Fsvx1i2AQt5WCYZXEGMsfNb5qBtsAJxKEgZs703bzGbrMZ3wnkqITu0ZAm3Yk5hfvLCAvDPeqdmhaaHEXRMMXnJpIDK%2FTIfkf0GP2jtExypeAwyz53l2OD6hNYU7d9yj%2Ftl4LNfSHg%3D%3D",
  "Bates College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Bates%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=AhEMnh%2BAYCOQ9c9ADYZlslSs0MELpsnghl73YeXk58BKITcR4iaLDyHrYrhkfQ0wCsNPT6Fjw89j45ssjGbveriXsyGzG8UwQCttmjRjDGAsRp0QI9iJScYhxw3VTHVclLkBVS22LHCaMgeiQCIze18Q7AAZ01lJugSYJGnEGCm5SgiqTg9%2BTrTiVSpOEWYflLxIpmYrC7%2FYfUK%2FgqrskVi4GxBhM7eQeEhrBmICnO5Y3I9Ue2O2o5uQ9y5warmb6oP5dY6CovFVOefFyvBC%2B16olwEOBF7je9keAFU9zZzewhgtQ7trgHrhjCG0BrHLLC1MNCGTiy3d3MJaTKemsQ%3D%3D",
  "Stevens Institute of Technology": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Stevens%20Institute%20of%20Technology?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=sosj%2FgzmIYtJ2ixQhJau4m%2B2uifQbUQygjpwpqZaYPysPFcIcUuKuFHzV%2B0pmM7A7qe2pXgtoziBa4sehSDv2mjvRijDzI0v3L81CDbJrTYsZnuBi2QOXPJqMMjpjfNMuzIwnGrqtEw7y7N4xrrC4VG1xzgba51We125YV%2FlMbWLPoM5OCd2DFpID94lUBIf0hhUQ4urxjBG9TH3ntFl9kqxF2hdk3W5Bh%2Bjz%2BhW6d1rDW9QmhAUcxjgWzr9QsZEQjBQ8ol5Jigsy%2F1NDLHz587DQN8ngGLmTJ98BCY4rxspZkOvPsFi1Abl%2BvhUvBF1Plv8Z8fQx5og4GlI5gZ6ig%3D%3D",
  "Stanford University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Stanford%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=D1DWnZv1oXueHWVn5y%2BPHgOzFZgwX8h75BZComYHNlEIj2%2FnwWNR5%2Br%2BB8g4ngXZlwbCD1E3bPOX8lshUtGAeCp8a0mZcDks%2F5SUDrcNO81Ud01zxlHVLiPk22tTJa5GdTisnoILOHjt6MSHC6tqWiJ1XrtGehu%2FUhwiym1ypNxmwGonK8fSik2wIJCkO2pl588Xrt4P2VG%2BoiEylo1u5s%2FPoBsC6kch%2FT%2BVa5kHfYzdoPRlilAuTV4lSXuAEfIz5dGTED9D0Gscnfq4JpOKgxaWduAvxBLRD8fHhVvoi8PPCKDFLtfx2bUBoQBGnw3kBpP6F3N%2FEFs7VLEcWGAIyA%3D%3D",
  "University of Vermont": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Vermont%20Grossman%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Qe8LH3W5%2FOYQz%2F63TWoXXeE9Gx%2Bv3ak4164QDfl%2BmS6NizEj0eg4zAR8LjCCMe2N6vZFzoO1tmtCqXd1lfvqZsf%2FdtHKysgdrwnXyd6EsMaXK%2BTIiJdNDq7efMZMqvNBYtrwUGQj%2F9SndNEda5ZbGpFkdW3jPJ%2FZlYQGHW7uwx3u66%2BJu%2FjCNmAqmiC08MiG3IWZ0xYREzsI3lJJzfrDxxZCWht%2BzHg5fcm6z6cQu6yQ59II%2BXgHsR983C77Kjrjkl8BrtlNsE%2BSZ0puOn%2Bos%2FwGnspr7BRYOwC%2B8NqCyI6sZzScZpC0GMT32r6RO687LYTyaKQr8haE6rsDIaAeug%3D%3D",
  "University of Miami": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Miami%20Herbert%20Business%20School?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ybeznXRWsA1sgGJSnVx2pHuT9v8WOiodTmH%2BgzhLx3ocGvH3URZqpXmirqLM9YhiSe8l3S8o%2BPK4ifDis8frfeqJ8lfxjHa0ZD7K0w1FucIIzkwmBm0Ibq0M3VOhalYIgUFnp9BW8%2BwpvuvoAkIb%2BzROllaz6CXP7txf9YlZW%2ByYJVTQjOA4wuc7NpRHWAuhOqd3MSoQNrChMRrtn6ONmxFV6C4CXZkuDhcPp0KYmphYNYq3ENU9i4xMnkpbbcR9KgjERrLayAV3E9GywKKZEKjvYuY3mrFeoYsF8nafcmI3%2BTNotBj%2FM9Jg0DgRtvJHqzgSn3PlDwJykCNnXuI%2Bzg%3D%3D",
  "Baylor University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Baylor%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=rGTGMcbFzu8edFhlaC%2F1RswhujHbe2H9zyXFfYn1LQIDnjuItBKsS5M0zNaIjED8Xnu9Ny51qNp2dIv%2FufCnBZ9he%2FEcHnOjDAaLJhE3CqjvNJNO%2BeO9WRHuJMDKqUol3eeqn4ljTKCPoZ11mkvcy83UgjAVbj%2BjTASQ1TnLyWnLcaR2s%2FQS%2FSAP1TN8h39ecmsXbw6MGRDXiwht34gTyfCJS%2FtduAvPU8Z8f%2BQu3%2F6QZxfvnUfRmEzO%2BfmJhrJx6YIc9y83ovsy3GP0kcCXpjM1kVSLOmam%2Bd5uSLFmwSwfHl0mx8aAO2IZBfVUCCD9CQt5MdAl3qFACY3%2BG3%2Ff3g%3D%3D",
  "Georgia State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Georgia%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Fq4RbFbsatFnipDHTQotf8yHEdIJYcBC4Ckz6K%2FkH1wBtPP9Sr4oX61ofWpdxQujp3wqiOMeMqFsxXF1dvTyS1qeg9wnKg19bFbUiwZRpsPmp4PkXHt84fgVXrotH2k%2FtzSuGacXEDQDHzYIwEwmUHFkHumtMXoVrHv29ss060bL2qqj109TtQTvH46X7tycDInhWK0yBUf%2FzwqIjC6qX%2Fgpx4FMCYS7cJmxV8kW16CQiX2J8q4IX3gMCUJmXTXsS1OnKWGmHRjBmxlSJFOGQS5MglIT2D%2BEBHqjhA1WcxRPdJ46VBjGKUxH9FoGLYOJ4UMIz8MiidbUxXh2qq0CKw%3D%3D",
  "University of Texas at Dallas": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20University%20of%20Texas%20at%20Dallas?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=g%2B2yvKvIWKUXd2u7sMmbxnCHHcyHJg71XBE4GUrHXjFQdFEyfoLQY4kpzvkaATB6GZMS%2ByG1YSF1%2FjlTehztCiJMNFBSfJnvJqVOT%2Ft0ebYzfVUerg0hqB%2FZJOrgrqon9%2FBM75PF2VeiVl065GxxZQaTNxYesYEag0hMowkEmK4wH8O%2BSrlkKgLbmRkRTWBg95TpEuhhns0cD3kceHNcBYRFdUkgqpcMgesgJ%2Bfkups5IJb7XLeRbSd0BL1kX85T0HeZfmaqOeZ2JGuua9Mu%2F7NNqIFyfie2aB0vQOsJJ1vn5rg3bSnBwNPuK7gwk3jIOyG6%2FmsNK3dIrpclNGKQQA%3D%3D",
  "Hofstra University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Hofstra%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=NsrtE%2FM7ts%2BDVT9pbENrmOfLjJtRNzGj8%2FBRcN2PwGfZeR0QWGdokndFT2VeRotRj6cq95p0H5z2GOeqAf%2FALNGgjltNIvjFak%2Fww8MJrjFYmdjeq0xCD2b45ep9MO0S%2BJVzCnqRTVQsIRuyWjVVhSPJejNDZ871fyXulwWdsWlvQNe3VqGl8zBnEBEAZazdgynSVrWWZKiH%2FoZEAgJfGu7bVEBzq9qQ2s%2BBVerv2MLwXiY1xrTo%2F1sj1gLoaVGOgXAqVrEIyWKjC98MIWU9uvLEH81mGd4%2FC%2F21QxsVzqxBfjdhdxwDN7MQ8UQoAZJztSKVbz8BHJ7X12RtltPIDg%3D%3D",
  "Barnard College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Barnard%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=DiBgw9JfbzMvInXIg565DRBKb6oeYvA%2BscM92zcPiqPEi3wMnt8o%2BkggqiUpc43JOG0Y%2FZmv0c5GRn3BMtkEYnGrZ8EkZ1YFLAF1jIVhPeDpQkoD6uLBXkUPzQWDLpTJoIe%2B7Px6%2BjXsoR6%2BIKQyjSkzGJpCtGsFTh%2BUHYr%2Fosc9lOXhuLkBL9JMwZi1hyAB6MHHBJLmhrJDl7mvWgl56bisrtEd42yeL0WSHzZM60Mq2rnHqK%2FsR6EEWnWnNXNLvpqVtwCfm50qCRk6IIAz95lD88e%2BX8ltpDPn4S4tguoO1wYdEKE942jGIh%2BdKozSpAPreUiZrz1BdseR2D3bkA%3D%3D",
  "Simon Fraser University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Beedie%20School%20of%20Business%20at%20Simon%20Fraser%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=XC95xJ3%2Fj0xe%2FocvdFbdBQ0MSle0O5lRAiUerJ1ETvQJTtnXJa3ctV%2Bjc2Q6GgmC5FKDyyTmlqicCCnYMFguA8tCwKNaq%2BDCFEY5Pr027%2F609jOnhZcdg5NdVcoCpnlShcTjAPeKHXS%2BdqBGtf%2FWsTf%2FFCyZtHnVwDoqI3kUjkeqkEA5JGIYG37e77BFEzqtae2n6XrVUi4Ocs6rGpQgCDIUub3U%2F4ap8FhzRE2Dl29R6oyCvCv%2BDf9Yh6jo82E%2BLygtYWQLSGxzEkaeADmJ2jS0y%2FusSYR5Z%2FLM2N8UKOf4hwqgssVAMPZt4%2B%2FXq6tJxd3ZYbiiV8IOA5Fn4z%2BCPA%3D%3D",
  "Utah State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Utah%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=g298OEpH8VlZwrE5Wi5szFGLPjEPBxw9h1090NdS0IBidd0RgIS%2BDeEBJp9OUrBYmflU3O3qmiLzjr4P1AypHGcR6yqLuNgamO7DWiJaZvJKMKqmCentEwMhDOBnMOSbVx0Kox9rTgE04cWR7G7Z1ddYmsYN8dty8Rc8cd34ldJFdgfvq4wyyp6hNyxaU8w0isxSSdyZWK5GjWCuGICV6%2FovgIRTWV4J9VBmqUMyyEUpZNXUOa4MJkFBGc6aXxp0qVrFOv9MslTAv5ZKeILGFuQh0ovUSWqVStQyKkUB%2BKFJ%2BabC4jXh53FSjQc3IzJbWrYTemh6qFgV519jCsKzug%3D%3D",
  "National University of Singapore": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/NUS%20Business%20School?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=kV206MtpkKKq5wI8m1nt5%2B8Zjs5Vpj5GpUI4Zs3muW5YTivghelxl0SHlPj4QMrmiYL5wcQXWKgeNGUWhc4gHHK416h5YWMtTzi8CBqY7507dQxC%2Bj5d5L%2F4CRMwPvTjW%2BjJi6FtVcR5BzVur4scMXO0W5SbQ5nhFStq%2FGgrtA2YrlLslEtpX86IK7F%2FNV%2BgJCcxLUz8fnCO0ypjj2gmYuaci0ayeLHG%2BuUXT6bt4wEcLJRHfHJTyK0BI9t0m24YsPw1etPMGzMbGrHHlC21c81i%2B7Gh7%2F9L3xINLXt%2FUj7NErrbdzynF1MN%2BR2i%2BCY0s0OhF%2B5YmoqFW8MlNgpc1g%3D%3D",
  "Southern Methodist University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/SMU%20Cox%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=UMEv4rh7Qvf95dSZBB2VUB3LHzN267TwTw9uHhozQcMd9XFd%2BNv%2BT%2FLTFTe9SLI9C6xonUJqUO4vjs3vND93Wt1X52Ic9cBQyBMiyqQ6p9%2BqFQLohaCPVo7UpWTAr18cgAtSrNr7hyluPk9ocrJF9sJx1RGhL91av7DT80M0sIp6wleKkDCF2A8sQ7dXkn9DdVTtsYZKgTSLFMRL%2BrmFOJ0OeKh6TU%2B1jaCAE1XNS%2BKZfCOAMRn5Gw5aE4ggD%2FTS8Q33cquFXl7IV2PjQq519ysgd1KpEsc1BbdqIp7xMXmpGO4Y5tiyujHbuYQFunD4yeMO2rmH2iB8mog3wsuFoA%3D%3D",
  "Wellesley College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Wellesley%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=i0LynJterelLq9ehW2DS%2F%2F0soQ8MBFdhDHZqp1svcqaipvh2B77Zi6dUwWdr2XS6RzaBMZz%2BIaHJ09VfdYtLkqSf9eJNbCueLxYkuLNL2EF9eESOfVhmSOFyVkKRJh9VWWCoVYynWO5CBJ5dRameS9eWvSICYH62dLxW%2BgvYhCej5iYQM51EjkxTDoTbmro8qtg2wavQJ2j0ksAUm9i30Bx%2BGL1DL%2B%2F0AKiHxuwUB7wJzXokyyracbk6SQmsMpWzCdJbvQLY7eRLffXHndlxFCNFT%2FR8DdVUJaCnQ5KlB%2BkRWyorOulKbPWJmG5ThBm9z5PRFLJsG70X3%2BikRuFk4g%3D%3D",
  "Hong Kong University of Science and Technology": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20Hong%20Kong%20University%20of%20Science%20and%20Technology?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ra3NrO0wgwJk43X1ABrR6hsWS26P5cRJxaihzYE6fHx0ipQYwn4rCzXHwhVLJekxwjd4eB2ZlhfMFFsep4PIAJrQ%2FXqRkiaL0S9imGR1n5tCKrOYf7MoJMNdOjL4dS4LZYp19fOFB41WIC7odAZrY5nZpibM9jg%2BW6CVeDuMLJbXTH9UL%2Ff14O%2BvF7LJFC%2F%2BNuFuQQ6STzgIGQ34qi0SZ4aU2NgJkHQafKlxyN38%2BbGMbqqGU9jl4r%2B6OQ9X7ou0cDuHSKuK7DjWVgvLHT6K39IFMjyxtUD3941bRyhBRApfouuONJXDd2olhs82ynUyP%2F4p%2B1EwoASlE2aUWeZQ1g%3D%3D",
  "Amherst College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Amherst%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=VtDOFPfHsExvIUI2j0UQVk0Rc0t0Y%2FACbmdgsxGN8RpTL19cO93TEEV%2FVFnlB6lYw7MlWVKCCAxc991T%2Fllrxgy9L9YFTO4ZWyIGtG%2BaPcX3QuUgK2GEhJjOI9pGGBSXJhSZogqeaFCWk%2B1fpt7kSnH8MJRv34bLqlcTWwDsCiyfGF4HkEd%2B%2BoiAje6P0o544KjYWYqQKCjOcpe46dUb1qRr0y0PnNt25ecE237esJBzo6yy1qLOFM2rK46si2vAezGZKtzqWWouFFoGZjbm6%2FBPHDwHzrBF4Yq51Bc3EUEsRbiiMBsztW%2BeNsLYktpIKHJhCJra2oEsIHoRuL4HFw%3D%3D",
  "Pace University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Pace%20University%20-%20Lubin%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=HweKrDwp4H68mn3mJn645wrpde9cBmJKlYcq9Jhchitb6mI8dZABmhjgifzC09J%2BWHQVt2eM%2FC4VArnl%2F5%2FL3CnXtnOAByy2s0z%2FP5Uey6y5bIIviOwAtK7KnYWiAHjSjeGxNzsikUddla18gGhelCHAkcnfzusbCtt8Be1DZtrHY%2BZSSylBrTSUC8shnBOEWyClGAALddl4%2FOkBdfyXIBalbjgo6YbsvdVlZuqU4ry4wA37ahZlmQDDFM0RYgMOpsKTHwRiKwgQzduRXEl5rTIeU0NW%2BnjHe88ani4DbV%2BAksNqRcN8Ka5ChCprxT5mPAkpeqxJJPQSDksXpobbkw%3D%3D",
  "University of Richmond": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Richmond%20-%20Robins%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=SF3XkOYEGCUf521mLrzuWr963BZujGC2tgZU452Om7H7E5DIFCSTaY4F4Vbad7k8mgnOU99q58gq5mp3J%2Bs2dJaEsvnPykUX7xEGr0tDy8FPKpoEREZ0ITsx%2BJ15BgIj0MY075H0TqZWRA3G9exxWSd0LznpbxyXn%2FZelvtZvgwThx4%2BRoELNB2LAjRMni8tvhpMLp%2BaAQMmfzQ8krsno8SrZejLjCmLUOqbBXC8%2FdLUepFD1QjiK77BpW73ry%2F0%2FG9pQcMtCXQ7Fz9PfTXNAwYFkF5nEL4g5ZMytBHeRrOEmwEeIYFQDL%2FVcjEfblyPiTRjMebEwhYgUjzyTdel6w%3D%3D",
  "Florida Agricultural and Mechanical University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Florida%20Agricultural%20and%20Mechanical%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=WqqEtD8RWLxc1A%2FOdX9eSYgg4UeX14n9J5E6g1O6Y8Y%2F%2Fmi%2Fzor%2F2OxWXelY8IvsLTZS8FlSx%2Bcks07iK0bZFnBGfWnU5NZ2rhk5otlRjKxtc03Rfa77KqAj3fCr44FuIChCYxfA4s7dYNqtfpLjuK9MAHQWcOccBrweKU0CQbtrwVC492j1dRQYKaHUVZl5VTa2w0qKtN%2FWGuVRB8tp94LQ7gzI%2F15pKdc8WGZMXRXKNNfNyeBCo%2BBV4UwBZBU5oJTwv7BbYIjwFh8%2Bm01Z3CrD0kUOuquvTZyJzZDueAhUloCIbf5cwcp2rj%2BIkaFB4wDrxHR1BeuBQSYPtBTRsw%3D%3D",
  "Denison University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Denison%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=jpT%2FOadHHClZnykqY%2FL8k6PpNrVcJRdaew5VV%2BYMODuzEhNT2YRu5uUMzV5Xmv8yq5Py6MydHrybUarV7KTRaMhKS7rV7QzLlT21F6Jy%2BYt98PW%2BUt87H5yaOLJqLP%2F4tkqtVMvwp8JToo6zHSHp18AzfF8kS8pc77XKTWfzNlmGAslbVPJUvPH8ZN9N8q8krkFncMmfpIFJKUZlbTLGCA0Mv0wo%2BKN3c5Sme44%2BYrY9n6EbX4tdgrYd5gF8cMXVf5EpGE%2BEzSYleEMNZdLfYdG1Vpd8EyL4IlkxaJ60vlPUNxBVZ%2BsH7Gsr0w0wngb6%2FF5Tcw3k2%2BLqjZjgqBGedQ%3D%3D",
  "Augustana College (IL)": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Augustana%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=cuYjc9i07fED0SOwzbJidWBGtf1A6d6grWhkqrxRlleV85bJGUddMT1KawpQl5A4eU4Sxjy3C07RzgmDzDUfJYgI0e0TF48HvqwXFf7SeoCzZ%2FC3RM7UeFA2FPmKTVwTGJNnkzHuAcfkfvQVuXxWFhBtIPC3wUyXDLcuaYaeRDDkM8F73rR41TJkYXl662BylQBKV7PLMIYDcsCJnRI10XbBoh9xy%2BKLK5IpfpRKx8t6m6C4MzjSBVJWsi%2FDyaob3jUaKUFpauzg8FuXWgNJFRQ5WMj3ZuAn4VqrvF%2Fb2FryKOUU9%2B%2FuQen%2Bcr6mlwaAYtvGz8D4yd3W0%2FWDQYbxnA%3D%3D",
  "Carleton College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Carleton%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=gkry129q%2Fnl5brVrJMVUzBslIMdFFi0c66kzlW7M3WhQ9TJVi%2Fc7LTT5ii13Q%2BExntcOkm8n%2FJH4Hom%2BUgxypmtgrbX9%2BnEGkM73qEaf0YLWXNY%2BcPdjWaVnCg6vEMd1kcy1Dxr1mh0Dddvw7xf0deuDv6xETSduur9e9gYdYLBKSMZPzdrBZ1ZniWCEEsV8%2FQFREg%2BYcGjNQwhg8YmOIifs4XEyzybdBUsLp8d6cjU78%2B04f%2B%2FFfGARcXLTKSekuWgUD6ecPGHd8m4hPR5eHasvty5ciPYh%2BkOpX2dUCmKWbOlszwTkkhaIIYrVnZYPBMJR%2BWMouinEN%2B0y23%2F9%2BA%3D%3D",
  "Tulane University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Tulane%20University%20-%20A.B.%20Freeman%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=lJEaY09qX5RbK4gCIMWqprakZ4Nm%2BvTAl1EKefokKuKfGmoH%2BtEjImhl4biPJ1QRYv83EhK2u6OJjONufgf1uVfuOswa4Qg%2Frw%2B3%2BiH5vNkB%2FcqVMR2SBFDj02P40lkxNVSbS5dlcCmdoo3Tot1TBVD7Rdj4ug5kzNiRBkruENcxbcewFPyNyXh3oD42%2FWPq%2FivVg3sXvq%2FrOD090BYgGTzT8zANp3Ck0VoOF8iuM%2Bu8p%2FOaJqRUg%2Fl9IxekJ1PMJiWP9fcaOLUZwTH6bwta%2FRGzqMdaiK8e5ct3UH0afFt5QKwNkvHeEfyrOvns9bm9phO9t5h6rbb6dCm2WFfeYg%3D%3D",
  "Johns Hopkins University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20Johns%20Hopkins%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=QaA6tCBRQGg1rxxdmk6UAPp%2B3wlShr9dwQDWJ%2FY7PX6sW2MBBnYqeIYtPW1FfQXoYhEgMmvsJRE%2FtU7mLG%2B8BBRVMZMKXz5ZcAJ7PYU7oWmOzJrEMehh%2BOBnwUo%2BBjAP3mFbUvpJHfvNc%2Bm05ZyrXhdNEEhOoRKEVILx2mo6FmvTrekzEZCXS9spSx8XuYJ8jSvLbgH3Y1FNjJaXD6Xl%2FNGSshCdIVXvx8tHMh8SJIEklswvY0zfaFddHctN0uNLOROKlYXgnGwC9EdZSf6FuKWqtT0O%2FvIZ%2FskcV9Uq1Cd4lLuIfsc4zAAESH3EZWSU7queOQ6K2VkNNMr7e5t7Xg%3D%3D",
  "Chapman University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Chapman%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=tssyXIznxezAiV4Px0adNuR%2BbxUYr2nf97XcCaxtfB6BgjUxY%2FUkSNsUs96KZzzN41ESWpG5TcaLkuQ9b43AAODn0SxvKdp0cJdiYa7K6k3ZkdD0da%2B0QKEPt7F1JYOmEFRSxwvQQwwYJjv%2F7xOwWsmw1jCT5OqvAlyBNfa3Utr4yQT49ZMFmR5BEzJf15nLL%2BbSySE43klRAyhy0q82%2BKayDeOz%2FA1gWgDEKQMC1FTwqkR12yuX0aPmtQb0wUhNK5V81f7FC%2FvoV%2FWRHzZSz90Keu61UcY1RLf01Ijin4qe2gxy4kW8Z4q%2BeVUmiezFF2NEHDSM%2BbCkdNUo%2BCCHow%3D%3D",
  "Dallas County Community College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Dallas%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=SfYShOeiIMVnyVyDTQWBBwYze%2FzhCV%2FL6%2BoQvLbFORLZr1s%2FIKpNeMjAlaBoO%2FxIsxgzd8%2FTyEDlMNMfjsjuUX%2F0yTnGx7MuEo4H9r9LC%2FZY%2BXdNl2C9SOlhkVe8xs2yiOodK89roR1GWrCgoGRSuCg51KolDQFA%2FNCtrHgiVvYawFt54MTl99nBa2xOnbQCKylauzF7%2Fywu393VdCweDFfPtPbzuiXbI94bb35Q1dA7f2viZTo2VxPl2Qp1mT8wchgLlceaWc9zzdFEQL%2BDDCDCfnfMUpfaJAqytzC4n%2FGQt2CXQpThVKoTL3mxgdqWWYwRp2HCmIYjF08HfdBVIg%3D%3D",
  "Los Angeles Community College District": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Los%20Angeles%20Pierce%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=u1PCXXUHRXOAZFX7HH92zEv9ssVONeapBbLmw%2Fks9MbUvj8Bqs8am4rRGMlOIG2C94dODkCN%2Fk0wls0tP5Q6i1BZ19HYlOTAFZxwz9GBYnDQQKtUfXGacu2N%2B8TQvWcnwkBGDxC4tr%2FBI60%2FSzea%2FL%2Bm8E8K8pyjULqa%2BHtOT%2BMz%2BNW68mj%2BPDtYb3NYwq%2FDmHDyWB4SCNfRxEljfd18O%2BkMD1cK0arF5eqz7c5pevmwnx7pXwEUqf5aETu7l3Y%2Fe%2B07qpShMWXhLOb%2Bvt191ca0CHFG7%2F0EVT2i2%2BN9J4RW6%2FGZy1E7ZJXL8BCoqMQdu0zAwHOxwEQfVayiVJIQ%2Fg%3D%3D",
  "Henry Ford College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Michigan?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=GO37fJa8fz%2BoxNlJpzfespOLWbNqBjxBdJMeh9KNkkj6uYo%2FuWwyw75jP%2F6XkEJcTXTRWZvk8FelcmZHLJnaA3HGFc%2Bhemodsvcz6g9AJM0qGhRN90aI0XwfJNzfI4QtMCwwk7JCKuAqo11YTtV3ZFxdel%2B5sbRt6pleyUoFiNmgUo3%2F5ODOkiCx6wH3no5z7doGJ3STptqo9ovKqkB%2BpvABYjUturz%2BfG5R8bBye37Clp%2FGFMkNUzWLhxQ5BUXCyWk5F54RI%2Fe7i%2B0BxDuKJol%2BZtfoYewv8Rf7tCqSjEzu030y0Gsd1b8sFaXKPVtfGnMm8PjzKs%2ByBvOBpiiqNQ%3D%3D",
  "Clemson University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Clemson%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=dlwDu0r5MXMkxdTyeKlKr5JHZqo9fc4duNERn%2BWVyZS9BqNCmbNe8jbR3FsgbByZ4z%2F50t%2FZxNMvd%2FL%2BGKz%2FQQkVpvzrX8qzELyjq5CFiRUX%2FNIiCgz%2Fq%2Fg9gPBFIKLrLXmhmuh07iQ6s31kwuYTU78JcgLwo9Ky3ut4MBCcLw7vUqpUkeb80bvvkYxJh%2FE7Ws1vZYYwkQKnFZnFg%2B8cbkJrcpVk7lsxZVEREANMdI%2BcVMqtFgdRVa19Nmd7zg4mZwWzTMi%2BGyjJnGEcfe%2ByoMklSxEvvUkaHh4yGezyw3EF%2FDBloLtQuBantZUpZPiXUIR0GAwNjld2uuTtfNUC3w%3D%3D",
  "State University of New York at Albany": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20at%20Albany?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=p1IyqlVHiK7HfK7YY8rw7AG%2BqkU3wFVKG45gxz83aZbrFXyFFZe7%2B48jOnlShGf%2BiV416H1F1X7Mli44IWmVax7KTHPxS0z3FDkOPlupXRb6q745NdJ%2B3jjmDD6rfLtE5EaiZdqz8TF6EWAK01bcv8wrk%2BbNGcSvsZQlaZCXZLIcpQ9OSouZdouNiy2KminyQtHqhYjfmd6i5QJzHGRIkKNlao8GDoCPxvttkevBoUlCL4w1tVgKjDkni0ShXjf5gWNp4Wm9ZurEOhiHKLoHVBpksNvrAV%2FZJNrNfduv79IjhYjEu%2BGdTb2FoK%2BYbxh%2FbwwTNJwEVZjm0aoUTM%2FCqQ%3D%3D",
  "University of Massachusetts at Lowell": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Manning%20School%20of%20Business%2C%20UMass%20Lowell?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=LOHU6CGBnKZNjq3nDgpDCEl60WOmojJcsKSKhwa0waQiZOdS0G9kxz4n6YxVFhCphJ69wXRQjR0EFiQs6WfzumuTnoMqeGINyBeAJxMho4%2Fm3LmroOUICHJ5rKEL5aGWayyBXchV6d5lAWdDJy7pE2G92tHEcythg8RDyhPJ4ZKqIXkK2VLhaLE8kV6QQh4V2YplrCjzG8viY%2FhnvsFrjCoEm4Az9CdUJKn5Jh8R6PvVTXSqHBgBfUbQ643RLIsTEGAWm00C6M5ypi1EiYsMmhoGEY0d%2FD78xbO6Jrsglb8OYAs24O7gbn3K4S414Wuy19DrttJDkSA9TVpnAhZOvA%3D%3D",
  "Hampden-Sydney College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Hampden-Sydney%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=eyWVdTBmTt6ps3V9nBt69ezR%2FTmGmpmtzo8089YdaAHCh26foViTjU6ufKbq0FHMM6eTtGoLBrie8UH6icMyBxpqRjyGahlXPuK8gUWgidfgyJVAoPdaE8e3P1L1olZo8Imxf0LyIDzfIQX2um1lmOBSjS%2FXkwHL05D%2Bks5S8EXyFw%2Bf5Kd2VgywrgDjXVq4iUrJepbUFkQKdTgdcWfKd4gS0ERThFAnFWGt4otFRG4e7r1qzLPGEEgn94a5OvzZ70p3cCTn7RQPYSNkVvnJOcpYLCwZSBTjLZ5ilqxXdxzRXhOKKR31ymjlD3XU7G%2BiN%2BtfXq5Fn4H5Pa%2BX5gKuCQ%3D%3D",
  "Scripps College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Scripps%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=KIlkbIvXIPqkqihBRt3i1ky9N5YwkD%2FS0C8P2SqoUm6JKDJiNhxLkThmLK8ie79kEwy5OFN3ub8sBB405GDBjucVJqC3%2FMOYPL39Ah5Q6lZmDwP%2BP7%2FFmnMK4QUl6KVt2QP1VSGJuI%2FPQJANah6Kr5zGcsU2h4muqRq7vvFLTQ7ybGrGRZ7ote0noFQ2gKV4JaAeOPB1UKapb7P1v1OSkaYuYAZD49U92nhzwnIM1b8PT1%2BwU1Fqdsv16kXfdlaKwHkZk%2FTXFPNL%2ByXiIbfRpJ1FAnabxzOg8M%2F760sgpJKYCaee4Ng3GKTXHwC76MJNhBnAWFvSQYxNGACOPCUdig%3D%3D",
  "Westminster College of Fulton": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Westminster%20College%20%28MO%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ymDgM%2FmsZqR0xH1x%2FQj8ZTwJXm6uCYzQ5UxtcXVFgyKyjdIPwbVFvOrOZT0%2FF8K1aKD15CeeQsIaW0rD1FmK2QT1VZk8L%2FtvFY9wjfRSVN043bsn8zeVH3vdYozYQkWPF14gdNxWggfyaAhBXwohMrvKyLHM1RHtJPNh6xsYa%2BBsjMrGUSG4TucQoFdU85%2FAF0Cy7Gt1F6S7zJM1IOSKGbdsQWN1rMLkaHMJ576Nmc%2BNXnUXD0d85h879dKNMktK%2FLfw4qiH4TYktgpqbz%2FWqvTSVl%2BDQGe2Cw3xWrtL0RNBnDSEo%2FwxdHlTjsffCA908N%2B4%2BbRzjapWrZ%2BPGKEoYw%3D%3D",
  "San Diego State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/San%20Diego%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=iFv8EtWMUeV0vAVmB1%2FX%2Bg4WDVyqgC1hlnNgedreg66TIX%2BRFRln2LW0SeWzjrAj0K0K4BlM%2F3QPYtLp%2BEIk639JDfOwtEqy313aOk5RLMbyEqZ%2F%2FOcalveBErlpEk8%2F5LA8RV%2BZNC5JOo2X7abVzkBYA4eCi88My6degwNq8B0PQGX%2ByVeTPOIOA74OvcUMb%2B82DUczXtj4SZhUxDtoxWcZOv50O8VOZ2yOTiUDRym5MiY67QgOQtyapzflM64oIMMad15l3YAtO2knFwgTfM2VyoVqMaaBI90CHz9G7qwCKvrrfMGMiz8p1ew%2Bs8ni5lTw8OClBBcUamck%2FNAdwg%3D%3D",
  "College of the Holy Cross": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/College%20of%20the%20Holy%20Cross?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=BUY2T%2BbKd9zv%2F2P8znfcLVJ%2BSg%2FF0vC0bzzdw8LZhhdc8KjviwUG3%2BmASCMroDJOAmBWA793UldjTKTUeOu%2BO8cIFrgUwxSXZa%2FpGL4pWg%2Buv7XRdz2qSFUMiu7BkWUQT8pjJhuSUHfbQPvDJlsuIfINo0AihlTN7jOvTK6CpW7FhigBVAWhVvZaWkHdEhlDe6gLxPRiX3r2KlPMRrhL5GgT2dr17%2BohtWXv%2FvMUanPqfleEtTujZ%2Fd8F0TpbDH7D5adPNWUVAQ%2F%2FH%2FUtzoqaCZ0khT6MFM88KRPX0RL9ijfAMjKAT9dbkh7f2tezlXQ7%2B9O1rhFvqyD%2BCG9nwwesQ%3D%3D",
  "Texas Christian University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Texas%20Christian%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=OuI9QjdlnS6hWF3Tyh6CzH7H9ID1mTdPX2g4f1OuaQc5ccPl%2FC%2Fe37vKheMG9QABOBQxXiORniEg3Co6JHmuBNwxGLbFnPpkI7D1QKw6VGMMtnLESrQQcE0CFWyimd%2Fyh5n4h4OcfVg38ZyTB7rJ%2FPGBglV4JjOBWGOqECV08bKyR6xRwKWrhOntzGtt5jE9gJ5nj6n90XbnXZwwplvTKn55Wr%2FeC1k2SckZQ9dG0%2Fn37pLOq8DBlbW9LgMkCHotcoTHBAkM8Ow%2Fx5Tgk9pgjl1%2B7SGLbRS3ToFZbsbnrBMbqWkEM2ipcxTRo%2BDCbzpBmvve60Cn7Uy5IkxhMrNzGw%3D%3D",
  "University of Illinois at Chicago": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Illinois%20Chicago?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=xTSjvpDtdnKEy8etq8MPiIbiatLWZc362RdZu9PZJbSTgVa9CzYHtnBcjlPPEjeqjKDTD1nA9ZRxidB8Bc5oSsaivu%2B7AV%2FdUuTqkhKwWEWu2UyhW0Uqo8JmChqzbLGbofNmy%2BZbZNYGssXZVgDa6H00zetOjBOemcyLRXXBQ3zgSha4i6xT3ZKcxHWgKweV67BlAL9am4VHfkE6LH0PRYt0eTdEwQWvvxDQwbADwmaNrkU67pHtUftMi7NOVDizGi4DdePp8%2F12dzmZa1NFPmp%2B%2FyyNb%2FLYPQztciBY2s6TgVxyOztIHRfzMpX3NAUevrHOkSVfrbFeam%2BYUn5o2g%3D%3D",
  "McMaster University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/DeGroote%20School%20of%20Business%20-%20McMaster%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=m9YXYxH51mmMrMyguYYhwZIhl4Y6dEziupINug%2BRcYB%2BbME7KuGkc33ST5%2BhKQI4mfqggAi5m01uz35ez8zbOAXkBR2h8Yg2LtK5LqePhnnZsKt%2FAb4aE253ReeJV9vyy5%2B4gAxI404rB%2FtxvvGPuosqj5nxbBYq0Sj5RYLYdVpmurtfjmiTkHtEIiKYK46FTnNxgeumCo%2FyXevb%2F8rkhhqHUD0X5U%2B2G%2FfhmIcdgq4x6oK9Thj65oiwf0oy2u%2BvzfMwMTtstyAeHW58M6vkyNy8TGELysjC6EGmrhS8HukeILDear73m0FOjri69ppZIrZR9MqojYmguo%2BxDoXAUQ%3D%3D",
  "University of North Georgia": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20North%20Georgia%20-%20Mike%20Cottrell%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=JNiDksC1%2FumstB%2BcBCr1taSPG2GGOkF3cksbWFMKX4UqQlLKgrO9RKw9xFaCzHO2tUJzL3Gdhh8Nfde%2BUk9nOs%2Fw2NmQWS4%2FtSpETugEy9Br0Auf3EeBaSKQZePsq29bjB8CjTAbAWdVNErjGviTQBRH4uwOgnrbRkzqnupzNq5AWpXAfedVQ9V02E6HrW0G9Qk7CLgIAXOw4OEe38I3BG%2Bmn%2B9ZaAPILaIFu4E1hwmiu911G2eqqqvj6M5kD01NPzi88DhQunyJuFMoK00yLW8pExlRW%2BqQbofH%2BI%2FVdQQntr9sf%2Fg8c7b%2Fd13VU5rPOw65kfDesvTgdOeiX6jLXw%3D%3D",
  "University of Hong Kong": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20University%20of%20Hong%20Kong?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=W3snyGpMoVfhss0GyM%2Fd3gD3GG%2FcdFCOegGqKtJNk1ML9262VbM1iN96RoHcSRhWNn8zk4UHxiu6QSJ%2BjRWAiBod2Sm1MeZo3f9pDUiFL%2BhzqTS%2F2DhuOn%2Fn%2F%2FXYPPrAmuccEF2RfEflxgvpZtJGfkND1EISJYPdklRQmPAxw9TOP%2FjIz6WCuzmOgoYO%2Fy8%2BwrbHWvVdTZrpnXHsFXV1rnE6BVRzGoDtKA4r238KVkjUINwPI5ggoCTX8ugOLBp2xIvGEmF5%2FaeiPO%2Ffz0fINieZkY4pETL6u2k4QeMEz9KwWnz4au%2Fg0uh%2BLUteVsjNAocho2Xj3YyHy9OPJnxLZw%3D%3D",
  "Friedrich-Alexander Universität Erlangen-Nürnberg": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/FAU%20Erlangen-N%C3%BCrnberg?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=maYDOu2OUUqkmya3JP0%2By%2BKSmFlWQDsZByp4SaxNdUp3SIKI8T7ojIZTHllCrseymiAyXq%2BXPjITyIfAzKErv%2BtThdBaC1YurmdTJx8722rZMSPPMo8SUyMmUSf3kDplNqV5na95Rsk3OXOO4cS13znYbEqZ6YdYvN879YKiUsr7RPCMb4T5qBrDhiqDtdAKtUmdREnBTaDNM0krG4%2Fi2ZPFP0pIwYZH1Zq3X%2F9L43wIj6%2FHGCb60DfRmzssNobPnVi10C71WEBsFw192PqSme%2FxotMXRtrH5chrL1SFTitCnPmDhXgUSDp%2FahWFQHPm3IGCulWjprQm3dlnaYhmnQ%3D%3D",
  "Pepperdine University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Pepperdine%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Z7YY%2Bsjfvqcy2YTSLfqQk7FM2v6QLZh1weivH%2B4e2lkdYe7BfsU2k3ffFQMyc5L7lHew4lZmuMBlBXRMdI6aM42TRhplvJT0V7ZoG64bEyiSqSDTzm1JRuCH1%2B689VNMyx%2Fegu00L0ka%2BQHnTU%2B3iqkI%2B%2B3jjvpw997nmGl175FTcO1G2Lsw0pcwFTSkbvSlW2IqSRuiDjQDC42qeAEB8g6zxh4t07mkyJntqpfqaZ5I7IwXBA46dobTC%2F%2BkR4%2Fk%2BQ1Y89Vp2MrunPtcC5Hou3LyuHVq1IrY2vE4au%2FFefnNW9dwIO96bNMUMOB3tMgkysJBOYPMvb77LrKcS8%2B%2Beg%3D%3D",
  "University of British Columbia": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20University%20of%20British%20Columbia?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=rYXMZeFwIjWdtb2I2IXgzA7%2Fg9lAGqOI200SKf5D57dzam9kZcevc%2FK%2FQaZQ3CFGZpv4W9lsBa5WUkK3VW7HBg0nmgVObcrkRYdMrsJpNeHWEKHDJ335pI%2FrW0RcqQSKLRwMB%2FrvxBHoUyEpQDs%2FjhPobBJ09qb0dReOmUiL70jpfz2RkvAwfReTA884RHp0avCtzg8%2BPpribRRuBymiRU3R7Bwn%2BL7vMl63lsyN6dvZ%2BQGexsRPqXdjaY%2FYfzuSCCes6HC5bokeku%2F4JZPiyqf0DawqCgi6bleiHuV1wJkFwaaKCamubKXF%2Fv2bxtaTzVLTiIeZacvUkrjB3Qurag%3D%3D",
  "Texas A&M University - College Station": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Texas%20A%26M%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=fr6tCvjaGH0i5lFyps80BLhP0s1ahOSM7jm9BICikPpQhDIsUZ57E12LVHUfZK7BlYydullW4248hvFHX2%2FDt0F0Z7q7cxdSw4945n88RVSfxp0h39lhBmjWeXZVKXqeWWy7tuT6Yq0Oed7fSVOTIFEem59F1yY551ncr6pd81cPDJ53WUE8J3ARGxw%2FDgmBHRrRGt5I3wYCZlCBskNJQcOZkdWtLNlcHjhBnrOoLRMKFJqLFQu6aJC3ljr8ez1zrB06kOmcTQ7uHlcaMptylXraNIE7XDFbKexlKjgZPxFbzFEg%2B%2FJVN%2FQB8xEt5Hqj9XJOWWE6mbpif%2B5taviWIg%3D%3D",
  "IE University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/IE%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=H70H%2BfuXFkv9Ee3XFo%2B1QZiWGlj%2F13OZkvn84mnCbOVsANsPExWNucYrLiySg4jRw6QpjWqI4BxrBzUndDLD6glCqy8BgNdd0%2BhDSves5jG%2F5rio0jADpRHXFmt7f%2FqJ%2BQK0VoNzgDtbRq1idlnNhwIjpReCccc8vQhiGpCM3dAQcn8DZ8DwT1rNn%2B10ZQZNjyPNMLLTBW0xOYeoFFVFY%2BapJ4Uqi6A%2FjFd%2BcKrL%2FQaiexIRSjMDhjX2VlWNlPVAHKtuup4MK0jg904%2B3r%2F8Lr474D%2BUOyuH7JxTVhDCUlcFShqXc5QN4hXPd2utlj5nEKFppa661Hys6nKp5C5YHw%3D%3D",
  "University of Pittsburgh": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Pittsburgh?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=g44Cmqqcnp2oth3U6F1OrRtujy8M0WGUCgi6NZ%2BlmBjahJD8ApjTP%2FusYcB9YrAleEVIFjG8t8eRhW02TJo0mE%2FXZE8VIxWFxPEknIz%2FdqZYaToZgqUAUz3FcuZMKtpO9HlWxjWvu%2FR7%2BkFZqC8cmhzUqfDlw4FNfwv%2FMaT04SyDlfJQmSCOP8E8IgPiqEmGpOLWx7pZ7lMWog9R5yQw62jNLrfmOH%2BOXPxM2rAf31ULJ6Y1xAoI2ITUYEzq4fAD2ouwN9Kbw31q7WvB0JozPRewKt%2BVxN9jXrd67dNtjEGdudC%2F1h6FQezk1%2F%2FejqRcjIAz%2BfTPONOU7%2BgOfFcoJQ%3D%3D",
  "University of Southern Mississippi": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20University%20of%20Southern%20Mississippi?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=YG9Bq8%2FYvFsq1NbqvA%2B3uy7Aw9sSvmIDdNf32UBnop%2BKqfcMokZ7VXfOUePIcWNr%2FLPAZ8CqGBpFbnuFwNs%2BmJaVA0U94fYMasdtD73LKrarRXOrLoRTjnFX4xo0lw2cG9WDS9UyhTK2YB09qUU7QKxm0U0lqUIVa%2FAWkIj7TkK%2BvxoyFWXsbla9QEEo5JOnO%2B%2FDS8hcNm3GeTW9gDZgAalFtdA4FYmzTY8NUFhDFTkCm%2BuEymkWtJKbfh0ZQMf4v9eHAQLsAT%2Bv%2FjxN%2BwLS%2FHTYAajRpNq08TUjLvL9SrL5OmvDUBgKMSGLh8Ir%2F54FkW7F8YM9FUHcKtcvFtO%2FAQ%3D%3D",
  "Santa Monica College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Sydney?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=dcFEXTUfU2yaCBEnbAlSINUo%2FBdpUnp6p7OaZk2NCluHxoWTq6y0NObhjKYY%2FPQJk9Ju601FN%2F2xgPTkZRO4dimceiWEN9JPOCY3W9AOfgAJ%2FCZo%2Fd3Un7qT8Hwd%2F4fGvrZ05%2BvOHbp%2BxMU9C9xobpyW6ixbiN80KuxSOtXQ%2F0LnRokgPOM3hNIBR8vg5cV7dIcJa7FMHcqXhJGQhoOn5gpkrQSGBwmPuMu9%2FaB4zYwGnnyo9dp7Aep27EfTWnAVwd6VWne4VoOpZ9izsGHkrHXPTM5w%2BRljledt1H3lKybiHmntIRSi7qZ1yIe1TkTzvBLUdLcQefMgkPf2aa3QWg%3D%3D",
  "Universität Hamburg": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Hamburg?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=shVDsBHnpOWtaHluDj4dYMqnu3DPv2dw1PjhQOUDYp3qbTvJL1w6ke%2B8tsZpZ7foeMR03yoopCQxYmBcBRZAAXPxoBnNzRaob8Sm9CW4wi0YojT9H%2BK4KVdrc%2B1GA6sNb37LEgkWQHwObazTebLJustcs5Sl%2FLqzzZOUMyttf7YN%2Fc3dFPWuv4k9s%2Bny7bb%2Bg346wZ0%2FZBknZ4mzACOQ3OoSk0%2BqoxDqrKu%2BS3sZ1g1t4HDu7KjMVNaND2fWe%2BPxpyzuKI3cbqRytcgpKdskiHclOOBcD5iCADkwYs2TlpT%2FpFrQdzMzNArCmypD15FBQtQuNiid3%2FFAoB8jyDVT%2FA%3D%3D",
  "Pitzer College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Pitzer%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=uWkaY7nB08DUXfjzCUB6yYafDntSUTPQwsiPk4hgSrf2AyuNMyuOINimsMQbbDDWpUjzRukEnCt0GFRub5dXF99pI9KeFjKlDc9RmtsAEW1F67fT84hD0xYWPgbAFBrRvLFtM61zVTaKTSkO5DoU2YQE04Lkm31oe866yoO9olCag6O59yjAjVt1e6ZYJ5pc65rYwTPJL2DF9zjksspnBprd0S6ZamSJ4N8R6Wh7VzR5MOaCl5ICEPV1ii7%2BcJbtZNHZabIEtBLWGiF5QQgV%2Fn%2FhyWk5WNDAF0JLtmTj%2F5bgkXqHdknyEnHq3VlivbOZXbwq5Uy3zqYpKwOj9moIGw%3D%3D",
  "University of Colorado at Boulder": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Colorado%20Boulder%20-%20Leeds%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=yFzVhHYGViHOSkeKJbndj9CSyiqDc2z8SZCRM9n2UwfCURZ4mzZBw8XzV%2BT7wVyceOl8%2Fd0JbSoKKdIFwf6baaxtp16VMJ80r686LU29QfMbyCuIoMaUZdnuvWKS%2F710sX%2F2bdxrFLxUa9xCpLHVcfVKqEFFRt4zUyaY8J458FIsWxU4QUGyGBP%2FKQVuWLzsWywoT9CzaGYNjN0HpsswDLU4EnK5v1od2jkCZ2nK13k0UHmGJBB%2FxkrcFuGp6y6BxRJ0KjyoW7T7Q%2F8ChT1r9H%2FCOVN5%2B2yFNjOwqDnVcPVBmGdywovJ6gslDizB2FERuft23oaSKNoOHqadxilYsg%3D%3D",
  "Western New England University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Western%20New%20England%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=XbdiqR%2BvYBszVMm%2B3nluxbucQjJXJ9An%2BrSt4MCK3XWMmcf%2FOLz0tj4HEU9XyvJY2mGkMc0AUZPy0pYW0j1jCOeieWff8rWvVDADdb01bCRSkW7WBiL3%2Bk6jEiEde2RwGehIMSzNFUQMUucFfar6KKs6ylgojSrv1rkPAN7qM5LgA6%2FBtJ3puYWwwL8eTSONfE1EvUhW%2FYsuwofihSLdOrxMKzGNEkk%2FD%2FcgAjGohx3%2BmD9ZSipHl7d3nt9KGH55WXWx%2B3SJ%2FkK7FSa0omUu3a45HXIB28%2BVzicSC2e4%2Bq2uuQzi%2Br2mKH7Km7H5scY2QvAzR5x4gKyf4iCzSXTPlQ%3D%3D",
  "Lake Forest College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Lake%20Forest%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=kRBNLFWzJZ92VsFP6IloyBjxS2DAHqwdJDaFBpwAa8nPDgUeg0J94ij29eTMAC2Arz0lHk5pXMtpz11BJoDvo42qy31eV56Cr3aZGUiLhKhdqOPsMo%2FJF623bj8OVUA7V307h81KaxNy32NN1jgoKEJmtczmKCRwmqNBVaDGxsx2B%2BfRwhv2%2FQ3oFxMWbKtJ7rGxC%2Fk77I3rJEoFP0vydYIaRjero%2BeHPuC2nTp7ryyEYn7kma2EnG1%2F7cc6jlQCiinTSR02CHQrVsOt7jxgWCurMfIRoIpd%2Beu4EYSkOIyGxU23V74ja1EaXkD0P8iOnyiKrKb6RqnTp1av4mIogw%3D%3D",
  "Institut d'Etudes Politiques de Paris (Sciences Po)": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Sciences%20Po?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=C1K0g3c5S1gl90OscLQc08LUzdXXQnep6YzLUGGjTUF1wVW7ChW86WXEMHw4L16ht2fRUE7rNdMX0BHjnfszqnqAotiKv4Kr5b43JSWUUyu2mIZRq0FquSTqJsXYOJnlDeNZ0t%2BgKL0RFiph5hLKyXDjjaYSozOUjD8%2Fku3Y9X8Cjf0uKxjYpIlnivhNGdWhUUa4FIFc0qCpTIJKSgCkAv0CIhtxOc0BP9HzTkh28GLKMla2whSjDd4dbSRWFVdYNLLq74C8yTAeFR4RcuS0lpGV6eHpIxlT6QWdiSHXVfmBGuX3zZkdmNGHxsKgu35ceV%2FxdOBZSZaz86gwQkNwPQ%3D%3D",
  "University of California, Santa Cruz": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20California%2C%20Santa%20Cruz?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=WqCSW7SNbltBEXhZwHgyHSpldGXCIWnIIETn40hCfrI0cF5JAqX0FX012iRQ4x8axWac7G7OLRLPiHs%2FqvHw2FfiAKBjMLa%2BKo%2B1G2LkA9hzQIn%2FCQzJwlQTcvQXHQA46zWioG3YBX3NqqcY6rDAUPHMfsyD3wF6f0fGWsPRTnWrR9g7G9nztRwSVjT4HcOYaBIgZGB2GDzxtsixDuCxzZ1GUQQzEZKi3M8Fea6fLaJgk7X5v%2BxRCN1cZWqckXZnPlwKIOhm1oGPitctlywQzAPdI%2B8WCMW5r93tj91%2FZUEVIIR%2FDwWwA0S0tkynMsf2%2FVkvpV29be40mbGrnBOJrw%3D%3D",
  "McGill University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/McGill%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=N84U430iKbiLZ5A5ar%2Bkfab5XP7YKvJpl4CY16I9%2BvIdYVz%2BwIvV6ObYYFKzFWSpMlT2LTcKKKxlqtCzlcEj%2FCi01PsifyKuIgo2%2BBDcZv6RYqm0cOb6kJXo8vvUANRCKo6JeFIUkCiWG2mZp7bR6Kan7zq8iVmUsxoVllsRqyQHPR6vze8mrt003bDOI5qmI9%2BgOXxPLsjJjD99EEtPW%2BJRA1asVvzhec1lpW75kzshpUuuF%2B4ZHOmj69gwG%2FKuTNTceJCrLyCcOm094MnSHhYXbvR0%2BKwgJgBqwmDqJmYW3pB0IommI3IS1tgORnXrkhD7wNko6eyX3W9LRgcOFA%3D%3D",
  "Prairie View A&M University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Prairie%20View%20A%26M%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ljslJwjsMhRFIFkTnEMSxJePjr3%2FmEG%2FADfkhgeQjK0K5wzbDdGy9krz64LcVuTrRjLswHZJf9km9b9Q61DVV6MRM38HleEJz1FtjxLA3KmW95JyVajOIk4szteVMjzHW2TErK%2B0j8gBOkhxvP%2Fwx%2B3si4PARDtbCBy%2Fc1nWji6CdHxK10k%2FDzzqBwHnGdLc1HwtIsQ9D7pm4zm%2Bh2Ss%2FFlaP0kaL5LP93OMn8%2FhmDeodBvbcb44DRzniNwhxkTiiNG6mOtzFDluovSbGBHA%2FzD0rM8yhDCCAVyjj7OerKta5fy9LIEbMzduIxNUhHpgpNurLoL%2F7MEX0C4%2F%2B2daRQ%3D%3D",
  "Rochester Institute of Technology": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Rochester%20Institute%20of%20Technology?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Q0IHhxam1rk8c19Lrs65%2B54wycAL1zrVL8YsTJgCCaJa%2BtRtjGmlv42W%2Fn2qFev%2Fm2cJsnAY7dackmFmhtUayIcZl3JqylfPVNW3N%2Fh9MMrxqLPSzupCNgx3y7cFwhpu0CH%2BE53GK%2BSPQNueRLQKG7N%2F7FTsFqeke%2B37o5vXdDeuqIcNN3RYzeVxpIB%2FkG7dZb2Un0MlhB%2FZuqcDHnZqGjHprdz2I2BK8teKMlxa7udxrD%2BPkpE0gC8N0dir8vkM3EuHUGpa0DVzopkwfe0PMIx%2BEe4zu3mZtZhOlYex%2B4ul%2FYqPiT5JoBYo2fm0%2FWQa2gtuixML3CRqX7CcPhMFtw%3D%3D",
  "Centre College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Centre%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ZdN8vzCYTYN3M5BleMl5NiBPZQIu4ZqqHoW2sOrNUIiZV4tCzwrPT3LavNP5wqZcml8b%2FwjmkoJUm3Ic32dDS%2F0PdG1Fg%2FBuHJ61e1e9wpq4y3nev8tjGK6Dtxn3%2B6yhMPsHEVIUZp0Bnt%2BfJ3pfI%2BKuSlcE%2FAdQtSSam1IQ722Ehf8BZsUt40PgeeEWBw6r1r018%2B%2BJxyhO1KJuzo%2FCWJtfbfiMnbxR0U%2BQ9NN3EA3kP%2FNsHGJZT9RuD304RqzL1MJeDlWYlQmKJn2szgprekPey734b0n0W2SuVrtu9jBsrk4s46hDrBZG5tFB1ohg3vaa5DtBxeRZncYgQ7I%2Brg%3D%3D",
  "Wilfrid Laurier University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Waterloo?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Fipey17RD%2BamGMK44gDSkyfq93pLxKbRTOwo0WnGQevQj7axpJoDeUMMPqCKAFUaFT2YobbQqG%2FlBHUG8KXLR797YR4k1%2BmDbi%2FxiYvaZI01tEBsh2DkYFt78qYeZ9qrYYqYWu60eRXWuh2BRPwXDnT2mEFdEV0XhHTwVcxBjnnMg9gMkAqyZWM8kA%2FazKHIvf5Sfax7z1UEBmwdO7dWMqTfKySwW6UmI8YgRKTt%2FVbQcSF1d9rAV5RLTqNpMu9u3V8u8m9CfougWqWGWxOtikUin5mO7N3BAuvJK7B4TGTUzdsztLORL2Fwku7ktjuZNJZYqH%2BA%2BKvWwHXKIMtZvg%3D%3D",
  "Texas A&M University - Kingsville": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Texas%20A%26M%20University-Kingsville?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=kaudQFv8vN5MT2GlwCtxwiunBFrEuM2h4yB%2Fpnc%2Bckm61LwzYjWFZsTLOcZiXqA4%2FCIFiKq3VUqjwkvGvXAx3074ozt2p8t0cds7GtWW3xQuu9R1V7ww04GmGjcz1%2Ftz2mzijj%2FjPQr6VwzJ2nCx0se7zICDKBRQKy0euMVRcbWgrY%2F%2Fc58DZmPMo7Af5ZD0gb39Hhgqv4IoT6KPcvEPOhBdpZDdSuo5BLRz4ugdjy%2BfNKQhrdzxnGKyaTuGAbH%2FaDc5njDIYEabHipl4gSmmPk64KzCQ9Hc3bsq688%2FP33Oe2JA5LqgMaCri6gzxBX4O%2BfjzNsLRG6ZtHUeBisJ%2Fg%3D%3D",
  "Kennesaw State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Kennesaw%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=turVZU1EhWLQxRY77ilsAXlciHaPqIa1HfqjSJcN9GVJoF1iEDzKb7Rpfaxv%2BIUDF9sPfjyYs%2FmLbAgELLkNSUAi%2BjIVV675Zi6qI%2FJpMI6bAsTzoVIRq3xlAqhriv%2BRWTDiZW5mS9bhDf9ggjD8VvYb9PK7CS6gXLuT09qkwzonO2Ec7uHfS%2FFvGDplhejED%2BX8inNv55stnaQBU5CXNx0Uy2QW416e%2BgHbWa0vIVXrPiK0xjzqMKP2jFDBd9%2Fz1s8E0Vf%2FXlmjQGznZf3yzSBWKRRrUnGx4l3MVLZ8wLoHoAj0ROrH3FoZixEWXiI28y%2FJeEJgrmrs%2FE9VSJKGwA%3D%3D",
  "University of South Florida": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20South%20Florida?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=BjFPs5h%2FlmdXfK6RlBkMgfpFCJjxT0ziKW%2BecvF7o2hMZHydwZPVAKjd5ilCO2NtXPeXR6ZZGHH8Y5EADrsnT583qemiFNW%2B%2BS3ulVesFUFrDYny8Ufj1E861leeYix2rN21Lv6DM3OG0PvJ6IVHC4LGXl6ccBeaMvmJ69Cs2WEvoa%2BywIukfI3iD1WQ%2ByE6ffgBYrNs9ls4Kt5h3mK1OcJsHiWiDAdzSSb7Xp8z29wHYve7WDMpKcxG2Ti77HoTPb0jHcGu%2Fdy8f91iQuR2eVUbA1yP2xPHu2VDAP4pUS2zWvOTlWawU%2FNfzbRLP5MfIp8U2AGtONX57UZGfAHELQ%3D%3D",
  "Illinois Institute of Technology": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Illinois%20Institute%20of%20Technology?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=oGQtMz6iOUJQv1z%2Fqmh2TlRzTHyk9kZy3gV%2BKKGeD7tDqAV2ocKYx56%2BxF%2BurQ8G%2BWPlQaDTEBudxRO%2BOArI8te3H7NZ4zH0PSsDRIzoaUcU1BdguyUEsFCDSvE2QmpUt5cHWXiv68t1xlaHNivIU00WvUNI8VzyUV276T3A3598wvlCh1Onzak8a%2BNT8g8YMDHTU2F70Xvla1Rpg4093fQj74p4f1Aq4b4F9slEmXTqooF%2Fvh6v7Nr4VnHIYrqtMK3xSKAUTUe2zj2DQqS5akY0UqA4x8wg5Kz5%2FALqAMtTGedUkmTf%2B7fJpC1%2BfNKhfpwFhzIoes8nULS%2F9zTPwA%3D%3D",
  "San Jose State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/San%20Jose%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=rxLCjZzzFzFhw2uX7Y2NQOK8pdulCB%2BxzSJ8yg%2BRrBiKdyPvoe83V85fjWOzZ8OhCYYa1aAtupxDcpKUX2hbigp0FlLn8XeTcMehJUDCZcQ%2FkZ3RU8AYp3yV3c8Dd%2FqW8zSaoEmrsfIBC6dvKeeWnXL56GH2TIS6j8EOF7iJkj%2FOM4rdoZm8jxLB%2FV5kdFM2KiSrMFXP1oj0I7qMkiou32Mw1JuRG7e3gbjYqxMM%2F5MSJeXbfyVED7mhTPqcCn00HgQsR%2BfxzqgX5tzDeZYZuQgwsw9MmzSeNv9RJQ46gXR6jfXkfNodbcdbMJaGg9agbMHGfxdi3d8WJ4gS%2FeFjrg%3D%3D",
  "University of Kentucky": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Kentucky?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=O97b5MQKN0CWUw%2BHe7wnR%2F6YboNdktvLH9LOje2ylp8ZncnByHqSuuvzXqQjaCD9z6HYuo32RQbHWNbP4Q6cFAhDli8O%2FLSqrfEJIZXYAUh2LltBPPCzwOrZPzir%2FSP7gi4qh%2FH5RC6Gt%2BaWqGAG7R6bySVxx%2BKg4LNe8ynm5OFk0j8mJI8oOoo2APcJ%2FMf97zYovvJzkw6myEDR4Oe%2Fs1elhARKRzxRdtLKMLTnnkvmbN10wi5x8BxWUHEdanngJ2aMoAzy3%2BHkhqxanobv%2B6KS0uyG3XmQ0ocp5sUGEiDgc46rVrLM3jyKk4Tgfd6epUEpit%2BFLC15hGBeUWfzAg%3D%3D",
  "Boise State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Boise%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=OJX%2FuBndWxWHy5W3Z88Yjk%2BtXJbBdFPATDlp%2BHET3ZjeCHrL8JUyOmmEdORQ8mn%2B%2FejZsBAMwl%2BNUQVVzmQsR7UR2xT0aB%2FvPReqOSQBLfvqSXNojUMcx3HSkKXzYcc2yluSPJinEyEBBWKW%2FPE0VbTmqoKD88ClpFgF0gTgOCTo75S6You8IbvajLH96AmDg8%2BMf%2FY1DklZ7Dx1prTkml9ua5rr3QiTIZaHSPeBrIORxXz1OJL%2FLnib%2B6MMjSzeZ4qwgkRfRWdO9HJKs%2BAO%2FFsx34G3BYVO0OzuCLonXmbA%2F26Wz%2BixVBkFhsM1VED3SmFqhVtzLvVhuKNtUh6S3A%3D%3D",
  "Sam Houston State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Sam%20Houston%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=uDOWvvqYB%2FOhm9tfmM8TrsGF6wG%2BXqzaKaHQfOyeXTpfoInqDUttH2dqAAwH%2FRw4ESGamsJkQ6Lwc%2BnowHOzD4XcDbQHROFOTFabAmIFykSIQ86sqrH%2FNEyFQRkSA%2B70Un2YiZb8VmogKNpSpeXp1vA0GDZBbUX%2FDjvK%2BP6QmHuiVO%2BQ7y9XIC3Nx8GtnyUmk1ixH4Y2F5JVEsYGNrd65QY6Lg5Aqv9r2Yo6NE%2FbJzahrQ1MCmUtdhSaks71motneqMj1n3B6Phks4kQ4f6RfBtwapFvPSQ1RBtF7AjaEjG%2Fj%2BQpdch2zghQ9G3S0FRpmYPUnQUh%2BIQzxynp0oJaew%3D%3D",
  "Humber College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Humber%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Wvlp95zAx89tHnbZbc1vCV3yIZ315Dyo9TOVc%2BgP3Wm5aL0a%2B6lfjIDHGmL7mo47mB71D7rL4mW3cHXWBmGEpn1uGq2TB1wN0%2FsocnbdpyuUmB0dPYEhF1UBqOlB4kW54s5GTEpOxCXRMJxZ8HmFAR79efl1q3Bh7Ub5r360bKjdZo3mQFt3mcody5ppcM8h5uLkPb2iykgzmkoeNC7QSzIdHHnCdhWuSO5XPnDXhgSEnwU089mm0j00ht3EOivcU53gv5VJSOry2cYAoU4iWjkrlRsUfLLdXbUHsJrhuidJ6GS%2FmH4pWlNaQaz4LmWZuDQGVXJrwmVHrDlKuvJgjw%3D%3D",
  "San Mateo County Community College District": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/College%20of%20San%20Mateo?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=QMh4Vo2r%2FMWPwTISMhZ046H%2FJFG3Imtvlj%2FDAfnNE2HzrCBhSYjZq7YrM0Ix9n%2B4EO2suOwF3yQoMxBBgoziMfyiP8refd9tzBzEyLz%2FaT6xjWUrQYGp5Fi3Ee2IpaTggpvxVHyF%2FGdCpTzeKH64%2FWyfwtb8VZtRUyjc3W54W5Aw9A03LQZy6YUW8KRmPaXIb4M%2FUbSJidUk5FsED9F0teS61evWRv45pgFFH8WSjlH7ktxbX6amlWK50QU6QaW9n74KFl82Nv9KvoH1T6c4JDyhacfsBgKLuj1ZTiVFp8YC4%2BREM1%2F18u6F415izuzmD2K%2ByYMEGCoRKbzSPBZXxA%3D%3D",
  "École Polytechnique": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Universit%C3%A9%20Paris%20Dauphine%20-%20PSL?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=vDyTAeeLde7ULHhKqw5tgrkK5Q0XTAAHzH0YBMu7r9kQl6QosjUpwOQ2SsEg0nJqq9pC2DaH40sdYKsyC8PF3Hw9f2910ldJiH8OKE4hT9OqZvtumiZemMTG73iW9fv%2Bpe4%2Fw2zzbsA3pm72Lomb0888PyX8Cgztxy3zQiMca2eLoxdNGWpmfYhYu2Zb%2FE71EqQ2mzXrByxl3pO3oqXyk4101m9vVGr9dJIkgjb%2Fg5ZCSxoB5cHv9Ucmy5gm9t%2FpbJIs%2BdIdW%2Fo2NuxZOqHumQlndLCxaM6kczg3UORmVRRleMN3u4CRQoXihVFlj8rxN0uRxH8DIlxmGmR6d3VffQ%3D%3D",
  "Al Akhawayn University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Al%20Akhawayn%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=qlhS%2F8j%2F9GrkVnBN095g1i6Z1NX0PofCzGDMaRVM1ncj5J8%2Ffoa6UlOJAsC2%2FgwDm%2Fgkp2LmV%2BcDEjDLWD%2BwHfExhq%2B48c9OS4iYeSJrV8svKDPUOTbZp6zSVS%2Fm4a1qNjgIUMQ6QUPOVxAhNIiAodMkhb%2BsLUvFgAyt6MrXyfzkMbnh3AohoEc2H1DrprmjlNAbE6x0qvfZkfkD7qleVFow9RptVX3OgfekKFmiB8bwXtl4K5%2BEiLpMhHuzqjI%2BREYZyj%2BtUmO6zOfepZbqiCyrzfC2MH%2BZWrcF%2FRkKdNP5CnCPynh7dUD%2FVhSsigdeBfdTXeFCQGGxouXPtEbUTw%3D%3D",
  "University of North Texas": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20North%20Texas?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=XBYYWnrwhTuOhoNI%2BErrp0d%2BIX%2FyOLuBoQhoQNrpbXMEq%2F6AUnYgGxVx5%2Biwk%2FMKMlth%2BoQOTvhubLOsFuAqnYbJFj9xb9ar53VqZrsdN3%2FwnFUIuC4cB2mxzPo9ApsTRJ5h8gs%2F1Bka2OhBB9IRBBngxy7lwuWsENQUrwHFaH8NW9PYKDjqYKGIlh3034D6Lc3xMj0X7nf0hqsCt1%2By7vC1LK8KemhIR3nLIoTQAUVB0YpQCO2f2v2b%2F4R38rW3nUl7y9F0XkXHuXN87ActwcPTQLzmzXzcThrMcDHmn3TWPgslaMSPi1M%2B57D3wsnZh9JgV1z%2FjmJm8hnFWAPzBA%3D%3D",
  "North Carolina A&T State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/North%20Carolina%20Agricultural%20and%20Technical%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=dnKaAiY%2FLYVcgtNDErxrG8OP6d4MQ7UOgjbKpyl8uD%2FzW0YnvSReIZB1Z9FG3LSahug9zaBbVfRxDlt114nK1%2FRC3%2FqTONC01rVrGysO7%2BNiP%2FbAV6nbYkng0LE%2B0ENhjnITxhUPEZgY5sae1O3cODA%2F0dfgi95UiZ%2BAcjluUxnboNAqps1Ij%2BDWBjkWQsaCyEXGk81wkXmh0lmhzYgkg%2BwQ0CtndTUtvvxTJrbWrl%2F8NpcP5uy5BIum92pN3%2F2hCJ1JYsT%2B4mP3S5M20wIIpIVK1fo0WsCZwbbk8dXJ%2FcrlUKwwQfn2WwSAzg9e1cuqJnbtBLs7owvoQqtsElIM3A%3D%3D",
  "Northwest Missouri State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Northwest%20Missouri%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Yh8Ni%2FOXolymhKNXaI0YIucYm7eym60h%2BVeNW%2B6MulzyuHsMlHT7oY6T3BYO2hSWLSBTfR3ROqJKcMNhdAaiNVGz1LyEtVq7JYF2w3YOOvhzViWomUxwrnZxP1mm%2FS%2BHQEWzq4gQSb5U9pLsCtsi8txNF3Wj01JbLVZMoX62Pyo1YRfn5ypKMDbGc2R7wVKjKOPxFgCt06NLheCKQ5O%2FX6zc2kX5XHq3rYnmCwg0wCOR3kXDaXP%2B3%2Bff0SWvjtzeq%2B8K6Bq2v7zvpk4ySLkCaGbpziJUmjj67py6wU54y%2F7rbmyY%2BSQLy221yKxw4Zu5p%2BjqWJnmVJZ4GZo%2FcR55BA%3D%3D",
  "Florida Atlantic University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Florida%20Atlantic%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=rTthv5W9j5sjumJLND9CTLTn6%2B576oEfkO7%2BsO17V8O2pzQxoIQCqNExMjIlKbzGd30N2S4OZDGtgqtEraf04vEwOqqeG%2B7dYzZqiIm%2FAc4T6JNDKmvyicsk50%2FPEKyerTwIxV2VRKQfmgxRwmRfhXcH4CwmzNxBEklzOVQbctDGrOus4TwRlF6H7QKmwGAeRI6iiyyMqaTqv3OJkNNvdVFFGLA%2FzeV%2FgEutwOhNRpSgtSFlK4gqcaR%2BDkLIfytFTfQ7EDeco9934NI9hyLjUKnbBayiGGJPACvKB8ps%2BYOB%2FRH%2FUO0NZ0k3zobBa%2BNDyRJuyHmlud8RetfsTRMFFg%3D%3D",
  "University of Maryland, Baltimore County": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Maryland%20Baltimore%20County?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=GcyrqK0Aag1EcpH4F6BfM1wYBECCW%2B7sPS0%2BESx0eqWbDnZP2EN4gb1MkqZ4J2dvNeAhXDYYIZ5A%2F%2BRnL%2FlpJY69j9GYJH6A7yEOBRrquiLTQJRi17LXDtnaMdgGnNc41EJg6BxitQb3gyd%2BdywrON4LiBY7yHl5svIkWpGjZsWFU6AlBNb40B6oE5e%2F9dxkYp0NR5EnYMsj%2BGy3hv%2BqcaE5BmtJk95LSSUpE563QhiNKKD2O1u8MUrhR%2BXLiMvzBJl1rn9qn%2BNZfxG%2BMgQbNSAoTz8jhsYvB06JnOrkoXvwhvHuAB%2BdsZcrsahmIMm8iFnGGjwCydRX1ne%2FpvDzTQ%3D%3D",
  "Brigham Young University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Brigham%20Young%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=lyTTNDaE3gIJepWlRP3Rr7aXWjoZyIWXR6LL4km8Eh%2FTZme1K67%2F5VjIdsMgUZNkQwTOxVY7GwwMhdu0oRrOzEo19QlvfuBsdZEMZeWi%2B4hopAG3avGyWmQrT2YaEptsW8V9U3POrPJRALwXeyZo9SkK4lwDYB0RRC4u9wkkDyxxTG6jvYMDgS9VMMdewa9OCyrB4qktIkON6%2BbFGgE9FM5TBEPRdOnmSdgDimcfC0NWbP1zAk0yiWFn3%2FEnnyGvusbITvh7bqkXFefOC1hjE9Xi100rRdWdf4gqcn5tvH%2BZSvrNTya495w9yDQMvInojA6eSJ%2FiHC%2FDPhgEP1Ob8g%3D%3D",
  "University of Kansas": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20University%20of%20Kansas?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=jN0nTkyM5tLemc%2Fl1vJZRAL2FiXoB3%2Bf2Y%2BC77jPmJuYnbJDJkUAaKn%2BzjlqSveWCm07ERW90aikID9wtQCBz1qa5%2B70zZ8SOpOo%2FsKf5wt29bsOeEeTbXgg9xag%2F0EFnVBQ1781GE8UqqvDHYuhLka%2FsSWpzz8OfWqidGh%2BEyTj7EFwmz1fZ%2Balnhpm4LwclVRhJ83QPKq82bsbncps3QjrXGU72NAFmuXKSE31rBN4zix0lW%2B%2FoHnHSnvVHEnt%2FVUGEQQwI3aWpTqBhHbP9KNemaWfzyGWEr%2BhNdJkU4Cf2lNbDdAxYbMJDVBfDp1oa%2BchIVVjO%2FchULVyP9%2F1Vg%3D%3D",
  "The University of Texas at San Antonio": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20University%20of%20Texas%20at%20San%20Antonio?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=cllslr%2F0MVDJfweug1lJnLH%2B8gCJLcVaaBA7NKTDc8aV2FX%2BMNS0TOwo0m77zsEbM5SuELo%2FELicX3MPqsZtv8Fkkt2qeGet2FwwkO79aPxwwYoVwxumMLozVzba89N%2BESk2AwUUYvD5R6sUZM4AuEZXeowNyUQG49AlqR7MEvnbXFoB6X%2BLy%2BSJ7AacwCPl0qRYTxEQnJr9UgiXVgjEmkgNNFA%2BaC6uBjMmccEXUoSj6pYiOhSo6W31ctoTUDvfz5rpZAczIskG77UVQnEB0twfI5Axz%2FKbS5vb6EOjOWsu3ybvyegVYDZKCzIWhVY%2FZdwxd%2BiA5QbD%2FCY0PpbLFQ%3D%3D",
  "Erasmus University Rotterdam": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Rotterdam%20School%20of%20Management%2C%20Erasmus%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=eAREPv0Dn9TVa0L1brE%2BUo0wXsGEf60tpdA5yNwQg3iqxkS5DZccU%2FuoQWuK%2BN1zJxRjLQSknyBty1OWGsME1YO%2B5JEWOxFDj%2FZU%2FaBdXW4s6MRwnAt%2Fm2TTPxgpbVll4SJAIvJtD%2BEEfbUFLq5MquFRsJb3PhLY2PZ%2FL0N64DQqc8ydMUO2V%2FrUFK1rEiKcbF89IgNEQv6ppavqhVVUoCuR%2BgRMFwqe9%2F1lX%2Bv5I8qbEv39QAraEGfULuS5K3mSULPVdOvuHQvBcF9MTbmtuDzbR9sqDPYiGpnmb3n8wXZR6LzzS%2B9nzlVC97aSMtti78U6120EjexDGNFx3Scetg%3D%3D",
  "Reed College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Reed%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=w7YZg33%2BKnM1LfXWru%2FQWgkQtWnkFNxwq4yLTPVcgjj09ULO1rsvQElQ8T7CP7Lzinhtoell%2F4ElPdITfWAzFVf0nwkppIWgeznMNZ%2FsU5zST6btXthcW6LITVWpmgzWjX0plzAiszhR2V2o1ptk%2FLJ96rvBhKRvLkbpWGR7GIA8veeYtmHK5IGzNdH9GDFP%2BXbbYJW5%2BOK5JzgO2S%2FVORJ7IB6pDNM1ML1lxfZEsR%2FK79KXxU7UjUVRskKJshl6hSVTk4cuH2eyktsedQ1%2FC6stme06ZKeNPhaj1Y0OR6%2Fu1P78SHRK2SGjKKvc4iv5c9NHC%2BtJG0gWQ4Q3IKAf2A%3D%3D",
  "Santa Clara University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Santa%20Clara%20University%20Leavey%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=FCuSNBZV1QY2MYG31nBMef%2FBEz9GowZ8lwefDTlZ8BjbUr7YemPuviQAClB8RkhTK80%2F8lzuex1CTXK2g3XnWDfzgk0ZxkoiywF3jYeBIFxsNg7Ki%2BcJib0k0qniG3IHCyi0MwfCr8bB1SiEgqS8d38KAYYdjkFfwJZDkBFlvxjB%2FPBWEDHONXN88DTdqoAegNSj1%2F5KXNqCqOJyqYhorqPOdagVPZY9a4vCmmzPhWPe8L5LWWrQUt%2B6UUWDj9TeaJYJtLJEL6bBltA10xvIbIyI8%2FGwQwI%2FNmk8XkIPPFVZRVWazLG7Yu8Y9Xb4W%2Bs8gosDOfxWJQKtfAhLz%2BHxNQ%3D%3D",
  "Bryn Mawr College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Bryn%20Mawr%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=dUTrGotQROixL8%2BG4rOa4uP6btQrnnD3p6sP%2F1p0qIdkK02WOCD7opeN%2F%2FnRoV7CeNWOWaeMgN8gbsRy4vN6mxTX%2Fs%2BiKr3rcCAzHU6cMMLADwXBKQJaT%2FHl93FLahSvCnu%2BqAZup0obSg%2FWjXacPr60oYaRLUi0%2BcI7xz4CSdg27q9xhmyh09DlTkB7xvpwxFhgxo1AjsIDXtcR8c22Z%2BqargeBBqz6v7%2Fk1P1oCatPKs2V%2BUcnRjOlrNS7zwUy2Cua%2B6v4qjwwFEjNUNdXuNBlNlzjx6i8yPOhV9uMIHuTsnbvsLAWXjGjFzlKMDtIkEKM46KuEjb84tuwUsng7A%3D%3D",
  "University of Debrecen": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Debrecen?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=VwL7Wy3VB6rRXk6YT83u2hrRQL4M80veRk7MW4mrz5UBssRgCAf8EqDxiY33IgYkSA8GTWgNABFxgPHpqpSY7h%2BT7wdBxMBPg3oG3Ylbk6CzvpaVVw7EJO01Qa%2FayXLfaiayhv7i9nf3C00bJ5JtaMPel6F%2B5Dj%2BGLrUOZJ72UV5Dwno7VSIu5bvAYIiLFXalgQX2EEP4AZuBoWKSEQh9oOn698ni3Z6aEfwb8eZBKwrQU9fN98UXCinGYwdH27SRsTGD83mpKNe0BEjSM35fm2hZqnoZxnZwdqRasityNsZ2GjNSAGq6%2BDiZDVhW3hFhgds%2F0pos2yXG6QeDgXMNg%3D%3D",
  "University of Wisconsin - Milwaukee": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Lubar%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=etSWwVewSW%2BSwQ75uW%2F%2Fw%2F%2F7GmrQXPEKBJao%2Fq%2BKcNRCsdX4QtISTvpvtf6aOx9vdzfvFvGzX%2BDzSbWXLLABrvmylxl1w%2BhH%2FnzeLKH6ZY17kc6lyUg3npsbmdBDpkdgUNvbrtx8RCgB7FjatPSHsTcVcoPdJ2CoZHAYg3VszOM01o%2Bn2DXoSK4L62NhGjqUtoGuEuqYCoCZuU4xoQxgvciK0YleiQ0Ur%2B%2FqaYzEmSYJhRx4e3JxhvTcjImCi1WORzGoNfJ5dGX9H2WubURz5ti8T3D1n3daI%2F8uhI8AySmyWe0yqdyDzcoYS5BGxOlFA2RgiqSi%2BIg4Vj3VhNbkxw%3D%3D",
  "Georgia Southern University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Georgia%20Southern%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=GOvdmUrjrwZn6QlcDwxQlAd2xDRiRjfvSzj3b0YH0Ad%2F%2BxC6c2MRB1siq%2BScParYQhw5%2B4CoIB1mb6hQTCHzDZoTATUs1WX8SJ3DRgXkDHMC2FoVQLq3US9ZoWafAUaIYKFi4CefsUJsjk5NhhUO9FGqjZLBqIEMN8b6Jup1%2FNjXZ8J8taSO0qB%2BG9CB8lQGnU8gYuvlGIhXainkDt52KDVRTHhFpqFY8%2FLowUM%2FHBs4HmVW9FWyqBsCWb%2BzQ7s27nz8kfgpGKTMsYnU1M9EGHyUUHEG7AVPiziuwqfb%2BE7Mq2XY0PdcsaNwhR1tpwjdHXqBBM2bbcpvxtbcLJOtIA%3D%3D",
  "Lafayette College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Lafayette%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=MEts3hBp2nTwK3saCAoatWKmEuGElLdsw0Ip2h22oLgC53mdwKl8ON1tyF3gZ7RYn4%2BACLVfcnXINw8Bu8R3D03J%2BqtXmG8d9S1nGIoL5X1UgdQjABykdwt0oexhsdOUa%2FhZbJaphXm%2Bv6XDBqYrl%2FUoc5oyhcXhn58%2B2uKM7EbduckskjUpFXCDq7x%2F1gfBiLPSTnmQHaQX0f2edPpg5RfhwvfWZuat%2FYj%2FJJmorltqYAkSkeEEjzU4I3ANA55i0bq2uUGooBMR9GAqCbDqmCQTT5W6SRczzXIy7FEy11DhaD3ohiUC1zqj2fiJ8FJw2EZyAMORVZpx2gOxpeAjNg%3D%3D",
  "University of Texas at El Paso": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/cathedral%20high%20school%20el%20paso?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=LKLjwq8f2MNwnuh3HwbbyqxuLDyx03gbWDSjoyPu2lTn%2F2V%2BmWCDcDSV5abK6y9f7BZR3Xz5e159thi3j7PyN6y39Eku71OEzmEjXuSh9zfikv6%2Bfprb%2BdUqhBeeF6uh8Ix62ectm%2Bs4oxB4YWRtfDyhsRvBdRN4CJMwh1eHUldS9jbhfpZJnuchk4qTey0l%2BniZl2BSw8rVqeUA78qV8Z4%2FgrG9MB4jA8AaH%2BHiNMO52oZCPWp8fMXzSpeMQBEzlNDMvX2gAz4PccYomTRfvmB7sH62MViZIz9D4Kbm5pEP%2BCvVl820pXCXLDn9UXZB0VE%2B3oQyecIocvKmHhXJHQ%3D%3D",
  "Ecole Supérieure de Commerce de Lyon": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/emlyon%20business%20school?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=viT8TNG%2FdK0G2pZQiC5XpVtuF%2FRJzVnUn8yNM0xRnYBWmL%2BKUAWSZUeLEVtVvZ8cSXFXDlE4qCn%2FSHEVBGkWJMLtTqF9fmTebta1ksKEKPFM9J8u6LoxHOlX7HXxMc41XO4s43RUzRp9CH3jSvgBG%2BGXB%2BGiyHwlk1HCqFmLOQkpdcqFfwyUkY88d3LhNT7Fy5sBIVq9qwFapjtAD8coL2Hh3E0RohqMD5gQVUU%2F0aaderrwfesTkXoKJjeVmmydY1zBqiCTTYOyqUB%2BMbQh7YjJsaKR26j8LbBnxsjQtYAEMeOqA95yYx30Nn9JXXf4deo11jtrv%2F2%2Fvbl9hg%2BEdg%3D%3D",
  "University of Houston-Downtown": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Houston-Downtown?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=jeXEvxNRzxIEIZMcM%2FuIHANHg4WZUaBFvxTx6fFUsC5EEFTQtQTUj%2FlRJjVEX6%2B4pQeTwfTEx6AgttsKbSwu0UgmI3OrabNZ4bCYK2phgPdLYrHXGgQCzMGvrYy6IUFmBCqSyENWGF0VUvS27Wp%2FPZrfOtZcCpQdLQ%2FXc9iHa1HCXXTOlvzIkafrQyZ4RgxNb9OgS3STidDd7k72n4M5FTXQAB5sbllC2b1uVkpYaPYiytJeDzg77Zmxzu9xLPguw%2BVjNe2Y%2B3U9Z3KegiTDBjqvYqZoCNovFkZY8mb84sKLyFAGfbeZRhLx31lQY1YbqLhY39tvqCkZfAMZOmXx2g%3D%3D",
  "Suffolk University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Suffolk%20University%20-%20Sawyer%20Business%20School?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=oq%2F6Ec8BlSvbXbaEf88ftDbjLWMUCeQJ0bYM0lkKoUvDOXr2BMpA2u0gXjKHMkG4x7IyhJs78FIxMVt72ajI21eBqLJGGUnaqp87Z6zVlS%2Bcq9F3uYG9dh5nvIH8KbV2KF7U3NLRZrsN3YTYG6dRlofThLHBKKXaPiqrS8jbvewC2X5C32we1EmbvCq17rD5buoxHu3BJ4qlxGIatR8WKzWiUa9X3vMkKbjVI73slFMOl%2Fgr03lSBM%2FY89GqQ%2FLnL5PUFPPt6XqPsbNjOOjncmLgvBvcaARTRErQg31O1HvkYkxJyLPgZlqA2EuPGTIOhVNUNo6fL2%2FXwpdb%2FD3GCg%3D%3D",
  "Ecole Supérieure de Commerce de Paris": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Sciences%20Po?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=C1K0g3c5S1gl90OscLQc08LUzdXXQnep6YzLUGGjTUF1wVW7ChW86WXEMHw4L16ht2fRUE7rNdMX0BHjnfszqnqAotiKv4Kr5b43JSWUUyu2mIZRq0FquSTqJsXYOJnlDeNZ0t%2BgKL0RFiph5hLKyXDjjaYSozOUjD8%2Fku3Y9X8Cjf0uKxjYpIlnivhNGdWhUUa4FIFc0qCpTIJKSgCkAv0CIhtxOc0BP9HzTkh28GLKMla2whSjDd4dbSRWFVdYNLLq74C8yTAeFR4RcuS0lpGV6eHpIxlT6QWdiSHXVfmBGuX3zZkdmNGHxsKgu35ceV%2FxdOBZSZaz86gwQkNwPQ%3D%3D",
  "University of the Fraser Valley": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20the%20Fraser%20Valley?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=j3WaqAkAexOhLYJ9arsca6UGAQwIyDvDgMbzzgEkwa3dy9TIUCDGMYbYtNEKI8teXtg1QskDUM58BHbWZWLENP5bCF5pQt1cgbDBiyzsk1s%2FWCpGkyZ0PgNjeStYb4d81iQjOl1h3%2FPl6ZxCcw6MK7nhxQ13WCAI4q77Vt%2FoJkchRsqNlJJnssCv4FO7mH%2FB5wIzJPw9uHVerkztjp%2FZFlJT7PNBt2QBr%2B4UubKd06QLMA1mefv%2FMoXG2A%2BHCQuFz1rhSdKGahFK3QLLpfyLRnjlcKAVMcXia063EXsFJfTaJZHwVefftgdZac7w%2B%2FbqYi2Gf1x0TNuklv5EM4CaEQ%3D%3D",
  "St. Lawrence University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/St.%20Lawrence%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=BehnB%2B2QqTrrXQ153i1gczfnpujvQrRQHkvTpTz9eKVrFfMopIMkBDhkVIXVjMxgpMZh1rHsbZN8y8CalGrfBQfGTYeZAo5gG5EX0mvH6xNtA7zu4hf1vzgOHzlHar5sMhJmXbxWUjRSKhmepjGJTDsvZ8s5UnzFLb4VmEN%2FMJQ61tvGFQYFrpxAFS3GsKARavXM6i9Bi1MJy%2BF1DiTeCNMCqs%2FoiOAfZ5VZaScr4fBy4uXUSzW7gBUDMtsWdaMLMqmfzuqMATD%2B2a8FgHdRZUdX7J7hQGziCJM7qfDNCcjMelek9bLPU8iADmzeBScjTC9rH4AwWuZkQkdYhdIZZw%3D%3D",
  "University of Central Missouri": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Central%20Missouri?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=lLFg0uK%2B453kP5qQsdjOGfdRjFyCX48OpmehEBlQ66yK2f7O0YEX9aHbliE7k40N0RmsMamHfkXA4d4lj5hZhOz0eZT%2BWdChXDUSJfBOHUGTi3NHSdT%2BNMEild0ZH1vDS%2BpWCDUifuqCo8kUpOf5VAtaJ1meCY7Y8JiRtHgWDHL9Gg6QGje6%2FXu69taWxgnOvUmwsBsM%2Bx264XNpLZoBrAdIl5%2FvTrv7wA9kL8PaIsob2jqY8Ke3%2BfVPgBBxcI8JULY9BaA%2BvI08CtLoSWSR6WZTex%2F0qX4ldbvGsZhchBkb7A757TLlGBUNZfh1WXrbNjh%2BB3qF5%2Bt9l3BTYMxrgQ%3D%3D",
  "Illinois State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Illinois%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=nrM%2FLcYvz9YzHDcwY57Owps1E1nGYio5O0%2B3yiFvPDytVB5Cq3Xk8RVvaMyndYWqNTmF9%2BQMSQS%2F1%2FfA%2B7XBvEDcME8gymhAJiBN3v4XpocawLQSPN9rKQtYmcPIX5AXyZ2iHiB%2BGzJxZnMc4bKkX3pslzMViAmkYHNI9df0X7%2BrBs4pnbbhKLSNK4BBhjlB4glL1l3S1iwxjW1U%2FMowrEVeR4%2BqeWkKGwx92kcXNA%2BbhQJ146zRXRv8mFXkkA1BcrP2oYssROOGvJE6DQ1WXr2RAivsMr44MD%2BpfX8bPufOWKB72BwBeYb20h7bGzpf5rt8na5GA2OkZMiMB0HFBQ%3D%3D",
  "Universität Regensburg": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Regensburg?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=gYmTvfRxks9cTmh8ItEV%2BMRirJb52ANr3ChEBsaMPEntSg0zSTtToH3FxB3ibTSiZiUxfC0WFTIIoosLyUyWr%2FDDWWZdX1xw3Ra540m9QenMGupCl7zLkF%2F%2BcS%2BPe4of%2BCSkdy3Mvskc4RWnXhjWE%2Bqg2EwLdGK9WtuFnS9Q1KtOrjksgjdqPJjpQV2b5zULKoXy%2ByJITQDcqDNc1zLxPoaj0UznMIWm%2FgbqyoSmwuw55NHXCZzqEPSVYOY4GBVt5zPuHcNX9YPvvImwcSeFY%2FMO4F2bcT4PKghQvn%2BZzD7BDT9juZl%2BaUm9fCdqxX3QDlfrq4VgqNdGUJEeJ84iuw%3D%3D",
  "University of Delaware": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Alfred%20Lerner%20College%20of%20Business%20%26%20Economics%20at%20University%20of%20Delaware?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=hBm7xZJoSf3J2zAiWdATSjZvzhjVKPHtEfr0vGhHG4TzWztnslygtQZCrzSLvsEFlfL7%2BBa6V4UwwRlogF2qFnvV0H2Cog7Cqto9BCg0xP6ChJATyVitFwNgz1nfANlE3Nqi40mfT%2Fga5m3Pb6EwV0WDATqNCixWaYmhVko81QRqFYiLF8R23LZHhurhsIRybwisDXuxaf9oqy2slZM3pECr0I7o7Z5j2WpsOh23e7Zb039rXBcGaHEQN45TMq%2BQ6NUwyPauPKgCmmNEzbOjw4YOJHS7JvWLTGP4Oh6IcNdqgFHQOCAdsELt9nXNHO%2FX8om6Lj1r0P2YqLlHzCRz7w%3D%3D",
  "University of Alberta": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Alberta?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=f5cCo7lTLZWNEqsYwBduI%2BkGEpAJr0wMFMC5b9G54siTyUiScFsU87R5I1N7WqA5zKxCgAp6fJAR%2BrK6r4rzpECOL5wHi8sD1Sy5n77%2ByIDKm8SxNmJ38C08LQ40QuVDgy8pKSOh7CLn%2FiPKei4ASOAsgBYFxnF4QyKD2kyGy%2BcEyWa99WYS8VfwZreLlLn174M9147%2BU7g2pwgKmqzmgYT6QwQIOVDomIFSYLy9XU%2BYWTyeZTbQDdlFsAAeRgC1X%2BRaOUgt5Tw5yA7iYPfF9FtYF3KyM0D1UwucPr1BYw0ZGBsJtKNXbD6HQYh6ywxlAw2O1EgM7osJG%2FSnzyTsUQ%3D%3D",
  "Grambling State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Grambling%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Snl2M%2BpmiDxYWyq2L4IXgUmRT3nUjUNxrpvJEz3v74u6njO%2FpGF1iLD4j%2BozEaguKLmYxuVgW7SIT1%2Bfe4zBA81gg4JAO%2BhZzWJgD6zADecD5pWvoFrkV1JQ%2FrnSHHApyam22fFrEZuEeDiL18eeVxmIHkqKWowzxtUSOAUhQyqCXdsXXkhxl0fTP%2Fc%2FAyj0qQu49x%2BkGK%2FSXjsRtCamLyxD1JPQ1vrPT1u%2FUv9As%2BQuiWKWcmpzS4MGZAhM8x8uS5RB%2B8DVKsgmNm0LX%2F9w3%2B5Bu9x9ou6lNdoAZcg9aH9OqT42utc0wmQ%2BmBIUp%2B3lKOv29cgBhkZb3HsLcgzUew%3D%3D",
  "University of Hawaii System": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Hawaii%20at%20Manoa?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=jJ30akVo3mN2UW2W94yeXXeHdt23ZDXyGf7V7492QXVY4dJk0Z5VPj7SRywM47OR8WO2a2Xkk6vGMgCcUOa5ea5Y1ZHa2di2Ck4uzEpowj44d79o%2Bp1g3Xp0GiNS6Dn0pNRjiQtRLlCMG95GUlesNpMr4JRRzm%2FDRmuuW6t9wuS0QmO5IV04mGYu2XXTuBoNHVMflstAAKaDzc75PMY4C8i4XkpNkmyBFkK%2FoAvS2eO0xb5%2BNvuhxDiOuHpa76aGEfCUPs2%2BzoA2b4j9P8FtQdw6XrPj7oMd4P3YE7%2Bbvf77nXkCHdAr4Qc1MWhyzw1XwJMsFCqnkJh9IdLh%2FFwofA%3D%3D",
  "Providence College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Providence%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=eXc4mE%2BNvHcLmemxSnnkd0pOTYUKi0ejkbz%2BPn36RUEXcTFA1JvRRB1SHZbTxGTnkirVxD84LpSs34Hai7gXifAulD5Mre7qApn5mmQ1TOr%2Bnlm2HvkW4cbC6bvMDU9kvVXCWxbaPHQNNXwLtHMBA2hJeqU3a0kRxv6H9HzXhUAjm076VKN1uZL4qOAoZUPQajhwN9nBx%2FUpAuuyFFvcf9PB%2F0TqAZOBRVyleciSnBFI0kyAQFFDcT4tuzo2SYTCwh4higLRumGV5nexiw0q0a1IZ5HSIazHcSntj5F02861TTU%2FBaIlPE3M3MkY3xu7qKBL39rh7uBCqzcq1FZ6cw%3D%3D",
  "Manhattan College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Manhattan%20University%20?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Ktjq%2FL2gszh83KTmuCtzLVg%2Bykk13O%2Ft78UprO2de8zjQs147m%2B07d%2FVJvPHOnsi%2FJJaddWnhhKTw5DyefFMn80brK7xNp51NeAOzcsJj6TKaZzrIwK2BMU9BUFWYpS26sun9TJ%2F7XyrwwX0%2BG%2B5QVPvzgvFTm7J6b1rfV2dD1hkFE81aLyrRXL3Z2egPwT2AgDTJ%2BBx1AQBdZyJYeeV6Vagu3hapiwHvSIgq2JHbtaNf11iK9L5qwJwVrA7R3SAYDFOSQQek%2FT1VX17S3H5yt0E7f54Gq0ZqGR2mijMQNCi0aUkXJWdXOsJgDMhW0MQcCPn5KAJf6SqMVKsHKu1jw%3D%3D",
  "State University of New York at Farmingdale": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Farmingdale%20State%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Gz0OLtHgYAlg6OVrmI%2Bn%2B3kQPWBjP11ElZJilMIUNvQStjyRQt0bvCGdxOisdQa01ey1u41o%2F809Zb%2BnUR4VedUNqcnqYRC6inRjL9B%2FmQhYXI%2F%2FlIQKGIbneiXlVyNtUsz9yY1Oh22NI7%2Bq%2BhMbuWC7YskM0etEXudgL2hSkSn%2FkLV4fySOp2UcotC%2BwtYbY2kJe7Hzgw9OQo7733yyFn4cqp9quLdMkBtSC0%2Fan3PeJNj4mRRNAYcAt6vtzR5a8jJHKUwae0PL0cE%2BTZP6mZelAgaFkf3ZLtnT2uvtZCw%2BK09o6Qljc0dnLyX7YYS2TemKgrlCO216TDsmc63X%2Fg%3D%3D",
  "Southern New Hampshire University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Southern%20New%20Hampshire%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=M38RIIHEis%2F0I3q%2Bwwr1LcRYm577EYNyTuFJUkMbsMgnVEUWm1jLj1Ato%2FIrDt0d0NkSDxdTcJXkcBPcjZ4SVKgV8FtaCupFP5jnnSyBkI0SnydRZa6hofZMMZBL6SbzZM3UAeb1rpbjWfJ9hjQ8ltqrEL13QTUbdgKLVWZlln6gaCRjUSaCrA2WxBqXnyTU1hMScOKN039mCWHwgddqf8w2x9fhh7H5rWa9UsuJxx6Zb8WsfcP%2FB4vDXekzZX9gVKLCibHc0XRLfTOylBWjlD1AuJ5VZRTfEYq1FRNuWI6%2FS1kt8WyWsLGZeZN3kBPt6I289V%2BMid9A13aenP0Xwg%3D%3D",
  "Monash University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Monash%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=kIx%2F2Jy9Si%2B0Mpe1RuNzdaG1mAVB98llbVA%2FaH6vRSjiH%2B9oyEdXmy0KMG7D7QaFyjogSvlPp1R10CJGLnSK7VtLuQJ1BiLOvmuKJjs8roIDcehxEK0%2FT9TcL1Bw%2FqJGX7djowybBRDCDuNsfzGmQyQ2Z8dyaIY4yVM0lVWqzi2KKck1ldwYfw5%2FsR3bFNgDAUiPpYuoPQCLtz19pXbxCMX0LpLjL1UTjUC%2FFWbAyNILz7QHLJfKpELa0BvjiUOrUzranmn%2FoYdqFxXcFaVLECQsQqJ0ZdCkhIvLxaSsdG87zUB5OyWBHsH4210IXJS6zbIZiB3hCvSg5ZC%2FIinoOw%3D%3D",
  "East Carolina University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/East%20Carolina%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=LWVhEpXv%2B9Mb2mAt4H%2Bd8E3%2Bt7M6%2FHjAkGnujDafvQkM1wiYmDhJvBEbVXqmi9Q0QUYAJoQcq%2BxJQAIX2qSe9SR7c78fYCCPV0UCKQ6rlHhYGhEhis2h8p0R%2FbD%2B2m1ZOVDErLRhMYtiiX3x7olFx794vInlI1TEy3VvWxTaqLdt0xzHyinO73gt1PVmHtG8mev2ISRrnrXJi8vShfS8dW%2BB7y2%2BJ2w3F94%2FVDjeLaJYw8LAgJvjEuKhfk2xu3p8pGGmNCKxgrpj69uxhXtA1LaRy2HKnvjQ101ez1cMtZl14nFA07pk53s1wlZPTqPSfdXlYUMoO%2B1Q9axGBqkHnQ%3D%3D",
  "Gonzaga University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Gonzaga%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=uBUZohdMzaGs9WSMXnpMGhco3ymnB0gI7LE%2BZFzlN7pgtbqb38K9qiqPvWMoUrpwAjvSUKy%2BGKpq9J3WyO8kVFnF0my0a%2FKozMn0zahendV%2FIDd4DACV%2FQaWusmYS31Ot2HhvqdHbETHu8TglGdd7JhNlYV1gmFXzks6qD%2F5o5MWwogmjq%2ByT2H8kSLb7VbqSCgWxiiSj2HfWv4tj5cZxt4%2FxcMoNSY4X%2F4r%2FOKN6GrjnZSdiD9BdJ0AnE2pq9xjxuevQrPE2gRq%2B1N7LOFUTGFOYElq%2BZZAF%2BPW0XJR2Lg5GvNmyoMEYxjrYjnh9x6mPkvM%2B9t5L1H684r0rkDb4Q%3D%3D",
  "University of Utah": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Utah%20-%20David%20Eccles%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=azFNzG3JtDhY%2BDkW2EzMAz7%2BBlVswLbrAfKR55%2FLQDBJvHyFgcXZT1U7SS1Be4OnUROqjNrkEXp%2Bw4jxLW4tuyL1ZpPruBgqa2RAFIPbx9VWTH4%2FJkftkXVM8cj34sBGuEtFMUzPt5mDOvMmdZIThmiWlgaDgpBnfVxdtYXvhkUI0Cz5bQugqIxDOCEldLkH%2B7Pm%2BZEC1ScKSmluakHLmIxI7AQ7vvVYAtXBOpG%2F0lUWMWJTcUU3rHMxnY%2B5uTvHpVzQnrqdflJi9d5GEOyL0e03jCi%2FneUENsTF7L%2F3wRUYzCa5Jvrwg31pCXjFD4w2Unu3SMQFV3HIRsA6Qb6RMg%3D%3D",
  "California Polytechnic State University, San Luis Obispo": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/California%20Polytechnic%20State%20University-San%20Luis%20Obispo?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=siLjdR5THcaHsWXoPSuKotOVmHIwCl59IgUIiLf1dVdSs%2F0NRmTaYULN2L6pRgvE6z8vn099IyVi%2BJjhVTF7tjevRN3f7cGszWU%2BlQXgOwnXPh76htqlIQpSZc8mIuxi4V9I69tarFdyyhxk92v%2BeVDTVkc3JFDEa%2FX5psmHDgWfr57omf2XcjO5ck6KhClqb0AUExHj%2Bq5KHKAAp9VvqOL8M66UGlWLaPyjoTh%2FIAgSFAI5EObIYPOYuYlLeQxuvF39fF2%2F3Lyqh%2B3wj8azuWitHhazyWQKdPyxoMGd2A%2BVXc9jI339bQ1yO9ThSIwNWD7AosEbLVTX6MudTzYptA%3D%3D",
  "Smith College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Smith%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=yH4b4XwOdT7ph%2BO9VUXJGPjxzZ%2BE8SFXa4fVA0UU%2BtoxNdvFKL99Gdf8tVOlEzPevGhpIuiYratDz2OxEnixole0Qqg0r%2F2W9d4F6Y%2Fi2BKONPeWzX4Sp1%2BfU%2BUWT9eKWknjM27q5MQ5Xp5KtVgU%2Fvx%2F%2FOkkMuCFU%2BIYjrdvMx3cQsDgyfUReK5Q1J0X5aIcDBB50HHaRWBSLJ0jEvZOtLzXu3tYuKQO1Ep60f8RMOhr3jrOSuUffvSOvM7OKgDalFyW7oi8K4fiUBxyQmw078NX9WWg0%2FjHN4dmaxXHhtTPRXdKB%2FT2k%2Fqi2JzFNLr9NSGLkCGdraXIZ6HtHGPkpA%3D%3D",
  "Concordia University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Concordia%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=P2irlvMGwNHtuRufFCgDzvYdRjmmRYgpO%2BKD6SV0qDpj%2FuU%2Bd5dWMvYtH3Hd0hMCBvwxT4Zs2Bp4fA15wWCCVb1LSHppRwQYnq6hdMvWp%2B%2BI8Jc1KetYMy2g50QIUK4zKFcCgkWCfqB1SybQOfNF6PBTLD8SRc%2BwmUV8MEe%2F4NxFKN7J%2FeZSnezqal0odLqHMcW0cUlhegy1lZvesijihF%2FjerkplcD7zHZeb48hCfmvxvqafcD497s4GflzhuUchczR7OpWIwfGHvBYtQkjo8jeNFZFppajIEOdcw6AGSdjDfY8KbXHx28DCDA6BJw13vHqqgxNW7hMpTQxCVGbNw%3D%3D",
  "Franklin and Marshall College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Franklin%20%26%20Marshall%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=E2ZVmkxCZXOfbTvEkRP6FxIw9nhRoarhOXpZLjGbhZAuSYrbFjOkeAQcgqjwpCgvkk7t27NjL8vLZhNRBEYFS3pFgcG9VLkkHj4JmKZgvmQriJmnZiaCmnxyG7eh6cym1fvlNthxT%2Fz28QhT0s4JOLRZG3fhQKiie2nCF2EhJtPxkjSGhVkKSSGvMKypBjjZekNOC5%2FDG2DTFRX%2BqF6lLg%2BKaNvp7CMHiMCCKRhBHe7JZTQWIzKTL%2Bk09%2BG6uixtg2jSyJJwCmdNNQAYC0aKDXwOsck2No93caz%2BdlajZSuC3OReS4q0Ph1Xxo3%2BaSBhw%2BboE8jGlH2Fc5KVio1rDw%3D%3D",
  "Mt. San Antonio College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Mt.%20San%20Antonio%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Mte5QMTUD6fD8Jiv0MFtyZSoTB9kz4mBxBfC4hDm4CgvJSKxrTBWR4gDKdaUeYJ8yas6OYhhKGonBtJYriOrQEC3bRi%2FKpqveObNM0pB3vYnEpA2fYsALjjgeY%2BAk8WMZIk%2FULCKx%2F4msjK91Uw1s1pO42LDLE1ViXpJvzAogP%2B0vJMra4aDc2Gj2RolllVgL2MhAqLwXU87LTfO3fsEE3JotXiuhq5R8A2D2znDuv2CqdHB81RzYTJgiXlTrFPdy%2BQG%2FvNOv6Di15cS25qEO3fhabGN2WAgJQn617rZGLgtz8l2wbqSAkLdEGONXI%2BFWtk2Xyu4zTDnf9%2Bg7iVuOw%3D%3D",
  "University of Melbourne": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Melbourne?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=vkx8ffqz6xB%2FG6yGk2Ri5WkxoC%2FdJo7F%2BYw3SMTf0kPDeV8ouDbuQWAtLZKF17gKa%2B4x9ZasYiSJm%2F8gR5TD%2BvVZu%2BDw%2FR69Q4rEKnc%2BJM8eOtdU3sfALJAltuGeCtuZaU7jiiXLRkgFYQW7me5aFAZk3FtkOnN5oSr4V%2BZljsuHQRv88RzxiTU5U7Uotq37zXpObgIATJrY%2B2%2Bvpkq9PVVHQIlVevrxjIqokjeifJ4ag%2Ft9i5rUDo9KtDXbTgFXhQjEx0SrKKPQmfTfTXd5eGikqZFhJhqAxmpi0i%2BYCkj0Se2ZZIHUL1niggpfGcWBcgHHw7%2BQuZ4ZN1TGvvBp3w%3D%3D",
  "Pacific Lutheran University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Pacific%20Lutheran%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Pw3blXIXianV6jOjQXut00wAplBboCJgx1omQzg1tqpOKlHemXnib3wi0d62uU%2BEieLPfkfzhIcaATDdbWDCVzprp4tSyEuw5Q1hV1nZTHQpANABFlsNRsfpCQyCZ559lMwnF7qShkEmZtPDuquuRbG5KSXMwUJNWySC1241kH28SpeJPWIGs%2FRkMqKd0tANAJlTz7hztNZyjKyqg2%2BZ5uXrj9XDXLsiv%2B3XGq2gXm8nYr8PJV0xrlnXb9hWqkNJZ33G7nhTGKu3%2F17pdnXKdnW1qsJ%2BoOYXGi%2ByhOWIj9MtyGPSI9%2F%2FBGB8NOVtgr2R1dxi6XBzct7uqNaIhIPJwg%3D%3D",
  "Haverford College in Pennsylvania": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Haverford%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=cm6FrB69CIsQ4kMQBRkgHvxnxsftntUXaJayD%2FEBaluSHBUNRfeBAOeIttEAQC86jcUn1YZTUi%2FovQ4y8CJESyngkY9otfbIlo8%2FlT%2FhKijX2nBKbxvEgcUEU4knFo25fBelWDn3L7M05CnSrPcxz7TXAx4egmvOZfikXOD%2BDKyDw97A4LdwIe3wxvLhJgpbl9jWIrXNJYbRp7M4WB46XsCzJVxb6Zw5fzZsnv7WN%2Bgy0TwaS97i8tVIm5%2Fu%2BRW%2B4j39oTYE4wdKrm9xZmTp6nOYsyUDE233%2BsDGCXLbvo6HiiHyPWwplD38RvmWYxZOj%2FMbnY8rNAFIcpAYpaT8ig%3D%3D",
  "California State University, Fullerton": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Cal%20State%20Fullerton%2C%20College%20of%20Business%20and%20Economics?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=QZreSiXbd5FriHCXDiSZ3QwjQBR2VigAJqyTK9v7wqV7Ih8Y82qMlnomeCW36dsACqfkAsQL64IHav0KZh1yro2N7GnH1QPtB1Yj2j974B%2FI5fZu5EucO4%2F%2FPg13p71RigzFeulyPIDb7UUhz1IZKQbtBSf5m9ivpTyk1w1%2Fn%2BtayEojxh%2B9gQJKx2XOPGdygiVVeEEtUbgF5GJv1Xxm1evW6Z%2BldVdsrN8Cr5qXd%2B%2Bc54Bq8bgTBhNI9le18NgqjE%2FTYFpd6mLY%2Fgtn9XmwR%2BaP2HrBAVLI2osIsjvRqzI5NEqU6kiH2CcSB%2FOy69Rnbv0NjohJHF3NLth0l%2Fyzpw%3D%3D",
  "Yeshiva University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Sy%20Syms%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=pG0KmPPA%2F%2B3YMg9Q3q%2B6%2BrNOdnTP3QNKwv8xqvNtJntmf8TiYOTsGg9mhw%2BqVX0MvT%2B5t6z7lxPxD7LJR84jpV4SRvOQU1FIQVp2Hym74VtdyEVFNbn53srowIMb8AcACV3OwCyBeKXoxaMIzZofV2Rl0jLYqnn8XvgYbFkx0TovDps88coF0yd7qHRbtfCpoq1gazfaGJFAhrHMcgSUVkUbz0dvmalRa%2FzMrep5Sn3X%2FxyZHlQXXTyYgW7KyUweCYBCXBdcTKW720kEAmWaeq7rRoAmbTc3d9XOTco8OQatRo6g1VLRzV6ik74nz%2BuKfyUDVAyA7o0L6Qqn0iIZ4Q%3D%3D",
  "University of Turin": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Universit%C3%A0%20degli%20Studi%20di%20Torino?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=w3F04Wa%2Frz1Dt4s73Qp0bIGXrliuGldUlmQD%2BfERmgUdKv5JkZF7iWO32yYIk2CeZWvx8g21HLfxVyBZtHyz%2FIMmOwZ%2BNqtZ0DBFsaeUWP2bH6g88p9Spg7sju9qEUhietp1HShsOrVeRqYFdmD91lf%2Bn3N7gs%2FLCoeJHpdpMwEg%2BzBr7g2Fgxrnsg6qioS64F188eQYj3dD3O8aSeViteHyl4K4FlKhIgSz3Us%2BXuvGjfjm8J1xwFtRAsaVGHRyVeypOxc7%2BZID9ylwOeALuPthzPukqf6K3H14CEtW5M4txFTBVoXROmXCEIak%2Bge6tbZ%2Fh6NKp7btaTTIQIRbKg%3D%3D",
  "Macalester College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/UWC%20Mahindra%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=butobXK%2B3qvIiVaRoqPFcIoNkqB47%2Bwuf83NENBke%2F3l4W0fXz2N9EhxpicA6%2BETOUl0%2FGDVu1zdL3Y850D4mpoKXWu7cOMVrorbCL%2B%2F6HqZHjiw0mPv7HVT%2B2XxYftBNp6fV3rpbByfC9QLirAauIsyTcSxemjhSB0OM8yOPKIXkS3NeO23H%2B5eubAkQiIeTkWlWMcKgE54T%2FQpDqN6wxfiX5o1ksE5taYVoWKLawcFKBLJju3f9luuf99vOX3CqOArM24qX5hi6CbN2PlaWKDKSvelBniNk97YRTvG0HNMR4Qr5FfGrrVThXzM%2Bge4R7rHTJcibJ77mIgEG8af7A%3D%3D",
  "Rowan-\u200bCabarrus Community College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Rowan-Cabarrus%20Community%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=kEORMf%2FVwvwSZYj%2FiYqTkzFwRdy1XAsTRdzCY%2B0gJofUBto0JZPu2a%2B9SFjGXjSj%2BhqNK1ScxSBPGd5DY3%2BBHMYDaK%2BrwaxwybTgdKqgr%2Bvev0heHf1IM7Uzxf0ZWjQs88lApuSzmrEv1aopPb7Z4YEDBrxjpRAY%2F2a0tKliFRuN2Gq93u5ecoVEubtqkeSdMhToLbKrc9lomhBgCwqshnLOAKizMK9lkUHsr%2F2A%2Fpvri0ht%2F%2F2NOFOQfiSAyCgaUmuB7XGe16E33%2BsKd22%2BA0vFhVaU5%2BZuwWypbkw9qZP1PHqK%2BVwPSzAHt5OvmdOVrApDhw6UFj4Y4u4ujzMUAQ%3D%3D",
  "Occidental College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Occidental%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=uT%2FzEnE3MlmYfHOcPds3UtMkKGcdBC%2FyHxVZU3qM85EUrio4tce6u7DQNTwHWJu0bJCZPB41hVFBSLeoNaCxNv9P8GhJi7L1eIyqIs7rBmlAceEGvgZi6P3RIpjPMoh9K1VUGtUGoENevU%2FxUeWa%2F4Eb1MCLPTuFonu2NFp5pXhMcAehyoWDmop5bfcIOeWnPp%2FFGHdF05yAcCnciprt2AZq7tUnoe9LNy4PYLcM7ThhCkjDYM0pYBGmVRaTWofbyL3fxs3cYZL9j89ga620A2mxEaAaEWGVY28dBpgAVmm1Xl6BSKIzBbliIw8DRBoTP8dmDA08Bk0Bfa%2Bh%2FC0%2BBA%3D%3D",
  "Grinnell College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Grinnell%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=s3z0OkNRgxTZ%2F7rZiLSyNwAXJIO7LhzjG3At%2BvASFrCsi5wwZ7N%2B6NC%2FOQyyxaij2ex7XNqf6MmyNM4StIyUoBuLFlPFUReGFRzktCaqyIgljdNmruQab0CTCFMH%2BrDg2KXUrIUUl%2FbIIFn37xld9hLPXcYhkFerVMmIa6eCz8QCRhrkLIA4aEJXuRF9slVF5K6ghLJDQpuhlqT6qYU5z%2FlBlSO9R%2B%2Fq8xDPAfXK3aj8OHb7LQnVxqMu8EmjAo1Q29BdMoK8JnYaLchtfFs5w%2BUUcw%2BELGLRmqBO2xzfz%2FJ2Qv%2Bsba7lXnr%2Fcg0R4QBG0RhSdv%2FDOgJW49%2FJ6fztCA%3D%3D",
  "University of San Diego": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20San%20Diego?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ju2fDfSNJvoQW%2BN1DTX5QDPp8fnoTsBlUZ4QVs1awKsNb6RGrQvNAWaWcuH%2FAFQULSSSHVV5djUbr4vJjKwBDz%2Fki2Utq49R9%2FR2SGZ6BioXcM6kZXvfN2dC3yN2zkLJXDvnTRrP3AnvgLYtuGvVsOLVVHEwOFMqojy6o8r8VgM8vDpp89QNQGdi5TfX4zSxVMHQ8e3MR07yqPaN5BL%2Btge1uG0l78h49eQ3EyJG1jymiVeDZoG%2Bb0uTORLIMIM%2Bd9cI7Z%2BDjVzXJQO1Q%2FBjJXXwU%2BduGWeWuwE52Spb481t745ztqH3XnBPrtW3GoK%2BPg1AiUySH%2F8K3nfoYUc%2B8w%3D%3D",
  "Worcester Polytechnic Institute": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Worcester%20Polytechnic%20Institute?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=LMcwSvtyKN5d4Azn2dEclZvohVfBWjtdKdDBUX96sACwHc%2FHUCCs%2FQBIibrw6yPK3K2aVFpNUt0PDE%2FnukBbf6ctJuR1S%2BEwy9WyNludhPgwBZHL6mk6TmO0HDIMssszmUfC3b73X%2BoDDndcGjDNGBZ0H3LYyno%2F9q7l1ATPKWKSPN5XZEn9TtkhpdouMxUQXAZzAXwpOTC4IueJY9wKezcT%2BHCtYKdEj8EaHh5ltPFB6gN2TdxTE0TRu3EUdK6Ia0An04%2BNxEke9RMfSl8aAoY0O6SU97UNq0Lx6%2FPAai7ddUtIOVdquykjVpbnAJuChx4N8Tk8hir1kja0P9yVPg%3D%3D",
  "University of California, Riverside": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20California%2C%20Riverside?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=fV7P3%2B%2BKehX3fGlzJQjvCalRsSyBfXGmcCG8mn7JQ6x0bEyCp65WIDqBYl%2FUTd2YQtD2UBxca1iDwOFFdfztaryrLiUC90hhaOwGSUz5dcD8KWiI8Fj1suJmq9jTkyN%2B9tgNlVOeSs5al2kay5gDGVxelpV5eTjhy6vmmtvCUTaNQZmaAFXACd3tQDEMd6IO9R4gMnJx18K4ssP0cPTG3T%2BperaLFiO9tQjy6P8hvBSHgN1fNyNd4mYaybkJOgLluvy%2Byp%2B%2Fh%2FyzDsUMu9hh1c0eq0wFbmwMK6Dbsl2QV9eZ1A1LpZEPVEQttlo9Y1sZIZcI2fZfpTwMAg7BdWDtJw%3D%3D",
  "Butler University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Butler%20University%20-%20Lacy%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Xyr9OPXkl072tVmCl1xQnwpILTnjQoCw5Gas0WofRMjCuMt6Y5f79%2FpUAGbMDfHcr%2BfBWes0Vj2gWFUgVBXRjpLx%2BSBTUqyvzSrBWQJDiAS1fMxFf%2FIZOxXhjy5a2SVBw1XD3ragYtTqApXlUZMqMx1Ei9D0WmAggZYDMoTs43u%2BWWoorwLxVprn6w%2BK0Vv3MJ3oBdzXNl0WiDnSGn7KyM3mb9uDWICRYttclMBneuguU0AIoC0ruZ6IPAF2rMMIjRNZyxUAf4RtrK867%2B5keA3CBDIVlEoOhjEEVmWKIbqNtT9BZhcYto%2F4fTopoasPVmjRPkUY3aTc6vB0Vulxeg%3D%3D",
  "Ohlone College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Ohlone%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=AwnOOnzDpPp8Xo6r373g4jmxYS6J%2FBnr4oamLYaEs%2BL4zBI5gx69UivgmV%2B1fWE0rd9Uwn6RgVDKyDrMGjm%2FrKoVcGQRYSbw29IAon02CsJ1KogoMlAE0F7bREoZ9Uxthj7fA2AYa7wuXzO42Z%2BtbYLcEpQS60S0N1HVMAY1nH5IMkw1etfL8KLs09uJSqXIaZ3HCzRE7DHD7t%2FSss5umwKWpHU%2Fn8HxnixOl9O%2B7G4EEAF9uztNKWxJtQxZPl6%2FbzxGndnc81d6B80yXAE58h02T01DCQ3RZjt%2BWgd0SRyBzJ5OGJ5YNKSmgIU1xi19mLOw3LvxuZkMGjJHIX9l1Q%3D%3D",
  "Rollins College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Rollins%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ez6%2FlRz0WexsIN5CS8L9%2F2WMZynpQnlciHg4bt5b90HZ382fAQpsecA3M%2BgEpNBVFcJcYpezql6wHnLy%2BzElxsdsVEK3NTn2C2xX1mRIi0rSkGoESx48BxitnY2wpEJ18Zro9qjK%2BtGbjz0xOvX%2BdrIfCn8kXHjxe8PxVa5ecUAEVj9CfOIwPZIsFhMTd3iQ6lC44nL0UGhjwkWqXEvtIYp4KF%2BLaXrG6QO%2BXrSdZ8So1SrYemDXOqtheUJYZ24LPdPqF5%2BJVl2sCJ7qwgFWC5oFWbX9hFI1nWTVZsv0X6JgPTwjlT5J%2BBZ8V4go7mCK6Kkqq8cNTehGlbKBx9X42w%3D%3D",
  "University of Arkansas - Fayetteville": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Arkansas%20%C2%AD-%20Sam%20M.%20Walton%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=EkXYXLJehg%2BSFWwVxt%2FM7pUGQw5NAXVJ%2B8iyJ4g9EkWyxjSSe%2Bd24z%2Bx7uWBs2NqO0eAqZkSXpsxSkFLoc%2BRxqKrwRrRKDSc%2B5buT60Tkq0PhyEze3SmSxj8ijfEb7ywzI4fJV%2FzYKw5ku6bZZlKif5gD4NE36%2FdZOSm54Dr13wUNf8AwTk5e%2B%2FVxlrvygFyETg%2FTDeCy9a0ToSYC6yTJgbAztuC0pXg1iyz5CK3FvNbvrEWOeSK18uouHzw8JrcQR1c93In2lseIgeFtEHyxZpuoTlEFMM8hF6YcMh6LrijIEIce9nw1tcLAM7D0zJk6FXO2d94%2BbqDJdzJlGpLMA%3D%3D",
  "New York Institute of Technology": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/New%20York%20Institute%20of%20Technology?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=b7mmX8l2GokXZmt3TQlPNH5vyAIOo560252B8Vag7fXlvMnF8hF%2F1HTFVrFhphs8i9eOBUJpi2dQRX8BEY6GoRdMBQoBmXYzBFSY2Rnfu8hMS05R%2BNpMESIFVzBRbcNWYIVVVB8AOU3VsAWB%2B6lu2X25IWj7JpBQ3%2Fl5S25QmfrjpVrlULzPNJ11TjAmMzrFt8KQwpWgIDwH6nMe0QmQPH%2FU6TlZbeOnYQ8%2F4rVFf0s7ho8LhfHmJg8HtcpeM4iJs91zPjJSLBwMBayXermZbSK308%2Bjt998o2%2Fx2Wo0eKmOlYzIiBKctoDmSpg7KAAm%2FWtW8bDh37nPKecMXn%2BDSg%3D%3D",
  "Dalhousie University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Dalhousie%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=wbKNMyiSSkrphx1OZ3ioiMumb%2BZB9UcGjzuv0zAFw1EBvWtzkA%2F%2FaDfxKNz24j94ss4pLezRu5QSt4DO5FRnxYm5G%2Fuf1AoTcS8OjnXE4G0PLWAJ6LEpaSoAQ2aJcTYOz99TTyvEkrkYfM5CEerdd5%2BYV8mDZmyEHwYtie2pmq8yLC02qdsH75v3Ci62JUJBgXm2nZSnpupp2114p1bufH2395e%2FeZtpiYK1KcnM0DarYbkCQXT02wzIVvfNBA%2FQKYoD57WYLjZ%2BM5CVR3yUY1FKWomvSAgJZNKNUvaxS365zhrJR9o2Ffj9sie511Lt8WLnZXmK9XLY7v8uAts8iw%3D%3D",
  "Whitworth College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Whitworth%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=wfFxCNS6vSoiURkKdLLwzdheiAuMP%2FMA7eII1t1gqu9uSBuiRVsN%2FN5vDPHd9XhD7dBHmqvKi%2BbABOtageY0VfnDKwuBjqt8Qxzc950AuQ6wSzi6HfvnJ65HCkr1m%2BPOjJL%2BGMFaboXMgw95x5z1ngGtZw5KirIjw1mqeRxHFH5LsFIH95xDXBu4E1H0hJf9A2hWtjtaAhlRqKDSwh%2FaK0DWKV6i2Wq89J7Eag0HmKe%2BIWLsm9HvrqpD1yIpvbyIN7c2B8Dz%2Fwg0omQ4GtHhBRt77Y8mcOA5lEC7LZbq1cpXx4sNg05NKQwJvDis5rvHliU9tk0NJIxz%2FOlUl6hTlg%3D%3D",
  "Universidad Nacional Autónoma de México": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Universidad%20Nacional%20Aut%C3%B3noma%20de%20M%C3%A9xico?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=lG8dlKQeWwXHUgZULmzltMs36wDB3CedYyFIAHUu59jb0699zpN0FIqduw4r70SngCwyUB6QFE4hAiuntGXAZx%2F9JSR3YUSbcBea%2BPflgRVza%2BZDvOG8ykaR0hOebpUGMNGUN%2BprCWjvA4CSfhz1kQFSQIxFE7gIYPonP03ihyTxIh9QlZwxgS0Oblk%2Bvg%2BnpIqD2wUNTsM%2BmF%2BQZud4fjSEID6GVLSfKJv6bhDGzY5b5Xd2jog7Ti6q2rSMhSvL%2F06AfgWGZCkCU5zbOYy%2BrULLB%2FmTyrYZ5Aq0CV9Cyquqk7SdSt9EksBh%2F3YMHdETfYi%2FYozZmxa0xcHLKdV%2FDQ%3D%3D",
  "Delaware Technical Community College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Delaware%20Technical%20Community%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Qr%2FjHcZABACY%2FxKd4xsnjzbkj5XgYjeHunVBfd13UVaIEHeaYJR%2FdS0Rhcgh4epy7PVm2vHA6320FIM9yHkqHoa8mHdQCKx5FETE2jlDm%2FG%2FNaEOBaiVnFotZSdpKsV6ta23lvvY8frGxmluli7SXuR%2BzpDfxFKrkcVThyIOmgOLJIaFfAA%2B0FKBg4q7rFafHe1No%2BDLJHUNC0y6bo2OLuvNSFEVz%2Ft%2BpVMkGIDho6ettdPZnJ83NBo0mseLeGyBmlZ1IV7Z2Vp%2BXE6k9llARsc8DWGgMRmxKjyexP9fZm7tAeZxNr%2F8UQ8anaE6CL25z45JPJ%2B88RQIv4JlyqCbzA%3D%3D",
  "Oberlin College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Oberlin%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=rnOPdLJMHIf9VyR0aKvUvI2pEHFwzKPugSSO4caZFdKt04sbPJ1j6Xo01Ss4kSANavfGNnVEbGrFpsHhV9vQzMf0KwMPKqo3pL4QlrLQqu%2BwOtqR4iPDHWzSCkSJ5LOQwzUlHQZ084oz6zGg8QiiI%2F2fefRUgeWyp9laD0OrpOIjg%2FdNQu%2FP%2BfNQOBhzyyNbUSkDccEwAW0mVzK79rUYHDWRuRhcFH%2BYJjaPUA8TLF2lhKXN6zuIl6V0MhkW%2BbBqHeLdkl2aktnPr3MkBsaSRdUbBmXAldFoSIkP6gupTB%2Frr860pKtN80pH5xe01JsNyNRL0yakzLpY%2FkoTsZFSdA%3D%3D",
  "Carleton University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Sprott%20School%20of%20Business%20at%20Carleton%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=R2rMcm6OFy%2Bwb5BufPu7MsQjOm5RHWwMB4fnhdG8arHQsso%2BKIVedN5kJp0UqDTnnydAxfnZQIb1K0Tn8hCiXAHmerpfHlhZgpkFH5c6Bqe3EDS%2FaSWfa8py4hfk8%2BVqZfIVjiHA7uyu1eL%2FKQqiI9DSj4Nunx9aLfKat7bmiu3t7su53woBBwd0OBrpzZT5S7Yw%2B56WKeZ8tdUrdfp6nA0hDyb4kHshEo1cax0FiwX%2B6V3zvTzvKHPb42CJRqA6GqRThDqzwewXhmrnnb1EuNVCDjOuzXEum9bFJ%2Bheak1b5XoFShQbGD3oz0ne3i5OAwLxMeUlpg%2FM11NGDFeLMg%3D%3D",
  "Lausanne Hotel School (EHL)": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/EHL%20?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=CMrQTmKQjlID3%2Bv%2FQIIsn7xDjr0n6lHa0fEL00p7G1DrQAh7rOZCGG4r9uzKbh6d7AhyXb3zx4AXufyJ0PLel5YrRu8m0FPjdD9R5myLJ%2BvONOH6o5OE6sbKgnxs6wZgkbClkWSRAC2U5QO9ZDFyEpEgb%2F1sjCW5k%2Ff25nk4kAEjbliRrUIixJ9%2FqviXw07y6Bn0WKWYF8iSgYl5mT20EhkjGV9wsALTrPA0SdoQOQkh0yJqAEdAkD4SAdVNDO5xW1O8LLe8hvM8No8stFcmKQljmVkbW%2BxagEm7KdVgW78nOFXEEoXXF2kpJK8WvZNKvvgE6LfOEiSbuS%2FXBRLxDA%3D%3D",
  "College of Charleston": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/College%20of%20Charleston?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=YkbVQw4jHPcjtrfdS2Qqd5oxzA9jtQTaKTH0QEB9ah5BfRCFizITFEzZZD3ywrKgUSwFWJv4vZlRkY%2FvDoyDuBBqu3APaZcwATlg%2BvP2ytAmE6pIXMe61G0%2FD3su80D0gRob20xaTAaoyzXz799CkhrKqDbN8P5xpwhgPi%2F6m9XbRNHl8xUPcup%2BuZkNzIDVmOdL6O23VzO1MHd9vkXV9r%2BBc%2FXwPvv2CpZqQMVj0%2Becy9BMoeWKgyillE1dPXLlcUTIjXoDhqkqD3XyJHjC0kd4sCdXP4F137iTFsWnSlUFql0ATjZmXGeZpQkH4N1srCXaACgHM91PwM5mvyRL7g%3D%3D",
  "Western Connecticut State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Western%20Connecticut%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=TpgC3dWRLxQXuT0VuXiphkjekwhU3e29IjeOJO1ok4%2BIiy7MI6tjHFKgOtl89L2ITx090C1zKL3fbc645thAfydc4jgzmxqSaCLvStP9ovBG46D5p1a3tR7MktrplAPLtOaYmzjMtYLqL2f9fOxUP40GKkrDHTqockP8L3UqqU3hpSgXCeoefHSBB6ig8wsb5uYZjlmfW%2FMwCu0yZbw8BAF5EkblDTS2Wz4j8rYdWCCi5R1HVXBZtOOa3iD4BOq8Lk2EoyIaS2q0q08hYYeXILxNeU2wNfnzqZ%2BxyQNQ03J4IO5ZjrGm4Jn184LzgxPRArKG%2FCstJipCtoIOSpB46w%3D%3D",
  "University of Puerto Rico": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Puerto%20Rico%2C%20R%C3%ADo%20Piedras?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=TFKaosCmf6i9rszRsqFyNhiGs8e88BznagJNGtt2a2qZ5YSJUK%2BpV4HQRGpjmK4801vXWFsuOlaJiRVgTukqfD2osa6Elpw7rg4ueESdybm3izPGlM1%2FFEJ%2BFcLGeSKImBbXewIfbj8bv7i3TapavwAhhLQdKqi1GWxCGcLTpWKM0ipZMQv%2FmemLAYqqEy9eZ%2FpWALaFW%2Fdmf1b8WgMpz5JwSm1gRLRAHGg4rR7h8qG2uyJFRnFpvNHyZdHbbrIKECCVn80FFrX1vk0XDhZplYB55n%2FxRQc31tGVAqx0tQjnaexR87vxHusg%2F4cQHJDdw89TpX3P%2B%2Fg8Jg85xlNZTg%3D%3D",
  "University of Amsterdam": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Amsterdam?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=agDM21Bv0D8us4PEmK%2FkkWshjN3CAcTZRZIuFZJpnGPsfcgbeywlaqnyocWXONaEhvyI2gLg%2FVTGdjtylWTKWg1Mlv1bmAwNBCY669gJKco0xagjV1Oi7IFsvXHeqy4URaxoqUfvMaKzxb%2BYwqh2S3%2BmPHso9peRWmMCpNCDB5P8D2WDvZOoK0n2eFYK4MfCTFkixW0Fx5gikiNtNpnnWKhdjoNhpX3bF6UFkJh3XbE8%2FXhYh84wJik554D%2FaRQsMjwG8sg6Wtchbqqx4IVyOnCJW2Agz6x2HYo87kn1FRdGjeVMGrKKcZzuXXrGB70K2X7PxclaAhngRyeda9Outw%3D%3D",
  "University of Missouri": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Missouri-Columbia?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=HCne34P3EQc2DM68jxdJjiyIYGf5Wr5hiBqYaHYknVn7QYN5JPJlaw%2BvCgPk4UFetfN5GGNuk0DjKxFY1pNq%2FaAHM%2FcP0DXC1AM%2FgWHo%2BrccRJnevohXFu4u%2FM1Kr%2BMokUCC6oyv2JXZTCbIF9OMeSOoHYe%2BioKT5dePC5AQ3ci3agzwYg6SzdxkQhOjYjMEwMfoE5BefCZbOElaPjzMiGqHX4Fb4QSfBawpRgNL9RZOf21tb%2FjFV2x7FYEok5U22qdPhLBfFLWL1kjuTIujbo8iZE1wplbQ9aRut6I8smAzCwpnynb6VApKapZzPI%2BzoK38cXwbAHfPoBBECK60GA%3D%3D",
  "Toronto Metropolitan University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Toronto%20Metropolitan%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=tETzMnpsaQZhWrVDVNyI3GeMXtF0224xA%2BO7Sc1KSPbGLfOBWlh6jbYw4z9TIM5AWE3%2FTHSiBy51l1XSksLVD6NCFVjzC%2BYfOCOtAK9x6zWYvzpt6lnDv3oVYUABjgHtKTqIXKBJkIfYrnNPWSbUcgXtzSBOvLyAOvCYqq7%2Bx9u8BwIMrgZ4DQA47C9SP4gpI4sO1P%2BO6esOrhEzaUeefjK8CGvSu0Mq6NSmqcOkm5PJ0oPA%2BTnYgj%2FFFEuUaSzjwBc423FsLdGRKISJ9lb6q9kB0nxfFEDtqSzKyyf0oEf2lZAsUMoPGtKDaGh8ZqVLYytHWQjHaZBsm6ULA4i9dg%3D%3D",
  "Northern Kentucky University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Northern%20Kentucky%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=hADxV0N0EAA9SA%2BCLXLsz5CxWq6Kb0kOWXQdRn4YcH8zMCD3PlHhTSnSyUmOPwFIfoFsCJP6jdDy%2FZcpO58gRVW0gLNuRnGDL7lRI4fu4lRM1y1xwir282%2FeltZz43bIUjeFAS%2F120wP22n5Fm7Li0OuuOXXdi1oCY76dHH80jRWYOldHfWamWd%2B6R92JTwh%2BY7NNcIeCXGlpll2sKlv5BDAihBejQ7Qf7mRiVSGSO2QBYMTLWPKdcDTIEiHe4JduXGZaOpnafmdK4ngBi3XPKVtRdJv%2BldKJ%2FtgDMiEHv7fR3hTsgr8og6ZrNe865iZG2XzrCkMoI%2FLWVBrnhdArw%3D%3D",
  "University of Rhode Island": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Rhode%20Island?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=EcYm3WDhFNgYjOmzYtFfrDb%2FEIwe%2FYPqGDV285mCkm0cI9GA9Z5MVI%2BB%2F6IYfh1SBMkbxoerVl0n0ty9Z7Uu%2BeW7jP2bBKFyZWBPLLs78I2n4wK4tossvvJFFkr%2FCA1jsm2VK3nexBj3TetVqcmt0jC%2BR1QQUIbDts7sR4PRsooS7hGzZG83yJQSymAil4w8gn1RhR%2F7OeIVoPq%2FidSUs32Pp3GeoJTuIPyCeL%2BeucBmk%2B7bAsJkud8P6tj7dURan3ET1i8OHnzUdpUJTvYwcTGLawnZDxMnkZplddT2eQ7wnSit34MSnjHnwrOG74%2F3%2F%2FcV7ffZ59%2FtRkKtdJTtaQ%3D%3D",
  "Colorado State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Colorado%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=apZe9orvXOu4M0gS1iaPkLiO22DUpR9457qHKLKeHGe5o17Jyj10cagRKMb%2B50W1iBp56TudWRcwoCcMYKcev0MWcGO1ax5nk22QZ6Yysb%2FBLM0yire69fmV5p7MniJpXcX3H9EULJvk3GCqMAWULrHY3MLBMvhvaOfmijJLftAEIGsPSlghGH2lEvjGvwRT0UmFvAnbPzUGFSWUlYxNg46xPhXZz48lPJLhcpH97k5%2BZiLYXo1D1nGl5Vc1%2BJi5liDYC3pxiUv4OVlLICvFbyqJ0N9mFCR1Kz6ef8yqkf0mO8wfzHZ3%2FvmJJFBI6GkEGcn1%2BLnUyL5mT6kLuweujA%3D%3D",
  "Sewanee, University of the South": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20University%20of%20the%20South?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Oygvh3E6Y%2Fl4NF2hU0vOWCXf0K6VSdCjoDM4kAe4qzrPlQyJTdXMkDGXwsYXWRiJEI1R4dB%2FY3OcgKSW%2FDo%2FFePpymSGx0hFslm%2FcRAQGTzDyflYkpVYqBzgvyVbPVg4MorDdzkPmbDIUIAcfZq0%2BnLRnBODg8pJ9UQdrHfuqC15ulclLk1IRpHE0l1pAsw1VLwBjVWeiPjh0pmCqIWjCN1JUksl4S3EKsgWC0y7FVOmMF9gcp4VSZDFAWfDXfvSs1KBUo5MeS3GZLprzuw3S7nGF3O0hlknFX9VEyAWUPIpg27%2BXEgqsFs2Fd0jUV8bySquIlvjNtx8I%2FNaqGAmdw%3D%3D",
  "Luther College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Luther%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=yZIn0YGw13oL4URwvR5wiM%2BhZl1gHaVfk7oSBGshhD2nEO%2FZwW7%2F3e9NpiwkCbtkrqnpLztnODBEPVl1fxbZNHslzDzr1gGz2BUEg%2FJaIfz5S4wWpKd76%2FQ1hNdcj7KHiTsGJxfqkt%2FOqIEh%2FCqxziK%2Fd30BaTbzeqLEslIM3UqFk0qDYRx70mJBlDZk9OvjpA2XZkETd4h6ryRdX6gcIQDopCkRTdYBCsSAB6tV7H8uzKfQ1hmE3BdtDym6g4c%2F4uRT5sreSUSNixC3ExNUrpgA1aZlAMSjDD5zlFMNJBBbDZ2j%2BmAW2geADHAEDcoQIuTyME4oFFw1S%2BFMbOcUcA%3D%3D",
  "Triton College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Triton%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=V5Gnbr0Q5ZFBZCRzfv1gUAQsEVxJBRo33gbz0EVO6m28jlv0NNX%2BYMAPbtSh4oU2t0Vj1Fx02%2FYxaUc8itJWTo3GnxTlykGt%2FJhkaqtxE1BxamBb7G8uVq6Qfi6xpFBySjiX1v5ZFcjKrQDmAj8Mq4SVG5qcMhH%2BjH5sRma5gvSs8GkoRvdKK8i51HziOeNdP1XcLpd4%2Bff%2BYcyeML79mOZRGres5U0FmTBLEoF%2BAbnYurkX9Rn%2BRbXupxJrWlWVaRyRoo05cK4WQWrThHtQ4Uc5uq9cG%2BQmdDRJruXc%2BaqhU4ADs4NyyioAC%2BTVEtu0irb3sZ3LjKE7WP1RWfTF2A%3D%3D",
  "University of Texas at Arlington": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20University%20of%20Texas%20at%20Arlington?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=KLVBs97t9Qzp2TPH7upqubSdryrLJ6vvHv6lOe9IEsG1lKjuAitF%2BXsffs8l4182FzqWI13ngogEJ2L2N2vAQ9k38GGsecHB4ZLcJhcpHiWi807RHuI6PXlXbPy5qXfGyFhtdABvZNvAD%2B9%2FQDE9Sa2%2B8WHHqLNkeDQruR267tqsBov1wQcjTogckqOFLR1W%2FnPmNokMH4I5CS%2F6v1UJc8RcgcwwOXg%2BJ98said3NfaF3NEvNGah9kPTB5rNRDSt6TBxcpJLH3toGXDrxIDVqzZZNOh9GJhXX%2FBJ0ds%2FQ04Z1jA4UgwUfUiSF7YpwAEaPjddV7AQPBVZhybCCzhlzg%3D%3D",
  "University of Oklahoma": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Oklahoma?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ZvXpS4cay6SI2EYlXxVNB4lEluZ%2FFde9q77X1uJuQAfGN%2BuqSY4IWJmSgNM5E3BgHbpDaq7UuBWreGVWwqEWhn5cqIjifIFcfmEbJ4Ui6NQXqdkwdjIVmlQgWXC6jRtealxS52Gh6wjCmbVqCkQGaq2UGCWWgOwgLaD%2B%2FOVDHyRB4Dz807Zr981bvkzw6V9s1stEEMmavDJgoWajiIh9zH6Yi8h0hUAqZncIpucN7%2Fx2emqhl8zihvIOnCU370kl7EhVChrGFkpqAr7dANawAcbxKi8Po3hig7aYKKztb7XirQnVpjzOmeBdVAnVEAcc21zu8xQF0qAjExxWkCVfZA%3D%3D",
  "ESADE Business & Law School": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Esade?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=XpoPiBzWMYINoq9%2Fi82w1aSh%2F%2BDSx0Qw9us5Rk3dE7myjcyRyUDTE%2BzBFll7OrVNpfB19RnaWCtyEQ%2FmjO0POkBLehua4elAJrSFHCVpDTlm8%2FQtEwzHR2ZjFBfStviYCNZMrJrrUecL8FNOW0SC0Yr0d%2Bi9BocECtSuKsSXgQKcVnPYghRGIz%2FLDpfa0UpY%2B7RyDMDPw0Kp1KqfezA8yA8PS%2BDjm5xGHaAQn9%2Fn3L%2Fxt573QA9hoW1lEbB4hxE4mhTxDsMQtXzOCAII6Gp%2FpsV2Mg6ZTuCCcGA7iD8v0XiTcZwpSJUpC%2FSSQSo94sX7hQvfa5JGXNknBYZ3oNPcYg%3D%3D",
  "Duquesne University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Duquesne%20University%20Palumbo%20Donahue%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=askVXTGkCFaYHLtMpBXp95tTvX%2FmwtimwuUvtLSRf4p0zIq5%2FnBUPYAy5Pm0eoi%2FnJidHmsVAgdiljIhvkJQ5oDCUyfjFmnAvSYsEPH0UQMw9W9y%2F0nlFXLcCtVzuCZux5vHrVoy6ZvPulLaUfra0z56KlRS25Zucs9TtxXkhoNVQbDDL1xj7tPRG8eNgfdviTSsedVdzZmQaEdVPkMRNF3goWL7q3dwQmEHKB9Q%2BnCQ8ohpxB5AHSKGy2m4%2BrWR1qhAMzzFy5g5A2cVaOcXaHCRFEp15jFPKVZqA1y8frwb0X%2Fi7mB1G%2BOZXm6fDR71cieDmqMBzMz4XYRTQxMmvg%3D%3D",
  "Lynchburg College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Lynchburg?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=AYoeOxRM1ywg4xlTQnefJwLfwlfqokENAPBi%2Bw%2FALo7zi5jFWe1gJmOiTG4f85TDeIgxr3kHmT9nVaZcgoUpdDBzS1Pr9RfYMfj%2Fb5Ic3VRZ%2BcJNSBWfaHu2yQaWvh0yWxS47tSqP1iGik5mARwydLuZAdyB8jSV1ciB0iSDeI17%2BNd5xsvL9TU7E7Ey57JODOB0Y6Eh9OgPiDmQZ7mhimHY3ZlU70hbF9yGeKCz0JtEQQX2QJwtEEafcantR%2Fg%2Bbzs%2Bpbf8otlPWL244l1Wwl5HfaSCrgPlRmA8IulAcTP0gyrcKxFfm01DVNay0YIUvjHIJljsBh0LTA3Nvd6T%2Fg%3D%3D",
  "Juniata College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Juniata%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=M9KMwLsMPFg%2Fn3BVp9AQ94dgiP1oRGUofifeu%2B6SKX4H9P2YNatM5eKRPZpjSm62dsZbkCg242Ftuy8cHO8sU7ThC%2BSbC3U92%2BEG6ybbMadGBaedHSpMceTfqOGPsCRjPuRa94d0UD5KgHPnTzN4s9EzB5RKSfIAws%2Bh01%2Fc%2BfMjHdq8wkxHmWyBkzr01UrmcxUov1z0%2BecyBKir8uSkFfBnCxPNS4YKGVa5AtliV86AcMhPkOqcoYDVDZJZeQAsW0l5EktHvJ8JLlUrptb5dBhtvv4wr4reERQSKnu2qZ8qPArR4ovp0K9UuFtyA%2F0RdQTXOqst3jJtxOIxnLIeHg%3D%3D",
  "Lewis & Clark College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Lewis%20%26%20Clark%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=biOFzFOZAZhdYmTQUfxLgvTbWzfhA67915uVoFPOwxaYiOV4Q281obZAEnXN0hYGNrg9TDcsmsrglhoQXzD7lzEWsRGokTX4JbEqiTA7X8RgP%2BfXrVXehP0rppa%2FBhxdbJP67QqBjOfZT7Q5O3%2F1g9MXb8JzW1knSN6mwfa18sxJMnvOf6k7yEuitC0Nuo3hiS9NkUr5SwVhxS8LAc6Owt77zyQYITQGeib8m0j6QswtFD8FSC%2BakjeIi7rXl4vfQjhkP%2FuWPzYnqK0Yz74x1xOdD3Btx%2BL%2BAviJZFsd2vZQpmkTESyOpIm7JZXSujynhKsNVe5rzh4KJ7iBROegtA%3D%3D",
  "HULT Business School": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Hult%20International%20Business%20School?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=cRLOPisFWlS8YSNrKgfe5YxebMtStYNTk%2BeI2E8hrTMmJrQiByL8K%2FIS36giJm6Xu3HNQNfCrl2NnxKtAANiKQq34qIpPz3Rs6YT1UqReOM0isR5QDcZ9NxPi7jYoUxQIGtzDHZKS4gvSOqEwsPphipzewpRftrdPq1C%2FcOEbNlMNVnnxA%2BOCuw%2FuLcQR90lGXmrecXnElelpQdm71CtGXDJbKfK0yMEIPr0sctgvpIKieUJvGJKDRYsCKYV79WdKsBg3jmCrh4Gf5PpsNBB6s4sDIjciGEqSrJ2yGgOmlfWdPv%2BohXHVuchfuqNmTMHh3wIRUOG1NliBoXFs6wc%2Bg%3D%3D",
  "Kansas State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Kansas%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Ab%2Fhwn6pUYIgWvP2Krr%2Fi0auFcRU7eEUeJshaK93K0g4vX71VmDXRq%2FiuiXPB4fNNW102vOjqglcW6d3qI2gIVv6tUN32euNXHltUZSQAAz3Atdj51HO5c1opeTl8J2xHoj%2F%2BeiHoeGgHyTCO%2FvPYUQ03UKaMPcEDsSrJAnFf9AeeLLrNX%2FuABK75cRlGu3Tv35h2oPKG2nr1QLivA7R2SvEHYJvMVsZIY4mUV9IBnqgb0NCeONYLX2bIx%2F%2FlPSIIkJCy0qkBnZjalsAvTPKvd5f3StTr%2FJlAFlMejf06k5Hk9wpG8nS%2FvIzOlTN4tY%2FA32hQQogh3jz12uiO9gNBw%3D%3D",
  "IÉSEG School of Management": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/I%C3%89SEG%20School%20of%20Management?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=tAfodhxW7cziuw6MofkoxV%2FhrM7JXzfdwyLtco7n8p%2BHaO3eO4IzGL2Ge2N3Zvsc2pbdG5Godkqdc6lcIiHdJtc6qebfS5dV%2BELih7PhQZLla6IUNBbX%2BivsUZSBK7r7ArPB6JHCiy1LYPwACcsZgguG4%2BvmhTzd%2FhgvxfUiSy5f02gYELZYceL9oGPSnuP91GzdT2ZwVN0Q2ULERmJ%2F7NeCa%2F6hPmPp1DPgap3upKAEDw2ofJg%2FTsM4cAPoXzgnGh4U8Xzl92HXQtzGpYX%2BapjPVj%2FJJVR%2F%2Fv8nF7T7UV%2FfFsTw5U2pGW8VdbHu5p0g9fg8NK%2F41GDDmKmLXRdVDg%3D%3D",
  "American University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/American%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=G5Sk%2B4yGoE4UujZLvEapswPh%2FMv8ArGLTvI9CexuUebmlKqjYJAooS4VKF6b3PWv%2BNnCoWXoxTJXynapj8Lp4gj09UT0ZQBFzO3mTWYsmC%2FYPNSOJfAgKhXj4o%2BNTh6ltR%2BjTmdCDsfzlN%2Fw3%2FSTASkIy1E7mNIe4tCaWeWMBCo5ZpYQs0B9s4efM0Spw5wyibMCFrReERNNsOD7ovlVs%2FD7EL9Q5aR4mCFLHImPaD71b3Ct3L%2BJNjk2d%2FpSOALPDH6kfhe7hNnDRqSNNsHrvZ6PFjsSaiwgo%2Fa8nm7l1uGdZE4Px4DgvNhBF13jiJ2Y7WcnvVaOU3Tk2UiwY%2BX39A%3D%3D",
  "Virginia Community College System": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Connecticut?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=x9QL0DA945GmAJBOvdTnrSWZVJcHxJ1o1%2B58OrHO%2BeZ1gz%2BtCiE5SXPzQcuQ%2Bn%2FBqxn%2BJ%2B%2BSmltUJyM%2F43e8YadA8AeQDo1oppBdHrOoPL%2BrPJRR7bcUxYlqayRzFjymjTlm2QSceuN3axH%2Fk4brpEEp7r%2FdbIBB33s6SKm5aRD8cePvd3wLl6J9gPwfJeSDiAkHqLHDtVD0mR0aDExtssVpHwVKhx7ec0Fi7CKe9KTaQTYbLu1kWdUu%2B7pmJbvMco%2BvotlhplBILUm1711vCASb9J0flJWGBFDu2859OHiQFNZSWD6EeT0wGbeIesT%2BqykWXGuvPsKapZCAYY7%2Fgw%3D%3D",
  "Bowie State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Bowie%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=sgSg678MNHHVBJE%2F9VgFYYYxL07Zle0LmzhBpPIRLFKvwgB94U%2B6uvQ9NGJN29bLfrYmmoXKvcUOztu23W0jw6UaWUF17LnPRQCfFy2kGlEUdGOJCFTp6x7WDhAdyN0xc%2Fv3YXum9GfKQXv46l%2FpaZFCeVPf46HAFLSl%2BO2dOQ%2Fk6LL3bN%2Bdrumd3Ep9usIsRbLD9m0YM673hrDQDI3vDHgu0tr8hR3MI%2FykxFgQ6DzIWM2GFYlUItwfIac1TawaUD%2Bs2T3Gyvd%2Fpqs1nXlznF9OWZCMLdKhRMcUFGvNpyABm0%2BFC7m8sak7BnHG%2FwZThlbJ3FrUqYbNaa4GDC84ig%3D%3D",
  "Arcadia College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Arcadia%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=BuQMHOiiNK2eZRZ4aWF05tYphudRJOHBK9sg2t6Oz9AJBMHQvYMgLEdUEKWtMXhHYXn1KqVgRg9fCwvUbUb0n3v8YIjvD4TycFsOZHKdA7OPGpITF5jjBvI3U3u1AaBoxRWHeJEvdIpfKZPGytS2cKPv7AI7BDTXGlKlBHIK0RiXYWJLSnOS2WgMolmnZEi%2BfIHoRfgbwU4yBH7vbUA%2FAvVis8jv%2FJNanmvDNNzjXjX1suzmxanz1OpalVQk9G%2FUOz7LnITLeu%2Fqo3%2BOstyUmDLuz7eKytutMzh0TSVjKWC6SvtDOkjXTlpvjmGjyVLS%2Fw2Hf156eYS11R05jCI6JQ%3D%3D",
  "University of San Francisco": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20San%20Francisco?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=D%2BVhh1lcg%2BVXnog3F2qLOfNe7MPT%2FJo1pJeFZgvSUDDKMIvEI8EOr9LVd%2Br5SOLO1mJuPqvG9zgNQXE6ydtqwGCEnqiK5VZSwmyfL3%2BBmfmQLN7Jly6W8dthEyz9uKDaGfqyXkN1mluBcNdDOW%2FZsJMGM6uAC%2BVLeJTOnIzGj7PEUu2rQMdyocdB0kmD1eKhuE8P5v468kZIfk5DHcADvi6ytiqw3OEGSWjNwmRf64W7qhk57%2FZKiR7z5D04fqVGDWpI7J%2Fo3nT29An96Pzk%2FiwM7RaL3p%2BpIF0zIVuo9B%2Bw7IPeQFg34MItNup%2FSuwtO4BgyZnPoJxASDMpkrqDDg%3D%3D",
  "University of Maryland Eastern Shore": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Maryland%20Eastern%20Shore?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=PligOirOfVYPMEz0MytDUxAFConkMjYUab5%2FrzD6TVYarAauoJoAtNZC5fLN7VwiyBIebls682ZoGuG%2B4qwM1srkyPCg5oUHP9Hmf3dfhj1ESZVYQR5gMoQ854zyz4mrQDyXOTJ6af1ng%2F%2Fhq5IvPk4xZlQZrslBEHghlSF27g%2BfrZhz0z7ItWLVlwgiPztVnXgDNHkrv93p0YC6YpqNoEcMNpWB2buLdK6pGvJ1nEWkD32%2BPNAKAxTZ9jaq1uB20GWONG5koA6tDyPDeY%2Fi3yq8HudycZEDLxPyJSZJLr6QcjW3dOYki4LpQrvRP4%2Bd7yaPhx1%2BBcsqv9Lw063qlA%3D%3D",
  "Brigham Young University - Idaho": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Brigham%20Young%20University%20-%20Idaho?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=O1ETnpZCjLhXwC5p2cvkyfxTHsuenz4%2FWpdH69WdyoV%2FM2egz3Qf07qmjQJfJiIMSjBa%2FzkBYcFWLYn4VeRE3T%2BdOqnyzMqNEqcxb3WUjC%2FKSf1n91IP7fJGSVdLwla%2BuioFV0KMotzOe4Ji9wjaYDzItOWhe6g%2F4YMkujeKiHZlt80WU9llJn5XLgol3dzKTmeMAFGOhUn0lS4rr7ECkbKwa%2FtoJIxwXUdOV33M9nys2gwqf6viHdseBv4DurRimSawV98b24m0H9Fpu11pHsCnFQn0Yty5dxRgTgCQSWWShlfj22GsN2LhNGAEabPYyaYlMK%2BJjI8Wsc6RAjjaGA%3D%3D",
  "Collin County Community College District": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Heritage%20High%20School?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=khcdhTVeummBT839uQjGnXzyrPBiUIu%2BSQkr%2BnqqZRHzraD9suc%2FLibGFM0tOkbOxgGw3jS%2FlOUFKLno%2FXp5F%2BjzR4QyGxrOscp0873SSbYmb3bGrUw0%2Byx%2FBzG8IRgHGzk1y0MeU521gB7ulHmmYtAOnJQBfyrJe%2FbSszAwVxfgb0WCOGI8D0YSB0ZqM6KLnKYsmDoRc3S2VO0CXl53N0bLZyhxaPiavOwbkTgNEGOvUY3wQj1yFVyHh4O6AceaV%2BiWbyJDWeKqJ0pPiA8GJfSAr5vlK9EHkgpcwOroNcKKPoCbwkjowv9eMC3dzjneePdFCcfPNzjJU1kVqxT7Dg%3D%3D",
  "Des Moines Area Community College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Des%20Moines%20Area%20Community%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=j8wx6e%2Fq%2F9BXNAnzOVT%2FE%2B4xFYCLKDSw0h6rPC8S5nE28M%2BqBpc5tiZn3fZ9TXDFEpsMPEbe8VuChR%2F0HkKyhCFiXFBXzlfiGn%2BXhzNXq0mmfRtDmPwn3O8rA3rE5TKNxzHgm8QL1gyQbCirP1vKZYW57Kd3%2BKCy43aV8IkX8zEIB489u7%2F5p5qenpnNVaWKFMa6EEmovR%2FoB4%2F67njxRluB5%2FS6QUt3799H%2BPxPHZwquKtjqKwc2Im4V0rd3WVYYJpgxwTIV%2FW0l53UJBTtXWR%2B2%2BUx6Um%2B4zSat8Zp1MPaPRsQpoHWCs%2Bs%2BD4FgUN0iJmyRwlfXpyRUsWwx7jgpA%3D%3D",
  "Union College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Union%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=HnjvgV6vF%2B0OCp2fmqh0kPWJhN4WvzhR1IliUbidN00dzLcktnOXONtpOT5%2FhmWOA0v59I%2BV1q9oX4vzaHjOzUHJbE9gyfyFiPE5myPmQuMIbICSc5bs78l0XymDluNYuJAGfr0rOMBBMLCtGM0XWXfFya4juOHW8PvY3U8WCzqDQnhF0lj9IAy%2BxJHyGmvuBPVFAwIfWtZ7BaP77BFh6dD2A2GWNFjhliD7vFX93r2qYNiWCTsyff7hpMOjgdLUuqZGl%2B6zDr3FltJ4LyI9doOfB9roG9DIJOQtJceyoriUiu0%2B13bemM2C386edijZ7hrPAnXNKjL2dTot5dgesg%3D%3D",
  "Frankfurt School of Finance & Management": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Frankfurt%20School%20of%20Finance%20%26%20Management?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=QYAb0QfAIkXZuDVD9Qj6%2FWC3dC6nzVo8Ow8%2Fku9lDbwiN3xnP%2FS%2BEeKDGIo3Mdmz1tOoVS4Lt89TBmjTmmQY3aC1egNQeVmSNR8rHbYwYGcNlqJX7nkQC5Ygm2m4dT1oFNkqVZBaTBz7BxlBRB3QA3N9zcjSSH%2Fv%2Brz6s8IQIhUqj0inrMG1MPCkH3h9ZbB%2F5ZjeWwR80iu8v4vVwkTaqcd3wgSN7hHrweIOD6gO2KbfH0%2F1vZikZQRq2teEaS8rHEGeT6cmTiIWIzyY0CIrmzMTr2eKWpO1Gg31GjQdTCWq7b%2FBA%2BlSEUBZUe6y0NdJfb%2BHrM1tQaRD6NQtbvCzsg%3D%3D",
  "St. Thomas University (FL)": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Houston%2C%20C.T.%20Bauer%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=VwnnWQXPdec%2F3%2Fg5eQBv%2BIR82SJ1EANxgJ3%2BLsclt90l8zEgq53s6zo4TvDMYyLYx3aV3aXDFFc%2BainuDnn5g%2FNAQt7us4xR5w8bi5y1dWmbM%2BciNNX8ZptnDMUeuQOLQwRsEJB5K7M2WKmfkIgdc4ZpGcfsQuKgMCweAn6qx0crd90pWVrSrOBZh7vgAwXleTrtW8DXw5CRQo7dv5H0DIYDVLyqAcohuIMaQtqxaNUx2cw4Is2yqGxpCRayH65Zd15lcUnwRppjbf08IZbMJ90nEMEiqmCBBlXImBPfb9dOTgEWbzSPeDDW%2F3TXKxLZdbBY%2F4xun0ceUbQeM%2FUXFA%3D%3D",
  "West Virginia University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/West%20Virginia%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=To8Q2C9dxFT8YtuWOfoHDhN67K4hN7td9o%2FMttX6WzN%2FcYLhXozOcvAnw354IsKDqyCzw1nvXKJH01e5TjDivgSkdDSSe9LP%2FkIomnlm8y8QsJ%2B5uaHKFWMDxW6JD9l7v3vBo925wYvP6pFQnKCcFMP381IA0OmHA%2FhW4Xc9KcwKTc1kEvOQdD%2BpukGXaMKZMAQasDdp04oy%2B3y%2BXs2n4NzucqxppycexUDo6iwAfvD8Lh7DeIxg1sAXLww2vAgZ3%2Bx3sIvCzY7mmqbWp%2BX%2FfefxDOgAz28NSduOH6UtdZtLuvEd44%2BG7PuQYBG8CE6whmZ0ko%2BMOxk9bFh13wvpHA%3D%3D",
  "Messiah College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Messiah%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=nkOyLWTEEklC%2Brw6uVpjEkXUnNFYZkOnEfFwOCm4DMEfmqeam2OAF1i7uTtJST3P3jLSmNuUcHvuEEjbZO6FWwLj0I7OtGvj95p7dsoimxMe16u5BnHtrNCxJ65lxmgfWpweBAfP49Ow8K8f9AAU2o7F6I%2B9AYESvMK%2BnA4TP28tsCqAmDbfYE6IfE%2FBZ%2FA0aS4miE%2BE3yPQYTMpgFnGOV9EzvrYzwtTcyEqQz9j%2FWR%2F5hsT4dEIrw3Whv10iEEFPl0piVbBmUIVUlHnu%2FjILhFCnTMfQA8ftJTTtDNEkXoeFJqYvD%2FJAai7uj03pK6Z8ZnLY0AxNYRrRl%2BCdfg9nw%3D%3D",
  "Montclair State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Montclair%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=wrI0vbUYGpMBvu%2BlMaFNjbnkynyQNsEbHBT1THdBiy4xR7Fcf8x5SZtTipzQ5%2Bpz%2FsUS1ar%2BJkwaKw8yrjNWvr8FF0cJeQ2BiXY389tSrOeao53yPJjrDK8QlUfuJ0PBwJ46o5u1DzAmUu4PJxdzRjsvdx0RjMA74y%2ByAT7Qar9i5OLgPH1ACqObrdmRmt8fEsd2Yp9M2DiMyE%2BKXm5Acu8WQtgIJ0yxRfbldQtf%2FWYIGF2Gbf14SgP%2BEgG07B7cBSD7j1Ad%2FZGiaEazyq%2FV3dPl4B14z6zw%2BJ3Pga4DSjLSRyA7VaAfSKbmDp7Eg0Me6oNvmyM7%2FJ%2BQvh9i5GGWyg%3D%3D",
  "Loyola College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Loyola%20University%20Maryland?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=PqRZ68AZTnPmKKjhbmoM3GM5ig41cKrA7suAz0A1CaxrrjhfRdlsshb3OgvRV6yW23oAap0S4IB2frdCC9X7pUm7eEHepoZTGentD9jzq8q9Rh0ikuAwA0goq5Ory2cU%2FzNMw5XX3GhG%2BzxiOh5j2RU6OSlGNUKg%2BuxuJkC1wAvk6Xhfdpd5M7EftJir3VKkYv7zpg9oNtH02JoBJxCfY8VEzMj1tNc0ntbVr%2FmGPJBQImfU433GsgHyLnd%2FznI89c0Yunns9DAsTDJrHE8KeglAuz84uC7M0fLvI5qFgzoZAfV%2FPWK7qPWXIdzH6GgtuGxBPD3kmvgz4HUjADizpA%3D%3D",
  "Wartburg College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Wartburg%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Fraebq%2FUDhZDo7GQU9VNWYRUL9UqO6RzEPFFJbZiOwGDQ4penlyaY9stWXjftQo1Xfdr%2FMQGFJHqc9VsRq%2Bg7OE%2BaDNKCY9IGDmq%2FY3uqU%2F337aVzpu4Bw8LGHoz7wbw1bjY%2BLbhDYGx3pxLs7k8mL%2BYGbCtv6ZLZsmel2Kw%2BhSoaDRSmSnU2iHMNLFf9N3uZUVUz0vC5fSwRUJzKP1jOVo1b1RPYLnpo5voeQHZBugtjN0waHONPjGlwNPgrZ4T9%2BvKh%2BYZ8G12k0wE0OqwcHW8kFSeMkgf8Yrf6TODj0uCcCkPPpkV%2BoeeJI%2F3R36CnTuv%2Fp34JyMbiZM8Opsj%2Fw%3D%3D",
  "Ithaca College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Ithaca%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=xISpGdriEy%2F08mJEAcVUtoVmiQDmOzzVdzVXPzMm2OMgvaUQpaZfztmu6%2FrqQm8cSBf3PowHdqQEwxMqHIcImiIf1I6klikFhj7l8SCAFJB8AtbXRXacSO4%2B7DyuALI0ytIUlN%2FuSpi9O0w1rYw6mrefEAL08%2BvfeVaoAD9TurJ6KXyyW8fyy%2Ft2WpQqbHkQ2yPVgZFoorh2LzoLx4Ly64v%2ByuaLkkRKMwoBcztWK91VUOd%2Fz3Bm8eKCn9prRUJYpr76Ng%2FYwpI3A%2FBWiIFb1cVh8faTRYlsTWDdDxny9QCCNpH971%2FIXzWjBhOOjecdpLi2bmIqs%2FzxqNbWJxmUeg%3D%3D",
  "Webster University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20University%20of%20Texas%20at%20Arlington?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=KLVBs97t9Qzp2TPH7upqubSdryrLJ6vvHv6lOe9IEsG1lKjuAitF%2BXsffs8l4182FzqWI13ngogEJ2L2N2vAQ9k38GGsecHB4ZLcJhcpHiWi807RHuI6PXlXbPy5qXfGyFhtdABvZNvAD%2B9%2FQDE9Sa2%2B8WHHqLNkeDQruR267tqsBov1wQcjTogckqOFLR1W%2FnPmNokMH4I5CS%2F6v1UJc8RcgcwwOXg%2BJ98said3NfaF3NEvNGah9kPTB5rNRDSt6TBxcpJLH3toGXDrxIDVqzZZNOh9GJhXX%2FBJ0ds%2FQ04Z1jA4UgwUfUiSF7YpwAEaPjddV7AQPBVZhybCCzhlzg%3D%3D",
  "Camden County College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Rowan%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=C95uxTsBWVz6wn4NT8oeiLiI5NVIqRcv9DFem59EPofMa1YErTQ2O%2FbUwIV5IBkVypi7yLBz2aSjT7%2BQk45lADmlZXJXLeLuq0RqxWOgHAYddM7EiRUl2N0OIs31zdb8LI25gI3x3ywf744B6keMYqj58lYDH%2Bq8OoILFvq%2FXtIwDQdThB2VWqd8j7kocGGui0tV1SaT%2BY44NZhypi%2BfjTh8BrhuCAYk3Y94ZM%2BRM0YOWolT0AeUy1li6EdY988%2FKh1D2l29%2BYbAbOzU8%2ByZ%2F93fHulp4Q35%2BGEsLhq4u180WYTYn0s4Atud1g5CmK6Ok5lBq1954nSWCY8vouI7cQ%3D%3D",
  "The University of Texas at Rio Grande Valley": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/UTRGV%20Robert%20C.%20Vackar%20College%20of%20Business%20and%20Entrepreneurship?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=UULRmBfRJw0h2I9Mz3FivtL%2Bsn4nEUIKgbyu7fdm38SsHBIMPNxm4rRcm5CGfpLANfZlxVSlGyCpXva%2BTWnmsRZKNjsG87FQ6B%2Fqxvl7ljzgKPJ0Fir0bJiIjQ%2Fo%2BKkm8A9sMAWhCyG4VsBemtgGTRiIllFlstfjfy9NSK5xcDhxd4rtUn%2FBNlYDTVQ9Wj3%2BHkvZj%2FyTk6gRhBe8NYg2H%2B3DAiA0klYh8SasPSXV0Jj8H8tvL%2BUdQGTf3AdgUw5BS%2BmcOZcbBQuVg0zBeSd%2BLGQ8YneWPXDtm4QUmnj5o5D6cq1MM4SGAqGK1OJDqWZpBtypoEkcy4HIfxQohVAv5g%3D%3D",
  "George Mason University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/George%20Mason%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=PvvUFTt1NFMahQR8B2Pm%2BFC0bhzQ%2BP%2B0%2B2PHmsOGA2tZRIb0KFjQJoQZawwwzOXbTmNJzHKdL6jHRQ71JRRWYnPYB4NAPMXiQatRS%2F298SAYnaOb9fAFgLPc0rPMoUbUdBsrHJGcD209yGq6MgtT90k2c4r5OQmA9%2BjMYfrUbmmsoxIyP2nlSFsPLRbZLiLTcsl15ZCP1o7L9kxvhqlXqO5znFCRUXcHpvM3xuSiikdTHk46Sn4DHrcSvveFIeRkVxAq7QtzAG6GCOUpsVl8i8nhq3V5tT9ta%2BshWCxs3bt1bqbM59LIuGcdYFyN1IMDkP95a1du3KxqapIe2lWAYw%3D%3D",
  "University of North Florida": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20North%20Florida?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=l2tDXshfgWVlWOgGJXHoKCv2EsxVTue%2BGVdAl17O0l2gF3yCLA3IdEtajKBFTnX5xoQK6xku8izHRVwCE0C8NJQ%2FAYp3oc63LIhf0aERZrSm94OImcobQewk%2BMMb%2BFlREtW3zBUXjVV7MS776sm9TEB6ziimX0pP2AU8bAquTvo33FVEwyjmy7ay%2BnuhpAt9t6WQfN08bbSHacMM7xkbwQnAXDs68NI7zamOtjqW%2BFj6ZN86DsLjnhhYCzDS4ivv4OTvS613pNYR%2FJmQgsAZmA2LwRksZdPtjwWrYoqCcNPxqViR1Z0%2BUXrhu8f9W2tW5kPRWs0JPlK6qVvDjmZI%2Fg%3D%3D",
  "Mount Holyoke College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Mount%20Holyoke%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=KnllzVaKkY2KVFPUB5IoFKg72NrcjhlhG5K5X%2FUL0MGTzTHLi6J2DGiKelry4F7WI4wvWi1%2BEsGtZ4luSZboku5ydKJQ7MbkcJb3PD4vZAUKpYXSLEoRQ5RN6V9uPTPGjbqlUdPo7CZcUhoMlcR7GNmmywYYJGCwMJFXW4GfdBTQFeGs%2FckaEW3ZCnpknPtyCiJox9RbMEgaIRgN3u48L3huAlCadh5l%2FpFN64Bue43plKInzPg8%2F6OejsWyxD9FpQ%2B7UTf%2B8u6TI0NKENnSGH%2Bal9VvMQHZaTnqkFcHuHtuDZ9BoDQnJSmdkjS7IwYT4DgL%2Fqdye2R4IkL9DjMrjA%3D%3D",
  "Uppsala University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Uppsala%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=iIqW3dhDgZuVYGnAigHc3PhU2j53HpyoGvyhZ3al9ipFM1aAOEwTSoMLef1H2a36bKjGLtoPgQlq1UsvJDJOdBRj5Yiw8fkUfVWkCcQPjS6vWH6k4vn8x1y3wFySwPMqzNscThJ2mqk4Bn2W1wAlOQ0PezYD%2FA%2B6piwXVYFAdiaCk7xAf2gEuuJLWSJx3qAKOP2mDE16diLNsbX4oTNTgUFlBHUWa6JUja4qTadkPZtldV4YSDcsl9e1VKyYvIej9uqckzfArxn6l1ziCbkadyiylQNPKyoPX%2FOtcjXWiYqg61xV%2FxE04IZ7%2FgZhx%2Fdgb5ge7kI8SbctHLqoJOhTGg%3D%3D",
  "University of Louisville": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Louisville?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=NksyzZtQCp%2BIQodVvef4Ude%2B1N78i3DG7XnkF8u%2F48Paqp1KcN1CAT7zil52J3vvkciJUQixQPn19%2B6rcjSJlXD4LBO%2FEBxU4CMdyDcszxhSEvnwFH%2BELYBtEuqC%2BhXeL5joiqtyFuRiY21EWfdOFrjZg8Dql1T4hcE6H5BW4AkfxdvSmFB4yu49JWO%2FTRtDiJbY96BQ7OKMDgK8dyQjUEhRut5AboJ1mRudwAuIEqYlzsHU2K7KlS3zGQkqKCw7bHofOaQe%2FE%2FrXuJ54HtfwGMiusI6SrbU4jTD5VzfWUVM00XW3e4gZv%2FJ08tHuBEYu0gySg4jb6Wrap%2FUamqcmw%3D%3D",
  "Trinity University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Trinity%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=QPMGU%2FtuuIOvDk%2FXXybnmbkgbMnzN3O%2BCGSHdJcz1GEoa%2B3OHi6UkYzg%2BO6cRJu3TEJhd76DViHMZUnvWN71Vvrmi%2BHI6Mfwlz%2FnLDjsKmK8XqOHF1hqRAe6jCWg5QDhroR1yLKpeB5VpDt2E7M9CGUWNpf%2Bi4yFJa2re4oBQyphMm2mU4vLQxmYepgRPv86A4gVvyhUiAjMT0zvtjRB8Ta7gdcyEg5AShywE1GFdteWhVKKGp45m5ACJqP%2FzecW%2FFqNhgvuIkuWPD7ZCgzupcPQrtH3X7GQ5KiieExWTQy2zk%2BWp9BDYnMAwkz6hjjyeNPKbGq9AsOV9J0eqXt5Gg%3D%3D",
  "Universidad Politécnica de Cataluna": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Universitat%20Polit%C3%A8cnica%20de%20Catalunya?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=jpPMacgafIoNK9vzNh6B5eZt%2BXHESfmyEyu0gRCGhRYuf7gHU%2F1I5q9SkhM4OMJWN93xKXRMij9iwunf38xNXLJsFYy2Oz%2FjCQt3jFdv9yCWq6JQg2hIs7dywfGECHKzmX3Cp%2B29j3ccTppax1h%2B94jaBxycyGb2omwQcmKaey6G077tHa7zqY0PZ4noSgBYObYQgT%2BMdRsswWWEAmCtO6yxPfvYM%2BgCga1duniY0P1FTqTw71N6LXuAFKwgZSri1r8tXLsenC1O%2BlFBCN0G05%2FV3NLlIoA5qS7dv9AKFCYE30iWtR3xyb0K7M0eXbINsDTp37TlBS38eXtfQy4xJg%3D%3D",
  "University of Saint Thomas (MN)": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20St.%20Thomas?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=vBsXO3hfv%2FmrbofgRompFkcZT%2BHRqOOJg1sYT44MGoMk5HPdYFCX0Tbw%2FWNfMeI2HOi7rJZhe%2FtRxDNJmaMKaWGjkfPaBex3bWcUfMRn9k0KQUuxFrljYvdEipvcFZu3Rw%2BM3VQWTWOPyXh9WH0dwtikEc6ZVCRiK7lP6vlo9f6HEZM0MY0vR%2B9NBvpGNOlOvzpshyze3dxw5szLKZDAFiUF%2FibUa66JbKPjO1fGa1yvzlNzmF8nPbpkHK4McfFdwglXglx%2F1qwnWb2b%2FMdtqR3O1uz1aVn67GduZevbyZHc1xvW1W6%2B7NYxtVoaXKjIHerfBnviCwsGnlGOqG3W%2FA%3D%3D",
  "Western Michigan University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Western%20Michigan%20University%20-%20Haworth%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=sHWpsj0IOLzxPUK3oab54xhdwL4yKnxrAch99nWG0wuF6UgJ1Juv5que7NOJ24eV25k%2FzNw1d7ZlunJjWpZrHr5Cft1472fj9EtTC6QTA3wDZiUJ6JRn6Q8a93iv4%2F6pzxdVFIO1V00ceWHNzbnsDdLsCvZTjnzc2ocBCGsyNDD6WQDpsNvVXqtRHlRl8O2mw4BC2bdNC99KvQDZ00xHsQZb9L8koREeFjLKAsc17rAaEtvMAJo78QOp%2BVNEUtHmDH4NWYQTnk1RsJ937SbSpAt9lQ8kXCiuXhIVZlzVlnJWmgvBwoNWuNbFWPyzFQvSaukAoCqbtAz6DoMM7voe%2FQ%3D%3D",
  "Nevada System of Higher Education": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Nevada-Las%20Vegas?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Unr18tsSxp6UN8vqFLuYLIFNKKYfKJJKD23HSP6EcskZBRGAlU6XkHBp8A03mU2T3WHmZg9OKpjnkmf5K28f%2F6ykqFTVOEQUpBjv0kxlnYWK5blPyl4x6fIZYZemDqhQjb%2F2uCci7B2Qv8jIgbEmH%2Bm2%2FV%2BdmvTRMujRuawb%2F15lcsBqNLpgyJLSDv6Eaw5HE%2F%2BE8bMSJ5BaFEP7LZWmozU7wNQi34k8AQ0PHHJaWma%2BnGUVLFC9uTUA0aq5XcUyidXxCHG2IyZkbiHMhReC40fJGRzAOoax4YAVBbRUa6vg4GkVNzWd831r2K449zLBPWk7kntHkNw8RRTztMQNTw%3D%3D",
  "Valdosta State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Valdosta%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=MT%2BfOyjXWb8btgtVGIHDBFmI7qlMKTqWUOWMUIP3%2F2iQjCTub1jM%2BgIHVpzNTpKCMUqJQiUqjAf0GT2tbRLuoopfrqVVsGYMGj3Rv8eRJANSeCphO3xugSCt4PC%2Bux4TUhNqWfeH0Jtbu7QrpK45Kz84fc%2BMbAxNr6BvPKFDGc2WCk50wux%2FX4uPso5pdZRNSyXJ8Q7XU%2BUk6guAiDkQz0fzm0uywlpmxEtS55jCycTH4%2FjrSLmlTd%2FbbVmqKEw7kfJ38ZL2dMDzK%2B2YjUZ2EB0k6r7Mg4zQ%2Bm0CxdtRDRfZizwRivYuP6E85N%2FlsuGUcZPqtzzKG0bhNvYh48%2FqpQ%3D%3D",
  "University of Iowa": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20University%20of%20Iowa%20Tippie%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=PfTRuG35pKX4%2FAyYctNNnPeDgMtV2BaghXuszEinRmL0BX3zl17UxKcTvrabcVJQwyvXWXmYMUQrTNQl9ocnRJ7tOuRhvC4waipLNJX5LjnJSJnErUTkAumRQM2HKo%2FsGTjnJuuRiVbhUa7PqhNABioql4jlD%2FETpvD%2FnfBg25fdhcYVhOja65511yLiQM5C6cFoQrVLcqV%2FHEif6V3GpHmG12fIMQyiPLxDT26DohsaRpm7uh5dkXtXlAtF%2Fq4QJdS7yGYbVPAK7QrWEG0XGiIeS5cUNyI5qPOxw2OdNOW7JTzrGAyZcrLNoZFYhHPSf%2FQZ1ZX4McBPyvmewqmhnQ%3D%3D",
  "Dillard University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Dillard%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=MoYvBxWMdeTBHaiS5jf%2BMvcegdhVjGxCWXrM2mGyfT9RXI7m8lIwlv13lFPi1SUiq3yEc7boRhAozPORBy%2FYV0A6wOwubuUUsjvNn6yF%2BuudNFsDv1hOifq4XFpoTi9LYn3GuoxLF4mwRACN4y1Vl%2FztlMhDgg%2BRzIrKk7rIC5k14fMzNFnbL0zWCiiDOJB5RxB%2F0WuPop6O%2F1%2Bchgg0bT3JF6BTobBtktq3rUWNeNjARehqO6OrdOvkczg3Q2PBfo5D0cnG%2Fl2TdIhosoC4R8bGv%2FewBkATJxPvLfhvPbAGH0pXjPSpbQ1KvgSNv%2FthJgKMBNCWj9WgIb189OxmGA%3D%3D",
  "University of La Verne": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20La%20Verne?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=BUgEkK%2FRVqGZG%2BHIirK22%2ByCwspxJC%2FwAiNuIq7ccTB42q1SkNb%2BYU5j6iWuxruCBsG15AITylu34F3%2BWCuKgVXc65hWCSjg9DV3oB0jp0yneQWBKwfTyR6%2B8uZ5blj2WkixMwCRUyBcQeDchAZiA9SSXpuG%2Fpw0tXgr5GZ2eK6fwwz2DU7W62RVwSnWkU5%2BCMMSzp53wkCP0OaFd%2BZJ%2FN3nwNivASnJgIMGSObQaiMwYDv3G%2BFgMO43Fhp2Zxda2ySoLW7YjmrTJtKaTtCyxY2fcbmNgi41PqabGx5bhGxf1OIoTzn7UfVCKU9AIULcuc%2Bjh3KOFfcC6ILULJSK8w%3D%3D",
  "Wofford College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Wofford%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=aXumnWuE7DZNUnQpMx9rH%2Flmi4vZFWae%2FKlmksjS3SUtGzsqiltiMhZvoE2sWGh7Q1lu4jW%2F%2BBJaOp6VofgolgxhXpw1VRGsb%2Fm78mUMuZ2HnvujIKd00nl5LBrGGW%2FLumjzvVwDUNOX6JT9Om0%2FLNeoeaysZuKJj1DgAFA5uzCz%2FS%2BkRWH%2FbCkMbJKi%2BwXmr5PITb3tPuwIVOogLiDD%2BMcto%2FlT6TwBtjpToYLosQ5cM%2FiDTmQIDPVpEm7tTx94XfgkPb50LvAbVy95lXWCy%2Fad%2BpyYKrozcYB4WRGq%2B1r6OUSPSIUZBiearVy4Agt29hPZbVlRpfBoIfy6tTe8sQ%3D%3D",
  "Academy of Art University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Academy%20of%20Art%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=wy66a%2B34FVGBURdsf4UykPFF4ZiBhpJ%2F1i0iG9bNpSOaQqhpq4vhWkWDgqHop2AzOUcixguhz3Y81F0ck6AkmJgU6m58oopL6Mpbp%2FzoMDNQDHcS8HXMyf4repg18KQDdzf7ZbVCn96kpyr2J%2BsWQ7IGW7eZuy6lXuPiiVwRB7W09iFos0XrPq0%2FAjJa16bu4pMz4zCWqrc220s9GDPS%2BmzhySV5spPKYjomyPe1Eo8FAlSmk4nKRmI714Zi8NIwzTXJJSsaArjdqYNK1zkIURYZ03824kVg6hhde5ERNJVpH7i2F3UiOjzPYlbj8pTB1E%2Feu70FRV2SpwIAep7PxQ%3D%3D",
  "Wayne State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Columbia%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=VBb9Q3W9g1CM5jIA1%2F2mHqs8J%2Ff7ooEtAm1%2FNVA8WJNVyWjFB%2FMu3oHpIF5KS%2Fe%2FBIHlI7WWr3APhi93Grvq8j7wJ2nqTr%2B1XOzGeDMC34vI2OanpZynx8NmsvNj2gvfkJ0yV7sUSo0dLBl9KRQ%2BCQU7rVTx82TmhuONmfk7d8Y5VugS9KZ8SyU%2Fo994PQ7rn03%2BB65CQrg0ohYsfDXLUxA1IFyiijIOxJ09HpL9cPYyykTxNrhDsqWOqN0yMS%2B8%2FeLt4MTI%2Fi9ozGfikSzy7njEgN%2BmmZpNMv9EygKvTQOuRKwTHEklsHhBWQ9stcNx1EoodTY2PdDe3ahbHLMR5w%3D%3D",
  "Christopher Newport University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Christopher%20Newport%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=fcHgmaWjyNUlkxph03b%2BV2uAMuBlsqpUErEHfwGCcG%2BNZYJ8%2Fc7ShG8NcPm1ETy1kV08QfrT2ng%2BI0OybHcwzPjd9kukXikqx8%2FxNQ%2BUFHxSqtlBVta%2FMdDcVV3Kb9PC6QHvtqaKlFlXOfPXDNVbcRQ4GpCMnhHGDKq0Ntl9lJOSIcLHiNelgQZwM%2FJyItTGfwR2tMMj%2FeqtEqMwYFpXBFgM%2BGCi2C7rqsi0GayGjihOZ8CqzNSXiA009p6m%2BCURrXQxg14Hgu6I9sZgfzY5ZvApicWyaye4dQa12OVGngszIn5xAE0mS520LmAkM0pOqD%2FTrEl6bDX6oqGmMvsrvQ%3D%3D",
  "Manipal University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/cxxkkwnzsy?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=LjMP8MQXAoEVQ7%2FqLkbau5ZbWvKu8fH05yHaCOKJZrqxXvfdfdDg5uZwGAV5bm%2BitCu9b6uYGlz%2BH6qhzt20ijbdeL01tiLV0EXWiteA%2Br7%2FYm264ZvudbENw4T07eRSoaMRFAlcvqRksKles1tHaghaDxSOzRt8qkqdVoi662vUsfDft8dgPm49VW%2B7B7umvCeL43n4mt2yOF4sgPAoutaUhn6qJ8aH3WXn4TWv7S2YIpgtFJ%2FIF8LZbHPJN2EwayPa8%2FK3JXTy%2FCF345Z1EXncSWFPZqrgxnrWpXt4%2BJ3i3a4iAKoA4JI65acP6dUmL%2BmDcqQGLbrr44%2BJw%2Bp4iw%3D%3D",
  "Grenoble Ecole de Management": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Grenoble%20Ecole%20de%20Management?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=HvEfmQ1ECH73wFmTMCqrUOZboSS3Z0uxn7V4HVMQeqZ2Nss6PGuaseqhrGs46gTmJJit2exDTsBzzgrhYvTDAQvQa%2BJwmZV4%2Bj2vJBP8TcNpTeSNZK315krUzAa7l9ckOvxXyz3cP8uCZQsnsr5QPYuK3ZPD2fck58Ya9M%2FwB1zY2vEmyzQnyctJw27qsLBaqNWxz0riPn%2FgozHh36%2B2sO5lKwje7ixLQjwvs1D3RysceSVrF8G86coxTHAkIxNL68Ig4uAVQ9jJvm2sQUskeSMqfSdZOfSO52Q2LoyZG2WFJy422%2Bw5awo5gPfVWOoLIIiPB1XlN73Ugr6bhgAZ8Q%3D%3D",
  "Alverno College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Alverno%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=R8450tm7g6JFyEyX0061BMw1V%2BZONu%2FkDutU2whzlV8MlVT59%2Fr5kjliYFMObnHDIUHO5EY364SyNTXXLo1U%2BJl3aoH8lqbt4orPt8K5ylrN%2BY0SHwBrj0vF4iDbAnw%2FsyMjcZPBuZqJq5wZ%2F4Hrg8%2Fz7KGlM4JC865YN7pA4yz8XmbQsD9Kw1%2BZgqUtsBaKq28IXMcolLpVDs12Z6TaAuREAl8tfbfuqSilxcnDjA3WXn32T40Uk2K3CN3YWXMZvKiMz%2F3i00LZ75hnuvc51DmHvG2VC54zW%2BXgvQu583WZavvdAiYbPDi8HAA75ju4bkuCFXBumhSmSbAf%2F7Q6sw%3D%3D",
  "Strayer College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Strayer%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=L4F4LOJWDkZMzerfs7OQ%2BgkNmhZCrya8G3nfyhqvltERp%2BlyXpj1QLvbmmMbMXAaucAwKkID23H5thBJ58A4bq%2FNjee4oDd1nKuoUhIgCIKktu6M6jizSvlMrB1%2FX%2BQ0P6LK0Tw%2Btiw%2FosBqLEjYI6Uzfuc4dP31cuFLisXes9e1GEEF1HJKUnvaYcPXtKrgZbM2uxp2762hKGEzaf7FL245wR5Dw491NZ57SNAc1fvQa1ejNqfjGawoLQKZPddw3OtDDMvflQy%2B3hSgs9MB9620s54sFa6uBkzwysapBPXkIcr%2FXzYPj7XJvmDv1NdwGGNXR%2BbE9uZxBodB1mjmYQ%3D%3D",
  "University of Victoria": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Victoria?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=JiflNG%2FGVdIGS9LJr8r4mg5C29AmfrYWSVhnwCsGdPB%2BJGvtcfgC%2FlB0GGeSqlvCgeydKQ0vj%2BoOCNZdy4MZQIBDIqfjUHnaVZqDNS%2B7VZI%2B%2B6nV41g4SvWpT99PfASAdcV1zFA0xS117df8AXTTiyb8gB1esWYDSpR9aEY%2FiyobQ%2BG%2Fh6jdsXJS%2Fvusw2Zna%2BvJ%2BpSwnjx6G1bVBY6N138pcWplkfPVeAlsvV5kig2NPOu8vOgyaRiTwKyXh2SQX7HA%2B%2FpM9An40Dt%2BJMByyk%2BALFZviaTNdqRnBlMkPTl956A4VWwt4CFzU0dmmacVc9n6efTLX4h3tr45zeXCaA%3D%3D",
  "Johnson C. Smith University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Johnson%20C.%20Smith%20University%20-%20JCSU?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=JFuISEceJuGupTDHewy3b2ZNsznsbtmvCHp1c%2Fmo%2BnISsjshCoOgBTjIcPv8xleasQ%2BL8dQa%2BYbJs7Pkav877emKvs84Yv0gbzZEaHWdon8m%2BCG6ERh4O%2FE4DeiD8DS7JD6U9jQyiCfhY4NQ3ULktrM72hYVg5LzuejHDabJ8FzdX3fNVPUoOSTNK9vu12qKJwtBGAoUUDaOL5HD7u1iKVNslvwJA5TQEKh09Ful5Z0un4%2FBz5LGBLEyn8LMYqwfU1IAo2jwzQbhHEcMxB863RXdKpYTwrJ6pDQfNHnnsFqGFDIidvc%2FgWh1FxQsKN%2FXG5VGufe8M0Z5K35KJ7u%2FZg%3D%3D",
  "Saint Edward's University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/St.Edward%27s%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=QvvvNB49olXQgvjqyKhIU6Rv40Nf%2BIeLIkKuJ6NHOlSmC4Msv0HBFtPUBqatJ%2FbE1R53Ewj7DJvr%2F2L80DR0HQY%2FvdqvVdjVi4jUhmNVTqD2eM3zoF8NVHphnj%2BSi4KFM8baIhQwVLOG1QybaI4NTuWY6MnOzzJoK6snlIu00okoV4gVXAaaGtbboBTtaVkHPWouC6YAY5M3KInaufX3UIdN%2BegJNSHbKYKMbZlsZKl5GzI9sxbGxDDC7424SPOlDHKyfzZDPjt%2FF98nwlf5fwNXgl5%2Bn2bGhBX%2FfUWvZJPjB8OtzTM1z%2FIv6Xc4HTDircRUbLwn1yE%2Bj5XMpY5oBg%3D%3D",
  "Eada Business School Barcelona": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/EADA%20Business%20School?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=AGerXnkspxDLjH2LhyL1PQw6RBnHBdJrS75fuHdrdm%2FJfjno0LPS%2BqF%2B8lsNn6NtEPF2pLUl0ypa2A6tUf1Y%2BRM5qxrCYFmJPn%2BkAYpoH32mofmRJGKmq%2Fau9gdyk6ayKFQq%2FBPF2wO7Kv03C3SQW3UAC5jP7COhieWPiHHMbQ1ND80KeNfHwVhHlMLQO2Zj2t1MKbqZR7XaF3i5Z%2BnqZ9vvDLHeprZyseiki2DbmrgxTOXrylk9ocR2%2F9BSXppX1EVxBg1X%2B%2BHe8zpnCTBaOC%2BzM%2BOr%2FQkV1ilm85OVQfKWcTCcqO78BsvCdYUlxC1JP1AJVOM0kSwSQZgLEVgsag%3D%3D",
  "Universidad Carlos III de Madrid": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Universidad%20Carlos%20III%20de%20Madrid?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Lprx6niNfqz1bHVJeDQtXI%2B6r3dsrKAYRMIhjMX%2BOJePFWfUZnJv0BYqg%2FxDPkEuhIabtIDS1mrArejGlnTOHzVfJg3wSYGXNIIZqgRzG7De7C2RRNTRBoTKP6grbRpb5Pzd9h8AjsWkVowQiayHNqDkuc%2FtoM0sk8a7LR7A9lIqhXpprjk1f0sGaxl9aGxlsopnomXj2F2QI0wKaiA2rYTZxaz%2BVks2damoCg7aqPWLmWGkonWNDXXAhZx7gJLxvWR5M5wfiCClCrrx36r06QzUilgjL0zLIJtj%2B4QGyjHetDOaZ9VnKEAm0NrXFO4Kve6DXwNZTIY7L4leJ75HkA%3D%3D",
  "Hobart and William Smith Colleges": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Hobart%20and%20William%20Smith%20Colleges?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Bs4mfYVvBtvilxldBQxcWJpZwbWqdpi7qlsJ6TJNq7hwfijjplmI5bLirEcTlCmKqSWy6VgIH80Sst2AaVZC2Tdxki7%2BgjVYHiPEK7uJkPXcN%2B5daCRQJXNIzjX%2B9WmszUdr%2FfwHjLpghVOmd8QzomOJNqOXol0g0d6%2F6D7q6rPOfIb%2Bw9bdpmQZstNTlkSEOU9yKk8SWchXsz7kXhov%2FpmsOgiytOowa2252GzbkbBMRPxkfgf4U%2BIpTS3ccpNRl%2BZW4m0tU4vmIpIjCNDeNu4LP%2FOI0tYi64bhjUh%2B9Co4pO4ADv7TRl%2B%2BDJhmJ3zgk1y3Bx%2F7VYBUl0A38dmciQ%3D%3D",
  "Universidade Catolica Portuguesa": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Cat%C3%B3lica%20Lisbon%20School%20of%20Business%20and%20Economics?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=fFopCM2A0afrzEQOJ6bgiQKLYluSzfeEHZPMYWpjGYVci9zPj1t5cqFRSb2O%2BFqllgAk4MBp9cpya27lXiiP30YKiCo6HSJCrBjfhD20aHgFELAotCs8K2L6oQdDtBi%2Bhkr5fKiX0Hqt04IhMVhk%2FUEE5Kwnu%2FQO60apOiQW5IcE27P8SrZV5zPOzwl8CCeu394P7Hq96dsnpdC3PMt1MQMpMLbdaxbxE8MVZxVu07pJVbbGCWiGqw3TwLnRN6UwUfCdX4CUUjfLcb9Y11TAtHeocEo4xNwljWB7CDGrPokvRED9jOMaScZz%2FX53uWhY7VGq3ZRVRDxEBKUezN0Vig%3D%3D",
  "Wagner College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Wagner%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=CroEWH%2Fb7qFn6ZNHghQsAH6j6h9SgZ3A5LuJ%2F0P7aXHPK%2FRcf3qrLK1LKcxVGWJ5ZPHmLnYbu66Bs3oFcOqmuUQZeJJARvfZadaCvjkCRxiHGgSKnwPT8OSoYzwpglzlClxve9DlLG1Pl0WwWm9ooT8PbHT08GSz9v%2Fq0daqaosBnYd%2BWHW1wlLhA4YeXogScsMKZwMx7FtCkyz0%2Brq7HEF1oug0eoTccztXUHG4DcH5QiDGiWN2U5ffC3JkHdRZvZ%2BKNmGp1PVjGDJB2zzIp3TgHuJ5a6FIhYKUpJSYhky1raqoRPjHrDAS3t4HulJRysldBauqSGlNb2HdEdV1Bg%3D%3D",
  "Xavier University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Xavier%20University%20-%20Williams%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=vCem0wSHlCfhaFovic1sQtmBKBb%2B%2BIl26Fk76Q0RmrNtWZ8InzcSusllNM1u9Fm05wZ%2FmM0zk4K3qj%2BSIfTduOKNngmG3IGIg%2FCecfXPwmJs9Jwrx08W09XLtIf4UBVpO9tU30DlEy%2BH2623COGxnNz0Ap6i79uvZ2e5wUAD%2F0Hv9nYzZc1KJ0CFqBy6MiB%2FDw30G0uQlOkfuhbM6ZwTuJkHTHGJDyapjxdNxRauU2klIMu%2Bt2dWWc8KbzIOtvNgVxQ94WcIfs01rnNvWSPbZFj5LQgd6N62bnDzFheFApP4%2Frvw4JENwqmD5w0Ee38HLL0VFbEtwZe7t%2BDpNmmrPg%3D%3D",
  "Cerritos College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Cerritos%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=HcEZYuT6sBVte9Cjsn5owKKUizsMgDJIj0Yol4qik4uD61%2FHquiU7%2B%2BzUrERjYWFYh6bu%2FERfu1WmaouFcctH9CSLoBcPjg25iPWXA4X4XmBwnpyOS7Zsls7uhptB%2FzH6TgeMkLXkiF8rYHoWQf9IIjxIw34KYZDrbETYMdohjBTOQqFjrMa3BpfCytLsQIJ4f1%2Bybv6ECBnrUn%2FfP0DtgADaBwyH016TA2onpn9js%2B149fbDicogmg6WBo5vEuvcAo%2BcB3GXl28xV%2FAKVdHxnmZw86vaHhE0D3oPyTgFLNQBgjbcS1M1b7tCmZW%2BAP7KlCK00PtqALSgH73Dvbl%2Fg%3D%3D",
  "Coastal Carolina University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Columbia%20International%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=FxARKDClhOAROml5GkgiK9cJ%2BkmC%2BVcMfwvUW0f4UYwwBy75aHFyGxxnkrPesFRbVKWGjbBrCjimJqzOwPLy00Tyq0Eh2t6z4Q%2BwYuU8j1qmSnLmyUAgtnaGtWdQwrOqk9QBv6I5xp8GsK%2BM4ePhth7zdAitF1NZoy8Um55tx0uE6EgoLJqvSU9uskdpOvcvKdDZpamU7%2Fb3lH3K4%2Bx3%2Fgv0jxUwqGz8PH2UcRrvXso%2FHATDbBmq8XLJ07taDp3oIjiCxe7ZZeiyR8atsa9ZV4QH9NX4huk0YPSVXT8dv9Gbi%2FQ6ZGimBn4gK3chpOkpMmNsBtCWp%2BmKZWfs06nZQA%3D%3D",
  "University of Guelph": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Guelph?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=riNTCG6ZV%2F5f3d%2Fp4m1%2BtTrZU9VhQjoA4%2B33umWuHPfblhSmTJoEnRyasOhUft7byDJLwTw5WuEgA6%2FHVRVNrQIfxc3GDmHBibkisxg2snEzC2XT1rLVbleeGSCmRCcd1zm3SOgiUJ1xwBt9WaYmw7b6%2Bn0pV0Wn%2BhjlTMumWOupkKUkuggSkh9HrRODLVKSC7gZI95WirGi4kH6R6soJClQVLJEuL8Y85p08RVHQ0tDttkPN8%2FR8BFcD3RSa2cjnf3zDdgGaPrB%2FjZMcAe2FSYHejMmZAKPrUSoJWfRz1q8QnlKuRmCHLJEykYMdIAIrvWsP8NT%2BPJwpd%2BUM4RI8w%3D%3D",
  "Belmont University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Belmont%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=o4y0F0YFZye9lhFuLgB7ZJ8CNOL1PAMjFB5K6HKZysgUXF74WiM41OlAv6D8THVFd3l7cTOGs19oTHKpRHFYG2sn8DVf1XVqXYP2wDdIfD7iCxahCOSkdJUDUxHzIcre04jdGcfY0go7oPnCJgyPEp4bmTar3%2B1uEsAU3wZxwumBOPGR3UKwisLEZWqnhNtb7iIlCssJLYEJgOnird%2FzcnVj67ugXFf%2BTU1qp6nTz1J0SDllar2jArBTIyqejeNSFSCzViaN0YHEeUy%2BU2zZ8%2FEKgs8gxrNm583l%2BM0ll1XV6US4AaxWg4Tv0tGfVPn1JsQ%2FvDxuvqegrsfL9iYFOw%3D%3D",
  "Skidmore College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Skidmore%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=HuuLYukGRdshrXs8x0eadKD%2F%2FhAouGJLUmJRqDJQIckWK57zsVuiLTyJwVGwefpaGSup7zYDjSQXX9MeTofg%2B4BBhEwxR8hyLT39Hafty8TK732sZqJwR2KOJPDy%2B6nbe1THuZN8eBsFbzbdfdSbaxzdn0Gn5kCyrqQl9rd9wOVNluC9LFrc1%2BYUTFDJupw2z5qj%2BuV03NXTHcjtBPG7KD5S7fy7zvaux3NCx8P8%2FERlU%2F2jgvM7Qkfu9jwjXqqa3DtNRbs76QkPGdJ8oEG6LyM7kVtogyzmyebGGUaj18buvR68%2BYZLz4oFMZAOI7WQ90Wa6k5KZEKyUFbIHI2khQ%3D%3D",
  "University of Milan - Bicocca": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Universit%C3%A0%20degli%20Studi%20di%20Milano-Bicocca?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=bpvFS%2FMqbkpc%2BuznAsOAEzMPKMsONy31wErsRyynGK4ft5x9%2F4slRa8kBUgnC7NVcpmO4akRGJRT77al5c%2Fs7Ltnvzk1YyrN1oann%2F5GIGjtfKI91i4VojU9Uz1gL%2FgviLJkcpKq4WsoxjRcxc%2BJUVgayt0enLTxX0l0b63o1i%2B0DLnk%2BBq%2Bf%2BasQkMB3pX0CGb6TwsXbDrN06RvSEk326wKjCzdvlfA%2BwthVokiTkjjRQr7yeNi4rMApBmEjmFuQ7cndb1SaifKaF3tGE12KxHfadFePyFqX7Lge6aY2UsK0KfGpqwb%2Fd4hGGP9LJ6uQCgorrF%2FJIxm6h4yr%2FgTDg%3D%3D",
  "Western Governors University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Western%20Governors%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=cZcgte8J46Ihk5gOelrc03Jyp3ZB3oMTIqq2Na0EyWhjKxnRzy%2FdMT%2Faw9WgItDoDMEpxZDr3Hjh%2Fko9Pr8hQxheXTVxfSDjOdsFaadxAI5%2FHGgF4m15aI4c07lttE5zD7aN3p3OUHbb%2FWSmnMZY6OCzjcEqEVaWes2MDpUtDr%2BNU%2FPZHmyrTKyIZxbmx32SmYkW%2B92e2ou%2BRe6q1OIzewPNjEwEqyC7xycnrp%2BIkVCEVwFsTCBwcxyRol8v9od7o9URXVyNhHzwSDGKQ6LL%2FBAvFuSyNYRi1HFQwMbgslZsufhH2uh55F8N7CDWgTiluHfQ%2B5%2BAKkpFAWDBTMtDyQ%3D%3D",
  "Lund University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Lund%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=muIR5JQ9gFg26K4wYrD15TE5dxCYUaswh20O%2BUAoXnPFzVf478n1bIH%2B4YOmJNjd5%2B6%2B5SMiupCe074g5n8Z%2Bxb9208X26%2FDaqq7l%2FkWIOaVIR9cbgy84dZNTtY5%2BHsC%2FDj9ImKWPBF3UXMhSQWKMkv9wJZVYhcXszs4rDW%2FwO0wDrz%2F8I9PEu2fqZcFP3Y2RwCeQiESXnwqzTz5t1LLKY2a1vXtlOVNqSAsBFHeHAy2DeVVR2E31qnzU5vYX26Qnc4qLSBdufoKbMlsWD4%2BJOU53VpBp74ob8snP3jV4WXIrw%2FrLjUqMj5UMdSwoaIIe%2FuCVF4CwgVq6B5GsNmU3A%3D%3D",
  "MiraCosta College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/MiraCosta%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=c5sMkFGA0pKr97KsfTT8ByVnoKdyGbhCoiNJOpRxEr4d9HUsvIOjUdW5OezNQ3axUOruxBNmYKFetukzp%2FvbsbttC9um2oy62d43WcMFYyGUPx4ZKP6EPyAu1mExKxNdjmAf9BJdJSrBqrBsGlIrRYdAYqSR6bSF0GAizKnUAb5qFyKQVqJKQBuRKHfaDpGsdkSdQTBJVHrccxI6GYA3iJ24V40l5QNmi%2BbIaNYoYqENgUriZWoqyHSdMWBlTgQUvi3oD92TErBUGoQiYiyP%2FDtRR0F75HDVkT0%2FQSbNmk9DfNjV6dM%2F2DH5yqroBo8cheoWyMyF4v5Q%2BvG4HfCaIw%3D%3D",
  "California State University, Long Beach": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/California%20State%20University-Long%20Beach%20-%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ozios4p%2BtQ60xvCxDsk1pgrD05qvx79pcaLyzFpomUVb9vY4cuhKxI%2FWKjXV%2Fd4b7ms1YzOM7PtGQw2NKIE3TREiiTScwzUUuJLvkWSTJ7bzrSWWfQHsMR56kKzezlq819QdZ%2FFrVIxZbDv9s6Q6NkDSt3NYWk4fLmBONFuXRpDmROScGcWeHU0CahFQrysthFkguQeqlbn%2BwGjdXlCKok2e8s0uwNu4X0zPNuqfp46QV7qG%2BPUt7e7AuDXr2clF%2FhEPdJg35vI886kTWAZ%2BUo0D6JVBBxqbVbVD7nBlTbUzr4ccd1eMGkBw6IwE1IGjq9GXZ59q7FUvi3TgF1O8ig%3D%3D",
  "Raritan Valley Community College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Raritan%20Valley%20Community%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=afMHGfo30xPidXbLUMRWYneCW31Qm9TlJ8ftH253QAiJlbGcbDZf45yRuqM%2FO%2BGYyAv%2BQWbc6f24R%2F15XxH2sCRKF50zyj1CbAKuyVqKU4oHPw%2BXrl%2BkyhBdXTMt3D90pBWz0N13DJnHK9icNCsCWGw%2F5EfeAKFKviAl9ewpZQvUBpOZYpr4IgfkDIhWxoL%2B6KTnPpRt%2BqGoN57WAyAejhp5zcHhcGRcXCAoQz9VcBMdaSL3W6ba%2Bv7F3DCKzyFSzLuQPQVESsj1NV4ScSzWIP1ofEkqcjAR74WtGB92Br9qX2wBtfzJwa8r24lzzcGRGmFJQNEFxRedaJ5fG5ZlAA%3D%3D",
  "Texas Tech University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Texas%20Tech%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=uyOiu7Q7FsJE7ihqjQVCl17XyZZaLn3nAOvhkO56hHdBJZRTNbAfPi2jl9roUO7P7A45bUCOPE7p%2Fd1yuuXPeMJ%2FJJEbcAE%2FbYXDV3vjqDzu%2BdPvEi3e5pP0RKGTAIPEYcQkuYSE%2B%2BF45gIR%2FLANjYCUnNHbUWIHMNOi%2FRGZxFdE9g1yMpcCj0y1hq0Gt6nUbWf04Da7Id5uyR1Wnn1mlpMpWOPABgaseYrdbfS9Y0%2F1hDgHCLX3IvhHEPfD0vt1g3O4enLefT5kDPPfPeXxBLD1JvSDMhecf44wwoaSpb5wIVXyT%2F5iUoviskXoqHwExkzTBYOrLMCuilHGScu6Uw%3D%3D",
  "Central Connecticut State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Central%20Connecticut%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=G2Llc1FsNb%2FliNRMrIFCRiwzWpkdL8eIBFGnNHKYj%2FUC8XIjr3PVToU99lhBOeGRtEs8Nt3v7P19XQjg85n%2Fh1eS6n0cKhClXjVtNdez%2FPpYot4v7lSnfhFqQz9lU4Z%2FVAuWTFkX8itXzvSi5%2B9%2FQqXoanrN3b27AS8Wp2qqFJOP22BVlT2i8AsmzvQlq%2BBjMMx28%2BW8GRDQntufXZaZFXqSZ5SM5WEG9Y%2FBf3dWzSXcbPI8R6soxsImmF55lXDTrdiHSEAyKIIcS8v29sFejXD4fJ2bKZs7A%2F8CYG%2BiFDpDubV%2FKf7yaOQnV6mNzW9uPE%2FrThRCEh9pa2FMMlgVFg%3D%3D",
  "College of Wooster": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20College%20of%20Wooster?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=t9n%2FOC3g299DgsUu4ypJsM8BX5Cp1geqJ4YgIOSm%2Ft56q4g14jwtmxYmodRRiUnq7HPqgoX60xBNtXAumsZPw%2FZpUQyftQGpFVWDOItwJuzrMTVk26rFpAQv6Vls3mP52AwVYItDGYzBwGH6kFGMkNVuRpx%2Frh94Z6%2BB%2B%2BmYRMCnzmsh14W%2BAhJznfMAGjrINa2ChqLiZG3Bju0TZZbP72DtTfCBuhDbbCDFMfX%2FxA7bLAgCRkJpj7Xfj1ouKOAfMlS3xYfVnsG%2BWr8kZYj%2B8g5LyCQviuZTURPU3UC4tJjEyhGqOZ1cUIZ%2BGSeA421DtVU%2Bh2mSn9p44w%2FCe%2F4rrw%3D%3D",
  "University of the Pacific": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20the%20Pacific?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=vsPPg4WGNJFjNqQZ8UOmbsdeGOmnMQZCv638bLlcFTyl74Nm%2FvXb6CTfCyAbJu30BnV%2BcBn%2FKd3l3c1CA79HYQ3VF5Eal3VbwLNci%2BcARxJpGdqUbsQNW7Y8eruDOFSTikxEANw0PTd6S6ETEKw7NmpnUqXcvGf8EeiI2tYrvoMMqVV81eKrIPuztmVxjA2SnjxKyzpU%2Bq0p%2FAvMSDJeqZBEHSigdIg%2FUq4JvJcEbc9s49FxfuJl1Q%2FhRRBNGz0XwKXpTU3HgjuM0ZXAn02GMhxa5nWZQgWCYxK49MTFeLQGgAhuBySWESfE6TtmicBMjEHoL45kb67%2BaIm1pvZL4w%3D%3D",
  "University of Ottawa": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Telfer%20School%20of%20Management%20at%20the%20University%20of%20Ottawa?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Ohc2xM0izuNVOHiJcb5Sv2ftaPYINzd5uQAF1e%2FjYN1MD1ePm6fN1YaAjnUVGnVXNGkrK5OKaYMTSf6jFD23Hu%2FbMI%2FrbEIJrpjqJBU2xvZhmTm9yYJcb1Ylva8AUqAJxk5loVMTXa5XUDRqWJUHN5DCvjo%2Bnmrg3n9GIN4r30%2F75asG8HL83EhasBJbjUg5Z1Aru1wAfUD3fDHIb3o0KgLcadVRjmoTh5XiesENYVycoQfHWnC%2BDQcB%2FU8JuZrLcroAJIz%2F75eg80YDyi%2BurFmz80WFqw%2Be2R8vU%2BRZcCIy9Ozf49eCnijMp76aSnfzib3%2FL5O7OCjRYmRGt1pFzA%3D%3D",
  "Metropolitan State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Metro%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=XtGmjwhsIseohOLveoaJz7GQzUcaFvp3e76PXkKAHa9aWeKl6gjQuxM%2F5HnYLCBxqixi13vZsJ8DlkVZlv3NsOXyW5dAoP2sl2vOgzQzvz8MQf0ZaNuk7EZpi4GArc7V%2F2b2XJ7rydWy1cCUKr8O3fX%2FxNOwyZO54BcZ%2FZjcKQJ7s32N2Q7yx9lz%2BKfTCjg2TjdaQvaHKdxNzexhTqYy6ALV5jUUHuKc3ZkC79QwiIY1HrpnGWXQrDZ%2FvoJ11HrtigCEHVonb57i2VlDTwHES1hk4G68%2BmU2qZJ6xLvbHqf8Lj2qKfEONrtWSwUkbhgdVrlKbTX6G%2BUFkqfvQs1SxA%3D%3D",
  "Bennington College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Bennington%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=uJLBEok3Cxh%2BqtfHTKmboomNNZRg9YDuGQ%2FthGjHy8rWiZeGDYSZfQzUeq8l1BkkKTFvvTd8qucaSzz%2BqcK5XTwlGsghR%2FdPkQW%2Fte37nwUCL3DdjeOBDV3WqIMQ%2Fxk0QWT1Lm2FjFvkz8k0lkUywD6ElrzuiTeJ7iWQwmIGQqtGoc79XFzaMsgAXrJADydRgoOrMIkAgQFQ9u0DWEBz9gh4bpE7xa9NMeBRP4yPpSQimr%2F2Kzw3sY12dSp8wbn8ce5Ax%2BeYi5GxC5r2N9x02hpXzvWFYguR7xd0fgxbC%2F0pZ3JINC6JqSAjncjGYm98Sb6VLXduNbFvxSHcn%2F0%2Fzw%3D%3D",
  "EPFL - EPF Lausanne": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/EPFL?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=l5lDoqNfzS%2Bh7Tpwp7WRCKmgpN8L0g5RK4YSiQZubBfpqrSRFTeHKHemY5WPaLbKwgec9mr1OIl%2BpLtqXaBN4NfWxHPjIjxumUZHl6rMj%2BL9J1fMrKDzmfSWmujOWy%2BRPl3rjNx3QEFSkqj7aV1t0fswtlRzFs5p2m2ZYlIWtaH9Ou3ryWop%2BhJfXp2I4ZKt8R0Erq7vNt1B%2FJbgOj8A4tQyh7kxqRMErzpYetzWjky0xuWFZAB0vTEwpMu52GOxpwjrRIqNmfIBoSqZWeWGY%2Fb1Ho85JNHrXhNTzABT2RU4j36RNPT0gX%2BZR0VzuGWKCMUwX9n%2FadJUCbjOrPQXQw%3D%3D",
  "University of Louisiana at Lafeyette": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Louisiana%20at%20Lafayette?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=UCczXYjTzQvYNj9kmbHb9febdES7O6Grn8f%2BbvViKBJ%2BzmJPDkvFkFIVk2xy9ie68aDvjXxD7PyYJvRJWlM36t2thb14NzpVZLaKgMolIBP5ktImtna1bBr9DkFKynG%2Fpi4bVebfIoovkHHARuOQ24OELmtIMQw1F6Bn%2FiZIVEsU%2FkbdPAQLD2YBhA21mqOx7odPoSKt4qGwe9xsjxaKBlZ19fQhXqM8uQydRCei7SI2lsQ7daxv5TQRmOsIxciD4ZVL6bUn81LQBB3cD5NT31uSdCdSICtuQB2uCe783pd1r%2B%2FOITul%2BhGjOFrBugXc5D3ML7tX4pseFnP3VDjIPw%3D%3D",
  "University of Regina": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Regina?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=IjsX96upwgRZFtNLCZ%2B%2BY9smoCtpUa9RhZmi9fruHjghrx9onbULpIded186qio%2B6UzHG68R%2BwiSe%2BLF0aXZ2lxgCJ0CkiGHNI%2FlDB%2F17254nFH7RM7eJdb2LhQmeJSw057bvST0yy1xniGkZH3LzWQJw7dlTI%2F5PLvvq%2BdqPjBS%2BMg%2BgH9d475fVWkZAudSYBFG3vGTjLW0veoJYgJl5c2vvq0TZ%2BqOiGSr9AXZSOvXFyf1ZdWN4Ml9Z5Bpvo%2Bh77ncbrXkHO4XPvF6Vspn%2BgAMWiLl%2FFcsg387%2BddwZ0Ph6AFUe%2B2A1M31erbRQvp%2FBhol2%2FrhbCDBvIYgndXD5g%3D%3D",
  "Göteborg University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/School%20of%20Business%2C%20Economics%20and%20Law%20at%20the%20University%20of%20Gothenburg?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=RcPwjV708nFjxvGX4GkvzhHEtbIuabqMQY3nTpP6dfdBuktUPffvL96NC1bbYbGFzND%2Fjjh0RKXCr5NfLCJXCqN8bEt3uCYHLUUECVkYcLc4os67kgktwc8dnfy8ET%2FuMIzVe%2BG0CgOAM8JcXL1FI4b6JoLyqYOoK%2BepXqUWlb%2B4q2ET4zK9OdVA1BdusUiAI29q4PdKXiuKrFuwBHkRjieBZvYYJ76ySE8IqsSiaCnkeM032gOprwD%2BhkziCyIPQ%2B4pTeIV4RblAKNp5eperq7ksoOE7KiZaABSq%2FLrrTXpfvr%2Bi0Hk82DfJ5uRauI0I6zDRAzb7eIWD5yxncqKtg%3D%3D",
  "Knox College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Kathmandu%20Model%20Secondary%20School?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=uiFYA%2Bky8NurStbMv%2FTlDg%2FAF81xHu9a3U21%2BczdQyNJ2dohk%2BvEWZoQKRwSy5rVMjYhY2%2FKUOWRAeRObPCAPffo96wd1NSWqcVNOiZIVm8aLdOrXOHvYbD9IS%2Bio2aGowEsyLsUhGbsN7gp8yF3KFPbmZTo0jgypU3Tt7Pd1K9IKDnasWGcgnVHlDynrvXSE%2FI39izuq1JXnUV22LwydrAgk2keNbNyBP5lBr9IAwAfJOvNyeb%2FwvDdJV%2Bv6p4F6X%2BCPV7amTdfN1ytEGgTf709W9iYkuJa94ZD%2FbevZEcdz1qJXjOEiJZNBIwkFeZnRvrHXj0nbeEhOj%2FxyM5Pag%3D%3D",
  "Utah Tech University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Utah%20Tech%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=t9uCrvsZQSJxjLKN7R6AnobkB8pnFVzHPz%2FdJaoCwUwRm0T%2Bxx1axO0qS4a9QFkB5Jw9QP8d1d4ndXDkrgSmV7i6gnReX8ioYrNvVn71jGkj34O6Vrw0K2EhI1Xlu%2Fq579%2Bm43IxQXt4sJ%2FOJT%2Fd7IMM4WRvGAWH3A4zkksL4atycPI2JVEgRjuI2W9N%2FSNn1tw3B%2BzU9WovCkiw%2BPwUKaSH9W5V9xDqb0Hq6dff4uO1KYBJa0Z2MzSZ%2BIcaXi5YCVQAR9%2Fm518Vfg7fnRu5pQdR69lF4BKOQMysXzrmFDAttWBFWysS%2FDWZRh9JArLX%2BxXLJlFvMXCFbEn3J57UrA%3D%3D",
  "Mercer University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Mercer%20University%20Stetson-Hatcher%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=BmexP8KZy8wrGbSIHEr%2BJRRQjFVlBlxZXsr73b72PVGRAUmTNxQGMwbxmvFUBp%2F3bsHikdDxDftJOtJFxTYfXjbdmZiHkUCrJB9FFlodpqw8Xqu3yV9neVRBVaEeuxyA%2BKbOvQ7Bi1DH7eELABFZln0ENol73jTiQAiFxu4jw6pttJKXU%2BzNhep4p7MKdKRpgdaRk9svmRegqALa4MaCZGJfzeje2tX%2BpAEUJW7VJDV%2FI8%2FsX%2BucdyWWNYyITyBQ%2Bu0QaBykeWxTPlKWebSzFJGkFRYXIp7ZTa2xb%2BRK2Tt%2BMsS%2BV3%2FsfMTXOiiYRVTLDnz3cg3CZqbqLTLwE2lBSA%3D%3D",
  "James Madison University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/James%20Madison%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=iUKoWe%2BkNqzUM0t9GC2ds9jVmzNPImHSbsYX%2FT%2F4rNoKliPXZw4O6%2FmLwGqiP7g6NoxC9KrkevolOzqDH8DSkAEkRLisDh1sUq9ck2OTkGEwncIdz4RE8kaXxrygnF8di9f6ohGFNyv7qODvMUUz9oDSqckr6k0FWzVrm8a5Wmm9qmqXvzdvMN90GpYhnN1DPSqrCY0gq7PkrWtqo2aBQq5KXv48sj2ghprGCZaYs0%2FukNu4ucfvXd3jLsEAD1Uee%2BbPHbfruXoDLqU8zSsn5ReWtur5xE5dgNMOxjgekZe6uD09nWwo%2FXNI947wpcv0rXvGvppe%2BBLT1VsEWGMv%2Fg%3D%3D",
  "The Principia": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Principia%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=xQQwNJ2kE3AlbTuau4GYArEpMi%2BdVFm%2B9qsN6RRYKwZTSf3UUpWFccfTK1%2F73E4%2Bzbs0bcqi1P9LoyqNY1HMXHrwNz%2Fyuj%2BVld9LoqW35EJE8Q3euL23CLCZArl4%2FpNfUQC1UrXHFwebmZ%2B3m0tf98%2FMt%2FQCI5CVV3oi6BK8qKBIhUjIg9Ntj1VX%2Ban8QtRDiNBCAsuLiKyi2IqxGDSdr7SmIlbA3Vr%2FGIXVI%2BFPk1wtChH6X%2BdnLIah9WX7jz57p5erNy1kF6W8I8U52GgXcVoAEItrzK4Ug1ZUz9FcSlv4J4idGNvr%2BV2%2F6BqinkJZgl%2BPOuUvjJGs7GXNcaPKNw%3D%3D",
  "Appalachian State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Appalachian%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=oLrri%2Fqix%2FaOCczD3RepLdHUTrfufm53SiN4c06QBPUjERlWMrW2udfNID7uYeblrxyr0zvBurBpijBN14tkUyFSR1osUIMGRGPdtqrSyoXlDPJOpDdR5CZ1NLLwgdcUN31dAWmMpUxMaGVsY%2FDmrngxviudIfP7xwndyrrCsn82Ss0DupnzgX6vvK%2BH9Lqkx9DxD4JVTj8LFyacu38MMsMrg2DtjTw0EW2Z5tlsQ1kZpLhYfQjKtnRBJYl4rdVtsPEY6OSVzODaHC6rVY5THZr2eJ1is%2BBHnVRayh04E9QAMyRQoMwUS4tkU9AeD9IUDxrA5OM3SBrbpJ8fnd4ubg%3D%3D",
  "California Baptist University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/California%20Baptist%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=gX3zA8326m9mOqLw%2F395cW3mG6l8digR4qAViBW5JnWkkbLegZEwSaE%2FvXM4pXf9kFeh%2FdX5LXdFZ4sZeXx5HgIUIhOhEGUtgQW7CZZghi4XyOCJMUndMy%2Bqa0jlzhAdYdHk9EbfFfphJKnhM%2FOlWWXADRr8KOLd9A3pVF18DyMpUI30qcY1DKQoKm0YIZoj4nEmjoyz4oem3MIsGmRMu%2BAwJbvCXOPt1y5hlPYwa%2FzxOJev0hU%2F9JDCM4Lxh1zATG5uXbeVXGqS62D5kx8YUkMntOqxD6HDDAl3fIDBSBCWvK8QMUNUmr2i7%2FOoXLmIKK7Ov6wHf4koB4oobChIEg%3D%3D",
  "Rennes School of Business": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Rennes%20School%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ve8HPNL7HPgkgqlFPgBj6tHf44K3SYBratHg9kkRbFliANrQWyUFQfdbIkw2PWDJ1Mi1Lc%2B1Ch9DhLVUx%2FFLyN63u2eEBmwb2KQ3D8m3UI4wvP0%2BryODF4Ce85dY5sBbSku%2Bxc2C1MAK1cy2meLcqieJkiwf07VcS8zVMKFqtwrAaYNnYKiRVknc71oqYZT5Dk0DfXRor7MczY%2F9t3EhOSGpONHXvOfgPZwS09Tl76bD6sFo7lKxau3mszofETmWAgvBf8so23y3uVoAXNhCaeUfRZZ4KAqOurQzyFWpUeD1wMyTXe3ksG9hWlGrtyjpaWPpE7%2FJ54aBS8MyCmBYKg%3D%3D",
  "University of New Mexico": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20University%20of%20New%20Mexico?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=pt6hQQVRTmFLVwmOA25asJANWNOsH7qC9CArq%2B3jPcuC2%2BgJEeRAfuIS3y25AL9IYuvu8vISWGRq3tHQfkHUQ1E8Q%2F5WSU1FkGW%2BBEQYnKQ8XBQ7jAsB1RWD7yEJDoP07VMVxDuQKMFs3UckeQpQL4Z52VkJplzyZZ%2BsZBcUr65b5GpTOFG2oLyw3%2F1884gCw1Ap6y%2BBCv9n2jJiIe8%2Bnb%2F74%2ByotVTVrusKJRgGCwYzw1WjeVO%2FAs9qJIAyXEOB9TGfRfPO8G33im81Q84AVobqaara%2B7ffrB6jMnGbg6%2B8MvnuhBGJdM6phHVfsGkTbzZEx2JLkexnBLYWqZIj8A%3D%3D",
  "Guilford College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Guilford%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=PMHqiPCljh6zIImYRe7MMRuYyNhgF9NQ8g8tb%2FX1xrb7ewcRte7OBPByWjZPZc9E7w%2FPVK%2B5b2xoMlYIrDJ7aB2cFS511C8Jvxa9lsiiSDdEVFd5vYJdQChvbA0Ozyb2kIkUiCcE96TqHpeDEyIQuFgUDBbGPGNtL6aLfzELPFEF9D89wXbGbPomg3dXVM1pOyh2Ej5I8YVwVb8H%2Fqpp6r2ZQswC7UkCy5yv4smNBTt2uiwehqje8Tydop8NheVOl6coNUTUyK2sQSW2KHdED%2FkReFO0A5rO%2FmKnZ6whY4ZGYvLXf%2FshXQj2ZSVCPrFnnR%2FMgReP7ktrKUSijEXagw%3D%3D",
  "Endicott College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Endicott%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=FOzX2eb2%2Fx1L8bFmgdQsoMaCGQP2PQgAABeRUvP0rYlo2C%2BHMRzgEhTasDFmFp%2FGK0UrauwhZwUpY8b46G3ZsfjuH2TYZkjZ%2FCnxIKs91Awght97GUq0bv7k9fsEXIKxXpr%2BR7LRdoJI3Niisy3J9RyOX5nwBIZqcBT3NKZZBcV1wdRPPsjNCIi%2BhjckItMVIGLWBF17fI9ZjnoJdJy461GCFxKOKLGMZhig51v3Xj4nkbc76inxEp0Vpku6XHEJtf5B5M%2BeSZnzNjR1ociFVOJVsU3jCjpe9sk7CKoInSQ871t%2B327LUkjACuhkacKdF4CSH46IsSwcCDZXNsc2VA%3D%3D",
  "South Carolina State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Washington%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=RHP99BnzD9sMzz0XSKYYxj7PKMhgHUliLxTqd1tkHTzAOsGfv0Tz1wvm1ZJvOAd8ETbum9G4NMwBBKi3V9k0dfkWv9qDwNEZ4NcLyH%2B3fs3f31OoAqz5wzLV4srPzwRkq0AH9RyDk6c04aXyrvFGlCNeLWMzbFH%2FhODs3p382rM9iob0Ly80L2UkNFHnB%2BUBCkOXIEEXUnLLQbt4xhAsdT697K3fCL2wvBhDgIximVhyRr06MQN9nYlj5%2Ffw3A7uE%2FXcihvKYfZ4x2%2Fvn2DNElwpnhckjU2dVzoUoJSN4H4i9o3zfUyBQsK%2Fs8vo1COXFHv3U8uDShk2MDtLeyQmWA%3D%3D",
  "Université d'Aix-Marseille": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Aix-Marseille%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=VEhhViUtBY2LbFjYhuOFsTqmuUy5hPGoevnQehBKmBhST8oOfCB%2BTDOBEV2K6limp7KZjIvpSNXEg6eFf4ssJKpX%2FP%2F7aieMnNKpnbhUnBV7JQMJnoonqcPaYDLOqrC26OIslR%2BGH8HC94qYEB0V0B9P4GYHivNYGdgMA0V%2Fl%2FVjj%2FEE8TFfinnCezqVcP9zst1dMiUrRIewRzc5Ejm8xU13roQN0%2Fc8jFPbTqDoTxlc9FcGqlI0iY0MB3IiicP7nFIZRb31sXRjYDTQmA84jdn9AdEmcOM50b0hfvmnPPsye7jE8TBACBhfLJJpXTg8Fg5GoVRYJFbCNjnAbDldGA%3D%3D",
  "University of Wisconsin - LaCrosse": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Wisconsin-La%20Crosse?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ezxBzauGf21TuYM%2B5DPAWm5P75NyAcSsNa69yuT%2Fe6TGpGNiJ9eOiy7WG%2Fn9hk10hqSuygjC68G%2B8z1Mu9OzRjMS4skvvhMunVXNrPZNA7Q3VkatfanSaaBkECXGn3bx5DCyvNqsiDSM2w%2BdONdsvaeinzPjyNZxGXLj526lgL1sNlbfevDSP%2BbeYcdab08ilQYtK7uwvxiEydK0TXqcHMnR7Ce0PwyuIKcpN3RMyzHYUuSI%2F3le3zFytg2hX1v7uMj7VAEP0ahZfLluAAlmmGlKtKis7AvF4pbW6rnt%2FJCz9SIwstXSeP%2F1zT6Om5NmG7LhhiUhXXb9NZqThWOfcA%3D%3D",
  "University of Denver": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Denver%20-%20Daniels%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=O%2BxynolrvyA%2FibgEX1XT%2BveHDS4nBgpqHp5wBZFsVg6hMIWv29FTnTbJtaS5IflPj9Z3hBdNOXBcEKpTEIC8lJJmM03Dpa50kSRAZygt94YbCnIsGyL256VX31Cd5jqrEq%2FTfk3mZVAh727%2FJAjqv%2FbilZhHdM0OFYxED9jVr6h7o5Zfmuv20M2urOF8az%2FV6Y9o7ts%2FVpMhFOkB2kKinIqqGZ2ibV9IE2gYBlh5IzqtI3taySnTJiHjRdBENDHGBIfvsLrCs%2BrnttKc7O55J1VEMC1RK1osKfFzuUe9pTnkAXvrWLjL9wEk1o7KRknbCl4vPy2xYKTelAatzdERXQ%3D%3D",
  "University of Dayton": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Dayton?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=AstiVWvTqd6TxhcRm2xVAxpPFNPgnYRIS6E%2FQf7NcAjwJxb9JFdT3JKLv%2Fei6Ou8iO65zO54aBZFFerKRbrSfhqbKpnYDCpxCuPztnkkxSIhid%2BJlxo6B1kjenUZGURBwCuWzhxHhe5wwQ6remBgE4op%2Bb7YfyjPHjB5gc4QgZXSeNh%2F1sWR63GRbJoTiW8OgtI2WWGQFRpAvTOYMedLdxsMtLnnuwlCw%2BCiO2I%2Bgz57xu1ADRw%2BhcQcG%2FtyBA8SCNkUsKF636cQkuyX3hBsr%2BwQ9h8IBJqyJ21P62YwEhoj%2BmJTbAOhS7%2FYiKwy9OaBfEw4RnlRI%2FkwsC%2B0uJW5pA%3D%3D",
  "Rowan College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Rowan%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=C95uxTsBWVz6wn4NT8oeiLiI5NVIqRcv9DFem59EPofMa1YErTQ2O%2FbUwIV5IBkVypi7yLBz2aSjT7%2BQk45lADmlZXJXLeLuq0RqxWOgHAYddM7EiRUl2N0OIs31zdb8LI25gI3x3ywf744B6keMYqj58lYDH%2Bq8OoILFvq%2FXtIwDQdThB2VWqd8j7kocGGui0tV1SaT%2BY44NZhypi%2BfjTh8BrhuCAYk3Y94ZM%2BRM0YOWolT0AeUy1li6EdY988%2FKh1D2l29%2BYbAbOzU8%2ByZ%2F93fHulp4Q35%2BGEsLhq4u180WYTYn0s4Atud1g5CmK6Ok5lBq1954nSWCY8vouI7cQ%3D%3D",
  "Saint Joseph's University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Saint%20Joseph%27s%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=N2V5iJV8YywMnmDIFO75ecGQKsVrUfRkipuBj%2FswXKYakrp2tzQVaUAiARXmgLF5Z%2BluMhsuDmtsiUQJvjEvQzqc8LhzkXTciqetGQsRfc9%2B37ixKEGEiphdTMbQDG5yb2469zyQc5KUe2rO0aoI%2FeBEVWqw0wNJRWC4MYPTdw0ap0dnjHQI25wOJoCue%2F4cSGVDTqTCa313EKlkYz%2F6uDC6wyixo6sStUc%2BcXiKKSJERiXPtSeBDGo9zTi9BpCsHvQIoZjkcJMFhttB7zGc3aRS%2F03brOELD1N%2FdVR0QHlHAdlYOCptMMQ5pGLlc23gsr%2BeNvD2lYCKQWFQYcJzlA%3D%3D",
  "University of Wisconsin - Whitewater": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Wisconsin-Whitewater?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=GuGTX%2F57Rwnf0SFptT6ARD4KQOP25WThWxLgV1ZbHI5RsphS2IQ7Mh80kg5pd%2FWtkuxnBlZWMXQ79Hkc7pAKFYT%2FTA%2Bll6Nfl84fJO1uJLMO%2FRQXmMWF3R%2B1n%2FX6hx3qzbHWmnNp5KYNDXJ0uzRt63zAhOWa9sDi%2BcFHAzGpFJMpN%2BqPSiegeD4S2JJBC0peit%2FnFmOaza6oNr0%2Bv%2ByWyc%2FNvyjKjEGzIzVCEszvxF8d27Hql9%2B2VoVpG4Tqgzg1ngH64sG9v7dDkGAb%2Bnjq%2BJWzecsYuAm4M7pNmShzYhgkAFCRvjE3ulzuj1SXD8O0aPcNdV0B7bv3fJalTtsQbw%3D%3D",
  "Le Moyne College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Le%20Moyne%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=P0XRsuV6vCOJp%2BsIWpb9Iy%2FN83cvbIAFgrX8DYGlXk6p6%2BrafgR%2BlreIW95GrArLgVwPFZjAetb%2FqUznnnXXdoo29ak45iG1bXShr1sbWo%2BHi4JIO1xLHMzTNZiXTMKtvOn5cOOFdnZPcRQ5TQAHa4HBnMIjFA6NJ1Bxo%2FONrLQM1N93n7EB7DLpIrUKbDB2Q%2BqS9ssO4A3x%2FwjqAvZ%2F%2FClZtEG0%2F2lMOPVNwWj3J5NQNzUbo0U8S48JMfJQ9%2FRm4xJ3%2FXXi2wXMYaE3okiMHJ8KgrVOGP41KfZvG0UGEDitdsZoubEIr1wBuVGJuDI2IRa35%2FLoUPM7QKwHf0X3eA%3D%3D",
  "Stetson University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Stetson%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=xgRohpjWBCLwcfQpzg1z2V0yVH21wX2rsVfNoQ01Sj0YFsi4L8ipXNOi4kWBoxupUosHJ3eFm%2BQffAlcpW7DlZl9tdHIr3577%2BzuOLCBtaE5WIMTttzG%2FDlb2Tjeb%2BBtKpknfSmapJ6tdBTayP2saKz2UkviZltYmzGOh5t341zod5lfSfPwIENrknMzeTPWgPs6Tin8GQt0%2BwFUZpX81ZLaJP79Q3aNeJia60%2BpUr%2BhmRcuOb6PEaih17YU1OOJTembnqJw6iXEPG8mSEov1zFRGmpW%2B4R1GR%2BTwjw6sofnq85xs1zHAbljXcHncwFU4VJOpvQTT2CidRjp2U%2Fxgg%3D%3D",
  "Saint Michael's College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Saint%20Michael%27s%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=go78iOkPZnY%2Bns7TgBDsbyCAo07P2M12PIGS6I2Z%2BGPl00AbnTKP9vEcOqC3RkDc3Gz7rNd13nuc29DFiKXxUbyBqC3RvK%2FkeyBndP%2FZwzjhbjky55S7MP7rwE50x9SGMYp5MSFPaADZZ8gVI6qVQg7euOyDWibI3S%2FB1o1WEa9qfy1gVqOvPjfkr5jTtpmS2Jl1AUYBA4gu6ip%2BHGOYg%2Bb5%2FD0jhjmciA4ngJFwXsBMOcDIvff91QLwOA%2FJiUW8yS11pgw6gk1WuDnfXPJbRUqieKn2DjzDTyaIiziIdwGnK%2BZ0lagoVdXbOuWWlBb4prUCXOj4VngUUtyXcpT9ig%3D%3D",
  "Clark Atlanta University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Clark%20Atlanta%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Q5nb7b4DOzDrT%2FYJ7m76wtPX9ioaOrICxVhfO4z%2F9V64a81zvuKowkByMNUQ8laaXXQIUfsdIxovYw8dqtq1701bZofDX1GhkGwbAziXRriSvhP6%2BJjsIsEALTYlFwSU7weDYYxHg%2BYc5X%2Byyfi82AYrg0zxtZmtKCpcF266%2FyPtRQs%2F4kMcz1Efm0%2BXlJe6jApL8cR4b9LUHieEZmeyVWYQsutTGBCThum8Fy%2BfiarmV5M%2ByF0bHSz17hzwf%2B87Cj1k%2Fup8oAAvnu8Gd%2FUKGYRzv3EehbJMstRSgiMhtkNyh120uWatMKMEYHByxFcD0mK%2FVXm3yTehu3DCpesbOg%3D%3D",
  "LaSalle University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Stony%20Brook%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=OWHSy9u9v4q%2Fs4WMaEIiJ%2B2ZQDBnDr3CMlAZXcTH03Xz9JGcaM%2BwTpUskvxYkUtfduGg2RczMYlv4KfcF%2B95WP4pu%2BLRC4rptNbho34%2B%2FDWzf80yeszzGyU%2FXb0vVipg4ISEnyKJBa20HnHnx5fqDNEl9qrBs7Y90%2FKC8fPBZlA1sWCq8D90%2FH%2FY9TKo9uovY2JFwN9euQ1erNA0Vxj8mLYBX8oBPVngLGW5090hPuK5mqdlxtY8zFATgVdlF5xucriyOYHt4rH4voOrFTEVjC4wGxvVGpJ1DZktba%2Bc5E%2BU%2F6BaCnsLbqt7PyKaKlsCu8WIrM1MRb9SU2SKvBhmSg%3D%3D",
  "École des Hautes Études Commerciales (HEC Business School)": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/HEC%20Paris?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=a9fa9UZp6Genj3P2rQyV1HwUFIA8MdMf3NbS8yL0qBYMfPzejDwgdfqxl6WIY9HC%2BB9M1TDTy9CQIIYq9gJ5P644e3Xb%2BruM%2FimjEieb9E%2BHLYnid97Ip9yicsqIvoyxMZdASKSuITjT21cX3yZSNynOILZJNRVgjiCe4BlDFEzfE3L1e0Dhp7HHROy%2FmkqQ50fb1nwEe4GpQD%2FpyhEpZeuvb1Zov21DHrKnLja6fTXbCefPGWW59nFd6WbjqI1yugbeb9211Wjm5D0YqcgC293TSgb60p4tBBL%2BIxf%2FyNXe9VR8HBPEMUJSefoV%2BEQaBLdflXlf8iIs6KQxc6NCog%3D%3D",
  "Drury College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Drury%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=k7xrufCL57NWXCaDL3uTZ0HPz9%2B5X6keJCAR10Jjebh7ev1oTes8Eht%2FQCWNGzUQF1Ua0Er7qHgQi8HfMaoQgdTpVdIiYiawlsfqXiWodsveyjz95QT%2FokLjPBL9CI%2Bs0tIt%2FdZyiFkCCjFzHNkt82n0Lw%2BqGH8inomYwHKYTTkh04ejzBNxVd5%2BHbXDwPaAn9TvSzJXY27dCRrc0%2FYCUkNEHwO2q3JtZzXYdsRN8aQzd2KXBzg7ViKSSULL8ENxVQImIUCsYgm%2BwBPqG3O7ieUFvy2MxI4CLW3vlAyMNxGJ9NIwgseDihIKq2Y97JnLb0tUMx5WzXnGACDnKFxfOg%3D%3D",
  "University of Roma \"La Sapienza\"": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Sapienza%20Universit%C3%A0%20di%20Roma?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=c9KIdm1SHY9QBlY26KndxHQauY4hY04YQoL1v7%2BMDxPBl0Rn%2B%2Fbf6HdQMwhC8wbHXfBBfdMH50b4FSav1JrDNxlh3qSKo4eQMRBUwOK9DB6o0kBDL1LD0iCDkXNxIyDsoXFGUh7kCnDasfKgqw0eXmCJ8ON8JqLNDswU3glkxzwee8TV0TxAArM5rDq3fJj91vln%2BrXqhUpw1QJjBTKInFzTcveA9l%2BefaZvrPf9pWQkXwV1VucX18zLoqwwPW42jpmoGY4CdxNTZfY9SVZX%2Fp%2BgCHQDV36%2F6JF2uY5X2FFcPsaXGZtPDblbwEZLdvuG84BtrZldwb5%2FMAyfjDvW4Q%3D%3D",
  "Eastern Connecticut State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Eastern%20Connecticut%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=QUx%2F2%2F9O67Xrl0ixplnSzXa1eH5ZpY5e9gBjTfskiOFmHb20qEaCm%2FUbmO9tZ2Uw9jAqCnVs7RPpzB20N5brJHTSq1sM79nyngpobt4ruKa6RMMfFYke1N3sf%2F21FnpQ6Rkzv12sH3aoPyY5jhO1Kj7%2BJ0HTad%2FXPGf7Gq2U3nu7wr%2Fa2xnEDmpRvJIHKjX%2BeSwhB4PVtEqnzcgrCPRfhKFU7Nox4iIk8VraPoWxcEikLXhojXgUiXPwmYGPonsZ%2BHxQW0JL9D0c%2B6MhsiJHhcDyJcAGWr3I38yBgyGW4%2FmjXjHxNysiCKP7QDGogWZHH1beD%2F%2B0a9bWb8sCKwwZUw%3D%3D",
  "Southern Alberta Institute of Technology": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Southern%20Alberta%20Institute%20of%20Technology%20%28SAIT%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=kL927go%2BUeEkIsfD7kK1qhSyv31h%2BrgCdRDAoPm6XuLTxc5dN%2BlnYGSXF9c5EHrHIX2Cep8MZ5%2FX2dUh%2Feelbw3v1xd3AhHoxy0Fp5YGLL63NJjnjr5H3XDEpo2U477JYrIV0M16HFgYGmbtgdRY%2FDX20vI2Vat6Wo0uRp1%2Bj0wzGd82N%2FfQ2UWJss14ByK47oLUcLVBjdCrEa8hh8x8Xn0rtApRYVQfSzqmF0LxmsbPZCKxnZeAVeHyBq3PLL5%2B2uGbR3aKtdR5gBjy3eOedMna%2Bjhs19CWXzbVCMXeX9VKtFyN1auhDmN9VKUDJO380Y92QN1bAy1N3obCwhwEKA%3D%3D",
  "Grand Canyon University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Grand%20Canyon%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=YT83Xn2p4mEaZ11igsQMDK63VAxBCxjEvI5B%2FmxXr7hLqzvNL2TNFHZ3%2Bw1Sy2v3s8qPJiWcqz6NxXVEnc4w1tUpBqX93Ytt9kqj88D9jdIDPMr%2B3Z5xV%2Fo7h%2BOhPLN7%2FHQ6odUU%2FQWFNo%2BZQQyBoVPdwR0DJR2Ivr5sIeu5BeNijVtlB%2BkZKEHAe4pgsApAjOe6TPUHRsflNiDllehArgc75%2BLJfaUKuBh%2BqX2qnJqjfrMOawX9EgvvRf%2Bj7RjYKbawzQSw6vrlkDbCmvDDKGtgpgX6DVYvJHFfqIwOAWQHibOdUNiSLbLK1Q7dW%2BVOhSJPmyUISzMBas9d31ilVA%3D%3D",
  "University of California, Merced": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20California%2C%20Merced?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=L9WSSfW79Dcq3WuNN5T6f9PuuZjdEeHhdJR4czUqn8gvNCCR4IjRxhTXwPY37KUTP5uBwi0Y7P%2B42913EVQr%2BTkjNAVCOGFHs1Kt3q8m32T8ND9gSoRbDTxYAUSypFwK1W1xNJIU%2F8lCorOg9ILIZrPl9BWa62v%2BYfqrsn%2Bd2oOatO2%2FTGADEnQ77qnE%2F00lyrzw%2Bnfp3SyI4YVytI8p7Wmi5NxDncg28td32tjYf6NkOKvdyON5Omnh6yaN8YMGId7x3RNwSqQFwhE45BNO8Hpaismk1MXdPn98Q%2BVDjbhw%2FRd6d87RSVze9ytxPrubZCgjPGSKqa%2FrN6v3BwS0Ag%3D%3D",
  "Universidad de Navarra": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Navarra?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=VWkCoSp1Y4ysREzZEmXJMWQDSXyrBYSGaWGPJbHf4mWQqtc%2Br1GBtXdFHkkCu%2BYoiLlSJC2R3zYEWav6ELQ0eaUu4puk0tyeCXaAGKVEXDuHddDoY%2FtmyT9MN76D5huBRsslUWkUFeDmYdHtvf6JwFRZT4mI5P2wxnNyymKGQP%2BSmZWxCd910w6X7GvRZbyhw1VBuX%2FNxKQFSNrfPKjhr%2Fny%2FWrFFp5rtsKyUuhvFvWbRShqbxFHLTZDp45fF0U4J89ohsxh%2B2DTf4ewitJehBeXt%2BWmuUFOZbnoNO%2B45cOVjMoRX9Izt%2BhXEBYLo0puOjoh%2B8nPwPDDUwvdU9smfg%3D%3D",
  "Brock University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Goodman%20School%20of%20Business%20at%20Brock%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=atnRVcCgEjLWGH9t7UR%2FYp8AQcA1JHPjiJpn%2F7yvETJglHxzZH1Ir8IcpqogWJ30NsgOuYcr%2F0l4WhN4n1OP91pacgYSdCWdWA9qEPIJK1%2F9RqQ0Y%2FG8OHZJXm2U0vzCYCutG%2B35HDwtjWFjcRBI%2B2UfzRuOWNMwIkoZWlEOP5oG8F7wtUeztkf%2FTTzVLROjX08tUC00ALj%2FpSen52ooqkawjv5aH%2FrIBsn6hfC8eSIb35pVJh9xymRD8vhVuZEE1b7ddEfw5M7eE1%2BiNE6OP0C3rYhnYDB8DCbDdiiZcLbtiWNkF9A%2B0aFG8DYZAyDjPXM0Ts7Nr3Dy2b9inj3AZg%3D%3D",
  "University of Mississippi": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Mississippi?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=e4a8Ei5X3Kmpo8qNWLUMbzBpz0JLOML5BT2A%2FPGOMAvGSIKNCE8PyGhcmpEdl%2BxI1s8BYTDXn2tvQ4dNdE6R8HmVn0OVE%2Bx31KXgJ6pLnPeu%2BYW3GRy3mrGrZH9irzkzbVLkLUdr628H9GlSDV%2B0mE8c%2FvnZjizh12dVjXYrtGYVhEIweeQibRlhdHl%2BzKyqRz0iY2uqju0k%2B7YiWCDPfLzo3kopnKX9HHADnVb1Fcplcu7Tzou79%2BExwSv4TG9gJIyL%2FWMBFnd%2BShMhUTMc5B4rTF0fvICtQfnfiQXJL%2B72oK3LRJXc%2F8XsLmQ%2FA56axJkawYAWRwVXOn1nIhnRNA%3D%3D",
  "Northern Arizona University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Northern%20Arizona%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=bDZ4%2F02LJ0FTzW9m%2BC4CUO0dh%2BrLYyxQduN9FndSlNhkGs6Zp69VXr1FIaOubxR8jCL%2B61maslEK51z0D831jDMYN%2BwIBpJvY8ZodhNC4Ym%2F4tRaHaPxPdlyrZnPJYfJwINUeYF3lbPq1s134C1jmUovhOp5fjQ7DHPPUbObdOTgsCT7MK06aq7%2FBKYe64i0KBLFTGX3ksHk0PURbnqk0JGFbnho9PpiIcXHutEJDYKkbbyYDfx8SnpM7jwSMZXdptC9TlEgXymfNo4tb%2FJdLNU3YM0jWVP6TVwWU0Ro2tc68GUXney8e87TTbdEI%2FPB98lD9zFztIjkgg5MC95htA%3D%3D",
  "American International College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/American%20International%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=kiHAShW45PtH52uIUvlKAs0SYClLajCX5eeR0eG%2Blv63fnLF4gCYC34Ev6EFD74np8mFNBOGptgaw6jkmsljOEHWWcvgHSCmoLkRCsDQdBZHup5e3DeL%2F7VlOXmwGGjGKNYqrGkDdI8AxnHN%2BbEn3k2hy6wDspJ%2FGEOwX9VuEnSaBdoUHzMT1Xm9JVxMolObQSnzMWgMp0g8aH7cKb%2BSyDXidOJ%2Bqcisu2Ik1uittNNO2pJpt2RYi%2BPaRfZED8zgR20OQvz6cGQiAnu9cO9bFhK5ckiK7oDwkAHbDqyd6uyycqvUoZqms8vjvQpvVw%2Fyb0FTSSFiOP0OHjyH13xTZA%3D%3D",
  "Susquehanna University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Montgomery%20County%20Community%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=yBK299Tffsv6A1vsVSwUMwIYehFn9I9am279vskrAX4yZIFEvTPwk1l9hM%2B8173xXixMVO7UUgZs1hVSMQkwZ10gs3JRBKt9KJnk5nGuS0vlOc%2B8DNZViRRhsc0LmKK6fz64NdA1HWUfrw3qQOuhHHqS%2FusgoYASHi0MSp3IXte1TuA6Z2qX43EfYd8b1a5T3FPxEUfhB7hkoZq5mP5oaViHTRHC1o5t%2Fq5PzKoNaM%2FmYXLoofxnTyRBQCEZr6udFOYt2vW%2BCqx1OMVboiF1doTkR2UXJSPYOgyj3k8dGDKB3yW8oHSkxerQLE9z64xUOic8Qg9ORYSdgGvC8EFbrA%3D%3D",
  "California Polytechnic State University, Pomona": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/California%20State%20Polytechnic%20University-Pomona?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=GaLTkhr%2B51DhKSUwRYZmU6qxyXHhOls%2Bx4oUXeuWia5RIvS%2FYnEqOoNsv7%2BCj7Whl9KWdgRQoOq51hBbLaNT1bD7x8HJgOAgAXTqod7puixMUWTv17NnD8wcvMCMR9lO7SBW2CLIwoLApDLTDeoTQcuTkG4SdSqP5uly%2BmTHCKEtJpxyHTarLQAievdmhgQh1vENhatz63tb1%2FAnaGECI42FQWjFMueMJx1Ij8xV%2BhJ9K2jT9TQ6E8AIDugpMQOzqerNfW5yPfv5Ksg0ZtAB0Cjt%2FkYl4UHl14vRf%2FzwPMaR90nD%2BvNn852WSfKnZdXhvYtI0MCy6hPnmgeimgZctQ%3D%3D",
  "Bloomsburg University of Pennsylvania": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Bloomsburg%20University%20of%20Pennsylvania?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=ivDu1AheYT75teV%2BNEnLGylyjUHPMyZNld0vLs%2F838ts%2BsJcbQNo3gthMAmYuibrQ5p8tjYpAxDi8TSBi7wAwmfpon95iVUN%2F4a5e6KENv%2BxFSMgWRGtahvCpwjEPQM2S1LEc%2BCaVGEzFmC9xpx5JkqwtF55agUAC1GMwxS8x9rSQYJjEfw1FF3%2BJYK8o0pxPERackU1%2BUeJhQylnaG40Gqmfv2HzzfQpiaa5E0G64cNUZAI1WRAz0thNbjEHASylcPWo6v1QNuJUocnby%2BjSdDVdO9iw6Rah6s8EI9pfOwHdmYC%2BWrdoQZ%2BidzEcx%2F4l6AMlN1EF8H2a8JNQPxfZw%3D%3D",
  "Cooper Union for the Advancement of Science and Art": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20Cooper%20Union%20for%20the%20Advancement%20of%20Science%20and%20Art?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=vggQik8ku6cWBm2UkUn1VQF43z2YjhoUdy9Ch49miBpHtMyugYZ2ndB0aNGG34f67wsp7Yv5y9EK0NDFLkCGHxt4ZmM4W8FXHWQaa11dJ66dWXgAxNnfj50W%2B1R66UDWocVTcYzfz1IGqUp86Rm1Lf6M%2B3%2BL8JUnkxJu5PWiN%2Fb4zC8xbjiMPCMhguHXzxBmAD7QNQBIpXVFSaV7FBl9nMXU6zbBvX0L%2BreHdB%2BjA66J5lkjGBjaQZ3XUN4Aml2FqedaNKLUpieDSBQ2hXAn12qvZNOc%2B3KvvAWdWhJuWSbAhcU7qZnyzb89%2FGkoai%2Ba8TXToUuyd2y%2BZIPudQ9jNA%3D%3D",
  "Nova Southeastern University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Nova%20Southeastern%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=iPWr7eqoFZYv2lcjkGBWfMLHNBvTEZf7vuc9C2%2BY3L3HVjkfH7ApI5JjFTzz0SdJOGL0aavcn31vzcdBogR6eYJCi9G8JgoFn2WSBdkxQPrnXod3BqOHV%2FIwlP4iijyegfq5EXhY2Aq7U61CvPxcqVeuHlSFb19n6HpsUC0zdH7iuPkeeUHMedIHiAuLGit30HeA%2B0InC6ENNz5puMfnXUAFdN%2B3qSv6zO9E9Nr7jJl4GQnAI6KYAT9Lc9NVFP%2FaCE2Fg2PNAV8KZNBLq0J2dD7YLUjzQeg6A5MZOtwwdXYq%2FpkG5vHPEAl9MOUXaS75VuGNseWQE1g1FsoRS4l%2Fdw%3D%3D",
  "Université du Québec à Montréal": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/%C3%89cole%20des%20sciences%20de%20la%20gestion%20%28ESG%20UQAM%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=f%2BWLWl6U9ekjYUkx4QjpwF%2FOToU9CZfhSe1EKRjjUUn031E2ItlNzNM7ZHxAcT1pW3nH7Nch10jLscmYk9VDrFKKaqXxqc%2BhN%2BlDGQXNe8wwZwVodjbycckUvZASESQeMM8ew4osVmnaz8YQgw3be1jFoIsA3UDJcPzY1P6cGAEgHIBM1388zggBpKAgBODWGAm2EXyCWwVmgneEeBaXDVe23FoPLSSJ4lYO102jymaMxpzfxf4jUqJzQNFJo5xACbfptVAZtBlveEBSTp1i7KsI%2BwE9J1xmX%2F68FhD0lpN%2BBPJ2QJM9rJv%2BJrJ4iAU0VwH4ZQDjUR%2FMEghaL4VX%2Fg%3D%3D",
  "University of Tulsa": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/The%20University%20of%20Tulsa?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=YGedyWSLoRI0GP3UzGL%2FATJGZ%2B3oigQm5VtLq98%2BP43cchl3vqKk3rDOlHZC0ZUwCbZF7Y5wNZP6oryIGXDp28SUKo20xwxpl426AGeuPODZ%2Ba7OflGm0RAqJxb%2F%2Bk4RmHo357%2FIY9LfjAhyZkvcnlQ5rx9Q4fJj2Ps%2BX2AA12LFRP3VHmOy3D7K4nkxOUh2%2FbVlcKZomNkm9TuYBRVPobkUVwDWO1SFFx%2BZg1qwrcl5jdNGUT42F86XcxCDdCKciky6rEtkEEmlJc2gR41mUUdsYyuKR8c5MASZIL05DWkuOn7b%2BqnXGLyN2iiLFUstn9eordKVewMAO2Jm8%2BBe6A%3D%3D",
  "Georgia Gwinnett College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Georgia%20Gwinnett%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=XaFrqqhWVpgPFJ3MHKCHEU5V2VmIgV26XiKvhx3CgF9Boq5g6NAdJ9aJ9MW%2B32DLZrR2gci6IRKsl%2F0XGTnzFjIFXIajtDuXEBI9EDzM9EpgK1VsicHoQktBxVFL0A1%2Bs3607x1LmyqaKKkWJ6m9Tb1j6YkYOuz%2BF6Rmng%2FCNRFba7Ztk2F949PpqiJQYUsnXMnXaY6bxZB6HOSurJU5daUEjv6O70JesIh2GgjKcakTO5%2B5f3Q8IxnhL%2Bc%2BSolWaGT%2F0kG%2BdPQKaG4tB0VRUtp%2FcnYOX0C8Lk1twIUCzCvQbTip49At9RhmPMQGeANd4YHIRPPa0ql0S%2FefYzGQQg%3D%3D",
  "Houston Community College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Houston%20Community%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=gigkJmzEB9J%2Btm07p2Ps5EBkP9fwKTHXB3h%2FFDIfwZLbEFe4tVLBCaSEh5oMik9zg9mg0ubgI%2BgZFaup8uBqWZ23vdoP8%2BJGUb%2FGZJjzW2%2BkZCoKvd6kzwSp5Jxwe7AcR4AY48on89nNcO9ILK0m7nGYWfsw2gYvpYVriltJo%2BN3K5hDhBX4eFwxnv2BExYy%2FUKbClAA5Nv%2F2E2NSRQ8JRevbGdKIqqJsVYb9%2FecBibvVVeP1fhfGjVV8%2F133dIh7G7e4ScZsg%2FL1t2lk%2B7KDiTkkbzy3iEVvUvE%2FW0MFTxGqwIzr7wbYfYHtMmpydlCqO3KC4ZyfRU80bvDQ4zg%2Fg%3D%3D",
  "Creighton University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Creighton%20University%27s%20Heider%20College%20of%20Business?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=SnHZ9k8AfbcE5V3bYR%2BxAkcCyl%2F2l4T%2BZqEYiZxLpfaAhKmscEIA1zIPQnsYXV6cTTVdckrU9GDOqDfPXgG7qIuSp1yjrPJ1NT%2F4pTVhcVn5pvU5hRh0t19sz2o1dWya1knbLNwRpM4vkT6UJZ%2F%2BMHy6NTaK4ayyhe5cKgivApekxI6qLn7v%2B1TXcUaArlGMArdJzYV4DR8Pf2qjSmfGHlbBTDFqwcb3LCs5OEwj6KLiRVRRdp4m0Gm1c%2FSYvKxl7Z8mLthSqEFpBeJs9DgPvs%2BBQ2UEyA7IB%2Btdzhv1wpF1QRLYCAZQ6F2N0oWFgEpSr4JVrMAkDGork%2Bqny8Gelw%3D%3D",
  "Connecticut College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Connecticut%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=lmg1rpYWlvMytvRrmwNvUJ6a2T%2B5hBmiZs8%2FtG6lQv3afdUkIKdaJZJjSffMfhONaJoTvV81PCrD5xeNJlL3adXftOiOpy26BjHbUK32SoTs6%2BSCAbbL7Jpcb%2FamYmzuNSOI6YONieHy1KVi72rJkHA5J0liEmTnuvyZaIm%2BiDTwUWGDPSYQfE%2BetCKLZzcjiGy7Gs7Lwpl%2BqHMWrq21HD%2FHArz5NmQA9FxZeZRYzvjZHl71fzoCP8H36PvB7SuEIN87OcvAA2tEe%2BEMH%2BlSt%2FB16nGlbMF2AcKHB3y0SAV3y4K6wgursFAdmKFL8kpeGgyapDcA556ycF96xNeEAg%3D%3D",
  "Université Panthéon-Sorbonne (Paris I)": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Paris%20I%3A%20Panth%C3%A9on-Sorbonne?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=P%2F8tWCWM6FBgFlm%2F9mQfxuf%2FEEEQ3000lfz%2F1n2PSI7MZ80PgiwWPLIOHmEXv0G2qGdd506943zFSwYebPCpan9i2J4r%2BMyC9RKhQCr%2FzpzY1wTr7z%2BXbIouQxx6WvFBnj9lO2AZ8WRIHXdG3Vr%2FZr6X00gCiT%2B1O5%2BtvLf4YZTpPgOTolVl3onQgRfqJ6igyS5F%2FzdfnjbLUo8HeJP2dwwBUeo9skTYbCXqHJctRfgtc0a0YKY8eZLaepL%2FjeG3m8XI1dFG0AoNQ6yNcqhJ%2FXVbxZPqBhUgBj4WTZvhaReot2ah19OKnyavZ5UEQT0VtzHutmDbnv8n9d832Xv7Zw%3D%3D",
  "Blinn College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Texas%20A%26M%20University-Texarkana?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=HRxgfwddSPZ1XR8JkOUErS1ZAEhSMw9IjLq%2BywMd2%2Fb74NwtPwTlfpsWi0poIJlu1UuWyT2DUdl11DUje61SfP2XeNaci99cXBkmQVr3%2F5ASrO0cOPiP9wUkoMpM6GSrZrhylxfgEPBL1DxrTkMAkB5j%2FGyYbCTse0bUkCw1Py6vnKolctwbjkFnhxtvVfqOb%2FpcfakzKh8WrpmxVNmcJayEdjPrPSU9WeVAHFhcgchKLd2ZG0L111iGwFDNL80pD%2BlzzLcdd3i8OhDo5ey5w20kPcnQuppGdhaqw%2BRM2eQyKnX%2F%2FtfPI%2BB9qWWS4thRMDxSQ6Ucd%2B9paH6wk2XwXw%3D%3D",
  "LeMoyne-Owen College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/LeMoyne-Owen%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=rDYcQZiL3SUlHl3PJNIwGSwh%2FgjtK3jcFFv2MlgEksCHh92kz6NgAUG9q1xDxHl4XDdGwNZO27od%2BFYPGA%2Fft9%2BZ2zK4cIpt%2Fv2hyKki4jMV80%2Bbq1QP8NIZkjIbx37lmIzuPKf1fkeXRWLBIS1mvp8XpNpiOhKWXd%2Be9LxcAmQLVaxUg61r6sUB%2BvSjei4oY1COsBQAjEqagaHoGE6HhF9YfoeFeWXMbY30E6VJSOSs6uyZ7TWREGKtfXqM52hYUdRMEF9UXN%2BdVLSoXefOsZy2ZxdKtJRJV2UaAHE8H%2FXQqTX6nGJYuqq%2Bz%2F66llk3aeknuRr6VSJgJnUI5XrFRg%3D%3D",
  "Emmanuel College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Emmanuel%20College%20%28Boston%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Z%2Fd07OguHDNVkE26hX0FNEoSBPKrQvdkeNRuP9fawdxyL2u4GbgW7rC%2FRg3cKimZVf4AQwqhMwwaZJ7uuO1o45xwIn7rp9RpEAHUm8%2BGdhOVD5DlYs2wLm5x0TgR2aQ1MZrCdFGmQ2FcSkQK9I6rxLT8SH3smaPmELrtbapMJYuZHqMaq8UltGeAviPjvEO67dgwj3hmM59U%2FMdbJrrRGZv3%2F4vzkDY9LtOSdcSnWNiy495obAldgMJgp3AQcE40RQW8BW0WufFRKLd%2Bd1aZYsBQhoc%2F2bgq%2FoyoFfNBzZTozB19yEaTKyCmWtPeKpTcYFaEX%2BW5nlPeCD8Yzlhp7g%3D%3D",
  "Richard Stockton College of New Jersey": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Stockton%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=tyN5TrKddQwkOpYHdc0y2E1afFMRVdzHZZL05wFpVdBRM0qyHVs6UTr3SW5VyZwXLzxxZY88ETzk1O4Nd%2BivpopAVka5QOuEuozGY4KVBCFs0BMGIElhmh3vSk5rI2%2BWeXAgmWwpXEdojw%2FwV6Kft%2BaUGUthxkuUTKMpO7IyPLu8guZ3%2BuSmMFJ9dJK8FiZtELrlmgHJ9jcqonXwatzMVgE5rIrGygbOc92775pPyDSc23xxdAZ5FouWeWiU4AH4lEiLmdiAWF2cm13VAFyH%2BfOBEhpnW8ED6zeL0OsGG7k1w2Ul92EMfVeduwVoEkiP0ZHfAE3OCWy7yAFUnB9TKw%3D%3D",
  "Wichita State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Wichita%20State%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=YdegpsxNC5WXXFFfzDGNgUEQngr8V9aRR9RWSqtJU%2Fwmjc7vjRX90OewpXfHIlF2E1Ki8ZKs6PPe6rpDSOBYgNHhRts1VCqUKf1gji22KohphzQklPdwPtSYfbQuGELgtlRTicyvqzde%2FjPR65R8B4UrgDf%2B3zOB6TcloeX6GoDmZV%2BayhRtbsyuV%2BvF7gs4r3MEcDd2nxE96B5NUBFdTy6QjcjWWAwRNxYkfgvPodjMgndCrH9DbeR7apObWSSkzJ0T9Onc5NzTVL7tGa2VzhgskSTnNz0RFFFy9b6obB6Clb%2FGOWLehv3H7HXqAshsfYDOx%2BEBSgbi10VFtRJEaQ%3D%3D",
  "Trine University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Trine%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=Xprv6173tAK%2BhybfpsdSzjanDce5Oblonx1mciFFewt2RIr0q%2BRSf9W7RM7fO1N6%2F5LPsYJbwt2fD41caOAueHyizYR%2B5YiDTuixolpve6r0u9A6FSrqVaJu3G%2B%2BCRimn1tE79DR12jtHSJ1SOMgmt9ucf4LOh3x3scJ6Tri%2FYACrewvxlO6z%2BUXOw4TG4SMwLCNw8rFgHTJ4HzyjUcluYO32aKgKE11h%2BJm%2FIk87OA%2BM3b7zVPgsBgdpf1pwcPH9DhWP5L1ho7GlkzEtq%2Brp1cj2F8uWZfftf8Uad7Z0YaxtsQoqPnBBXvmnq2TkG6JfE9GkjBrBg8F85BkotsmYA%3D%3D",
  "Western Carolina University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Western%20Carolina%20University?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=p%2Fq4drEkG0d9BfycVp4aNXonq83loLfqIu%2BNFFFhKutzxVLM76eHtMJmpdZ70MZsl4VEil0YMXa1xkYTo3IfNqibES4HDslegh8nBlicH9Q3wHwbF55Oh6PpihDOMLO2OY%2BMg7KHBBTZKU4YMm38ZSv6SHX2mTMsoI15YTBmfjPe10odvBNov8SOH0kD4BNDQWVMKpZwpjrU7Ytvk7kDgHgD7JDosMb8aRyRX9s8xU%2FMS8Z1oAY3nxFUG689jMhfQzl9LJV3Uh6KBxZdQKG%2Bba7mR6tqSNcDh0RmtyjSGddcLloyo8M4qjZBk2aJjZkvcMeHNZ44yxsm%2BASY2kq%2FeQ%3D%3D",
  "City Colleges of Chicago": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/City%20Colleges%20of%20Chicago-Harold%20Washington%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=yHUOlafKz4a7X1iUGv1m%2Fi%2FZZet%2F7M2uI1jha5lx6RZdknv6hvMecCR19b%2BmcMDpcIwIvUEe1w%2F6eN4xMQ4X%2F36NsrOG1CvNVlqSkAZJPTHhIaebFXmWn6N7fP%2FWMnUgUAvitbpuwpTULmWwv5EQfuB5JSgSzIQIPct9nQB1l4cL1Ku0As%2B6noBZOQ2CrHFruOJlKPSdAI2XCCvcHDKckKPELYGR5VCXr7AYpRAnkTOGtQfZ7qDFJQ8GZj7PytV5wwgPyBmN2RZzx1clvbD5YTXi5d67to5UmHLrNriGlZDNwLxJh8WIPkfjedFGrubjQWAI1%2Bd7YYOplOrAVPSltg%3D%3D",
  "Beloit College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Beloit%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=jHBsbaIgsZ%2FAyqdae%2Fo83%2BYm9NO%2FmJ8juabLVuQaOt8pfVbwKrZM9mUGCJPrRJJeOlwYd2%2Fe%2F%2FxPwtWhn21GVCtUzLwJgE2yFqnM8MUiFMyNkDGJ1%2FKRiuHIloq%2FejZcre1%2FQqb%2BHxksi46fhVFOhZ%2B1Cwz3eYwA4MqIyx0FoQu02f3%2Be7anO4xB86vJb18sMOtDCSpeHlz0OQOQMWhuRG6zJ2ojYzSBssD9OVbyS2shsvo3ib9hTJhfZEvbX%2FBPH0FxAniAx3lpauYZcMHdhwWQfJtLj0CRHLwDryvqX9slgwsmFECWxkyRGLbPEYE%2BYgMaPnUB2CbO8kncxgnEyQ%3D%3D",
  "University of New Haven": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20New%20Haven?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=gw0k%2Buqs3afSQYzQYYA3zs80YzToBfF8bRePec2wKGgdeQyYR4FSAXsjQQtv%2BZYqto6ELJRBM28PS04mFfwxW32S9tgOeT7nojhBBfQobv%2B3wXUi5nVBVgBIXPcAdXNd5iYFl1UC0oNn%2BBDvtXAeCup0lPXuvAUyPivYFYTRfUSdGSkvEbV%2BILsk7Hdwp4UU6gdRRk2RZg2eBjG1hPv83lFOdS%2Fxm8gsNCcLNuFl9GGACiCpKHFZwDCS%2FgdowPPrbsh5BoS8sj%2BF45QyzUjholI3KqnjyIWXHe%2BOykhgnU7GroKtSlK1shdjOxyJ%2FhS86yzRqdhI7V4JECECOPBRxQ%3D%3D",
  "University of Indianapolis": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/University%20of%20Indianapolis?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=kabnU9C%2F9Sa3GojlaqAiDUbgiXyDbQzahXFVK4XJTRS0vRApXheW23dja%2BQKKoC8XiUG8kOOv6s746KSW8x2Qst4Gkg3m32%2Fz115mRkSPcPsFppin0YkPPtAKrRNf8ySlToJ8CgtTMf99sdXkU14ManrwAYy4SH1R%2F74uArva0VkgRkmc2fzFMKc2f0CoB6Qyr1Al0zcm2VzOJeWRxsHv2vb6ekNGnhusdgFp8s2%2BS207zLpytlFe32Ni7zHjbZWHARFNpeMmU7GfKX3OOhDpHlRxbGWYzAoQE0l2kJ6Hqm5nLnhhBNR0WBaqWLlJiGNhYORNJyyyHK6VoowPBDKHA%3D%3D",
  "Everett Community College": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Everett%20Community%20College?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=KY6b6nTCiN8tvjlKJpQm5mpk3GEGNfTD2i0t6wmrULIYwxUE5UK1sQi%2BbYpM0hVxEiQ9Lov1F4qLvM8KOaB2aFFQcD%2FypSlAix%2F92ZY96LLUBsvv8NTbOFPfyea3nRhbeAGq0qwkKs4nACoq0h0y6A6Iq3Kc4%2FZ43%2FWMot0sB4UYALlSe212v72%2F3H72Lk0egrotI6gREhj7npwhRrwu03X2ziTg9YnvcWNRB11sRG4%2BLraYNztcXKJ8%2FfUEl24DxFElFPBqr0q5rARbg9H5qrZBoTOg%2BAOlsEL4XLyol9QAdBsdhxq1C%2BE%2FW6i98DwgoN2j9zpD8qSEkHpbWOWxPg%3D%3D",
  "Universidad Autónoma de Madrid": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Universidad%20Aut%C3%B3noma%20de%20Madrid?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=oFYvXidrzpnVFfhT%2BLz8qlH%2BUI8BxW%2BUrFJzcGVBiWibiVWjfGuD6whYqa8JG3vXOfRMlVrPOzcdojHwbChO6TSeWaSBQ5m2bvvR7MgAsPtF%2Bu1WkawbOQjN4S0QGgSdUcVS8S2ANnDxe6wsv%2FMgRXhM%2FaJPIi%2B2JodyokTR16QJFINkOe2ZIJkrh5jgszb%2Bb3zWq0TaRXYV7rmL2ys25XCbFirnix2Ud6%2BqpG4Il9aV8K2jPJiG%2BVSvzDJj1aagOUpMLCQBoLjlDuLP6MBwAqPRP62iK2KPlH45%2BsVFozQ4Tx9%2B6OMvbXuddPQ%2FXbaT9GZwidBEIo3Qfu4BP0qqVQ%3D%3D",
  "Morgan State University": "https://storage.googleapis.com/recruit-u-f79a8.appspot.com/schools/Oregon%20High%20School?Expires=33134745600&GoogleAccessId=firebase-adminsdk-tr47g%40recruit-u-f79a8.iam.gserviceaccount.com&Signature=PLfKQFGTqOIgl%2BXmhJCW%2Fz%2FzzRaT9J%2FYl0enVh7ceqXxP5BrgdnXzPNCgIZrByElZ1bgOaf6LwpRpV6oSfXJAQrtnsdALb7ZyROI5BS3khTzllokmNCqrKCxOOHQnVKcjmrVXkL7EUmcsB%2FNBYLJjQpezmy5RjC0uA%2FK05QJOYFCQh2VFq0pLtBmxV0zBIf9fM5SoK98GIINSAt7zTBUSyx6W2vi5TJBzu3%2F9SxCO7236ZpiM4nvovyU0Lc5FQH%2BSq%2BQBCZebzh4WJuv2loyDu1zjba9ZkOPsiP%2Bge1%2BgorP6%2BegJwFRmlFr1Ge5vl%2BToox2oVvgPGZM5VI8YE5v2Q%3D%3D"
}

