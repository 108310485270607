import React, { useState } from "react";
import CollapseComponent from "../atoms/CollapseComponent";
import FadeInOnScroll from "../atoms/FadeInOnScroll";
import { searchMajors } from "../../utils/typesense";
import SvgClose from "../icons/SvgClose";

export default function SearchMajors({ outerLabel, selectedMajors, setSelectedMajors, limit, error, placeholder }) {

    const [majorSearch, setMajorSearch] = useState("");
    const [openCollapse, setOpenCollapse] = useState(false);
    const [majorSearchResults, setMajorSearchResults] = useState([]);

    const filteredMajorResults = majorSearchResults.filter(
        (result) => !selectedMajors.includes(result.label)
    );

    const handleMajorClick = (major) => {
        if (selectedMajors.includes(major)) {
            setSelectedMajors([
                ...new Set(
                    [...selectedMajors].filter((item) => item !== major)
                ),
            ]);
        } else {
            setSelectedMajors([...new Set([...selectedMajors, major])]);
        }
    };

    const handleSearchChange = (event) => {
        const text = event.target.value;
        setMajorSearch(text);

        if (text === "") {
            setOpenCollapse(false);
            setMajorSearchResults([]);
            return;
        }

        setOpenCollapse(true);
        majorSearchChanged(text);
    };

    const majorSearchChanged = async (text) => {
        const majorSearchResults = await searchMajors(text);
        setMajorSearchResults(majorSearchResults);
    };

    const maxToShow = limit || 2

    return (
        <div>
            <p className="text-sm text-slate-500 mb-1">{outerLabel}</p>
            <div className="relative">
                <div className={`bg-white rounded-lg border ${error ? 'border-red-400' : 'border-neutral-200'}`}>
                    <div className="flex-1 flex flex-row items-center">
                        {selectedMajors.length === 0 ? (
                            <input
                                type="text"
                                placeholder={placeholder}
                                className="input-light flex-1 !border-0 !outline-none min-w-[50px] py-2 px-3"
                                value={majorSearch}
                                onChange={handleSearchChange}
                            />
                        ) : (
                            <>
                                <div className="flex flex-row items-center pl-3">
                                    <div className="flex flex-row flex-wrap gap-2">
                                        {selectedMajors.length > maxToShow ? (
                                            <div className="flex flex-row items-center gap-1 bg-slate-100 rounded-full px-2 py-1">
                                                <span className="text-sm line-clamp-1 max-w-[150px]">
                                                    {selectedMajors.length}{" "}
                                                    selections
                                                </span>
                                            </div>
                                        ) : (
                                            selectedMajors.map(
                                                (major, index) => (
                                                    <div
                                                        key={index}
                                                        className="flex flex-row items-center gap-1 bg-slate-100 rounded-full px-2 py-1"
                                                    >
                                                        <span className="text-sm line-clamp-1 max-w-[150px]">
                                                            {major}
                                                        </span>
                                                        <div
                                                            className="text-slate-500 hover:text-red-400 cursor-pointer"
                                                            onClick={() =>
                                                                handleMajorClick(
                                                                    major
                                                                )
                                                            }
                                                        >
                                                            <SvgClose className="h-3 w-3" />
                                                        </div>
                                                    </div>
                                                )
                                            )
                                        )}
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    placeholder="Search more..."
                                    className="flex-1 input-light !border-0 !outline-none min-w-[50px] py-2 px-3"
                                    value={majorSearch}
                                    onChange={handleSearchChange}
                                />
                                {selectedMajors.length > 1 && (
                                    <div
                                        className="text-slate-500 flex flex-row items-center gap-1 mr-2 hover:text-red-400 cursor-pointer ml-1"
                                        onClick={() =>
                                            setSelectedMajors([])
                                        }
                                    >
                                        Clear selections
                                        <SvgClose className="h-4 w-4" />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {error && <p className="text-xs text-red-400 mt-1">{error}</p>}
                <div className="absolute top-12 left-0 right-0 z-[99]">
                    <CollapseComponent
                        isOpen={majorSearch.length !== 0 && openCollapse}
                        className="bg-white shadow-lg border overflow-hidden border-neutral-200 rounded-lg"
                    >
                        {filteredMajorResults.map((res) => (
                            <FadeInOnScroll
                                triggerImmediately
                                delay={10}
                                key={res.id}
                            >
                                <div
                                    onClick={() => {
                                        handleMajorClick(res.label);
                                        setMajorSearch("");
                                        setOpenCollapse(false);
                                    }}
                                    className="flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 px-2"
                                >
                                    <div className="flex flex-col">
                                        <p className="text-sm text-slate-800 line-clamp-2">
                                            {res.label}
                                        </p>
                                    </div>
                                </div>
                            </FadeInOnScroll>
                        ))}
                    </CollapseComponent>
                </div>
            </div>
        </div>
    );
}
