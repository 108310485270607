import React, {PureComponent} from "react";
import CompanyLogo from "../atoms/CompanyLogo";
import Dropzone from "react-dropzone";
import SvgPostAdd from "../icons/SvgPostAdd";
import SvgNote from "../icons/SvgNote";
import SvgRemove from "../icons/SvgRemove";
import { CLASS_YEARS } from "../../utils/dummy";
import MultiSelectComponent from "../atoms/MultiSelectComponent";

class AddPostText extends PureComponent {

    render() {
        const selectedItem = this.props.selectedItem || {};
        return (
            <>
                <div className="col-plain w-full">
                    <div
                        className='flex flex-row items-center gap-3 py-2 rounded-xl px-2'>
                        <CompanyLogo id={selectedItem.company_id} className={'w-16 h-16'}/>
                        <div className='flex flex-col'>
                            <p className='body1-bold text-slate-800'>
                                {selectedItem.name}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-plain w-full">
                    <p className="body3-bold text-neutral-400 mb-1">
                        Caption (*)
                    </p>
                    <textarea
                        name="plaintext"
                        value={this.props.plaintext}
                        onChange={this.props.handleInputChange}
                        placeholder={'Add your post caption here...'}
                        className='input-light text-sm px-3 py-3 body2 base-black-50 w-full min-h-[120px]'
                    />
                </div>
                {this.props.allowFileUpload && (
                !this.props.uploadedFile ?
                    <Dropzone onDrop={this.props.onDrop}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className='cursor-pointer'>
                                <input {...getInputProps()} />
                                <div
                                    className='w-full flex flex-row items-center justify-center rounded-xl h-26 border border-neutral-300 border-dashed p-6 text-neutral-400 hover:bg-neutral-100'>
                                    <SvgPostAdd width={24} height={24}/>
                                    <p className='body3 ml-2'>
                                        Add Files
                                    </p>
                                </div>
                            </div>
                        )}
                    </Dropzone> : <div className='w-full flex flex-row items-center gap-3 py-2 rounded-xl px-2'>
                        <div className='flex flex-row'>
                            <SvgNote width={24} height={24} fill={"#4848F2"} stroke={"#4848F2"}/>
                            <p className='body1-bold text-slate-800 ml-8'>
                                {this.props.uploadedFile.name || "Attachment"}
                            </p>
                            <div onClick={this.props.removeFile} className={"pointer ml-4"}>
                                <SvgRemove width={24} height={24} fill={"#4848F2"} stroke={"#4848F2"}
                                           />
                            </div>
                        </div>
                    </div>)}
                    {!!this.props.classYears && 
                        <div className="col-plain">
                            <MultiSelectComponent
                                className={'flex-[0.5]'}
                                outerLabel={'Intended Classes'}
                                label={'ie. 2025, 2026...'}
                                value={this.props.classYears}
                                light
                                setValue={this.props.updateClassYears}
                                clearFilter={() => this.props.updateClassYears(null)}
                                options={CLASS_YEARS}
                                node={document.getElementById('create-post-modal')}
                            />
                        </div>
                    }
            </>
        )
    }
}

export default AddPostText;
