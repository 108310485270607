import React from 'react';

function SelectableItem({label, isSelected, onClick, value, append}) {
    return (
        <div
            onClick={() => onClick(value || label)}
            className={`flex flex-row text-center   ${!append ? 'justify-center' : 'text-left'} text-xs md:text-sm font-semibold items-center gap-3 p-2 px-1 md:p-3 md:px-2 rounded-lg md:rounded-2xl cursor-pointer ${isSelected ? 'bg-primary/5 text-primary border border-primary' : 'bg-slate-100 border border-transparent hover:bg-slate-200 hover:text-primary'}`}
        >
            {append && append}
            <p className={append && 'text-left line-clamp-2'}>{label}</p>
        </div>
    );
}

export default SelectableItem;
