import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={24}
            viewBox="0 -960 960 960"
            width={24}
            {...props}
        >
            <path fill={'currentColor'} d="M80-160v-640h800v640H80zm400-280L160-640v400h640v-400L480-440zm0-80l320-200H160l320 200zM160-640v-80 80z" />
        </svg>
    )
}

export default SvgComponent
