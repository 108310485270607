import React, {Component} from "react";


class UpgradedCompanyPagePerks extends Component {

    render() {
        const company = this.props.company || {};
        const perks = company.perks || [];
        return (
            <div
                className='bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl'>
                <div className='flex flex-row items-center justify-between gap-2 mb-2'>
                    <p className='text-xl font-semibold text-slate-800 mb-2'>
                        Perks
                    </p>
                </div>
                <div className='flex flex-col gap-2'>
                    {perks.length === 0 &&
                        <p className='text-slate-500'>
                            No perks listed yet
                        </p>
                    }
                    {perks.map(item =>
                        <p className='text-slate-500 text-md'>
                            {item}
                        </p>
                    )}
                </div>
            </div>
        )
    }
}

export default UpgradedCompanyPagePerks;
