import React, {PureComponent} from "react";
import {connect} from "react-redux";
import ModalComponent from "./ModalComponent";
import {searchCompanies} from "../../../utils/typesense";
import CompanyLogo from "../CompanyLogo";
import { addRecentPlacement } from "../../../api/student/clubs";
import CollapseComponent from "../CollapseComponent";
import WrappedTextInput from "../WrappedTextInput";
import SvgSearch from "../../icons/SvgSearch";
import FadeInOnScroll from "../FadeInOnScroll";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

const ZERO_STATE = {
    search: '',
    searchResults: [],
    companyId: "",
    company: {},
    numPlacements: null,
}

class AddRecentPlacementModal extends PureComponent {
    state = ZERO_STATE


    numPlacementsChanged = (numPlacements) => {
        this.setState({numPlacements: numPlacements.target.value})
    }

    closeModal = () => {
        this.props.closeModal()
        this.setState(ZERO_STATE);
    }

    submit = async () => {
        const placement = {
            company_id: this.state.companyId,
            num_placements: this.state.numPlacements,
        }
        await addRecentPlacement(this.props.authUser, this.props.club.id, placement);
        this.closeModal();
        this.props.getClubInfo();
    }

    setCompany = (result) => {
        this.setState({
            searchResults: [],
            search: result.name,
            company: result,
            companyId: result.id,
        })
    }

    searchChanged = async (e) => {
        const text = e.target.value;
        this.setState({search: text});
        const searchResults = await searchCompanies(text);
        this.setState({searchResults: searchResults.slice(0, 4)});
    }

    render() {
        const saveDisabled = !this.state.companyId;
        return (
            <ModalComponent isOpen={this.props.isOpen}
                            header={"Add Recent Placement"}
                            backgroundColor={'white'}
                            headerToggle
                            size={'lg'}
                            toggle={this.closeModal}
                            FooterComponent={
                                <div className='flex flex-row items-center justify-end gap-3'>
                                    <button onClick={this.closeModal} className='secondary-button body1-bold'>
                                        Close
                                    </button>
                                    <button onClick={this.submit} disabled={saveDisabled}
                                            className={`primary-button body1-bold ${saveDisabled && 'opacity-50 cursor-not-allowed'}`}>
                                        Submit
                                    </button>
                                </div>
                            }
                            footerAlignment={'right'}
                            id={'add-job-modal'}
            >
                <div className='flex flex-col gap-5 min-h-[20vh] justify-center'>
                    <div className="col-plain w-full gap-3">
                        <div className='flex flex-col gap-1'>
                            <p className="body3-bold text-neutral-400 mb-1">
                                Number of placements
                            </p>
                            <WrappedTextInput
                                type="number"
                                placeholder={`Number of placements in the last 2 years...`}
                                className='input-light px-3 body2 w-full mb-3'
                                value={this.state.numPlacements || 0}
                                onChange={this.numPlacementsChanged}
                            />
                        </div>
                        <div className='flex flex-col gap-1'>
                            <p className="body3-bold text-neutral-400 mb-1">
                                Company
                            </p>
                            <WrappedTextInput
                                type="text"
                                placeholder={`Find a company...`}
                                className='pl-44 input-light px-3 body2 w-full mb-3'
                                value={this.state.search}
                                onChange={this.searchChanged}
                                icon={<SvgSearch width={24} height={24}
                                                className={'absolute text-slate-500'}
                                                style={{left: 12, top: 10}}/>}
                            />
                        </div>
                        <div className='min-h-[30vh]'>
                            <CollapseComponent isOpen={this.state.search.length === 0}>
                                <div className='flex flex-col items-center justify-center py-12'>
                                    <p className='text-slate-500 body2'>
                                        Search to find a company
                                    </p>
                                </div>
                            </CollapseComponent>
                            <CollapseComponent isOpen={this.state.search.length !== 0 && !this.state.companyId.length}>
                                {this.state.searchResults.map((res) =>
                                    <FadeInOnScroll triggerImmediately delay={10} key={res.id}>
                                        <div
                                            onClick={() => this.setCompany(res)}
                                            className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2'>
                                            <CompanyLogo id={res.id} className={'w-16 h-16'}/>
                                            <div className='flex flex-col'>
                                                <p className='body1-bold text-slate-800'>
                                                    {res.name}
                                                </p>
                                            </div>
                                        </div>
                                    </FadeInOnScroll>
                                )}
                            </CollapseComponent>
                        </div>
                    </div>

                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(AddRecentPlacementModal)
