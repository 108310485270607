import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="30px"
            height="30px"
            {...props}
            fill={'currentColor'}
        >
            <path d="M14.984 2.986a1 1 0 00-.777.391 1 1 0 00-.004.006l-4.91 4.91a1 1 0 101.414 1.414L14 6.414V17c0 4.43-3.57 8-8 8a1 1 0 100 2c5.511 0 10-4.489 10-10V6.414l3.293 3.293a1 1 0 101.414-1.414l-4.916-4.916a1 1 0 00-.807-.39zm2.235 18.237c-.27.716-.606 1.398-1.002 2.04A9.978 9.978 0 0024 27a1 1 0 000-2c-2.86 0-5.366-1.514-6.781-3.777z" />
        </svg>
    )
}

export default SvgComponent
