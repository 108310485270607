import React from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import {primary} from "../../../utils/colors";

const TooltipDemo = (props) => {
    return (
        <Tooltip.Provider delayDuration={250}>
            <Tooltip.Root>
                <Tooltip.Trigger asChild>
                    {props.toggleComponent}
                </Tooltip.Trigger>
                {!props.disabled &&
                    <Tooltip.Portal>
                        <Tooltip.Content
                            className="data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade text-violet11 select-none rounded-[4px] bg-white px-[15px] py-[10px] text-[15px] leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]
                        body2 bg-primary-force text-white max-w-[300px] flex flex-row gap-3
                        "
                            sideOffset={5}
                        >
                            {props.label}
                            {props.content}
                            <Tooltip.Arrow className="fill-white primary fill-current" style={{fill: primary}}/>
                        </Tooltip.Content>
                    </Tooltip.Portal>
                }
            </Tooltip.Root>
        </Tooltip.Provider>
    );
};

export default TooltipDemo;
