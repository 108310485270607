import React, {PureComponent} from "react";
import {primary} from "../../utils/colors";
import SvgCheckCircle from "../icons/SvgCheckCircle";
import HorizontalDivider from "./HorizontalDivider";
import SvgCheck from "../icons/SvgCheck";


class HorizontalStepperList extends PureComponent {

    render() {
        const status = this.props.status;
        const steps = this.props.steps;
        const currentStep = steps.findIndex(step => step.title === status);
        return (
            <div className='mt-3 mb-6 flex flex-row flex-1'>
                {this.props.steps.map((step, i) => {
                        return (
                            <div className={`flex flex-col ${this.props.steps.length !== (i + 1) && 'flex-1'}`}
                                 key={step.title}>
                                <div className='flex flex-row justify-between items-stretch flex-1'>
                                    <div>
                                        <p className={`${currentStep >= (i) ? 'text-slate-600' : 'text-slate-400'} body4-bold mb-1`}>{step.title}</p>
                                        <p className='text-neutral-400 body4'>{step.date} {step.desc ? '•' + step.desc : ''}</p>
                                    </div>
                                </div>
                                <p>
                                    {currentStep.title}
                                </p>
                                <div className='flex flex-row items-center'>
                                    {(currentStep >= i - 1) ?
                                        <>
                                            {(i > currentStep) ?
                                                <div
                                                    onClick={(event) => {
                                                        event.stopPropagation()
                                                        this.props.updateStatus(steps[i].title)
                                                    }}
                                                    className='stepper-list-dot
                                                    cursor-pointer
                                                    hover:bg-primary/20
                                                    hover:border-[1.5px]
                                                    hover:border-primary
                                                    flex flex-col items-center justify-center outline outline-1 outline-offset-1
                                                    outline-primary/20
                                                    '>
                                                    <div className='rounded-full w-1 h-1 bg-primary'/>
                                                </div>
                                                :
                                                <div
                                                    className='stepper-list-dot bg-primary/10 border-[1.5px] flex flex-col items-center justify-center border-primary'>
                                                    <SvgCheck className={'text-primary w-4 h-4'}/>
                                                </div>
                                            }
                                            {this.props.steps.length !== (i + 1) &&
                                                <HorizontalDivider width={16} className='flex flex-1' grow
                                                                   color={(currentStep >= i + 1) ? primary : (currentStep >= i) ? primary + '50' : '#00000020'}/>
                                            }
                                        </>
                                        :
                                        <>
                                            <div className='stepper-list-dot'/>
                                            {this.props.steps.length !== (i + 1) &&
                                                <HorizontalDivider width={16} className='flex flex-1' grow
                                                                   color={"#00000020"}/>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        )
                    }
                )}
            </div>
        )
    }
}

export default HorizontalStepperList
