import axios from '../axios';

export const getUserTrackers = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {}
        };
        const res = await axios.get("/trackers/user", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const createTracker = async (user, params) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/trackers", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const deleteTracker = async (user, tracker_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {tracker_id}
        };
        const res = await axios.delete("/trackers", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const addNote = async (user, tracker_id, text) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const params = {
            text,
            tracker_id
        }
        const res = await axios.put("/trackers/notes", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const deleteNote = async (user, tracker_id, index) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const params = {
            index,
            tracker_id
        }
        const res = await axios.post("/trackers/notes", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const editTracker = async (user, tracker_id, updated_params) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {updated_params, tracker_id};
        const res = await axios.put("/trackers", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}
