import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="30px"
            height="30px"
            fill={'currentColor'}
            {...props}
        >
            <path d="M4 4a2 2 0 00-.387.04c-.01.001-.02.005-.031.007A2 2 0 002 6v12a2 2 0 00.04.387c.001.01.005.02.007.031A2 2 0 004 20h22a2 2 0 00.387-.04c.01-.001.02-.005.031-.007A2 2 0 0028 18V6a2 2 0 00-.04-.387c-.001-.01-.005-.02-.007-.031A2 2 0 0026 4H4zm2 2h18a2 2 0 002 2v8a2 2 0 00-2 2H6a2 2 0 00-2-2V8a2 2 0 002-2zm9 2c-2.209 0-4 2.015-4 4.5 0 1.422.598 2.675 1.514 3.5h4.972c.916-.825 1.514-2.078 1.514-3.5 0-2.485-1.791-4.5-4-4.5zm-8 3a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1zm16 0a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1zm2.984 10.986a1 1 0 00-.144.014H25v2c-.839 0-1.554.516-1.852 1.248a45.687 45.687 0 00-3.699-1.21C15.817 23.016 10.603 22 4 22a1 1 0 100 2c6.397 0 11.422.983 14.908 1.963 1.743.49 3.1.978 4.059 1.346.48.184.86.337 1.15.45.146.058.266.104.383.143.103.035.173.079.418.09.028.003.054.018.082.018 1.076 0 1.937-.851 1.99-1.908a1 1 0 00.01-.141V22h-.846a1 1 0 00-.17-.014z" />
        </svg>
    )
}

export default SvgComponent
