import SvgCompanyBuilding from "../icons/SvgCompanyBuilding";
import SvgUserFeather from "../icons/SvgUserFeather";
import SvgLogoIcon from "../icons/SvgLogoIcon";
import React from "react";
import SvgULogo from "../icons/SvgULogo";


function SourceDisplay({item, isPerson}) {
    if (isPerson) {
        return (
            <p className='text-xs text-slate-500'>
                Source:&nbsp;
                {item?.source === 'club' ?
                    <SvgCompanyBuilding className={'w-4 h-4 mb-0.5 ml-1 inline'}/> :
                    item?.source === 'recruitu.io' || item?.source === "apollo" ?
                        <SvgULogo className={'w-2.5 h-2.5 mb-1 ml-1 mr-1 inline'}/> :
                        <SvgUserFeather className={'w-4 h-4 mb-0.5 ml-1 inline'}/>}
                {item?.source === 'club' ? 'Club' : item?.source === 'recruitu.io' || item?.source === "apollo" ? "RecruitU" : "Personal"}
            </p>
        )
    }
    return (
        <p className='text-xs text-slate-500'>
            Source:&nbsp;
            {!!item?.exclusive ?
                <SvgCompanyBuilding className={'w-4 h-4 mb-0.5 ml-1 mr-0.5 inline'}/> :
                !!item?.creator ?
                    <SvgUserFeather className={'w-4 h-4 mb-0.5 ml-1 inline'}/> :
                    <SvgULogo className={'w-3 h-3 mb-1 ml-1 mr-1 inline'}/>}
            {item?.exclusive ? item?.company_name : !!item?.creator ? "Personal" : "RecruitU"}
        </p>
    )
}

export default SourceDisplay
