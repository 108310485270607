import axios from '../axios';

export const bunnyCDNKey = "6c2982cc-7c9f-4595-8305ca500c06-b59e-4182";

export const uploadVideo = async (file, video_id) => {
    const config = {
        headers: {
        AccessKey: bunnyCDNKey,
        'Content-Type': 'application/octet-stream'
        }
    };
    const response = await axios.put(
        `https://ny.storage.bunnycdn.com/recruitu/hirevue/${video_id}.mp4`,
        file,
        config
    );

    console.log("VIDEO", `https://recruitu-hirevue.b-cdn.net/hirevue/${video_id}.mp4)`, response.status);
    return `https://recruitu-hirevue.b-cdn.net/hirevue/${video_id}.mp4`;

};