import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ModalComponent from "./ModalComponent";
import { useState } from "react";
import { connect } from "react-redux";
import { isProfileUpdateNeeded } from "../../../utils/profileUtils";
import { Button } from "../Button";


const mapStateToProps = (state) => ({
    isProfileUpdateReminderDismissed:
        state.userReducer.isProfileUpdateReminderDismissed,
});

const mapDispatchToProps = (dispatch) => ({
    setProfileUpdateReminderDismissed: () =>
        dispatch({ type: "SET_PROFILE_UPDATE_REMINDER_DISMISSED" }),
});

const ProfileUpdateReminder = ({
    isProfileUpdateReminderDismissed,
    setProfileUpdateReminderDismissed,
    user,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();

    const toggle = () => {
        setIsOpen(!isOpen);
        if (!isProfileUpdateReminderDismissed) {
            setProfileUpdateReminderDismissed();
        }
    };

    const handleUpdateProfile = () => {
        toggle();
        history.push("/profile");
    };

    useEffect(() => {
        if (!isProfileUpdateReminderDismissed && isProfileUpdateNeeded(user)) {
            setIsOpen(true);
        }
    }, [isProfileUpdateReminderDismissed, user]);

    const FooterComponent = (
        <div className="flex flex-row gap-4">
            <Button onClick={toggle} variant="secondary">
                Later
            </Button>
            <Button onClick={handleUpdateProfile} >
                Update my Profile
            </Button>
        </div>
    );

    return (
        <ModalComponent
            isOpen={isOpen}
            toggle={toggle}
            header="Keep your profile up to date"
            headerToggle
            backgroundColor={'white'}
            FooterComponent={FooterComponent}
        >
            <p className="text-md text-slate-600">
                Keep your profile up to date to boost your profile visibility
                and personalize your experience
            </p>
        </ModalComponent>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUpdateReminder);