import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {wait} from "@testing-library/user-event/dist/utils";
import {searchCompanies} from "../../../utils/typesense";
import {editPerson} from "../../../api/student/people";
import CollapseComponent from "../CollapseComponent";
import SvgAddCircle from "../../icons/SvgAddCircle";
import ModalComponent from "./ModalComponent";
import {Button} from "../Button";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}


class EditContactModal extends PureComponent {
    state = {
        firstName: '',
        lastName: '',
        title: '',
        industry: null,
        linkedin: "",
        companyName: "",
        loading: false,
        email: "",
        phone: "",
        companyId: "",
        grade: "",
        searchResults: [],
        changed: false,
        companySearchFocused: false
    }

    titleChanged = e => this.setState({title: e.target.value, changed: true})
    linkedInChanged = e => this.setState({linkedin: e.target.value, changed: true});
    firstNameChanged = e => this.setState({firstName: e.target.value, changed: true});
    lastNameChanged = e => this.setState({lastName: e.target.value, changed: true});
    emailChanged = e => this.setState({email: e.target.value, changed: true});
    secondaryEmailChanged = e => this.setState({secondaryEmail: e.target.value, changed: true})
    phoneChanged = e => this.setState({phone: e.target.value, changed: true});
    gradeChanged = e => this.setState({grade: e.target.value.slice(0, 4), changed: true});
    industryChanged = e => this.setState({industry: e.target.value, changed: true});


    companyChanged = async (e) => {
        const text = e.target.value;
        if (text === "") {
            this.setState({companyId: ""});
        }
        this.setState({companyName: text, changed: true});
        const searchResults = await searchCompanies(text);
        this.setState({searchResults});
    }

    setCompany = (result) => {
        this.setState({
            searchResults: [],
            companyId: result.id,
            companyName: result.name,
        })
    }

    componentDidMount = () => {
        this.setPersonInfo();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.person !== this.props.person) {
            this.setPersonInfo();
        }
    }

    setPersonInfo = () => {
        const person = this.props.person || {};
        this.setState({
            firstName: person.first_name,
            lastName: person.last_name,
            title: person.title,
            linkedin: person.linkedin,
            companyId: person.company_id,
            companyName: person.company_name,
            email: person.email,
            secondaryEmail: person.secondary_email,
            phone: person.phone,
            grade: person.grade,
            industry: person.industry,
        })
    }


    updatePerson = async () => {
        const newPerson = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            title: this.state.title,
            linkedin: this.state.linkedin,
            company_id: this.state.companyId,
            company_name: this.state.companyName,
            email: this.state.email,
            secondary_email: this.state.secondaryEmail,
            phone: this.state.phone,
            grade: !!this.state.grade ? String(this.state.grade) : "",
            industry: this.state.industry,
        };

        if (!!this.props.network) {
            const newNetwork = {...this.props.network};
            newNetwork.person = newPerson;
            this.props.updateNetwork(newNetwork);
        } else {
            this.props.getClubInfo();
        }
        const id = !!this.props.person.person_id ? this.props.person.person_id : this.props.person.id;
        await editPerson(this.props.authUser, id, newPerson);
        this.props.close()
    }

    onBlur = () => {
        this.setState({companySearchFocused: false});
        wait(300).then(() =>
            this.setState({searchResults: []})
        )
    }


    render() {
        const saveDisabled = !this.state.changed
        return (
            <ModalComponent
                isOpen={this.props.open}
                backgroundColor={'white'}
                header={'Edit Contact'}
                headerToggle
                size={'md'}
                toggle={this.props.close}
                FooterComponent={
                    <div className='flex-row flex flex-1 gap-3 pt-5'
                         style={{display: 'flex', marginTop: 25, justifyContent: 'flex-end'}}>
                        <Button onClick={this.props.close} variant='secondary'>Cancel</Button>
                        <Button
                            disabled={saveDisabled}
                            onClick={() => this.updatePerson()}
                            variant='default'
                        >
                            Save Changes
                        </Button>
                    </div>
                }
            >
                <div className='flex flex-col w-full gap-5'>
                    <div className='flex flex-row justify-between items-center'>
                        <div className='flex flex-col gap-5 flex-1 mr-5'>
                            <div className='flex flex-row items-center gap-3'>
                                <div className="col-plain flex-1">
                                    <p className="body3-bold text-slate-400 mb-1">
                                        First Name
                                    </p>
                                    <input
                                        value={this.state.firstName}
                                        onChange={this.firstNameChanged}
                                        placeholder={'John'}
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                                <div className="col-plain flex-1">
                                    <p className="body3-bold text-slate-400 mb-1">
                                        Last Name
                                    </p>
                                    <input
                                        value={this.state.lastName}
                                        onChange={this.lastNameChanged}
                                        placeholder={'Doe'}
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                                <div className="col-plain flex-1">
                                    <p className="body3-bold text-slate-400 mb-1">
                                        Grad Year
                                    </p>
                                    <input
                                        type={"number"}
                                        value={this.state.grade}
                                        onChange={this.gradeChanged}
                                        placeholder={'2025'}
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                            </div>
                            <div className="col-plain w-full">
                                <p className="body3-bold text-slate-400 mb-1">
                                    Company
                                </p>
                                <input
                                    value={this.state.companyName}
                                    onChange={this.companyChanged}
                                    onFocus={() => this.setState({companySearchFocused: true})}
                                    onBlur={this.onBlur}
                                    placeholder={'ie. Morgan Stanley'}
                                    className='input-light px-3 body2 base-black-50 w-full'
                                />
                                <div
                                    className={`relative ${!this.state.companySearchFocused && 'opacity-0'}`}>
                                    <CollapseComponent isOpen={this.state.companySearchFocused}>
                                        <div
                                            className='absolute left-0 right-0 bg-white border border-slate-200 rounded-xl overflow-hidden'>
                                            <div className=''>
                                                {this.state.searchResults.map(result => {
                                                    return (
                                                        <div
                                                            className='flex flex-row items-center gap-2 py-2 cursor-pointer hover:bg-slate-100 px-3'
                                                            onClick={() => {
                                                                this.setCompany(result)
                                                            }}>
                                                            <img src={result.logo_url} alt={'club-icon'}
                                                                 className='w-8 h-8 rounded-sm border object-contain bg-white border-slate-200 mr-2'/>
                                                            <div className='col-plain'>
                                                                <p className='body3'>{result.name}</p>
                                                                <p className='body4'>{result.sector}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                {this.state.companySearchFocused &&
                                                    <div
                                                        className='flex flex-row items-center gap-2 py-2 cursor-pointer text-slate-500 hover:bg-slate-100 px-3'
                                                        onClick={() => this.setCompany(this.state.companyName)}>
                                                        <div
                                                            className='w-8 h-8 flex flex-col items-center justify-center'>
                                                            <SvgAddCircle className={'w-5 h-5'}/>
                                                        </div>
                                                        <div className='col-plain'>
                                                            <p className='body3'>Add missing
                                                                company: {this.state.companyName}</p>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </CollapseComponent>
                                </div>
                            </div>
                            <div className="col-plain w-full">
                                <p className="body3-bold text-slate-400 mb-1">
                                    Title
                                </p>
                                <input
                                    value={this.state.title}
                                    onChange={this.titleChanged}
                                    placeholder={'ie. Risk Management Analyst'}
                                    className='input-light px-3 body2 base-black-50 w-full'
                                />
                            </div>
                            <div className="col-plain flex-1">
                                <p className="body3-bold text-slate-400 mb-1">
                                    Industry
                                </p>
                                <input
                                    value={this.state.industry}
                                    onChange={this.industryChanged}
                                    placeholder={'ie. Investment Banking'}
                                    className='input-light px-3 body2 base-black-50 w-full'
                                />
                            </div>
                            <div className="col-plain w-full">
                                <p className="body3-bold text-slate-400 mb-1">
                                    Primary Email
                                </p>
                                <input
                                    value={this.state.email}
                                    onChange={this.emailChanged}
                                    placeholder={'ie. John.Doe@doe.com'}
                                    className='input-light px-3 body2 base-black-50 w-full'
                                />
                            </div>
                            <div className="col-plain w-full">
                                <p className="body3-bold text-slate-400 mb-1">
                                    Secondary Email
                                </p>
                                <input
                                    value={this.state.secondaryEmail}
                                    onChange={this.secondaryEmailChanged}
                                    placeholder={'ie. John.Doe@doe.com'}
                                    className='input-light px-3 body2 base-black-50 w-full'
                                />
                            </div>
                            <div className="col-plain w-full">
                                <p className="body3-bold text-slate-400 mb-1">
                                    Phone
                                </p>
                                <input
                                    value={this.state.phone}
                                    onChange={this.phoneChanged}
                                    placeholder={'ie. 1234567890'}
                                    className='input-light px-3 body2 base-black-50 w-full'
                                />
                            </div>
                            <div className="col-plain w-full">
                                <p className="body3-bold text-slate-400 mb-1">
                                    LinkedIn
                                </p>
                                <input
                                    value={this.state.linkedin}
                                    onChange={this.linkedInChanged}
                                    placeholder={'ie. https://www.linkedin.com/in/john-doe/'}
                                    className='input-light px-3 body2 base-black-50 w-full'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(EditContactModal)
