import React, {Component} from "react";
import {
    EllipsisVerticalIcon,
    ArrowPathIcon,
  } from "@heroicons/react/24/outline";
import {withRouter} from "react-router-dom";
import Spinner from "../components/Spinner";
import SvgVisibility from "../components/icons/SvgVisibility";
import {connect} from "react-redux";
import SchoolLogo from "../components-recruiter/atoms/SchoolLogo";
import ProfilePicture from "../components-recruiter/atoms/ProfilePicture";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { Button } from "../components/atoms/Button";


function mapDispatchToProps(dispatch) {
    return {
        toggleStudentPeek: () => {
            dispatch({
                type: "TOGGLE_STUDENT_PEEK",
            })
        },
        setStudentPeekStudent: (user) => {
            dispatch({
                type: "SET_STUDENT_PEEK_STUDENT",
                user,
            })
        },
    }
}

class ClubApplicantTableItem extends Component {
    state = {
        profileError: false
    }

    openStudentPeek = () => {
        this.props.toggleStudentPeek();
        this.props.setStudentPeekStudent(this.props.item);
    }

    openStudentPage = (profileId) => {
        this.props.history.push(`/profile/${profileId}`);
    }

    render() {
        const columns = this.props.columns
        const user = this.props.item || {};
        return (
            <div className='flex flex-row items-center text-slate-500 cursor-pointer' onClick={() => this.openStudentPeek()}>
                <div
                    className={'flex flex-row items-center gap-2'}
                    style={{flex: columns[0].flex}}>
                    <ProfilePicture
                        image={user.profile_pic_url}
                        userID={user.id}
                        size={40}
                        className={'border border-slate-200'}
                    />
                    <div className={'flex flex-col gap-0.5 flex-1 mr-3'}>
                        <p className=' text-slate-800 font-semibold text-sm'>
                            {user.first_name} {user.last_name}
                        </p>
                        {user.tag &&
                            <div
                                className={`text-xs text-${user.tag.color}-500 bg-${user.tag.color}-100 px-2 rounded-full`}>
                                {user.tag.label}
                            </div>
                        }
                    </div>
                </div>
                <div style={{flex: columns[1].flex}}>
                    <p className='text-xs'>
                        {user.major.join(', ')}
                    </p>
                </div>
                <div style={{flex: columns[2].flex}}>
                    <p className='text-xs'>
                        {user.gpa}
                    </p>
                </div>
                <div style={{flex: columns[3].flex}}>
                    <p className='text-xs'>
                        {user.grade}
                    </p>
                </div>
                <div className="flex shrink-0 justify-end items-center gap-x-6" style={{flex: columns[4].flex}}>
                <Button
                  onClick={() => {
                    this.props.setApplicationToView(this.props.submission);
                    this.props.setViewApplicationModalOpen(true);
                  }}
                  icon={SvgVisibility}
                  variant="outline"
                >
                  View Application
                </Button>
                <Menu as="div" className="relative flex-none">
                  <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon
                      aria-hidden="true"
                      className="h-5 w-5"
                    />
                  </MenuButton>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-52 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <MenuItem>
                      <div
                        onClick={() => this.props.admitUser(this.props.submission, false)}
                        className="flex items-center justify-start px-3 py-1  data-[focus]:bg-gray-50 cursor-pointer"
                      >
                        <p className="mr-2 text-sm leading-6 text-gray-900">
                          Decline Application
                        </p>
                        {this.props.isLoadingDecline && <Spinner size={20} />}
                      </div>
                    </MenuItem>
                    <MenuItem>
                      <div
                        onClick={() => this.props.admitUser(this.props.submission, true)}
                        className="flex items-center justify-start px-3 py-1  data-[focus]:bg-gray-50 cursor-pointer"
                      >
                        <p className="text-sm leading-6 text-primary mr-2">
                          Approve as member
                        </p>
                        {this.props.isLoadingAdmit && <Spinner size={20} />}
                      </div>
                    </MenuItem>
                  </MenuItems>
                </Menu>
              </div>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(withRouter(ClubApplicantTableItem));
