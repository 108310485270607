import React, {Component} from "react";
import {Button} from "./Button";
import {withRouter} from "react-router-dom";

class AnimatedTabs extends Component {
    constructor(props) {
        super(props);
        this.indicatorRef = React.createRef();
    }
    state = {
        indicatorWidth: 0,
        indicatorLeft: 0,
        activeTabIndex: 0,
        routeChanging: false,
        hoveringIndex: null
    };

    componentDidMount() {
        this.onChange();
    }

    onChange = () => {
        const {tabs} = this.props;
        const activeTabIndex = tabs.findIndex((item) => item.title === this.props.activeTab);
        if (activeTabIndex !== -1) {
            this.setState({activeTabIndex, routeChanging: false}, () => {
                this.updateIndicatorPosition();
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.routeChanging || this.props.activeTab !== prevProps.activeTab) {
            this.onChange();
        }
    }

    updateIndicatorPosition = () => {
        const activeTabButton = this.indicatorRef.current;
        if (activeTabButton) {
            const tabWidth = activeTabButton.offsetWidth;
            const tabLeft = activeTabButton.offsetLeft;

            this.setState({
                indicatorWidth: tabWidth,
                indicatorLeft: tabLeft,
            });
        }
    };

    handleTabClick = (title, index) => {
        this.setState({routeChanging: true, activeTabIndex: index});
        this.props.changeTab(title)
    };

    render() {
        const {tabs} = this.props;
        const {indicatorWidth, indicatorLeft, activeTabIndex} = this.state;

        return (
            <div className={`relative mr-auto ${this.props.className}`}>
                <div
                    className="absolute bottom-0 top-0 z-1 bg-primary rounded-lg"
                    style={{
                        width: indicatorWidth,
                        left: indicatorLeft,
                        transition: "0.3s",
                        zIndex: 0,
                    }}
                ></div>
                <div className="flex flex-row flex-1 gap-2" style={{zIndex: 99999}}>
                    {tabs.map((item, index) => (
                        <Button
                            key={`animated-tabs-button${index}`}
                            variant={activeTabIndex === index ? "default" : "ghost"}
                            size={'default'}
                            ref={activeTabIndex === index ? this.indicatorRef : null}
                            onClick={() => this.handleTabClick(item.title, index)}
                            onMouseEnter={() => this.setState({hoveringIndex: index})}
                            onMouseLeave={() => this.setState({hoveringIndex: null})}
                            // style={{backgroundColor: 'transparent'}}
                        >
                            {item.icon && <item.icon
                                style={{transition: 'all 100ms ease'}}
                                className={`w-5 h-5 mr-1 ${activeTabIndex === index ? 'scale-110 opacity-100 text-white' : this.state.hoveringIndex === index ? 'opacity-100 text-neutral-400' : 'scale-100'}`}

                            />
                            }
                            <span
                                className={` z-[0] capitalize font-bold ${activeTabIndex === index ? 'text-white' : this.state.hoveringIndex === index ? 'text-neutral-400' : ''}`}>
                                {item.title}
                           </span>
                        </Button>
                    ))}
                </div>
            </div>
        );
    }
}

export default withRouter(AnimatedTabs);
