import React, {useEffect, useRef, useState} from "react";
import {TESTIMONIALS} from "./onboarding-constants";
import {Button} from "../atoms/Button";
import SvgChevronDown from "../icons/ChevronDown";

function OnboardingTestimonials() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [startPosition, setStartPosition] = useState(0);
    const containerRef = useRef(null);
    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? TESTIMONIALS.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === TESTIMONIALS.length - 1 ? 0 : prevIndex + 1));
    };

    const handleTouchStart = (e) => {
        setStartPosition(e.touches[0].clientX);
        setIsDragging(true);
    };

    const handleTouchMove = (e) => {
        if (!isDragging) return;
        const currentPosition = e.touches[0].clientX;
        const delta = startPosition - currentPosition;
        containerRef.current.style.transform = `translateX(calc(-${currentIndex * 75}% - ${delta}px))`;
    };

    const handleTouchEnd = (e) => {
        setIsDragging(false);
        const currentPosition = e.changedTouches[0].clientX;
        const delta = startPosition - currentPosition;
        if (delta > 50) {
            handleNext();
        } else if (delta < -50) {
            handlePrev();
        } else {
            containerRef.current.style.transform = `translateX(-${currentIndex * 75}%)`;
        }
    };

    useEffect(() => {
        if (!isDragging) {
            containerRef.current.style.transition = 'transform 0.5s';
            containerRef.current.style.transform = `translateX(-${currentIndex * 75}%)`;
        } else {
            containerRef.current.style.transition = 'none';
        }
    }, [currentIndex, isDragging]);
    return (
        <div className='relative w-[40vw] mb-60'>
            <div className='relative overflow-hidden'>
                <div
                    ref={containerRef}
                    className='flex transition-transform duration-500 relative'
                    style={{transform: `translateX(${currentIndex * 75}%)`}}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    {TESTIMONIALS.map((testimonial, index) => (
                        <div key={index} className='w-3/4 flex-shrink-0 p-8 ml-20'
                             style={{opacity: index === currentIndex ? 1 : 0.5}}>
                            <p className='text-sm mb-5'>{testimonial.text}</p>
                            <p className='md:text-xl font-semibold'>{testimonial.name}</p>
                            <p className='text-xs opacity-50'>{testimonial.since}</p>
                        </div>
                    ))}
                </div>
                <div className='absolute top-0 bottom-0 right-0 w-20'
                     style={{background: 'linear-gradient(to right, #3043A700, #3043A7)'}}
                />
                <div className='absolute top-0 bottom-0 left-0 w-12'
                     style={{background: 'linear-gradient(to left, #3043A700, #3043A7)'}}
                />
            </div>
            <div className='flex flex-row items-center justify-center gap-3 mt-2'>
                <div className='left-0 top-1/2 transform'>
                    <Button onClick={handlePrev} variant={'ghost'}><SvgChevronDown
                        className='rotate-90 scale-[1.5]'/></Button>
                </div>
                <div className='right-0 top-1/2 transform'>
                    <Button onClick={handleNext} variant={'ghost'}><SvgChevronDown
                        className='-rotate-90 scale-[1.5]'/></Button>
                </div>
            </div>
        </div>
    );
}

export default OnboardingTestimonials;
