import axios from '../axios';

export const removeClubMember = async (user, club_id, member_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            club_id, member_id
        }
        const res = await axios.put("/admin/club", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const removeAlumniMember = async (user, club_id, alumni_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            club_id, alumni_id
        }
        const res = await axios.put("/admin/alumni", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const assignClubAdmin = async (user, club_id, member_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/admin/club", {club_id, member_id}, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const removeClubAdmin = async (user, club_id, member_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {club_id, user_id: member_id}
        };
        const res = await axios.delete("/admin/club", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const sendHelpEmail = async (user, club_id, plain_text) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/admin/email", {club_id, plain_text}, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const createAlumni = async (user, params) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/admin/alumni", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const editEvent = async (user, event_id, params_to_edit) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            updated_params: params_to_edit,
            event_id,
        }
        const res = await axios.put("/admin/events", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}