import React, {PureComponent} from "react";
import JobBoardListItem from "../jobboard/JobBoardListItem";
import JobPeek from "../jobboard/JobPeek";
import SvgSearch from "../icons/SvgSearch";
import {connect} from "react-redux";
import JobBoardLoading from "../loading/JobBoardLoading";
import FadeInOnScroll from "../atoms/FadeInOnScroll";
import SvgPostAdd from "../icons/SvgPostAdd";
import SuggestJobModal from "../atoms/modals/SuggestJobModal";
import {NUM_JOBS_SHOWN} from '../../utils/constants';
import PageHeaderWrapper from "../wrappers/PageHeaderWrapper";
import {Button} from "../atoms/Button";
import {discoverJobs} from "../../api/student/jobs";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        applications: state.userReducer.applications,
        constants: state.userReducer.constants,
    };
}

class JobBoard extends PureComponent {
    constructor(props) {
        super(props);
        this.headingRef = React.createRef(); // Create a ref to access the component's DOM node
        this.state = {
            listingPreview: {},
            search: '',
            location: [],
            companyId: null,
            onsite: null,
            jobType: null,
            jobs: [],
            originalJobs: [],
            jobsLoading: true,
            moreJobsLoading: false,
            listingPreviewOpen: false,
            grid: false,
            classYear: null,
            hideMoreJobs: false,
            industry: [],
            roles: [],
            upcomingDeadlines: false,
            company: '',
            nextOffset: null,
            originalOffset: null,
        };
    }

    previewListing = (listing) => {
        this.setState({listingPreview: listing, listingPreviewOpen: true})
        // this.props.history.push(`/job/${listing?.id}`);
    }

    closeListingPreview = () => {
        this.setState({listingPreviewOpen: false})
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const companyId = queryParams.get("companyId")
        const companyName = queryParams.get("companyName")
        if (!!companyId) {
            this.setState({companyId, companyName})
            this.getJobInfo(companyId);
        } else {
            this.getJobInfo();
        }
    }

    getJobInfo = async (companyId=this.state.companyId) => {
        const params = {
            query: this.state.search,
            location: this.state.location,
            type: this.state.jobType,
            work_model: this.state.onsite,
            class_year: this.state.classYear,
            industry: this.state.industry,
            roles: this.state.roles,
            company_id: companyId || this.state.companyId,
            sort_by_deadline: this.state.upcomingDeadlines,
        }
        const { jobs, next_offset } = await discoverJobs(this.props.authUser, params);
        this.setState({jobs: jobs, originalJobs: jobs, jobsLoading: false, nextOffset: next_offset, originalOffset: next_offset}); // Update if there is possibility of no jobs being shown
    }

    getNextJobInfo = async () => {
        this.setState({moreJobsLoading: true});
       
        const params = {
            query: this.state.search,
            location: this.state.location,
            type: this.state.jobType,
            work_model: this.state.onsite,
            class_year: this.state.classYear,
            industry: this.state.industry,
            roles: this.state.roles,
            company_id: this.state.companyId,
            sort_by_deadline: this.state.upcomingDeadlines,
            offset: this.state.nextOffset,
        }
        const { jobs: newJobs, next_offset } = await discoverJobs(this.props.authUser, params);
        this.setState({
            jobs: [...this.state.jobs, ...newJobs],
            moreJobsLoading: false,
            hideMoreJobs: newJobs.length < NUM_JOBS_SHOWN,
            nextOffset: next_offset,
        });
    }

    handleSearchChange = (event) => {
        const text = event.target.value;
        this.setState({search: text});

        this.search(text, this.state.location, this.state.jobType, this.state.onsite, this.state.classYear, this.state.industry, this.state.roles, this.state.companyId);
    }


    search = async (text, location, jobType, onsite, class_year, industry, roles, companyId) => {
        if (text === "" && location === null && jobType === null && onsite === null && class_year === null && industry === null && companyId === null) {
            this.setState({jobs: this.state.originalJobs, hideMoreJobs: false, nextOffset: this.state.originalOffset});
            return
        }
     
        const params = {
            query: text,
            location: location,
            type: jobType,
            work_model: onsite,
            class_year: class_year,
            industry: industry,
            roles: roles,
            company_id: companyId,
        }
        const {jobs , next_offset} = await discoverJobs(this.props.authUser, params, false);
        this.setState({jobs: jobs, jobsLoading: false, nextOffset: next_offset});
    }

    updateLocation = (selectedLocation) => {
        const locationArray = [...this.state.location];

        let updatedLocation;
        if (locationArray.includes(selectedLocation)) {
            updatedLocation = locationArray.filter((item) => item !== selectedLocation);
        } else if (selectedLocation === null) {
            updatedLocation = [];
        } else {
            updatedLocation = [...locationArray, selectedLocation]
        }


        this.setState({ location: updatedLocation});
        this.search(this.state.search, updatedLocation, this.state.jobType, this.state.onsite, this.state.classYear, this.state.industry, this.state.roles, this.state.companyId);
    }

    updateIndustry = (selectedIndustry) => {
        const industryArray = [...this.state.industry];

        let updatedIndustry;
        if (industryArray.includes(selectedIndustry)) {
            updatedIndustry = industryArray.filter((item) => item !== selectedIndustry);
        } else if (selectedIndustry === null) {
            updatedIndustry = [];
        } else {
            updatedIndustry = [...industryArray, selectedIndustry]
        }

        this.setState({industry: updatedIndustry});
        this.search(this.state.search, this.state.location, this.state.jobType, this.state.onsite, this.state.classYear, updatedIndustry, this.state.roles, this.state.companyId);
    };

    updateRoles = (selectedRole) => {
        const rolesArray = [...this.state.roles];

        let updatedRoles;
        if (rolesArray.includes(selectedRole)) {
            updatedRoles = rolesArray.filter((item) => item !== selectedRole);
        } else if (selectedRole === null) {
            updatedRoles = [];
        } else {
            updatedRoles = [...rolesArray, selectedRole]
        }

        this.setState({roles: updatedRoles});
        this.search(this.state.search, this.state.location, this.state.jobType, this.state.onsite, this.state.classYear, this.state.industry, updatedRoles, this.state.companyId);
    };

    updateJobType = (jobType) => {

        this.setState({jobType});
        this.search(this.state.search, this.state.location, jobType, this.state.onsite, this.state.classYear, this.state.industry, this.state.roles, this.state.companyId);
    }

    updateOnsite = (onsite) => {
        this.setState({onsite});
        this.search(this.state.search, this.state.location, this.state.jobType, onsite, this.state.classYear, this.state.industry, this.state.roles, this.state.companyId);
    }

    updateClassYear = (classYear) => {

        this.setState({classYear});
        this.search(this.state.search, this.state.location, this.state.jobType, this.state.onsite, classYear, this.state.industry, this.state.roles, this.state.companyId);
    }

    setCompany = (result) => {
        this.setState({
            companyId: result.id,
            companyName: result.name,
        })
        this.search(this.state.search, this.state.location, this.state.jobType, this.state.onsite, this.state.classYear, this.state.industry, this.state.roles, result.id);
    }

    render() {
        const INDUSTRY_TAGS = this.props.constants.industry_tags.map(tag => ({title: tag, value: tag})); 
        const SECONDARY_TAGS = this.props.constants.secondary_tags.map(tag => ({title: tag, value: tag})); 
        const LOCATION_TAGS = this.props.constants.locations.map(loc => ({title: loc.city})); 
        return (
            <>
                <PageHeaderWrapper
                    className={'pt-2'}
                    search={{
                        placeholder: 'Search jobs...',
                        value: this.state.search,
                        onChange: this.handleSearchChange,
                    }}
                    selects={[
                        {
                            label: 'Location',
                            value: this.state.location,
                            setValue: this.updateLocation,
                            options:LOCATION_TAGS,
                            multiSelect: true,
                        },
                        {
                            label: 'Job type',
                            value: this.state.jobType,
                            setValue: this.updateJobType,
                            options: [{title: "Internship",}, {title: "Full Time",}, {title: "Early Insight Program",}]
                        },
                        // {
                        //     label: 'On-site/remote',
                        //     value: this.state.onsite,
                        //     setValue: this.updateOnsite,
                        //     options: [{title: "On-site",}, {title: "Remote",}, {title: "Hybrid",},]
                        // },
                        {
                            label: 'Class year',
                            value: this.state.classYear,
                            setValue: this.updateClassYear,
                            options: [{title: "2025",}, {title: "2026"}, {title: '2027'}, {title: '2028'},]
                        },
                        {
                            label: 'Industry',
                            value: this.state.industry,
                            setValue: this.updateIndustry,
                            options: INDUSTRY_TAGS,
                            multiSelect: true,
                        },
                        {
                            label: 'Roles',
                            value: this.state.roles,
                            setValue: this.updateRoles,
                            options: SECONDARY_TAGS,
                            multiSelect: true,
                        },
                    ]}
                    setCompany={this.setCompany}
                    company={{id: this.state.companyId, name: this.state.companyName}}

                />
                <div className='overflow-hidden flex-1'>
                    <div className={'overflow-y-scroll hide-scrollbar w-full flex-1 pb-5 px-5'}>
                        {this.state.jobsLoading ?
                            <JobBoardLoading/>
                            :
                            this.state.jobs.length === 0 ?
                                <div className='my-8 flex text-neutral-400 flex-col items-center justify-center flex-1'>
                                    <SvgSearch/>
                                    <p className='body2 mt-2 mb-6'>
                                        No listings found...
                                    </p>
                                    <SuggestJobModal authUser={this.props.authUser}>
                                        <Button variant={'secondary'} icon={SvgPostAdd}>
                                            Suggest a job
                                        </Button>
                                    </SuggestJobModal>
                                </div>
                                :
                                <>
                                    {!this.state.grid ?
                                        <>
                                            {this.state.jobs.map((item, index) =>
                                                <FadeInOnScroll key={item.id} triggerImmediately
                                                                delay={(index * 50) + 10}>
                                                    <JobBoardListItem key={item.id} item={item}
                                                                      previewListing={this.previewListing}
                                                                      application={this.props.applications[item?.id]}
                                                                      previewingID={this.state.listingPreview.id}
                                                                      authUser={this.props.authUser}
                                                    />
                                                </FadeInOnScroll>
                                            )}
                                        </>
                                        :
                                        <div className='grid grid-cols-3 gap-4'>
                                            {this.state.jobs.map((item, index) =>
                                                <FadeInOnScroll key={item.id} triggerImmediately
                                                                delay={(index * 50) + 10}>
                                                    <JobBoardListItem grid key={item.id} item={item}
                                                                      previewListing={this.previewListing}
                                                                      application={this.props.applications[item?.id]}
                                                                      authUser={this.props.authUser}/>

                                                </FadeInOnScroll>
                                            )}
                                        </div>
                                    }
                                </>
                        }

                        {(this.state.nextOffset != null && (!this.state.jobsLoading && this.state.jobs.length !== 0)) &&
                            <div className='flex flex-row items-center justify-center py-8 body2-bold'
                                 onClick={this.getNextJobInfo}>
                                <Button loading={this.state.moreJobsLoading} variant={'ghost'}>
                                    Load more jobs
                                </Button>
                            </div>
                        }
                    </div>
                    <JobPeek
                        listing={this.state.listingPreview}
                        open={this.state.listingPreviewOpen}
                        close={this.closeListingPreview}
                        application={this.props.applications[this.state.listingPreview.id]}
                        authUser={this.props.authUser}/>

                </div>
            </>
        )
    }
}

export default connect(mapStateToProps)(JobBoard);
