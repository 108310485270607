import React, {Component} from "react";
import RecruiterCompanyAddDeal from "../modals/RecruiterCompanyAddDeal";
import SvgRemove from "../../components/icons/SvgRemove";
import SvgEyeOff from "../../components/icons/SvgEyeOff";
import SvgVisibility from "../../components/icons/SvgVisibility";
import SvgPlus from "../../components/icons/SvgPlus";
import { DateTime } from 'luxon';

class RecruiterCompanyPageDeals extends Component {
    state = {
        editDealsModalOpen: false,
        addDealsModalOpen:false
    }

    openEditDealsModal = () => {
        this.setState({editDealsModalOpen: true})
    }

    closeEditDealsModal = () => {
        this.setState({editDealsModalOpen: false})
    }

    openAddDealsModal = () => {
        this.setState({addDealsModalOpen: true})
    }

    closeAddDealsModal = () => {
        this.setState({addDealsModalOpen: false})
    }
    render() {
        const company = this.props.company || {};
        const deals = company.deals || [];
        const hidden = this.props.hidden || [];
        return (
            <div
                className={`bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl ${hidden.includes("deals") && 'opacity-50'}`}>
                <div className='flex flex-row items-center gap-2 mb-2'>
                    <p className='text-xl font-semibold text-slate-800'>
                        Deals
                    </p>
                    <button
                        onClick={this.openAddDealsModal}
                        className='text-slate-500 hover:text-primary cursor-pointer'>
                        <SvgPlus className={'w-4 h-4'}/>
                    </button>
                    <button
                        onClick={() => this.props.hide("deals")}
                        className='text-slate-500 hover:text-primary cursor-pointer'>
                        {hidden.includes("deals") ?
                            <SvgVisibility className={'w-4 h-4'}/>
                            :
                            <SvgEyeOff className={'w-4 h-4'}/>
                        }
                    </button>
                </div>
                <div className='flex flex-col gap-8'>
                    {deals.length === 0 &&
                        <p className='text-slate-500'>
                            No deals listed yet
                        </p>
                    }
                    {deals.map((item, index) =>
                        <div className='flex flex-col gap-2'>
                            <p className='text-slate-500 text-sm'>
                                {DateTime.fromISO(item.date).isValid ? DateTime.fromISO(item.date).toFormat("MMM d, yyyy") + " - ": ""}{item.company_name}
                            </p>
                            <img
                                alt={'deal'}
                                src={item.logo_url}
                                className='w-1/3 object-contain'
                            />
                            <div>
                                <p className='text-slate-800 font-semibold text-md'>
                                    {item.title}
                                </p>
                                <p className='text-slate-500 text-sm'>
                                    {item.description}
                                </p>
                            </div>
                            <div onClick={() => this.props.removeItem("deals", index)}
                                className={'p-2 cursor-pointer rounded-full bg-slate-50 hover:bg-red-400 hover:text-white text-red-400 top-2 right-2'}>
                                <SvgRemove className={'w-5 h-5'}/>
                            </div>
                        </div>
                    )}
                </div>
                <RecruiterCompanyAddDeal
                    isOpen={this.state.addDealsModalOpen}
                    closeModal={this.closeAddDealsModal}
                    company={this.props.company}
                />
            </div>
        )
    }
}

export default RecruiterCompanyPageDeals;
