import * as Dialog from '@radix-ui/react-dialog';
import SvgClose from "../../icons/SvgClose";
import {createReport} from "../../../api/student/reports";
import {useState} from "react";

export default (props) => {
    const [message, setMessage] = useState("")
    return (
    <Dialog.Root>
        <Dialog.Trigger asChild>
            {props.children}
        </Dialog.Trigger>
        <Dialog.Portal>
            <Dialog.Overlay className="DialogOverlay"/>
            <Dialog.Content className="DialogContent">
                <Dialog.Title className="title2 text-neutral-900">Report issue with this job?</Dialog.Title>
                <Dialog.Description className="DialogDescription body2 text-neutral-400">
                    Let us know whats wrong and we'll look into it. Thanks for helping make RecruitU great!
                </Dialog.Description>
                <label htmlFor="message" className="block mb-2 body2-bold text-neutral-400 dark:text-white">
                    Your note
                </label>
                <textarea id="message" rows="4"
                          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Describe the issue here..." onChange={(e) => setMessage(e.target.value)}></textarea>
                <div style={{display: 'flex', marginTop: 25, justifyContent: 'flex-end'}}>
                    <Dialog.Close asChild>
                        <button className="primary-button body1-bold" onClick={(e) => createReport(props.authUser, props.reportType, message, {job_id: props.job_id})}>Submit Report</button>
                    </Dialog.Close>
                </div>
                <Dialog.Close asChild>
                    <button className="IconButton" aria-label="Close">
                        <SvgClose/>
                    </button>
                </Dialog.Close>
            </Dialog.Content>
        </Dialog.Portal>
    </Dialog.Root>
)};
