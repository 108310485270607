import React, {Component} from "react";
import Avatar from "boring-avatars";
import SvgSchool from "../../components/icons/SvgSchool";
import { COLLEGE_LOGOS_MAP } from "../../utils/constants";

class SchoolLogo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageError: false
        };
    }

    handleImageError = () => {
        this.setState({imageError: true});
    };

    render() {
        const {imageError} = this.state;
        const {image, college} = this.props;
        const defaultImage = !!college && college in COLLEGE_LOGOS_MAP ? COLLEGE_LOGOS_MAP[college] : null
        
        if (imageError || (!image && !defaultImage)) {
            return (
                <div className={`rounded-xl overflow-hidden aspect-square h-min relative  ${this.props.className}`}
                style={{width:this.props.size || 40, height:this.props.size || 40}}
                >
                    <div className='absolute cover-parent bg-white/80 flex flex-col items-center justify-center'>
                        <SvgSchool className={'w-6 h-6 mx-auto'}/>
                    </div>
                    <Avatar
                        size={this.props.size || 40}
                        name={this.props.clubID || 'fallback'}
                        variant="marble"
                        square
                        colors={['#d4daff', '#b8bff8', '#5b62c2', '#8fa3ff', '#3D4CB6']}
                    />
                </div>
            )
        }
        return (
            <div className={`rounded-xl overflow-hidden aspect-square h-min  ${this.props.className}`}
                 style={{width:this.props.size || 40, height:this.props.size || 40}}
            >
                <img
                    src={image || defaultImage}
                    onError={this.handleImageError}
                    className={'object-cover bg-slate-50'}
                    style={{width: this.props.size || 40, height: this.props.size || 40}}
                    alt='Club Logo'
                />
            </div>
        );
    }
}

export default SchoolLogo;
