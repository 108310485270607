import React, {PureComponent} from "react";
import NetworkingListShimmers from "../../loading/NetworkingListShimmers";
import SvgEmpty from "../../icons/SvgEmpty";
import ClubPagePost from "../ClubPagePost";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

class ClubFeedTab extends PureComponent {
    render() {
        const {loading, posts, club, getClubInfo, openEventPage, isAdmin, containerClassName} = this.props;
        const featuredEvents = club.featured_events || [];

        return (
            <div
                className={`flex flex-col gap-3 items-center flex-1 overflow-y-scroll hide-scrollbar ${featuredEvents.length > 0 && 'grid grid-cols-3'} gap-4 pt-3 ${containerClassName || 'px-5'}`}>
                {loading ?
                    <>
                        <NetworkingListShimmers/>
                    </>
                    :
                    posts.length === 0 ?
                        <div className='flex flex-col items-center py-32 gap-5'>
                            <SvgEmpty className={'w-8 h-8 text-neutral-400'}/>
                            <p className='body2 text-neutral-400'>
                                No one in the club has posted a listing. Add one above.
                            </p>
                        </div>
                        :
                        <div className='flex flex-col w-full gap-2'>
                            {posts.map(post => (
                                <ClubPagePost post={post} club={club}
                                              getClubInfo={getClubInfo}
                                              isAdmin={isAdmin}
                                              openEventPage={openEventPage}
                                              authUser={this.props.authUser}
                                              editPost={this.props.editPost}/>
                            ))}
                        </div>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps)(ClubFeedTab);
