import React, { Component } from "react";
import CompanyLogo from "../atoms/CompanyLogo";
import CompanyStatusTrackerComponent from "../atoms/CompanyStatusTrackerComponent";
import TagComponent from "../atoms/TagComponent";
import Checkbox from "../atoms/Checkbox";
import SvgUsers from "../icons/SvgUsers";
import { editTracker } from "../../api/student/trackers";
import RuDirectLabel from "../atoms/RuDirectLabel";

class CompanyResultItem extends Component {
    toggleCheckbox = async () => {
        const newTracker = { ...this.props.tracker };
        const newStatus = !newTracker.status;
        newTracker.status = newStatus;
        this.props.updateTracker(newTracker);
        await editTracker(this.props.authUser, this.props.tracker.id, {
            status: newStatus,
        });
    };

    render() {
        const company = this.props.item || {};
        const isPartner = company.is_partner ?? false;
        return (
            <div
                onClick={() => this.props.openCompanyPeek(company)}
                className={`card hover-card mb-2 p-3`}
            >
                <div className="flex flex-col gap-1">
                    {isPartner && <RuDirectLabel />}
                    <div
                        className={
                            "flex flex-row justify-between items-center gap-3"
                        }
                    >
                        <div>
                            <div className="flex flex-row gap-3 flex-1 items-center">
                                <CompanyLogo
                                    id={company.id}
                                    className={"w-20 h-20"}
                                />
                                <div className="flex flex-col items-start gap-1">
                                    <p className="text-xl font-semibold">
                                        {company.name}
                                    </p>
                                    <div className="row-ac text-xs text-slate-700 font-semibold">
                                        <SvgUsers className={"w-3 h-3"} />
                                        <p className="m-0 ml-8">
                                            {company.num_employees} employees
                                        </p>
                                    </div>
                                    <div className="flex flex-row items-center gap-3">
                                        {!!company.specialties &&
                                        company.specialties.length > 0 ? (
                                            <div className="flex flex-row gap-1 mt-1 whitespace-nowrap flex-wrap">
                                                {company?.specialties.map(
                                                    (specialty) => (
                                                        <TagComponent
                                                            label={specialty}
                                                        />
                                                    )
                                                )}
                                            </div>
                                        ) : (
                                            <TagComponent
                                                label={company.sector}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!!this.props.tracker ? (
                            <Checkbox
                                checked={this.props.tracker?.status}
                                onClick={this.toggleCheckbox}
                                label={"Offered"}
                            />
                        ) : (
                            <div>
                                <CompanyStatusTrackerComponent
                                    companyId={company.id}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyResultItem;
