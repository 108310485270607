import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import { Button } from "../components/atoms/Button";


class SavedSearchCard extends Component {

    navigateSavedSearch = (searchId) => {
        this.props.history.push(`/search?searchId=${searchId}`)
    }

    render() {
        const item = this.props.item;
        const index = this.props.index;
        return (

            <div key={index}
                className='border flex flex-row items-center border-slate-200 hover:border-slate-300 hover:bg-slate-50 cursor-pointer rounded-lg p-3 text-sm card'>
            <div>
                {!!item.grades.length && <p><strong>Class Years:</strong> {item.grades.join(", ")}</p>}
                {!!item.majors.length && <p><strong>Majors:</strong> {item.majors.join(", ")}</p>}
                {!!item.gpa && <p><strong>GPA:</strong> {item.gpa !== null ? item.gpa : "N/A"}</p>}
                {!!item.college.length && <p><strong>College:</strong> {item.college}</p>}
            </div>
            <Button className='ml-auto' onClick={() => this.navigateSavedSearch(item.id)}>
                Search
            </Button>
        </div>
        )
    }
}

export default withRouter(SavedSearchCard)
