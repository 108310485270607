import React, { useEffect, useState } from 'react';
import OnboardingStepHeader from './OnboardingStepHeader';
import OnboardingStepWrapper from './OnboardingStepWrapper';
import OnboardingStepButtons from './OnboardingStepButtons';
import SelectableItem from './SelectableItem';
import {ONBOARDING_CITIES} from "./onboarding-constants";
import SelectedTag from './SelectedTag';

function OnboardingCitiesStep({ goNextStep, goPreviousStep, onCitiesSelected, cities }) {
    const [selectedCities, setSelectedCities] = useState([]);
    const [sortOrder, setSortOrder] = useState('popularity');

    const handleCityClick = (label) => {
        if (selectedCities.includes(label)) {
            setSelectedCities(selectedCities.filter(item => item !== label));
        } else {
            setSelectedCities([...selectedCities, label]);
        }
    };

    const handleSortChange = (e) => {
        setSortOrder(e.target.value);
    };

    const sortedCities = [...ONBOARDING_CITIES].sort((a, b) => {
        if (sortOrder === 'alphabetical') {
            return a.city.localeCompare(b.city);
        }
        return 0; // Replace with actual popularity sorting logic if available
    });

    const handleNext = () => {
        onCitiesSelected(selectedCities);
        goNextStep();
    };

    useEffect(() => {
        setSelectedCities(cities);
    }, [])

    return (
        <OnboardingStepWrapper>
            <OnboardingStepHeader
                title={'Where do you want to work?'}
                description={'Pick the cities you’d be excited to work in. You can always change this later.'}
            />
            <div className='flex flex-col gap-3 md:gap-5'>
                <p className='text-lg md:text-xl font-semibold'>Your picks</p>
                <div className='flex flex-wrap gap-2 max-h-[10vh] md:max-h-[auto] overflow-y-auto'>
                    <p className='text-slate-500 text-sm md:text-base hidden only:flex h-9'>
                        Select cities below...
                    </p>
                    {selectedCities.map(cityKey => (
                        <SelectedTag key={cityKey} label={cityKey} onClose={handleCityClick}/>
                    ))}
                </div>
                <div className='flex flex-row items-center justify-between'>
                    <p className='text-lg md:text-xl font-semibold'>Cities</p>
                    <select onChange={handleSortChange} value={sortOrder} className='text-xs md:text-sm font-medium'>
                        <option value="popularity">Sort by popularity</option>
                        <option value="alphabetical">Sort alphabetically</option>
                    </select>
                </div>
                <div>
                    <div
                        className='grid grid-cols-2 md:grid-cols-3 gap-2 max-h-[20vh] lg:max-h-[30vh] overflow-y-scroll border-b border-slate-200'>
                        {sortedCities.map(({city, state}) => (
                            <SelectableItem
                                key={`${city}-${state}`}
                                label={`${city}, ${state}`}
                                isSelected={selectedCities.includes(`${city}, ${state}`)}
                                onClick={handleCityClick}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <OnboardingStepButtons
                goNextStep={handleNext}
                goPreviousStep={goPreviousStep}
            />
        </OnboardingStepWrapper>
    );
}

export default OnboardingCitiesStep;
