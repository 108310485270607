import React, { useState, useEffect, createContext } from "react";
import { firebaseAuth } from "../api/firebase";
import { analytics } from "../utils/june";
import { getUser } from '../api/student/users';
import useVerificationCallback from "../hooks/useVerificationCallback";

export const UserContext = createContext({user: null});

const UserProvider = ({ children, userData }) => {
    const [user, setUser] = useState(0);
    useVerificationCallback();

    useEffect(() => {
        firebaseAuth.onAuthStateChanged(async userAuth => {
            if (userAuth) {
                userAuth.getIdTokenResult().then(async idTokenResult => {
                    if (userAuth.userType === "student") {
                        const profile = (await getUser(userAuth, userAuth.uid))?.user;
                        analytics.identify(userAuth.uid, {
                            firstName: userData?.first_name,
                            lastName: userData?.last_name,
                            name: `${userData?.first_name} ${userData?.last_name}`,
                            email: userAuth.email,
                            clubs: profile?.clubs.map(club => ({
                                name: club.name,
                                isExec: Object.keys(club.leadership).includes(userAuth.uid),
                                isPointOfContact: club?.points_of_contact.map(poc => poc.email).includes(userAuth.email),
                            })),
                            classYear: profile?.grade || "",
                            school: profile?.college || "",
                            major: profile?.major
                        });
                        analytics.track('Signed In',
                            {browser: 'chrome'});
                    }
                   
                    const claims = idTokenResult.claims;
                    const userType = !!claims.recruiter ? "recruiter" : "student";
                    userAuth.userType = userType;
                    setUser(userAuth);
                })
            } else {
                setUser(null);
            }
        })
    }, [userData]);

    return (
        <UserContext.Provider value={user}>
            {children}
        </UserContext.Provider>
    );
}

export default UserProvider;
