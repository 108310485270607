import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'} d="M480-160l-42-43 247-247H160v-60h525L438-757l42-43 320 320-320 320z" />
        </svg>
    )
}

export default SvgComponent
