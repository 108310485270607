import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'} d="M248-246l-42-42 412-412H240v-60h480v480h-60v-378L248-246z"/>
        </svg>
    )
}

export default SvgComponent
