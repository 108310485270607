import React, {PureComponent} from "react";
import SvgSearch from "../icons/SvgSearch";
import {connect} from "react-redux";
import JobBoardLoading from "../loading/JobBoardLoading";
import {searchClubs, searchColleges} from "../../utils/typesense";
import SearchHeaderWrapper from "../../components-recruiter/atoms/SearchHeaderWrapper";
import {Button} from "../atoms/Button";
import RecruiterSchoolClubCollapse from "../../components-recruiter/RecruiterSchoolClubCollapse";
import FadeInOnScroll from "../atoms/FadeInOnScroll";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        applications: state.userReducer.applications,
    };
}

class RecruiterCollegeSearch extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            clubPreview: {},
            search: '',
            filterFavorites: false,
            clubs: [],
            colleges: [],
            originalClubs: [],
            loading: false,
            moreCollegesLoading: false,
            clubPreviewOpen: false,
            page: 1,
            hideMoreClubs: false,
        };
    }

    previewClub = (club) => {
        this.setState({clubPreview: club, clubPreviewOpen: true})
    }

    closeClubPreview = () => {
        this.setState({clubPreviewOpen: false})
    }

    componentDidMount = async () => {
        this.search(" ")
    }

    getNextCollegeInfo = async () => {
        if (!this.props.showPaywall) {
            this.setState({moreCollegesLoading: true});
            const newColleges = await searchColleges(this.state.search, this.state.page + 1);

            const colleges = [];
            for (const college of newColleges) {
                const clubs = await searchClubs("", 1, 100, college.name)
                if (clubs.length) {
                    college.clubs = clubs;
                    colleges.push(college)
                }
            }
            console.log("col", colleges.length)
            this.setState({
                colleges: [...this.state.colleges, ...colleges],
                moreCollegesLoading: false,
                page: this.state.page + 1,
                hideMoreColleges: colleges.length === 0,
            })
        }
    }

    handleSearchChange = (event) => {
        if (!this.props.showPaywall) {
            const text = event.target.value;
            this.setState({search: text});

            this.search(text);
        }
    }

    search = async (text) => {
        if (text === "") {
            this.setState({clubs: this.state.originalClubs, hideMoreClubs: false, page: 1});
            return;
        }

        const colleges = [];
        const collegeResults = await searchColleges(text)
        for (const college of collegeResults) {
            const clubs = await searchClubs("", 1, 100, college.name)
            if (clubs.length) {
                college.clubs = clubs;
                colleges.push(college)
            }
        }
        this.setState({loading: false, colleges})
        window.analytics.track("Recruiter Club Search Performed", {
            query: text
        })
    }

    clearFilters = () => {
        this.setState({
            filterFavorites: false,
            page: 1
        });
        this.setState({clubs: this.state.originalClubs});
    }

    render() {
        const clearFiltersVisible = this.state.state || this.state.school || this.state.filterFavorites;
        return (
            <div className='flex flex-col overflow-hidden flex-1'>
                <SearchHeaderWrapper
                    search={{
                        placeholder: 'Search schools...',
                        value: this.state.search,
                        onChange: this.handleSearchChange,
                    }}
                    // checkbox={{
                    //     label: 'Favorited',
                    //     active: this.state.filterFavorites,
                    //     onClick: () => this.setState({filterFavorites: !this.state.filterFavorites})
                    // }}
                >
                </SearchHeaderWrapper>
                <div className={'flex flex-1 flex-col overflow-y-scroll'}>
                    <>
                        {this.state.loading ?
                            <JobBoardLoading/>
                            :
                            this.state.colleges.length === 0 ?
                                <div className='my-8 flex text-neutral-400 flex-col items-center justify-center flex-1'>
                                    <SvgSearch/>
                                    <p className='body2 mt-2 mb-6'>
                                        No colleges found...
                                    </p>
                                </div>
                                :
                                <div className='flex flex-col gap-3 mt-5'>
                                    {this.state.colleges.map((school, index) =>
                                        <FadeInOnScroll key={school.id} triggerImmediately
                                                        delay={(index * 50) + 10}>
                                            <RecruiterSchoolClubCollapse
                                                school={school}
                                                previewClub={this.previewClub}
                                                clubPreview={this.state.clubPreview}
                                            />
                                        </FadeInOnScroll>
                                    )}
                                </div>
                        }
                        {(!this.state.hideMoreColleges && (!this.state.loading && this.state.colleges.length !== 0)) &&
                            <div className='flex flex-row items-center justify-center py-8 body2-bold'
                                 onClick={this.getNextCollegeInfo}>
                                <Button loading={this.state.moreCollegesLoading} variant={'ghost'}>
                                    Load more colleges
                                </Button>
                            </div>
                        }
                    </>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(RecruiterCollegeSearch);
