import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="30px"
            height="30px"
            {...props}
        >
            <path fill={'currentColor'}
                  d="M4 2a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2H4zm9.586 4L15 7.414l-6 6-4-4L6.414 8 9 10.586 13.586 6zM20 6v14H6v2h14a2 2 0 002-2V6h-2z"/>
        </svg>
    )
}

export default SvgComponent
