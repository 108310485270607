import { useState, useEffect } from "react";
import SvgClose from "../../components/icons/SvgClose";
import { connect } from "react-redux";
import { downloadAllResumes } from "../../api/student/clubs";
import * as Dialog from "@radix-ui/react-dialog";
import { Button } from "../../components/atoms/Button";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../utils/constants";
import { getAllClubMembers } from "../../api/clubAdmin/clubs";

const mapStateToProps = (state) => {
  return {
    authUser: state.userReducer.authUser,
  };
};

function DownloadResumesModal(props) {
  const [error, setError] = useState(false);

  const handleDownload = async (year = "*") => {
    try {
      const res = await getAllClubMembers(props.authUser, props.clubId, props.status, props.year);
      console.log("r", res.members)
      const resumePromise = downloadAllResumes(props.authUser, res.members);
      toast.promise(
        resumePromise,
        {
          pending: "Downloading resumes...",
          success: "Resumes downloaded successfully!",
          error: "No resumes available for these filters",
        },
        TOAST_OPTIONS
      );
      const resumeResult = await resumePromise;
      if (!resumeResult) {
        setError(true);
      } else {
        setError(false);
      }
    } catch (error) {
      console.log("Error no resumes this year");
    }
  };


  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{props.children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent w-[60vw]">
          <Dialog.Title className="title2 text-neutral-900">
            Download Resume Booklet
          </Dialog.Title>
          <div className="flex flex-col w-full gap-5 mt-5">
            <div>
              <p className="text-sm text-slate-700 mb-2">Search terms</p>
              {/* {props.memberName && (
                <p className=" text-slate-900 font-semibold">
                  {`Search term:   ${props.memberName}`}
                </p>
              )} */}
              {props.status && (
                <p className=" text-slate-900 font-semibold">
                  {`Job Status:   ${props.status}`}
                </p>
              )}
              {props.year && (
                <p className="text-slate-900 font-semibold">
                  {`Graduation Year:   ${props.year}`}
                </p>
              )}
              {!props.status && !props.year && (
                <p className="text-slate-900 font-semibold">All Members</p>
              )}
              <p className="text-sm my-4 text-slate-700">{`All resumes added by members will be added to the generated booklet.`}</p>
            </div>
            <div className="flex flex-row gap-3">
              <Button
                className="basis-full"
                onClick={() => handleDownload()}
              >{`Generate and Download Booklet`}</Button>
            </div>
            {error ? (
              <p className="w-full text-center text-rose-700 font-semibold">
                No resumes available for these students
              </p>
            ) : null}
          </div>
          <Dialog.Close asChild>
            <button className="IconButton mt-4" aria-label="Close">
              <SvgClose />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default connect(mapStateToProps, null)(DownloadResumesModal);
