import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 96 960 960"
            width={48}
            {...props}
        >
            <path
                fill={props.fill || 'currentColor'}
                d="M120 936V378h247v-92l113-110 113 110v258h247v392H120zm60-60h106V770H180v106zm0-166h106V604H180v106zm0-166h106V438H180v106zm247 332h106V770H427v106zm0-166h106V604H427v106zm0-166h106V438H427v106zm0-166h106V272H427v106zm247 498h106V770H674v106zm0-166h106V604H674v106z" />
        </svg>
    )
}

export default SvgComponent
