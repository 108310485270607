import React, {PureComponent} from "react";
import SvgBriefcase from "../../components/icons/SvgBriefcase"
import SvgCalendar from "../icons/SvgCalendar";


class SelectPostType extends PureComponent {
    select = (type) => {
        this.props.changeType(type);
        this.props.nextStep();
    }

    render() {
        const options = [
            {title: 'job', icon: SvgBriefcase},
            {title: 'event', icon: SvgCalendar},
        ]
        return (
            <div className="col-plain w-full gap-3">
                <div className='flex flex-col gap-1'>
                    <p className="body3-bold text-neutral-400 mb-1">
                        Type
                    </p>

                    {options.map(option => 
                        <div className='card mb-0 flex flex-row items-center justify-between hover-card gap-2' onClick={() => this.select(option.title)}>
                            <div className='mb-0 flex flex-row items-center gap-2'>
                                <div className='flex flex-row items-center gap-2 flex-1'>
                                    <option.icon
                                        style={{transition: 'all 100ms ease'}}
                                        className={`w-5 h-5 mr-1 ${true ? 'opacity-100 text-neutral-400' : 'scale-100'}`}

                                    />
                                    <div className='flex flex-1'>
                                        <p className='body1-bold'>
                                            {option.title.charAt(0).toUpperCase() + option.title.slice(1)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default SelectPostType
