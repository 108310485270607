import React from "react";


function OnboardingStepHeader({title, description}) {
    return (
        <div className='flex flex-col gap-2 md:pt-2'>
            <p className='font-bold text-xl md:text-2xl xl:text-3xl text-center md:text-left'>
                {title}
            </p>
            <p className='text-sm xl:text-base text-slate-500'>
                {description}
            </p>
        </div>
    )
}

export default OnboardingStepHeader
