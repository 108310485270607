import React, { useEffect, useState } from "react";

const Stopwatch = ({ isRecording, finishRecording, stopRecording }) => {
    // Start the countdown from 60 seconds
    const [remainingSeconds, setRemainingSeconds] = useState(60);

    useEffect(() => {
        let timer;
        if (isRecording) {
            timer = setInterval(() => {
                setRemainingSeconds(prevSeconds => {
                    // When the timer reaches 0, stop the interval and invoke stopRecording and finishRecording
                    if (prevSeconds - 1 <= 0) {
                        clearInterval(timer);
                        stopRecording();
                        finishRecording();
                        return 0; // Prevent the countdown from going into negative values
                    } else {
                        return prevSeconds - 1;
                    }
                });
            }, 1000);
        } else {
            // Reset the timer when not recording
            setRemainingSeconds(60);
            clearInterval(timer);
        }

        // Clear interval on component unmount or when recording stops
        return () => clearInterval(timer);
    }, [isRecording, stopRecording, finishRecording]);

    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;

    // Conditional styling for the text color
    const textStyle = {
        color: remainingSeconds <= 10 ? 'red' : 'black', // Text turns red when 10 seconds or less remain
    };

    return (
        <span style={textStyle}>
            {`${minutes}:${seconds.toString().padStart(2, '0')}`}
        </span>
    );
};

export default Stopwatch;
