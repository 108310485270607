import React, {Component} from "react";
import {Button} from "../atoms/Button";
import SvgVideo from "../icons/SvgVideo";
import QuestionResponseListItem from "./QuestionResponseListItem";


class QuestionPageResponsesSection extends Component {
    render() {
        const hasResponded = this.props.responses.length > 0
        return (
            <>
                <div className='flex flex-row items-center justify-between'>
                    <p className='text-xl font-bold text-slate-900'>
                        Your Response{this.props.responses.length > 1 && 's'}:
                    </p>
                    {hasResponded &&
                        <Button size={'sm'} variant={'secondary'} onClick={this.props.initiateResponse} icon={SvgVideo}
                                iconPosition={'left'}>
                            Record a new response
                        </Button>
                    }
                </div>
                {!hasResponded ?
                    <div
                        className='w-full flex flex-col items-center justify-center min-h-[120px] border bg-slate-50 border-slate-200 rounded-lg p-4'>
                        <Button onClick={this.props.initiateResponse} icon={SvgVideo} iconPosition={'left'}>
                            Record a response
                        </Button>
                    </div>
                    :
                    <div className='grid lg:grid-cols-1 xl:grid-cols-2 gap-5'>
                        {this.props.responses.map(item =>
                            <QuestionResponseListItem
                                item={item}
                            />
                        )}
                    </div>
                }
            </>
        )
    }
}

export default QuestionPageResponsesSection
