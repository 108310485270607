import React, {PureComponent} from "react";
import SvgUsers from "../icons/SvgUsers";
import Avatar from "boring-avatars";
import SvgCompanyBuilding from "../icons/SvgCompanyBuilding";
import {wait} from "@testing-library/user-event/dist/utils";

class CompanyLogo extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            imageError: false,
            avatarSize: 40, // Default size, will be updated
        };
        this.avatarContainerRef = React.createRef(); // Creating a ref
    }

    componentDidMount() {
        this.updateAvatarSize();
    }

    updateAvatarSize = () => {
        // Ensure the element is mounted
        if (this.avatarContainerRef.current) {
            wait(200).then(() => {
                const {width} = this.avatarContainerRef.current?.getBoundingClientRect() || 40;
                this.setState({avatarSize: width});
            })
        }
    };


    render() {
        if (this.state.imageError || (!this.props.id && !this.props.backupUrl)) {
            return (
                <div
                    ref={this.avatarContainerRef}
                    className={`rounded-xl relative z-[0] overflow-hidden bg-slate-50 aspect-square h-min ${this.props.className}`}>
                    <div className='absolute cover-parent bg-slate-100 flex flex-col items-center justify-center'>
                        <SvgCompanyBuilding className={'w-6 h-6 mx-auto'}/>
                    </div>
                </div>
            )
        } else {
            return (
                <img
                    src={!!this.props.id ?
                        "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/companies%2F" +
                        this.props.id +
                        "?alt=media"
                        :
                        this.props.backupUrl
                    }
                    alt={``}
                    className={`${this.props.className} object-contain bg-slate-50 border border-slate-200 rounded-lg bg-white`}
                    onClick={this.props.onClick}
                    style={{
                        ...this.props.imageStyle,
                    }}
                    onError={(e) => {
                        e.target.src = "https://handshake-production-cdn.joinhandshake.com/static_assets/default_institution_1.png";
                        this.setState({imageError: true});
                    }}
                />
            )
        }
    }
}


export default CompanyLogo;
