import React, {Component} from "react";
import ModalComponent from "./ModalComponent";
import SvgCheck from "../../icons/SvgCheck";
import SvgClose from "../../icons/SvgClose";
import SvgSchool from "../../icons/SvgSchool";
import FadeInOnScroll from "../FadeInOnScroll";
import {getClub, joinClub} from "../../../api/student/clubs";

class ReceivedInviteModal extends Component {
    state={
        club: {},
        loading: true
    }

    componentDidMount = async () => {
        if (this.props.isOpen && this.props.invitedClubId) {
            const club = await getClub(this.props.authUser, this.props.invitedClubId);
            this.setState({club: club.club, loading: false});
        }
    }

    tryJoinClub = async () => {
        const clubJoin = await joinClub(this.props.authUser, this.props.invitedClubId);
        if (clubJoin) {
            this.props.closeModal();
        }
    }

    render() {
        return (
            <ModalComponent
                isOpen={this.props.isOpen && !this.state.loading}
                backgroundColor={'white'}
                headerToggle
                toggle={this.props.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <button
                            onClick={this.props.closeModal}
                            className="secondary-button body1-bold">
                            <SvgClose className={'w-5 h-5 mr-2'}/>
                            Decline
                        </button>
                        <button
                            onClick={this.tryJoinClub}
                            className="primary-button body1-bold">
                            <SvgCheck className={'w-5 h-5 mr-2'}/>
                            Accept Invite
                        </button>
                    </div>
                }
            >
                <img alt={'invite'} src={require('../../../img/illustrations/techny-secure-lock-and-key.gif')}
                     className='w-1/2 mx-auto scale-125'/>
                <p className='heading4 font-semibold text-center'>
                    You've been invited to join a club!
                </p>
                <p className='text-center text-slate-500 my-5'>
                    Accept your invite to gain access to featured jobs, exclusive club offerings, and access to your
                    alumni network.
                </p>
                <FadeInOnScroll triggerImmediately delay={100}>
                    <div className='rounded-xl border border-slate-200 flex flex-row items-center gap-3 p-3'>
                        <img alt={'club icon'} src={this.state.club.logo_url}
                             className='w-16 h-16 rounded-lg border border-slate-200 object-cover'/>
                        <div className='flex flex-col gap-0'>
                            <p className='title2 text-slate-800'>
                                {this.state.club.name}
                            </p>
                            <div className='flex flex-row items-center gap-2 text-slate-500'>
                                <SvgSchool className={'w-5 h-5'}/>
                                <p className='body1'>
                                    {this.state.club.college}
                                </p>
                            </div>
                        </div>
                    </div>
                </FadeInOnScroll>
            </ModalComponent>
        )
    }
}

export default ReceivedInviteModal
