import React, {PureComponent} from "react";
import SvgArrowDropDown from "../icons/SvgArrowDropDown";
import SvgCheck from "../icons/SvgCheck";

class StatusSelect extends PureComponent {
    render() {
        if (this.props.table)
            return (
                <div
                    className='flex flex-row items-start cursor-pointer body3 text-neutral-500 px-1.5 lg:pl-3 lg:pr-1 py-1 flex flex-row items-center justify-between gap-1 rounded-lg border min-w-[10px] lg:min-w-[120px]'>
                    <div className='flex flex-row items-center gap-1'>
                        <p className='
'>
                            {this.props.value || this.props.placeholder || 'Not saved'}
                        </p>
                    </div>
                    {this.props.dropDown &&
                        <SvgArrowDropDown className={' w-4 h-4 text-neutral-300'}/>
                    }
                </div>
            )
        return (
            <div className='flex flex-col items-center gap-2'>
                <div
                    className='stepper-list-dot bg-primary/10 border-[1.5px] flex flex-col items-center justify-center border-primary'>
                    <SvgCheck className={'text-primary w-4 h-4'}/>
                </div>
                <p className='text-xs text-slate-500 whitespace-nowrap'>
                    {this.props.value || this.props.placeholder || 'Not saved'}
                </p>
            </div>
//         <div
//             className='flex flex-row items-start cursor-pointer body3 text-neutral-500 px-1.5 lg:pl-3 lg:pr-1 py-1 flex flex-row items-center justify-between gap-1 rounded-lg border min-w-[10px] lg:min-w-[120px]'>
//             <div className='flex flex-row items-center gap-1'>
//                 <p className='
// '>
//                     {this.props.value || this.props.placeholder || 'Not saved'}
//                 </p>
//             </div>
//             {this.props.dropDown &&
//                 <SvgArrowDropDown className={' w-4 h-4 text-neutral-300'}/>
//             }
//         </div>
            // <div className='flex flex-row items-start cursor-pointer'>
            //     <div className={`
            //         ${(this.props.value === 'To apply' || this.props.value === 'Contacted') ?
            //         'border-orange-400' :
            //         (this.props.value === 'Applied' || this.props.value === 'Scheduled' || this.props.value === 'Decision') ?
            //             'border-blue-400' :
            //             (this.props.value === 'Interviewing' || this.props.value === 'Want to chat') ?
            //                 'border-yellow-400' :
            //                 (this.props.value === 'Offered' || this.props.value === 'Response received') ?
            //                     'border-teal-400' :
            //                     this.props.value === 'Decided' ?
            //                         'border-violet-400' :
            //                         this.props.value === 'Hired' ?
            //                             '' :
            //                             this.props.value === 'Rejected' ?
            //                                 '' :
            //                                 ''
            //     }
            //         body3 text-neutral-500 px-3 py-1 flex flex-row items-center gap-2 rounded-full border ${this.props.sm && 'px-1 py-0 h-8'}
            //         `}>
            //         <div className={`w-2 h-2 rounded-full
            //                 ${(this.props.value === 'To apply' || this.props.value === 'Contacted') ?
            //             'bg-orange-500' :
            //             (this.props.value === 'Applied' || this.props.value === 'Scheduled' || this.props.value === 'Decision') ?
            //                 'bg-blue-500' :
            //                 (this.props.value === 'Interviewing' || this.props.value === 'Want to chat') ?
            //                     'bg-yellow-500' :
            //                     (this.props.value === 'Offered' || this.props.value === 'Response received') ?
            //                         'bg-teal-500' :
            //                         this.props.value === 'Decided' ?
            //                             'bg-violet-500' :
            //                             this.props.value === 'Hired' ?
            //                                 'bg-green-400' :
            //                                 this.props.value === 'Rejected' ?
            //                                     'bg-red-400' :
            //                                     'bg-neutral-400'
            //         }`}/>
            //         <p className=' whitespace-nowrap'>
            //             {this.props.value || this.props.placeholder || 'Not saved'}
            //         </p>
            //         {this.props.dropDown &&
            //             <SvgArrowDropDown className={' w-4 h-4 text-neutral-300'}/>
            //         }
            //     </div>
            // </div>
        )
    }
}

export default StatusSelect
