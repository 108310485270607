import React from "react";
import OnboardingStepHeader from "./OnboardingStepHeader";
import OnboardingStepWrapper from "./OnboardingStepWrapper";
import {COMPANY_IDS_1, COMPANY_IDS_2, GET_MATCHES_STEPS, SCHOOLS} from "./onboarding-constants";
import OnboardingStepButtons from "./OnboardingStepButtons";
import Marquee from "react-fast-marquee";
import CompanyLogo from "../atoms/CompanyLogo";
import {Button} from "../atoms/Button";

function OnboardingJobMatchIntroStep({goNextStep, goPreviousStep}) {

    return (
        <OnboardingStepWrapper>
            <div className='flex flex-col gap-4'>
                <Marquee autoFill speed={20} gradient>
                    {COMPANY_IDS_1.map(id =>
                        <div key={id} className='p-1 mx-2 border border-slate-200 rounded-lg'>
                            <CompanyLogo id={id} className={'w-16 h-16 xl:w-18  xl:h-18'}/>
                        </div>
                    )}
                </Marquee>
                <Marquee autoFill speed={20} direction={'right'} gradient>
                    {COMPANY_IDS_2.map(id =>
                        <div key={id} className='p-1 mx-2 border border-slate-200 rounded-lg'>
                            <CompanyLogo id={id} className={'w-16 h-16 xl:w-18  xl:h-18'}/>
                        </div>
                    )}
                </Marquee>
                <div className='flex flex-col items-center'>
                    <p className='text-sm text-slate-500 mb-2'>
                        Trusted by thousands of students from top universities
                    </p>
                    <div className='flex flex-row items-center'>
                        {SCHOOLS.map((item, index) =>
                            <img alt={'school-logo'} key={item}
                                 className={`w-8 h-8 rounded-full bg-white border-2 border-slate-200 ${index !== SCHOOLS.length && '-ml-2'}`}
                                 src={item}/>
                        )}
                        <p className='text-xs text-slate-500 ml-2'>
                            + more
                        </p>
                    </div>
                </div>
            </div>
            <OnboardingStepHeader
                title={'Your top job matches are just a few clicks away'}
                description={'Let us know what you’re looking for and we’ll find the best matches for you. Manage the entire recruitment process in one place.'}
            />
            <div className='flex flex-col gap-3'>
                {GET_MATCHES_STEPS.map((item, i) =>
                    <div key={item} className='flex flex-row items-center gap-3'>
                        <div
                            className='w-8 h-8 bg-primary rounded-full flex flex-col items-center justify-center text-white'>
                            {i + 1}
                        </div>
                        <p className='text-lg font-semibold'>
                            {item}
                        </p>
                    </div>
                )}
            </div>
            <div className='flex flex-col gap-4'>
                <Button size={'lg'} onClick={() => goNextStep()}>
                    Get started
                </Button>
                <OnboardingStepButtons
                    goNextStep={goNextStep}
                    goPreviousStep={goPreviousStep}
                    hideNext
                />
            </div>
        </OnboardingStepWrapper>
    );
}

export default OnboardingJobMatchIntroStep;
