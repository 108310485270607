import React, {PureComponent} from "react";
import TextBlockShimmer from "../loading/TextBlockShimmer";
import SvgPlus from "../icons/SvgPlus";
import SvgEditFeather from "../icons/SvgEditFeather";
import {editClub} from "../../api/student/clubs";
import FreeTextInputModal from "../modals/FreeTextInputModal";
import SvgVisibility from "../icons/SvgVisibility";
import SvgEyeOff from "../icons/SvgEyeOff";
import Tooltip from "../atoms/modals/Tooltip";
import {connect} from "react-redux";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

class ClubPageMissionWidget extends PureComponent {
    state = {
        mission: '',
        freeTextInputModalOpen: false,
    }

    componentDidMount = () => {
        this.setState({mission: this.props.club.mission})

    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.club.mission !== this.props.club.mission) {
            this.setState({mission: this.props.club.mission})
        }
    }

    openFreeTextInputModal = () => {
        this.setState({freeTextInputModalOpen: true})
    }

    closeFreeTextInputModal = () => {
        this.setState({freeTextInputModalOpen: false})
    }

    handleInputChange = (e) => {
        this.setState({mission: e.target.value});
    };

    save = async () => {
        await editClub(this.props.authUser, this.props.club.id, {
            mission: this.state.mission
        });
        this.props.getClubInfo();
        window.analytics.track("Club Edited", {
            mission: this.state.mission
        })
    }

    render() {
        const club = this.props.club;
        const hidden = this.props.hidden || [];
        return (
            <>
                <div
                    className={`bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl mt-5 ${hidden.includes("mission") && 'opacity-50'}`}>
                    <div className='flex flex-row items-center gap-2 mb-2'>
                        <p className='text-xl font-semibold text-slate-800'>
                            Our Mission
                        </p>
                        {!this.props.loading && this.props.adminIds.includes(club.id) &&
                            <>
                                <button
                                    onClick={this.openFreeTextInputModal}
                                    className='text-slate-500 hover:text-primary cursor-pointer'>
                                    {club?.mission?.length === 0 ?
                                        <SvgPlus className={'w-4 h-4'}/>
                                        :
                                        <SvgEditFeather className={'w-4 h-4'}/>
                                    }
                                </button>
                                <Tooltip
                                    toggleComponent={
                                        <button
                                            onClick={() => this.props.hide("mission")}
                                            className='text-slate-500 hover:text-primary cursor-pointer'>
                                            {hidden.includes("mission") ?
                                                <SvgVisibility className={'w-4 h-4'}/>
                                                :
                                                <SvgEyeOff className={'w-4 h-4'}/>
                                            }
                                        </button>
                                    }
                                    label={`${hidden.includes("mission") ? "Show" : "Hide"} this section when recruiters are viewing your club.`}
                                />
                                
                            </>
                        }
                    </div>
                    {this.props.loading ?
                        <>
                            <TextBlockShimmer/>
                        </>
                        :
                        club?.mission ?
                            <p className='text-slate-500 text-md'>
                                {club?.mission}
                            </p>
                            :
                            <p className='text-slate-500'>
                                No mission created yet
                            </p>
                    }
                </div>
                <FreeTextInputModal
                    isOpen={this.state.freeTextInputModalOpen}
                    title={'Edit club mission'}
                    placeholder={'Club mission statement...'}
                    value={this.state.mission}
                    handleInputChange={this.handleInputChange}
                    closeModal={this.closeFreeTextInputModal}
                    save={this.save}
                    disabled={!this.state.mission}
                />
            </>
        )
    }
}

export default connect(mapStateToProps)(ClubPageMissionWidget);
