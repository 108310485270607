import {wait} from "@testing-library/user-event/dist/utils";
import React, {PureComponent} from "react";
import {CLASS_YEARS, JOB_TYPE_OPTIONS} from "../../../utils/dummy";
import DatePicker from "react-datepicker";
import {deleteJob, editJob} from "../../../api/student/jobs";
import {searchCompanies} from "../../../utils/typesense";
import TagComponent from "../TagComponent";
import MultiSelectComponent from '../MultiSelectComponent';
import ModalComponent from './ModalComponent';
import SelectComponent from '../SelectComponent';
import { DateTime } from 'luxon';
import RichTextEditor from "../inputs/RichTextEditor";
import {EditorState, ContentState, convertFromRaw} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';

const workEnvironmentOptions = [
    {value: "On-site", title: "On-site"},
    // {value: "Hybrid", title: "Hybrid"},
    {value: "Remote", title: "Remote"},
];

const salaryOptions = [
    {value: "Paid", title: "Paid"},
    {value: "Unpaid", title: "Unpaid"},
];

class EditJobModal extends PureComponent {
    state = {
        title: '',
        company: "",
        city: [],
        state: [],
        newCity: "",
        newState: "",
        deadline: "",
        description: "",
        onsite: null,
        salary: null,
        type: null,
        url: "",
        searchResults: [],
        newTag: '',
        newSecondaryTag: '',
        classYears: [],
        industry: '',
        tags: [],
        secondaryTags: [],
        editorState: EditorState.createEmpty(),
    }

    titleChanged = e => this.setState({title: e.target.value});

    newCityChanged = e => this.setState({newCity: e.target.value});

    newStateChanged = e => this.setState({newState: e.target.value});

    urlChanged = e => this.setState({url: e.target.value});

    industryChanged = e => this.setState({industry: e.target.value});

    descriptionChanged = e => this.setState({description: e.target.value});

    handleDeadlineChange = e => this.setState({deadline: e});

    newTagChanged = e => this.setState({newTag: e.target.value});
    newSecondaryTagChanged = e => this.setState({newSecondaryTag: e.target.value});

    updateOnsite = (onsite) => {
        if (onsite === this.state.onsite || onsite === null) {
            this.setState({onsite: null});
            return;
        }
        this.setState({onsite});
    }

    handleSalaryChange = (salary) => {
        if (salary === this.state.salary || salary === null) {
            this.setState({salary: null});
            return;
        }
        this.setState({salary});
    }

    handleTypeChange = (type) => {
        if (type === this.state.type || type === null) {
            this.setState({type: null});
            return;
        }
        this.setState({type});
    }

    updateClassYears = (year) => {
        if (year === null) {
            this.setState({classYears: []});
            return
        }

        let newClassYears = [...this.state.classYears];
        if (this.state.classYears.includes(year)) {
            const index = this.state.classYears.indexOf(year);
            newClassYears.splice(index, 1);
        } else {
            newClassYears.push(year);
        }
        this.setState({classYears: newClassYears});
    }

    updateTags = (tag) => {
        if (tag === null) {
            this.setState({tags: []});
            return
        }

        let newTags = [...this.state.tags];
        if (this.state.tags.includes(tag)) {
            const index = this.state.tags.indexOf(tag);
            newTags.splice(index, 1);
        } else {
            newTags.push(tag);
        }
        this.setState({tags: newTags});
    }

    updateSecondaryTags = (tag) => {
        if (tag === null) {
            this.setState({secondaryTags: []});
            return
        }

        let newTags = [...this.state.secondaryTags];
        if (this.state.secondaryTags.includes(tag)) {
            const index = this.state.secondaryTags.indexOf(tag);
            newTags.splice(index, 1);
        } else {
            newTags.push(tag);
        }
        this.setState({secondaryTags: newTags});
    }

    updateCities = () => {
        const city = this.state.newCity;
        let newCity = [...this.state.city];
        newCity.push(city);
        this.setState({city: newCity, newCity: ""});
    }

    deleteCity = (city) => {
        let newCities = [...this.state.city];
        const index = this.state.city.indexOf(city);
        newCities.splice(index, 1);
        this.setState({city: newCities, newCity: ""});
    }

    updateStates = () => {
        const state = this.state.newState;
        let newState = [...this.state.state];
        newState.push(state);
        this.setState({state: newState, newState: ""});
    }

    deleteState = (state) => {
        let newStates = [...this.state.state];
        const index = this.state.state.indexOf(state);
        newStates.splice(index, 1);
        this.setState({state: newStates, newState: ""});
    }

    companyChanged = async (e) => {
        const text = e.target.value;
        this.setState({company: text});
        const searchResults = await searchCompanies(text);
        this.setState({searchResults});
    }

    setCompany = (result) => {
        this.setState({
            searchResults: [],
            companyId: result.id,
            company: result.name,
        })
    }

    componentDidMount = () => {
        this.setJobInfo();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.isOpen !== this.props.isOpen) {
            wait(10).then(() => {
                this.setJobInfo();
            })
        }
    }

    updateJob = async () => {
        const contentState = this.state.editorState.getCurrentContent();
        const htmlContent = stateToHTML(contentState);

        await editJob(this.props.authUser, this.props.listing.id, {
            name: this.state.title,
            description: htmlContent,
            city: this.state.city,
            state: this.state.state,
            work_model: this.state.onsite,
            salary: this.state.salary,
            company_id: this.state.companyId,
            deadline: !this.state.deadline ? "" : DateTime.fromJSDate(this.state.deadline).setZone('utc', {keepLocalTime: true}).toISO().replace('Z', ''),
            link: this.state.url,
            class_years: this.state.classYears,
            industry: this.state.industry,
            type: this.state.type,
            specialties: this.state.tags,
            secondary_specialties: this.state.secondaryTags,
        });
        this.props.closeModal();
        this.props.fetchJob();
    }

    deleteJob = async () => {
        await deleteJob(this.props.authUser, this.props.listing.id);
        this.props.closeModal();
    }

    setJobInfo = () => {
        const job = this.props.listing || {};
        const company = job.company || {};
        const luxon_date = DateTime.fromISO(job?.deadline).isValid ? DateTime.fromISO(job.deadline) : DateTime.now();
        const deadline = new Date(luxon_date.toFormat("yyyy-MM-dd'T'HH:mm:ss"));
        this.setState({
            title: job.name,
            city: !!job.city ? job.city : [],
            state: !!job.state ? job.state : [],
            description: job.description,
            deadline: deadline,
            onsite: job.work_model,
            salary: job.salary,
            company: company.name,
            url: job.link,
            classYears: !!job.class_years? job.class_years : [],
            industry: job.industry,
            type: job.type,
            tags: job.specialties,
            secondaryTags: job.secondary_specialties
        });
        if (job.description) {
            try {
                const contentState = typeof value === 'string'
                    ? ContentState.createFromText(job.description)
                    : convertFromRaw(job.description);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({ editorState });
            } catch (error) {
                this.setState({ editorState: EditorState.createEmpty() });
            }
        } else {
            this.setState({ editorState: EditorState.createEmpty() });
        }
    }

    setEditorState = (editorState) => {
        this.setState({editorState});
    }

    render() {
        if (!this.props.authUser?.email) return null;

        const tags = this.state.tags || [];
        const secondaryTags = this.state.secondaryTags || [];
        const INDUSTRY_TAGS = this.props.constants.industry_tags.map(tag => ({title: tag, value: tag})); 
        const SECONDARY_TAGS = this.props.constants.secondary_tags.map(tag => ({title: tag, value: tag})); 
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Edit Job'}
                headerToggle
                size={'xl'}
                toggle={this.props.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <div className='secondary-button body1-bold' onClick={this.deleteJob}>
                            Delete Job
                        </div>
                        <div onClick={this.updateJob} className='primary-button body1-bold'>
                            Save
                        </div>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div id={'add-joblisting-modal'}>
                    <div className='flex flex-row flex-wrap gap-2 mt-5'>
                        <div className="col-plain">
                            <p className="body3-bold text-neutral-400 mb-1">
                                Title
                            </p>
                            <input
                                value={this.state.title}
                                style={{width: 300}}
                                onChange={this.titleChanged}
                                placeholder={'Enter title'}
                                className='input-light px-3 body2 base-black-50'
                            />
                        </div>
                        <div className="col-plain">
                            <p className="body3-bold text-neutral-400 mb-1">
                                Company
                            </p>
                            <input
                                value={this.state.company}
                                style={{width: 300}}
                                onChange={this.companyChanged}
                                placeholder={'Enter title'}
                                className='input-light px-3 body2 base-black-50'
                            />
                        </div>
                        {this.state.searchResults.map(result => {
                            return (
                                <button className='row-plain' onClick={() => this.setCompany(result)}>
                                    <img src={result.logo_url} alt={'company-icon'}
                                            className='w-8 h-8 rounded-sm border object-contain bg-white border-neutral-200 mr-2'/>
                                    <div className='col-plain'>
                                        <p className='body3'>{result.name}</p>
                                        <p className='body4'>{result.sector}</p>
                                    </div>
                                </button>
                            )
                        })}
                        <div className="col-plain">
                            <p className="body3-bold text-neutral-400 mb-1">
                                Specialty / Focus
                            </p>
                            <input
                                value={this.state.industry}
                                style={{width: 300}}
                                onChange={this.industryChanged}
                                placeholder={'Enter specialty / focus'}
                                className='input-light px-3 body2 base-black-50'
                            />
                        </div>
                        <div className="col-plain">
                            <p className="body3-bold text-neutral-400 mb-1">
                                Job URL
                            </p>
                            <input
                                value={this.state.url}
                                style={{width: 300}}
                                onChange={this.urlChanged}
                                placeholder={'Enter job url'}
                                className='input-light px-3 body2 base-black-50'
                            />
                        </div>
                        <div>
                            <p className="body3-bold text-neutral-400 mb-1">
                                Due
                            </p>
                            <DatePicker
                                className={'px-3 input-light body2 w-full'}
                                placeholderText={'Due date'}
                                selected={this.state.deadline}
                                showYearDropdown
                                // onSelect={handleDateSelect} //when day is clicked
                                onChange={this.handleDeadlineChange} //only when value has changed
                            />
                        </div>
                        <div className="col-plain">
                            <SelectComponent
                                className={'w-1/3'}
                                outerLabel={'Work Model'}
                                label={'ie. Remote'}
                                value={this.state.onsite}
                                light
                                setValue={this.updateOnsite}
                                clearFilter={() => this.updateOnsite(null)}
                                options={workEnvironmentOptions}
                                node={document.getElementById('add-joblisting-modal')}
                            />
                        </div>
                        <div className="col-plain">
                            <SelectComponent
                                className={'w-1/3'}
                                outerLabel={'Salary'}
                                label={'ie. Paid'}
                                value={this.state.salary}
                                setValue={this.handleSalaryChange}
                                clearFilter={() => this.handleSalaryChange(null)}
                                light
                                options={salaryOptions}
                                node={document.getElementById('add-joblisting-modal')}
                            />
                        </div>
                        <div className="col-plain">
                            <SelectComponent
                                className={'w-1/3'}
                                outerLabel={'Type'}
                                label={'ie. Internship'}
                                value={this.state.type}
                                setValue={this.handleTypeChange}
                                clearFilter={() => this.handleTypeChange(null)}
                                light
                                options={JOB_TYPE_OPTIONS}
                                node={document.getElementById('add-joblisting-modal')}
                            />
                        </div>
                        <div className="col-plain">
                            <MultiSelectComponent
                                className={'flex-[0.5]'}
                                outerLabel={'Graduation Year'}
                                label={'ie. 2025, 2026...'}
                                value={this.state.classYears}
                                light
                                setValue={this.updateClassYears}
                                clearFilter={() => this.updateClassYears(null)}
                                options={CLASS_YEARS}
                                node={document.getElementById('add-joblisting-modal')}

                            />
                        </div>
                    </div>
                    <div className='mt-3'>
                        <label htmlFor="message" className="block mb-2 body2-bold text-neutral-400 dark:text-white">
                            Job Description
                        </label>
                        <div>
                            <RichTextEditor initialHtml={this.state.description} editorState={this.state.editorState} setEditorState={this.setEditorState}/>
                        </div>
                        {/* <textarea id="message" rows="4"
                                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Job description..." onChange={this.descriptionChanged}
                                    value={this.state.description}></textarea> */}
                    </div>
                    <div className='flex flex-col gap-3'>
                        <div className='flex flex-row gap-3'>
                            <div className='mt-5'>
                                <label className="block mb-2 body2-bold text-neutral-400 dark:text-white">
                                    City
                                </label>
                                <div className='items-center flex flex-row gap-1'>
                                    {this.state.city.map(tag =>
                                        <TagComponent
                                            label={tag}
                                            type={'job-type'}
                                            removable
                                            deleteTag={this.deleteCity}
                                        />
                                    )}
                                </div>
                                <div className="col-plain mt-2">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        + Add City
                                    </p>
                                    <div className='flex flex-row items-center gap-2'>
                                        <input
                                            value={this.state.newCity}
                                            style={{width: 300}}
                                            onChange={this.newCityChanged}
                                            placeholder={'Enter city'}
                                            className='input-light px-3 body2 base-black-50'
                                        />
                                        <button
                                            disabled={this.state.newCity === ''}
                                            onClick={this.updateCities}
                                            className={'primary-button-sm h-full body1-bold disabled:opacity-50'}>
                                            Add city
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <label className="block mb-2 body2-bold text-neutral-400 dark:text-white">
                                    State
                                </label>
                                <div className='items-center flex flex-row gap-1'>
                                    {this.state.state.map(tag =>
                                        <TagComponent
                                            label={tag}
                                            removable
                                            deleteTag={this.deleteState}
                                        />
                                    )}
                                </div>
                                <div className="col-plain mt-2">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        + Add State
                                    </p>
                                    <div className='flex flex-row items-center gap-2'>
                                        <input
                                            value={this.state.newState}
                                            style={{width: 300}}
                                            onChange={this.newStateChanged}
                                            placeholder={'Enter state'}
                                            className='input-light px-3 body2 base-black-50'
                                        />
                                        <button
                                            disabled={this.state.newState === ''}
                                            onClick={this.updateStates}
                                            className={'primary-button-sm h-full body1-bold disabled:opacity-50'}>
                                            Add state
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-20'>
                            <label className="block mb-2 body2-bold text-neutral-400 dark:text-white">
                                Tags
                            </label>
                            <div className="col-plain mt-2">
                                <p className="body3-bold text-neutral-400 mb-1">
                                    + Add Industry
                                </p>
                                <div className='flex flex-row items-center gap-2'>
                                    <MultiSelectComponent
                                        className={'flex-[0.5]'}
                                        outerLabel={'Industries'}
                                        label={'...'}
                                        value={tags}
                                        light
                                        setValue={this.updateTags}
                                        clearFilter={() => this.updateTags(null)}
                                        options={INDUSTRY_TAGS}
                                        node={document.getElementById('add-joblisting-modal')}
                                    />
                                </div>
                            </div>
                            <div className="col-plain mt-2">
                            <div className='items-center flex flex-row gap-1'>
                                 </div>
                                <p className="body3-bold text-neutral-400 mb-1">
                                    + Add Role
                                </p>
                                <div className='flex flex-row items-center gap-2'>
                                    <MultiSelectComponent
                                        className={'flex-[0.5]'}
                                        outerLabel={'Roles'}
                                        label={'...'}
                                        value={secondaryTags}
                                        light
                                        setValue={this.updateSecondaryTags}
                                        clearFilter={() => this.updateSecondaryTags(null)}
                                        options={SECONDARY_TAGS}
                                        node={document.getElementById('add-joblisting-modal')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalComponent>
        )
    }
}

export default EditJobModal
