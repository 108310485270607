import React, {PureComponent} from "react";


class NetworkingListShimmers extends PureComponent {
    render() {
        return (
            <>
                <div className='shimmer mb-4 br-4 w-full h-12'/>
                <div className='shimmer mb-4 br-4 w-full h-12'/>
                <div className='shimmer mb-4 br-4 w-full h-12'/>
                <div className='shimmer mb-4 br-4 w-full h-12'/>
                <div className='shimmer mb-4 br-4 w-full h-12'/>
                <div className='shimmer mb-4 br-4 w-full h-12'/>
            </>
        )
    }
}

export default NetworkingListShimmers
