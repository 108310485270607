import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            width="32px"
            height="32px"
            fill={'currentColor'}
            {...props}
        >
            <path d="M11 5C9.031 5 7.46 6.441 7.125 8.313c-1.89.476-3.531 1.707-4.25 3.656h-.031L.719 17.938A6.955 6.955 0 000 21c0 3.855 3.145 7 7 7 3.473 0 6.367-2.55 6.906-5.875A2.983 2.983 0 0016 23c.813 0 1.55-.34 2.094-.875C18.633 25.449 21.527 28 25 28c3.855 0 7-3.145 7-7 0-.973-.2-1.906-.563-2.75l-2.28-6.375-.032-.031v-.031c-.73-1.774-2.348-3.012-4.25-3.5C24.539 6.44 22.969 5 21 5c-1.855 0-3.363 1.285-3.813 3h-2.375C14.364 6.285 12.855 5 11 5zm0 2c1.191 0 2 .809 2 2v1h6V9c0-1.191.809-2 2-2 1.191 0 2 .809 2 2v.906l.906.094c1.485.156 2.766 1.191 3.344 2.531.008.02.023.043.031.063l.75 2.125A6.94 6.94 0 0025 14c-2.926 0-5.426 1.816-6.469 4.375A3.013 3.013 0 0016 17c-1.05 0-1.996.543-2.531 1.375C12.426 15.816 9.926 14 7 14c-1.07 0-2.09.254-3 .688l.75-2.032v-.031C5.332 11.078 6.559 10.16 8.094 10L9 9.906V9c0-1.191.809-2 2-2zm-4 9c2.773 0 5 2.227 5 5s-2.227 5-5 5-5-2.227-5-5c0-.707.176-1.36.438-1.969.015-.039.015-.086.03-.125A4.977 4.977 0 017 16zm18 0c2.773 0 5 2.227 5 5s-2.227 5-5 5-5-2.227-5-5 2.227-5 5-5zm-9 3c.563 0 1 .438 1 1 0 .563-.438 1-1 1-.563 0-1-.438-1-1 0-.563.438-1 1-1z" />
        </svg>
    )
}

export default SvgComponent
