import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {wait} from "@testing-library/user-event/dist/utils";
import CreateJobQualificationStep from "./create-job/CreateJobQualificationStep";
import {editEvent} from "../../api/recruiter/events";
import CreateEventDetailsStep from "./create-event/CreateEventDetailsStep";
import CreateEventDateAndLocationStep from "./create-event/CreateEventDateAndLocationStep";
import SectionTabs from "../../components/atoms/SectionTabs";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import { EditorState } from "draft-js";
import { convertFromHtml, convertToHtml } from "../../utils/richEditor";
import { stateToHTML } from "draft-js-export-html";


const ZERO_STATE = {
    step: 1,
    eventTitle: '',
    eventDescription: EditorState.createEmpty(),
    industry: null,
    city: '',
    state: '',
    date: null,
    classYears: [],
    qualifiedOnly: false,
    registerOnRecruit: true,
    majors: [],
    clubs: [],
    schools: [],
    startTime: '10:00',
    endTime: '11:00',
    virtual: false,
    meetingLink: '',
    activeTab: 'Event Details',
    gender: null,
    race: [],
    veteran: false,
    lgbtq: false,
    disabled: false,
    authorized: false,
    role: '',
    rsvpDate: null,
    hasRsvpDate: false,
};

class RecruiterEditEventModal extends Component {
    state = ZERO_STATE;

    submit = async () => {
        const industry = this.state.role?.endsWith('Consulting') ? 'Consulting' : 'Finance';
        const params = {
            name: this.state.eventTitle,
            description: stateToHTML(this.state.eventDescription.getCurrentContent()),
            location: this.state.virtual ? "Remote" : "In-Person",
            event_date: this.state.date,
            start_time: this.state.startTime,
            end_time: this.state.endTime,
            city: !!this.state.city ? [this.state.city] : [],
            state: !!this.state.state ? [this.state.state] : [],
            // class_years: this.state.classYears,
            // clubs: this.state.clubs.map(club => club.id),
            // schools: this.state.schools,
            link: this.state.meetingLink,
            industry,
            // majors: this.state.majors,
            // gpa: this.state.gpa,
            qualified_only: this.state.qualifiedOnly,
            register_direct: this.state.registerOnRecruit,
            rsvp_date: this.state.hasRsvpDate && this.state.rsvpDate ? DateTime.fromJSDate(this.state.rsvpDate).setZone('utc', {keepLocalTime: true}).toISO().replace('Z', '') : "",
            secondary_specialties: [this.state.role],
            specialties: [industry],
            // diversity: {
            //     gender: this.state.gender,
            //     race: this.state.race,
            //     veteran: this.state.veteran,
            //     lgbtq: this.state.lgbtq,
            //     disabled: this.state.disabled,
            //     authorized: this.state.authorized,
            // }
        }
        toast.promise(editEvent(this.props.authUser, this.props.event.id, params).then(() => {
            window.location.reload();
        }), {
            pending: "Saving changes...",
            success: "Changes saved successfully!",
            error: "Failed to save changes."
        });
    }

    setInfo = () => {
        const event = this.props.event || {};
        this.setState({
            eventTitle: event.name,
            eventDescription: EditorState.createWithContent(convertFromHtml(event.description)),
            eventType: event.type,
            city: !!event.city ? event.city[0] : "",
            state: !!event.state ? event.state[0] : "",
            workEnvironment: event.work_model,
            // classYears: event.class_years,
            meetingLink: event.link,
            role: !!event.secondary_specialties ? event.secondary_specialties[0] : "",
            date: !!event.event_date ? new Date(event.event_date) : null,
            startTime: event.start_time,
            endTime: event.end_time,
            virtual: event.location === "Remote",
            schools: event.schools,
            clubs: event.clubs,
            // majors: event.majors,
            // gpa: event.gpa,
            qualifiedOnly: event.qualified_only,
            registerOnRecruit: event.register_direct,
            // gender: event.diversity?.gender || null,
            // race: event.diversity?.race || [],
            // veteran: event.diversity?.veteran || false,
            // lgbtq: event.diversity?.lgbtq || false,
            // disabled: event.diversity?.disabled || false,
            // authorized: event.diversity?.authorized || false,
            hasRsvpDate: !!event.rsvp_date,
            rsvpDate: !!event.rsvp_date ? new Date(event.rsvp_date) : null,
        })
    }

    selectSmartList = (res) => {
        this.setState({
            listId: res.id,
            listName: res.name,
            schools: res.colleges,
            gpa: res.gpa,
            classYears: res.grades,
            majors: res.majors,
            gender: res.gender,
            race: res.race,
            lgbtq: res.lgbtq,
            disabled: res.disabled,
            veteran: res.veteran,
            authorized: res.authorized,
        })
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.isOpen !== this.props.isOpen) {
            wait(10).then(() => {
                this.setInfo();
            })
        }
    }

    componentDidMount = () => {
        this.setInfo();
    }

    handleInputChange = (e, inputName) => {
        this.setState({[inputName]: e.target.value});
    };

    handleSelectChange = (inputName, value) => {
        this.setState({[inputName]: value});
    };

    handleWorkEnvironmentChange = (event) => {
        this.setState({
            workEnvironment: event.target.value,
        });
    };

    handleQualifiedOnlyChange = (boolean) => {
        this.setState({
            qualifiedOnly: boolean,
        });
    };

    toggleInput = (stateKey) => {
        this.setState((prevState) => ({
            [stateKey]: !prevState[stateKey],
        }));
    }

    handleDateChange = (e, stateKey) => {
        this.setState({[stateKey]: e})
    };

    handleTimeChange = (e, stateKey) => {
        this.setState({[stateKey]: e})
    };


    setEditorState = (editorState) => {
        this.setState({eventDescription: editorState})
    }


    handleQualificationCheckbox = (inputName) => {
        this.setState({[inputName]: !this.state[inputName]});
    }

    handleVirtualChange = (value) => {
        this.setState({virtual: value})
    };

    changeTab = (tab) => {
        this.setState({activeTab: tab});
    };

    render() {
        const TABS = ['Event Details', 'Date and Location', 'Attendees'];
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Edit This Event'}
                headerToggle
                size={'lg'}
                toggle={this.props.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <div onClick={this.props.closeModal} className='secondary-button body1-bold'>
                            {"Cancel"}
                        </div>
                        <div onClick={this.submit} className='primary-button body1-bold'>
                            {"Submit"}
                        </div>
                    </div>
                }
                footerAlignment={'right'}
                showScrollBar
            >
                <div id={'add-joblisting-modal'}>
                    <div className='mb-5'>
                        <SectionTabs
                            activeTab={this.state.activeTab}
                            changeTab={this.changeTab}
                            tabs={TABS}
                        />
                    </div>
                    {this.state.activeTab === TABS[0] &&
                        <CreateEventDetailsStep
                            hideTutorial
                            eventTitle={this.state.eventTitle}
                            eventDescription={this.state.eventDescription}
                            role={this.state.role}
                            handleInputChange={this.handleInputChange}
                            handleSelectChange={this.handleSelectChange}
                            constants={this.props.constants}
                            setEditorState={this.setEditorState}
                            editorState={this.state.eventDescription}
                        />
                    }
                    {this.state.activeTab === TABS[1] &&
                        <CreateEventDateAndLocationStep
                            hideTutorial
                            date={this.state.date}
                            handleDateChange={this.handleDateChange}
                            startTime={this.state.startTime}
                            endTime={this.state.endTime}
                            handleTimeChange={this.handleTimeChange}
                            virtual={this.state.virtual}
                            meetingLink={this.state.meetingLink}
                            state={this.state.state}
                            city={this.state.city}
                            registerOnRecruit={this.state.registerOnRecruit}
                            toggleInput={this.toggleInput}
                            handleVirtualChange={this.handleVirtualChange}
                            handleInputChange={this.handleInputChange}
                            handleSelectChange={this.handleSelectChange}
                            hasRsvpDate={this.state.hasRsvpDate}
                            rsvpDate={this.state.rsvpDate}
                        />
                    }
                    {this.state.activeTab === TABS[2] &&
                        <CreateJobQualificationStep
                            hideTutorial
                            qualifiedOnly={this.state.qualifiedOnly}
                            handleQualifiedOnlyChange={this.handleQualifiedOnlyChange}
                            updateSchools={this.updateSchools}
                            event
                            handleQualificationCheckbox={this.handleQualificationCheckbox}
                            editing
                        />
                    }
                </div>
            </ModalComponent>
        )
    }
}

export default RecruiterEditEventModal
