import React, {PureComponent} from "react";
import {Link} from "react-router-dom";
import TextBlockShimmer from "../loading/TextBlockShimmer";
import CompanyLogo from "../atoms/CompanyLogo";
import {APP_ADMINS} from "../../utils/constants";
import SvgEdit from "../../components/icons/SvgEdit";
import EditCompanyModal from "../atoms/modals/EditCompanyModal";
import CompanyStatusTrackerComponent from "../atoms/CompanyStatusTrackerComponent";
import SvgLink from "../icons/SvgLink";
import TagComponent from "../atoms/TagComponent";


class CompanyPageAboutWidget extends PureComponent {

    render() {
        const company = this.props.company || {};
        return (
            <div className='card'>
                <div className='row-ac-jb'>
                    <div className='flex flex-row items-center gap-4'>
                        <CompanyLogo id={company.id} className={'w-20 h-20'}/>
                        <div className=''>
                            {this.props.loading ?
                                <div className='shimmer mb-4 br-4 w-80' style={{height: 24}}/>
                                :
                                <p className='m-0 text-3xl font-bold'>
                                    {company.name}
                                </p>
                            }
                            {!!company.founded &&
                                <p className='text-sm text-slate-500'>
                                    Founded in {company.founded}
                                </p>
                            }
                        </div>
                    </div>
                    <div className='flex flex-row items-center gap-5'>
                        {!this.props.loading && !this.props.hideFollow ?
                            // <GetNotifiedCompanyToggle
                            //     company={company} user={this.props.user} setUser={this.props.setUser} authUser={this.props.authUser}
                            // />
                            <div>
                                <CompanyStatusTrackerComponent companyId={company.id}/>
                            </div>
                            : null
                        }
                        {APP_ADMINS.includes(this.props.user?.username) ?
                            <EditCompanyModal company={company} authUser={this.props.authUser}
                                              fetchCompany={this.props.fetchCompany}>
                                <div className='row-ac primary cursor-pointer'>
                                    <SvgEdit width={16} height={16}/>
                                </div>
                            </EditCompanyModal>
                            : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default CompanyPageAboutWidget
