import axios from '../axios';

export const getUserStats = async (user, queried_user_id = null) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {queried_user_id}
        };
        const res = await axios.get("/users/stats", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}