import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'} d="M479.911-410Q451-410 430.5-430.589q-20.5-20.588-20.5-49.5Q410-509 430.589-529.5q20.588-20.5 49.5-20.5Q509-550 529.5-529.411q20.5 20.588 20.5 49.5Q550-451 529.411-430.5q-20.588 20.5-49.5 20.5zM450-640v-200h60v200h-60zm0 520v-200h60v200h-60zm190-330v-60h200v60H640zm-520 0v-60h200v60H120z" />
        </svg>
    )
}

export default SvgComponent
