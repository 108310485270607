import React, {PureComponent} from "react";
import {connect} from "react-redux";
import AddPostText from "../../createpostflow/AddPostText";
import ModalComponent from "./ModalComponent";
import CompanyLogo from "../CompanyLogo";
import {editPost} from "../../../api/student/posts";
import {uploadDoc} from "../../../api/firebase";
import SvgNote from "../../icons/SvgNote";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateApplication: (application) => {
            dispatch({
                type: "UPDATE_APPLICATION",
                application,
            })
        },
    }
}

const ZERO_STATE = {
    search: '',
    type: "",
    step: 1,
    eventId: null,
    jobId: null,
    plaintext: "",
    uploadedFile: null,
    loading: false,
}

class EditPostModal extends PureComponent {
    state = ZERO_STATE

    componentDidMount = () => {
        this.setInfo();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.post !== prevProps.post || this.props.isOpen !== prevProps.isOpen) {
            this.setInfo();
        }
    }

    setInfo = () => {
        const post = this.props.post || {};
        if (!!post.job_id) {
            const job = post.job || {};
            this.setState({job: post.job, jobId: job.id, type: "job", plaintext: post.plaintext, uploadedFile: post.uploaded_file});
        } else {
            const event = post.event || {};
            this.setState({event: post.event, eventId: event.id, type: "event", plaintext: post.plaintext, uploadedFile: post.uploaded_file});
        }
    }

    handleInputChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    };

    handleSelectChange = (inputName, value) => {
        this.setState({[inputName]: value});
    };

    onDrop = async (acceptedFiles) => {
        const randomUUID = Math.random().toString(36).substring(7);
        this.setState({buttonMessage: "File uploaded!"});
        const url = await uploadDoc("post_file", this.props.club.id + "-" + this.props.authUser.uid + "-" + randomUUID, acceptedFiles[0]);
        const name = acceptedFiles[0].name;
        const uploadedFile = {url: url, name: name};
        this.setState({uploadedFile: uploadedFile});
    }

    removeUpload = () => {
        this.setState({uploadedFile: null});
    }


    updatePlaintext = (plaintext) => {
        this.setState({plaintext})
    }

    switchTitle = () => {
        switch (this.state.step) {
            case 1:
                return "Update the post text"
            case 2:
                return `Confirm`
            default:
                return "Edit post"
        }
    }

    nextStep = (step = null) => {
        if (!!step && typeof step === "number") this.setState({step});
        else this.setState({step: this.state.step + 1});
    }

    previousStep = () => {
        this.setState({step: this.state.step - 1});
    }


    switchNextButtonDisabled = () => {
        switch (this.state.step) {
            case 1:
                return !this.state.plaintext
            default:
                return false
        }
    }

    closeModal = () => {
        this.props.closeModal()
        this.setState(ZERO_STATE);
    }

    submit = async () => {
        this.setState({loading: true})
        const post = this.props.post || {};
        const params = {
            plaintext: this.state.plaintext,
            uploaded_file: this.state.uploadedFile?.url,
        }
        await editPost(this.props.authUser, post.id, params);
        this.closeModal();
        this.props.getClubInfo();
    }



    render() {
        const numSteps = 2
        const selectedItem = this.state.type === "job" ? this.state.job : this.state.event;
        return (
            <ModalComponent isOpen={this.props.isOpen}
                            header={this.switchTitle()}
                            backgroundColor={'white'}
                            headerToggle
                            size={'lg'}
                            toggle={this.closeModal}
                            FooterComponent={
                                <div className='flex flex-row items-center justify-end gap-3'>
                                    {this.state.step === 1 ?
                                        <button onClick={this.closeModal} className='secondary-button body1-bold'>
                                            Close
                                        </button>
                                        :
                                        <button onClick={this.previousStep} className='secondary-button body1-bold'>
                                            Previous
                                        </button>
                                    }
                                    {this.state.step === numSteps ?
                                        <button onClick={this.submit}
                                                disabled={this.state.loading}
                                                className={`primary-button body1-bold`}>
                                            Submit
                                        </button>
                                        :
                                        <button onClick={this.nextStep}
                                                disabled={this.switchNextButtonDisabled()}
                                                className={`primary-button body1-bold ${this.switchNextButtonDisabled() && 'opacity-50'}`}>
                                            Next
                                        </button>
                                    }
                                </div>
                            }
                            footerAlignment={'right'}
                            id={'create-post-modal'}
            >
                <div id={'add-joblisting-modal'}>
                    <div id={'add-job-modal'}>
                        <div className='px-4 py-4 flex flex-col gap-5 min-h-[20vh] justify-center'>
                            {this.state.step === 1 &&
                                <AddPostText
                                    plaintext={this.state.plaintext}
                                    selectedItem={selectedItem}
                                    allowFileUpload={true}
                                    handleInputChange={this.handleInputChange}
                                    onDrop={this.onDrop}
                                    uploadedFile={this.state.uploadedFile}
                                    removeFile={this.removeUpload}
                                />
                            }
                            {this.state.step === 2 &&
                                <div>
                                    <div className={'flex flex-row items-center gap-3 mt-5'}>
                                        <CompanyLogo id={selectedItem.company_id} className={'w-20 h-20'}/>
                                        <div>
                                            <p className='body1-bold m-0 blacks-1 text-neutral-900'>
                                                {selectedItem.name}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className='border border-slate-200 p-5 rounded-xl max-h-[200px] overflow-y-scroll my-5'>
                                        <p className={'body2 text-slate-800'}>
                                            {this.state.plaintext}
                                        </p>
                                    </div>
                                    {!!this.state.uploadedFile && <div className='w-full flex flex-row items-center gap-3 py-2 rounded-xl px-2'>
                                        <div className='flex flex-row'>
                                            <SvgNote width={24} height={24} fill={"#4848F2"} stroke={"#4848F2"}/>
                                            <p className='body1-bold text-slate-800 ml-8'>
                                                {this.state.uploadedFile.name || "Attachment"}
                                            </p>
                                        </div>
                                    </div>}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPostModal)
