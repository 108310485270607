import React, {Component} from 'react';
import SvgClose from "../icons/SvgClose";
import SvgArrowDropDown from "../icons/SvgArrowDropDown";

class SelectComponentBody extends Component {
    render() {
        return (
            <div
                className={`${this.props.value ? 'pill-active border bg-white border-slate-200 hover:bg-slate-50 text-slate-800' : (this.props.light ? 'pill-light' : 'pill')} border-slate-200 rounded-lg text-sm min-w-[110px]`}>
                <div className='flex flex-row items-center'>
                    <span className='mr-1'>
                        {this.props.value || this.props.label}
                    </span>
                    {(this.props.value && this.props.clearFilter) && (
                        <div className='text-slate-500 hover:text-red-400 cursor-pointer mr-2 mt-0.5' onClick={this.props.clearFilter}>
                            <SvgClose className='h-4 w-4'/>
                        </div>
                    )}
                </div>
                <SvgArrowDropDown className='w-5 h-5'/>
            </div>
        );
    }
}

export default SelectComponentBody;
