import React, {Component} from "react";
import {connect} from "react-redux";
import SvgEditFeather from "../../components/icons/SvgEditFeather";
import ClubLogo from "../../components-recruiter/atoms/ClubLogo";
import EditClubModal from "../atoms/modals/EditClubModal";
import SvgEdit from "../icons/SvgEdit";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

class ClubPageAboutHeader extends Component {
    state = {
        editHeaderModalOpen: false
    }

    openEditHeaderModal = () => {
        this.setState({editHeaderModalOpen: true})
    }

    closeEditHeaderModal = () => {
        this.setState({editHeaderModalOpen: false})
    }

    render() {
        const club = this.props.club || {};
        const adminIds = this.props.adminIds
        return (
            <div
                className='bg-white shadow-lg flex-1 shadow-slate-200 p-5 mt-5 border border-slate-200 rounded-xl'>
                <div className='flex flex-row gap-8'>
                    <ClubLogo clubId={club.id}
                              backupUrl={club.logo_url}
                              size={80}
                              className={`border border-slate-200`}/>
                    <div className='flex flex-col gap-3'>
                        <div className='flex flex-row items-center gap-2'>
                            <p className='text-3xl text-slate-800 font-semibold'>
                                {club.name}
                            </p>
                            {!this.props.loading && adminIds.includes(club.id) &&
                                <button onClick={this.openEditHeaderModal}
                                    className='text-slate-500 mt-0.5 hover:text-primary cursor-pointer'>
                                    <SvgEditFeather className={'w-5 h-5'}/>
                                </button>
                            }
                        </div>
                        <div className='flex flex-row items-center gap-10'>
                            <div>
                                <p className='text-sm text-slate-500'>
                                    Website
                                </p>
                                <p className='text-sm font-semibold text-primary'>
                                    {club.website || "--"}
                                </p>
                            </div>
                            <div>
                                <p className='text-sm text-slate-500'>
                                    Industry
                                </p>
                                <p className='text-sm font-semibold text-primary'>
                                    {club.industry || "--"}
                                </p>
                            </div>
                            <div>
                                <p className='text-sm text-slate-500'>
                                    Founded
                                </p>
                                <p className='text-sm font-semibold text-primary'>
                                    {club.founded || "--"}
                                </p>
                            </div>
                            <div>
                                <p className='text-sm text-slate-500'>
                                    Members
                                </p>
                                <p className='text-sm font-semibold text-primary'>
                                    {club.num_members_range || '--'}
                                </p>
                            </div>
                            <div>
                                <p className='text-sm text-slate-500'>
                                    Application only
                                </p>
                                <p className='text-sm font-semibold text-primary'>
                                    {club.application_only ? "Yes" : "No"}
                                </p>
                            </div>
                        </div>
                    </div>
                    <EditClubModal isOpen={this.state.editHeaderModalOpen} closeModal={this.closeEditHeaderModal} club={club} getClubInfo={this.props.getClubInfo} numMembers={this.props.numMembers}/>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(ClubPageAboutHeader);
