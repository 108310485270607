import React, {PureComponent} from "react";
import PieChart from "../atoms/charts/PieChart";
import {roundTo} from "../../utils/strings";

class ClubPageStatisticsWidget extends PureComponent {
    state = {
        pieData: [
            {x: "Finance", y: 40},
            {x: "Business / Business Admin", y: 20},
            {x: "Economics", y: 40},
            {x: "Mathematics", y: 40},
            {x: "History", y: 40},
            {x: "Other", y: 40},
        ],
        dummyPieData: [
            {x: "Finance", y: 40},
            {x: "Business / Business Admin", y: 20},
            {x: "Economics", y: 40},
            {x: "Mathematics", y: 40},
            {x: "History", y: 40},
            {x: "Other", y: 40},
        ],
    };

    componentDidMount = () => {
        this.formatStats()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.stats !== this.props.stats) {
            this.formatStats();
        }
    }

    formatStats = () => {
        const stats = this.props.stats || {};
        const majors = stats.majors || {};
        const formattedMajors = [];
        for (const major in majors) {
            formattedMajors.push({x: major, y: Number(majors[major])});
        }
        this.setState({pieData: formattedMajors});
    }

    render() {
        const stats = this.props.stats || {};
        const grades = stats.grades || {};
        return (
            <div className={`${!this.props.inPeek && 'card mb-0'} ${this.props.className}`}>
                {this.props.inPeek ?
                    null
                    :
                    <h3 className={`mt-0 title2 text-neutral-900`}>
                        Club Statistics
                    </h3>
                }
                <div className='flex flex-col gap-3'>
                    <div className='flex flex-col'>
                        {(!stats.avg_gpa || stats.avg_gpa === 0) ?
                            <p className={`${this.props.inPeek ? 'heading3 font-bold' : 'heading2'} text-gray-400`}>
                                N/A
                            </p>
                            :
                            <p className={`${this.props.inPeek ? 'heading3 font-bold' : 'heading2'} text-gray-800`}>
                                {roundTo(stats.avg_gpa, 2)}
                            </p>
                        }
                        <p className='body2 text-gray-500 mt-[-8px]'>
                            Average GPA
                        </p>
                    </div>
                    <div className='flex flex-row items-center gap-3 flex-wrap'>
                        <p className='body2 base-black-70 m-0'>
                            <span className='body2-bold base-black-100'>{grades['2028'] || 0}</span> freshmen
                        </p>
                        <p className='body2 base-black-70 m-0'>
                            <span className='body2-bold base-black-100'>{grades['2027'] || 0}</span> sophomores
                        </p>
                        <p className='body2 base-black-70 m-0'>
                            <span className='body2-bold base-black-100'>{grades['2026'] || 0}</span> juniors
                        </p>
                        <p className='body2 base-black-70 m-0'>
                            <span className='body2-bold base-black-100'>{grades['2025'] || 0}</span> seniors
                        </p>
                        <p className='body2 base-black-50 m-0'>
                            <span className='body2-bold base-black-50'>{grades['Unclaimed'] || 0}</span> unclaimed
                        </p>
                    </div>
                </div>
                <div className={`flex flex-col ${!this.props.inPeek && 'items-center'} w-full`}>
                    <div className={this.props.inPeek && ''}>
                        {this.state.pieData.length > 0 &&
                            <PieChart inPeek={this.props.inPeek} data={this.state.pieData}/>
                        }
                        {this.state.pieData.length === 0 &&
                            <PieChart inPeek={this.props.inPeek} data={this.state.dummyPieData} className={'grayscale opacity-30'}/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default ClubPageStatisticsWidget
