import React, {PureComponent} from "react";
import TagComponent from "../atoms/TagComponent";
import ClubPageJobItem from "./ClubPageJobItem";
import Tooltip from "../atoms/modals/Tooltip";
import SvgRemove from "../icons/SvgRemove";
import CompanyEventItem from "../jobboard/CompanyEventItem";
import {deletePost} from "../../api/student/posts";
import {DateTime} from 'luxon';
import CompanyLogo from "../atoms/CompanyLogo"
import SvgNote from "../icons/SvgNote";
import SvgEdit from "../icons/SvgEdit"
import {Link} from "react-router-dom";
import {Button} from "../atoms/Button";
import SvgSubDirectoryArrow from "../icons/SvgSubDirectoryArrow";
import SvgArrowDropDown from "../icons/SvgArrowDropDown";
import CollapseComponent from "../atoms/CollapseComponent";
import ProfilePicture from "../../components-recruiter/atoms/ProfilePicture";

class ClubPagePost extends PureComponent {
    state = {
        attachmentCollapsed: false
    }

    toggleCollapse = () => {
        this.setState({attachmentCollapsed: !this.state.attachmentCollapsed})
    }

    delete = async () => {
        await deletePost(this.props.authUser, this.props.post.id);
        this.props.getClubInfo();
    }

    render() {
        const post = this.props.post || {};
        const listing = !!post.job_id ? post.job : post.event;
        const user = post.user || {};
        const company = post.company || {};
        const createdAt = DateTime.fromISO(post.created_at);
        const club = this.props.club || {};
        const leadership = club.leadership || {};
        const admin = club.admin || [];
        const isAdmin = this.props.isAdmin;
        const position = leadership[user.username];
        const classYears = post.class_years || [];
        const profilePic = user.profile_pic_url ? user.profile_pic_url : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fblank-person.jpeg?alt=media&token=ac3b0019-71c8-4830-932e-de437faf5fc8";
        return (
            <div
                className={`card rounded-xl w-full`}>
                <div className='flex flex-col justify-between flex-1'>
                    <div className='flex flex-row items-center w-full flex-1'>
                        <div className='w-full'>
                            <div className='w-full'>
                                {!!post.job_id ?
                                    <ClubPageJobItem
                                        listing={listing}
                                        club={club} user={post.user}
                                        getClubInfo={this.props.getClubInfo}
                                        hideRemove
                                        inPost
                                    />
                                    :
                                    <CompanyEventItem
                                        key={listing.id}
                                        item={listing}
                                        isAdmin={isAdmin}
                                        inPost
                                        openEventPage={this.props.openEventPage}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    {post.plaintext?.length > 1 &&
                        <div
                            className='rounded-xl max-h-[200px] overflow-y-scroll mt-3'>
                            <p className={'text-md font-medium text-slate-800'}>
                                {post.plaintext}
                            </p>
                        </div>
                    }
                    <div className='flex flex-row justify-between flex-1 mt-3'>
                        {post.company_post ?
                            <div className='flex flex-row items-center flex-1'>
                                <Link to={`/company/` + company.id}>
                                    <CompanyLogo id={company.id} className={'w-10 h-10'}/>
                                </Link>
                                <div className='ml-3 flex flex-col'>
                                    <div className='flex flex-row items-center gap-2'>
                                        <Link to={`/company/` + company.id}>
                                            <p className='text-md font-semibold blacks-1'>
                                                {company.name}
                                            </p>
                                        </Link>
                                        {company.sector &&
                                            <TagComponent
                                                label={company.sector}
                                                type={''}
                                            />
                                        }
                                    </div>
                                    <div className='flex flex-row items-center gap-3 text-neutral-400'>
                                        {createdAt.isValid ?
                                            <div className='text-xs text-slate-400'>
                                                <p className='m-0'>
                                                    Posted {createdAt.toRelative()}
                                                </p>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='flex flex-row items-center flex-1'>
                                <ProfilePicture
                                    image={user.profile_pic_url}
                                    userID={user.id}
                                    size={40}
                                    className={'border border-slate-200'}
                                />
                                <div className='ml-3 flex flex-col'>
                                    <div className='flex flex-row items-center gap-2'>

                                        <p className='text-md font-semibold blacks-1'>
                                            {user.first_name} {user.last_name}
                                        </p>
                                        {user.grade &&
                                            <TagComponent
                                                label={"Class of " + user.grade}
                                                type={''}
                                            />
                                        }
                                        {!!position &&
                                            <p className='body2 text-slate-400 m-0'>
                                                {position}
                                            </p>
                                        }
                                    </div>
                                    <div className='flex flex-row items-center gap-3 text-neutral-400'>
                                        {createdAt.isValid ?
                                            <div className='text-xs text-slate-400'>
                                                <p className='m-0'>
                                                    Posted {createdAt.toRelative()}
                                                </p>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        }
                        {!!post.uploaded_file &&
                            <a href={post.uploaded_file} target="_blank" rel="noreferrer">
                                <Button variant={'ghost'} size={'sm'} className='mr-3 mt-0.5'>
                                    <SvgNote width={20} height={20}/>
                                    View File
                                </Button>
                            </a>
                        }
                        {classYears.length > 0 ?
                            <div className="flex flex-row mt-4 items-center mr-8">
                                <p className={'text-xs font-semibold text-slate-500'}>
                                    For:
                                </p>
                                <div className='ml-2 flex flex-row flex-wrap gap-3 h-min'>
                                    {classYears.map(classYear =>
                                        <TagComponent
                                            label={"Class of " + classYear}
                                            type={'random'}
                                        />
                                    )}
                                </div>
                            </div>
                            : null}
                        <div className='flex flex-row gap-3'>
                            {(post.creator === this.props.authUser.uid || isAdmin && !post.company_post) ?
                                <Tooltip
                                    toggleComponent={
                                        <button onClick={(e) => {
                                            this.props.editPost(post);
                                            e.stopPropagation()
                                        }}>
                                            <SvgEdit
                                                className={'w-6 h-6 text-neutral-400 hover:text-red-500 cursor-pointer'}/>
                                        </button>
                                    }
                                    label={"Edit this post"}
                                />
                                : null
                            }
                            {((admin.includes(user.username) || post.creator === this.props.authUser.uid || isAdmin ) && !post.company_post) ?
                                <Tooltip
                                    toggleComponent={
                                        <button onClick={(e) => {
                                            this.delete();
                                            e.stopPropagation()
                                        }}
                                        >
                                            <SvgRemove
                                                className={'w-6 h-6 text-neutral-400 hover:text-red-500 cursor-pointer'}/>
                                        </button>
                                    }
                                    label={"Delete this post"}
                                />
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ClubPagePost
