import React, {Component} from "react";
import TagComponent from "../atoms/TagComponent";
import HorizontalDivider from "../atoms/HorizontalDivider";
import {Button} from "../atoms/Button";
import SvgVideo from "../icons/SvgVideo";
import SvgVisibility from "../icons/SvgVisibility";
import ViewAnswerModal from "../modals/ViewAnswerModal";
import {calculateStatistics, scoreColor} from "../../utils/stats";


class QuestionPageInfoSection extends Component {
    state = {
        isAnswerModalOpen: false
    }

    toggleAnswerModal = () => {
        this.setState({isAnswerModalOpen: !this.state.isAnswerModalOpen})
    }

    render() {
        return (
            <div className='flex flex-col gap-3'>
                <p className='text-md uppercase text-slate-500 font-bold -mb-3'>
                    Question
                </p>
                <p className='text-slate-900 text-4xl font-bold'>
                    {this.props.question?.name}
                </p>
                <div className='flex flex-row items-center gap-2'>
                    <TagComponent
                        label={this.props.question?.type}
                    />
                    <TagComponent
                        label={this.props.question?.industry}
                    />
                </div>
                {/* <div className='flex flex-row gap-2 mt-3'>
                    <CompanyLogo id={this.props.question?.company_id} className={'w-14 h-14'}/>
                    <div>
                        <p className='body2 text-slate-500'>
                            from
                        </p>
                        <p className='body1-bold'>
                            {this.props.question?.company_name}
                        </p>
                    </div>
                </div> */}
                <HorizontalDivider className={'bg-slate-200 w-full h-[px] mt-3'}/>
                <div className='flex flex-row items-center py-1'>
                    <div className='flex flex-row items-center gap-1'>
                        <Button onClick={this.props.initiateResponse} icon={SvgVideo} iconPosition={'left'}>
                            Record a Response
                        </Button>
                        {!this.props.hasResponses &&
                            <div className='text-slate-500 ml-3'>
                                Not yet answered
                            </div>
                        }
                        {this.props.hasResponses &&
                            <Button onClick={this.toggleAnswerModal} icon={SvgVisibility} variant={'secondary'}
                                    className={`ml-2`}>
                                View correct answer
                            </Button>
                        }
                    </div>
                    {this.props.hasResponses ?
                        <div className='flex flex-row gap-8 ml-auto'>
                            <div className=''>
                                <p className={`m-0 text-xl font-bold ${this.props.answers.length > 0 ? 'text-primary' : 'text-slate-500'}`}>
                                    {this.props.answers.length}
                                </p>
                                <p className=' text-xs font-semibold text-slate-400'>
                                    Total<br/>Submissions
                                </p>
                            </div>
                            <div className=''>
                                <p className={`m-0 text-xl font-bold`}
                                   style={{color: scoreColor(calculateStatistics(this.props.answers).topScore)}}
                                >
                                    {calculateStatistics(this.props.answers).topScore}
                                </p>
                                <p className=' text-xs font-semibold text-slate-400'>
                                    Best<br/> Score
                                </p>
                            </div>
                            <div className=''>
                                <p className={`m-0 text-xl font-bold`}
                                   style={{color: scoreColor(calculateStatistics(this.props.answers).averageScore)}}
                                >
                                    {calculateStatistics(this.props.answers).averageScore}
                                </p>
                                <p className=' text-xs font-semibold text-slate-400'>
                                    Average<br/> Score
                                </p>
                            </div>
                            <div className=''>
                                <p className={`m-0 text-xl font-bold  text-slate-500`}>
                                    {calculateStatistics(this.props.answers).averageWpm}
                                </p>
                                <p className=' text-xs font-semibold text-slate-400'>
                                    Avg words<br/>per min
                                </p>
                            </div>
                            {/* <div className='hidden xl:block'>
                                <p className={`m-0 text-xl font-bold text-slate-500`}>
                                    {calculateStatistics(this.props.answers).averageNumberOfStrengths}
                                </p>
                                <p className=' text-xs font-semibold text-slate-400'>
                                    Avg # of<br/> Strengths
                                </p>
                            </div>
                            <div className='hidden xl:block'>
                                <p className={`m-0 text-xl font-bold  text-slate-500`}>
                                    {calculateStatistics(this.props.answers).averageNumberOfWeaknesses}
                                </p>
                                <p className=' text-xs font-semibold text-slate-400'>
                                    Avg # of<br/> Weaknesses
                                </p>
                            </div> */}
                        </div>
                        :
                        <Button onClick={this.toggleAnswerModal} icon={SvgVisibility} variant={'secondary'}
                                className={`ml-auto`}>
                            View correct answer
                        </Button>
                    }
                    {/*<Button size={'icon'} variant={'ghost'} icon={SvgBookmark} className='ml-auto'/>*/}
                    {/*<Button size={'icon'} variant={'ghost'} icon={SvgShare}/>*/}
                </div>
                <HorizontalDivider className={'bg-slate-200 w-full h-[px] mb-3'}/>
                <ViewAnswerModal
                    isOpen={this.state.isAnswerModalOpen}
                    title={"Correct answer:"}
                    closeModal={this.toggleAnswerModal}
                    question={this.props.question}
                />
            </div>
        )
    }
}

export default QuestionPageInfoSection
