import React, {Component} from "react";
import ResourcesQuestionCard from "./ResourcesQuestionCard";
import {Button, buttonVariants} from "../atoms/Button";
import {withRouter} from "react-router-dom";
import SvgCheckCircle from "../icons/SvgCheckCircle";
import SvgResume from "../icons/SvgResume";
import SvgHourglass from "../icons/SvgHourglass";

class InterviewSetPreview extends Component {
    state = {
        completed: false,
        status: "not started"
    }

    navigateToQuestion = () => {
        this.props.history.push({
            pathname: `/question/${this.props.set.questions[0].id}`,
            params: {
                item: this.props.item,
                interviewSet: this.props.set,
            }
        })
    }

    componentDidMount() {
        const answerIds = this.props.answers.map(a => a.question_id);
        const allQuestions = this.props.set.questions;
        const answeredCount = allQuestions.filter(question => answerIds.includes(question.id)).length;

        let newStatus;
        if (answeredCount === 0) {
            newStatus = "not started";
        } else if (answeredCount === allQuestions.length) {
            newStatus = "completed";
        } else {
            newStatus = "started";
        }

        if (newStatus !== this.state.status) {
            this.setState({status: newStatus});
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.set !== this.props.set || prevProps.answers !== this.props.answers) {
            const answerIds = this.props.answers.map(a => a.question_id);
            const allQuestions = this.props.set.questions;
            const answeredCount = allQuestions.filter(question => answerIds.includes(question.id)).length;

            let newStatus;
            if (answeredCount === 0) {
                newStatus = "not started";
            } else if (answeredCount === allQuestions.length) {
                newStatus = "completed";
            } else {
                newStatus = "started";
            }

            if (newStatus !== this.state.status) {
                this.setState({status: newStatus});
            }
        }
    }

    renderButtonAndMessage = () => {
        const {status} = this.state;
        let buttonLabel = "";
        let buttonVariant = "";
        let message = "";

        switch (status) {
            case "completed":
                buttonLabel = "View Results";
                buttonVariant = "secondary";
                message = "Congratulations! You have completed this Interview";
                break;
            case "started":
                buttonLabel = "Continue This Interview";
                buttonVariant = "orange";
                message = "You have started this Interview but haven't completed it yet";
                break;
            default:
                buttonLabel = "Start This Interview";
                buttonVariant = "default";
                message = "You haven't started this Interview yet";
                break;
        }

        return (
            <>
          <Button
            onClick={() => {
              if (buttonLabel === "Start This Interview")
                window.analytics.track("Interview Set Started", { interview_id: this.props.set.questions[0].id})
              this.navigateToQuestion();
            }}
            variant={buttonVariant}
            className={`px-20`}
          >
                    {buttonLabel}
                </Button>
                <p className='text-sm text-slate-500'>{message}</p>
            </>
        );
    };


    render() {
        const set = this.props.set || {};
        const {status} = this.state;
        return (
            <>
                <div className='flex flex-col gap-1'>
                    <p className='text-xs font-bold text-primary uppercase'>
                        PREVIEWING
                    </p>
                    <p className='text-3xl font-semibold'>
                        {set.name}
                    </p>
                    <p className='text-slate-700'>
                        {set.description}
                    </p>
                </div>
                <div className='flex flex-row items-center gap-10'>
                    <div className='mb-1'>
                        <p className='text-sm text-slate-500 mb-1'>
                            Status
                        </p>
                        {status === 'completed' ?
                            <div className='flex flex-row items-center gap-1'>
                                <SvgCheckCircle className={'text-primary w-4 h-4'}/>
                                <p className='text-xs font-semibold text-primary'>
                                    FINISHED
                                </p>
                            </div>
                            :
                            status === 'started' ?
                                <div className='flex flex-row items-center gap-1'>
                                    <SvgResume className={'text-orange-500 w-4 h-4'}/>
                                    <p className='text-xs font-semibold text-orange-500'>
                                        STARTED
                                    </p>
                                </div>
                                :
                                <div className='flex flex-row items-center gap-1'>
                                    <SvgHourglass className={'text-slate-500 w-4 h-4'}/>
                                    <p className='text-xs font-semibold text-slate-500'>
                                        NOT STARTED
                                    </p>
                                </div>
                        }
                    </div>
                    <div>
                        <p className='text-sm text-slate-500'>
                            Questions
                        </p>
                        <p className='font-semibold'>
                            {set.questions?.length}
                        </p>
                    </div>
                    <div>
                        <p className='text-sm text-slate-500'>
                            Est Time
                        </p>
                        <p className='font-semibold'>
                            {set.estimated_time}
                        </p>
                    </div>
                    <div>
                        <p className='text-sm text-slate-500'>
                            Difficulty
                        </p>
                        <p className='font-semibold'>
                            {set.difficulty}
                        </p>
                    </div>
                </div>
                <div className='flex flex-row items-center gap-3'>
                    {this.renderButtonAndMessage()}
                </div>
                <div className='flex flex-col gap-0 mt-2 flex-1 overflow-hidden'>
                    <p className='text-xl font-semibold border-b border-slate-200 pb-3'>
                        Questions in this interview ({set.questions?.length}):
                    </p>
                    <div className='flex flex-col flex-1 overflow-y-scroll pb-5'>
                        {set.questions.map(item => {
                                let answer = {};
                                for (const ans of this.props.answers) {
                                    if (ans.question_id === item.id) answer = ans;
                                }
                                return (
                                    <ResourcesQuestionCard
                                        item={item}
                                        grid={false}
                                        interviewSet={this.props.set}
                                        disabled={true}
                                        answer={answer}
                                    />
                                )
                            }
                        )}
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(InterviewSetPreview);
