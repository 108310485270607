import React, {Component} from "react";

class ProgressStepper extends Component {


    render() {
        return (
            <div className='flex flex-col items-center gap-2 mb-8'>
                <div className='flex flex-row items-center justify-center w-5/6'>
                    {this.props.stepLabels.map((item, index) =>
                        <div className='flex-1 flex flex-col items-center' key={item + 'dot'}>
                            <div
                                className={`w-10 h-10 ${this.props.step === (index + 1) ? 'bg-primary/10' : ''} rounded-full flex flex-col items-center justify-center`}>
                                <div
                                    className={`w-2 h-2 rounded-full ${this.props.step === (index + 1) ? 'outline-primary bg-primary' : 'bg-slate-300 outline-slate-300'} outline outline-[1.5px] outline-offset-8`}/>
                            </div>
                        </div>
                    )}
                </div>
                <div className='flex flex-row items-center justify-center w-5/6'>
                    {this.props.stepLabels.map((item, index) =>
                        <div className='flex-1 flex flex-col items-center' key={item + 'label'}>
                            <p className={`text-sm font-semibold text-center ${this.props.step === (index + 1) ? 'text-primary' : 'text-slate-500'}`}>
                                {item}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default ProgressStepper
