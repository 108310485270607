import React, {PureComponent} from "react";
import {connect} from "react-redux";
import AddJobCompany from "../../addjobflow/SearchCompany";
import ModalComponent from "./ModalComponent";
import AddJobTitleDescription from "../../addjobflow/AddJobTitleDescription";
import AddJobLocation from "../../addjobflow/AddJobLocation";
import {createJob} from "../../../api/student/jobs";
import {searchCompanies} from "../../../utils/typesense";
import CompanyLogo from "../CompanyLogo";
import {toast} from "react-toastify";
import {createApplication} from "../../../api/student/applications";
import TagComponent from "../TagComponent";
import {TOAST_OPTIONS} from "../../../utils/constants";
import {Button} from "../Button";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        constants: state.userReducer.constants
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateApplication: (application) => {
            dispatch({
                type: "UPDATE_APPLICATION",
                application,
            })
        },
    }
}

const ZERO_STATE = {
    search: '',
    company: "",
    companyName: "",
    jobTitle: "",
    jobDescription: "",
    url: "",
    location: "",
    state: "",
    jobType: "",
    onSiteRemote: "",
    classYear: "",
    industry: "",
    deadline: null,
    step: 1,
    searchResults: []
}

class AddClubJobModal extends PureComponent {
    state = ZERO_STATE

    handleInputChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    };

    handleLocationChange = (selectedLocation) => {
        const locationArray = [...this.state.location];

        let updatedLocation;
        if (locationArray.includes(selectedLocation)) {
            updatedLocation = locationArray.filter((item) => item !== selectedLocation);
        } else if (selectedLocation === null) {
            updatedLocation = [];
        } else {
            updatedLocation = [...locationArray, selectedLocation]
        }

        this.setState({location: updatedLocation});
    }

    handleDateChange = (e, stateKey) => {
        this.setState({[stateKey]: e})
    };

    updateJobType = (jobType) => {
        this.setState({jobType})
    }

    updateOnSiteRemote = (onSiteRemote) => {
        this.setState({onSiteRemote})
    }

    updateClassYear = (classYear) => {
        this.setState({classYear})
    }

    updateIndustry = (industry) => {
        this.setState({industry})
    }

    switchTitle = () => {
        switch (this.state.step) {
            case 1:
                return "Enter a job manually"
            case 2:
                return "Enter the job title and a description"
            case 3:
                return "Where is this job located?"
            case 4:
                return "Last step, let us know a little more..."
            default:
                return "Here's a preview, everything look good?"
        }
    }

    nextStep = () => {
        this.setState({step: this.state.step + 1})
    }

    previousStep = () => {
        this.setState({step: this.state.step - 1})
    }

    switchNextButtonDisabled = () => {
        switch (this.state.step) {
            case 1:
                return this.state.jobTitle.length === 0 || this.state.url.length === 0 || this.state.companyName.length === 0
            case 3:
                return false
            case 4:
                return false
            default:
                return false
        }
    }

    closeModal = () => {
        this.props.closeModal();
        this.setState(ZERO_STATE);
    }

    submit = async () => {
        const params = {
            name: this.state.jobTitle,
            title: this.state.jobTitle,
            description: this.state.jobDescription,
            company_id: this.state.companyId,
            company_name: this.state.companyName,
            creator: this.props.authUser.uid,
            type: this.state.jobType,
            deadline: this.state.deadline,
            city: !!this.state.location ? [this.state.location] : [],
            state: !!this.state.state ? [this.state.state] : [],
            work_model: this.state.onSiteRemote,
            class_years: !!this.state.classYear ? [this.state.classYear] : [],
            scope: this.props.personalJob ? "personal" : "club",
            club_id: this.props.personalJob ? null : this.props.club.id,
            clubs: this.props.personalJob ? [] : [this.props.club.id],
            link: this.state.url,
            industry: this.state.industry,
        }

        const job = await createJob(this.props.authUser, params);
        this.closeModal();

        if (this.props.personalJob) {
            this.startApplication(job.job);
        } else {
            this.props.getClubInfo();
        }
    }

    startApplication = async (job) => {
        try {
            const applicationPromise = createApplication(this.props.authUser, {
                company_id: job.company_id,
                job_id: job.id,
            });
            toast.promise(
                applicationPromise,
                {
                    pending: 'Adding application...',
                    success: 'Application saved to saved jobs',
                    error: 'Failed to save application to saved jobs',
                },
                TOAST_OPTIONS
            );
            const applicationResult = await applicationPromise
            if (applicationResult) {
                this.props.updateApplication(applicationResult.application);
            }
        } catch (error) {
            toast.error('Failed to save application to saved jobs', TOAST_OPTIONS);
        }
    };

    setCompany = (result) => {
        this.setState({
            searchResults: [],
            companyId: result.id,
            companyName: result.name,
        })
    }

    handleSelectChange = (inputName, value) => {
        this.setState({[inputName]: value});
    };

    companyChanged = async (text) => {
        const searchResults = await searchCompanies(text);
        this.setState({searchResults});
    }

    render() {
        const numSteps = 3
        const progressWidth = (this.state.step / numSteps) * 100;
        return (
            <ModalComponent isOpen={this.props.isOpen}
                            header={"Enter an application manually"}
                            backgroundColor={'white'}
                            headerToggle
                            size={'lg'}
                            toggle={this.closeModal}
                            FooterComponent={
                                <div className='flex flex-row items-center justify-end gap-3'>
                                    {this.state.step === 1 ?
                                        <Button variant={'secondary'} onClick={this.closeModal}>
                                            Close
                                        </Button>
                                        :
                                        <Button variant={'secondary'} onClick={this.previousStep}>
                                            Back
                                        </Button>
                                    }
                                    {this.state.step === numSteps ?
                                        <>
                                            <Button onClick={this.submit}>
                                                Submit
                                            </Button>
                                        </>
                                        :
                                        <Button onClick={this.nextStep}
                                                disabled={this.switchNextButtonDisabled()}>
                                            Next
                                        </Button>
                                    }
                                </div>
                            }
                            footerAlignment={'right'}
                            id={'add-job-modal'}
            >
                <div className='bg-slate-200 h-3 mt-4 rounded-full overflow-hidden '>
                    <div className='bg-indigo-600 h-3'
                         style={{width: progressWidth + '%', transition: 'width 600ms ease-in-out'}}/>
                    {progressWidth}
                </div>
                <div className='px-4 py-4 flex flex-col gap-5 min-h-[20vh] justify-center'>
                    {this.state.step === 0 &&
                        <AddJobCompany nextStep={this.nextStep} setCompany={this.setCompany}
                                       companyChanged={this.companyChanged} searchResults={this.state.searchResults}/>
                    }

                    {this.state.step === 1 &&
                        <AddJobTitleDescription
                            jobTitle={this.state.jobTitle}
                            jobDescription={this.state.jobDescription}
                            url={this.state.url}
                            handleInputChange={this.handleInputChange}
                            nextStep={this.nextStep} setCompany={this.setCompany}
                            companyChanged={this.companyChanged} searchResults={this.state.searchResults}
                        />
                    }

                    {this.state.step === 2 &&
                        <AddJobLocation
                            location={this.state.location}
                            state={this.state.state}
                            deadline={this.state.deadline}
                            handleLocationChange={this.handleLocationChange}
                            handleInputChange={this.handleInputChange}
                            handleSelectChange={this.handleSelectChange}
                            handleDateChange={this.handleDateChange}
                            industry={this.state.industry}
                            updateIndustry={this.updateIndustry}
                            constants={this.props.constants}
                        />
                    }
                    {this.state.step === 3 &&
                        <div>
                            <div className={'flex flex-row items-center gap-3 mt-5'}>
                                <CompanyLogo id={this.state.companyId} className={'w-20 h-20'}/>
                                <div>
                                    <p className='body1-bold m-0 blacks-1 text-neutral-900'>
                                        {this.state.jobTitle}
                                    </p>
                                    <div className='flex flex-row items-center text-neutral-400'>
                                        <p className='body2 m-0'>
                                            {this.state.companyName}&nbsp;•&nbsp;{this.state.location}, {this.state.state}
                                        </p>
                                    </div>
                                    <div className='mt-2 flex flex-row items-center gap-2 flex-wrap'>
                                        <TagComponent
                                            label={this.state.industry}
                                            type={'industry'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className='border border-slate-200 p-5 rounded-xl max-h-[200px] overflow-y-scroll my-5'>
                                <p className={'body2 text-slate-800'}>
                                    {this.state.jobDescription}
                                </p>
                            </div>
                        </div>
                    }
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddClubJobModal)
