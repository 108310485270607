import React, {Component} from "react";

class ProfilePicture extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageError: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.userID !== prevProps.userID) {
            this.setState({imageError: false})
        }
    }

    handleImageError = () => {
        this.setState({imageError: true});
    };

    render() {
        const {imageError} = this.state;
        const {image} = this.props;
        const defaultImage = "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fblank-person.jpeg?alt=media";
        // if (imageError || !image) {
        //     return (
        //         <div className={`rounded-full aspect-square h-min ${this.props.className}`}>
        //             <Avatar
        //                 size={this.props.size || 40}
        //                 name={this.props.userID || 'fallback'}
        //                 variant="marble"
        //                 colors={['#9ba5fd', '#303a8a', '#10123b', '#264aff', '#3D4CB6']}
        //             />
        //         </div>
        //     )
        // }
        return (
            <div
                style={{width: this.props.size || 40, height: this.props.size || 40, borderRadius: '50%'}}
                className={`rounded-full bg-white aspect-square ${this.props.className}`}>
                <img
                    src={imageError || !image ? defaultImage : image}
                    onError={this.handleImageError}
                    className='object-cover'
                    style={{width: this.props.size || 40, height: this.props.size || 40, borderRadius: '50%'}}
                    alt='User Profile'
                />
            </div>
        );
    }
}

export default ProfilePicture;
