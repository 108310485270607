import React, {PureComponent} from "react";
import ToggleButton from "../ToggleButton";
import CollapseComponent from "../CollapseComponent";
import {createClubApplication} from "../../../api/student/clubs";
import {connect} from "react-redux";
import ModalComponent from "./ModalComponent";
import {Button} from "../Button";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
    };
}

class NewClubModal extends PureComponent {
    state = {
        title: '',
        college: '',
        email: '',
        togglePresident: true
    }

    titleChanged = e => this.setState({title: e.target.value});

    emailChanged = e => this.setState({email: e.target.value});

    collegeChanged = e => this.setState({college: e.target.value});


    togglePresident = () => {
        this.setState({isPresident: !this.state.isPresident})
    }


    submitClub = async () => {
        this.props.submitClub();
        const res = await createClubApplication(this.props.authUser, {
            name: this.state.title,
            college: this.state.college,
            email: this.state.email,
        });
        this.setState({email: "", college: "", title: ""});
        return res;
    }

    render() {
        const submitDisabled = this.state.name?.length === 0 || this.state.college?.length === 0
        return (
            <ModalComponent isOpen={this.props.open}
                            header={'Let\'s add your club!'}
                            description={'Enter your club\'s info, and we\'ll get in touch and help get your club set up on RecruitU.'}
                            backgroundColor={'white'}
                            headerToggle
                            size={'lg'}
                            toggle={this.props.close}
                            FooterComponent={
                                <div className='flex flex-row items-center justify-end gap-3'>
                                    <Button onClick={this.props.close} variant={'secondary'}>
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={this.submitClub}
                                        disabled={submitDisabled}
                                        variant={'default'}>Submit your club
                                    </Button>
                                </div>
                            }
                            footerAlignment={'right'}
                            id={'add-job-modal'}
            >
                <div className='flex flex-col w-full gap-5 mb-[-16px]'>
                    <div className='flex flex-row justify-between items-center'>
                        <div className='flex flex-col gap-5 flex-1 mr-5'>
                            <div className="col-plain w-full">
                                <p className="body3-bold text-neutral-400 mb-1">
                                    Club Name
                                </p>
                                <input
                                    value={this.state.title}
                                    onChange={this.titleChanged}
                                    placeholder={'ie. Capital Scholars Society'}
                                    className='input-light px-3 body2 base-black-50 w-full'
                                />
                            </div>
                            <div className="col-plain w-full">
                                <p className="body3-bold text-neutral-400 mb-1">
                                    School / University
                                </p>
                                <input
                                    value={this.state.college}
                                    onChange={this.collegeChanged}
                                    placeholder={'ie. Avalon University'}
                                    className='input-light px-3 body2 base-black-50 w-full'
                                />
                            </div>
                            <div
                                className='bg-neutral-100 flex flex-col p-5 border border-neutral-200 rounded-xl mt-2'>
                                <div
                                    className={`flex flex-row items-center justify-between ${this.state.isPresident && 'mb-0'}`}>
                                    <p className="body3-bold text-neutral-400">
                                        Are you the Club president?
                                    </p>
                                    <div className='flex flex-row items-center'>
                                        <p className={`mr-2 body4-bold uppercase ${this.state.isPresident ? 'primary' : 'text-neutral-400'}`}>
                                            {!this.state.isPresident ? 'No' : 'Yes'}
                                        </p>
                                        <ToggleButton
                                            active={this.state.isPresident}
                                            onClick={this.togglePresident}
                                        />
                                    </div>
                                </div>
                                <CollapseComponent isOpen={!this.state.isPresident}>
                                    <div className="col-plain w-full mt-3">
                                        <p className="body3-bold text-neutral-400 mb-1">
                                            President's Email
                                        </p>
                                        <input
                                            value={this.state.email}
                                            onChange={this.emailChanged}
                                            placeholder={'example@university.edu'}
                                            className='input-light px-3 body2 base-black-50 w-full'
                                        />
                                    </div>
                                </CollapseComponent>
                            </div>
                        </div>
                        <img
                            className='w-1/2'
                            src={require('../../../img/illustrations/techny-school-supplies-for-school-subjects.gif')}/>
                    </div>
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(NewClubModal);
