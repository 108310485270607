import SvgEdit from "../components/icons/SvgEdit";
import SvgPlus from "../components/icons/SvgPlus";
import SvgCellMerge from "../components/icons/SvgCellMerge";
import SvgCash from "../components/icons/SvgCash";
import SvgAccounting from "../components/icons/SvgAccounting";
import SvgValuation from "../components/icons/SvgValuation";
import SvgEnterpriseVal from "../components/icons/SvgEnterpriseVal";
import SvgBarinstorm from "../components/icons/SvgBarinstorm";
import SvgBuild from "../components/icons/SvgBuild";
import SvgCoins from "../components/icons/SvgCoins";
import SvgPrivateEquity from "../components/icons/SvgPrivateEquity";
import {roundTo} from "./strings";
import SvgCopy from "../components/icons/SvgCopy";
import SvgQuiz from "../components/icons/SvgQuiz";
import SvgCheckAll from "../components/icons/SvgCheckAll";

export function calculateStatistics(answers) {
    // Initialize counters and accumulators
    let totalScore = 0;
    let topScore = 0;
    let totalWpm = 0;
    let totalStrengths = 0;
    let totalWeaknesses = 0;
    let countWpm = 0; // Count only answers with a valid wpm for average calculation
    let numAnswers = 0

    answers.forEach(answer => {
        const score = answer.score || 0;
        const wpm = parseFloat(answer.wpm) || 0; // Ensure wpm is treated as a number
        const strengths = answer.strengths ? answer.strengths.length : 0;
        const weaknesses = answer.weaknesses ? answer.weaknesses.length : 0;

        if (score === 0) {
            return
        } else {
            numAnswers += 1;
        }
        totalScore += score;
        topScore = Math.max(topScore, score);
        if (wpm > 0) {
            totalWpm += wpm;
            countWpm += 1;
        }
        totalStrengths += strengths;
        totalWeaknesses += weaknesses;
    });

    // Calculate averages
    const averageScore = totalScore / numAnswers || '--';
    const averageWpm = countWpm > 0 ? totalWpm / countWpm : '--';
    const averageStrengths = totalStrengths / numAnswers || '--';
    const averageWeaknesses = totalWeaknesses / numAnswers || '--';

    // Function to format the output, replacing NaN with "--"
    const formatOutput = (value, digits) => typeof (value) === 'string' ? "--" : roundTo(value, digits);

    // Construct and return the statistics object with formatted values
    return {
        averageScore: formatOutput(averageScore, 1),
        topScore: topScore === 0 ? '--' : formatOutput(topScore, 1),
        averageWpm: formatOutput(averageWpm, 0),
        averageNumberOfStrengths: formatOutput(averageStrengths, 2),
        averageNumberOfWeaknesses: formatOutput(averageWeaknesses, 2)
    };
}


export const scoreColor = (score) => {
    if (score >= 9) {
        return '#4caf50'; // Darker green, highest scores
    } else if (score >= 8) {
        return '#8bc34a'; // Dark lime green
    } else if (score >= 7) {
        return '#cddc39'; // Lime, transitioning from green to yellow
    } else if (score >= 6) {
        return '#ffeb3b'; // Yellow
    } else if (score >= 5) {
        return '#ffc107'; // Amber, indicating a middle range
    } else if (score >= 4) {
        return '#ff9800'; // Orange
    } else if (score >= 3) {
        return '#ff5722'; // Deep orange, starting to indicate lower scores
    } else if (score >= 2) {
        return '#f44336'; // Red
    } else if (score >= 1) {
        return '#e53935'; // Darker red, for the lower scores
    } else if (score > 0) {
        return '#d32f2f'; // Deep dark red, for scores between 0 and 1
    } else {
        return '#607d8b'; // Blue-gray, for 0 or unexpected values
    }
};


export const renderQuestionCategoryIcon = (category) => {
    switch (category) {
        case category = "Private Equity / Buyside Recruiting":
            return SvgPrivateEquity
        case category = "Leveraged Buyouts (LBOs)":
            return SvgCoins
        case category = "Mergers":
            return SvgCellMerge
        case category = "DCF":
            return SvgCash
        case category = "Accounting":
            return SvgAccounting
        case category = 'Restructuring (Rx) / Distressed M&A':
            return SvgBuild
        case category = 'Precedent Transactions':
            return SvgValuation
        case category = 'Comparable Company Analysis':
            return SvgEnterpriseVal
        case category = 'Brain Teasers / Misc.':
            return SvgBarinstorm
        case category = 'All':
            return SvgCheckAll
        default:
            return SvgCopy
    }
}

