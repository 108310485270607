import React from "react";
import { connect } from "react-redux";
import { Button } from "../../components/atoms/Button";
import CopyReferralButton from "../../components/atoms/CopyReferralButton";
import { useHistory } from "react-router-dom";

const ResourcesPagePlaceholder = ({ user, page }) => {
    const history = useHistory();
    const completeUserProfile = () => {
        history.push('/edit-profile');
    };

    const isPersonalizationComplete = user.completed_personalization;

    const numSteps = 3;
    // Added this so progress bar doesn't go to zero 
    const progressWidth = ((user.referrals?.length || 0) + 0.00000001 / numSteps) * 100;

    if (page === 'practice') {
        return (
            <div className='absolute h-screen top-16 left-0 right-0 bottom-0 bg-slate-400/20 backdrop-blur-sm flex flex-col items-center justify-center pb-80'>
                <div className='card w-[500px]'>
                    <img alt={'invite'}
                         src={require('../../img/illustrations/techny-school-supplies-for-school-subjects.gif')}
                         className='w-1/2 mx-auto scale-125'/>
                    <div className="flex flex-col justify-center items-center">
                        <p className='text-xl mt-5 text-slate-800 font-semibold mb-2'>
                            Practice coming soon
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='absolute h-screen top-16 left-0 right-0 bottom-0 bg-slate-400/20 backdrop-blur-sm flex flex-col items-center justify-center pb-80'>
            <div className='card w-[500px]'>
                <img alt={'invite'}
                     src={require('../../img/illustrations/techny-secure-lock-and-key.gif')}
                     className='w-1/2 mx-auto scale-125'/>
                <div className="flex flex-col justify-center items-center">
                    <p className='text-xl text-slate-800 font-semibold mb-2'>
                        Unlock more learning resources
                    </p>
                    <p className='text-md text-slate-700 mb-4 text-center'>
                        The resources tab will be a one-stop shop for interview prep personally tailored to you.
                        <strong>
                            &nbsp;Complete the steps below to unlock this page.
                        </strong>
                    </p>

                    <div className='flex flex-row w-full items-center mb-5 justify-between'>
                        <div className='flex flex-row items-center gap-2'>
                            <div className='bg-primary w-8 h-8 rounded-full flex flex-col items-center justify-center text-white text-xl font-semibold'>
                                1
                            </div>
                            <p className='font-semibold'>
                                Complete my profile
                            </p>
                        </div>
                        {isPersonalizationComplete ?
                            <Button variant={'green'} disabled={true}>
                                Completed
                            </Button>
                            :
                            <div className='flex flex-row items-center gap-3'>
                                <Button variant='secondary' onClick={completeUserProfile}>
                                    Complete
                                </Button>
                            </div>
                        }
                    </div>

                    <div className='flex flex-row w-full items-center justify-between'>
                        <div className='flex flex-row items-center gap-2'>
                            <div className='bg-primary w-8 h-8 rounded-full flex flex-col items-center justify-center text-white text-xl font-semibold'>
                                2
                            </div>
                            <p className='font-semibold'>
                                Invite 2 users ({user.referrals?.length || 0}/2)
                            </p>
                        </div>
                        {user.referrals?.length >= 2 ?
                            <Button variant={'green'} disabled={true}>
                                Complete
                            </Button>
                            :
                           <CopyReferralButton />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.userReducer.user
});

export default connect(mapStateToProps)(ResourcesPagePlaceholder);
