import axios from '../axios';

export const getUserAnswers = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/answers", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const createAnswer = async (user, params) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/answers", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const gradeAnswer = async (user, answer_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/answers/grade", {answer_id}, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}