// Libraries
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {AddToCalendarButton} from 'add-to-calendar-button-react';

// Components
import EventPageAboutEventWidget from '../eventpage/EventPageAboutEventWidget';
import EventPageTitleWidget from '../eventpage/EventPageTitleWidget';
import JobPageAboutCompanyWidget from '../jobpage/JobPageAboutCompanyWidget';
import PageContentWrapper from '../wrappers/PageContentWrapper';
import Spinner from '../Spinner';
import SvgArrowOutward from '../icons/SvgArrowOutward';
import {Button} from "../atoms/Button";
import GoBack from "../atoms/GoBack";
import { getEvent } from '../../api/student/events';


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
    }
}


function PublicEventPage({authUser, user, setUser, history}) {
    const {eventId} = useParams();
    const [loading, setLoading] = useState(true);
    const [event, setEvent] = useState({})
    const [validId, setValidId] = useState(false);


    useEffect(() => {
        fetchEvent();
    }, []);

    const fetchEvent = async () => {
        const event = await getEvent(eventId);

        // If event out of scope go back
        if (!event || event.scope !== "public") {
            setLoading(false);
            return;
        }

        setValidId(true);
        setEvent(event);
        window.analytics.page("Public Event Page Viewed", {
            "event_id": eventId,
        })
        setLoading(false);
    }

    const company = event.company || {};
    if (loading) {
        return (
            <div className='flex flex-col items-center justify-center min-h-[60vh]'>
                <Spinner/>
            </div>
        )
    }
    return (
        <PageContentWrapper>
            {(!validId) ?
                    <div className='flex h-full justify-center items-center'>
                        <p>404: Event does not exist or you do not have access</p>
                    </div>
                    :
                    <div className='flex flex-col flex-1 overflow-y-scroll py-5'>
                        <div className={'pt-2 w-full max-w-[1100px] mx-auto px-4'}>
                            <div className='flex flex-row items-center gap-3 mb-3'>
                                <GoBack/>
                            </div>
                            <EventPageTitleWidget listing={event} loading={loading} user={user} authUser={authUser}
                                                  fetchEvent={fetchEvent}/>
                            <div className='card flex flex-row gap-3 flex-1 w-full'>
                                <div className='flex-1 w-full'>
                                <Button
                                    onClick={() => history.push(`/authentication?path=event/${event.id}`)}
                                    variant={'secondary'}>
                                    RSVP for Event
                                    <SvgArrowOutward className={'w-5 h-5'}/>
                                </Button>
                                </div>
                            </div>
                            <EventPageAboutEventWidget listing={event} loading={loading}/>
                            {!!company.name ? <JobPageAboutCompanyWidget authUser={authUser} listing={event} user={user} hideFollowButton
                                                                         setUser={setUser}/> : null}
                        </div>
                    </div>
            }
        </PageContentWrapper>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicEventPage);
