import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import {
  getApplication,
  checkForExistingApplication,
} from "../../api/clubAdmin/applications";
import { getClub } from "../../api/clubAdmin/clubs";
import { connect } from "react-redux";
import { ClubAdminAboutSection } from "../ClubAdminAboutSection";
import { Button } from "../../components/atoms/Button";
import { submitApplication } from "../../api/clubAdmin/applications";
import { Transition } from "@headlessui/react";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";

function mapStateToProps(state) {
  return {
    authUser: state.userReducer.authUser,
    user: state.userReducer.user,
  };
}

const ClubApplicationPage = ({ authUser, user }) => {
  const { clubId, applicationId } = useParams();
  const [application, setApplication] = useState(null);
  const [applicationQuestions, setApplicationQuestions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [club, setClub] = useState(null);
  const [isClubAdmin, setIsClubAdmin] = useState(null);
  const [isSubmissionLoading, setIsSubmissionLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (!user) return;

      setIsLoading(true);
      setShow(false);

      const clubAdmin = user.admin.some((admin) => admin.id === clubId);
      setIsClubAdmin(clubAdmin);

      const clubData = await getClub(authUser, clubId);
      setClub(clubData.club);

      if (clubAdmin) {
        // Admin doesn't need to check for existing applications
        const { application, application_questions } = await getApplication(authUser, applicationId);
        setApplication(application[0]);
        setApplicationQuestions(application_questions);
        setAnswers(application_questions.map((question) => ({
          question_id: question.id,
          answer: "",
        })));
      } else {
        // Non-admin users check for existing application
        const { application_submission } = await checkForExistingApplication(authUser, user.id, applicationId);

        if (application_submission.length > 0) {
          setShow(true);
        } else {
          const { application, application_questions } = await getApplication(authUser, applicationId);
          setApplication(application[0]);
          setApplicationQuestions(application_questions);
          setAnswers(application_questions.map((question) => ({
            question_id: question.id,
            answer: "",
          })));
        }
      }

      setIsLoading(false);
    }

    fetchData();
  }, [clubId, applicationId, authUser, user]);


  async function submit() {
    setIsSubmissionLoading(true);
    const params = {
      application_id: application.id,
      club_id: club.id,
      user_id: user.id,
      answers: answers.map((answer) => ({
        question_id: answer.question_id,
        answerText: answer.answer,
      })),
    };
    await submitApplication(authUser, params);
    setShow(true);
    setIsSubmissionLoading(false);
  }

  const handleAnswerChange = (question_id, value) => {
    const newAnswers = answers.map((answer) =>
      answer.question_id === question_id
        ? { ...answer, answer: value }
        : answer,
    );
    setAnswers(newAnswers);
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full">
        <Spinner light={true} size={24} />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-start w-full mx-auto max-w-[950px] justify-start px-4 py-6 ">
      <ClubAdminAboutSection prependTitle={'Apply To'} isAdmin={false} club={club} />
      <div className="flex flex-row justify-between gap-3 w-full">
        <div className="bg-white shadow-lg flex-1 shadow-slate-200 p-5 mt-5 border border-slate-200 rounded-xl min-h-[12rem]">

            {!show && (
              <>
                {isClubAdmin && (
                  <div className="w-full bg-zinc-100 text-center py-2 rounded-lg mb-4">
                    <p>
                      This is a preview of the application as it will appear to
                      applicants.
                    </p>
                  </div>
                )}
                <p className="text-xl font-semibold text-slate-800">
                  {application.title}
                </p>
                {!!application.description &&
                    <p className="text-slate-700 text-md mt-3 mb-5">
                      {application.description}
                    </p>
                }

                <ul className="w-full mt-12">
                  {applicationQuestions.map((question, index) => (
                      <li key={question.id} className="flex-col">
                      <p className="text-slate-700 font-semibold font-base mb-3">
                        <span className='font-bold'>
                          {index+1}.&nbsp;
                        </span>
                        {question.questionText}
                      </p>
                      <textarea
                        value={
                          answers.find(
                            (answer) => answer.question_id === question.id,
                          )?.answer || ""
                        }
                        onChange={(e) =>
                          handleAnswerChange(question.id, e.target.value)
                        }
                        placeholder={
                          "Answer to the best of your ability. There are no wrong answers!"
                        }
                        className="input-light text-sm px-3 py-3 body2 base-black-50 w-full min-h-[100px]"
                      />
                    </li>
                  ))}
                </ul>
                {!isClubAdmin && user.resume && (
                  <div className="flex justify-end">
                    <Button
                      onClick={submit}
                      variant="default"
                      className="w-64 mt-8"
                      disabled={
                        isSubmissionLoading ||
                        answers.some((answer) => !answer.answer)
                      }
                    >
                      <span className="pr-2">Submit Application</span>{" "}
                      {isSubmissionLoading && (
                        <Spinner light={true} size={18} />
                      )}
                    </Button>
                  </div>
                )}
                {!user.resume &&
                  <p className="text-red-400 text-md mt-3 mb-5">
                    Please go to the About section of your profile and upload your resume before you can apply to this job.
                  </p>
                } 
              </>
            )
          }
          <Transition
            show={show}
            enter="transition-all ease-in-out duration-500 delay-[200ms]"
            enterFrom="opacity-0 translate-y-6"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="flex flex-row items-center justify-center mx-auto w-full h-full"
          >
            <div>
              <CheckBadgeIcon className="w-10 h-10 text-primary items-center justify-center" />
              <div className="h-12 w-0.5 bg-slate-900 mx-4" />
              <p className="text-slate-900 font-bold text-sm">
                Your application has been submitted successfully!
              </p>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(ClubApplicationPage);
