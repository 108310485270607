import * as Dialog from '@radix-ui/react-dialog';
import SvgClose from "../../icons/SvgClose";
import {suggestJob} from "../../../api/student/jobs";
import React, {PureComponent} from "react";

class SuggestJobModal extends PureComponent {
    state = {
        link: ""
    }

    suggest = async () => {
        await suggestJob(this.props.authUser, this.state.link);
        this.setState({link: ""});
    }

    render() {
        return (
            <Dialog.Root>
                <Dialog.Trigger asChild>
                    {this.props.children}
                </Dialog.Trigger>
                <Dialog.Portal>
                    <Dialog.Overlay className="DialogOverlay"/>
                    <Dialog.Content className="DialogContent">
                        <Dialog.Title className="title2 text-neutral-900">Can't find the job you're looking
                            for?</Dialog.Title>
                        <Dialog.Description className="DialogDescription body2 text-neutral-400">
                            Drop the link to an application and we'll look to add it onto the platform as soon as we can!
                        </Dialog.Description>
                        <label htmlFor="message" className="block mb-2 body2-bold text-neutral-400 dark:text-white">
                            Application URL
                        </label>
                        <input
                            value={this.state.link}
                            onChange={(e) => this.setState({link: e.target.value})}
                            placeholder={'ie. https://www.linkedin.com/jobs/view/trading-analyst'}
                            className='input-light px-3 body2 base-black-50 w-full'
                        />
                        <div style={{display: 'flex', marginTop: 25, justifyContent: 'flex-end'}}>
                            <Dialog.Close asChild>
                                <button className="primary-button body1-bold"
                                        onClick={this.suggest}>
                                    Suggest Job
                                </button>
                            </Dialog.Close>
                        </div>
                        <Dialog.Close asChild>
                            <button className="IconButton" aria-label="Close">
                                <SvgClose/>
                            </button>
                        </Dialog.Close>
                    </Dialog.Content>
                </Dialog.Portal>
            </Dialog.Root>
        )
    }
};

export default SuggestJobModal;
