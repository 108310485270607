import React, {useEffect, useState} from "react";
import Spinner from "../components/Spinner";
import {connect} from "react-redux";
import MessageStudentFlow from "./MessageStudentFlow";
import {Button} from "../components/atoms/Button";
import {deleteList, editList, getLists, getSingleList} from "../api/recruiter/lists";
import SvgEdit from "../components/icons/SvgEdit";
import EditListModal from "./modals/EditListModal";
import { DateTime } from 'luxon';
import ClubLogo from "./atoms/ClubLogo";
import SvgSchool from "../components/icons/SvgSchool";
import SvgClose from "../components/icons/SvgClose";
import RecruiterDashboardTable from "./RecruiterDashboardTable";
import {SAVED_LISTS_TABLE_HEADERS} from "../utils/dummy";
import RecruiterFavoritesTableItem from "./RecruiterFavoritesTableItem";
import SvgMoreVertical from "../components/icons/SvgMoreVertical";
import GoBack from "../components/atoms/GoBack";
import SvgShare from "../components/icons/SvgShare";
import SvgPlus from "../components/icons/SvgPlus";
import { filteredUserSearch } from "../utils/typesense";
import SvgStars from "../components/icons/SvgStars";
import { NUM_STUDENTS_SHOWN } from "../utils/constants";
import {downloadAllResumes} from "../api/student/clubs";
import { toast } from "react-toastify";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import { TOAST_OPTIONS } from "../utils/constants";
import SearchHeaderWrapper from "./atoms/SearchHeaderWrapper";
import { MAJOR_OPTIONS } from "../utils/dummy";
import { filterUsers } from "../utils/searchUtils";
import { useMemo } from "react";
import { useCallback } from "react";
import { searchClubs } from "../utils/typesense"; // Make sure to import this function

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleCreateJobModal: (clubs) => {
            dispatch({
                type: "TOGGLE_CREATE_JOB_MODAL",
                clubs
            })
        },
        toggleCreateEventModal: (clubs) => {
            dispatch({
                type: "TOGGLE_CREATE_EVENT_MODAL",
                clubs
            })
        },
    }
}


function RecruiterSavedClubsPage({authUser, toggleCreateEventModal, toggleCreateJobModal, history}) {
    const [type, setType] = useState("user");
    const [list, setList] = useState({});
    const [error, setError] = useState(false);
    const [IsEditClubListModalOpen, setIsEditClubListModalOpen] = useState(false)
    const [activeTab, setActiveTab] = useState('Students');
    const [isEditing, setIsEditing] = useState(false);
    const [page, setPage] = useState(1);
    const [hideMoreStudents, setHideMoreStudents] = useState(false);
    const [moreStudentsLoading, setMoreStudentsLoading] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [majors, setMajors] = useState([]);
    const [classYears, setClassYears] = useState([]);
    const [gpa, setGpa] = useState("");
    const [gender, setGender] = useState("");
    const [race, setRace] = useState("");
    const [veteran, setVeteran] = useState(false);
    const [lgbtq, setLgbtq] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [college, setCollege] = useState("");
    const [clubs, setClubs] = useState([]);
    const [selectedClubs, setSelectedClubs] = useState([]);

    const selectedClubsMappedToIds = useMemo(() => {
        return clubs.filter(club => selectedClubs.includes(club.name)).map(club => club.id);
    }, [selectedClubs, clubs]);

    const delList = async () => {
        await deleteList(authUser, list.id, type);
        history.push('/saved-lists')
    }

    const remove = async (id) => {
        await editList(authUser, list.id, type, {
            [`${type}_ids`]: [id]
        })
        getList();
    }

    useEffect(() => {
        getList();
    }, [])

    const getList = async () => {
        setLoading(true);
        const parts = window.location.pathname.split("/");
        const lastArg = parts[3];
        const type = parts[2];
        const listId = lastArg.split("?")[0];

        const res = await getSingleList(authUser, listId, type)
        const list = res.list
        if (type === "smart") {
            await fetchUserSearch("", list);
        } else {    
            setList(list);
        }
        setType(type);
        setLoading(false);
    }

    const fetchUserSearch = useCallback(async (query, currentList) => {
        const page = 1
        setPage(page);
        const users = await filteredUserSearch(query, page, null, currentList.grades, currentList.majors, currentList.colleges, currentList.gpa, currentList.gender, currentList.race, currentList.veteran, currentList.lgbtq, currentList.disabled, currentList.authorized, currentList.industries || []);
        setList({
            ...currentList,
            users: users.students,
            total: users.total
        });
        setHideMoreStudents(users.students.length < NUM_STUDENTS_SHOWN);
    }, [list.grades, list.majors, list.colleges, list.gpa, list.gender, list.race, list.veteran, list.lgbtq, list.disabled, list.authorized, list.industries]);

    const filteredUsers = useMemo(() => {
        if (!list.users || type === 'smart') return [];
        return filterUsers(list.users, {
            search,
            majors,
            classYears,
            gpa,
            gender,
            race,
            veteran,
            lgbtq,
            disabled,
            authorized,
            college,
            selectedClubsMappedToIds
        });
    }, [list.users, search, majors, classYears, gpa, gender, race, veteran, lgbtq, disabled, authorized, college, type, selectedClubsMappedToIds]);

    const getNextPageInfo = useCallback(async () => {
        setMoreStudentsLoading(true);
        const newUsers = await filteredUserSearch(search, page + 1, null, list.grades, list.majors, list.colleges, list.gpa, list.gender, list.race, list.veteran, list.lgbtq, list.disabled, list.authorized, list.industries || []);
        setList(prevList => ({
            ...prevList,
            users: [...prevList.users, ...newUsers.students]
        }));
        setPage(prevPage => prevPage + 1);
        setMoreStudentsLoading(false);
        setHideMoreStudents(newUsers.students.length < NUM_STUDENTS_SHOWN);
    }, [search, page, list.grades, list.majors, list.colleges, list.gpa, list.gender, list.race, list.veteran, list.lgbtq, list.disabled, list.authorized, list.industries]);

    const downloadCsv = async () => {
        let csv = '';
        if (type === 'club') {
            csv = `${list.name}\n`;
            csv += 'Club Name,School\n';
            const clubsToDownload = list.clubs || [];
            csv += clubsToDownload.map(club => `"${club.name.replace(/"/g, '""')}","${club.college.replace(/"/g, '""')}"`).join('\n');
        } else if (type === 'smart') {
            let usersToDownload = [...list.users];
            for (let page = 2; page < 100; page += 1) {
                const newUsers = await filteredUserSearch(search, page, null, list.grades, list.majors, list.colleges, list.gpa, list.gender, list.race, list.veteran, list.lgbtq, list.disabled, list.authorized, list.industries || []);
                usersToDownload = [...usersToDownload, ...newUsers.students];
                if (newUsers.students.length < NUM_STUDENTS_SHOWN) {
                    break
                }
            }
            csv = `name,email,class year,college,gpa,majors,minors,authorized to work,disability,first generation,gender,race,requires sponsorship,veteran\n`;
            csv += usersToDownload.map(row => `${row.first_name} ${row.last_name},${row.email},${row.grade},"${row.college.replace(/"/g, '""')}",${row.gpa},${row?.major.join("/")},${row?.minor?.join("|")},${row?.diversity?.authorized},${row.diversity?.disabled},${row.diversity?.first_generation},${row.diversity?.gender},${row.diversity?.race?.join("|")},${row.diversity?.require_sponsorship},${row.diversity?.veteran}`).join('\n');
        } else {
            const usersToDownload = filteredUsers;
            csv = `name,email,class year,college,gpa,majors,minors,authorized to work,disability,first generation,gender,race,requires sponsorship,veteran\n`;
            csv += usersToDownload.map(row => `${row.first_name} ${row.last_name},${row.email},${row.grade},"${row.college.replace(/"/g, '""')}",${row.gpa},${row?.major.join("/")},${row?.minor?.join("|")},${row?.diversity?.authorized},${row.diversity?.disabled},${row.diversity?.first_generation},${row.diversity?.gender},${row.diversity?.race?.join("|")},${row.diversity?.require_sponsorship},${row.diversity?.veteran}`).join('\n');
        }

        // Create a Blob object from the CSV data
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        // Create a link element and click it programmatically to trigger download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${list.name}.csv`);
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };
    
    const handleDownload = async (year = "*") => {
        try {
          let usersToDownload = [];
          if (type === 'smart') {
            usersToDownload = [...list.users];
            for (let page = 2; page < 100; page += 1) {
                const newUsers = await filteredUserSearch(search, page, null, list.grades, list.majors, list.colleges, list.gpa, list.gender, list.race, list.veteran, list.lgbtq, list.disabled, list.authorized, list.industries || []);
                usersToDownload = [...usersToDownload, ...newUsers.students];

                if (newUsers.students.length < NUM_STUDENTS_SHOWN) {
                    break
                }
                console.log('aaaa', usersToDownload)
            }
          } else {
            usersToDownload = filteredUsers;
          } 
          const resumePromise = downloadAllResumes(authUser, usersToDownload);
          toast.promise(
            resumePromise,
            {
              pending: "Downloading resumes...",
              success: "Resumes downloaded successfully!",
              error: "No resumes available for these filters",
            },
            TOAST_OPTIONS
          );
          const resumeResult = await resumePromise;
          if (!resumeResult) {
            setError(true);
          } else {
            setError(false);
          }
        } catch (error) {
          console.log("Error no resumes this year");
        }
    }

    const [loading, setLoading] = useState(false)
    if (loading) {
        return (
            <div className='flex flex-col items-center justify-center flex-1 mb-[15vh]'>
                <Spinner size={40}/>
            </div>
        )
    }

    const users = list?.users || [];
    // const clubs  = list?.clubs || [];
    const listCreation = DateTime.fromISO(list.created_at);
    const colleges = list?.colleges || [];

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        if (type === 'smart') {
            fetchUserSearch(value, list);
        }
    };

    const updateMajor = (majors) => {
        if (majors === null) {
            setMajors([]);
            return;
        }

        setMajors(prevMajors => 
            prevMajors.includes(majors)
                ? prevMajors.filter(major => major !== majors)
                : [...prevMajors, majors]
        );
    };

    const updateClassYears = (value) => {
        if (value === null) {
            setClassYears([]);
            return;
        }

        setClassYears(prevClassYears => 
            prevClassYears.includes(value)
                ? prevClassYears.filter(year => year !== value)
                : [...prevClassYears, value]
        );
    };

    const updateGpa = (value) => {
        setGpa(value);
    };

    const handleClubChange = (value) => {
       if (value === null) {
        setSelectedClubs([]);
        return;
       }
       setSelectedClubs(prevSelectedClubs => 
        prevSelectedClubs.includes(value)
            ? prevSelectedClubs.filter(club => club !== value)
            : [...prevSelectedClubs, value]
       );
    }

    const updateDiversity = (field, value) => {
        switch (field) {
            case 'gender':
                setGender(value);
                break;
            case 'race':
                setRace(value);
                break;
            case 'veteran':
                setVeteran(value);
                break;
            case 'lgbtq':
                setLgbtq(value);
                break;
            case 'disabled':
                setDisabled(value);
                break;
            case 'authorized':
                setAuthorized(value);
                break;
        }
    };

    const setSchool = async (value) => {
        setCollege(value);
        if (value) {
            try {
                const clubResults = await searchClubs("", 1, 100, value);
                setClubs(clubResults);
            } catch (error) {
                setClubs([]);
            }
        } else {
            setClubs([]);
        }
    };


    return (
        <div className={`flex flex-col flex-1 overflow-y-hidden`}>

            <div className="ml-3 mt-3">
                <GoBack />
            </div>

            <div className='flex flex-row items-end justify-between p-5 pb-0'>
                <div className='flex flex-row items-center gap-2 mb-2'>
                    <p className='text-slate-800 text-2xl font-semibold'>
                        {list.name}
                        <span className='text-slate-500'>
                            {` (${
                                type === 'club'
                                    ? list.clubs?.length ?? 0
                                    : type === 'smart'
                                    ? list.total ?? 0
                                    : filteredUsers.length
                            } ${type === 'club' ? 'clubs' : 'students'})`}
                        </span>
                    </p>
                    {type === 'smart' &&
                        <SvgStars className={'text-primary'}/>
                    }
                </div>
                <div className='flex flex-row gap-3'>
                    {type === "user" && <MessageStudentFlow users={list[`users`]} buttonVariant={'secondary'}/>}
                    <Button variant={'secondary'} onClick={downloadCsv}>
                        Download CSV
                    </Button>
                    {type !== "club" &&
                        <Button variant={'secondary'} onClick={handleDownload}>
                            Download Resumes
                        </Button>
                    }
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            setIsEditClubListModalOpen(true)
                        }}
                        icon={SvgEdit} size={'icon'} variant={'secondary'}/>
                    {isEditing ?
                        <div className="mr-8">
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setIsEditing(false)
                                }}
                                className="mr-8"
                                variant={'secondary'}>
                                {type === 'user' ? 'Cancel' : 'Finish editing'}
                            </Button>
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setConfirmationModalOpen(true);
                                }}
                                variant={'destructive'}>
                                Delete list
                            </Button>
                        </div>

                        :
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                setIsEditing(true)
                            }}
                            icon={SvgMoreVertical} size={'icon'}
                            variant={'secondary'}/>
                    }
                </div>
            </div>
            <div className='flex flex-row items-end pl-5 '>
                <p className='text-slate-800 text-sm'>
                    <strong>Created:</strong> {listCreation.toLocaleString(DateTime.DATETIME_MED)} {!!list.creator_name && ` by ${list.creator_name}`}
                </p>
            </div>
            {colleges.length ?
                <div className='flex flex-row items-end pl-5'>
                    <p className='text-slate-800 text-sm'>
                        <strong>Colleges:</strong> {list.colleges.join(", ")}
                    </p>
                </div>
                : null
            }
            <div className={'flex flex-1 flex-col overflow-hidden px-5 mt-2'}>
                {type !== 'club' && (
                    <SearchHeaderWrapper
                        className="mb-4"
                        search={{
                            placeholder: 'Search by name...',
                            value: search,
                            onChange: handleSearchChange,
                        }}
                        updateDiversity={type !== 'smart' ? updateDiversity : undefined}
                        setSchool={type !== 'smart' ? setSchool : undefined}
                        clubs={type !== 'smart' ? clubs : undefined}
                        selectedClubs={selectedClubs}
                        handleClubChange={handleClubChange}
                        schoolSearch={type !== 'smart' ? college : undefined}
                        selects={ type !== 'smart' ? [
                            {
                                label: 'Major',
                                value: majors,
                                setValue: updateMajor,
                                options: MAJOR_OPTIONS,
                                multiSelect: true,
                            },
                            {
                                label: 'Class Year',
                                value: classYears,
                                setValue: updateClassYears,
                                options: [{title: "2025",}, {title: "2026",}, {title: "2027",}, {title: "2028",}],
                                multiSelect: true,
                            },
                            {
                                label: 'Min GPA',
                                value: gpa,
                                setValue: updateGpa,
                                options: [{title: "3.9",}, {title: "3.8",}, {title: "3.7",}, {title: "3.6",}, {title: "3.5",}, {title: "3.4",}, {title: "3.3",}, {title: "3.2",}, {title: "3.1",}, {title: "3.0",}],
                            },
                            {
                                label: 'Gender',
                                value: gender,
                                setValue: (value) => updateDiversity('gender', value),
                                options: [{title: "Male",}, {title: "Female",}, {title: "Prefer Not to Specify",}],
                            },
                            {
                                label: 'Race',
                                value: race,
                                setValue: (value) => updateDiversity('race', value),
                                options: [{title: "White",}, {title: "Hispanic or Latino",}, {title: "Black or African American",}, {title: "American Indian or Native American",},  {title: "Asian or Pacific Islander",}, {title: "Other",}, {title: "Prefer Not to Specify",}],
                            },
                        ] : []}
                        checkboxes={ type !== 'smart' ? [
                            {
                                label: 'Veteran',
                                active: veteran,
                                onClick: () => updateDiversity("veteran", !veteran)
                            },
                            {
                                label: 'LGBTQ',
                                active: lgbtq,
                                onClick: () => updateDiversity("lgbtq", !lgbtq)
                            },
                            {
                                label: 'Disability',
                                active: disabled,
                                onClick: () => updateDiversity("disabled", !disabled)
                            },
                            {
                                label: 'Authorized to work',
                                active: authorized,
                                onClick: () => updateDiversity("authorized", !authorized)
                            },
                        ] : []}
                    />
                  
                )}

                <div className='flex flex-col flex-1 overflow-y-scroll'>
                    {type === "club" ?
                        <div>
                            {/* <div>
                                {type === "club" &&
                                    <div className='bg-primary/10 rounded-2xl p-3 flex flex-row items-center mb-2'>
                                        <div className='flex flex-row items-center gap-2 text-primary mr-a'>
                                            <SvgShare/>
                                            <p className='font-bold'>
                                                Post to this clubs list
                                                ({list[`clubs`].length} {'club'}{list[`clubs`].length !== 1 && "s"}):
                                            </p>
                                        </div>
                                        <div className='flex flex-row items-center gap-2'>
                                            <Button variant={'default'}
                                                    icon={SvgPlus}
                                                    onClick={() => {
                                                        window.analytics.track("Recruiter Create New Job Clicked");
                                                        toggleCreateJobModal(list.clubs);
                                                    }}>
                                                New Job
                                            </Button>
                                            <Button
                                                variant={'default'}
                                                icon={SvgPlus}
                                                onClick={() => {
                                                    window.analytics.track("Recruiter Create New Event Clicked");
                                                    toggleCreateEventModal(list.clubs);
                                                }}>
                                                New Event
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </div> */}
                            {type === "club" && list.clubs?.length === 0 ?
                                <div className='flex flex-col items-center py-12 gap-3'>
                                    <p className='text-slate-500'>
                                        No clubs added yet
                                    </p>
                                    <Button onClick={() => setIsEditClubListModalOpen(true)}>
                                        Add clubs
                                    </Button>
                                </div>
                                : list.clubs?.map(item =>
                                    <div
                                        key={item.id}
                                        className={'p-2 cursor-pointer flex flex-row hover:bg-slate-50 rounded-xl items-center gap-3'}>
                                        <div className='flex flex-row items-center gap-5'>
                                            <ClubLogo clubId={item.id} size={64} backupUrl={item.logo_url}
                                                      className={'border bg-white border-slate-200'}/>
                                            <div className='mr-2 flex flex-col gap-2'>
                                                <p className='body1-bold m-0 text-gray-800'>
                                                    {item.name}
                                                </p>
                                                <div className='flex flex-row items-center text-neutral-400 gap-1'>
                                                    <SvgSchool className={'w-4 h-4'}/>
                                                    <p className='body2 m-0'>
                                                        {item.college}&nbsp; {!!item.founded && `• Est. ${item.founded}`}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {isEditing &&
                                            <Button
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    remove(item.id)
                                                }}
                                                size={'icon'} variant={'destructive'} icon={SvgClose}
                                                className='ml-auto'/>
                                        }
                                    </div>
                                )}
                        </div>
                        :
                        <div className='flex-1 flex flex-col'>
                            <RecruiterDashboardTable
                                columns={SAVED_LISTS_TABLE_HEADERS}
                                TableBody={
                                    <div className='flex flex-col gap-4 p-5 flex-1'>
                                        {(type === 'smart' ? list.users?.length ?? 0 : filteredUsers.length) === 0 ?
                                            <div className='flex flex-col items-center py-12 gap-3'>
                                                <p className='text-slate-500'>
                                                    No students match your search criteria
                                                </p>
                                                <Button onClick={() => setIsEditClubListModalOpen(true)}>
                                                    Add students
                                                </Button>
                                            </div>
                                            :
                                            (type === 'smart' ? list.users : filteredUsers).map(item =>
                                                <div key={item.id}>
                                                    <RecruiterFavoritesTableItem
                                                        item={item}
                                                        columns={SAVED_LISTS_TABLE_HEADERS}
                                                        remove={remove}
                                                        isEditing={isEditing}
                                                        list={list}
                                                        type={type}
                                                    />
                                                </div>
                                            )
                                        }
                                        {(!hideMoreStudents && type === "smart" && !loading && list.users?.length !== 0) &&
                                            <div className='flex flex-row items-center justify-center mr-3 py-8 mb-20 body2-bold'
                                                onClick={getNextPageInfo}>
                                                <Button loading={moreStudentsLoading} variant={'ghost'}>
                                                    Next page
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                }
                            />
                        </div>
                    }
                </div>
            </div>
            <EditListModal
                isOpen={IsEditClubListModalOpen}
                currentList={list}
                type={type}
                closeModal={() => setIsEditClubListModalOpen(false)}
                getLists={getLists}
            />
            <ConfirmationModal
                isOpen={confirmationModalOpen}
                closeModal={() => setConfirmationModalOpen(false)}
                confirmText={'Delete'}
                title={'Are you sure?'}
                onConfirm={delList}
                description={'Are you sure you want to delete this list? This action cannot be undone.'}
            />
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterSavedClubsPage);