import axios from '../axios';

export const getRecruiterEvents = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/recruiter/events", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getRecruiterRecentAttendees = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/recruiter/events/recent_attendees", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getUserCompanyEvents = async (user, user_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {user_id}
        };
        const res = await axios.get("/recruiter/events/user/company", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const createEvent = async (user, params) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    // try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/recruiter/events", {params}, config);
        return res.data;
    // } catch (err) {
    //     console.log(err);
    // }
}

export const getEvent = async (user, event_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {event_id}
        };
        const res = await axios.get("/recruiter/event", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getEventAttendees = async (user, event_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {event_id}
        };
        const res = await axios.get("/recruiter/events/attendees", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const editEvent = async (user, event_id, params_to_edit) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            updated_params: params_to_edit,
            event_id
        }
        const res = await axios.put("/recruiter/event", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const deleteEvent = async (user, event_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {event_id}
        };
        const res = await axios.delete("/recruiter/event", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}
