import React from "react";
import preview from '../../img/brand/platform_preview.png'
import SvgLogo from "../icons/SvgLogo";
import {SEEKING_STEPS} from "./onboarding-constants";
import OnboardingTestimonials from "./OnboardingTestimonials";


function OnboardingLeftPane({step}) {

    return (
        <div className='md:flex flex-col justify-between flex-1 bg-primary text-white relative hidden'>
            {step < 4 ?
                <>
                    <div className='m-60 mb-0 flex flex-col justify-between flex-1'>
                        <SvgLogo className={'w-36 h-12'}/>
                        <div className='my-auto flex flex-col gap-4'>
                            <p className='text-3xl lg:text-4xl xl:text-5xl font-black'>
                                A better way to manage your finance and consulting career journey
                            </p>
                            <p className='text-xl'>
                                Discover new jobs and contacts, manage all your applications, and track your progress to
                                your
                                next
                                role
                            </p>
                        </div>
                    </div>
                    <img src={preview} className='rounded-tl-2xl ml-60'/>
                    <div className='w-20 absolute top-0 bottom-0 right-0'
                         style={{background: 'linear-gradient(to right, #00000000, #00000020)'}}
                    />
                </>
                :
                <div className='m-60 mb-0 flex flex-col justify-between flex-1'>
                    <SvgLogo className={'w-36 h-12'}/>
                    <div className='mb-auto mt-60 flex flex-col gap-5'>
                        {SEEKING_STEPS.map((item, index) =>
                            <p key={item.label}
                               className={`${(step < item.untilStep && step >= item.startStep) ? 'text-5xl' : 'text-3xl opacity-50'} duration-500 font-black`}
                            >
                                {index + 1}. {item.label}
                            </p>
                        )}
                    </div>
                    <OnboardingTestimonials/>
                </div>
            }
        </div>
    )
}

export default OnboardingLeftPane
