import React, {Component} from "react";
import SvgLogiIcon from "../icons/SvgLogiIcon";


class RuDirectLabel extends Component {
    render() {
        return (
            <div className={`flex bg-primary/10 text-primary justify-center border border-accent/20 rounded-lg px-2 py-0.5 flex-row items-center gap-1.5 mb-1 truncate ${this.props.className}`}>
                <SvgLogiIcon className={'w-3.5 h-3.5'}/>
                <p className='text-sm font-semibold truncate'>
                    {this.props.label || "RecruitU Partner"}
                </p>
            </div>
        )
    }
}

export default RuDirectLabel
