import {Button} from "../components/atoms/Button";
import SvgMail from "../components/icons/SvgMail";
import RecruiterMessageStudentModal from "./modals/RecruiterMessageStudentModal";
import {useState} from "react";
import {withRouter} from "react-router-dom";


function MessageStudentFlow({user, users, buttonVariant, history, pocs}) {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const navigateToEmail = () => {
        history.push({
            pathname: `/email`,
            state: {
                user,
                users
            }
        })
    }
    if (pocs && users && !users.length) return null
    return (
        <div>
            <Button
                variant={buttonVariant || 'default'}
                onClick={(e) => {
                    e.stopPropagation();
                    navigateToEmail();
                }}>
                <SvgMail className={'w-4 h-4 mr-1'}/> Message {pocs && "Club Points of Contact"}
            </Button>
            <RecruiterMessageStudentModal
                isOpen={isModalOpen}
                closeModal={() => setIsModalOpen(false)}
                user={user}
                users={users}
            />
        </div>
    )
}

export default withRouter(MessageStudentFlow);

