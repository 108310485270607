import React, { useState } from "react";
import SvgNoteAdd from "../../components/icons/SvgNoteAdd";
import { Button} from "../../components/atoms/Button";
import NoteComponent from "../../components/NoteComponent";
import {SIDEBAR_CARD} from "../../utils/dummy";
import SvgNote from "../../components/icons/SvgNote";

const NotetakerWidget = ({ notes, onSaveNote, onDeleteNote }) => {
    const [note, setNote] = useState("");

    const handleNoteChange = (e) => {
        setNote(e.target.value);
    };

    const handleSaveNote = () => {
        onSaveNote(note);
        setNote("");
    };

    return (
        <div className={`${SIDEBAR_CARD}`}>
            <div className='flex flex-row justify-between items-center mb-2'>
                <p className='text-lg font-bold'>
                    Your Notes
                </p>
            </div>
            <div className='flex flex-row items-center gap-2'>
                <textarea
                    rows="1"
                    className="block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write your note here..."
                    value={note}
                    onChange={handleNoteChange}
                />
                <Button variant={'default'} disabled={note.length === 0} onClick={handleSaveNote}>
                    Save
                </Button>
            </div>
            {notes.length === 0 &&
                <div className='flex flex-row items-center text-slate-500 justify-center pt-5 gap-2'>
                        <SvgNote className={'w-4 h-4'}/>
                    <p className='body3'>
                        No notes yet...
                    </p>
                </div>
            }
            <div className='flex flex-col-reverse mt-3'>
                {notes.map((note, index) =>
                    <NoteComponent note={note} deleteNote={onDeleteNote} index={index} key={index}/>
                )}
            </div>
        </div>
    );
};

export default NotetakerWidget;
