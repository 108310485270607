import React, {Component} from "react";
import CompanyLogo from "../atoms/CompanyLogo";
import SvgDiversity from "../icons/SvgDiversity";
import {Link} from "react-router-dom";
import SvgRemove from "../icons/SvgRemove";
import {connect} from "react-redux"
import {addRecentPlacement} from "../../api/student/clubs";

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
    };
}

class ClubPageCompanyCard extends Component {
    submit = async () => {
        const placement = this.props.item || {};
        await addRecentPlacement(this.props.authUser, this.props.club.id, {company_id: placement.company_id, num_placements: placement.num_placements});
        this.props.getClubInfo();
    }

    render() {
        const placement = this.props.item || {};
        const company = placement.company || {};
        const club = this.props.club || {};
        const user = this.props.user || {};
        const adminIds = user?.admin?.map(club => club.id);
        return (
            <div className='card p-3 shadow-none mb-0 flex flex-row items-center justify-between hover-card gap-2'>
                <Link to={{
                    pathname: '/company/'+company.id,
                    state: {company_id: company.id, origin: "discover"}
                }}>
                    <div className='mb-0 flex flex-row items-center gap-2'>
                        <div className='flex flex-row items-center gap-2 flex-1'>
                            <CompanyLogo id={company.id} className={'w-16 h-16'}/>
                            <div className='flex flex-1'>
                                <p className='body1-bold'>
                                    {company.name}
                                </p>
                                <p className='body2 text-slate-500'>
                                    {company.sector}
                                </p>
                                {!!placement.num_placements &&
                                    <div className='row-ac body2-bold'>
                                        <SvgDiversity width={16} height={16}/>
                                        <p className='m-0 ml-8'>
                                            {placement.num_placements} placements
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Link>
                {adminIds?.includes(club.id) ?
                    <div className="flex flex-row items-center" onClick={this.submit}>
                        <SvgRemove className={'w-5 h-5 text-neutral-400 hover:text-red-500 cursor-pointer'}/>
                    </div>
                : null}
            </div>
        )
    }
}

export default connect(mapStateToProps)(ClubPageCompanyCard);
