import React, { PureComponent } from "react";
import SvgDiversity from "../icons/SvgDiversity";
import SvgLink from "../icons/SvgLink";
import SvgSchool from "../icons/SvgSchool";
import SvgEdit from "../icons/SvgEdit";
import EditClubModal from "../atoms/modals/EditClubModal";
import SvgAddCircle from "../icons/SvgAddCircle";
import SvgDownload from "../icons/SvgDownload";
import AddClubMemberModal from "../atoms/modals/AddClubMemberModal";
import ClubLogo from "../../components-recruiter/atoms/ClubLogo";
import MemberInviteModal from "../atoms/modals/MemberInviteModal";

class ClubPageTitleWidget extends PureComponent {
  state = {
    aboutOpen: true,
    addClubMemberModalOpen: false,
    memberInviteModalOpen: false,
  };

  toggleAddClubMemberModal = () => {
    this.setState({
      addClubMemberModalOpen: !this.state.addClubMemberModalOpen,
    });
  };

  toggleMemberInviteModal = () => {
    this.setState({ memberInviteModalOpen: !this.state.memberInviteModalOpen });
  };

  toggleAboutCollapse = () => {
    this.setState({ aboutOpen: !this.state.aboutOpen });
  };

  renderNumMembers = (num) => {
    if (num <= 25) return "1-25";
    else if (num <= 50) return "26-50";
    else if (num <= 75) return "51-75";
    else if (num <= 100) return "76-100";
    else return "100+";
  };

  render() {
    const club = this.props.club;
    const user = this.props.user || {};
    const numMembers = this.props.numMembers;
    return (
      <>
        <div className="flex flex-row items-center justify-between flex-wrap gap-3">
          <div className="row-ac">
            {this.props.loading ? (
              <div
                className="br-12 shimmer border border-neutral-200"
                style={{ width: 80, height: 80 }}
              />
            ) : (
              <ClubLogo
                clubId={club.id}
                backupUrl={club.logo_url}
                size={80}
                className={`border border-slate-200`}
              />
            )}
            <div className="ml-24">
              {this.props.loading ? (
                <div
                  className="shimmer br-4 w-80 mb-4"
                  style={{ height: 24 }}
                />
              ) : (
                <p className="title2 m-0 blacks-1">{club.name}</p>
              )}
              {this.props.loading ? (
                <div
                  className="shimmer br-4 w-80 mb-4"
                  style={{ height: 17 }}
                />
              ) : (
                <div className="body2 base-black-50 row-ac mt-8">
                  <div className="row-ac mr-24">
                    <SvgSchool className={"w-5 h-5"} />
                    <p className="m-0 ml-4">{club.college}</p>
                  </div>
                  {club.founded && (
                    <div className="row-ac mr-24">
                      <p className="m-0 ml-4">Founded {club.founded}</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="body2-bold blacks-1 row-ac gap-6 mr-12">
            <div className="row-ac">
              <SvgDiversity className={"w-5 h-5"} />
              <p className="m-0 ml-8">
                <span className={this.props.loading && "text-neutral-400"}>
                  {this.props.loading ? "-- " : numMembers}
                </span>{" "}
                member{numMembers !== 1 && "s"}
              </p>
            </div>
            <div
              onClick={this.toggleMemberInviteModal}
              className="row-ac bg-primary-light px-4 cursor-pointer py-1 rounded-full primary"
            >
              <SvgAddCircle className={"w-5 h-5"} />
              <button className="m-0 ml-8">Invite Members</button>
            </div>
          </div>
          <MemberInviteModal
            isOpen={this.state.memberInviteModalOpen}
            closeModal={this.toggleMemberInviteModal}
            club={club}
            adminIds={[]}
            toggleAddClubMemberModal={this.toggleAddClubMemberModal}
          />
          <AddClubMemberModal
            isOpen={this.state.addClubMemberModalOpen}
            closeModal={this.toggleAddClubMemberModal}
            club={club}
            getClubInfo={this.props.getClubInfo}
          />
        </div>
      </>
    );
  }
}

export default ClubPageTitleWidget;
