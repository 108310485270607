import axios from '../axios';

export const getQuestion = async (user, question_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {question_id}
        };
        const res = await axios.get("/questions", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}



export const getAllQuestions = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/questions/all", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}
