import React, {PureComponent} from "react";
import SvgMagic from "../icons/SvgMagic";
import SvgDocument from "../icons/SvgDocument";
import SvgSeeking from "../icons/SvgSeeking";
import SvgLinkedIn from "../icons/SvgLinkedIn";
import SvgEdit from "../icons/SvgEdit";

class ProfilePageAboutWidget extends PureComponent {

    render() {
        const user = this.props.user || {};
        return (
            <div className='card flex flex-col gap-3'>
                {user.bio ?
                    <div className='flex flex-col gap-2'>
                        <h3 className='mt-0 title2 text-neutral-900'>
                            About
                        </h3>
                        <p className='body2 base-black-70'>
                            {user.bio}
                        </p>
                    </div>
                    : null}
                <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-row items-center'>
                        <a className='flex flex-row items-center mr-5' href={user.linkedin} target={'_blank'}
                           rel={'noreferrer'}>
                            <SvgLinkedIn className={'h-5 w-5'}/>
                            <p className='body2-bold ml-1'>
                                LinkedIn
                            </p>
                        </a>
                        {(!this.props.recruiter && this.props.studentProfilePage && !this.props.clubAdmin) || !user.resume ? null :
                            <a className='flex flex-row items-center mr-5' href={user.resume} target={'_blank'}
                               rel={'noreferrer'}>
                                <div className='flex flex-row items-center mr-5'>
                                    <SvgDocument className={'h-5 w-5'}/>
                                    <p className='body2-bold ml-1'>
                                        Resume
                                    </p>
                                </div>
                            </a>
                            }
                        {user.seeking_role &&
                            <div className='flex flex-row items-center mr-5 primary'>
                                <SvgSeeking className={'h-5 w-5'}/>
                                <p className='body2-bold ml-1'>
                                    Currently seeking role
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfilePageAboutWidget
