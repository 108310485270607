import React, {Component} from "react";
import {page_h_padding_class} from "../../utils/constants";
import SvgPeople from "../../components/icons/SvgPeopleFeather"
import WrappedTextInput from "../../components/atoms/WrappedTextInput";
import SelectComponent from "../../components/atoms/SelectComponent";
import SvgCheck from "../../components/icons/SvgCheck";
import { filteredCollegeSearch, searchClubs, searchCompanies } from "../../utils/typesense";
import MultiSelectComponent from "../../components/atoms/MultiSelectComponent";
import SvgSearchFeather from "../../components/icons/SvgSearchFeather";
import SvgSchool from "../../components/icons/SvgSchool";
import CollapseComponent from "../../components/atoms/CollapseComponent";
import FadeInOnScroll from "../../components/atoms/FadeInOnScroll";
import {Button} from "../../components/atoms/Button";
import SvgClose from "../../components/icons/SvgClose";

const initialState = {
    openSchoolCollapse: false,
    schoolSearchResults: [],
    schoolSearch: "",
    clubSearch: "",
    clubOptions: [],
    currentCompanySearch: "",
    currentCompanyOptions: [],
    previousCompanySearch: "",
    previousCompanyOptions: []
}

class SearchHeaderWrapper extends Component {
    state = initialState
    

    handleSchoolSearchChange = (event) => {
        const text = event.target.value;
        if (!text) {
            this.setSchool(null)
        }
        this.setState({schoolSearch: text, openSchoolCollapse: true});
        this.schoolSearchChanged(text);
    }

    schoolSearchChanged = async (text) => {
        const schoolSearchResults = await filteredCollegeSearch(text);
        this.setState({schoolSearchResults})
    }

    handleClubSearchChange = async (event) => {
        const text = event.target.value;
        this.setState({clubSearch: text});

        if (!text) {
            this.setClub({name: "", id: null});
            return
        }
        const clubs = await searchClubs(text, 1, 5);
        this.setState({clubOptions: clubs});
    }

    setSchool = (result) => {
        this.setState({
            schoolSearchResults: [],
            schoolSearch: result
        })
        this.props.setSchool(result);
    }

    setClub = (result) => {
        this.setState({
            clubOptions: [],
            clubSearch: result.name
        })
        this.props.setClub(result.id);
    }

    handleCurrentCompanySearchChange = async (event) => {
        const text = event.target.value;
        this.setState({currentCompanySearch: text});

        if (!text) {
            this.setCurrentCompany({name: "", id: null});
            return;
        }
        const companies = await searchCompanies(text);
        this.setState({currentCompanyOptions: companies});
    }

    handlePreviousCompanySearchChange = async (event) => {
        const text = event.target.value;
        this.setState({previousCompanySearch: text});

        if (!text) {
            this.setPreviousCompany({name: "", id: null});
            return;
        }
        const companies = await searchCompanies(text);
        this.setState({previousCompanyOptions: companies});
    }

    setCurrentCompany = (result) => {
        this.setState({
            currentCompanyOptions: [],
            currentCompanySearch: result.name
        })
        this.props.setCurrentCompany(result.id);
    }

    setPreviousCompany = (result) => {
        this.setState({
            previousCompanyOptions: [],
            previousCompanySearch: result.name
        })
        this.props.setPreviousCompany(result.id);
    }

    handleClearFilters = () => {
        this.setState(initialState)
        this.props.clearFilters();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.schoolSearch !== prevProps.schoolSearch && this.props.schoolSearch) {
            this.setState({schoolSearch: this.props.schoolSearch, openSchoolCollapse: false})
        }
    }

    render() {
        return (
            <div
                className={`min-h-24 flex flex-row items-center justify-between ${this.props.className}`}>
                <div className='flex flex-col gap-0 flex-1'>
                    {this.props.nav}
                    <div className='flex flex-row items-center gap-1 text-gray-400'>
                        <p className='m-0 body2-bold font-semibold'>
                            {this.props.sub || ''}
                        </p>
                    </div>
                    <div className='flex flex-row items-center justify-between'>
                        {this.props.title &&
                            <h1 className={`text-white heading4 font-semibold capitalize`}>
                                <p>{this.props.title}</p>
                            </h1>
                        }
                        {this.props.actionButtonUpper}
                    </div>
                    {this.props.titleSecondary &&
                        <h1 className={`text-white body1-bold font-semibold`}>
                            <p>{this.props.titleSecondary}</p>
                        </h1>
                    }
                    <div className='mt-4 flex flex-row items-center justify-between w-full'>
                        <div className='flex flex-row items-center gap-2 flex-wrap'>
                            {this.props.search &&
                                <WrappedTextInput
                                    type="text"
                                    placeholder={this.props.search.placeholder}
                                    className='pl-44 input-light body2 w-[240px] lg:w-[240px]'
                                    value={this.props.search.value}
                                    onChange={this.props.search.onChange}
                                    icon={<SvgSearchFeather width={20} height={20} className={'absolute text-slate-500'}
                                                     style={{left: 12, top: 10}}/>}
                                />
                            }
                            {!!this.props.setSchool &&
                                <div className='flex flex-row items-center gap-2'>
                                    <div className='relative'>
                                        <WrappedTextInput
                                            type="text"
                                            placeholder={'Search by school...'}
                                            className='pl-44 input-light px-3 body2 w-full'
                                            value={this.state.schoolSearch}
                                            onChange={this.handleSchoolSearchChange}
                                            icon={<SvgSchool width={20} height={20}
                                                                  className={'absolute text-slate-500'}
                                                                  style={{left: 12, top: 10}}/>}
                                        />
                                        <div className='absolute top-12 left-0 right-0 z-[99]'>
                                            <CollapseComponent
                                                isOpen={this.state.schoolSearch.length !== 0 && this.state.openSchoolCollapse}
                                                className='bg-white shadow-lg border border-neutral-200 rounded-lg'>
                                                {this.state.schoolSearchResults.map((res) =>
                                                    <div
                                                        onClick={() => {
                                                            this.props.setSchool(res.name);
                                                            this.setState({
                                                                schoolSearch: res.name,
                                                                openSchoolCollapse: false
                                                            })
                                                        }}
                                                        className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2'>
                                                        <div className='flex flex-col'>
                                                            <p className='text-sm text-slate-800 line-clamp-2'>
                                                                {res.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </CollapseComponent>
                                        </div>
                                    </div>
                                    {this.props.clubs !== undefined && this.props.clubs.length > 0 && (
                                        <MultiSelectComponent
                                            light
                                            label="Clubs"
                                            value={this.props.selectedClubs}
                                            scrollable={true}
                                            setValue={this.props.handleClubChange}
                                            clearFilter={() => this.props.handleClubChange(null)}
                                            options={this.props.clubs.map(club => ({ title: club.name }))}
                                        />
                                    )}
                                </div>
                            }
                            {!!this.props.setClub &&
                                <div className='relative'>
                                    <WrappedTextInput
                                        type="text"
                                        placeholder={'Search by club...'}
                                        className='pl-44 input-light flex-1 px-3 body2 w-full'
                                        value={this.state.clubSearch}
                                        onChange={this.handleClubSearchChange}
                                        icon={
                                            <SvgPeople
                                                width={24}
                                                height={24}
                                                className="absolute text-slate-500"
                                                style={{ left: 12, top: 9 }}
                                            />
                                        }
                                    />
                                    <div className='absolute left-0 right-0 z-10 top-10'>
                                        <CollapseComponent className={'bg-white rounded-lg '}
                                                        isOpen={this.state.clubSearch.length !== 0}>
                                            {this.state.clubOptions.map((res) =>
                                                <FadeInOnScroll triggerImmediately delay={10} key={res.id}>
                                                    <div
                                                        onClick={() => this.setClub(res)}
                                                        className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2'>
                                                        <img
                                                            className='h-[40px] aspect-square border border-slate-200 bg-white rounded-lg object-contain'
                                                            src={res.logo_url}
                                                        />
                                                        <div className='flex flex-col'>
                                                            <p className='body1-bold text-slate-800'>
                                                                {res.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </FadeInOnScroll>
                                            )}
                                        </CollapseComponent>
                                    </div>
                                </div>
                            }
                            {!!this.props.setCurrentCompany &&
                                <div className='relative'>
                                    <WrappedTextInput
                                        type="text"
                                        placeholder={'Search by current company...'}
                                        className='pl-44 input-light flex-1 px-3 body2 w-full'
                                        value={this.state.currentCompanySearch}
                                        onChange={this.handleCurrentCompanySearchChange}
                                        icon={
                                            <SvgSearchFeather
                                                width={20}
                                                height={20}
                                                className="absolute text-slate-500"
                                                style={{ left: 12, top: 10 }}
                                            />
                                        }
                                    />
                                    <div className='absolute left-0 right-0 z-10 top-10'>
                                        <CollapseComponent 
                                            className={'bg-white rounded-lg'}
                                            isOpen={this.state.currentCompanySearch.length !== 0}
                                        >
                                            {this.state.currentCompanyOptions.map((res) =>
                                                <FadeInOnScroll triggerImmediately delay={10} key={res.id}>
                                                    <div
                                                        onClick={() => this.setCurrentCompany(res)}
                                                        className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2'
                                                    >
                                                        <img
                                                            className='h-[40px] aspect-square border border-slate-200 bg-white rounded-lg object-contain'
                                                            src={res.logo_url}
                                                        />
                                                        <div className='flex flex-col'>
                                                            <p className='body1-bold text-slate-800'>
                                                                {res.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </FadeInOnScroll>
                                            )}
                                        </CollapseComponent>
                                    </div>
                                </div>
                            }

                            {!!this.props.setPreviousCompany &&
                                <div className='relative'>
                                    <WrappedTextInput
                                        type="text"
                                        placeholder={'Search by previous company...'}
                                        className='pl-44 input-light flex-1 px-3 body2 w-full'
                                        value={this.state.previousCompanySearch}
                                        onChange={this.handlePreviousCompanySearchChange}
                                        icon={
                                            <SvgSearchFeather
                                                width={20}
                                                height={20}
                                                className="absolute text-slate-500"
                                                style={{ left: 12, top: 10 }}
                                            />
                                        }
                                    />
                                    <div className='absolute left-0 right-0 z-10 top-10'>
                                        <CollapseComponent 
                                            className={'bg-white rounded-lg'}
                                            isOpen={this.state.previousCompanySearch.length !== 0}
                                        >
                                            {this.state.previousCompanyOptions.map((res) =>
                                                <FadeInOnScroll triggerImmediately delay={10} key={res.id}>
                                                    <div
                                                        onClick={() => this.setPreviousCompany(res)}
                                                        className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2'
                                                    >
                                                        <img
                                                            className='h-[40px] aspect-square border border-slate-200 bg-white rounded-lg object-contain'
                                                            src={res.logo_url}
                                                        />
                                                        <div className='flex flex-col'>
                                                            <p className='body1-bold text-slate-800'>
                                                                {res.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </FadeInOnScroll>
                                            )}
                                        </CollapseComponent>
                                    </div>
                                </div>
                            }
                            {this.props.selectStatus &&
                                <SelectComponent
                                    light
                                    label={this.props.selectStatus.label}
                                    value={this.props.selectStatus.value}
                                    scrollable={true}
                                    setValue={this.props.selectStatus.setValue}
                                    clearFilter={() => this.props.selectStatus.setValue(null)}
                                    options={this.props.selectStatus.options}
                                />
                            }
                            {this.props.selects && this.props.selects.map(select => {
                                if (!select.multiSelect) {
                                    return (
                                        <SelectComponent
                                            label={select.label}
                                            light
                                            value={select.value}
                                            scrollable={true}
                                            setValue={select.setValue}
                                            clearFilter={() => !!select.field ? select.setValue(select.field, null) : select.setValue(null)}
                                            options={select.options}
                                            stateName={select.field}
                                        />
                                    )
                                } else return (
                                    <MultiSelectComponent
                                        label={select.label}
                                        light
                                        value={select.value}
                                        scrollable={true}
                                        setValue={select.setValue}
                                        clearFilter={() => select.setValue(null)}
                                        options={select.options}
                                    />
                                )
                            })}
                            {this.props.checkboxes &&
                                this.props.checkboxes.map(checkbox => {
                                    return (
                                        <button onClick={checkbox.onClick}
                                                className={`flex flex-row items-center px-3 base-white-100`}
                                        >
                                            <div
                                                className={`${checkbox.active ? 'checkbox-active' : 'checkbox border-slate-400'} mr-12`}>
                                                {checkbox.active &&
                                                    <SvgCheck className={'base-white-100'}/>
                                                }
                                            </div>
                                            <p className='body2 text-slate-500'>
                                                {checkbox.label}
                                            </p>
                                        </button>
                                    )
                                }
                                    
                                )
                                
                            }
                            {this.props.clearFilters && (
                                <Button
                                    variant={'secondary'}
                                    onClick={this.handleClearFilters}
                                    icon={SvgClose}
                                    iconPosition={'left'}
                                    size={'sm'}
                                >
                                    Clear
                                </Button>
                            )}
                        </div>
                        <div className='flex flex-row'>
                            {this.props.actionButton}
                        </div>
                        {this.props.placeChildrenRight && this.props.children}
                    </div>
                    {this.props.placeChildrenRight ? null : this.props.children}
                </div>
            </div>
        )
    }
}

export default SearchHeaderWrapper
