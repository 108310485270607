import React from "react";

export default function AuthFormTitle({ currentTab, recruiter, invite, renderName }) {
    return (
        <div className="flex flex-col items-center">
            <div className="text-2xl  w-[300px] text-center font-bold mb-1">
                Welcome {currentTab === "login" ? "back" : "to " + renderName()}
                !
            </div>
            <p className="text-sm text-slate-500 mb-6 w-[300px] text-center">
                {currentTab === "login" ? (
                    "Enter your details to access your account."
                ) : !recruiter ? (
                    "Enter your details to create your account."
                ) : invite === "blk" ? (
                    <div className="flex flex-col items-center">
                        <p>
                            You have been invited by BLK Capital Management to
                            create your account.
                        </p>
                        <img
                            alt={"logo"}
                            style={{ height: 32 }}
                            className="object-contain mt-2"
                            src={require("../../img/brand/blk-logo-navy.png")}
                        />
                    </div>
                ) : (
                    "You have been invited to create your account."
                )}
            </p>
        </div>
    );
}
