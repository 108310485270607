import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {connect} from "react-redux";
import {Button} from "../../components/atoms/Button";


class ConfirmationModal extends Component {

    save = async () => {
        this.props.save()
        this.props.closeModal();
    }

    render() {
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                headerToggle
                size={'sm'}
                toggle={this.props.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={this.props.closeModal} variant='secondary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={this.props.disabled}
                            onClick={this.props.onConfirm}>
                            {this.props.confirmText || 'Confirm'}
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
            >
                <p className='text-2xl mb-3 font-bold text-slate-900'>
                    {this.props.title}
                </p>
                <p className='text-lg text-slate-700 mb-5'>
                    {this.props.description}
                </p>
            </ModalComponent>
        )
    }
}

export default ConfirmationModal;
