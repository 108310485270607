import React, {Component} from "react";
import RecruiterDashboardTable from "./RecruiterDashboardTable";
import {
    MAJOR_OPTIONS,
    FAVORITES_TABLE_HEADERS
} from "../utils/dummy";
import SelectComponent from "../components/atoms/SelectComponent";
import WrappedTextInput from "../components/atoms/WrappedTextInput";
import SvgSearch from "../components/icons/SvgSearch";
import {connect} from "react-redux";
import { Button } from "../components/atoms/Button";
import { getRecruiterJobs } from "../api/recruiter/jobs";
import { getRecruiterRecentAttendees } from "../api/recruiter/events";
import RecruiterFavoritesTableItem from "./RecruiterFavoritesTableItem";
import Spinner from "../components/Spinner";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        candidates: state.userReducer.candidates
    };
}

class RecruiterEnrichPage extends Component {
    state = {
        job: {},
        year: "",
        major: "",
        loading: false,
        applicants: [],
        attendees: []
    }

    componentDidMount = () => {
        window.analytics.page("Recruiter Favorites Page");
        this.refreshStudents();
    }

    refreshStudents = async () => {
        this.setState({loading: true})
        const result = await getRecruiterJobs(this.props.authUser);
        const recentAttendees = await getRecruiterRecentAttendees(this.props.authUser);
        this.setState({loading:false, attendees: recentAttendees.users, applicants: result.recent_applicants});
    }

    handleSearchChange = (e) => {
        const search = e.target.value;
        this.setState({search})
    };

    handleYearChange = (year) => {
        if (year === this.state.year) {
            year = ""
        }
        this.setState({year});
    }

    handleMajorChange = (major) => {
        if (major === this.state.major) {
            major = ""
        }
        this.setState({major});
    }

    downloadCsv = () => {
        const candidates = this.state.applicants.concat(this.state.attendees);
        const csv = candidates.map(row => `${row.first_name} ${row.last_name},${row.email},${row.grade},${row.college}`).join('\n');

        // Create a Blob object from the CSV data
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        // Create a link element and click it programmatically to trigger download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'candidates.csv');
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      };

    render() {
        const candidates = this.state.applicants.concat(this.state.attendees);
        const allCandidates = candidates.filter(user => {
            let nameMatch = true;
            const {search, major, year} = this.state;
            if (!!search) {
                const fullName = user.first_name + " " + user.last_name;
                const lowerCaseName = fullName.toLowerCase();
                nameMatch = lowerCaseName.startsWith(search.toLowerCase());
            }

            let majorMatch = true;
            if (!!major) {
                majorMatch = user.major.includes(major)
            }
            let yearMatch = true;
            if (!!year) {
                yearMatch = user.grade === year
            }
            return nameMatch && majorMatch && yearMatch
        });
        return (
            <div className={`flex flex-col flex-1 p-5 gap-8`}>
                <div className="">
                    <div className="flex flex-row justify-ends mb-4">
                        <div className={'flex flex-col gap-4 flex-1'}>
                            <div className='flex flex-row items-center gap-2'>
                                <p className='text-2xl font-semibold text-slate-800'>
                                    Enrich
                                </p>
                                <div
                                    className='text-primary whitespace-nowrap text-sm font-semibold px-2 mt-0 rounded-full bg-primary/10'>
                                    {allCandidates.length}
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-3'>
                                <div>
                                    <WrappedTextInput
                                        type="text"
                                        outerLabel={'Search'}
                                        placeholder={'Search candidates...'}
                                        className='pl-44 input-light px-3 body2 w-full'
                                        value={this.state.search}
                                        onChange={this.handleSearchChange}
                                        icon={<SvgSearch width={24} height={24}
                                                        className={'absolute text-slate-500'}
                                                        style={{left: 12, top: 10}}/>}
                                    />
                                </div>
                                <SelectComponent
                                    outerLabel={'Major'}
                                    label={'All'}
                                    value={this.state.major}
                                    light
                                    setValue={this.handleMajorChange}
                                    clearFilter={() => this.handleMajorChange(null)}
                                    options={MAJOR_OPTIONS}
                                />
                                <SelectComponent
                                    outerLabel={'Grad Year'}
                                    label={'All'}
                                    value={this.state.year}
                                    light
                                    setValue={this.handleYearChange}
                                    clearFilter={() => this.handleYearChange(null)}
                                    options={[{title: "2024",}, {title: "2025",}, {title: "2026",}, {title: "2027",}]}
                                />
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row items-center gap-3'>
                                <Button variant={'secondary'} onClick={this.downloadCsv}>
                                    Download CSV
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='flex-1 flex flex-col'>
                        <RecruiterDashboardTable
                            columns={FAVORITES_TABLE_HEADERS}
                            TableBody={
                                this.state.loading ?
                                    <div className='flex flex-col items-center justify-center flex-1 mb-[15vh] mt-[15vh]'>
                                        <Spinner size={24}/>
                                    </div>
                                    :
                                    <div className='flex flex-col gap-4 p-5 flex-1'>
                                        {allCandidates.map(item =>
                                            <RecruiterFavoritesTableItem
                                                item={item}
                                                columns={FAVORITES_TABLE_HEADERS}
                                            />
                                        )}
                                    </div>
                            }
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(RecruiterEnrichPage);
