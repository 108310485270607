import React, {PureComponent} from "react";
import SvgSearch from "../icons/SvgSearch";
import WrappedTextInput from "../atoms/WrappedTextInput";
import CollapseComponent from "../atoms/CollapseComponent";
import FadeInOnScroll from "../atoms/FadeInOnScroll";
import CompanyLogo from "../atoms/CompanyLogo";
import SvgBriefcase from "../../components/icons/SvgBriefcase"
import SvgCalendar from "../icons/SvgCalendar";
import AnimatedTabs from "../atoms/AnimatedTabs";
import {Button} from "../atoms/Button";


class AddJobEvent extends PureComponent {
    state = {
        search: '',
        company: "",
    }

    handleSearchChange = (event) => {
        const text = event.target.value;
        this.setState({search: text});

        this.props.searchChanged(text, this.props.type);
    }

    render() {
        const searchResults = this.props.searchResults || [];
        return (
            <div className="col-plain w-full gap-3">
                <div className='flex flex-col gap-1'>
                    <p className="body3-bold text-neutral-400 mb-1">
                        {this.props.type === "job" ? "Job" : "Event"}
                    </p>
                    <WrappedTextInput
                        type="text"
                        placeholder={`Find a ${this.props.type}...`}
                        className='pl-44 input-light px-3 body2 w-full mb-3'
                        value={this.state.search}
                        onChange={this.handleSearchChange}
                        icon={<SvgSearch width={24} height={24}
                                         className={'absolute text-slate-500'}
                                         style={{left: 12, top: 10}}/>}
                    />
                </div>
                <div className='min-h-[30vh]'>
                    <CollapseComponent isOpen={this.state.search.length === 0 && !searchResults.length}>
                        <div className='flex flex-col items-center justify-center py-12'>
                            <p className='text-slate-500 body2'>
                                Search to find a {this.props.type}
                            </p>
                        </div>
                    </CollapseComponent>
                    <CollapseComponent isOpen={this.state.search.length !== 0 || searchResults.length}>
                        {searchResults.map((res) =>
                            <FadeInOnScroll triggerImmediately delay={10} key={res.id}>
                                <div
                                    onClick={() => this.props.setJobEvent(res)}
                                    className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2'>
                                    <CompanyLogo id={res.company_id} className={'w-16 h-16'}/>
                                    <div className='flex flex-col'>
                                        <p className='body1-bold text-slate-800'>
                                            {res.name}
                                        </p>
                                    </div>
                                </div>
                            </FadeInOnScroll>
                        )}
                    </CollapseComponent>
                        {/* <div className='flex flex-row text-sm text-slate-500 items-center justify-center gap-2'>
                            <p>
                                {this.props.type === "job" ? "Not looking for a job from our job board?" : "Not looking for an event from our listings?"}
                            </p>
                            <Button
                                variant={'secondary'}
                                onClick={this.openCreateModal}
                                className='text-primary cursor-pointer'
                            >
                                Add a{this.props.type !== "job" && 'n'} {this.props.type} manually
                            </Button>
                        </div> */}
                </div>
            </div>
        )
    }
}

export default AddJobEvent
