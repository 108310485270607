import React, {Component} from "react";
import Calendar from "../calendar/Calendar";
import PageHeaderWrapper from "../wrappers/PageHeaderWrapper";
import AnimatedTabs from "../atoms/AnimatedTabs";
import PageContentWrapper from "../wrappers/PageContentWrapper";
import PageLTRWrapper from "../wrappers/PageLTRWrapper";
import {connect} from "react-redux";
import SvgCalendar from "../icons/SvgCalendar";
import SvgList from "../icons/SvgList";
import {getUserEvents} from "../../api/student/events";
import CompanyEventItem from "../jobboard/CompanyEventItem";
import CalendarSuggestedJobsSidebar from "../calendar/CalendarSuggestedJobsSidebar";
import EventPeek from "../discover/EventPeek";
import SectionTabs from "../atoms/SectionTabs";
import {Button} from "../atoms/Button";
import {Link} from "react-router-dom";
import TrackedEventItem from "../crm/TrackedEventItem";
import TableHeader from "../atoms/TableHeader";
import {CRM_TABLE_HEADERS, EVENTS_TABLE_HEADERS, EVENTS_TRACKER_TABLE_HEADERS} from "../../utils/dummy";
import SvgEmpty from "../icons/SvgEmpty";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        trackers: state.userReducer.trackers,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateTracker: (tracker) => {
            dispatch({
                type: "UPDATE_TRACKER",
                tracker,
            })
        }
    }
}

class CalendarPage extends Component {
    state = {
        events: [],
        activeTab: 'List View',
        searchEventsOpen: false,
        selectedEvent: {},
        listingPreviewOpen: false,

    }

    userEvents = async () => {
        const res = await getUserEvents(this.props.authUser);
        this.setState({events: res.events});
    }

    componentDidMount = () => {
        this.userEvents();
    }


    changeTab = (tab) => {
        this.setState({
            activeTab: tab
        })
    }

    openEventPage = (selectedEvent) => {
        this.props.history.push(`/event/${selectedEvent.id}`);
    }

    openListingPreview = (listing) => {
        this.setState({listingPreviewOpen: true, selectedEvent: listing})
    }

    closeListingPreview = () => {
        this.setState({listingPreviewOpen: false})
    }

    render() {
        const trackers = Object.values(this.props.trackers) || [];
        return (
            <PageContentWrapper>
                <div className={'bg-white px-5 pb-5 mt-2 overflow-y-scroll'}>
                    <TableHeader
                        headers={EVENTS_TRACKER_TABLE_HEADERS}
                        selectedSort={null}
                        changeSort={() => null}
                    />
                    <div
                        className={`flex flex-col overflow-y-scroll w-full`}>
                        <div className='hidden only:flex my-8 mt-[140px] text-neutral-400 flex-col items-center justify-center flex-1'>
                            <SvgEmpty/>
                            <p className='text-base mt-2 mb-6'>
                                No events saved...
                            </p>
                            <Button
                                onClick={() => this.props.changeTab(`Search`)}>
                                Find events
                            </Button>
                        </div>
                        {trackers.map((item, index) => {
                            if (!!item.event_id) {
                                return (
                                    <TrackedEventItem key={item.id} item={item.event} list={!this.state.grid}
                                                      openEventPage={this.openListingPreview}
                                                      tracker={this.props.trackers[item.event.id]}
                                                      updateTracker={this.props.updateTracker}
                                                      authUser={this.props.authUser}
                                    />
                                )
                            }
                        })}
                    </div>
                </div>
                <EventPeek listing={this.state.selectedEvent}
                           open={this.state.listingPreviewOpen}
                           close={this.closeListingPreview}
                           authUser={this.props.authUser} event={this.state.selectedEvent}
                           tracker={this.props.trackers[this.state.selectedEvent.id]}
                           updateTracker={this.props.updateTracker}/>
            </PageContentWrapper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage);
