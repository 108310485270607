import axios from "../axios";

export const getClub = async (user, club_id) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
      params: { club_id },
    };
    const res = await axios.get("/clubs", config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const editClub = async (user, club_id, params_to_edit) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const body = {
      updated_params: params_to_edit,
      club_id,
    };
    const res = await axios.put("/clubs", body, config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getClubAdminInfo = async (user, club_id) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
      params: { club_id },
    };
    const res = await axios.get("/clubs/admin", config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};


export const getClubRequestsInfo = async (user, club_id) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
      params: { club_id },
    };
    const res = await axios.get("/clubs/requests", config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const createNewAdmin = async (user, club_id, admin_email) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const res = await axios.post(
      "/clubs/admin",
      { club_id, admin_email },
      config
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getClubInfo = async (club_id) => {
  try {
    const config = {
      headers: { mode: "no-cors", "Access-Control-Allow-Origin": "*" },
      params: { club_id },
    };
    const res = await axios.get("/clubs/unauthed", config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};


export const approveRejectClubMemberRequest = async (user, club_id, member_id, decision) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const body = {
      member_id,
      decision,
      club_id,
    };
    const res = await axios.put("/admin/club/request", body, config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};


export const getAllClubMembers = async (user, club_id, status, year) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
      params: { club_id, status, year },
    };
    const res = await axios.get("/clubs/members/all", config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};