import React, {PureComponent} from "react";
import SvgLinkedIn from "../components/icons/SvgLinkedIn";
import SvgRemove from "../components/icons/SvgRemove";
import DropdownComponent from "../components/atoms/DropdownComponent";
import SvgMoreVertical from "../components/icons/SvgMoreVertical";
import SvgEdit from "../components/icons/SvgEdit";
import {connect} from "react-redux";
import TagComponent from "../components/atoms/TagComponent";
import { approveRejectClubMemberRequest } from "../api/clubAdmin/clubs";


function mapDispatchToProps(dispatch) {
    return {
        toggleStudentPeek: () => {
            dispatch({
                type: "TOGGLE_STUDENT_PEEK",
            })
        },
        setStudentPeekStudent: (user) => {
            dispatch({
                type: "SET_STUDENT_PEEK_STUDENT",
                user,
            })
        },
    }
}

class ClubStudentRequestListItem extends PureComponent {
    state = {
        hovering: false,
    }

    onMouseEnter = () => {
        this.setState({hovering: true});
    }

    onMouseLeave = () => {
        this.setState({hovering: false});
    }

    removeMember = async () => {
        await approveRejectClubMemberRequest(this.props.authUser, this.props.club.id, this.props.item.id, false);
        this.props.refresh();
    }

    approveMember = async () => {
        await approveRejectClubMemberRequest(this.props.authUser, this.props.club.id, this.props.item.id, true);
        this.props.refresh();
    }

    openStudentPeek = () => {
        this.props.toggleStudentPeek();
        this.props.setStudentPeekStudent(this.props.item);
    }


    render() {
        const member = this.props.item;
        const profilePic = member.profile_pic_url ? member.profile_pic_url : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fblank-person.jpeg?alt=media&token=ac3b0019-71c8-4830-932e-de437faf5fc8";
        const TAGS = {
            "Actively looking for a job": {title: "Searching", type: "rank"},
            "Open to opportunities": {title: "Open", type: "industry"},
            "Not looking right now": {title: "Closed", type: "random"}
        }
        return (
            <div className={`row-ac-jb py-4 border-b border-b-neutral-100 last:border-none`}
                 onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                <div className='flex flex-row items-center gap-1' style={{flex: .30}}>
                    <img className='bg-base-white-20 br-32 border border-neutral-200' alt={"profile pic"}
                        onError={() => this.setState({profileError: true})}
                        style={{width: 28, height: 28}} src={!this.state.profileError ? profilePic : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fblank-person.jpeg?alt=media&token=ac3b0019-71c8-4830-932e-de437faf5fc8"}/>
                    <div className='flex flex-row items-center flex-wrap'>
                        <p className='body2-bold base-black-70'>
                            {member.first_name} {member.last_name}
                        </p>
                        {member.linkedin &&
                            <a href={member.linkedin}
                               target={"_blank"}>
                                <SvgLinkedIn className={'base-black-30 h-base-black-70 pointer w-4 h-4'}/>
                            </a>
                        }
                       
                    </div>
                </div>
                <div className='row-ac primary 2xl:block' style={{flex: .25}}>
                    <p className={`body4 ${!member.claimed ? 'base-black-50' : 'base-black-100'}`}>
                        {member.email}
                    </p>
                </div>
                <div style={{flex: .15}}>
                    <p className={`body3 ${!member.claimed ? 'base-black-50' : 'base-black-100'}`}>
                        {member.grade || '--'}
                    </p>
                </div>
                <div className='row-ac primary hidden lg:block' style={{flex: .3}}>
                    <p className={`body4 ${!member.claimed ? 'base-black-50' : 'base-black-100'}`}>
                        {member.major.join(", ") || '--'}
                    </p>
                </div>
                <div className='row-ac primary hidden lg:block ml-2' style={{flex: .1}}>
                    <p className={`body3 base-black-100`}>
                         {member.gpa || '--'}
                    </p>
                </div>
                <div className='row-ac primary hidden lg:block ml-2' style={{flex: .15}}>
                    <div className="flex items-center">
                        {!!member.job_status ?
                            <TagComponent
                                label={TAGS[member.job_status].title}
                                type={TAGS[member.job_status].type}
                            />
                            :
                            <p>--</p>
                        }
                    </div>
                </div>
                <div className={'flex flex-[0.15] flex-row gap-2 justify-end'}>
                    <div className={`px-2 py-1.5 rounded-lg bg-primary-light primary body3-bold pointer`} onClick={this.openStudentPeek}>
                        View
                    </div>
                    <DropdownComponent
                        ToggleComponent={
                            <div
                                className='flex flex-col items-center mt-[8px] justify-center text-slate-500 hover:text-slate-800 cursor-pointer'>
                                <SvgMoreVertical className={'w-4 h-4'}/>
                            </div>
                        }
                        value={null}
                        isControlled={false}
                        setValue={() => null}
                        options={
                            [
                                {
                                    title: 'Approve member',
                                    icon: SvgEdit,
                                    onClick: this.approveMember,
                                },
                                {
                                    title: 'Reject member',
                                    icon: SvgRemove,
                                    onClick: this.removeMember,
                                    color: 'red'
                                },
                                
                            ]
                        }
                        direction="bottom"
                        light
                    />
                </div>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(ClubStudentRequestListItem);
