import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="30px"
            height="30px"
            {...props}
            fill={'currentColor'}
        >
            <path d="M15 0c-1.645 0-3 1.355-3 3H8C6.355 3 5 4.355 5 6v18c0 1.645 1.355 3 3 3h8v-2H8c-.565 0-1-.435-1-1V6c0-.565.435-1 1-1h2a2 2 0 002 2h6a2 2 0 002-2h2c.565 0 1 .435 1 1v7h2V6c0-1.645-1.355-3-3-3h-4c0-1.645-1.355-3-3-3zm0 2c.564 0 1 .436 1 1 0 .564-.436 1-1 1-.564 0-1-.436-1-1 0-.564.436-1 1-1zm-1 7v4h4a4 4 0 00-4-4zm-1 1a4 4 0 104 4h-4v-4zm7 5a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V16.996a1.991 1.991 0 00-1.996-1.994C23.317 15.003 20 15 20 15zm0 2h8v3h-8v-3zm-10 3a1 1 0 000 2h6v-2h-6zm11 3a1 1 0 110 2 1 1 0 010-2zm3 0a1 1 0 110 2 1 1 0 010-2zm3 0a1 1 0 011 1v3a1 1 0 01-2 0v-3a1 1 0 011-1zm-6 3a1 1 0 110 2 1 1 0 010-2zm3 0a1 1 0 110 2 1 1 0 010-2z" />
        </svg>
    )
}

export default SvgComponent
