import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {editNetwork} from "../../api/student/networks";
import {Link} from "react-router-dom";
import SvgLinkedIn from "../icons/SvgLinkedIn";
import {toast} from "react-toastify";
import {TOAST_OPTIONS} from "../../utils/constants";
import SvgChevronRight from "../icons/SvgChevronRight";
import CompanyLogo from "../atoms/CompanyLogo";
import Checkbox from "../atoms/Checkbox";
import SvgFavoriteFilled from "../icons/SvgFavoriteFilled";
import SvgFlag from "../icons/SvgFlag";
import CompanyLabel from "../CompanyLabel";
import SvgDocument from "../icons/SvgDocument";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

class NetworkingPageTableItem extends PureComponent {
    updateStatus = async () => {
        try {
            const newNetwork = {...this.props.network};
            const newStatus = newNetwork.status === "Contacted" ? "Not contacted" : "Contacted";
            newNetwork.status = newStatus;
            this.setState({status: newStatus});
            this.props.updateNetwork(newNetwork);

            await editNetwork(this.props.authUser, this.props.network.id, {status: newStatus});

            toast.promise(
                Promise.resolve('Status updated!'),
                {
                    pending: 'Updating status...',
                    success: 'Status updated!',
                    error: 'Failed to update status!',
                },
                TOAST_OPTIONS
            );

            window.analytics.track("Updated Contact Networked", {
              contact_id: this.props.network.person_id,
              status: newStatus
            })
        } catch (error) {
            toast.error('Failed to update status!', TOAST_OPTIONS);
        }
    };

    updateFavorited = async (e) => {
        e.stopPropagation()
        try {
            const newNetwork = {...this.props.network};
            const newValue = !newNetwork.favorited;
            newNetwork.favorited = newValue;
            this.props.updateNetwork(newNetwork);

            await editNetwork(this.props.authUser, this.props.network.id, {favorited: newValue});

            toast.promise(
                Promise.resolve(newValue ? 'Contact favorited!' : 'Contact removed from favorites'),
                {
                    pending: 'Updating favorited...',
                    success: newValue ? 'Contact favorited!' : 'Contact removed from favorites',
                    error: 'Failed to update!',
                },
                TOAST_OPTIONS
            );
        } catch (error) {
            toast.error('Failed to update!', TOAST_OPTIONS);
        }
    };

    updateActionRequired = async (e) => {
        e.stopPropagation()
        try {
            const newNetwork = {...this.props.network};
            const newValue = !newNetwork.action_required;
            newNetwork.action_required = newValue;
            this.props.updateNetwork(newNetwork);

            await editNetwork(this.props.authUser, this.props.network.id, {action_required: newValue});

            toast.promise(
                Promise.resolve(newValue ? 'Flagged as action needed' : 'Removed action needed flag'),
                {
                    pending: 'Updating action required flag...',
                    success: newValue ? 'Flagged as action needed' : 'Removed action needed flag',
                    error: 'Failed to update action required flag!',
                },
                TOAST_OPTIONS
            );
        } catch (error) {
            toast.error('Failed to update action required!', TOAST_OPTIONS);
        }
    };

    render() {
        const network = this.props.network || {};
        const person = network.person || {};
        return (
            <div
                className={`${this.props.className} row-ac-jb cursor-pointer py-0 rounded-md hover:bg-neutral-50 border-b border-b-neutral-100 last:border-none`}
                onClick={() => {this.props.openPeek(network.person_id, person)}}>
                <div className='row-ac' style={{flex: 0.7}}>
                    <p className='base-black-70 body2-bold m-0 ellipsis'>
                        {person.first_name} {person.last_name}
                    </p>
                    {person.linkedin &&
                        <a href={person.linkedin}
                           target={"_blank"}>

                            <SvgLinkedIn className={'base-black-30 h-base-black-70 pointer w-4 h-4 ml-1'}/>
                        </a>
                    }
                </div>
                {this.props.showCompany &&
                    <CompanyLabel
                        companyId={person.company_id}
                        companyName={person.company_name}
                    />
                }
                <div style={{flex: .75}}>
                    <p className='base-black-30 body3'>
                        {person.title || '--'}
                    </p>
                </div>
                {/* <div style={{flex: .5}}>
                    <p className='base-black-30 body3'>
                        {person?.source === 'club' ? 'Club' : person?.source === 'recruitu.io' || person?.source === "apollo" ? "RecruitU" : "Personal"}
                    </p>
                </div> */}
                <div style={{flex: .5}} className='flex flex-row items-center gap-1 body3 base-black-50'>
                    <SvgDocument className={'w-4 h-4'}/>
                    {network.notes.length}
                </div>
                <div style={{flex: .5}}>
                    <div className='flex flex-row items-center justify-start md:pr-4 lg:pr-6 mt-5 mb-5'>
                        <Checkbox
                            checked={network.status === "Contacted"}
                            onClick={this.updateStatus}
                            label={'Networking call'}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(NetworkingPageTableItem);
