import axios from '../axios';

export const getSavedSearches = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/recruiter/saved_searches", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const createSavedSearch = async (user, params) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params,
        };
        const res = await axios.post("/recruiter/saved_searches", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const deleteSearch = async (user, search_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {search_id}
        };
        const res = await axios.delete("/recruiter/saved_searches", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}