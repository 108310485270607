import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={83}
            height={87}
            viewBox="0 0 83 87"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M0 28.778v28.778h10.458V10.32h21.469v28.477l9.507 9.382 9.5 9.376h14.774L54.044 46.044 42.385 34.54V0H0v28.778z"
                fill="currentColor"
            />
            <path
                d="M72.542 52.45v23.617H42.385V63.753l-5.229-5.16-5.229-5.16v32.955H83V28.832H72.542V52.45z"
                fill="currentColor"
            />
        </svg>
    )
}

export default SvgComponent
