import React, {Component} from "react";
import SvgEditFeather from "../../components/icons/SvgEditFeather";
import RecruiterCompanyAddUpdate from "../modals/RecruiterCompanyAddUpdate";
import {DateTime} from 'luxon';
import SvgRemove from "../../components/icons/SvgRemove";
import SvgEyeOff from "../../components/icons/SvgEyeOff";
import SvgVisibility from "../../components/icons/SvgVisibility";
import zerostate from '../../img/zerostates/companyupdates.png'
import {Button} from "../../components/atoms/Button";

class RecruiterCompanyPageUpdatesSection extends Component {
    state = {
        updatesModalOpen: false,
    }

    openUpdatesModal = () => {
        this.setState({updatesModalOpen: true})
    }

    closeUpdatesModal = () => {
        this.setState({updatesModalOpen: false})
    }

    render() {
        const company = this.props.company || {};
        const updates = company.updates || [];
        const hidden = this.props.hidden || [];
        return (
            <div
                className={`bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl ${hidden.includes("updates") && 'opacity-50'}`}>
                <div className='flex flex-row items-center gap-2 mb-2'>
                    <p className='text-xl font-semibold text-slate-800'>
                        Updates
                    </p>
                    {updates.length > 0 &&
                        <button
                            onClick={this.openUpdatesModal}
                            className='text-slate-500 hover:text-primary cursor-pointer'>
                            <SvgEditFeather className={'w-4 h-4'}/>
                        </button>
                    }
                    <button
                        onClick={() => this.props.hide("updates")}
                        className='text-slate-500 hover:text-primary cursor-pointer'>
                        {hidden.includes("updates") ?
                            <SvgVisibility className={'w-4 h-4'}/>
                            :
                            <SvgEyeOff className={'w-4 h-4'}/>
                        }
                    </button>
                </div>
                <p className='text-slate-500 text-md mb-5'>
                    Stay in the know to start your journey at {company.name}
                </p>
                {updates.length === 0 &&
                    <>
                        <div className='w-full relative cursor-pointer' onClick={this.openUpdatesModal}>
                            <img src={zerostate} className='w-full blur-sm mb-5 saturate-50 opacity-50'/>
                            <div
                                className='absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center'>
                                <Button>
                                    Add an update
                                </Button>
                            </div>
                        </div>
                    </>
                }
                <div className='grid grid-cols-3 gap-3 mt-5'>
                    {updates.map((item, index) =>
                        <div key={item} className='rounded-lg relative border border-slate-100 p-3 gap-3 flex flex-col'>
                            <img
                                src={item.cover_photo}
                                className='object-cover mt-1 w-full h-32 aspect-square bg-slate-200 rounded-lg'
                            />
                            <div className='flex flex-col gap-2'>
                                <div className='flex flex-col'>
                                    <p className='text-xs text-primary font-semibold'>
                                        {DateTime.fromISO(item.date).isValid ? DateTime.fromISO(item.date).toRelative() + " - " : ""}{item.category}
                                    </p>
                                    <p className='text-md text-slate-800 font-semibold'>
                                        {item.title}
                                    </p>
                                    <p className='text-sm text-slate-500'>
                                        {item.description}
                                    </p>
                                </div>
                            </div>
                            <div onClick={() => this.props.removeItem("updates", index)}
                                className={'p-2 absolute cursor-pointer rounded-full bg-slate-50 hover:bg-red-400 hover:text-white text-red-400 top-2 right-2'}>
                                <SvgRemove className={'w-5 h-5'}/>
                            </div>
                        </div>
                    )}
                </div>
                <RecruiterCompanyAddUpdate
                    isOpen={this.state.updatesModalOpen}
                    closeModal={this.closeUpdatesModal}
                    company={company}
                />
            </div>
        )
    }
}

export default RecruiterCompanyPageUpdatesSection;
