import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getClub,
  editClub,
} from "../../api/clubAdmin/clubs";
import Spinner from "../../components/Spinner";
import EditClubModal from "../../components/atoms/modals/EditClubModal";
import ClubPageAboutWidget from "../../components/clubpage/ClubPageAboutWidget";
import ClubPageRecentPlacements from "../../components/clubpage/ClubPageRecentPlacements";
import AddRecentPlacementModal from "../../components/atoms/modals/AddRecentPlacementModal";
import { ClubAdminAboutSection } from "../ClubAdminAboutSection";
import PointsOfContactWidget from "../../components/clubpage/PointsOfContactWidget";

function mapStateToProps(state) {
  return {
    authUser: state.userReducer.authUser,
    user: state.userReducer.user,
  };
}

const ClubAdminClubPage = ({ club, numMembers, authUser }) => {
  const [clubId, setClubId] = useState(null);
  const [clubData, setClubData] = useState(null);
  const [hidden, setHidden] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editHeaderModalOpen, setEditHeaderModalOpen] = useState(false);
  const [recentPlacementModalOpen, setRecentPlacementModalOpen] = useState(false);

  useEffect(() => {
    setClubId(club.id);
    setClubData(club);
    setHidden(club.hidden);
  }, [club]);


  const getClubInfo = async () => {
    setLoading(true);
    const { club } = await getClub(authUser, clubId);
    setClubData(club);
    setClubId(club.id);
    setLoading(false);
  };

  const openRecentPlacementModal = () => {
    setRecentPlacementModalOpen(true);
  };

  const closeRecentPlacementModal = () => {
    setRecentPlacementModalOpen(false);
  };

  const openEditHeaderModal = () => {
    setEditHeaderModalOpen(true);
  };

  const closeEditHeaderModal = () => {
    setEditHeaderModalOpen(false);
  };

  const hide = async (field) => {
    const hiddenArray = [...hidden];
    if (hiddenArray.includes(field)) {
        const index = hiddenArray.indexOf(field);
        hiddenArray.splice(index, 1);
    } else {
      hiddenArray.push(field);
    }
    setHidden(hiddenArray);
    await editClub(authUser, clubId, { hidden: hiddenArray });
  };


    if (loading || !clubData) {
      return (
        <div className="mt-48">
          <Spinner size={24} />
        </div>
      );
    }

    return (
      <div className="flex flex-col flex-1 p-5 gap-3 relative">
        <div className="flex flex-col gap-3 flex-1">
          <ClubAdminAboutSection isAdmin={true} club={clubData} openEditHeaderModal={openEditHeaderModal} />
          <ClubPageAboutWidget
            club={clubData}
            loading={loading}
            getClubInfo={getClubInfo}
            isAdmin={true}
            hidden={hidden}
            hide={hide}
          />
          <ClubPageRecentPlacements
            club={clubData}
            loading={loading}
            getClubInfo={getClubInfo}
            isAdmin={true}
            hidden={hidden}
            openRecentPlacementModal={openRecentPlacementModal}
            hide={hide}
          />
          <PointsOfContactWidget 
            club={clubData}
            loading={loading} 
            getClubInfo={getClubInfo}
            isAdmin={true}
            hide={hide} 
            hidden={hidden}/>
        </div>
        <EditClubModal
          isOpen={editHeaderModalOpen}
          closeModal={closeEditHeaderModal}
          club={clubData}
          getClubInfo={getClubInfo}
          numMembers={numMembers}
        />
        <AddRecentPlacementModal
          isOpen={recentPlacementModalOpen}
          closeModal={closeRecentPlacementModal}
          club={clubData}
          getClubInfo={getClubInfo}
        />
      </div>
    );

}

export default connect(mapStateToProps)(ClubAdminClubPage);
