import React, {useState} from 'react';
import CompanyLogo from "./atoms/CompanyLogo";
import SvgLinkedIn from "./icons/SvgLinkedIn";
import SvgChevronRight from "./icons/SvgChevronRight";
import SvgMail from "./icons/SvgMail";
import SvgPhone from "./icons/SvgPhone";
import SvgCheck from "./icons/SvgCheck";
import SvgCopy from "./icons/SvgCopy";
import {Link} from "react-router-dom";
import {wait} from "@testing-library/user-event/dist/utils";
import {SIDEBAR_CARD} from "../utils/dummy";
import SvgUserCheck from "./icons/SvgUserCheck";
import SvgUserFeather from "./icons/SvgUserFeather";

const ContactDetails = ({person, hideHeader, hideContact}) => {
    const [phoneCopied, setPhoneCopied] = useState(false);
    const [emailCopied, setEmailCopied] = useState(false);

    const copyToClipboard = (type, text) => {
        if (type === 'phone') {
            setPhoneCopied(true);
        }
        if (type === 'email') {
            setEmailCopied(true);
        }
        navigator.clipboard.writeText(text)
            .then(() => console.log('Copied to clipboard:', text))
            .catch(error => console.error('Failed to copy link to clipboard:', error));

        window.analytics.track("Contact Email Copied", {
          person_id: person.id
        })

        wait(3000).then(() => {
            if (type === 'phone') {
                setPhoneCopied(false);
            }
            if (type === 'email') {
                setEmailCopied(false);
            }
        });
    };

    return (
        <div>
            {!hideHeader &&
                <div className='flex flex-row items-center gap-5'>
                    <div
                        className='w-16 h-16 border-slate-200 rounded-full border flex flex-col items-center justify-center bg-slate-50'>
                        <SvgUserFeather className={'w-8 h-8 text-slate-400'}/>
                    </div>
                    <div>
                        <div className='flex flex-row items-center gap-2'>
                            <p className='text-3xl font-bold'>{person.first_name} {person.last_name}</p>
                            {person.linkedin && <a href={person.linkedin} target="_blank"><SvgLinkedIn
                                className='base-black-30 h-base-black-70 pointer w-5 h-5 mt-1'/></a>}
                        </div>
                        <p className={`body1 ${person.industry ? 'text-slate-800' : 'text-slate-300'}`}>{person.industry || 'No industry info'}</p>
                    </div>
                </div>
            }
            <div className={`${SIDEBAR_CARD} ${!hideHeader && 'mt-5'}`}>
                <p className='text-lg font-bold mb-2'>Work</p>
                <div className=' flex flex-row gap-2'>
                    {person?.title && <div
                        className={`flex flex-row items-center gap-2 body2 ${person.title ? 'text-slate-800' : 'text-slate-500'}`}>
                        <p className='text-slate-800'>{person.title}{(person.title && person.company_name) && ' at'}</p>
                    </div>}
                    {person?.company_id ?
                        <div className='flex flex-row items-center gap-2 primary cursor-pointer'>
                            <CompanyLogo id={person.company_id} className={'w-8 h-8'}/>
                            <Link to={{
                                pathname: '/company/' + person.company_id,
                                state: {company_id: person.company_id}
                            }}>
                                <div className='flex flex-row items-center gap-0'>
                                    <p className='body2-bold primary'>{person.company_name}</p>
                                    <SvgChevronRight className={'w-5 h-5 mt-0.5'}/>
                                </div>
                            </Link>
                        </div> :
                        <div className='row-ac primary'><p
                            className='body2 text-slate-500'>{person.company_name || ''}</p></div>
                    }
                </div>
            </div>
            {person.school &&
                <div className={`${SIDEBAR_CARD} mt-5`}>
                    <p className='text-lg font-bold mb-2'>Education</p>
                    <div className=' flex flex-row gap-2'>
                        {person.school && <div
                            className={`flex flex-row items-center gap-2 body2`}>
                            {person.school}
                        </div>}
                    </div>
                </div>
            }
            {!hideContact &&
                <div className={`${SIDEBAR_CARD} mt-5`}>
                    <p className='text-lg font-bold mb-2'>Contact</p>
                    <div className='flex flex-col gap-1'>
                        <div
                            className={`flex flex-row items-center gap-2 body2 ${person.email ? 'text-slate-800' : 'text-slate-500'}`}>
                            <div className='w-8 h-8 flex flex-col items-center justify-center'><SvgMail
                                className={'w-5 h-5'}/>
                            </div>
                            <p>{person.email || 'No email saved'}</p>
                            {person.email &&
                                (emailCopied ?
                                        <div className='cursor-pointer text-green-500 flex flex-row items-center gap-1'>
                                            <SvgCheck className={'w-4 h-4'}/>
                                            <p className='text-xs'>Copied!</p>
                                        </div> :
                                        <div onClick={() => copyToClipboard('email', person.email)}
                                             className='cursor-pointer text-slate-400 hover:text-slate-900'>
                                            <SvgCopy className={'w-4 h-4'}/>
                                        </div>
                                )
                            }
                        </div>
                        {/* <div
                        className={`flex flex-row items-center gap-2 body2 ${person.phone ? 'text-slate-800' : 'text-slate-500'}`}>
                        <div className='w-8 h-8 flex flex-col items-center justify-center'><SvgPhone
                            className={'w-5 h-5'}/>
                        </div>
                        <p>{person.phone || 'No phone saved'}</p>
                        {person.phone &&
                            (phoneCopied ?
                                    <div className='cursor-pointer text-green-500 flex flex-row items-center gap-1'>
                                        <SvgCheck className={'w-4 h-4'}/>
                                        <p className='text-xs'>Copied!</p>
                                    </div> :
                                    <div onClick={() => copyToClipboard('phone', person.phone)}
                                         className='cursor-pointer text-neutral-400 hover:text-neutral-900'>
                                        <SvgCopy className={'w-4 h-4'}/>
                                    </div>
                            )
                        }
                    </div> */}
                    </div>
                </div>
            }
        </div>
    );
}

export default ContactDetails;
