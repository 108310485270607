import React, {PureComponent} from "react";
import SvgAddCircle from "../../components/icons/SvgAddCircle";
import Tooltip from "../../components/atoms/modals/Tooltip";
import {connect} from "react-redux";
import { favoriteStudent } from "../../api/recruiter/recruiter";
import SvgHeartOutline from "../../components/icons/SvgHeartOutline";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        candidates: state.userReducer.candidates

    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateCandidate: (candidate) => {
            dispatch({
                type: "UPDATE_CANDIDATE",
                candidate,
            })
        }
    }
}


class RecruiterFavoritedButtonComponent extends PureComponent {
    favorite = async () => {
        const student = {...this.props.candidates[this.props.candidate.id]};
        if (!("company_student" in student)) {
            student["company_student"] = {};
        }
        student["company_student"]["favorited"] = !student?.company_student?.favorited;
        this.props.updateCandidate(student);

        favoriteStudent(this.props.authUser, this.props.candidate.username);
    }

    render() {
        const candidateId = this.props.candidate.id;
        const candidate = this.props.candidates[candidateId];
        const favorited = candidate?.company_student?.favorited || false;
        return (
            <>
                {(favorited) ?
                    <button className={`secondary-pill-sm opacity-50 ${this.props.sm ? 'body4-bold' : 'body3-bold'}`} onClick={(e) => {
                        e.stopPropagation()
                        this.favorite()
                    }}>
                        Favorited
                    </button>
                    :
                    <Tooltip
                        toggleComponent={
                            <button onClick={(e) => {
                                e.stopPropagation()
                                this.favorite()
                            }}
                                    className={`secondary-pill-sm ${this.props.sm ? 'body4-bold' : 'body3-bold'}`}
                            >
                                {this.props.recruiter ?
                                    <SvgHeartOutline className={`${this.props.sm ? 'w-4 h-4' : 'w-5 h-5'} mr-1`}/>
                                    :
                                    <SvgAddCircle className={`${this.props.sm ? 'w-4 h-4' : 'w-5 h-5'} mr-1`}/>
                                }
                                Favorite
                            </button>
                        }
                        label={'Favorite this candidate'}
                    />
                }
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterFavoritedButtonComponent);
