import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'} d="M321-531v-60h319v60H321zm0 127v-60h319v60H321zm0 127v-60h319v60H321zm371-327v-88h-88v-60h88v-88h60v88h88v60h-88v88h-60zM120-120v-720h454v60H180v600h600v-394h60v454H120z" />
        </svg>
    )
}

export default SvgComponent
