import React, {PureComponent} from "react";
import TooltipComponent from "./TooltipComponent";
import PropTypes from "prop-types";
import SvgCheck from "../icons/SvgCheck";
import ClubLogo from "../../components-recruiter/atoms/ClubLogo";

class MultiSelectDropdownComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    handleClick = (e, title) => {
        e.stopPropagation()
        // this.toggle(false, true);
        this.props.setValue(title)
    }

    optionRenderer = (option, index) => {
        return (
            <div key={option.title}
                 className={`${this.props.light ? 'dropdown-menu-option-light' : 'dropdown-menu-option'} body2-emphasized ${option.active ? 'active' : ''}`}
                 onClick={(e) => {
                     this.handleClick(e, this.props.useValue ? option : option.title);
                 }}>
                {
                    this.props.selectDropdown &&
                    <div
                        className={`${this.props.value.includes(option.title) ? 'checkbox-active' : 'checkbox'} mr-12 ${(this.props.light && this.props.value !== option.title) && 'border-neutral-200'}`}>
                        {this.props.value.includes(option.title) &&
                            <SvgCheck className={'base-white-100'}/>
                        }
                    </div>
                }
                <div className="flex items-center gap-1">
                    {option.club && (
                        <ClubLogo
                            clubId={option.club.id}
                            backupUrl={option.club.logo_url}
                            alt={'club-icon'}
                            size={24}
                        />
                    )}
    
                    <div className="col-plain" style={{color: option.color}}>
                        <p className={` ${!!option.subtitle ? 'body3 mb-4' : 'body2'}`}
                        style={{color: option.color}}
                        >
                            {option.title}
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    toggle = (isOpen, fromClick) => {
        if (this.props.isControlled) {
            this.props.toggle(isOpen)
            this.props.toggleCallback && this.props.toggleCallback(isOpen);
            !!fromClick && this.props.optionClickedCallback && this.props.optionClickedCallback()
        } else {
            this.props.toggleCallback && this.props.toggleCallback(isOpen);
            this.setState({isOpen})
            !!fromClick && this.props.optionClickedCallback && this.props.optionClickedCallback()
        }
    }

    render() {
        return (
            <TooltipComponent
                ToggleComponent={this.props.ToggleComponent}
                toggleClassName={this.props.toggleClassName}
                ContentComponent={
                    <div className={`dropdown-menu ${this.props.light ? 'dropdown-menu-light' : 'dropdown-menu'} ${(this.props.scrollable && this.props.options.length > 10) && 'max-h-[30vh] overflow-y-scroll'}`}>
                        {this.props.options.map((option, index) =>
                            this.optionRenderer(option, index)
                        )}
                    </div>
                }
                disabled={this.props.disabled}
                visible={this.props.isControlled ? this.props.isOpen : this.state.isOpen}
                toggle={this.toggle}
                offsetX={this.props.offsetX}
                offsetY={this.props.offsetY}
                placement={this.props.direction || "bottom-start"}
                node={this.props.node}
            />
        )
    }
}

MultiSelectDropdownComponent.propTypes = {
    isControlled: PropTypes.bool,
    toggle: PropTypes.func,
    toggleCallback: PropTypes.func,
    optionClickedCallback: PropTypes.func,
    ToggleComponent: PropTypes.object,
    toggleClassName: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    isOpen: PropTypes.bool,
    offsetX: PropTypes.number,
    offsetY: PropTypes.number,
    direction: PropTypes.string,
    node: PropTypes.node
}

export default MultiSelectDropdownComponent
