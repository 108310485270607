import axios from "../axios";

export const createApplication = async (user, params) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const res = await axios.post("/clubs/admin/application", params, config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateApplication = async (user, params) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const res = await axios.patch("/clubs/admin/application", params, config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getApplicationsByClub = async (user, club_id) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
      params: { club_id },
    };
    const res = await axios.get("/clubs/admin/application", config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getApplication = async (user, application_id) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
      params: { application_id },
    };
    const res = await axios.get("/clubs/admin/application", config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const submitApplication = async (user, params) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const res = await axios.post("/clubs/application/submit", params, config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const checkForExistingApplication = async (
  user,
  user_id,
  application_id
) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
      params: { user_id, application_id },
    };
    const res = await axios.get("/clubs/application/check", config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getApplicationSubmissions = async (user, application_id) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
      params: { application_id },
    };
    const res = await axios.get("/clubs/application", config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
