import {useState, useRef} from "react";
import {resourceData} from "./resourceData";
import CollapseComponent from "../atoms/CollapseComponent";
import SvgChevronDown from "../icons/ChevronDown";
import { getResources } from "../../api/student/resources";
import { useEffect } from "react";
import { connect } from "react-redux";
import Spinner from "../Spinner";

const mapStateToProps = (state) => ({
    authUser: state.userReducer.authUser,
});

function ResourcesLearnTab({ authUser }) {
    const [resources, setResources] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedPageAccessKey, setSelectedPageAccessKey] = useState('');
    const [expandedSectionIndex, setExpandedSectionIndex] = useState(0);

    
    useEffect(() => {
        const fetchResources = async () => {
            try {
                const resources = await getResources(authUser);
                setResources(resources);
                setSelectedPageAccessKey(Object.values(Object.values(resources.resourcesTableOfContents)[0])[0]);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching resources:', error);
            }
        };

        fetchResources();
    }, []);

    const handleSectionClick = (index) => {
        // Toggle the expanded section
        if (expandedSectionIndex === index) {
            setExpandedSectionIndex(null);
        } else {
            setExpandedSectionIndex(index);
        }
        setSelectedPageAccessKey(Object.values(Object.values(resources.resourcesTableOfContents)[index])[0]);
    };

    const handlePageClick = (pageIndex) => {
        setSelectedPageAccessKey(pageIndex);
    };

    const selectedPage = resources?.resourceAccessMap?.[selectedPageAccessKey];

    const subPageRefs = useRef([]);

    const scrollToSubPage = (index) => {
        if (subPageRefs.current?.[index]) {
            subPageRefs.current[index].scrollIntoView({behavior: "smooth"});
        }
    };

    const formatContentWithImageStyles = (content) => {
        return content.replace(
            /<img(.*?)>/g,
            `<div class="flex justify-center my-3"><img $1 class="max-w-full"/></div>`
        );
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-full">
                <Spinner size={40} />
            </div>
        );
    }

    return (
        <div className="flex flex-row bg-gray-100 border border-slate-300 rounded-2xl overflow-hidden flex-1 my-5">
            {/* Combined Section and Page List */}
            <div className="w-1/4 lg:w-1/6 border-r border-gray-300 p-3">
                <h3 className="text-lg font-semibold mb-4">Resources</h3>
                <ul className="space-y-2">
                    {Object.entries(resources.resourcesTableOfContents).map(([topic, subLevels], sectionIndex) => (
                        <li key={sectionIndex}>
                            {/* Section Button */}
                            <button
                                onClick={() => handleSectionClick(sectionIndex)}
                                className={`w-full text-sm text-left px-2 py-2 rounded-lg font-medium hover:bg-primary hover:text-white transition flex justify-between items-center ${
                                    expandedSectionIndex === sectionIndex
                                        ? "bg-primary text-white"
                                        : "bg-white text-primary border border-primary"
                                }`}
                            >
                                <span>{topic}</span>
                                <SvgChevronDown
                                    className={expandedSectionIndex === sectionIndex ? 'rotate-180' : 'rotate-0'}/>
                            </button>

                            <CollapseComponent isOpen={expandedSectionIndex === sectionIndex}>
                                <div className='flex flex-row items-stretch my-2'>
                                    <div className='flex bg-slate-300 ml-3 w-[2px]'/>
                                    <ul className="ml-4 flex-1 flex space-y-1">
                                        {Object.entries(subLevels).map(([label, accessKey]) => (
                                            <li key={accessKey}>
                                                <button
                                                    onClick={() => handlePageClick(accessKey)}
                                                    className={`w-full text-sm text-left px-2 py-1 rounded-lg transition ${
                                                        accessKey === selectedPageAccessKey
                                                            ? "text-primary font-semibold"
                                                            : "text-slate-500 border border-primary hover:bg-slate-200"
                                                    }`}
                                                >
                                                    {label}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </CollapseComponent>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Main content window displaying all subpages of selected page */}
            <div className="w-3/4 lg:w-5/6 p-5 pb-0 bg-white shadow-lg flex flex-col flex-1 overflow-hidden">
                <h3 className="text-xl font-bold mb-2">{selectedPageAccessKey?.split?.('%%')[1]}</h3>

                {/* Directory to scroll to subpages */}
                {selectedPage && selectedPage.length > 1 && (
                    <div className="mb-2">
                        <ul className="flex space-x-4 flex-wrap">
                            <h4 className="text-md font-semibold mb-2">Jump to:</h4>
                            {selectedPage.map((subPage, index) => (
                                <li key={index}>
                                    <button
                                        onClick={() => scrollToSubPage(index)}
                                        className="text-primary hover:underline"
                                    >
                                        {subPage.title}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {/* Subpage content */}
                {/* TODO: add support for pdfs */}
                <div className="flex flex-col flex-1 overflow-y-scroll">
                    {(selectedPage ?? []).map((subPage, index) => (
                        <div
                            key={index}
                            ref={(el) => (subPageRefs.current[index] = el)}
                            className="mb-6"
                        >
                            {(subPage.title)?.trim() !== selectedPageAccessKey?.split?.('%%')[1]?.trim() && <h4 className="text-lg font-semibold">{subPage.title}</h4>} 
                            <div
                                className="mt-2 text-gray-700 prose prose-sm min-w-full"
                                dangerouslySetInnerHTML={{
                                    __html: formatContentWithImageStyles(subPage.content),
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default connect(mapStateToProps)(ResourcesLearnTab);
