import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {logout} from "../api/authentication";
import SvgUsers from "../components/icons/SvgUsers";
import Avatar from "boring-avatars";
import ProfilePicture from "./atoms/ProfilePicture";

class SideNavItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false
        };
    }

    handleHover = () => {
        this.setState({
            isHovered: !this.state.isHovered
        });
    };

    navigateToRoute = (route) => {
        this.props.history.push(route)
        this.props.forceClose()
    }

    render() {
        const item = this.props.item
        if (item.label === 'divider') {
            return (
                <div className={'w-full h-[1px] my-5 bg-white/10'}/>
            )
        }
        if (item.logout) {
            return (
                <div
                    onClick={() => logout()}
                    className={`py-3 pointer px-2.5 flex flex-row items-center h-10 my-1.5 hover:bg-white/10 active:bg-white/20 rounded-lg cursor-pointer`}
                >
                    <div className='w-5 h-5 aspect-square flex flex-col'>
                        {item.icon &&
                            <item.icon className={'w-5 h-5'}/>
                        }
                    </div>
                    <div
                        style={{transition: 'width 300ms ease'}}
                        className={(this.props.navbarOpen && !this.props.forceClosed) ? 'w-full ml-3 mr-1 whitespace-nowrap' : 'w-0 overflow-hidden opacity-50 hidden'}>
                        <p className='text-md font-semibold'>{item?.label}</p>
                    </div>
                </div>
            );
        }
        if (item.linkToClub) {
            return (
                <Link to={{pathname: '/club/' + item.id, state: {club_id: item.id}}}
                      className={`py-2 gap-3 px-2.5 flex flex-row items-center hover:bg-white/10 active:bg-white/20 rounded-lg cursor-pointer`}
                >
                    {item.icon &&
                        <div className='w-10 flex flex-col items-center justify-center'
                             style={{width: 40, minWidth: 40, height: 24}}>
                            <item.icon className={'w-5 h-5 text-white/50'}/>
                        </div>
                    }
                    {item.img ?
                        <img src={item.img}
                             style={{width: 40}}
                             className={`w-10 h-10 object-contain bg-white rounded-md border-[1px] bg-base-white-20 border-[1px] border-white/20 `}/>
                        :
                        <div
                            className="w-10 h-10 object-contain bg-white rounded-md border-[1px] bg-base-white-20 border-[1px] border-white/20">
                            <div
                                className={`rounded-xl relative overflow-hidden bg-slate-50 aspect-square h-min border border-slate-200`}>
                                <div
                                    className='absolute cover-parent bg-white/80 flex flex-col items-center justify-center'>
                                    <SvgUsers className={'w-6 h-6 mx-auto'}/>
                                </div>
                                <Avatar
                                    size={40}
                                    name={item.label}
                                    variant="marble"
                                    colors={['#9ba5fd', '#303a8a', '#10123b', '#264aff', '#3D4CB6']}
                                />
                            </div>
                        </div>
                    }
                    <div
                        className={`overflow-hidden whitespace-nowrap flex-1 overflow-ellipsis ${(this.props.navbarOpen && !this.props.forceClosed) ? '' : 'w-0 hidden'}`}
                        style={{transition: 'width 300ms ease'}}
                    >
                        <p className='text-sm font-semibold text-ellipsis overflow-hidden text-ellipsis w-[132px]'>
                            {item.label}
                        </p>
                    </div>
                </Link>
            );
        }
        return (
            <div
                onClick={() => item.onClick ? item.onClick() : this.navigateToRoute(item.route)}
                className={`py-3 px-2.5 flex flex-row items-center h-10 my-1.5 hover:bg-white/10 active:bg-white/20 rounded-lg cursor-pointer`}
            >
                <div className={'w-5 h-5 aspect-square flex flex-col'}>
                    {item.icon &&
                        <div
                            className={` ${item.label === "New Club" && 'w-10 flex flex-col items-center justify-center'}`}
                            style={{width: 40, minWidth: 40, height: 24}}>
                            <item.icon className={`w-5 h-5 ${item.label === "Lists" && 'scale-125'}`}/>
                        </div>
                    }
                    {item.user &&
                        <div className={`w-6 h-6 scale-[.9] -mt-.5`}>
                            <ProfilePicture userID={item.user.username} image={item.img} size={24} className={'-ml-1'}/>
                        </div>
                    }
                </div>
                <div
                    className={(this.props.navbarOpen && !this.props.forceClosed) ? 'w-full ml-3 mr-1 whitespace-nowrap' : 'w-0 hidden'}
                    style={{transition: 'width 300ms ease'}}
                >
                    <p className={`text-md font-semibold ${item.label === "New Club" && 'ml-8'}`}>{item.label}</p>
                </div>
            </div>
        );
    }
}

export default withRouter(SideNavItem);
