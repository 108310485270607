import React from "react";
import Avatar from "boring-avatars";
import SvgUsers from "../../components/icons/SvgUsers";

class StudentClubLogo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageError: false
        };
    }

    handleImageError = () => {
        this.setState({imageError: true});
    };

    render() {
        const {imageError} = this.state;
        const {clubId} = this.props;
        if (imageError || !clubId) {
            return (
                <div
                    className={`rounded-xl relative overflow-hidden bg-slate-50 aspect-square h-min ${this.props.className}`}>
                    <div className='absolute cover-parent bg-white/80 flex flex-col items-center justify-center'>
                        <SvgUsers className={'w-6 h-6 mx-auto'}/>
                    </div>
                    <Avatar
                        size={this.props.size || 40}
                        name={this.props.userID || 'fallback'}
                        variant="marble"
                        colors={['#9ba5fd', '#303a8a', '#10123b', '#264aff', '#3D4CB6']}
                    />
                </div>
            )
        }
        return (
            <div className={`aspect-square overflow-hidden h-min rounded-xl bg-slate-50 ${this.props.className}`}>
                <img
                    src={!imageError && !!clubId ? `https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/clubs%2F${clubId}?alt=media` : 'https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fcover_image%2FdefaultCompanyLogo.jpg?alt=media'}
                    onError={this.handleImageError}
                    className={'object-contain bg-slate-50'}
                    style={{width: this.props.size || 40, height: this.props.size || 40}}
                    alt='Club Logo'
                />
            </div>
        );
    }
}

export default StudentClubLogo;
