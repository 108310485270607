import React, {PureComponent} from "react";
import {connect} from "react-redux";
import PageContentWrapper from "../wrappers/PageContentWrapper";
import {withRouter} from "react-router-dom";
import PageHeaderWrapper from "../wrappers/PageHeaderWrapper";
import DiscoverJobs from "../discover/DiscoverJobs";
import SvgSearch from "../icons/SvgSearch";
import AnimatedTabs from "../atoms/AnimatedTabs";
import SvgBriefcase from "../icons/SvgBriefcase";
import CRMPage from "./CRMPage";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        applications: state.userReducer.applications,
        user: state.userReducer.user,
    };
}

class JobsPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            filter: "All",
            companyId: null,
            companyName: null,
            activeTab: "Search"
        };
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const activeTab = queryParams.get("activeSubTab");
        const companyId = queryParams.get("companyId")
        const companyName = queryParams.get("companyName")
        if (!!activeTab) this.changeTab(activeTab);
        if (!activeTab) window.analytics.page("Job Tab  Viewed")
        if (!!companyId) this.setState({companyId});
        if (!!companyName) this.setState({companyName})
    }



    changeTab = (tab) => {
        this.setState({activeTab: tab});
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("activeTab", tab);

        const params = `activeTab=${tab}`
        const newUrl = `${window.location.pathname}?${params}`;
        this.props.history.replace(newUrl);
    };


    render() {
        const activeTab = this.state.activeTab;
        return (
            <PageContentWrapper>
                <PageHeaderWrapper className={'pt-3 pb-0'}>
                    <div className='relative -mb-2 -mt-2'>
                    <AnimatedTabs
                            activeTab={this.state.activeTab}
                            changeTab={this.changeTab}
                            xl
                            tabs={[
                                {title: 'Search', icon: SvgSearch},
                                {title: 'Saved', icon: SvgBriefcase},
                                ]}
                        />
                    </div>
                </PageHeaderWrapper>
                <div className='pt-3 flex flex-1 overflow-hidden'>
                    {activeTab === "Search" &&
                        <DiscoverJobs history={this.props.history} companyId={this.state.companyId}
                                    companyName={this.state.companyName}/>
                    }
                    {activeTab === "Saved" &&
                        <CRMPage   changeTab={this.changeTab}/>
                    }
                </div>
            </PageContentWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps)(JobsPage));
