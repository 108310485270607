import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 96 960 960"
            width={48}
            {...props}
        >
            <path
                fill={props.fill || 'currentColor'}
                d="M887 489l-23-50-50-23 50-23 23-50 23 50 50 23-50 23-23 50zM760 314l-35-74-74-35 74-35 35-74 35 74 74 35-74 35-35 74zM360 976q-34 0-57.5-23.5T279 895h162q0 34-23.5 57.5T360 976zM198 833v-60h324v60H198zm5-121q-66-43-104.5-107.5T60 459q0-122 89-211t211-89q122 0 211 89t89 211q0 81-38 145.5T517 712H203zm22-60h271q48-32 76-83t28-110q0-99-70.5-169.5T360 219q-99 0-169.5 70.5T120 459q0 59 28 110t77 83zm135 0z" />
        </svg>
    )
}

export default SvgComponent
