import React, {Component} from "react";
import WrappedTextInput from "./WrappedTextInput";
import SvgCheck from "../icons/SvgCheck";
import { Button } from "./Button";
import SvgCopy from "../icons/SvgCopy";
import { wait } from "@testing-library/user-event/dist/utils";


class InviteExecutivesCta extends Component {
    state = {
        email: "",
        copied: false
    }

    copyLink = () => {
        this.setState({copied: true});
        navigator.clipboard.writeText("https://app.recruitu.com/authentication?activeTab=signup&clubId=" + this.props.club.id);
        window.analytics.track("Copy Invite Link", {"club_id": this.props.club.id})
        wait(1000).then(() =>
            this.setState({copied: false})
        )
    }

    render() {
        return (
            <div
                className='flex flex-col items-center justify-center mt-48'>
                <div className='card w-[360px]'>
                    <img alt={'invite'}
                         src={require('../../img/illustrations/techny-secure-lock-and-key.gif')}
                         className='w-1/2 mx-auto scale-125'/>
                    <p className='text-xl text-slate-800 font-semibold mb-2'>
                        {this.props.title || ' Student and Club Search'}
                    </p>
                    <p className='text-md text-slate-500'>
                        You can unlock the rest of the features for your club by inviting an executive to join the platform!
                    </p>
                    <div className='flex flex-row mt-8'>
                        <div className='flex-1'>
                            <p className='body3-bold text-neutral-500 mb-2'>
                                Executive's Email
                            </p>
                            <WrappedTextInput
                                type="text"
                                placeholder={'ie. john.smith@ut.edu'}
                                className='input-light px-3 body2 w-full'
                                value={this.state.email}
                                onChange={(e) => this.setState({email: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-1">
                        <a
                            href={`mailto:${this.state.email}?&subject=Invitation to Join RecruitU!&bcc=brooks.gammill@recruitu.io,wesley.farley@recruitu.io.com`} target={'_blank'}
                            rel={'noreferrer'} className='primary-button body1-bold mx-auto mt-5'>
                            Get in touch
                        </a>
                        <Button 
                            variant='secondary' 
                            className="basis-1/2 mx-auto mt-2" 
                            onClick={this.copyLink}
                        >
                            {this.state.copied ?
                                <SvgCheck className={'w-5 h-5'}/>
                                :
                                <SvgCopy className={'w-5 h-5'}/>
                            }
                            {this.state.copied ? 'Link copied!' : 'Copy invite link'}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default InviteExecutivesCta
