import React, {PureComponent} from "react";
import SvgChevronDown from "../icons/ChevronDown";
import SvgMoreVertical from "../icons/SvgMoreVertical";
import DropdownComponent from "./DropdownComponent";
import SvgCheck from "../icons/SvgCheck";
import { DateTime } from 'luxon';



class WorkspaceMemberItem extends PureComponent {
    render() {
        const member = this.props.member;

        return (
            <div
                className='flex flex-row justify-between items-center gap-8 border-b pb-2 border-neutral-100 last:border-none last:pb-0'>
                <div className='flex flex-row items-center gap-3 flex-1'>
                    <div className='w-8 h-8 rounded-full bg-slate-200 flex flex-row items-center justify-center'>
                        <p className='text-2-500'>
                            {member.email.slice(0,1)}
                        </p>
                    </div>
                    <div className='flex flex-col gap-0 flex-1'>
                        <div className='flex flex-row items-start gap-1'>
                            <p className='text-md font-semibold text-gray-800'>
                                {!!member.first_name ? member.first_name : member.email} {member.last_name}
                            </p>
                            {this.props.authUser.email === member.email &&
                                <div className='border px-2 flex flex-row items-center h-5 rounded-full'>
                                    <p className='text-xs'>
                                        You
                                    </p>
                                </div>
                            }
                        </div>
                        <div className='flex flex-row items-start gap-1'>
                            <p className={`text-sm text-gray-500 ${!member.joined && 'text-brandCoral'}`}>
                                {!!member.first_name ? member.email : 'Invite not accepted'}
                            </p>
                            {/* {!member.joined &&
                                <p className='text-1-500 text-brandCoral cursor-pointer hover:opacity-70'>
                                    - Resend
                                </p>
                            } */}
                        </div>
                    </div>
                </div>
                {!this.props.hideDate &&
                    <div className='flex-[0.25]'>
                        <p className='text-sm text-slate-400'>
                            Joined
                        </p>
                        <p className='text-md font-medium text-slate-800'>
                            {(!member.created_at && "--") || DateTime.fromISO(member.created_at).toFormat("MM/dd/yy")}
                        </p>
                    </div>
                }
                {/* <div className='flex-[0.3]'>
                    <p className='text-1-500 text-slate-400'>
                        Role
                    </p>
                    <DropdownComponent
                        ToggleComponent={
                            <div className='flex flex-row items-center gap-1 hover:opacity-80 cursor-pointer'>
                                {!member.admin && <SvgCheck className={'w-3 h-3 mb-0.5 text-brandCoral'}/>}
                                <p className='text-2-500 text-slate-800'>
                                    {member.admin ? 'Admin' : 'Member'}
                                </p>
                                <SvgChevronDown/>
                            </div>
                        }
                        setValue={() => null}
                        options={[
                            {title: 'Admin', onClick: () => null, visible: member.joined},
                            {title: 'Member', onClick: () => null},
                        ]}
                        light
                    />
                </div>
                 */}
                 {!this.props.recruiter || !("username" in member) ?
                <DropdownComponent
                    ToggleComponent={
                        <div
                            className='flex cursor-pointer flex-col items-center hover:text-neutral-800 justify-center h-5 w-5 rounded-full hover:bg-neutral-100'>
                            <SvgMoreVertical className={'w-4 h-4'}/>
                        </div>
                    }
                    setValue={() => null}
                    options={[
                        {title: `Remove ${this.props.recruiter ? "Invite" : "Adin"}`, onClick: () => {this.props.removeAdmin(member)}, color: 'red'},
                    ]}
                    light
                />
                :
                <div className='flex flex-col items-center justify-center h-5 w-5 '></div>
                }
            </div>
        )
    }
}

export default WorkspaceMemberItem
