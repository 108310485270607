import React, {PureComponent} from "react";
import SvgSearch from "../icons/SvgSearch";
import {connect} from "react-redux";
import EditPostModal from "../atoms/modals/EditPostModal";
import JobBoardLoading from "../loading/JobBoardLoading";
import ClubPagePost from "../clubpage/ClubPagePost";
import {Button} from "../atoms/Button";
import {getUserPosts} from "../../api/student/posts";
import FadeInOnScroll from "../atoms/FadeInOnScroll";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        applications: state.userReducer.applications,
    };
}

class DiscoverFeed extends PureComponent {
    constructor(props) {
        super(props);
        this.headingRef = React.createRef(); // Create a ref to access the component's DOM node
        this.state = {
            page: 1,
            hideMorePosts: false,
            morePostsLoading: false,
            posts: [],
            index: null,
            postsLoading: true,
            currentPost: {},
            editPostModalOpen: false,
        };
    }


    componentDidMount = () => {
        this.getPosts();
    }

    getPosts = async () => {
        this.setState({postsLoading: true})
        const res = await getUserPosts(this.props.authUser);
        this.setState({posts: res.posts, index: res.index, postsLoading: false})
    }


    getMorePosts = async () => {
        this.setState({morePostsLoading: true});
        const res = await getUserPosts(this.props.authUser, this.state.index);
        this.setState({
            posts: [...this.state.posts, ...res.posts],
            morePostsLoading: false,
            page: this.state.page + 1,
            index: res.index,
            hideMorePosts: res.posts.length === 0
        });
    }

    openEventPage = (selectedEvent) => {
        this.props.history.push(`/event/${selectedEvent.id}`);
    }

    openEditPostModal = (post) => {
        this.setState({currentPost: post, editPostModalOpen: true})
    }

    closeEditPostModal = () => {
        this.setState({editPostModalOpen: false})
    }


    render() {
        return (
            <>
                <div className='overflow-hidden flex-1'>
                    <div className={'overflow-y-scroll hide-scrollbar w-full flex-1 pb-5 px-5'}>
                        {this.state.postsLoading ?
                            <JobBoardLoading title={'Loading posts'}/>
                            :
                            this.state.posts.length === 0 ?
                                <div className='my-8 flex text-neutral-400 flex-col items-center justify-center flex-1'>
                                    <SvgSearch/>
                                    <p className='body2 mt-2 mb-6'>
                                        No recent posts found...
                                    </p>
                                </div>
                                :
                                <div className={`flex flex-col gap-0 ${this.state.grid && 'grid grid-cols-4 gap-4'}`}>
                                    {this.state.posts.map((post, index) =>
                                        <FadeInOnScroll key={post.id} triggerImmediately
                                                        delay={(index * 50) + 10}>
                                            <ClubPagePost post={post} club={{id: 'fake'}}
                                                          getClubInfo={this.getPosts}
                                                          openEventPage={this.openEventPage}
                                                          authUser={this.props.authUser}
                                                          editPost={() => this.openEditPostModal(post)}/>
                                        </FadeInOnScroll>
                                    )}
                                </div>
                        }

                        {(!this.state.postsLoading && !this.state.hideMorePosts ) &&
                            <div className='flex flex-row items-center justify-center py-8 body2-bold'
                                 onClick={this.getMorePosts}>
                                <Button loading={this.state.morePostsLoading} variant={'ghost'}>
                                    Load older posts
                                </Button>
                            </div>
                        }
                    </div>
                    <EditPostModal
                        isOpen={this.state.editPostModalOpen}
                        closeModal={this.closeEditPostModal}
                        club={{id: 'club'}}
                        post={this.state.currentPost}
                        getClubInfo={this.getPosts}
                    />
                </div>
            </>
        )
    }
}

export default connect(mapStateToProps)(DiscoverFeed);
