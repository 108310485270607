import axios from 'axios';

const env = process.env.NODE_ENV;
const vercelEnv = process.env.REACT_APP_VERCEL_ENV;;

let baseURL;
const devProxy = `http://localhost:5001/`;
const prodURL = `https://recruit-u-f79a8.uc.r.appspot.com/`;
const devURL = `https://dev-dot-recruit-u-f79a8.uc.r.appspot.com/`;
let segmentKey;

if (vercelEnv === 'preview') {
    console.log("preview");
    baseURL = devURL;
    segmentKey = "";
} else if (env === 'production') {
    console.log("production");
    baseURL = prodURL;
    segmentKey = "GDMzt3cUzPhxbfFrY9MMO917e5aro1D4";
} else {
    console.log("development");
    baseURL = devProxy;
    segmentKey = "0DcYXwxdPpkincCrXLwJ5FQMQqGe1REB";  // This will need to be changed every 14 days
}

console.log("PROCESS:", process.env.NODE_ENV, process.env.STAGING_ENVIRONMENT, process.env, baseURL);

export default axios.create({baseURL: baseURL + 'api'});
export {segmentKey};
