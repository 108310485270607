import React, {Component} from "react";
import SvgPlus from "../../components/icons/SvgPlus";
import FadeInOnScroll from "../../components/atoms/FadeInOnScroll";


class NewJobOrEventCard extends Component {
    render() {
        return (
            <FadeInOnScroll triggerImmediately delay={(this.props.index * 100) + 10}>
                <div
                    onClick={this.props.onClick}
                    className='
                            text-primary group flex flex-col items-center justify-center h-full cursor-pointer
                            hover:bg-white/25  hover:shadow-lg hover:shadow-slate-200/40
                            p-5 border border-slate-200 hover:border-primary
                            rounded-xl
                            outline-transparent hover:outline-slate-200 outline outline-offset-2
                            '>
                    <div
                        className='flex flex-col items-center justify-center transform group-hover:translate-y-[-4px]'
                        style={{transition: '150ms ease-in-out'}}
                    >
                        <SvgPlus className={'w-10 h-10 transform group-hover:rotate-90'}
                                 style={{transition: '150ms ease-in-out'}}/>
                        <p className='text-md font-semibold mt-3'>
                            {this.props.label}
                        </p>
                    </div>
                </div>
            </FadeInOnScroll>
        )
    }
}

export default NewJobOrEventCard
