import React, {PureComponent} from "react";
import Dropzone from 'react-dropzone'
import {connect} from "react-redux";
import * as XLSX from 'xlsx';
import {uploadRoster} from "../../api/student/users";
import SvgCheck from "../icons/SvgCheck";
import Spinner from "../Spinner";
import TextBlockShimmer from "../loading/TextBlockShimmer";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

Dropzone.autoDiscover = false;

class ClubPageUploadRosterWidget extends PureComponent {
    state = {
        materials: [],
        buttonMessage: "Upload Roster",
        disabled: false,
        error: "",
        processing: false,
        emailUsers: true,
    };

    onDrop = async (acceptedFiles) => {
        this.setState({error: ""})
        this.parseRoster(acceptedFiles[0]);

    }

    parseRoster = (file) => {
        const reader = new FileReader();
        reader.onload = (evt) => {
            try {
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, {type: 'binary'});
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // update to csv
                const data = XLSX.utils.sheet_to_csv(ws, {header: 1});
                this.uploadRoster(data);
            } catch (e) {
                this.setState({error: "Please upload a XLSX file."});
            }

        };
        reader.readAsBinaryString(file);
    }

    uploadRoster = async (data) => {
        const headers = data.split(/\r?\n/)[0];
        if (headers.startsWith('Name,Email,Grad Year,Club Position')) {
            this.setState({processing: true});
            await uploadRoster(this.props.authUser, this.props.club.id, data, this.state.emailUsers);
            // refresh club data
            this.props.getClubInfo();
            this.setState({buttonMessage: "Uploaded!", disabled: true});
        } else {
            this.setState({error: "File must have first row: Name, Email, Grad Year, Club Position. Please reformat and submit again."});
        }
    }

    render() {
        if (this.props.loading) {
            return (
                <TextBlockShimmer/>
            )
        }
        if (this.state.processing || this.props.club.pending) {
            return (
                <div className='card pb-10'>
                    <h3 className='mt-0 title2 text-neutral-900 mb-2'>
                        Roster uploading
                    </h3>
                    <p className='body2 base-black-70 mb-4'>
                        Your roster upload is being processed. Please check back in a few minutes. If the roster does not appear, please reach out to us at <a href={"mailto:admin@recruitu.io"} className='primary'>admin@recruitu.io</a>.
                    </p>
                    <Spinner/>
                </div>
            )
        }
        return (
            <div className='card mb-0'>
                <h3 className='mt-0 title2 text-neutral-900 mb-2'>
                    Upload roster
                </h3>
                <p className='body2 base-black-70 mb-4'>
                    Upload your roster in XLSX format with columns: Name, Email, Grad Year, and Club Position. Make sure all emails are official .EDU emails. Download
                    the following template to get started:
                </p>
                <div className='flex flex-row items-center justify-center mb-3'>
                    <img className={'w-1/3 scale-[1.5] relative z-[0]'}
                         src={require('../../img/illustrations/techny-data-dashboard.gif')}/>
                </div>
                <a href={"https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/assets%2Ftemplate.xlsx?alt=media&token=dc822006-4f51-4744-ac87-d2fd918b7546"}
                   className='secondary-button body1-bold w-full relative mb-4'
                >
                    Download Template
                </a>
               
                <Dropzone onDrop={this.onDrop}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <button className='primary-button body1-bold w-full' style={{flex: 1}}
                                    disabled={this.state.disabled}>
                                {this.state.buttonMessage}
                            </button>
                        </div>
                    )}
                </Dropzone>
                <div className='row-ac-jb mt-[6px]'>
                    <div className=''>
                        <p className="text-red-500 body3">
                            {this.state.error}
                        </p>
                    </div>
                </div>

                <div className='flex flex flex-row items-center gap-1 mt-12'>
                <div
                    onClick={() => this.setState({emailUsers: !this.state.emailUsers})}
                    className={`${this.state.emailUsers ? 'checkbox-active' : 'checkbox border-neutral-200'} cursor-pointer`}>
                    {this.state.emailUsers &&
                        <SvgCheck className={'w-4 h-4 base-white-100'}/>
                    }
                </div>
                    <p className='body1 text-slate-400'>
                        Notify all users 
                    </p>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(ClubPageUploadRosterWidget);
