import React, {useEffect, useState} from 'react';
import OnboardingStepHeader from './OnboardingStepHeader';
import OnboardingStepWrapper from './OnboardingStepWrapper';
import OnboardingStepButtons from './OnboardingStepButtons';
import InputWrapper from '../atoms/inputs/InputWrapper';
import {validateEmail} from '../../utils/strings';

function OnboardingProfileStep({goNextStep, goPreviousStep, onProfileSubmit, error}) {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    useEffect(() => {
        if (formData.firstName && formData.lastName && formData.email && formData.password && formData.confirmPassword) {
            validateForm();
        }
    }, [formData]);

    const validateForm = () => {
        let formErrors = {};

        if (!formData.firstName) formErrors.firstName = "First name is required";
        if (!formData.lastName) formErrors.lastName = "Last name is required";
        if (!formData.email) formErrors.email = "Email is required";
        if (!validateEmail(formData.email)) formErrors.email = "Email must be an official .edu address";
        if (!formData.password) formErrors.password = "Password is required";
        setErrors(formErrors);
        setIsFormValid(Object.keys(formErrors).length === 0);
    };

    const handleSubmit = () => {
        if (isFormValid) {
            onProfileSubmit(formData);
        }
    };
    return (
        <OnboardingStepWrapper>
            <OnboardingStepHeader
                title={'Create your RecruitU profile'}
                description={'Get set up with RecruitU, the best platform for managing your job search'}
            />
            <div className='flex flex-col gap-5'>
                <div className='grid grid-cols-2 gap-5'>
                    <InputWrapper
                        label='First Name'
                        type='text'
                        name='firstName'
                        placeholder='First name'
                        value={formData.firstName}
                        onChange={handleChange}
                        error={errors.firstName}
                    />
                    <InputWrapper
                        label='Last Name'
                        type='text'
                        name='lastName'
                        placeholder='Last name'
                        value={formData.lastName}
                        onChange={handleChange}
                        error={errors.lastName}
                    />
                </div>
                <div className='-mb-1'>
                    <InputWrapper
                        label='Student Email'
                        type='email'
                        name='email'
                        placeholder='Student email'
                        value={formData.email}
                        onChange={handleChange}
                        error={errors.email}
                    />
                    {/* <div className='h-2 mt-1'>
                        {(formData.email.includes('@') && (!validateEmail(formData.email))) &&
                            <p className='text-xs text-red-500'>
                                Email must be an official .edu address
                            </p>
                        }
                    </div> */}
                </div>
                <InputWrapper
                    label='Password'
                    type='password'
                    name='password'
                    placeholder='Password'
                    value={formData.password}
                    onChange={handleChange}
                    error={errors.password}
                />
                <div>
                    <InputWrapper
                        label='Confirm Password'
                        type='password'
                        name='confirmPassword'
                        placeholder='Confirm password'
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        error={errors.confirmPassword}
                    />
                    <div className='h-2 mt-1'>
                        {(formData.confirmPassword && (formData.confirmPassword !== formData.password) && !formData.password.length < 8) &&
                            <p className='text-xs text-red-500'>
                                Passwords must match and be at least 8 characters long
                            </p>
                        }
                    </div>
                </div>
                <p className='text-xs text-slate-500'>
                    By signing up you agree to our <a href={'https://recruitu.com/terms-and-conditions'} target={"_blank"}
                                                      rel="noreferrer" className='text-primary'>Terms of Use</a> and <a
                    href={'https://recruitu.com/privacy-policy'} target={"_blank"} rel="noreferrer" className='text-primary'>Privacy
                    Policy</a>.
                </p>
            </div>
            <OnboardingStepButtons
                goNextStep={handleSubmit}
                goPreviousStep={goPreviousStep}
                isNextDisabled={!isFormValid}
                nextLabel="Create account"
            />
            {!!error &&
                <div className='h-2 mt-1'>
                    <p className='text-xs text-red-500'>
                        {error}
                    </p>
                
            </div>
            }
        </OnboardingStepWrapper>
    )
        ;
}

export default OnboardingProfileStep;
