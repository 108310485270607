import React, {Component} from "react";
import {DateTime} from 'luxon';
import zerostate from '../../img/zerostates/companyupdates.png'

class UpgradedCompanyPageUpdates extends Component {

    render() {
        const company = this.props.company || {};
        const updates = company.updates || [];
        return (
            <div
                className='bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl'>
                <div className='flex flex-row items-center gap-2 mb-2'>
                    <p className='text-xl font-semibold text-slate-800'>
                        Updates
                    </p>
                </div>
                <p className='text-slate-500 text-md mb-5'>
                    Stay in the know to start your journey at {company.name}
                </p>
                {updates.length === 0 &&
                    <>
                        <div className='w-full relative' >
                            <img src={zerostate} className='w-full blur-sm mb-5 saturate-50 opacity-50'/>
                            <div
                                className='absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center'>
                            </div>
                        </div>
                    </>
                }
                <div className='grid grid-cols-3 gap-3 mt-5'>
                    {updates.map((item, index) =>
                        <div key={item} className='rounded-lg relative border border-slate-100 p-3 gap-3 flex flex-col'>
                            <img
                                src={item.cover_photo}
                                className='object-cover mt-1 w-full h-32 aspect-square bg-slate-200 rounded-lg'
                            />
                            <div className='flex flex-col gap-2'>
                                <div className='flex flex-col'>
                                    <p className='text-xs text-primary font-semibold'>
                                        {DateTime.fromISO(item.date).isValid ? DateTime.fromISO(item.date).toRelative() + " - " : ""}{item.category}
                                    </p>
                                    <p className='text-md text-slate-800 font-semibold'>
                                        {item.title}
                                    </p>
                                    <p className='text-sm text-slate-500'>
                                        {item.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default UpgradedCompanyPageUpdates;
