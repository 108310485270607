import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {editCompany} from "../../api/recruiter/companies";
import {connect} from "react-redux";
import TagComponent from "../../components/atoms/TagComponent";
import {Button} from "../../components/atoms/Button";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class RecruiterCompanyEditPerks extends Component {
    state = {
        perk: "",
        perks: [],
    }

    perkChanged = e => this.setState({perk: e.target.value});


    updatePerks = () => {
        const perk = this.state.perk;
        let newPerks = [...this.state.perks];
        newPerks.push(perk);
        this.setState({perks: newPerks, perk: ""});
    }

    deletePerk = (perk) => {
        let newPerks = [...this.state.perks];
        const index = this.state.perks.indexOf(perk);
        newPerks.splice(index, 1);
        this.setState({perks: newPerks, perk: ""});
    }

    save = async () => {
        await editCompany(this.props.authUser, {perks: this.state.perks});
        this.props.closeModal();
        window.location.reload()
        window.analytics.track("Recruiter Edit Company Perks");
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.company !== prevProps.company) {
            this.setInfo();
        }
    }

    componentDidMount = () => {
        this.setInfo();
    }

    setInfo = () => {
        const company = this.props.company || {};
        const perks = company.perks || [];
        this.setState({perks})
    }

    render() {
        const saveEnabled = this.props.company.perks !== this.state.perks;
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Edit company perks'}
                headerToggle
                size={'md'}
                toggle={this.props.closeModal}
                showScrollBar
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={this.props.closeModal} variant='secondary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={!saveEnabled}
                            onClick={this.save}>
                            Save Changes
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div className={`flex flex-col gap-1.5 mb-5`}>
                    <div className='items-center flex flex-row flex-wrap gap-1'>
                        {this.state.perks.map(tag =>
                            <TagComponent
                                label={tag}
                                type={'job-type'}
                                removable
                                deleteTag={this.deletePerk}
                            />
                        )}
                    </div>
                    <div className="col-plain mt-2">
                        <p className="body3-bold text-neutral-400 mb-1">
                            Perk name
                        </p>
                        <div className='flex flex-row w-full items-center gap-2'>
                            <input
                                value={this.state.perk}
                                style={{width: 300}}
                                onChange={this.perkChanged}
                                placeholder={'Enter perk'}
                                className='input-light px-3 body2 base-black-50 flex-1'
                            />
                            <Button
                                disabled={this.state.perk === ''}
                                onClick={this.updatePerks}
                            >
                                Add perk
                            </Button>
                        </div>
                    </div>
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(RecruiterCompanyEditPerks);
