import React, {PureComponent} from "react";
import NetworkingListItem from "../jobpage/NetworkingListItem";
import {wait} from "@testing-library/user-event/dist/utils";
import {withRouter} from "react-router-dom";
import ManualContactEntry from "../atoms/modals/ManualContactEntry";
import {SIDEBAR_CARD} from "../../utils/dummy";

class CompanyPageNetworkingWidget extends PureComponent {
    state = {
        newClubModalVisible: false,
        clubSubmitSuccessVisible: false,
    };

    closeNewClubModal = () => {
        this.setState({newClubModalVisible: false})
    }

    closeClubSubmitSuccesfulModal = () => {
        this.setState({clubSubmitSuccessVisible: false})
    }

    submitClub = () => {
        this.closeNewClubModal()
        wait(300).then(() =>
            this.setState({clubSubmitSuccessVisible: true})
        )
    }

    render() {
        const company = this.props.company;
        if (this.props.people.length === 0) {
            return (
                <div className={`flex flex-col justify-between items-start card`}>
                    <div className='mb-5'>
                        <h3 className='mt-0 title2 text-slate-800 mb-2'>
                            Start networking
                        </h3>
                        <p className='body2 text-slate-500 opacity-50'>
                            Add a contact you know from this company to get started!
                        </p>
                    </div>
                    <ManualContactEntry updateNetwork={this.props.updateNetwork} history={this.props.history} light/>
                    {/* <button className='primary-button-pill bg-white border hover:bg-white hover:bg-opacity-80'
                            onClick={() => this.setState({newClubModalVisible: true})}>
                        <SvgAddCircle className={'w-4 h-4 mr-2'}/>
                        <span className='body3-bold'>
                            New Club
                        </span>
                    </button>
                    <NewClubModal open={this.state.newClubModalVisible} submitClub={this.submitClub}
                                  close={this.closeNewClubModal}/>
                    <ClubSumbitSuccess open={this.state.clubSubmitSuccessVisible}
                                       close={this.closeClubSubmitSuccesfulModal}/> */}
                </div>
            )
        }
        return (
            <div className='card'>
                <>
                    <h3 className='mt-0 title2 text-neutral-900 mb-2'>
                        Start networking
                    </h3>
                    <p className='body2 base-black-70 mb-4'>
                        Add select employees to your network dashboard and start tracking who you've
                        connected with.
                    </p>
                    <div className='grid grid-cols-2 gap-4'>
                        {this.props.people.map(person =>
                            <NetworkingListItem person={person}/>
                        )}
                    </div>
                </>
            </div>
        )
    }
}

export default withRouter(CompanyPageNetworkingWidget);
