import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'} d="M323-205l157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178zM233-80l65-281L80-550l288-25 112-265 112 265 288 25-218 189 65 281-247-149L233-80zm247-355z" />
        </svg>
    )
}

export default SvgComponent
