import React, {useState} from 'react';
import SelectableItem from "../onboarding/SelectableItem";
import {POPULAR_COMPANIES} from "../onboarding/onboarding-constants";// Replace with actual company list
import WrappedTextInput from '../atoms/WrappedTextInput';
import SvgCompanyBuilding from '../icons/SvgCompanyBuilding';
import CollapseComponent from '../atoms/CollapseComponent';
import FadeInOnScroll from '../atoms/FadeInOnScroll';
import CompanyLogo from '../atoms/CompanyLogo';
import {searchCompanies} from "../../utils/typesense";
import SelectedTag from "../onboarding/SelectedTag";
import EditProfileWrapper from "./EditProfileWrapper";
import EditProfileCtaButton from "./EditProfileCtaButton";
import { toast } from 'react-toastify';
import useUser  from '../../hooks/useUser';
import { createTracker, deleteTracker, getUserTrackers } from '../../api/student/trackers';
import { useDispatch } from 'react-redux';
function EditProfileCompanies({trackers, goToNextSection, hasNextSection, loading, setLoading}) {
    const { authUser } = useUser();
    const dispatch = useDispatch();
    const [selectedCompanies, setSelectedCompanies] = useState(() => {
        return Object.values(trackers ?? {}).filter(tracker => tracker.type === "company").map(tracker => tracker.company);
    });
    const [sortOrder, setSortOrder] = useState('popularity');
    const [companySearch, setCompanySearch] = useState('');
    const [openCollapse, setOpenCollapse] = useState(false);
    const [companySearchResults, setCompanySearchResults] = useState([]);

    const setTrackers = (trackers) => {
        dispatch({
            type: 'SET_TRACKERS',
            trackers
        });
    }

    const handleCompanyClick = (company) => {
        const selectedCompanyIds = selectedCompanies.map(company => company.id);
        if (selectedCompanyIds.includes(company.id)) {
            setSelectedCompanies(selectedCompanies.filter(item => item.id !== company.id));
        } else {
            setSelectedCompanies([...selectedCompanies, company]);
        }
    };

    const handleSortChange = (e) => {
        setSortOrder(e.target.value);
    };

    const sortedCompanies = [...POPULAR_COMPANIES].sort((a, b) => {
        if (sortOrder === 'alphabetical') {
            return a.name.localeCompare(b.name);
        }
        return 0; // Replace with actual popularity sorting logic if available
    }).map(company => ({
        ...company,
        // cast to string to avoid type issues
        id: company.id.toString()
    }));

    const handleSearchChange = (event) => {
        const text = event.target.value;
        setCompanySearch(text);

        if (text === '') {
            setOpenCollapse(false);
            setCompanySearchResults([]);
            return;
        }

        setOpenCollapse(true);
        companySearchChanged(text);
    };

    const companySearchChanged = async (text) => {
        const companySearchResults = await searchCompanies(text);
        setCompanySearchResults(companySearchResults)
    };

    const updateCompanies = async () => {
        try {
            setLoading(true);
          
            await Promise.all([
                // Handle existing trackers - delete if not selected anymore
                ...Object.entries(trackers)
                    .filter(([id, tracker]) => tracker.type === "company")
                    .map(([companyId, tracker]) => {
                        const stillSelected = selectedCompanies.find(company => company.id === companyId);
                        if (!stillSelected) {
                            return deleteTracker(authUser, tracker.id);
                        }
                        return Promise.resolve();
                    }),
                // Handle new selections - create tracker if not already tracked
                ...selectedCompanies.map(company => {
                    const alreadyTracked = !!trackers[company.id];
                    
                    if (!alreadyTracked) {
                        return createTracker(authUser, {
                            type: "company", 
                            company_id: company.id
                        });
                    }
                    return Promise.resolve();
                })
            ]);
            const { trackers: newTrackers } = await getUserTrackers(authUser);
            if (!!newTrackers) {
                setTrackers(newTrackers);
            }
            if (hasNextSection) {
                goToNextSection();
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = async () => {
        if (!selectedCompanies.length) {
            toast.error('Please select at least one company');
            return;
        }
        toast.promise(updateCompanies, {
            pending: 'Updating companies...',
            success: 'Companies updated successfully!',
            error: 'Failed to update companies'
        });
    }
    return (
        <>
            <EditProfileWrapper>
                <div className='flex flex-col gap-3 md:gap-5'>
                    <p className='text-lg md:text-xl font-semibold'>Your picks</p>
                    <div className='flex flex-wrap gap-2'>
                        <p className='text-slate-500 hidden only:flex text-sm md:text-base'>
                            Select companies below...
                        </p>
                        {selectedCompanies.map(company => (
                            <SelectedTag
                                key={company.id}
                                label={company.name}
                                onClose={() => handleCompanyClick(company)}
                            />
                        ))}
                    </div>
                    <div className='relative'>
                        <WrappedTextInput
                            type="text"
                            placeholder={'Search any company...'}
                            className='pl-44 input-light px-3 body2 w-full'
                            value={companySearch}
                            onChange={handleSearchChange}
                            icon={
                                <SvgCompanyBuilding
                                    width={20}
                                    height={20}
                                    className={'absolute text-slate-500'}
                                    style={{left: 12, top: 10}}
                                />
                            }
                        />
                        <div className='absolute top-12 left-0 right-0 z-[99]'>
                            <CollapseComponent
                                isOpen={companySearch.length !== 0 && openCollapse}
                                className='bg-white shadow-lg border overflow-hidden border-neutral-200 rounded-lg'
                            >
                                {companySearchResults.map((res) => (
                                    <FadeInOnScroll triggerImmediately delay={10} key={res.city}>
                                        <div
                                            onClick={() => {
                                                handleCompanyClick(res);
                                                setCompanySearch('');
                                                setOpenCollapse(false);
                                            }}
                                            className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 px-2'
                                        >
                                            <CompanyLogo id={res.id} className={'w-12 h-12'}/>
                                            <div className='flex flex-col'>
                                                <p className='text-sm text-slate-800 line-clamp-2'>
                                                    {res.name}
                                                </p>
                                            </div>
                                        </div>
                                    </FadeInOnScroll>
                                ))}
                            </CollapseComponent>
                        </div>
                    </div>
                    <div className='flex flex-row items-center justify-between'>
                        <p className='text-lg md:text-xl font-semibold'>Popular Companies</p>
                        <select onChange={handleSortChange} value={sortOrder} className='text-xs md:text-sm font-medium'>
                            <option value="popularity">Sort by popularity</option>
                            <option value="alphabetical">Sort alphabetically</option>
                        </select>
                    </div>
                    <div
                        className='grid grid-cols-2 md:grid-cols-3 gap-2 pb-32  overflow-y-scroll border-b border-slate-200'>
                        {sortedCompanies.map(({id, name}) => (
                            <SelectableItem
                                key={`${id}`}
                                label={name}
                                append={
                                    <CompanyLogo id={id} className={'w-8 h-8'}/>
                                }
                                value={name}
                                isSelected={!!selectedCompanies.find(company => company.id === id)}
                                onClick={() => handleCompanyClick({id, name})}
                            />
                        ))}
                    </div>
                </div>
            </EditProfileWrapper>
            <EditProfileCtaButton onClick={handleSubmit} disabled={loading}>
                {hasNextSection ? 'Save & Continue' : 'Save'}
            </EditProfileCtaButton>
        </>
    );
}

export default EditProfileCompanies;
