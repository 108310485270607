import React, { PureComponent } from "react";

class WrappedTextInput extends PureComponent {
    render() {
        return (
            <div className="flex flex-col gap-1.5 text-sm">
                {this.props.outerLabel && (
                    <div className="flex flex-row items-center gap-1">
                        {this.props.outerLabel && (
                            <div className="text-xs font-medium text-slate-500">
                                {this.props.outerLabel}
                            </div>
                        )}
                    </div>
                )}
                <div className="flex flex-col gap-0.5">
                    <div
                        className={`text-input-wrapper ${
                            this.props.disabled && "cursor-not-allowed"
                        } disabled:bg-slate-200`}
                    >
                        {this.props.textarea ? (
                            <textarea className={`${this.props.className} pt-3`} {...this.props} min={0} style={{paddingTop:8, minHeight:100}} />
                        ) : (
                            <input
                                type={this.props.type || "text"}
                                {...this.props}
                                min={0}
                            />
                        )}
                        {this.props.icon}
                    </div>
                    {!!this.props.subText && (
                        <p className="text-xs text-slate-500">{this.props.subText}</p>
                    )}
                </div>
            </div>
        );
    }
}

export default WrappedTextInput;
