import React, {PureComponent} from "react";
import TagComponent from "../../components/atoms/TagComponent";
import SvgPin from "../../components/icons/SvgPin";
import SvgBriefcase from "../../components/icons/SvgBriefcase";
import SvgArrow from "../../components/icons/SvgArrow";
import SvgCalendar from "../../components/icons/SvgCalendar";
import SvgVideo from "../../components/icons/SvgVideo";
import SvgCheck from "../../components/icons/SvgCheck";
import RecruiterPeekEditStatus from "../modals/RecruiterPeekEditStatus";
import {Link} from "react-router-dom";
import { DateTime } from 'luxon';

class RecruiterStudentPeekEventItem extends PureComponent {
    state = {
        notes: '',
        editStatusModalOpen: false
    }

    openEditStatusModal = () => {
        this.setState({editStatusModalOpen: true})
    }
    closeEditStatusModal = () => {
        this.setState({editStatusModalOpen: false})
    }

    notesChanged = e => this.setState({notes: e.target.value});

    render() {
        const event = this.props.event;
        let date = null;
        if (!!event.event_date) {
            date = DateTime.fromISO(event.event_date);
        }
        return (
            <Link to={{pathname: `/event-listing/${event.id}`, state: {name: event.name}}}>
                <div className='flex flex-col bg-slate-50 group hover:bg-slate-100 rounded-lg p-5 cursor-pointer'>
                    <div className='flex flex-row justify-between'>
                        <div>
                            <div>
                                <div className='flex flex-row text-primary items-center gap-1.5'>
                                    <SvgCalendar className={'w-3.5 h-3.5'}/>
                                    <p className='text-sm text-primary font-semibold'>
                                        {event.industry} {!!event.class_years && event.class_years.length ? " - Class of " + event.class_years.map(year => "'" + year.slice(2)) : null}
                                    </p>
                                </div>
                            </div>
                            <p
                                onClick={() => null}
                                className='text-lg font-semibold text-slate-800 group-hover:text-primary cursor-pointer'>
                                {event.name} <SvgArrow className={'inline w-5 h-5 mb-0.5'}/>
                            </p>
                            {!!event.location ?
                                <div className='flex flex-row items-center gap-2 text-slate-500 wrap'>
                                    <div className='flex flex-row items-center gap-1'>
                                        {event.location === "Remote" ? <SvgVideo className={'w-3.5 w-3.5'}/> :
                                            <SvgPin className={'w-3.5 w-3.5'}/>}
                                        <p className='text-md '>
                                            {event.location}
                                        </p>
                                    </div>
                                </div>
                                : null}
                        </div>
                        <div className='flex flex-col items-end'>
                            <div
                                className='flex flex-col items-center rounded-lg border-neutral-200 bg-white border overflow-hidden'>
                                <p className='body4-bold uppercase p-0.5 px-2 bg-neutral-100'>
                                    {date.toFormat("MMM")}
                                </p>
                                <p className='body2-bold p-0.5'>
                                    {date.toFormat("d")}
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className='mt-3'>
                        <label htmlFor="message" className="block mb-2 body2-bold text-neutral-400 dark:text-white">
                            Notes
                        </label>
                        <textarea id="message" rows="4"
                                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="Enter private notes for your recruiting team..." onChange={this.notesChanged}
                                  value={this.state.notes}></textarea>
                    </div>
                    <div className='flex flex-row items-center gap-8 mt-3'>
                        <div className='ml-auto'>
                            <p className='text-xs font-semibold text-slate-500'>
                                Status:
                            </p>
                            <p className='text-md text-slate-800'>
                                Attended
                            </p>
                        </div>
                        <button onClick={this.openEditStatusModal} className='primary-button body1-bold'>
                            Update Status
                        </button>
                    </div>
                    <RecruiterPeekEditStatus
                        type={'event'}
                        isOpen={this.state.editStatusModalOpen}
                        closeModal={this.closeEditStatusModal}
                    /> */}
                </div>
            </Link>
        )
    }
}

export default RecruiterStudentPeekEventItem;
