import React, {Component} from "react";
import {page_h_padding_class} from "../../utils/constants";


class PageLTRWrapper extends Component {
    render() {
        return (
            <div className={`page-body-a ${page_h_padding_class} gap-3 overflow-hidden bg-white ${this.props.className}`} style={{marginRight:-12, ...this.props.style}}>
                {this.props.children}
            </div>
        )
    }
}

export default PageLTRWrapper
