import * as React from "react";

const SvgStars = (props) => (
    <svg
        width={20}
        height={20}
        fill="none"
        viewBox={'0 0 20 20'}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m16.217 6.667.658-1.459 1.458-.658a.417.417 0 0 0 0-.758l-1.458-.659-.658-1.466a.417.417 0 0 0-.759 0L14.8 3.125l-1.467.658a.417.417 0 0 0 0 .759l1.459.658.658 1.467c.15.325.617.325.767 0Zm-6.634 1.25L8.258 5c-.291-.65-1.225-.65-1.516 0L5.417 7.917 2.5 9.242c-.65.3-.65 1.225 0 1.516l2.917 1.325L6.742 15c.3.65 1.225.65 1.516 0l1.325-2.917 2.917-1.325c.65-.3.65-1.225 0-1.516L9.583 7.917Zm5.867 5.416-.658 1.459-1.459.658a.417.417 0 0 0 0 .758l1.459.659.658 1.466c.15.325.608.325.758 0l.659-1.458 1.466-.658a.417.417 0 0 0 0-.759l-1.458-.658-.658-1.467a.423.423 0 0 0-.767 0Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgStars;
