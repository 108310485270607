import React, {PureComponent} from "react";
import SvgArrowDropDown from "../icons/SvgArrowDropDown";
import DropdownComponent from "./DropdownComponent";
import SvgClose from "../icons/SvgClose";
import SelectComponentBody from "./SelectComponentBody";

class SelectComponent extends PureComponent {
    render() {
        return (
            <div className={`flex flex-col gap-1.5 ${this.props.className}`}>
                {this.props.outerLabel &&
                    <div className='text-xs font-medium text-slate-500'>
                        {this.props.outerLabel}
                    </div>
                }
                <DropdownComponent
                    ToggleComponent={
                        <SelectComponentBody
                            value={this.props.value}
                            label={this.props.label}
                            light={this.props.light}
                            clearFilter={this.props.clearFilter}
                        />
                    }
                    scrollable={this.props.scrollable}
                    value={this.props.value}
                    selectDropdown
                    stateName={this.props.stateName}
                    setValue={this.props.setValue}
                    options={this.props.options}
                    light={this.props.light}
                    disabled={this.props.disabled}
                    node={this.props.node}
                />
            </div>
        )
    }
}

export default SelectComponent
