import axios from "../axios";

export const getRecruiterJobApplications = async (user, job_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {job_id}
        };
        const res = await axios.get("/recruiter/applications", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getUserCompanyApplications = async (user, user_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {user_id}
        };
        const res = await axios.get("/recruiter/applications/user/company", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}
