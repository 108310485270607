import React, {PureComponent} from "react";

import {primary} from "../../utils/colors";
import SvgCheck from "../icons/SvgCheck";
import SvgClose from "../icons/SvgClose";

class ToggleButton extends PureComponent {
    render() {
        return (
            <button className='togglebutton'
                    onClick={this.props.onClick}
                    type="button"
            >
                <div style={{backgroundColor: this.props.active ? primary : '#00000030'}}
                     className='togglebutton-innercontainer'>
                    <div style={{left: this.props.active ? 23 : 2}} className='row-ac-jc togglebutton-circle'>
                        {this.props.active ?
                            <SvgCheck fill={primary} width={15} height={15}/>
                            :
                            <SvgClose fill={'#00000030'} width={18} height={18}/>
                        }
                    </div>
                </div>
            </button>
        )
    }
}


export default ToggleButton;
