import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {connect} from "react-redux";
import {Button} from "../../components/atoms/Button";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class FreeTextInputModal extends Component {


    render() {
        const question = this.props.question
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={this.props.title}
                headerToggle
                size={'lg'}
                toggle={this.props.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={this.props.closeModal} variant='secondary'>
                            Close
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div className={`flex flex-col gap-1.5 whitespace-pre-line`}>
                    {question.answer_1 || 'No answer has been added to this question...'}
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(FreeTextInputModal);
