import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'} d="M566-80v-60h214v-430H180v220h-60v-410q0-24 18-42t42-18h65v-60h65v60h340v-60h65v60h65q24 0 42 18t18 42v620q0 24-18 42t-42 18H566zM320-14l-42-42 113-114H50v-60h341L278-344l42-42 186 186L320-14zM180-630h600v-130H180v130zm0 0v-130 130z" />
        </svg>
    )
}

export default SvgComponent
