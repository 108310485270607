import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'}
                  d="M180-81q-24 0-42-18t-18-42v-603h60v603h474v60H180zm120-120q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h440q24 0 42 18t18 42v560q0 24-18 42t-42 18H300zm0-60h440v-560H300v560zm0 0v-560 560z"/>
        </svg>
    )
}

export default SvgComponent
