import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="30px"
            height="30px"
            {...props}
            fill={'currentColor'}
        >
            <path
                d="M13 3a1.99 1.99 0 00-1.414.586l-9 9a2 2 0 000 2.828l1.562 1.563a3.952 3.952 0 011.024-1.805l9-9A3.971 3.971 0 0117 5h5.445C21.752 3.809 20.477 3 19 3h-6zm4 4a1.99 1.99 0 00-1.414.586l-9 9a2 2 0 000 2.828l8 8a2 2 0 002.828 0l9-9A1.99 1.99 0 0027 17v-6a4 4 0 00-4-4h-6zm6 2a2 2 0 11.001 3.999A2 2 0 0123 9z"/>
        </svg>
    )
}

export default SvgComponent
