import React, {PureComponent} from "react";
import {ToastContainer} from "react-toastify";

class ToastComponent extends PureComponent {
    render() {
        return (
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        )
    }
}

export default ToastComponent
