import React, {PureComponent} from "react";
import ClubPageUploadRosterWidget from "../ClubPageUploadRosterWidget";
import Spinner from "../../Spinner";
import ClubPageStatisticsWidget from "../ClubPageStatisticsWidget";

class ClubAdminTab extends PureComponent {
    state = {
        statistics: {},
        statisticsLoading: true,
    }

    render() {
        const {club, loading, getClubInfo, statistics} = this.props;
        const user = this.props.user || {};
        return (
            <div className='flex flex-col flex-1 overflow-scroll gap-3 px-5 items-center'>
                <div className='max-w-[900px] mt-5'>
                    <div className='card gap-3 flex flex-col'>
                        <h3 className={`mt-0 title2 text-neutral-900`}>
                            Need help?
                        </h3>
                        <p className='text-slate-700'>
                            We strive to give clubs the best experience possible. If you need help or have questions on
                            things like roster management, your club feed and alumni, or anything else - don't hesitate to reach out.
                        </p>
                    </div>
                    <div className='flex flex-row gap-3'>
                        <div className='flex-1 flex flex-col items-stretch'>
                            {this.state.loading ?
                                <div className='flex flex-col flex-1 items-center justify-center'>
                                    <Spinner />
                                </div>
                                :
                                <ClubPageStatisticsWidget
                                    stats={statistics}
                                    title={'Club statistics'}
                                    className={'flex-1'}
                                />
                            }
                        </div>
                        <div className='flex-1'>
                            <ClubPageUploadRosterWidget club={club} getClubInfo={getClubInfo} loading={loading}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ClubAdminTab;
