import React, {PureComponent} from "react";
import {connect} from "react-redux";
import ProfilePageTitleWidget from "../profilepage/ProfilePageTitleWidget";
import ProfilePageAboutWidget from "../profilepage/ProfilePageAboutWidget";
import ProfilePageExperienceWidget from "../profilepage/ProfilePageExperienceWidget";
import ProfilePageEducationWidget from "../profilepage/ProfilePageEducationWidget";
import ProfilePageClubsWidget from "../profilepage/ProfilePageClubsWidget";
import PageContentWrapper from "../wrappers/PageContentWrapper";
import PageLTRWrapper from "../wrappers/PageLTRWrapper";
import PageRightWrapper from "../wrappers/PageRightWrapper";
import PageLeftWrapper from "../wrappers/PageLeftWrapper";
import ReferralWidget from "../ReferralWidget";
import ProfileMyPreferencesSection from "../profilepage/ProfileMyPreferencesSection";

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
    }
}

class ProfilePage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            height: 0
        };
    }

    componentDidMount() {
        window.analytics.page("My Profile Viewed");
    }

    render() {
        const user = this.props.user || {};
        const experiences = user.experiences || [];
        return (
            <PageContentWrapper>
                <PageLTRWrapper>
                    <PageLeftWrapper className={'overflow-y-scroll hide-scrollbar pt-5'}>
                        <ProfilePageTitleWidget user={user} />
                        <ProfilePageAboutWidget user={user} />
                        <ProfilePageExperienceWidget experiences={experiences} user={this.props.user}/>
                        <ProfilePageEducationWidget education={user.education} user={this.props.user}/>
                    </PageLeftWrapper>
                    <PageRightWrapper open>
                        <div className='flex flex-col flex-1 overflow-y-scroll pt-5'>
                            <ProfileMyPreferencesSection user={user}/>
                            <div className='mb-5'>
                                <ReferralWidget user={user}/>
                            </div>
                            <ProfilePageClubsWidget user={user}
                                                    openChooseClubsModal={() => this.setState({chooseClubsModalOpen: true})}/>
                            {/* <JobPageMatchWidget listing={listing}/> */}
                            {/*<JobPageCTAWidget listing={{company: {name: "Morgan Stanley", sector: "Financial Services"}}}/>*/}
                        </div>
                    </PageRightWrapper>
                </PageLTRWrapper>
            </PageContentWrapper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
