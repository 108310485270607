import React, {PureComponent} from "react";
import SvgArrowDropDown from "../icons/SvgArrowDropDown";
import SvgClose from "../icons/SvgClose";
import MultiSelectDropdownComponent from "./MultiSelectDropdownComponent";

class MultiSelectComponent extends PureComponent {
    render() {
        return (
            <div className={`flex flex-col gap-1.5 ${this.props.className}`}>
                {this.props.outerLabel &&
                    <div className='text-xs font-medium text-slate-500'>
                        {this.props.outerLabel}
                    </div>
                }
                <MultiSelectDropdownComponent
                    ToggleComponent={
                        <div
                            className={`${this.props.value.length > 0 ? 'pill-active border bg-white border-slate-200 hover:bg-slate-50 text-slate-800' : (this.props.light ? 'pill-light' : 'pill')} text-sm rounded-lg`}>
                            <div className='flex flex-row items-center'>
                                        <span className='mr-1 line-clamp-1 md:line-clamp-none'>
                                            {this.props.value.length === 0 && this.props.label}
                                            {this.props.value.length > 2 ?
                                                <>{this.props.value.length} selections</>
                                                :
                                                this.props.value.length > 1 ?
                                                    <>
                                                        <span className="md:hidden">
                                                            {this.props.value.length} selections
                                                        </span>
                                                        <span className="hidden md:inline">
                                                            {this.props.value[0]}, {this.props.value[1]}
                                                        </span>
                                                    </>
                                                    :
                                                    this.props.value[0]
                                            }
                                            </span>
                                {this.props.value.length > 0 &&
                                    <div className='text-slate-500 hover:text-red-400 cursor-pointer mr-2 mt-0.5' onClick={this.props.clearFilter}>
                                        <SvgClose className={'h-4 w-4'}/>
                                    </div>
                                }
                            </div>
                            <SvgArrowDropDown width={24} height={24}/>
                        </div>
                    }
                    value={this.props.value}
                    selectDropdown
                    setValue={this.props.setValue}
                    scrollable={this.props.scrollable}
                    options={this.props.options}
                    light={this.props.light}
                    node={this.props.node}
                    disabled={this.props.disabled}
                    useValue={this.props.useValue}
                />
                {this.props.accentText &&
                    <div className='text-xs font-medium text-slate-500'>
                        {this.props.accentText}
                    </div>
                }
            </div>
        )
    }
}

export default MultiSelectComponent
