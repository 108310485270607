import React, {PureComponent} from "react";
import { DIVERSITY_OPTIONS } from "../../utils/constants";

class ProfilePageDemographics extends PureComponent {

    render() {
        const user = this.props.user || {};
        const demographics = user.diversity || {};
        return (
            <div className='card'>
                <div className='flex flex-row items-center justify-between mb-4'>
                    <h3 className='mt-0 title2 text-neutral-900'>
                        {this.props.recruiter || this.props.studentProfilePage ? this.props.user.first_name + "\'s Employment Data" : "Your Employment Data"}
                    </h3>
                </div>
                <div>
                    {DIVERSITY_OPTIONS.map(option =>
                        <>
                            <div className='row-ac mb-2 hover:bg-neutral-100 px-1 py-1 rounded-lg' key={option.value}>
                                <div className='ml-3 flex flex-col gap-0 flex-1'>
                                    <p className='text-md font-semibold m-0 blacks-1'>
                                        {option.title}: {demographics[option.value]}
                                    </p>
                                    {/* <div className='text-xs text-slate-500 inline-flex'>
                                        <p>
                                            {club.college}
                                            {club.founded &&
                                                <span className='m-0 ml-4'>
                                                - Founded {club.founded}
                                            </span>
                                            }
                                        </p>
                                    </div> */}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    }
}

export default ProfilePageDemographics
