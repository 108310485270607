import React, {PureComponent} from "react";
import TextBlockShimmer from "../loading/TextBlockShimmer";
import SvgPlus from "../icons/SvgPlus";
import SvgEditFeather from "../icons/SvgEditFeather";
import FreeTextInputModal from "../modals/FreeTextInputModal";
import {connect} from "react-redux";
import { editClub } from "../../api/student/clubs";
import SvgVisibility from "../icons/SvgVisibility";
import SvgEyeOff from "../icons/SvgEyeOff";
import Tooltip from "../atoms/modals/Tooltip";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}


class ClubPageStepsToApplyWidget extends PureComponent {
    state = {
        applicationProcess: '',
        freeTextInputModalOpen: false,
    }

    componentDidMount = () => {
        this.setState({applicationProcess: this.props.club.application_process})

    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.club.application_process !== this.props.club.application_process) {
            this.setState({applicationProcess: this.props.club.application_process})
        }
    }

    openFreeTextInputModal = () => {
        this.setState({freeTextInputModalOpen: true})
    }

    closeFreeTextInputModal = () => {
        this.setState({freeTextInputModalOpen: false})
    }

    handleInputChange = (e) => {
        this.setState({applicationProcess: e.target.value});
    };

    save = async () => {
        await editClub(this.props.authUser, this.props.club.id, {
            application_process: this.state.applicationProcess
        });
        this.props.getClubInfo();
        window.analytics.track("Club Edited", {
            application_process: this.state.applicationProcess
        })
    }

    render() {
        const club = this.props.club;
        const hidden = this.props.hidden || [];
        return (
            <>
                {this.props.loading ?
                    <>
                        <TextBlockShimmer/>
                    </>
                    :
                    <div
                        className={`bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl mt-5 ${hidden.includes("application_process") && 'opacity-50'}`}>
                        <div className='flex flex-row items-center gap-2 mb-2'>
                            <p className='text-xl font-semibold text-slate-800'>
                                More about {club?.name}
                            </p>
                            {!this.props.loading && this.props.adminIds.includes(club.id) &&
                            <>
                                <button
                                    onClick={this.openFreeTextInputModal}
                                    className='text-slate-500 hover:text-primary cursor-pointer'>
                                    {club?.application_process?.length === 0 ?
                                        <SvgPlus className={'w-4 h-4'}/>
                                        :
                                        <SvgEditFeather className={'w-4 h-4'}/>
                                    }
                                </button>
                                <Tooltip
                                    toggleComponent={
                                        <button
                                            onClick={() => this.props.hide("application_process")}
                                            className='text-slate-500 hover:text-primary cursor-pointer'>
                                            {hidden.includes("application_process") ?
                                                <SvgVisibility className={'w-4 h-4'}/>
                                                :
                                                <SvgEyeOff className={'w-4 h-4'}/>
                                            }
                                        </button>
                                    }
                                    label={`${hidden.includes("application_process") ? "Show" : "Hide"} this section when recruiters are viewing your club.`}
                                />
                            </>
                        }
                        </div>
                        {club?.application_process ?
                            <p className='text-slate-500 text-md'>
                                {club?.application_process}
                            </p>
                            :
                            <p className='text-slate-500'>
                                No instructions added yet
                            </p>
                        }
                    </div>
                }
                <FreeTextInputModal
                    isOpen={this.state.freeTextInputModalOpen}
                    title={'Edit how to apply section'}
                    placeholder={'Let incoming students know exactly how they can apply to your club'}
                    value={this.state.applicationProcess}
                    handleInputChange={this.handleInputChange}
                    closeModal={this.closeFreeTextInputModal}
                    save={this.save}
                    disabled={!this.state.applicationProcess}
                />
            </>
        )
    }
}

export default connect(mapStateToProps)(ClubPageStepsToApplyWidget)
