import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-video"
            {...props}
        >
            <path d="M23 7L16 12 23 17 23 7z" />
            <rect x={1} y={5} width={15} height={14} rx={2} ry={2} />
        </svg>
    )
}

export default SvgComponent
