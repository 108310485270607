import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={55}
            height={60}
            viewBox="0 0 55 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M55 60H38.385L22.917 38.091h-8.594V60H0V0h25.367c4.836 0 8.75.816 11.742 2.448 2.992 1.605 5.183 3.818 6.572 6.64 1.39 2.793 2.084 5.974 2.084 9.543 0 3.126-.427 5.781-1.282 7.966-.855 2.158-2.017 3.984-3.487 5.478a21.907 21.907 0 01-4.889 3.734L55 60zM23.484 27.427c2.564 0 4.622-.843 6.171-2.53 1.576-1.688 2.364-3.694 2.364-6.017 0-2.435-.801-4.426-2.404-5.975-1.603-1.55-3.513-2.324-5.73-2.324h-9.562v16.846h9.16z"
                fill="currentColor"
            />
        </svg>
    )
}

export default SvgComponent
