import React, {Component} from "react";
import {connect} from "react-redux";
import ModalComponent from "./ModalComponent";
import SvgCheck from "../../icons/SvgCheck";
import StudentClubListItem from "../../StudentClubListItem";
import {getSchoolClubs, editClub} from "../../../api/student/clubs";
import { editUser, requestClubs } from "../../../api/student/users";
import {Button} from "../Button";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        }
    }
}


class ChooseClubsModal extends Component {
    state={
        clubOptions: [],
        clubs: [],
    }

    componentDidMount = () => {
        if (this.props.isOpen) {
            this.getInfo()
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen) {
            this.getInfo();
        }
    }

    getInfo = async () => {
        const user = this.props.user || {};
        const clubs = user.clubs || [];
        const res = await getSchoolClubs(this.props.authUser, user.college);

        const allClubs = [...user.clubs];
        for (const c of res.clubs.filter(club => club.demo !== true)) {
            if (!allClubs.map(club => club.id).includes(c.id)) {
                allClubs.push(c);
            }
        }
        this.setState({clubOptions: allClubs, clubs: clubs.map(club => club.id)});
    }

    addRemoveClub = (clubId) => {
        const clubs = [...this.state.clubs];
        if (clubs.includes(clubId)) {
            const index = clubs.indexOf(clubId);
            clubs.splice(index, 1);
        } else {
            clubs.push(clubId);
        }
        this.setState({clubs})
    }

    updateClubs = async () => {
        await editUser(this.props.authUser, {
            selected_clubs: true,
        })
        await requestClubs(this.props.authUser, this.state.clubs);
        window.location.reload();
    }

    addAdmin = async (club) => {
        const leadership = {...club?.leadership}
        if (this.props.user.username in leadership) {
            delete leadership[this.props.user.username];
        } else {
            leadership[this.props.user.username] = "Executive";
        }
        await editClub(this.props.authUser, club.id, {leadership});
    }

    render() {
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                size={'lg'}
                toggle={this.props.close}
                FooterComponent={
                    <div className='flex flex-row items-center justify-center w-full gap-3'>
                        <Button variant={'secondary'} onClick={this.props.close}>
                            Close
                        </Button>
                        <Button
                            onClick={this.updateClubs}
                            className="primary-button body1-bold">
                            <SvgCheck className={'w-5 h-5 mr-2'}/>
                            Complete
                        </Button>
                    </div>
                }
            >
                <p className='text-3xl font-semibold text-center'>
                    Add your clubs
                </p>
                <p className='text-center text-slate-500 my-5'>
                    Join the clubs you would like and then select "Complete" when you are done.
                </p>
                <div className='flex flex-col gap-3 mb-4 overflow-y-scroll max-h-[60vh]'>
                    {this.state.clubOptions.map(club =>
                        <StudentClubListItem
                            club={club}
                            addRemoveClub={this.addRemoveClub}
                            userClubs={this.state.clubs}
                            grid={true}
                            addAdmin={this.addAdmin}
                            username={this.props.user.username}
                        />
                    )}
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseClubsModal)
