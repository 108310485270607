import React, {Component} from "react";
import {connect} from "react-redux";
import {getInterviewSets} from "../../api/student/interview_sets";
import InterviewSetListItem from "./InterviewSetListItem";
import {getAllQuestions} from "../../api/student/questions";
import {question_categories_array, TOAST_OPTIONS} from "../../utils/constants";
import {Button} from "../atoms/Button";
import ResponseCategoryGroupDashboard from "./ResponseCategoryGroupDashboard";
import ProfilePicture from "../../components-recruiter/atoms/ProfilePicture";
import SvgIce from "../icons/SvgIce";
import SvgFire from "../icons/SvgFire";
import Spinner from "../Spinner";
import SvgClock from "../icons/SvgClock";
import {roundTo} from "../../utils/strings";
import SvgLock from "../icons/SvgLock";
import {toast} from "react-toastify";
import ResponsesCategoryGroup from "./ResponsesCategoryGroup";
import ResponsesCategoryGroupDashboard from "./ResponsesCategoryGroupDashboard";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
    };
}


class ResourcesMyDashboard extends Component {
    state = {
        interviewSets: [],
        categorizedQuestions: {},
        categorizedAnswers: [],
        keyedAnswers: {},
        categoryKeyedAnswers: [],
        loading: false
    }

    componentDidMount = async () => {
        this.setState({loading: true});
        this.categorizeAnswers();
        const res = await getAllQuestions(this.props.authUser);
        this.setState({categorizedQuestions: res.questions})
        this.getSets();
        this.setState({loading: false});
    }

    categorizeAnswers = () => {
        const answers = this.props.answers
        const categories = question_categories_array

        // Map each category to its answers
        let categorizedAnswers = categories.map(category => ({
            category,
            answers: answers.filter(answer => answer.question.category === category)
        }));

        // Sort the mapped categories by the length of their answers array, from longest to shortest
        categorizedAnswers.sort((a, b) => b.answers?.length - a.answers?.length);

        // The dictionary of categorized answers
        const categoryKeyedAnswers = categories.reduce((acc, category) => {
            acc[category] = answers.filter(answer => answer.question.category === category);
            return acc;
        }, {});

        const keyedAnswers = {}
        for (const answer of this.props.answers) {
            if (!(answer.question_id in keyedAnswers)) keyedAnswers[answer.question_id] = 0
            if (answer.score > keyedAnswers[answer.question_id]) keyedAnswers[answer.question_id] = answer.score
        }

        this.setState({keyedAnswers, categorizedAnswers, categoryKeyedAnswers});
    };

    getSets = async () => {
        const res = await getInterviewSets(this.props.authUser);
        this.setState({interviewSets: res.interview_sets})
    }

    selectTopic = (set) => {
        console.log(set)
        this.props.changeTab('Question Bank')
    }

    render() {
        const answers = this.props.answers || [];
        const statistics = this.props.statistics
        if (this.state.loading) {
            return <div className={"flex flex-row items-center justify-center my-auto"}>
                <Spinner size={24}/>
            </div>
        }
        return (
            <div className='flex flex-col flex-1 overflow-y-scroll w-full pt-0 pb-20'>
                <div className='py-4 min-h-24 flex flex-col gap-3 w-full'>
                    <div
                        className='border-slate-200 border p-5 mt-2 rounded-2xl flex-1 gap-10 flex-row justify-between'>
                        <div className='flex flex-row items-center gap-5 flex-1'>
                            <ProfilePicture
                                className={'border-2 border-slate-200'}
                                image={this.props.user.profile_pic_url} userID={this.props.user.id} size={48}/>
                            <div className='flex-1'>
                                <p className='text-slate-900 text-3xl font-bold'>
                                    {statistics.streak === 0 ? 'Start your streak!' : 'Extend your streak!'}
                                </p>
                                <p className='text-slate-500'>
                                    Apply your skills by practicing real-world questions and receiving valuable
                                    feedback.
                                </p>
                            </div>
                        </div>
                        <div
                            className='flex flex-row items-center gap-8'>
                            <div className=''>
                                <p className='text-4xl font-bold'>
                                    {statistics.total_submissions || '0'}
                                </p>
                                <p className='text-slate-500'>
                                    Submissions
                                </p>
                            </div>
                            <div>
                                <div className='flex flex-row items-center gap-1'>
                                    {statistics.streak === 0 ? <SvgIce/> : <SvgFire/>}
                                    <p className='text-4xl font-bold'>
                                        {statistics.streak || '0'}d
                                    </p>
                                </div>
                                <p className='text-slate-500'>
                                    Streak
                                </p>
                            </div>
                            <div>
                                <div className='flex flex-row items-center gap-1'>
                                    <SvgClock/>
                                    <p className='text-4xl font-bold'>
                                        {roundTo(statistics.total_length, 0) || '-'}s
                                    </p>
                                </div>
                                <p className='text-slate-500'>
                                    Tot. Interview Time
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row gap-12 flex-1 mt-5'>
                    <div className='flex flex-col flex-1 overflow-hidden'>
                        <p className='text-2xl font-bold'>
                            Next Up Interview
                        </p>
                        <p className='text-slate-700'>
                        Curated interview topics created by industry experts to help you excel. Check out full list&nbsp;
                        <span className='text-primary font-semibold cursor-pointer'
                                       onClick={() => this.props.changeTab('Interviews')}>here</span>.
                        </p>
                        <div className='flex flex-col gap-2 pt-3 flex-1 overflow-y-scroll'>
                            {this.state.interviewSets.map(set => {
                                    const answerIds = answers.map(answer => answer.question_id);
                                    const commonIds = set.question_ids.filter(id => answerIds.includes(id))
                                    if (this.state.showCompleted && commonIds.length !== set.question_ids.length) {
                                        return
                                    }
                                    return (
                                        <InterviewSetListItem
                                            set={set}
                                            selectedSet={this.state.selectedSet}
                                            key={set.id}
                                            selectSetToPreview={this.props.selectInterviewToPreview}
                                            answerIds={answers.map(answer => answer.question_id)}
                                        />
                                    )
                                }
                            )}
                            <div className={'flex flex-col gap-2 relative p-3'}>
                                {this.state.interviewSets.map(set => {
                                    return (
                                        <InterviewSetListItem
                                            set={set}
                                            selectedSet={this.state.selectedSet}
                                            key={set.id}
                                            selectSetToPreview={this.selectSetToPreview}
                                            answerIds={answers.map(answer => answer.question_id)}
                                        />
                                    )
                                })}
                                <div
                                    className='absolute bg-white/30 top-0 left-0 right-0 px-5 text-center bottom-0 backdrop-blur flex flex-col items-center justify-center'
                                >
                                    <SvgLock className={'text-primary'}/>
                                    <p className='font-bold mb-2'>
                                        Upgrade to unlock more
                                    </p>
                                    <p className='text-sm mb-3'>
                                        Upgrade to RecruitU premium to gain access to more real-world interview sets.
                                    </p>
                                    <Button onClick={() => toast.warning('Coming soon!', TOAST_OPTIONS)}>
                                        Upgrade
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='text-2xl font-bold'>
                            Topics To Improve
                        </p>
                        <p className='text-slate-700'>
                            Looking to work on a specific topic? Check out the full question bank<span
                            className='text-primary font-semibold cursor-pointer'
                            onClick={() => this.props.changeTab('Question Bank')}> here</span>.
                        </p>
                        <div className='flex flex-col gap-2 pt-3 flex-1 overflow-y-scroll'>
                            {Object.keys(this.state.categorizedQuestions).slice(0, 5).map(category =>
                                <ResponseCategoryGroupDashboard
                                    category={category}
                                    keyedAnswers={this.state.keyedAnswers}
                                    answers={this.state.categoryKeyedAnswers[category]}
                                    questions={this.state.categorizedQuestions[category]}
                                    selectTopicToPreview={this.props.selectTopicToPreview}
                                />
                            )}
                            <Button onClick={() => this.props.changeTab('Question Bank')} className={'mx-auto mt-4'}
                                    variant={'secondary'}>
                                View all
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(ResourcesMyDashboard);
