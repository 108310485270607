import React, {Component} from "react";
import CompanyLogo from "../atoms/CompanyLogo";
import CompanyStatusTrackerComponent from "../atoms/CompanyStatusTrackerComponent";
import TagComponent from "../atoms/TagComponent";
import Checkbox from "../atoms/Checkbox";
import SvgUsers from "../icons/SvgUsers";
import {editTracker} from "../../api/student/trackers";
import SvgDocument from "../icons/SvgDocument";

class TrackedCompanyItem extends Component {
    toggleCheckbox = async () => {
        const newTracker = {...this.props.tracker};
        const newStatus = !newTracker.status
        newTracker.status = newStatus
        this.props.updateTracker(newTracker);
        await editTracker(this.props.authUser, this.props.tracker.id, {status: newStatus});
        
        if (newStatus) {
          window.analytics.track("Company Status Updated", {
            company_id: this.props.item.id
          })
        }
    }

    render() {
        const company = this.props.item || {};
        return (
            <div
                onClick={() => this.props.openCompanyPeek(company)}
                className='border-b border-slate-200 py-2 mb-0 flex flex-col hover-card gap-2 h-full'>
                <div className={'flex flex-row justify-between gap-3'}>
                    <div className='flex flex-row items-center gap-3 flex-[1.25]'>
                        <CompanyLogo id={company.id} className={'w-16 h-16'}/>
                        <p className='body1-bold'>
                            {company.name}
                        </p>
                    </div>
                    <div className='flex flex-row items-center flex-1'>
                        <div className='mr-auto'>
                            <TagComponent
                                label={company.sector}
                            />
                        </div>
                    </div>
                    <div className='row-ac text-sm flex flex-row items-center flex-1'>
                        <SvgUsers width={16} height={16}/>
                        <p className='m-0 ml-8'>
                            {company.num_employees} employees
                        </p>
                    </div>
                    <div style={{flex: .5}} className='flex flex-row items-center gap-1 body3 base-black-50'>
                        <SvgDocument className={'w-4 h-4'}/>
                        {this.props.tracker?.notes.length || 0}
                    </div>
                    <div className='flex flex-row items-center flex-[.5]'>
                        <Checkbox
                            checked={this.props.tracker?.status}
                            onClick={this.toggleCheckbox}
                            label={'Offered'}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default TrackedCompanyItem
