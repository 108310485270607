import axios from "../axios";

export const createReport = async (user, report_type, report_message, report_metadata) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            report_type,
            report_message,
            report_metadata,
        }
        const res = await axios.post("/reports", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}
