import React, {PureComponent} from "react";
import SelectComponent from "../atoms/SelectComponent";
import DatePicker from "react-datepicker";
import {STATES} from "../../utils/constants";

class AddJobLocation extends PureComponent {
    render() {
        const STATE_OPTIONS = STATES.map(state => {
            return ({value: state, title: state})
        });
        const INDUSTRY_TAGS = this.props.constants.industry_tags.map(tag => ({title: tag, value: tag})); 

        return (
            <>
                <div className='flex flex-row gap-3'>
                    <div className="col-plain flex-[2]">
                        <p className="body3-bold text-neutral-400 mb-1">
                            City
                        </p>
                        <input
                            name="location"
                            value={this.props.location}
                            onChange={this.props.handleInputChange}
                            placeholder={'ie. New York'}
                            className='input-light px-3 body2 base-black-50 w-full'
                        />
                    </div>
                    <div className="col-plain flex-[2]">
                        <SelectComponent
                            className={'w-full'}
                            outerLabel={'State'}
                            label={'ie. NY'}
                            value={this.props.state}
                            light
                            setValue={this.props.handleSelectChange}
                            clearFilter={() => this.props.handleSelectChange('state', null)}
                            stateName={'state'}
                            options={STATE_OPTIONS}
                            scrollable
                            node={document.getElementById('add-job-modal')}
                        />
                    </div>

                </div>
                <div className="flex flex-row gap-3">
                    <div className="col-plain flex-[2]">
                        <p className="body3-bold text-neutral-400 mb-1">
                            Deadline
                        </p>
                        <DatePicker
                            className={'px-3 input-light body2 w-full'}
                            placeholderText={'Due date'}
                            selected={this.props.deadline}
                            showYearDropdown
                            onChange={(e) => this.props.handleDateChange(e, 'deadline')}
                        />
                    </div>
                    <div className="col-plain flex-[2]">
                        <p className="body3-bold text-neutral-400 mb-1">
                            Industry
                        </p>
                        <SelectComponent
                            label={'Select industry...'}
                            className={'w-full'}
                            value={this.props.industry}
                            light
                            setValue={this.props.updateIndustry}
                            clearFilter={() => this.props.updateIndustry(null)}
                            options={INDUSTRY_TAGS}
                            node={document.getElementById('add-job-modal')}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default AddJobLocation
