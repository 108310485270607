import React, {useState, useEffect} from "react";
import {useParams} from 'react-router-dom';
import RecruiterDashboardTable from "../../components-recruiter/RecruiterDashboardTable";
import { CLUB_ADMIN_APPLICANTS_TABLE_HEADERS, MAJOR_OPTIONS} from "../../utils/dummy";
import SelectComponent from "../../components/atoms/SelectComponent";
import WrappedTextInput from "../../components/atoms/WrappedTextInput";
import SvgSearch from "../../components/icons/SvgSearch";
import MultiSelectComponent from "../../components/atoms/MultiSelectComponent";
import GoBack from "../../components/atoms/GoBack";
import {connect} from "react-redux";
import Spinner from "../../components/Spinner";
import {Button} from "../../components/atoms/Button";
import ClubApplicantTableItem from "../ClubApplicantTableItem";
import { downloadAllResumes } from "../../api/student/clubs";
import { getApplicationSubmissions } from "../../api/clubAdmin/applications";
import { admitUserToClub } from "../../api/clubAdmin/user";
import { ViewApplicationSubmissionModal } from "../modals/ViewApplicationSubmissionModal";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user
    };
}


const ClubAdminApplicantsPage = ({
    club,
    user,
    authUser,
    history,
    location
}) => {
  const { applicationId } = useParams();
  const club_id = club.id;
  const application_link = `/club/${club_id}/application/${applicationId}`;
  const application_webpage = window.location.origin + application_link;
  const [submissions, setSubmissions] = useState([]);
  const [viewApplicationModalOpen, setViewApplicationModalOpen] =
    useState(false);
  const [applicationToView, setApplicationToView] = useState(null);
  const [isLoadingAdmit, setIsLoadingAdmit] = useState(false);
  const [isLoadingDecline, setIsLoadingDecline] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("")
  const [year, setYear] = useState([]);
  const [gpa, setGpa] = useState("");
  const [major, setMajor] = useState([]);
  const {application, application_questions} = location.state || {};


  async function fetchData() {
    setIsLoading(true);
    const { application_submissions, submission_count } =
      await getApplicationSubmissions(authUser, applicationId);
    setSubmissions(application_submissions);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [authUser, applicationId]);

  async function admitUser(submission, approval_decision) {
    if (approval_decision) {
      setIsLoadingAdmit(true);
    } else {
      setIsLoadingDecline(true);
    }
    await admitUserToClub(
      authUser,
      submission.user.username,
      submission.club_id,
      submission.id,
      approval_decision
    );
    setIsLoadingAdmit(false);
    setIsLoadingDecline(false);
    setSubmissions((submissions) =>
      submissions.filter((s) => s.id !== submission.id)
    );
    setViewApplicationModalOpen(false);
  }

  const downloadCsv = () => {
    const applicants = submissions.map(sub => sub.user);
    const people = applicants.map((row, i) => {
        let basicInfo = [
            escapeCsvValue(`${row.first_name} ${row.last_name}`),
            escapeCsvValue(row.email),
            escapeCsvValue(row.grade),
            escapeCsvValue(row.college),
            escapeCsvValue(row.gpa),
            escapeCsvValue(row.resume)
        ].join(',');
        
        for (const answer of submissions[i]["answers"]) {
            basicInfo += `,${escapeCsvValue(answer.answerText)}`;
        }
        return (
            basicInfo
        )
    }).join('\n');

    // Create a Blob object from the CSV data
    let header =  `name,email,class year,college,gpa,resume`;
    for (const question of application_questions) {
        header += `,${escapeCsvValue(question.questionText)}`;
    }
    header += "\n";
    const csv = header + people;
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);

    // Create a link element and click it programmatically to trigger download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'applicants.csv');
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  function escapeCsvValue(value) {
        if (!value) return ''; // Handle null or undefined
        value = value.toString().replace(/"/g, '""'); // Escape double quotes by doubling them
        // If value contains commas, newlines, or quotes, wrap in double quotes
        if (/[,"\n]/.test(value)) {
            return `"${value}"`;
        }
        return value;
    }

  const resumeBooklet = async () => {
      await downloadAllResumes(authUser, submissions.map(sub => sub.user));
  };

    const handleSearchChange = (e) => {
        const search = e.target.value;
        setSearch(search)
    };

    const handleYearChange = (newYear) => {
        if (newYear === null) {
            setYear([])
            return
        }

        let newClassYears = [...year];
        if (year.includes(newYear)) {
            const index = year.indexOf(newYear);
            newClassYears.splice(index, 1);
        } else {
            newClassYears.push(newYear);
        }
        setYear(newClassYears)
    }

    const handleMajorChange = (newMajor) => {
        if (newMajor === null) {
            setMajor([])
            return
        }

        let newMajors = [...major];
        if (major.includes(newMajor)) {
            const index = major.indexOf(newMajor);
            newMajors.splice(index, 1);
        } else {
            newMajors.push(newMajor);
        }
        setMajor(newMajors)
    }

    const handleGpaChange = (gpa) => {
        setGpa(gpa)
    }

    const submissionsUsers = submissions.map(sub => sub.user);
    const attendees = submissionsUsers.filter(user => {
        let nameMatch = true;
        if (!!search) {
            const fullName = user.first_name + " " + user.last_name;
            const lowerCaseName = fullName.toLowerCase();
            nameMatch = lowerCaseName.startsWith(search.toLowerCase());
        }

        let majorMatch = true;
        if (!!major.length) {
            majorMatch = user.major.some(m => major.includes(m));
        }
        let yearMatch = true;
        if (!!year.length) {
            yearMatch = year.includes(user.grade);
        }
        let gpaMatch = true;
        if (!!gpa) {
            gpaMatch = user.gpa >= gpa;
        }
        return nameMatch && majorMatch && yearMatch && gpaMatch;
    });
    return (
        <div className={`flex flex-col flex-1 p-5 gap-5 overflow-hidden`}>
            <div className='flex flex-col gap-5'>
                <GoBack/>
                <div className='flex flex-row justify-between items-start gap-12'>
                    <div className='flex flex-col gap-1 flex-1'>
                        <p className='text-2xl font-semibold'>
                            {application.title}
                        </p>
                        <p className='text-md '>
                            {application.description}
                        </p>
                    </div>
                    <div>
                        <div className='flex flex-row items-center gap-3'>
                            <Button variant={'secondary'} onClick={downloadCsv}>
                                Save CSV
                            </Button>
                            <Button variant={'secondary'} onClick={resumeBooklet}>
                                Download resumes
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'flex flex-col gap-4 flex-1 overflow-hidden'}>
                <div className='flex flex-row items-center gap-2'>
                    <p className='text-2xl font-semibold text-slate-800'>
                        Applicants
                    </p>
                    <div
                        className='text-primary whitespace-nowrap text-sm font-semibold px-2 mt-0 rounded-full bg-primary/10'>
                        {attendees.length}
                    </div>
                </div>
                <div className='flex flex-row items-center gap-3'>
                    <div>
                        <WrappedTextInput
                            type="text"
                            outerLabel={'Search'}
                            placeholder={'Search applicants...'}
                            className='pl-44 input-light px-3 body2 w-full'
                            value={search}
                            onChange={handleSearchChange}
                            icon={<SvgSearch width={24} height={24}
                                                className={'absolute text-slate-500'}
                                                style={{left: 12, top: 10}}/>}
                        />
                    </div>
                    <MultiSelectComponent
                        outerLabel={'Major'}
                        label={'All'}
                        value={major}
                        light
                        setValue={handleMajorChange}
                        clearFilter={() => handleMajorChange(null)}
                        options={MAJOR_OPTIONS}
                    />
                    <MultiSelectComponent
                        outerLabel={'Grad Year'}
                        label={'All'}
                        value={year}
                        light
                        setValue={handleYearChange}
                        clearFilter={() => handleYearChange(null)}
                        options={[{title: "2025",}, {title: "2026",}, {title: "2027",}, {title: "2028",}]}
                    />
                    <SelectComponent
                        outerLabel={'Min GPA'}
                        label={'All'}
                        value={gpa}
                        light
                        setValue={handleGpaChange}
                        clearFilter={() => handleGpaChange(null)}
                        options={[{title: "3.9",}, {title: "3.8",}, {title: "3.7",}, {title: "3.6",}, {title: "3.5",}, {title: "3.4",}, {title: "3.3",},{title: "3.2",},{title: "3.1",}, {title: "3.0",}]}
                    />
                </div>
                <div className='flex-1 flex flex-col overflow-hidden'>
                    <RecruiterDashboardTable
                        columns={CLUB_ADMIN_APPLICANTS_TABLE_HEADERS}
                        TableBody={
                            isLoading ?
                                <div className='flex flex-col items-center justify-center flex-1 mb-[15vh]'>
                                    <Spinner />
                                </div>
                                :
                                <div className='flex flex-col gap-4 p-5 flex-1 overflow-y-scroll'>
                                    {attendees.length === 0 ?
                                        <div
                                            className='flex flex-col items-center justify-center text-slate-500 text-sm flex-1'>
                                            No applicants to show
                                        </div>
                                        :
                                        attendees.map((item, i) =>
                                            <ClubApplicantTableItem
                                                item={item}
                                                columns={CLUB_ADMIN_APPLICANTS_TABLE_HEADERS}
                                                admitUser={admitUser}
                                                isLoadingAdmit={isLoadingAdmit}
                                                isLoadingDecline={isLoadingDecline}
                                                setApplicationToView={setApplicationToView}
                                                setViewApplicationModalOpen={setViewApplicationModalOpen}
                                                submission={submissions.filter(sub => sub.user_id === item.username)[0]}
                                            />
                                        )}
                                </div>
                        }
                    />
                </div>
            </div>
            <ViewApplicationSubmissionModal
                isOpen={viewApplicationModalOpen}
                closeModal={() => setViewApplicationModalOpen(false)}
                application={applicationToView}
                application_questions={application_questions}
                user={user}
                authUser={authUser}
                setSubmissions={setSubmissions}
                history={history}
            />
        </div>
    )
    
}

export default connect(mapStateToProps)(ClubAdminApplicantsPage);
