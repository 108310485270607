import React, {useState} from "react";
import OnboardingStepHeader from "./OnboardingStepHeader";
import OnboardingStepWrapper from "./OnboardingStepWrapper";
import SvgLinkLinkedin from "./SvgLinkLinkedin";
import InputWrapper from "../atoms/inputs/InputWrapper";
import {Button} from "../atoms/Button";
import AnimatedTabs from "../atoms/AnimatedTabs";
import SvgSearch from "../icons/SvgSearch";
import SvgBriefcase from "../icons/SvgBriefcase";
import { MobileIcon   } from '@radix-ui/react-icons'

function OnboardingLinkedinStep({goNextStep, linkedin, setLinkedin}) {
    const [error, setError] = useState('');
    const [activeTab, setActiveTab] = useState('import');
    const handleNextStep = () => {
        const validationResult = validateLinkedinUrl(linkedin);
        if (validationResult === true) {
            setError('');
            goNextStep();
        } else {
            setError(validationResult);
        }
    };

    const validateLinkedinUrl = (url) => {
        const linkedinUrlPattern = /^(https?:\/\/)?(www\.)?linkedin\.com\/.+/;
        if (linkedinUrlPattern.test(url)) {
            return true;
        }
        return 'The LinkedIn URL must start with "http://www.linkedin.com" or "https://www.linkedin.com"';
    }

    return (
        <OnboardingStepWrapper>
            <OnboardingStepHeader
                title={'Sync your LinkedIn'}
                description={'Save time and import your work experience, education, and other info'}
            />
             <div className="flex w-full rounded-lg overflow-hidden p-2 bg-slate-100 md:hidden">
                <button
                    className={`flex-1 flex items-center justify-center flex-row py-1.5 text-sm text-center rounded-l ${activeTab === 'import' ? 'bg-gray-300' : 'bg-gray-200 text-gray-700'}`}
                    onClick={() => setActiveTab('import')}
                >
                    <SvgBriefcase className="inline-block mr-1.5 w-4 h-4" />
                    Import
                </button>
                <button
                    className={`flex-1 flex items-center justify-center flex-row py-1.5 text-sm text-center rounded-r ${activeTab === 'find' ? 'bg-gray-300' : 'bg-gray-200 text-gray-700'}`}
                    onClick={() => setActiveTab('find')}
                >
                    <SvgSearch className="inline-block mr-1.5 w-4 h-4" />
                    Find Link
                </button>
             </div>
           
            {activeTab === 'import' && (
                <>
                    <SvgLinkLinkedin className={'w-full h-[80px] md:h-[200px]'}/>
                    <div>
                        <InputWrapper
                            label='Full LinkedIn URL'
                            type='text'
                            name='linkedin'
                            placeholder='Paste the full URL to your LinkedIn here (e.g. https://www.linkedin.com/in/john-smith)'
                            value={linkedin}
                            onChange={(e) => setLinkedin(e.target.value)}
                        />
                        {error && <p className='text-red-500 text-xs mt-2'>{error}</p>}
                        <p className='w-2/3 mx-auto text-center text-slate-400 text-xs mt-3'>
                            <span className='font-bold'>One time sync</span> with Linkedin to populate your profile for
                            recruiters. We do not maintain any
                            connection
                            to your account
                        </p>
                        <Button className='w-full mt-10' size={'lg'} onClick={handleNextStep}>
                            Sync now
                        </Button>
                    </div>
                </>
            )}
            {activeTab === 'find' && (

                <div className="space-y-4">
                    <div className="space-y-2">
                    <h4 className="font-medium flex items-center">
                        <SvgSearch className="w-4 h-4 mr-2" />
                        Method 1: Search Engine
                    </h4>
                    <ol className="list-decimal list-inside space-y-1 text-sm ml-6">
                        <li>Open a new tab in your browser</li>
                        <li>{`Type in "[Your Full Name] [Your University] LinkedIn`}</li>
                        <li>Find your profile in the search results</li>
                        <li>Copy and paste the link</li>
                    </ol>
                    </div>
                    <div className="space-y-2">
                    <h4 className="font-medium flex items-center">
                        <MobileIcon className="w-4 h-4 mr-2" />
                        Method 2: LinkedIn App
                    </h4>
                    <ol className="list-decimal list-inside space-y-1 text-sm ml-6">
                        <li>Open the LinkedIn app</li>
                        <li>Go to your profile</li>
                        <li>Click the three dots (...)</li>
                        <li>Select "Share via"</li>
                        <li>Copy the link</li>
                    </ol>
                    </div>
                </div>
            )}
        </OnboardingStepWrapper>
    );
}

export default OnboardingLinkedinStep;
