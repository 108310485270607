import React, {PureComponent} from "react";
import CompanyEventItem from "../../components/jobboard/CompanyEventItem";
import {connect} from "react-redux";
import SvgEmpty from "../icons/SvgEmpty";
import CompanyStatusTrackerComponent from "../atoms/CompanyStatusTrackerComponent";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        applications: state.userReducer.applications,
        user: state.userReducer.user,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
    }
}

class CompanyPageEvents extends PureComponent {
    openEventPage = (selectedEvent) => {
        this.props.history.push(`/event/${selectedEvent.id}`);
    }

    render() {
        const company = this.props.company || {};
        return (
            <>
            {this.props.events.length === 0 ?
                <div
                    className='flex flex-col items-center justify-center text-slate-500 text-sm gap-3 flex-1 mt-10'>
                    <SvgEmpty/>
                    <p className='text-lg text-slate-800 font-semibold'>
                        No events listed for this company.
                    </p>
                    <div>
                        <CompanyStatusTrackerComponent companyId={company.id}/>
                    </div>
                </div>
                :
                <div className='flex flex-col gap-3'>
                    {this.props.events.map((item, index) =>
                        <CompanyEventItem key={item.id} item={item} openEventPage={() => this.openEventPage(item)}/>
                    )}
                </div>
            }
        </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPageEvents);
