import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 96 960 960"
            width={48}
            {...props}
        >
            <path
                fill={props.fill || 'currentColor'}
                d="M384 679l50.079-108.921L543 520l-108.921-50.079L384 361l-50.079 108.921L225 520l108.921 50.079L384 679zm0 145l-95-209-209-95 209-95 95-209 95 209 209 95-209 95-95 209zm344 112l-47-105-105-47 105-48 47-104 48 104 104 48-104 47-48 105zM384 520z" />
        </svg>
    )
}

export default SvgComponent
