import React, {PureComponent} from "react";
import {withRouter} from "react-router-dom";
import TrackedJobTableItem from "./TrackedJobTableItem";
import SvgArrow from "../icons/SvgArrow";
import {CRM_TABLE_HEADERS} from "../../utils/dummy";
import TableHeader from "../atoms/TableHeader";
import {DateTime} from 'luxon';
import SvgEmpty from "../icons/SvgEmpty";
import {Button} from "../atoms/Button";

class TrackedJobsWidget extends PureComponent {
    state = {
        filter: "All",
        renderEmpty: false,
        selectedSort: null,
        applications: this.props.applications
    }


    updateFilter = (filter) => {
        const currentApplications = this.props.applications.filter(app => filter === "All" || filter === app.personal_status);
        this.setState({filter, renderEmpty: currentApplications.length === 0});
    }

    componentDidMount = () => {
        this.updateFilter("All");
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.applications !== prevProps.applications) {
            this.changeSort(this.state.selectedSort, true)
        }
    }

    changeSort = (selectedSort, dontReverse = false) => {
        let data = [...this.props.applications];
        if (selectedSort === null) {

        } else if (this.state.selectedSort === selectedSort && !dontReverse) {
            console.log("REVERSING")
            data = [...this.state.applications];
            data.reverse()
            console.log(data.map(d => d.job.deadline))
        } else {
            if (selectedSort === 'Job') {
                data.sort((a, b) => a['job']['name'].localeCompare(b['job']['name']));
            } else if (selectedSort === 'Deadline') {
                data.sort((a, b) => {
                    if (!a['job']['deadline']) {
                        return 1;
                    } else if (!b['job']['deadline']) {
                        return -1
                    }
                    const timestampA = DateTime.fromISO(a['job']['deadline']);
                    const timestampB = DateTime.fromISO(b['job']['deadline']);
                    return (timestampA < timestampB) ? -1 : 1;
                });
            } else if (selectedSort === 'Result') {
                data.sort((a, b) => a['result'].localeCompare(b['result']));
            } else if (selectedSort === 'Status') {
                data.sort((a, b) => a['personal_status'].localeCompare(b['personal_status']));
            }
        }
        this.setState({selectedSort, applications: data});
    };

    switchTableTitle = () => {
        switch (this.state.filter) {
            case 'All':
                return 'All applications'
            case 'To apply':
                return 'Need to apply'
            case 'Applied':
                return 'Already applied'
            case 'Interviewing':
                return 'Currently interviewing'
            case 'Offered':
                return 'Offer received'
        }
    }

    render() {
        return (
            <div className=' flex-1 mb-0'>
                <div className=' sticky top-0 bg-white z-[0]'>
                    <TableHeader
                        headers={CRM_TABLE_HEADERS}
                        selectedSort={this.state.selectedSort}
                        changeSort={this.changeSort}
                    />
                </div>
                <div className='flex flex-1'>
                    {this.state.renderEmpty ?
                        <div
                            className='hidden only:flex my-8 text-neutral-400 flex-col items-center justify-center flex-1'>
                            <SvgEmpty/>
                            <p className='text-base mt-2 mb-6'>
                                No jobs saved...
                            </p>
                            <Button
                                onClick={() => this.props.changeTab(`Search`)}>
                                Find jobs
                            </Button>
                        </div>
                        :
                        <div>
                            {this.state.applications.map(app => {
                                    if (this.state.filter === "All" || this.state.filter === app.personal_status) {
                                        return (<TrackedJobTableItem key={app.id} application={app}
                                                                     editApplication={this.props.editApplication}
                                                                     previewApplication={this.props.previewApplication}/>);
                                    }
                                }
                            )}
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(TrackedJobsWidget)
