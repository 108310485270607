import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            width="32px"
            height="32px"
            fill={'currentColor'}
            {...props}
        >
            <path d="M16 3c-3.844 0-7 3.156-7 7v2.875A9.97 9.97 0 006 20c0 5.512 4.488 10 10 10s10-4.488 10-10a9.97 9.97 0 00-3-7.125V10c0-3.844-3.156-7-7-7zm0 2c2.754 0 5 2.246 5 5v1.375C19.523 10.515 17.824 10 16 10c-1.824 0-3.523.516-5 1.375V10c0-2.754 2.246-5 5-5zm0 7c4.43 0 8 3.57 8 8s-3.57 8-8 8-8-3.57-8-8 3.57-8 8-8zm0 6c-1.105 0-2 .895-2 2 0 .738.402 1.371 1 1.719V25h2v-3.281c.598-.348 1-.98 1-1.719 0-1.105-.895-2-2-2z" />
        </svg>
    )
}

export default SvgComponent
