import React, {PureComponent} from "react";
import TagComponent from "../atoms/TagComponent";
import CompanyLogo from "../atoms/CompanyLogo";
import {withRouter} from "react-router-dom";
import SvgBriefcase from "../icons/SvgBriefcase";
import {DateTime} from 'luxon';
import Tooltip from "../atoms/modals/Tooltip";
import { Button } from "../atoms/Button";
import SvgAddCircle from "../icons/SvgAddCircle";
import TooltipComponent from "../atoms/TooltipComponent";
import SvgCheckCircle from "../icons/SvgCheckCircle";

class OnboardingMatchResult extends PureComponent {

    pickJob = (job) => {
        const newJobs = [...this.props.jobs];
        newJobs.push(job);
        this.props.setJobs(newJobs);
    }

    render() {
        const listing = this.props.item || {};
        const tracking = this.props.jobs.map(job => job.id).includes(listing.id);
        const createdAt = DateTime.fromISO(listing.created_at);
        let location = null;
        if (!!listing.city.length) {
            if (listing.city.length === 1) {
                location = listing.city[0] + ", " + listing.state[0]
            } else {
                location = "Multiple Locations"
            }
        }
        const classYears = listing.class_years || [];
        classYears.sort();
        let yearText = '';

        if (classYears.length === 1) {
            yearText = `Class of '${classYears[0].toString().slice(-2)}`;
        } else if (classYears.length > 1) {
            const minYear = classYears[0].toString().slice(-2);
            const maxYear = classYears[classYears.length - 1].toString().slice(-2);
            yearText = `Class of '${minYear} - '${maxYear}`;
        }
        return (
            <li className={`card hover-card mb-2 rounded-xl p-3`}
                style={{border: `1px solid #00000020`}}>
                <div className={'row-ac-jb'}>
                    <div className={`flex flex-row items-center`}>

                        <CompanyLogo 
                            id={listing.company_id}
                            className={'w-12 h-12 md:w-20 md:h-20 mr-3'}
                        />

                        <div className={`col-jc`}>
                            <div className='mr-2'>
                                {!!yearText ?
                                    <div className='hidden md:flex flex-row text-primary items-center gap-1.5'>
                                        <SvgBriefcase className={'w-3 h-3'}/>
                                        <p className='text-xs font-semibold'>
                                            {yearText}
                                        </p>
                                    </div>
                                    : null}
                                {listing?.name.length > 50 ?
                                    <TooltipComponent
                                        ToggleComponent={
                                            <p className='text-md font-semibold line-clamp-1'>
                                                {listing.name}
                                            </p>
                                        }
                                        hover
                                        placement={'bottom'}
                                        label={listing.name}
                                    />
                                    :
                                    <p className='text-md font-semibold line-clamp-1'>
                                        {listing.name}
                                    </p>
                                }
                                <div className='flex flex-row wrap items-center text-slate-500'>
                                    <div className='row-ac'>
                                        <p className='text-sm font-semibold'>
                                            {listing.company_name}
                                        </p>
                                    </div>
                                    <p className='text-sm m-0'>
                                        &nbsp;{!!location ? "•" : ""}&nbsp;{location}
                                    </p>
                                </div>
                            </div>
                            <div className='mt-1 hidden md:flex flex-row items-center gap-2 flex-wrap '>
                                <TagComponent
                                    label={listing.type === "Job" ? "Full Time" : listing.type}
                                    type={'random'}
                                />
                                {listing.industry &&
                                    <TagComponent
                                        label={listing.industry}
                                        type={'industry'}
                                    />
                                }
                                {createdAt.isValid ?
                                    <div className='mr-1 text-xs text-slate-500'>
                                        <p className='m-0'>
                                            Posted: {createdAt.toRelative()}
                                        </p>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                    <div className={'row-ac hidden md:flex'}>
                        {tracking ?
                            <div className={`flex flex-1 mr-2 ${this.props.className}`}>
                                <Tooltip
                                    toggleComponent={
                                        <Button
                                            variant={'secondary'}
                                            icon={SvgCheckCircle}
                                            disabled={true}
                                            className={'flex-1 flex w-full cursor-not-allowed'}
                                        >
                                            {listing.apply_direct ? "Already Applied!" : "Saved"}
                                        </Button>
                                    }
                                    label={listing.apply_direct ? 'You have already applied to this job on RecruitU' : 'You already have this job saved'}
                                />
                            </div>
                            :
                            <>
                                <div className="flex flex-1 mr-2">
                                    <Tooltip
                                        toggleComponent={
                                            <Button
                                                onClick={() => this.pickJob(listing)}
                                                variant={'secondary'}
                                                className={'flex-1 flex w-full'}
                                                icon={SvgAddCircle}
                                            >
                                                Save
                                            </Button>
                                        }
                                        label={'Save this to your saved jobs'}
                                    />
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className={'row-ac md:hidden mt-2'}>
                    {tracking ?
                        <div className={`flex flex-1 mr-2 ${this.props.className}`}>
                            <Tooltip
                                toggleComponent={
                                    <Button
                                        variant={'secondary'}
                                        icon={SvgCheckCircle}
                                        disabled={true}
                                        className={'flex-1 flex w-full cursor-not-allowed'}
                                    >
                                        {listing.apply_direct ? "Already Applied!" : "Saved"}
                                    </Button>
                                }
                                label={listing.apply_direct ? 'You have already applied to this job on RecruitU' : 'You already have this job saved'}
                            />
                        </div>
                        :
                        <>
                            <div className="flex flex-1 mr-2">
                                <Tooltip
                                    toggleComponent={
                                        <Button
                                            onClick={() => this.pickJob(listing)}
                                            variant={'secondary'}
                                            className={'flex-1 flex w-full'}
                                            icon={SvgAddCircle}
                                        >
                                            Save
                                        </Button>
                                    }
                                    label={'Save this to your saved jobs'}
                                />
                            </div>
                        </>
                    }
                </div>
            </li>
        )
    }
}

export default withRouter(OnboardingMatchResult);
