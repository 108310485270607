import React, {Component} from "react";
import ModalComponent from "../../../components/atoms/modals/ModalComponent";
import WrappedTextInput from "../../../components/atoms/WrappedTextInput";
import DatePicker from "react-datepicker";
import Dropzone from "react-dropzone";
import SvgPlus from "../../../components/icons/SvgPlus";
import {connect} from "react-redux";
import {v4 as uuid} from 'uuid';
import {Button} from "../../atoms/Button";
import {editClub} from "../../../api/student/clubs";
import {uploadDoc} from "../../../api/firebase";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class ClubPageAddSponsorModal extends Component {
    state = {
        company_name: '',
        date: '',
        logoUrl: "",
    }

    closeModal = () => {
        this.props.closeModal()
    }

    handleInputChange = (e, inputName) => {
        this.setState({[inputName]: e.target.value});
    };

    handleDateChange = (e, stateKey) => {
        this.setState({[stateKey]: e})
    };

    save = async () => {
        const sponsors = this.props.club.sponsors || [];
        sponsors.push({
            date: this.state.date,
            company_name: this.state.company_name,
            logo_url: this.state.logoUrl,
        })
        await editClub(this.props.authUser, this.props.club.id, {sponsors});
        this.props.closeModal();
        this.props.getClubInfo();
    }

    onDrop = async (acceptedFiles) => {
        const newUuid = await uuid()
        const url = await uploadDoc("clubs/sponsors/" + this.props.club.id, newUuid, acceptedFiles[0]);
        this.setState({logoUrl: url});
    }

    render() {
        const saveDisabled = false
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Add sponsor'}
                headerToggle
                size={'lg'}
                toggle={this.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={this.closeModal} variant='secondary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={saveDisabled}
                            onClick={this.save}>
                            Add
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div className='flex flex-row gap-5 items-center'>
                    <Dropzone onDrop={(files) => this.onDrop(files)}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className='cursor-pointer'>
                                <input {...getInputProps()} />
                                <div style={{}} className=''>
                                    {!!this.state.logoUrl ? (
                                        <div
                                            className='bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square overflow-hidden flex flex-col items-center justify-center border border-gray-200 rounded-lg'
                                        >
                                            <img
                                                className='h-36 w-36 object-contain bg-white aspect-square cursor-pointer  rounded-lg'
                                                src={this.state.logoUrl}
                                                alt="profile-pic"
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className='h-36 w-36 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square flex flex-col items-center justify-center border border-gray-200 rounded-lg'
                                        >
                                            <SvgPlus className={'text-blue-500'}/>
                                            <p className='text-xs mt-2'>
                                                Add logo
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </Dropzone>
                    <div className='flex flex-col w-full gap-3'>
                        <WrappedTextInput
                            type="text"
                            outerLabel={'Company'}
                            placeholder={'Company name'}
                            className='input-light px-3 w-full'
                            value={this.state.company_name}
                            onChange={(e) => this.handleInputChange(e, 'company_name')}
                        />
                        <div className='w-full flex flex-1'>
                            <div className='text-xs font-medium text-slate-500 mb-1'>
                                Date
                            </div>
                            <DatePicker
                                className={'px-3 input-light body2 w-full flex-1'}
                                placeholderText={'Sponsorship date'}
                                selected={this.state.date}
                                showYearDropdown
                                onChange={(e) => this.handleDateChange(e, 'date')}
                            />
                        </div>
                    </div>
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(ClubPageAddSponsorModal);
