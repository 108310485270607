import React, {useState, useEffect} from 'react';
import CompanyLogo from '../atoms/CompanyLogo';
import SvgEdit from '../icons/SvgEdit';
import SvgChevronRight from '../icons/SvgChevronRight';
import SvgLocation from '../icons/SvgLocation';
import TagComponent from '../atoms/TagComponent';
import {Link} from 'react-router-dom';
import EditEventModal from '../atoms/modals/EditEventModal';
import {DateTime} from 'luxon';
import RuDirectLabel from "../atoms/RuDirectLabel";
import {APP_ADMINS} from "../../utils/constants";
import SvgCalendar from "../icons/SvgCalendar";

function EventPageTitleWidget({listing, loading, user, authUser, fetchEvent, constants}) {
    const [datePosted, setDatePosted] = useState(DateTime.now());
    const [eventDate, setEventDate] = useState(DateTime.now());
    const [rsvpDate, setRsvpDate] = useState(null);
    const [company, setCompany] = useState({});
    const [editEventModalOpen, setEditEventModalOpen] = useState(false);

    useEffect(() => {
        // TODO: delete conditional once dates are stored in ISO 8601 format
        if (listing.created_at) {
            listing.created_at = listing.created_at.substring(0, 10) + "T" + listing.created_at.substring(11);
        }
        setDatePosted(DateTime.fromISO(listing.created_at));
        setEventDate(DateTime.fromISO(listing.event_date));

        if (listing.rsvp_date) {
            setRsvpDate(DateTime.fromISO(listing.rsvp_date));
        }

        setCompany(listing.company || {});
    }, [listing])

    const classYears = listing.class_years || [];
    classYears.sort();
    let yearText = '';

    if (classYears.length === 1) {
        yearText = `Class of '${classYears[0].toString().slice(-2)}`;
    } else if (classYears.length > 1) {
        const minYear = classYears[0].toString().slice(-2);
        const maxYear = classYears[classYears.length - 1].toString().slice(-2);
        yearText = `Class of '${minYear} - '${maxYear}`;
    }
    return (
        <div className='card flex flex-row justify-between'>
            {/* Left side of title card */}
            <div>
                <div className='row-ac gap-4'>
                    <CompanyLogo id={company.id} className='w-24 h-24'/>
                    <div>
                        <div className='flex flex-row text-primary items-center gap-1.5'>
                            <SvgCalendar className={'w-3 h-3'}/>
                            <p className='text-xs font-semibold'>
                                {yearText}
                            </p>
                        </div>
                        <p className='text-2xl font-bold m-0 blacks-1 flex flex-row text-neutral-900'>
                            {listing.name}
                        </p>
                        {!!company.name ?
                            <Link to={{pathname: `/company/${company.id}`, state: {company_id: company.id}}}
                                  className='row-ac text-primary'>
                                <p className='body2-bold m-0'>
                                    {company.name}
                                </p>
                                <SvgChevronRight width={24} height={24}/>
                            </Link>
                            :
                            <p className='body2-bold m-0'>
                                {listing.company_name}
                            </p>
                        }
                        <div className='flex flex-row items-center gap-3'>
                            <div className='flex flex-row items-center gap-3 '>
                                <div className='text-xs base-black-50 row-ac gap-2'>
                                    {listing.location &&
                                        <SvgLocation width={16} height={16}/>
                                    }
                                    <p className='m-0'>
                                        {listing.location}{!!listing.location && datePosted.isValid && " • "}{datePosted.isValid ? `Posted ${datePosted.toRelative().toString()}` : null}
                                    </p>
                                </div>
                                {listing.exclusive &&
                                    <RuDirectLabel className={'mt-1'} label={"RecruitU Partner"}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/* Tags */}
                <div className='body2-bold blacks-1 mt-24 flex flex-row items-center flex-wrap gap-5'>
                    <div className='flex flex-row items-center gap-1'>
                        {APP_ADMINS.includes(user?.username) && listing.id ?
                            <TagComponent
                                label={`${(Object.keys(listing.attendees || new Object())).length} attending`}
                                type='job-type'
                            /> : null
                        }
                        <TagComponent
                            label={listing.type}
                            type='industry'
                        />
                        {!!listing.secondary_specialties && listing.secondary_specialties.length > 0 ? 
                            <div className='flex flex-row gap-1 whitespace-nowrap flex-wrap'>
                                {listing?.secondary_specialties.map(specialty =>
                                    <TagComponent
                                        label={specialty}
                                        type={'industry'}
                                        key={specialty}
                                    />
                                )}
                            </div>
                            :
                            <TagComponent
                                label={listing.industry}
                                type={'industry'}
                            />
                        }
                    </div>
                    {APP_ADMINS.includes(user?.username) && listing.id ?
                        <div className='row-ac primary cursor-pointer' onClick={() => setEditEventModalOpen(true)}>
                            <SvgEdit width={16} height={16}/>
                            <p className='m-0 ml-8'>
                                Edit
                            </p>
                        </div>
                    : null}
                </div>

            </div>

            {/* Right side of title card */}
            <div className='flex gap-3 flex-row-reverse'>
                {!!listing.event_date &&
                    <div className='flex flex-col items-center gap-1'>
                        <div className='flex flex-col items-center rounded-lg border-neutral-200 border'>
                            <p className='body4-bold uppercase p-0.5 px-3 bg-neutral-100'>
                                {eventDate.toLocaleString({month: 'short'})}
                            </p>
                            <p className='body2-bold p-1'>
                                {eventDate.toLocaleString({day: 'numeric'})}
                            </p>
                        </div>
                        <p className='text-neutral-500 text-xs'>
                            Event Date
                        </p>
                    </div>
                }
                {!!rsvpDate &&
                    <div className='flex flex-col items-center gap-1'>
                        <div className='flex flex-col items-center rounded-lg border-neutral-200 border'>
                            <p className='body4-bold uppercase p-0.5 px-3 bg-neutral-100'>
                                {rsvpDate.toLocaleString({month: 'short'})}
                            </p>
                            <p className='body2-bold p-1'>
                                {rsvpDate.toLocaleString({day: 'numeric'})}
                            </p>
                        </div>
                        <p className='text-neutral-500 text-xs'>
                            RSVP By
                        </p>
                    </div>
                }
            </div>
            {fetchEvent && constants && (
                <EditEventModal
                    listing={listing}
                    isOpen={editEventModalOpen}
                    closeModal={() => setEditEventModalOpen(false)}
                    authUser={authUser}
                    fetchEvent={fetchEvent}
                    constants={constants}
                />
            )}
        </div>
    )
}

export default EventPageTitleWidget;
