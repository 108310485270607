import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={24}
            viewBox="0 -960 960 960"
            width={24}
            {...props}
        >
            <path fill={'currentColor'} d="M520-600v-240h320v240H520zM120-440v-400h320v400H120zm400 320v-400h320v400H520zm-400 0v-240h320v240H120zm80-400h160v-240H200v240zm400 320h160v-240H600v240zm0-480h160v-80H600v80zM200-200h160v-80H200v80zm160-320zm240-160zm0 240zM360-280z" />
        </svg>
    )
}

export default SvgComponent
