import React, {Component} from "react";
import {connect} from "react-redux";
import {Button} from "../atoms/Button";
import InterviewSetListItem from "./InterviewSetListItem";
import InterviewSetPreview from "./InterviewSetPreview";
import {getInterviewSets} from "../../api/student/interview_sets";
import {question_categories} from "../../utils/dummy";
import {renderQuestionCategoryIcon} from "../../utils/stats";
import SvgCheck from "../icons/SvgCheck";
import CopyReferralButton from "../atoms/CopyReferralButton";
import LottieWrapper from "../atoms/LottieWrapper";
import json from '../../img/lottie/Arrows.json'
import SvgLock from "../icons/SvgLock";
import Spinner from "../Spinner";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
    };
}


class ResourcesInterviewSets extends Component {
    state = {
        selectedSet: this.props.selectedSet || null,
        interviewSets: [],
        showCompleted: false,
        setsLoading: false
    }

    selectSetToPreview = (set) => {
        this.setState({selectedSet: set})
    }

    componentDidMount = () => {
        this.getSets();
    }

    getSets = async () => {
        this.setState({setsLoading: true})
        const res = await getInterviewSets(this.props.authUser);
        if ((this.props.user?.referrals?.length ?? 0) >= 2) {
            this.setState({interviewSets: res.interview_sets, setsLoading: false})
        } else {
            this.setState({interviewSets: res.interview_sets.slice(0,1), setsLoading: false})

        }
    }


    renderCategoryIcon = (category) => {
        const Icon = renderQuestionCategoryIcon(category)
        return (
            <Icon className={'w-5 h-5 mr-1 mt-0.5'}/>
        )
    }

    toggleShowCompleted = () => {
        this.setState({showCompleted: !this.state.showCompleted})
    }

    render() {
        const categories = [{title: 'All'}].concat(question_categories);
        const answers = this.props.answers || [];
        return (
            <div className='flex flex-row flex-1 w-full overflow-hidden gap-3 mt-5'>
                <div className='flex flex-col flex-1 overflow-hidden'>
                    <div className='border-b border-slate-200 pb-3 relative'>
                        <div className='flex flex-row flex-wrap items-center justify-between'>
                            <p className='text-lg font-semibold'>
                                {this.state.selectedCategory === 'All' && 'All'} Interviews
                            </p>
                            <div onClick={this.toggleShowCompleted}
                                 className={`flex cursor-pointer group ${this.state.showCompleted ? 'text-primary' : 'text-slate-500 hover:text-slate-700'} flex-row items-center`}>
                                <p className='text-sm mr-2 font-semibold'>
                                    Show completed
                                </p>
                                <div
                                    className={`${this.state.showCompleted ? 'checkbox-active' : 'checkbox border border-slate-300 group-hover:border-slate-500'}`}>
                                    {this.state.showCompleted && <SvgCheck className={'text-white'}/>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-2 pt-3 flex-1 overflow-y-scroll'>
                        {this.state.setsLoading &&
                            <div className='w-full my-32 flex flex-col items-center'>
                                <Spinner/>
                            </div>
                        }
                        {this.state.interviewSets.map(set => {
                                const answerIds = answers.map(answer => answer.question_id);
                                const commonIds = set.question_ids.filter(id => answerIds.includes(id))
                                if (this.state.showCompleted && commonIds.length !== set.question_ids.length) {
                                    return
                                }
                                return (
                                    <InterviewSetListItem
                                        set={set}
                                        selectedSet={this.state.selectedSet}
                                        key={set.id}
                                        selectSetToPreview={this.selectSetToPreview}
                                        answerIds={answers.map(answer => answer.question_id)}
                                    />
                                )
                            }
                        )}
                        {(this.props.user?.referrals?.length ?? 0) < 2 &&
                            <div className={'flex flex-col gap-2 relative p-3'}>
                                {this.state.interviewSets.slice(0, 3).map(set => {
                                    return (
                                        <InterviewSetListItem
                                            set={set}
                                            selectedSet={this.state.selectedSet}
                                            key={set.id}
                                            selectSetToPreview={this.selectSetToPreview}
                                            answerIds={answers.map(answer => answer.question_id)}
                                        />
                                    )
                                })}
                                <div
                                    className='absolute bg-white/30 top-0 left-0 right-0 px-5 text-center bottom-0 backdrop-blur flex flex-col items-center justify-center'
                                >
                                    <SvgLock className={'text-primary'}/>
                                    <p className='font-bold mb-2'>
                                        Invite your friends!
                                    </p>
                                    <p className='font-semibold text-xs mb-10'>
                                        Beta Release of RecruitU Learn. If you want access to Interview Sets from firms refer 2 friends.
                                    </p>
                                    <div className='flex flex-row w-full items-center justify-between'>
                                        <div className='flex flex-row items-center gap-2'>
                                            <p className='font-semibold'>
                                                Invite 2 users ({this.props.user.referrals?.length || 0}/2)
                                            </p>
                                        </div>
                                        {this.props.user.referrals?.length >= 2 ?
                                            <Button variant={'green'} disabled={true}>
                                                Complete
                                            </Button>
                                            :
                                        <CopyReferralButton />
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div
                    className={`flex-[2] w-full flex flex-col gap-3 border-l border-slate-200 pl-5 overflow-hidden`}>
                    {this.state.selectedSet ?
                        <InterviewSetPreview set={this.state.selectedSet} answers={this.props.answers}
                                             answerIds={answers.map(answer => answer.question_id)}/>
                        :
                        <div className='flex flex-col  justify-center py-40'>
                            <div className='w-3/4 flex flex-col gap-2 ml-12'>
                                <div
                                    className='rotate-90 mr-auto -mb-6 -mt-8 hue-rotate-[18deg] saturate-80 brightness-75'>
                                    <LottieWrapper json={json} width={50} height={100}/>
                                </div>
                                <p className='text-2xl font-semibold'>
                                    Select an Interview to get started
                                </p>
                                <p className='text-slate-500'>
                                    Browse curated interviews in the menu to the left. These lists are designed to help
                                    sharpen your skills and provide valuable experience.
                                </p>

                                {/* <div className='mt-20 flex flex-col items-start gap-3'>
                                    <p className='text-slate-500'>
                                        Looking for a specific topic to practice? Check out the question bank:
                                    </p>
                                    <Button onClick={() => this.props.changeTab('Question Bank')} variant={'secondary'}>
                                        <SvgQuiz className={'w-5 h-5'}/>
                                        Question Bank
                                    </Button>
                                </div> */}
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(ResourcesInterviewSets);
