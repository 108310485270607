import React, {Component} from "react";
import RecruiterJobPreviewCard from "./RecruiterJobPreviewCard";
import {connect} from "react-redux";
import SvgPlus from "../components/icons/SvgPlus";
import FadeInOnScroll from "../components/atoms/FadeInOnScroll";
import NewJobOrEventCard from "./atoms/NewJobOrEventCard";
import Spinner from "../components/Spinner";
import {getRecruiterJobs} from "../api/recruiter/jobs";
import {Button} from "../components/atoms/Button";


function mapDispatchToProps(dispatch) {
    return {
        toggleCreateJobModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_JOB_MODAL",
            })
        },
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

class RecruiterJobsPage extends Component {
    state = {
        jobs: [],
        jobsLoading: true
    }


    componentDidMount = async () => {
        const result = await getRecruiterJobs(this.props.authUser);
        this.setState({jobs: result.jobs, jobsLoading: false});
        window.analytics.page("Recruiter Jobs Page");
    }

    render() {
        return (
            <div className={`flex flex-col flex-1 p-5 gap-3`}>
                <div className='flex flex-row items-center justify-between border-b border-slate-200 pb-5'>
                    <div>
                        <p className='text-slate-800 text-2xl font-semibold'>
                            All Jobs
                        </p>
                    </div>
                    <Button
                        icon={SvgPlus}
                        onClick={this.props.toggleCreateJobModal}
                    >
                        New Job
                    </Button>
                </div>
                {this.state.jobsLoading ?
                    <div className='flex flex-col items-center justify-center flex-1 mb-[15vh]'>
                        <Spinner />
                    </div>
                    :
                    <div className='grid grid-cols-3 gap-5'>
                        {this.state.jobs.map((item, index) =>
                            <FadeInOnScroll key={item.id} triggerImmediately
                                            delay={(index * 100) + 10}>
                                <RecruiterJobPreviewCard item={item}/>
                            </FadeInOnScroll>
                        )}
                        <NewJobOrEventCard
                            index={this.state.jobs.length}
                            label={'Post a new job'}
                            onClick={this.props.toggleCreateJobModal}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterJobsPage);
