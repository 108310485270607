import React, {PureComponent} from "react";
import ExperienceListItem from "./ExperienceListItem";

class ProfilePageExperienceWidget extends PureComponent {
    render() {
        return (
            <div className={!this.props.inPeek && 'card'}>
                {!this.props.inPeek &&
                    <div className='flex flex-row items-center justify-between'>
                        <h3 className={`mt-0 ${this.props.inPeek ? 'body1-bold' : 'title2'} text-neutral-900 mb-2`}>
                            Experience
                        </h3>
                    </div>
                }
                {this.props.experiences.map((experience, i) =>
                    <ExperienceListItem
                        key={experience.ends_at + i + experience.title}
                        experience={experience} last={i === this.props.experiences.length - 1}
                        sm={this.props.inPeek}
                        i={i}
                    />
                )}
            </div>
        )
    }
}

export default ProfilePageExperienceWidget
