import React, {Component} from "react";
import ModalComponent from "../atoms/modals/ModalComponent";
import {Button} from "../atoms/Button";
import SvgClose from "../icons/SvgClose";
import {connect} from "react-redux";
import SvgArrowBack from "../icons/SvgArrowBack";

function mapDispatchToProps(dispatch) {
    return {
        setImageViewerModalClosed: () => {
            dispatch({
                type: "SET_IMAGE_VIEWER_MODAL_CLOSED",
            })
        },
    }
}

function mapStateToProps(state) {
    return {
        imageViewerModalOpen: state.userReducer.imageViewerModalOpen,
        imageViewerImages: state.userReducer.imageViewerImages,
    }
}


class ImageViewerModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentImageIndex: 0,
        };
    }

    closeModal = () => {
        this.props.setImageViewerModalClosed();
    }

    previousImage = (e) => {
        e.stopPropagation()
        this.setState(prevState => ({
            currentImageIndex: Math.max(prevState.currentImageIndex - 1, 0)
        }));
    }

    nextImage = (e) => {
        e.stopPropagation()
        this.setState(prevState => ({
            currentImageIndex: Math.min(prevState.currentImageIndex + 1, this.props.imageViewerImages.length - 1)
        }));
    }

    render() {
        const { currentImageIndex } = this.state;
        const { imageViewerModalOpen, imageViewerImages } = this.props;
        const canNavigateBackward = currentImageIndex > 0;
        const canNavigateForward = currentImageIndex < imageViewerImages.length - 1;

        return (
            <ModalComponent
                isOpen={imageViewerModalOpen}
                toggle={this.closeModal}
                hideBody
            >
                <div className='p-5 flex flex-col flex-1 relative' onClick={this.closeModal}>
                    <div className='fixed top-5 right-5 z-10'>
                        <Button onClick={this.closeModal} size={'icon'} variant={'secondary'} radius={'full'}>
                            <SvgClose />
                        </Button>
                    </div>
                    <div className='flex flex-row justify-center flex-1 w-[90vw] items-center relative'>
                        {canNavigateBackward && (
                            <Button size={'icon'} className='absolute left-0' onClick={this.previousImage}>
                                <SvgArrowBack />
                            </Button>
                        )}
                        <img src={imageViewerImages[currentImageIndex]}
                             className='max-h-[90vh] w-auto object-contain'
                             alt="Image Viewer"
                             onClick={(e) => e.stopPropagation()}
                        />
                        {canNavigateForward && (
                            <Button size={'icon'} className='absolute right-0' onClick={this.nextImage}>
                                <SvgArrowBack className={'rotate-180'}/>
                            </Button>
                        )}
                    </div>
                </div>
            </ModalComponent>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageViewerModal);
