import React, {PureComponent} from "react";
import {CLUB_ADMIN_NAV_ITEMS} from "../utils/dummy";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import TopNavItem from "../components/navigation/TopNavItem";
import SvgLogoPrimary from "../components/icons/SvgLogoPrimary";
import ModalComponent from "../components/atoms/modals/ModalComponent";
import SvgExitToApp from "../components/icons/SvgExitToApp";

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        clubAdminClubId: state.userReducer.clubAdminClubId
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleClubAdminPortal: () => {
            dispatch({
                type: "TOGGLE_CLUB_ADMIN_PORTAL",
            })
        },
        setClubAdminClubId: (clubAdminClubId) => {
            dispatch({
                type: "SET_CLUB_ADMIN_CLUB_ID",
                clubAdminClubId

            })
        },
    }
}

class ClubAdminNavbar extends PureComponent {
    state = {
        navbarOpen: false,
        forceClosed: false,
        lastRoute: '/'
    }

    componentDidMount() {
        this.checkRoute()
    }

    componentDidUpdate(prevProps) {
        // Check if the current route path differs from the previous one
        if (this.props?.location?.pathname !== prevProps?.location?.pathname) {
            this.checkRoute()
        }
    }

    checkRoute = () => {
        this.setState({activeRoute: this.props?.location?.pathname});
        const path = this.props?.location?.pathname
        if (path.startsWith("/clubadmin")) {
            this.setState({lastRoute: path})
        }
    }

    selectClub = (clubId) => {
        this.props.setClubAdminClubId(clubId);
    }

    openClubSelectionModal = () => {
        this.setState({clubSelectionModalVisible: true});
    }

    closeClubSelectionModal = () => {
        this.setState({clubSelectionModalVisible: false});
    }


    render() {
        const admin = this.props.user.admin || [];
        const formattedClubs = admin.map(club => {
            return {label: club.name, id: club.id, img: club.logo_url, linkToClub: true}
        });
        return (
            <div
                className={`flex flex-row justify-between  border-b border-slate-200 p-6 absolute top-0 right-0 left-0 h-[60px] z-[999] bg-white`}
                style={{
                    transition: 'width 150ms ease-in-out',
                    overflow: "hidden"
                }}
            >
                <div className='flex flex-row items-center'>
                    <Link to={'/clubadmin'} className={'cursor-pointer flex flex-row items-center gap-2'} style={{}}>
                        <SvgLogoPrimary style={{height: 32, width: 120}}/>
                        <div
                            className={'ml-1 pl-2 border-l border-slate-300'}
                        >
                            <p className={`text-xs lg:text-[16px] text-black font-semibold`}>Club</p>
                            <p className={`text-xs lg:text-[16px] text-black font-semibold -mt-0.5`}>Admin</p>
                        </div>
                    </Link>
                    <ul className='flex flex-row ml-5 gap-1 lg:ml-14'>
                        {(CLUB_ADMIN_NAV_ITEMS.slice(0, 5)).map(item =>
                            <TopNavItem
                                item={item}
                                key={item.label}
                                isActive={this.state.lastRoute === item.route}
                            />
                        )}
                    </ul>
                </div>
                <ul className='ml-auto flex flex-row items-center gap-1'>
                    {formattedClubs.length > 0 && formattedClubs.length <= 5 ? (
                        <ul className='flex flex-row items-center ml-auto bg-primary/5 pl-[16px] overflow-hidden rounded-lg mr-3 gap-0'>
                            <p className={'text-sm font-semibold mr-3'}>
                                Groups:
                            </p>
                            <div className='flex flex-row max-w-[210px] overflow-scroll hide-scrollbar'>
                                {formattedClubs.map((item, index) => {
                                        return (
                                            <div onClick={() => this.selectClub(item.id)}>
                                                <TopNavItem
                                                    item={item}
                                                    key={item.label}
                                                    groupActive={(this.props.clubAdminClubId === item.id) ? true : ((!this.props.clubAdminClubId && index === 0))}
                                                />
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        </ul>
                    ) : (
                        <button
                            className="ml-auto"
                            onClick={this.openClubSelectionModal}
                        >
                            <TopNavItem
                                item={{label: "Switch club"}}
                                key={"Switch club"}
                            />
                        </button>
                    )}
                    <div onClick={() => {this.props.toggleClubAdminPortal(); this.props.history.push(`/club/${!this.props.clubAdminClubId ? this.props.user.admin[0].id : this.props.clubAdminClubId}`)}}
                        className={'flex relative flex-row items-center rounded-lg cursor-pointer'}>
                            <TopNavItem
                                item={{label: "Member Portal", icon:SvgExitToApp}}
                                key={"Member Portal"}
                            />
                    </div>
                </ul>
                <ModalComponent
                    backgroundColor={'white'}
                    header={'Select a group to view'}
                    headerToggle
                    isOpen={this.state.clubSelectionModalVisible} toggle={this.closeClubSelectionModal}>
                    <div className="p-4 max-h-[50vh] overflow-y-scroll">
                        {formattedClubs.map(club => (
                            <div key={club.id} className="mb-2">
                                <div
                                    onClick={() => {
                                        this.closeClubSelectionModal()
                                        this.selectClub(club.id)
                                    }}
                                    className="block py-2 px-4 bg-gray-100 hover:bg-gray-200 rounded-lg">
                                    {club.label}
                                </div>
                            </div>
                        ))}
                    </div>
                </ModalComponent>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClubAdminNavbar));
