import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import SvgClose from "../../components/icons/SvgClose";
import {useHistory, useLocation} from "react-router-dom";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
    };
}

const SidebarOverlayWrapper = ({open, onClose, children, label, width, user}) => {
    const [opened, setOpened] = useState(false);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (open) {
            setOpened(true);
        } else {
            setOpened(false);
        }
    }, [open]);

    useEffect(() => {
        const handleEscapeKeyPress = (event) => {
            if (event.key === "Escape" && opened) {
                handleClose();
            }
        };

        const handleLocationChange = () => {
            if (opened) {
                handleClose();
            }
        };

        document.addEventListener("keydown", handleEscapeKeyPress);
        const unlisten = history.listen(handleLocationChange);

        return () => {
            document.removeEventListener("keydown", handleEscapeKeyPress);
            unlisten();
        };
    }, [opened, history]);

    const handleClose = () => {
        setOpened(false);
        setTimeout(() => {
            onClose();
        }, 150);
    };

    return (
        <>
            {open && (
                <div
                    onClick={handleClose}
                    className="absolute top-0 left-0 w-[100vw] right-0 bottom-0 bg-black/30 backdrop-blur-[2px] z-[0] p-32"
                    style={{opacity: opened ? 1 : 0, transition: "all 200ms ease-in-out"}}
                ></div>
            )}

            <div
                className={`fixed right-0 top-0 ${!user.company_id && 'pt-[60px]'} flex-1 bottom-0 z-[0]  overflow-hidden`}
                style={{
                    opacity: 1,
                    width: opened ? (width || "50vw") : "0vw",
                    transition: "200ms ease-in-out",
                }}
            >
                <div className="col-plain flex-1 bg-white overflow-hidden p-5" style={{width: width || "50vw"}}>
                    <div className="flex flex-row items-center justify-between mb-5">
                        <p className="text-lg font-bold text-slate-800">{label}</p>
                        <div className="cursor-pointer text-neutral-500 hover:text-neutral-900 ml-auto"
                             onClick={handleClose}>
                            <SvgClose width={24} height={24}/>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
};

SidebarOverlayWrapper.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    label: PropTypes.string,
    width: PropTypes.string,
};

export default connect(mapStateToProps, null)(SidebarOverlayWrapper);
