import React, {Component} from "react";
import SvgMap from "../../components/icons/SvgMap";
import SvgHome from "../../components/icons/SvgHome";
import SvgBriefcase from "../../components/icons/SvgBriefcase";
import SvgEyeOff from "../../components/icons/SvgEyeOff";
import SvgVisibility from "../../components/icons/SvgVisibility";
import SvgEditFeather from "../../components/icons/SvgEditFeather";
import RecruiterCompanyEditOverview from "../modals/RecruiterCompanyEditOverview";


class RecruiterCompanyPageOverview extends Component {

    state = {
        editOverviewModalOpen: false
    }

    openEditOverviewModal = () => {
        this.setState({editOverviewModalOpen: true})
    }

    closeEditOverviewModal = () => {
        this.setState({editOverviewModalOpen: false})
    }

    render() {
        const company = this.props.company || {};
        const hidden = this.props.hidden || [];
        return (
            <div
                className={`bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl ${hidden.includes("overview") && 'opacity-50'}`}>
                <div className='flex flex-row items-center gap-2 mb-4'>
                    <p className='text-xl font-semibold text-slate-800'>
                        Overview
                    </p>
                    <button
                        onClick={this.openEditOverviewModal}
                        className='text-slate-500 hover:text-primary cursor-pointer'>
                        <SvgEditFeather className={'w-4 h-4'}/>
                    </button>
                    <button
                        onClick={() => this.props.hide("overview")}
                        className='text-slate-500 hover:text-primary cursor-pointer'>
                        {hidden.includes("overview") ?
                            <SvgVisibility className={'w-4 h-4'}/>
                            :
                            <SvgEyeOff className={'w-4 h-4'}/>
                        }
                    </button>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='flex flex-col gap-1'>
                        <SvgMap className={'text-primary w-5 h-5 mb-0.5'}/>
                        <p className='text-slate-800 text-md font-semibold'>
                            Number of locations
                        </p>
                        <p className='text-md text-slate-500'>
                            {company.num_locations || "None listed"}
                        </p>
                    </div>
                    <div className='flex flex-col gap-1'>
                        <SvgHome className={'text-primary w-5 h-5 mb-0.5'}/>
                        <p className='text-slate-800 text-md font-semibold'>
                            Headquarters
                        </p>
                        <p className='text-md text-slate-500'>
                            {company.headquarters || 'None listed'}
                        </p>
                    </div>
                    <div className='flex flex-col gap-1'>
                        <SvgBriefcase className={'text-primary w-5 h-5 mb-0.5'}/>
                        <p className='text-slate-800 text-md font-semibold'>
                            Job openings
                        </p>
                        <p className='text-md text-slate-500'>
                            {company.num_job_openings || "None listed"}
                        </p>
                    </div>
                </div>
                <RecruiterCompanyEditOverview
                    isOpen={this.state.editOverviewModalOpen}
                    company={company}
                    closeModal={this.closeEditOverviewModal}
                />
            </div>
        )
    }
}

export default RecruiterCompanyPageOverview;
