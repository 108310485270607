import React, {PureComponent} from "react";
import SvgSearch from "../icons/SvgSearch";
import {connect} from "react-redux";
import JobBoardLoading from "../loading/JobBoardLoading";
import ClubSearchListItem from "../clubsearch/ClubSearchListItem";
import {searchClubs, searchColleges} from "../../utils/typesense";
import {NUM_CLUBS_SHOWN} from "../../utils/constants";
import SearchHeaderWrapper from "../../components-recruiter/atoms/SearchHeaderWrapper";
import {Button} from "../atoms/Button";
import FadeInOnScroll from "../atoms/FadeInOnScroll";
import { pluralizeString } from "../../utils/strings";
import SelectClubListModal from "../../components-recruiter/modals/SelectClubListModal";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        applications: state.userReducer.applications,
    };
}

const initialFilters = {
    search: "",
    college: "",
}

class RecruiterClubSearch extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            clubPreview: {},
            clubs: [],
            originalClubs: [],
            loading: false,
            moreClubsLoading: false,
            clubPreviewOpen: false,
            page: 1,
            hideMoreClubs: false,
            selectedClubs: {},
            isSelectListModalOpen: false,
            ...initialFilters
        };
    }

    previewClub = (club) => {
        this.setState({clubPreview: club, clubPreviewOpen: true})
    }

    closeClubPreview = () => {
        this.setState({clubPreviewOpen: false})
    }

    onSelectClubChange = (club) => {
        if (this.state.selectedClubs[club.id]) {
          const { [club.id]: _, ...selectedClubs } = this.state.selectedClubs;
          this.setState({selectedClubs})
        } else {
          this.setState({selectedClubs: {...this.state.selectedClubs, [club.id]: club}})
        }
      }
  
    isClubSelected = (clubId) => {
        return !!this.state.selectedClubs[clubId] 
    }

    componentDidMount = async () => {
        const results = await searchClubs(this.state.search);
        this.setState({
            loading: false,
            clubs: results,
            originalClubs: results,
            hideMoreClubs: results.length < NUM_CLUBS_SHOWN
        });
    }

    getNextClubInfo = async () => {
        if (!this.props.showPaywall) {
            this.setState({moreClubsLoading: true});
            const newClubs = await searchClubs(this.state.search, this.state.page + 1, NUM_CLUBS_SHOWN, this.state.college);
            this.setState({
                clubs: [...this.state.clubs, ...newClubs],
                moreClubsLoading: false,
                page: this.state.page + 1,
                hideMoreClubs: newClubs.length < NUM_CLUBS_SHOWN,
            })
        }
    }

    handleSearchChange = (event) => {
        if (!this.props.showPaywall) {
            const text = event.target.value;
            this.setState({search: text});

            this.search(text);
        }
    }

    setSchool = (result) => {
        if (!result) {
            this.setState({page: 1})
        }
        this.setState({
            college: result
        })
        this.search(this.state.search, result);
    }

    search = async (text, college=null) => {
        if (text === "" && college === null) {
            this.setState({clubs: this.state.originalClubs, hideMoreClubs: false, page: 1});
            return;
        }
        const results = await searchClubs(text, 1, NUM_CLUBS_SHOWN, college);
        this.setState({clubs: results, loading: false, hideMoreClubs: results.length < NUM_CLUBS_SHOWN});
        window.analytics.track("Recruiter Club Search Performed", {
            query: text
        })
    }

    clearFilters = () => {
        this.setState({...initialFilters});
        this.setState({clubs: this.state.originalClubs});
    }

    render() {
        const clearFiltersVisible = 
            this.state.search || 
            this.state.college 
        return (
            <div className='flex flex-col overflow-hidden flex-1'>
                <SearchHeaderWrapper
                    search={{
                        placeholder: 'Search clubs...',
                        value: this.state.search,
                        onChange: this.handleSearchChange,
                    }}
                    setSchool={this.setSchool}
                    placeChildrenRight
                    clearFilters={clearFiltersVisible ? this.clearFilters : undefined}
                >
                    <div className='flex flex-row items-center justify-end gap-4'>
                        <p className='text-sm text-slate-500'>
                            {Object.values(this.state.selectedClubs).length} {pluralizeString('club', Object.values(this.state.selectedClubs).length)} selected
                        </p>
                        <Button
                            size="sm"
                            onClick={() => this.setState({isSelectListModalOpen: true})}
                            disabled={Object.values(this.state.selectedClubs).length === 0}
                        >
                            Add to list
                        </Button>
                    </div>
                </SearchHeaderWrapper>

              

                <div className={'flex flex-1 flex-col overflow-y-scroll'}>
                    <>
                        {this.state.loading ?
                            <JobBoardLoading/>
                            :
                            this.state.clubs.length === 0 ?
                                <div className='my-8 flex text-neutral-400 flex-col items-center justify-center flex-1'>
                                    <SvgSearch/>
                                    <p className='body2 mt-2 mb-6'>
                                        No clubs found...
                                    </p>
                                </div>
                                :
                                <>

                                    <div className='flex flex-col gap-3 mt-5'>
                                        {this.state.clubs.map((club, index) => {
                                            if (!club.description && !club.logo_url) {
                                                return
                                            }
                                            return (
                                                <FadeInOnScroll key={club.id} triggerImmediately
                                                            delay={(index * 50) + 10}>
                                                <ClubSearchListItem
                                                    item={club}
                                                    className={'border border-slate-200'}
                                                    isSelected={this.isClubSelected(club.id)}
                                                    onSelect={this.onSelectClubChange}
                                                />
                                                </FadeInOnScroll>
                                            )
                                        }
                                            
                                        )}
                                    </div>
                                    <SelectClubListModal
                                        items={Object.values(this.state.selectedClubs)}
                                        type={"club"}
                                        isOpen={this.state.isSelectListModalOpen}
                                        authUser={this.props.authUser}
                                        closeModal={() => this.setState({isSelectListModalOpen: false})}
                                        onUpdate={() => this.setState({selectedClubs: {}})}
                                    />
                                </>
                        }
                        {(!this.state.hideMoreClubs && (!this.state.loading && this.state.clubs.length !== 0)) &&
                            <div className='flex flex-row items-center justify-center py-8 body2-bold'
                                 onClick={this.getNextClubInfo}>
                                <Button loading={this.state.moreClubsLoading} variant={'ghost'}>
                                    Load more clubs
                                </Button>
                            </div>
                        }
                    </>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(RecruiterClubSearch);
