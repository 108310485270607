import React, {useRef, useEffect} from "react";


const VideoPreview = React.memo(({stream}) => {
    const videoRef = useRef(null);

    useEffect(() => {
        const videoElement = videoRef.current;

        if (videoElement && stream) {
            videoElement.srcObject = stream;

            videoElement.play().catch(error => {
                console.error('Error playing video:', error);
            });

            return () => {
                if (videoElement) {
                    videoElement.srcObject = null;
                }
            };
        }

        
    }, [stream]);

    if (!stream) {
        return null;
    }

    return (
        <video ref={videoRef} autoPlay className="w-full h-full object-cover scale-x-[-1]"/>
    );
}, (prevProps, nextProps) => prevProps.stream === nextProps.stream);

export default VideoPreview;
