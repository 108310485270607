import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class Spinner extends PureComponent {
    render() {
        return (
            <div className={this.props.light ? 'spinner-light' : "spinner"}
                 style={{width: this.props.size || '1em', height: this.props.size || '1em', ...this.props.style}}/>
        );
    }
}

Spinner.propTypes = {
    size: PropTypes.number
};

export default Spinner;
