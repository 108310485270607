import { toast } from "react-toastify";
import { useEffect } from "react";

export default function useVerificationCallback() {
    const location = window.location;
    const history = window.history;
    const searchParams = new URLSearchParams(location.search);

    const isEmailVerificationCallback = searchParams.get('email-verification') === 'true';

    useEffect(() => {
        if (isEmailVerificationCallback) {
            toast.success('Email verified!');
            searchParams.delete('email-verification');
            history.pushState({}, '', `${location.pathname}${searchParams.toString() ? '?' + searchParams.toString() : ''}`);
            
        }
    }, []);

    return isEmailVerificationCallback;
}