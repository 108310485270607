import React, {Component} from "react";
import SvgPlus from "../../components/icons/SvgPlus";
import {DateTime} from 'luxon';
import ClubPageAddSponsorModal from "./modals/ClubPageAddSponsorModal";
import SvgRemove from "../icons/SvgRemove";
import {connect} from "react-redux";
import { editClub } from "../../api/student/clubs";
import SvgVisibility from "../icons/SvgVisibility";
import SvgEyeOff from "../icons/SvgEyeOff";
import Tooltip from "../atoms/modals/Tooltip";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class ClubPageSponsorsWidget extends Component {
    state = {
        editSponsorModalOpen: false,
        addSponsorModalOpen: false
    }

    openAddSponsorModal = () => {
        this.setState({addSponsorModalOpen: true})
    }

    closeAddSponsorModal = () => {
        this.setState({addSponsorModalOpen: false})
    }

    remove = async (sp) => {
        const sponsors = [...this.props.club.sponsors];
        const index = sponsors.indexOf(sp);
        sponsors.splice(index, 1);
        
        await editClub(this.props.authUser, this.props.club.id, {sponsors});
        this.props.getClubInfo();
    }

    render() {
        const club = this.props.club || {};
        const sponsors = club.sponsors || [];
        const hidden = this.props.hidden || [];
        return (
            <div
                className={`bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl mt-5 ${hidden.includes("sponsors") && 'opacity-50'}`}>
                <div className='flex flex-row items-center gap-2 mb-2'>
                    <p className='text-xl font-semibold text-slate-800'>
                        Club Sponsors
                    </p>
                    {!this.props.loading && this.props.adminIds.includes(club.id) &&
                        <>
                            <button
                                onClick={this.openAddSponsorModal}
                                className='text-slate-500 hover:text-primary cursor-pointer'>
                                <SvgPlus className={'w-4 h-4'}/>
                            </button>
                            <Tooltip
                                toggleComponent={
                                    <button
                                        onClick={() => this.props.hide("sponsors")}
                                        className='text-slate-500 hover:text-primary cursor-pointer'>
                                        {hidden.includes("sponsors") ?
                                            <SvgVisibility className={'w-4 h-4'}/>
                                            :
                                            <SvgEyeOff className={'w-4 h-4'}/>
                                        }
                                    </button>
                                }
                                label={`${hidden.includes("sponsors") ? "Show" : "Hide"} this section when recruiters are viewing your club.`}
                            />
                        </>
                    }
                </div>
                <div className='flex flex-col gap-8'>
                    {sponsors.length === 0 &&
                        <p className='text-slate-500'>
                            No sponsors listed yet
                        </p>
                    }
                    <div className='flex flex-row items-center gap-10 flex-wrap gap-y-2 mt-2'>
                        {sponsors.map(item =>
                            <div className='flex flex-row items-center gap-3'>
                                <img
                                    alt={'deal'}
                                    src={item.logo_url}
                                    className='w-20 object-contain border border-slate-200 rounded-xl'
                                />
                                <div>
                                    <p className='text-slate-800 text-lg font-semibold'>
                                        {item.company_name}
                                    </p>
                                    <p className='text-slate-500 text-sm'>
                                        {DateTime.fromISO(item.date).isValid ? DateTime.fromISO(item.date).toFormat("MMM d, yyyy") : ""}
                                    </p>
                                </div>
                                {this.props.adminIds.includes(club.id) ?
                                    <div className="flex flex-row items-center" onClick={() => this.remove(item)}>
                                        <SvgRemove className={'w-5 h-5 text-neutral-400 hover:text-red-500 cursor-pointer'}/>
                                    </div>
                                : null}
                            </div>
                        )}
                    </div>
                    
                </div>
                <ClubPageAddSponsorModal
                    isOpen={this.state.addSponsorModalOpen}
                    closeModal={this.closeAddSponsorModal}
                    club={this.props.club}
                    getClubInfo={this.props.getClubInfo}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps)(ClubPageSponsorsWidget);
