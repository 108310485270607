import React, {PureComponent} from "react";
import TagComponent from "../../components/atoms/TagComponent";
import SvgPin from "../../components/icons/SvgPin";
import SvgBriefcase from "../../components/icons/SvgBriefcase";
import SvgArrow from "../../components/icons/SvgArrow";
import {Link} from "react-router-dom";
import RecruiterPeekEditStatus from "../modals/RecruiterPeekEditStatus";
import RecruiterSideStatusDisplay from "../atoms/RecruiterSideStatusDisplay";
import {connect} from "react-redux";
import {toHaveStyle} from "@testing-library/jest-dom/dist/matchers";

function mapDispatchToProps(dispatch) {
    return {
        toggleStudentPeek: () => {
            dispatch({
                type: "TOGGLE_STUDENT_PEEK",
            })
        },
        setStudentPeekStudent: (user) => {
            dispatch({
                type: "SET_STUDENT_PEEK_STUDENT",
                user,
            })
        },
    }
}


class RecruiterStudentPeekApplicationItem extends PureComponent {
    state = {
        notes: '',
        editStatusModalOpen: false
    }
    openEditStatusModal = () => {
        this.setState({editStatusModalOpen: true})
    }
    closeEditStatusModal = () => {
        this.setState({editStatusModalOpen: false})
    }

    notesChanged = e => this.setState({notes: e.target.value});

    navigateToJobListing = () => {
        this.props.close();
        const job = this.props.application.job;
        this.props.history.push({
            pathname: `/job-listing/${job.id}`,
            params: {
                name: job.name
            }}
        )
    }

    render() {
        const application = this.props.application || {};
        const answers = application.answers || {};
        const job = application.job || {};
        return (
            <div className='flex flex-col bg-slate-50 hover:bg-slate-100 group rounded-lg p-5 cursor-pointer' onClick={this.navigateToJobListing}>
                <div className='flex flex-row justify-between'>
                    <div>
                        <div>
                            <div className='flex flex-row text-primary items-center gap-1.5'>
                                <SvgBriefcase className={'w-3.5 h-3.5'}/>
                                <p className='text-sm text-primary font-semibold'>
                                    {!!job.class_years && job.class_years.length ? "Class of " + job.class_years.map(year => "'" + year.slice(2)) : null}
                                </p>
                            </div>
                        </div>
                        <p
                            className='text-lg font-semibold text-slate-800 group-hover:text-primary cursor-pointer'>
                            {job.name} <SvgArrow className={'inline w-5 h-5 mb-0.5'}/>
                        </p>
                        <div className='flex flex-row items-center gap-2 text-slate-500 wrap'>
                            {!!job.city.length && !!job.state.length ?
                                <div className='flex flex-row items-center gap-1'>
                                    <SvgPin className={'w-3.5 w-3.5'}/>
                                    <p className='text-md '>
                                        {job.city[0]}, {job.state[0]}
                                    </p>
                                </div>
                                : null}
                            {!!job.openings ?
                                <>
                                    <p>
                                        •
                                    </p>
                                    <p className='text-md '>
                                        <span className=''>{job.openings}</span> openings
                                    </p>
                                </>
                                : null}
                        </div>
                        <div className='mr-a mt-2 flex flex-row items-center gap-2 wrap'>
                            <TagComponent label={job.type}/>
                            <TagComponent label={job.industry}/>
                        </div>
                        <div className="mt-16">
                            {job.application_questions.map((question, i) => {
                                const answer = !!application.answers ? application.answers[i] : "N/A"
                                return (
                                    <div className='flex flex-col mb-2'>
                                        <p className='text-xs font-semibold'>
                                            {i+1 + ". "} {question.text}
                                        </p>
                                        <p className='text-xs text-semibold'>
                                            {answer}
                                        </p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                {/* <div className='mt-5'>
                    <label htmlFor="message" className="block mb-2 body2-bold text-neutral-400 dark:text-white">
                        Notes
                    </label>
                    <textarea id="message" rows="4"
                              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Enter private notes for your recruiting team..." onChange={this.notesChanged}
                              value={this.state.notes}></textarea>
                </div>
                <div className='flex flex-row items-center gap-8 mt-3'>
                    <div className='ml-auto flex flex-col gap-2 items-center'>
                        <p className='text-xs font-semibold text-slate-500'>
                            Status:
                        </p>
                        <RecruiterSideStatusDisplay value={'Applied'}/>
                    </div>
                    <button onClick={this.openEditStatusModal} className='primary-button body1-bold'>
                        Update Status
                    </button>
                </div>
                <RecruiterPeekEditStatus
                    type={'application'}
                    isOpen={this.state.editStatusModalOpen}
                    closeModal={this.closeEditStatusModal}
                /> */}
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(RecruiterStudentPeekApplicationItem);
