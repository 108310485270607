import ModalComponent from "../../components/atoms/modals/ModalComponent";
import { Button } from "../../components/atoms/Button";
import {DateTime} from 'luxon';
import { admitUserToClub } from "../../api/clubAdmin/user";
import { useState } from "react";
import Spinner from "../../components/Spinner";
import SvgVisibility from "../../components/icons/SvgVisibility";
import SvgDocument from "../../components/icons/SvgDocument";
import {Link} from "react-router-dom"

export const ViewApplicationSubmissionModal = ({user, authUser, isOpen, closeModal, application, application_questions, setSubmissions, history}) => {
    const [isLoadingAdmit, setIsLoadingAdmit] = useState(false);
    const [isLoadingDecline, setIsLoadingDecline] = useState(false);
    
    async function admitUser(approval_decision) {
        if (approval_decision) {
            setIsLoadingAdmit(true)
        } else {
            setIsLoadingDecline(true)
        }
        await admitUserToClub(authUser, application.user.username, application.club_id, application.id, approval_decision);
        setIsLoadingAdmit(false)
        setIsLoadingDecline(false)
        setSubmissions((submissions) => submissions.filter((submission) => submission.id !== application.id));
        closeModal();
    }

  if (!application) return null;

    return (
      <ModalComponent
        isOpen={isOpen}
        backgroundColor={"white"}
        header={`Application Submission from ${application.user.first_name} ${application.user.last_name}`}
        description={`Submitted: ${DateTime.fromISO(application?.created_at).toRelative()}`}
        headerToggle
        size={"lg"}
        toggle={closeModal}
        FooterComponent={
          <div className="flex flex-row items-center gap-3">
            <Button
              variant={"secondary"}
              onClick={() => admitUser(false)}
              disabled={isLoadingAdmit || isLoadingDecline}
                          >
             Decline Application {isLoadingDecline && <Spinner size={20} light={true} />}
            </Button>
            <Button
              onClick={() => admitUser(true)}
              disabled={isLoadingAdmit || isLoadingDecline}
            >
              Approve as Member {isLoadingAdmit && <Spinner size={20} light={true} />}
            </Button>
          </div>
        }
        footerAlignment={"right"}
      >
        <div className="flex flex-row justify-between mb-8">
         <div className="flex min-w-0 gap-x-4">
            <img
              src={
                application.user.profile_pic_url
                  ? application.user.profile_pic_url
                  : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fblank-person.jpeg?alt=media&token=ac3b0019-71c8-4830-932e-de437faf5fc8"
              }
              className="h-12 w-12 flex-none rounded-full bg-gray-50"
            />
            <div className="min-w-0 flex-auto">
              <p className="text-sm font-semibold leading-6 text-gray-900">
                <a className="hover:underline">
                  {application.user.first_name} {application.user.last_name}
                </a>
              </p>
              <p className="mt-1 flex text-xs leading-5 text-gray-500">
                <a
                  href={`mailto:${application.user.email}`}
                  className="truncate hover:underline"
                >
                  {application.user.email}
                </a>
              </p>
            </div>
          </div>
          <div className="flex shrink-0 items-center gap-x-6">
          {!application.user.resume ? null :
              <a className='flex flex-row items-center' href={application.user.resume} target={'_blank'}
                  rel={'noreferrer'}>
                  <div className='flex flex-row items-center'>
                      <SvgDocument className={'h-5 w-5'}/>
                      <p className='body2-bold ml-1'>
                          Resume
                      </p>
                  </div>
              </a>
              }
          <Link to={{
                pathname: '/studentprofile',
                state: {userId: application.user.username}
            }}>
            <Button
              // onClick={() => {
              //   history.push({pathname: "/studentprofile", state: {userId: application.user.id}});
              // }}
              icon={SvgVisibility}
              variant="outline"
            >
              View Full Profile
            </Button>
            </Link>
          </div>
        </div>
        {application_questions.map((question, index) => {
            return (
                <div key={index} className="flex flex-col gap-3">
                <div className="flex flex-row items-center gap-2">
                    <p className="text-lg font-semibold text-slate-800">
                    {question.questionText}
                    </p>
                </div>
                <div className="flex flex-row items-center gap-2">
                    <p className="text-md text-slate-500">{application.answers.find((answer) => answer.question_id === question.id).answerText ?? 'No answer received.'}</p>
                </div>
                </div>
            );
        })}
      </ModalComponent>
    );
}