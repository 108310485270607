import React from "react";

export default function EditProfileCtaButton({ onClick, children, disabled }) {
    return (
        <button
            className="bg-primary font-semibold text-white px-6 py-2 rounded-lg hover:bg-primary-800 w-full transition mt-auto disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
}
