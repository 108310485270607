import React, {PureComponent} from "react";
import SvgSearch from "../icons/SvgSearch";
import {connect} from "react-redux";
import SvgClose from "../icons/SvgClose";
import JobBoardLoading from "../loading/JobBoardLoading";
import PageHeaderWrapper from "../wrappers/PageHeaderWrapper";
import CompanyResultItem from "../jobboard/CompanyResultItem";
import {NUM_COMPANIES_SHOWN} from "../../utils/constants";
import {searchCompanies} from "../../utils/typesense";
import {Button} from "../atoms/Button";
import CompanyPeek from "./CompanyPeek";
import FadeInOnScroll from "../atoms/FadeInOnScroll";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        applications: state.userReducer.applications,
        constants: state.userReducer.constants
    };
}

class DiscoverCompanies extends PureComponent {
    constructor(props) {
        super(props);
        this.headingRef = React.createRef(); // Create a ref to access the component's DOM node
        this.state = {
            companyPreview: {},
            search: '',
            companies: [],
            originalCompanies: [],
            companiesLoading: false,
            moreCompaniesLoading: false,
            companyPeekOpen: false,
            grid: false,
            page: 1,
            hideMoreCompanies: false,
            industry: [],
            cities: [],
        };
    }

    previewCompany = (company) => {
        this.setState({companyPreview: company, companyPeekOpen: true})
        // this.props.history.push(`/company/${company?.id}`);
    }

    closeCompanyPreview = () => {
        this.setState({companyPeekOpen: false})
    }

    handleSearchChange = (event) => {
        const text = event.target.value;
        this.setState({search: text});

        this.search(text);
    }


    search = (text, industry = [], cities = []) => {
        searchCompanies(text, NUM_COMPANIES_SHOWN, 1, industry, cities, false).then(companyResults => {
            this.setState({
                companies: companyResults,
                loading: false,
                hideMoreCompanies: companyResults.length < NUM_COMPANIES_SHOWN
            });
        })
    }

    getNextCompanyInfo = async () => {
        this.setState({moreCompaniesLoading: true});
        const newCompanies = await searchCompanies(this.state.search, NUM_COMPANIES_SHOWN, this.state.page + 1, this.state.industry, this.state.cities);
        this.setState({
            companies: [...this.state.companies, ...newCompanies],
            moreCompaniesLoading: false,
            page: this.state.page + 1,
            hideMoreCompanies: newCompanies.length < NUM_COMPANIES_SHOWN,
        });
    }

    componentDidMount = async () => {
        this.search("");
    }

    updateIndustry = (selectedIndustry) => {
        const industryArray = [...this.state.industry];

        let updatedIndustry;
        if (industryArray.includes(selectedIndustry)) {
            updatedIndustry = industryArray.filter((item) => item !== selectedIndustry);
        } else if (selectedIndustry === null) {
            updatedIndustry = [];
        } else {
            updatedIndustry = [...industryArray, selectedIndustry]
        }

        this.setState({industry: updatedIndustry, page: updatedIndustry.length === 0 ? 1 : this.state.page});
        this.search(this.state.search, updatedIndustry);
    };

    updateCities = (selectedCity) => {
        const citiesArray = [...this.state.industry];

        let updatedCity;
        if (citiesArray.includes(selectedCity)) {
            updatedCity = citiesArray.filter((item) => item !== selectedCity);
        } else if (selectedCity === null) {
            updatedCity = [];
        } else {
            updatedCity = [...citiesArray, selectedCity]
        }

        this.setState({cities: updatedCity, page: updatedCity.length === 0 ? 1 : this.state.page});
        this.search(this.state.search, this.state.industry, updatedCity);
    };

    clearFilters = () => {
        this.setState({
            location: null,
            industry: [],
            page: 1
        });
        this.search("");
    }

    toggleGridView = (type) => {
        this.setState({grid: type === 'grid'})
    }


    render() {
        const clearFiltersVisible = this.state.location || this.state.industry?.length >= 1;
        const INDUSTRY_TAGS = this.props.constants.industry_tags.map(tag => ({title: tag, value: tag})); 
        const LOCATION_TAGS = this.props.constants.locations.map(loc => ({title: loc.city})); 
        return (
            <>
                <PageHeaderWrapper
                    className={'pt-2'}
                    search={{
                        placeholder: 'Search companies...',
                        value: this.state.search,
                        onChange: this.handleSearchChange,
                    }}
                    clearFilters={clearFiltersVisible &&
                        <div
                            onClick={this.clearFilters}
                            className='body2 base-white-30 hover:text-neutral-50 flex flex-row items-center ml-3 cursor-pointer'>
                            <SvgClose className={'w-5 h-5 mr-1'}/> Clear
                        </div>

                    }
                    selects={[
                        {
                            label: 'Industry',
                            value: this.state.industry,
                            setValue: this.updateIndustry,
                            options: INDUSTRY_TAGS,
                            multiSelect: true,
                        },
                        {
                            label: 'Cities',
                            value: this.state.cities,
                            setValue: this.updateCities,
                            options: LOCATION_TAGS,
                            multiSelect: true,
                        },
                    ]}
                />
                <div className='overflow-hidden flex-1'>
                    <div className={'overflow-y-scroll hide-scrollbar w-full flex-1 pb-5 px-5'}>
                        {this.state.companiesLoading ?
                            <JobBoardLoading/>
                            :
                            this.state.companies.length === 0 ?
                                <div className='my-8 flex text-neutral-400 flex-col items-center justify-center flex-1'>
                                    <SvgSearch/>
                                    <p className='body2 mt-2 mb-6'>
                                        No companies found...
                                    </p>
                                </div>
                                :
                                <div
                                    className={`flex flex-col`}>
                                    {this.state.companies.map((item, index) =>
                                        <FadeInOnScroll key={item.id} triggerImmediately
                                                        delay={(index * 50) + 10}>
                                            <CompanyResultItem
                                                openCompanyPeek={this.previewCompany}
                                                item={item}
                                                list={!this.state.grid}
                                            />
                                        </FadeInOnScroll>
                                    )}
                                </div>
                        }
                        {(!this.state.hideMoreCompanies && (!this.state.companiesLoading && this.state.companies.length !== 0)) &&
                            <div className='flex flex-row items-center justify-center py-8 body2-bold'
                                 onClick={this.getNextCompanyInfo}>
                                <Button loading={this.state.moreCompaniesLoading} variant={'ghost'}>
                                    Load more companies
                                </Button>
                            </div>
                        }
                    </div>
                    <CompanyPeek
                        company={this.state.companyPreview}
                        open={this.state.companyPeekOpen}
                        close={this.closeCompanyPreview}
                    />
                </div>
            </>
        )
    }
}

export default connect(mapStateToProps)(DiscoverCompanies);
