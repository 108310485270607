import React from 'react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import SvgCheck from "../../icons/SvgCheck";
import {Button} from "../Button";

const ClubSumbitSuccess = (props) => (
    <AlertDialog.Root open={props.open} close={props.close}>
        <AlertDialog.Portal>
            <AlertDialog.Overlay className="DialogOverlay"/>
            <AlertDialog.Content
                className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] rounded-xl max-w-[500px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
                <AlertDialog.Title className="title2 text-neutral-900">
                   <SvgCheck className={'text-primary'}/> <p className='mt-3'>
                    You're All Set!
                </p>
                </AlertDialog.Title>
                <AlertDialog.Description className="DialogDescription body2 text-neutral-400">
                    We'll reach out via email to help get your club set up. Thanks for choosing RecruitU!
                </AlertDialog.Description>
                <div className="flex justify-end gap-[25px]">
                    <AlertDialog.Action asChild onClick={props.close}>
                        <Button>
                            Sounds good!
                        </Button>
                    </AlertDialog.Action>
                </div>
            </AlertDialog.Content>
        </AlertDialog.Portal>
    </AlertDialog.Root>
);

export default ClubSumbitSuccess;
