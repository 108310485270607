import React, {PureComponent} from "react";
import NetworkingPageTableItem from "./NetworkingPageTableItem";
import ManualContactEntry from "../atoms/modals/ManualContactEntry";
import JobBoardLoading from "../loading/JobBoardLoading";
import {wait} from "@testing-library/user-event/dist/utils";
import ClubSumbitSuccess from "../atoms/modals/ClubSumbitSuccess";
import NewClubModal from "../atoms/modals/NewClubModal";
import SvgSearch from "../icons/SvgSearch";
import TableHeader from "../atoms/TableHeader";
import {NETWORK_TABLE_HEADERS} from "../../utils/dummy";
import {Button} from "../atoms/Button";
import SvgEmpty from "../icons/SvgEmpty";

class TrackedNetworkingWidget extends PureComponent {
    state = {
        filter: "All",
        newClubModalVisible: false,
        clubSubmitSuccessVisible: false,
        viewByCompany: false,
        selectedSortNetworks: null,
        networks: this.props.networks,
        viewAlumniModalOpen: false,
        selectedPerson: {},
    }

    openViewAlumniModal = (selectedPerson) => {
        this.setState({viewAlumniModalOpen: true, selectedPerson})
    }

    closeViewAlumniModal = () => {
        this.setState({viewAlumniModalOpen: false})
    }


    updateFilter = (filter) => {
        this.setState({filter});
    }

    closeNewClubModal = () => {
        this.setState({newClubModalVisible: false})
    }

    closeClubSubmitSuccesfulModal = () => {
        this.setState({clubSubmitSuccessVisible: false})
    }

    submitClub = () => {
        this.closeNewClubModal()
        wait(300).then(() =>
            this.setState({clubSubmitSuccessVisible: true})
        )
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.networks !== prevProps.networks) {
            this.changeSortNetworks(this.state.selectedSortNetworks, true)
        }
    }

    changeSortNetworks = (selectedSortNetworks, dontReverse = false) => {
        let data = [...this.props.networks];
        if (selectedSortNetworks === null) {

        } else if (this.state.selectedSortNetworks === selectedSortNetworks && !dontReverse) {
            data = [...this.state.networks];
            data.reverse()
        } else {
            if (selectedSortNetworks === 'Name') {
                data.sort((a, b) => a['person']['first_name'].localeCompare(b['person']['first_name']));
            } else if (selectedSortNetworks === 'Company') {
                data.sort((a, b) => a['person']['company_name'].localeCompare(b['person']['company_name']));
            } else if (selectedSortNetworks === 'Title') {
                data.sort((a, b) => a['person']['title'].localeCompare(b['person']['title']));
            } else if (selectedSortNetworks === 'Favorited') {
                data.sort((a, b) => {
                    const favoritedA = a['favorited'] ? 1 : 0;
                    const favoritedB = b['favorited'] ? 1 : 0;
                    return favoritedB - favoritedA;
                });
            } else if (selectedSortNetworks === 'Action Needed') {
                data.sort((a, b) => {
                    const flaggedA = a['action_required'] ? 1 : 0;
                    const flaggedB = b['action_required'] ? 1 : 0;
                    return flaggedB - flaggedA;
                });
            } else if (selectedSortNetworks === 'Status') {
                data.sort((a, b) => a['status'].localeCompare(b['status']));
            }
        }
        this.setState({selectedSortNetworks, networks: data});
    };


    render() {
        const activeTab = this.state.activeTab
        return (
            <div className='m-0 flex-1 relative '>
                <div className='  top-0 py-1'>
                    
                    {this.state.activeTab !== 'Companies' &&
                        <TableHeader
                            headers={NETWORK_TABLE_HEADERS}
                            selectedSort={this.state.selectedSortNetworks}
                            changeSort={this.changeSortNetworks}
                        />
                    }
                </div>
                <div className='flex flex-col flex-1 hide-scrollbar'>
                    {this.props.loading ?
                        <JobBoardLoading page={'networking'}/>
                        :
                        (this.props.networks.length === 0 && !this.props.loading) ?
                            <div
                                className='hidden only:flex text-neutral-400 flex-col items-center justify-center flex-1'>
                                <SvgEmpty/>
                                <p className='text-base mt-2 mb-2'>
                                    No contacts saved...
                                </p>
                                <p className='text-sm mb-6 w-[440px] text-center'>
                                    Add contacts manually, track your progress and take notes. Your saved network is
                                    completely private to you.
                                </p>
                                <div className='flex flex-row items-center gap-5'>
                                    <Button
                                        onClick={() => this.props.changeTab('Search')}>
                                        <SvgSearch className={'w-4 h-4'}/> Find people
                                    </Button>
                                    <ManualContactEntry updateNetwork={this.props.updateNetwork}
                                                        history={this.props.history}/>
                                    {/*<Button*/}
                                    {/*    onClick={() => this.setState({newClubModalVisible: true})}>*/}
                                    {/*    <SvgAddCircle className={'w-4 h-4'}/> New Club*/}
                                    {/*</Button>*/}
                                </div>
                            </div>
                            :
                            <div className={`flex flex-col ${activeTab === 'Companies' && ' gap-3 pt-3'}`}>
                                <>
                                    {this.state.networks.map((network, idx) => {
                                            if (this.state.filter === "All" || this.state.filter === network.status) {
                                                return (
                                                    <NetworkingPageTableItem openPeek={this.props.openPeek}
                                                                                key={`tracked-networking-widget-page-table-item${idx}`}
                                                                                network={network}
                                                                                showCompany
                                                                                updateNetwork={this.props.updateNetwork}
                                                                                openViewAlumniModal={this.openViewAlumniModal}/>
                                                )
                                            }
                                        }
                                    )}
                                </>
                            </div>
                    }
                </div>
                <NewClubModal
                    open={this.state.newClubModalVisible}
                    submitClub={this.submitClub}
                    close={this.closeNewClubModal}/>
                <ClubSumbitSuccess open={this.state.clubSubmitSuccessVisible}
                                   close={this.closeClubSubmitSuccesfulModal}/>
            </div>
        )
    }
}

export default TrackedNetworkingWidget
