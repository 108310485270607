import React, {PureComponent} from "react";
import SelectComponent from "../atoms/SelectComponent";
import MultiSelectComponent from "../atoms/MultiSelectComponent";
import CheckboxGroup from "../atoms/inputs/CheckboxGroup";
import { ACTIVELY_LOOKING_OPTIONS, ONBOARDING_CITIES } from "../onboarding/onboarding-constants";

class IndustryQuestions extends PureComponent {
    render() {
        const CITIES = ONBOARDING_CITIES.map(info => ({title: `${info.city}, ${info.state}`}));
        const LOOKING_OPTIONS = ACTIVELY_LOOKING_OPTIONS.map(info => ({title: info.label}));
        return (
            <>
                <div className="flex flex-col gap-8">
                    <SelectComponent
                        outerLabel={'What is the status of your job search?'}
                        label={'...'}
                        className={'w-full'}
                        value={this.props.seekingRole}
                        stateName={'seekingRole'}
                        light
                        setValue={this.props.handleSelectChange}
                        clearFilter={() => this.props.handleSelectChange('seekingRole', null)}
                        options={LOOKING_OPTIONS}
                        node={document.getElementById('personalization-modal')}
                    />
                    <CheckboxGroup
                        className="w-full"
                        outerLabel={'Where would you like to work?'}
                        value={this.props.locations}
                        onChange={(newValue) => this.props.handleMultiSelectChange('locations', newValue)}
                        options={CITIES}
                        orientation="row"
                    />
                </div>
            </>
        )
    }
}

export default IndustryQuestions
