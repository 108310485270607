import React, {PureComponent} from "react";
import SvgWhitelisted from "../icons/SvgWhitelisted";
import SvgInfo from "../icons/SvgInfo";
import SvgRecommended from "../icons/SvgRecommended";
import {green, greenLight, primary, primaryLight} from "../../utils/colors";
import TagComponent from "../atoms/TagComponent";
import CompanyLogo from "../atoms/CompanyLogo";
import Tooltip from "../atoms/modals/Tooltip";
import {Link, withRouter} from "react-router-dom";
import SvgBriefcase from "../icons/SvgBriefcase";
import {DateTime} from 'luxon';
import SvgChevronRight from "../icons/SvgChevronRight";
import RuDirectLabel from "../atoms/RuDirectLabel";
import JobStatusTrackerComponent from "../JobStatusTrackerComponent";
import SourceDisplay from "../atoms/SourceDisplay";

class JobBoardListItem extends PureComponent {

    navigateToCompany = (e, companyId) => {
        e.stopPropagation();
        this.props.history.push({
            pathname: '/company/' + companyId,
            state: { company_id: companyId, previousPage: { location: "jobboard", data: {} } }
        });
    };

    render() {
        const listing = this.props.item || {};
        const deadline = DateTime.fromISO(listing.deadline);
        const createdAt = DateTime.fromISO(listing.created_at);
        const tags = listing.tags || [];
        let location = null;
        if (!!listing.city.length) {
            if (listing.city.length === 1) {
                location = listing.city[0] + ", " + listing.state[0]
            } else {
                location = "Multiple Locations"
            }
        }
        const classYears = listing.class_years || [];
        classYears.sort();
        let yearText = '';

        if (classYears.length === 1) {
            yearText = `Class of '${classYears[0].toString().slice(-2)}`;
        } else if (classYears.length > 1) {
            const minYear = classYears[0].toString().slice(-2);
            const maxYear = classYears[classYears.length - 1].toString().slice(-2);
            yearText = `Class of '${minYear} - '${maxYear}`;
        }
        return (
            <li className={`card hover-card mb-2 p-3`}
                onClick={() => this.props.previewListing(listing)}
                style={{border: `1px solid ${this.props.previewingID === listing.id ? '#4848F2' : '#00000020'}`}}>
                {listing.exclusive &&
                    <RuDirectLabel className={'mb-2'}/>
                }
                {listing.recommended &&
                    <div className=' w-100p row-ac-jc mb-2 pv-4 br-8'
                         style={{backgroundColor: primaryLight, color: primary}}>
                        <SvgRecommended width={20} height={20}/>
                        <p className='m-0 font-semibold mh-4'>
                            Recommended for you
                        </p>
                        <Tooltip
                            toggleComponent={
                                <SvgInfo width={16} height={16}/>
                            }
                            label={'We matched this job based on your qualifications and interests.'}
                        />
                    </div>
                }
                <div className={'row-ac-jb'}>
                    <div className={`flex flex-row items-center`}>
                        {!this.props.hideLogo &&
                            <CompanyLogo id={listing.company_id}
                                         className={'w-20 h-20 mr-3'}/>
                        }
                        <div className={`col-jc`}>
                            <div className='mr-2'>
                                {!!yearText ?
                                    <div className='flex flex-row text-primary items-center gap-1.5'>
                                        <SvgBriefcase className={'w-3 h-3'}/>
                                        <p className='text-xs font-semibold'>
                                            {yearText}
                                        </p>
                                    </div>
                                    : null}
                                <p className='text-md font-semibold'>
                                    {listing.name}
                                </p>
                                <div className='flex flex-row items-center text-slate-500'>
                                    <div className='row-ac'>
                                        {!!listing.company_id ?
                                            <div className='row-ac hover:underline cursor-pointer'
                                                 onClick={(e) => this.navigateToCompany(e, listing.company_id)}>
                                                <p className='text-sm font-semibold'>
                                                    {listing.company_name}
                                                </p>
                                            </div>
                                            :
                                            <p className='text-sm font-semibold'>
                                                {listing.company_name}
                                            </p>
                                        }
                                    </div>
                                    <p className='text-sm m-0'>
                                        &nbsp;{!!location ? "•" : ""}&nbsp;{location}
                                    </p>
                                </div>
                            </div>
                            <div className='mt-1 flex flex-row items-center gap-2 flex-wrap'>
                                <TagComponent
                                    label={listing.type === "Job" ? "Full Time" : listing.type}
                                    type={'random'}
                                />
                                {!!listing.secondary_specialties && listing.secondary_specialties.filter(e => typeof e === "string").length > 0 ? 
                                    <div className='flex flex-row gap-1 whitespace-nowrap flex-wrap'>
                                        {listing?.secondary_specialties.filter(e => typeof e === "string").map(specialty =>
                                            <TagComponent
                                                label={specialty}
                                                type={'industry'}
                                            />
                                        )}
                                    </div>
                                    :
                                    <TagComponent
                                        label={listing.industry}
                                        type={'industry'}
                                    />
                                }
                                {createdAt.isValid ?
                                    <div className='mr-1 text-xs text-slate-500'>
                                        <p className='m-0'>
                                            Posted: {createdAt.toRelative()}
                                        </p>
                                    </div>
                                    : null}
                                {/* <div className='mt-0.5'>
                                    <SourceDisplay item={listing}/>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className={'row-ac'}>
                        {deadline.isValid ?
                            <div className={'mr-2'}>
                                <div className='flex flex-col items-center rounded-lg border-slate-200 border'>
                                    <p className='body4-bold uppercase p-0.5 px-3 bg-slate-100'>
                                        {deadline.toFormat('MMM')}
                                    </p>
                                    <p className='body2-bold p-1'>
                                        {deadline.toFormat('dd')}
                                    </p>
                                </div>
                            </div>
                            : null}
                        <JobStatusTrackerComponent listing={listing}/>
                    </div>
                </div>
            </li>
        )
    }
}

export default withRouter(JobBoardListItem);
