import React, { useState } from "react";
import { connect } from "react-redux";
import { editUser, getUser } from "../../api/student/users";
import { TOAST_OPTIONS } from "../../utils/constants";
import { toast } from 'react-toastify';
import AuthorizationSponsorshipQuestions from "./AuthorizationSponsorshipQuestions";
import EditProfileWrapper from "./EditProfileWrapper";
import EditProfileCtaButton from "./EditProfileCtaButton";
import { hasPersonalizationComplete } from "../../utils/profileUtils";
function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
    }
}


function EditProfileSponsorship({ authUser, setUser, user, loading, setLoading, goToNextSection, hasNextSection }) {
    const [state, setState] = useState(() => ({
        authorized: user.diversity?.authorized ?? '',
        requireSponsorship: user.diversity?.require_sponsorship ?? '',
    }));


    const updateProfile = async () => {
        try {
            setLoading(true);
            const params = {
                diversity: {
                    ...user.diversity ?? {},
                    authorized: state.authorized,
                    require_sponsorship: state.requireSponsorship,
                },
            }
            // because the steps are now in different sections the completed_personalization depends of both sections props
            if (hasPersonalizationComplete({...user, ...params})) {
                params.completed_personalization = true
            }
            await editUser(authUser, params);
            const { user: updatedUser } = await getUser(authUser);

            setUser(updatedUser);
            if (hasNextSection) {
                goToNextSection();
            }
        } catch (error) {
            toast.error('Failed to update sponsorship preferences!', TOAST_OPTIONS);
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = () => {
        if (!state.authorized || !state.requireSponsorship) {
            toast.error('Please fill out all fields!', TOAST_OPTIONS);
            return;
        }
        toast.promise(
            updateProfile,
            {
                pending: 'Updating preferences...',
                success: 'Preferences updated!',
                error: 'Failed to update preferences!',
            },
            TOAST_OPTIONS
        );
    }

    const handleSelectChange = (inputName, value) => {
        setState({...state, [inputName]: value});
    };


    return (
        <>
            <EditProfileWrapper>
                <div className='flex flex-col gap-5 min-h-[20vh] justify-center'>
                    <AuthorizationSponsorshipQuestions
                        authorized={state.authorized}
                        requireSponsorship={state.requireSponsorship}
                        handleSelectChange={handleSelectChange}
                    />
                </div>
            </EditProfileWrapper>
            <EditProfileCtaButton onClick={handleSubmit} disabled={loading} >
                {hasNextSection ? 'Save & continue' : 'Save'}
            </EditProfileCtaButton>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileSponsorship);
 