import React, { Component } from "react";
import { Button } from "../atoms/Button";
import CompanyLogo from "../atoms/CompanyLogo";
import SvgArrowOutward from "../icons/SvgArrowOutward";
import SvgLinkedIn from "../icons/SvgLinkedIn";
import { Link } from "react-router-dom";
import SvgChevronRight from "../icons/SvgChevronRight";
import NetworkStatusTrackerComponet from "../clubpage/NetworkStatusTrackerComponent";
import SvgSchool from "../icons/SvgSchool";
import TagComponent from "../atoms/TagComponent";

class DiscoverPersonItem extends Component {
  render() {
    const person = this.props.item;
    return (
      <div
        className={`card hover-card gap-2 flex flex-row items-center mb-2 p-3`}
      >
        <div className="flex flex-row items-center wrap flex-1 gap-3 mr-4">
          <CompanyLogo id={person?.company_id} className={"w-20 h-20"} backupUrl={person?.backup_logo_url}/>
          <div className="flex flex-col">
            <div className="flex flex-row items-center gap-1">
              <p className="text-md font-semibold">
                {person?.first_name} {person?.last_name}
              </p>
              {!!person?.linkedin ? (
                <a
                  className="row-ac text-neutral-400 hover:text-neutral-600 cursor-pointer"
                  target={"_blank"}
                  rel={"noreferrer"}
                  href={person?.linkedin}
                >
                  <SvgLinkedIn className={"w-5 h-5"} />
                </a>
              ) : null}
              <p className={"text-sm font-semibold text-primary"}>
                                  {!!person?.grade ? `Class of ${person.grade}` : null}
                </p>
            </div>
            <div className="flex flex-row items-center gap-1">
              {!!person.title && (
                <p className={`text-sm text-slate-500`}>{person.title}</p>
              )}
              {person?.title && person?.company_name && (
                <p className="text-sm text-slate-500">at</p>
              )}
              {!!person?.company_id ? (
                <div className="flex flex-row items-center gap-2 text-primary cursor-pointer">
                  <Link
                    to={{
                      pathname: `/company/${person.company_id}`,
                      state: { company_id: person.company_id },
                    }}
                  >
                    <div className="flex flex-row wrap items-center gap-0">
                      <p className="text-sm font-semibold text-primary">
                        {person.company_name}
                        {/*{person.company_name.length > 15 ? person.company_name.slice(0, 15) + '...' : person.company_name}*/}
                      </p>
                      <SvgChevronRight className={"w-5 h-5"} />
                    </div>
                  </Link>
                </div>
              ) : (
                <div className="row-ac primary">
                  <p className="text-sm font-semibold text-slate-500">
                    {person.company_name}
                  </p>
                </div>
              )}
            </div>
            <div className="mr-auto flex flex-row items-center gap-2 mt-1">
              <TagComponent label={person.industry} />
              {(!!person.school) && (
                <div
                  className={`flex flex-row text-slate-500 items-center gap-1.5`}
                >
                  <SvgSchool className={"w-3 h-3"} />
                  <p className="text-xs font-semibold">
                    {person.school}{" "}
                  </p>
                </div>
              )}
              {/*<div className='mt-[1px] opacity-50'>*/}
              {/*    <SourceDisplay item={person} isPerson/>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        <div className="flex-[.15] flex flex-row items-center justify-end">
          {this.props.adminPortal ? (
            <Button variant={"secondary"} icon={SvgArrowOutward}>
              View
            </Button>
          ) : (
            <NetworkStatusTrackerComponet
              personId={person.id}
              updateNetwork={this.props.updateNetwork}
              person={person}
              openPeek={this.props.openPeek}
              clubAlumni
              sm
            />
          )}
        </div>
      </div>
    );
  }
}

export default DiscoverPersonItem;
