import React from "react";
import ModalComponent from "./ModalComponent";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { sendVerificationEmail } from "../../../api/authentication";
import { toast } from "react-toastify";
import { useState } from "react";

const mapStateToProps = (state) => ({
    isOpen: state.userReducer.isPreApplyModalOpen,
});

function mapDispatchToProps(dispatch) {
    return {
        setPreApplyModalOpen: () =>
            dispatch({ type: "SET_PRE_APPLY_MODAL_OPEN" }),
        setPreApplyModalClosed: () =>
            dispatch({ type: "SET_PRE_APPLY_MODAL_CLOSED" }),
    };
}

function PreApplyModal({
    isOpen,
    setPreApplyModalOpen,
    setPreApplyModalClosed,
}) {
    const history = useHistory();
    const { user, authUser } = useSelector(state => state.userReducer)
    const emailVerified = user?.email_verified
    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);

    function toggleModal() {
        if (isOpen) {
            setPreApplyModalClosed();
        } else {
            setPreApplyModalOpen();
        }
    }
    function handleCTAClick() {

        if (!emailVerified) {
            setLoading(true);
            const verificationPromise = sendVerificationEmail(authUser).then(() => {
                setEmailSent(true);
            }).finally(() => {
                setLoading(false);
                toggleModal();
            });
            toast.promise(
                verificationPromise,
                {
                    pending: 'Sending verification email...',
                    success: 'Please check your email inbox/spam folder for verification link',
                    error: 'Failed to send verification email'
                }
            );
        } else {
            history.push('/edit-profile');
            toggleModal();
        }
    }
    return (
        <ModalComponent
            isOpen={isOpen}
            noPadding
            width={800}
            noMaxHeight
            backgroundColor={"white"}
            toggle={toggleModal}
            id={'pre-apply-modal'}
        >
            <div className="flex flex-col items-center justify-center text-center py-12 px-8">
                <h1 className="text-2xl font-bold mb-6">Unlock Your Job Search Potential!</h1>
                <p className="text-lg mb-8">Complete your profile to applying</p>
                <button
                    disabled={loading || emailSent}
                    className="bg-primary text-white font-bold py-3 px-8 rounded-full text-xl hover:bg-primary-dark transition duration-300"
                    onClick={handleCTAClick}
                >
                    {emailVerified ? 'Complete My Profile' : emailSent ? 'Email Sent' : 'Verify My Email'}
                </button>
            </div>
        </ModalComponent>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PreApplyModal);
