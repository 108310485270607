import React, {PureComponent} from "react";
import {RECRUITER_NAV_ITEMS} from "../utils/dummy";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import SideNavItem from "../components-recruiter/SideNavItem";
import SvgChevronLeft from "./icons/SvgChevronLeft";
import {wait} from "@testing-library/user-event/dist/utils";
import TopNavItem from "./navigation/TopNavItem";
import SvgLogo from "./icons/SvgLogo";
import SvgLogoPrimary from "./icons/SvgLogoPrimary";

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
    };
}

class RecruiterNavbar extends PureComponent {
    state = {
        newClubModalVisible: false,
        clubSubmitSuccessVisible: false,
        navbarOpen: false,
        forceClosed: false,
        lastRoute: '/'
    }

    componentDidMount() {
        this.checkRoute()
    }

    componentDidUpdate(prevProps) {
        // Check if the current route path differs from the previous one
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.checkRoute()
        }
    }

    checkRoute = () => {
        this.setState({activeRoute: this.props.location.pathname});
        const path = this.props.location.pathname
        if (path === '/' || path === '/search' || path === '/saved-lists' || path === '/engage' || path === '/company-page' || path === '/settings' || path === '/jobs' || path === '/companies') {
            this.setState({lastRoute: path})
        } else if (path.includes('/club') || path.includes('/profile')) {
            this.setState({lastRoute: ''})
        }
    }


    render() {
        const user = this.props.user || {};
        const profilePic = user.profile_pic_url ? user.profile_pic_url : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fblank-person.jpeg?alt=media&token=ac3b0019-71c8-4830-932e-de437faf5fc8";
        return (
            <div
                className={`flex flex-row justify-between  border-b border-slate-200 p-6 px-5 absolute top-0 right-0 left-0 h-[80px]`}
                style={{
                    transition: 'width 150ms ease-in-out',
                    overflow: "hidden"
                }}
            >
                <div className='flex flex-row items-center'>
                    <Link to={'/'} className={'cursor-pointer'} style={{width: 200}}>
                        <SvgLogoPrimary style={{height: 32, width: 120}}/>
                    </Link>
                    <ul className='flex flex-row items-center gap-1'>
                        {(RECRUITER_NAV_ITEMS.slice(0, 4)).map(item =>
                            <TopNavItem
                                item={item}
                                key={item.label}
                                isActive={this.state.lastRoute === item.route}
                            />
                        )}
                    </ul>
                </div>
                <ul className='ml-auto flex flex-row items-center gap-1'>
                    {(RECRUITER_NAV_ITEMS.slice(4)).map(item =>
                        <TopNavItem
                            item={item}
                            key={item.label}
                            isActive={this.state.lastRoute === item.route}
                        />
                    )}
                </ul>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(RecruiterNavbar));
