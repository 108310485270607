import React, {Component} from "react";
import SvgPlus from "../../components/icons/SvgPlus";
import SvgEyeOff from "../../components/icons/SvgEyeOff";
import SvgVisibility from "../../components/icons/SvgVisibility";
import RecruiterCompanyEditPerks from "../modals/RecruiterCompanyEditPerks";


class RecruiterCompanyPagePerks extends Component {
    state = {
        perksModalOpen: false,
    }

    openPerksModal = () => {
        this.setState({perksModalOpen: true})
    }

    closePerksModal = () => {
        this.setState({perksModalOpen: false})
    }


    render() {
        const company = this.props.company || {};
        const perks = company.perks || [];
        const hidden = this.props.hidden || [];
        return (
            <div
                className={`bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl ${hidden.includes("perks") && 'opacity-50'}`}>
                <div className='flex flex-row items-center gap-2 mb-2'>
                    <p className='text-xl font-semibold text-slate-800 mb-2'>
                        Perks
                    </p>
                    <button
                        onClick={this.openPerksModal}
                        className='text-slate-500 hover:text-primary cursor-pointer'>
                        <SvgPlus className={'w-4 h-4'}/>
                    </button>
                    <button
                        onClick={() => this.props.hide("perks")}
                        className='text-slate-500 hover:text-primary cursor-pointer'>
                        {hidden.includes("perks") ?
                            <SvgVisibility className={'w-4 h-4'}/>
                            :
                            <SvgEyeOff className={'w-4 h-4'}/>
                        }
                    </button>
                </div>
                <div className='flex flex-col gap-2'>
                    {perks.length === 0 &&
                        <p className='text-slate-500'>
                            No perks listed yet
                        </p>
                    }
                    {perks.map(item =>
                        <p className='text-slate-500 text-md'>
                            {item}
                        </p>
                    )}
                </div>
                <RecruiterCompanyEditPerks
                    isOpen={this.state.perksModalOpen}
                    closeModal={this.closePerksModal}
                    company={company}
                />
            </div>
        )
    }
}

export default RecruiterCompanyPagePerks;
