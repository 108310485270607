import React, {Component} from "react";
import {connect} from "react-redux";
import HorizontalDivider from "../components/atoms/HorizontalDivider";
import CollapseComponent from "../components/atoms/CollapseComponent";
import {Button} from "../components/atoms/Button";
import GoBack from "../components/atoms/GoBack";
import { getCompanyEmails } from "../api/recruiter/companies";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        recruiter: state.userReducer.user,
    };
}


class EmailHistoryPage extends Component {
    state = {
        emails: [],
        openEmailIds: {},
    };

    toggleEmailCollapse = (id) => {
        this.setState((prevState) => ({
            openEmailIds: {
                ...prevState.openEmailIds,
                [id]: !prevState.openEmailIds[id],
            },
        }));
    };

    componentDidMount = () => {
        this.getEmailHistory();
    }

    getEmailHistory = async () => {
        const res = await getCompanyEmails(this.props.authUser);
        this.setState({emails: res.emails})
    }

    renderEmailItem = (email) => {
        const isOpen = this.state.openEmailIds[email.id];
        const recepients = email.emails || [];
        return (
            <div key={email.id} className="flex flex-col bg-white shadow-md rounded-lg p-5 mb-4">
                <p className="text-xs text-slate-500">{email.entity}</p>
                <div className="flex flex-row justify-between items-center mb-2">
                    <p className="text-lg font-semibold text-slate-800">{email.subject}</p>
                </div>
                <CollapseComponent isOpen={!!isOpen}>
                    <div className="text-slate-700 mt-3 mb-5">
                        <div className="DraftEditor-root">
                            <div
                                className="public-DraftEditor-content"
                                dangerouslySetInnerHTML={{__html: email.text}}
                            />
                        </div>
                    </div>
                </CollapseComponent>
                <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => this.toggleEmailCollapse(email.id)}
                    className="mt-2"
                >
                    {isOpen ? "Show less" : "Show email"}
                </Button>
                <HorizontalDivider className="bg-slate-200 my-3"/>
                <p className="text-sm text-slate-700">
                    Recipients: {recepients.length < 10 ? recepients.join(", ") : `${recepients.slice(0,10).join(", ")} + ${recepients.length - 10} more`}
                </p>
            </div>
        );
    };

    render() {
        const {emails} = this.state;
        return (
            <div className="flex flex-col p-6 max-w-[800px] mx-auto">
                <GoBack/>
                <p className="text-2xl font-semibold mt-2 text-slate-800 mb-6">Email History</p>
                {emails.length > 0 ? (
                    emails.map(this.renderEmailItem)
                ) : (
                    <p className="text-sm text-slate-600">No emails sent yet.</p>
                )}
            </div>
        );
    }
}

export default connect(mapStateToProps)(EmailHistoryPage);
