import React from 'react';
import {Link} from "react-router-dom";

const LinksList = ({links}) => {
    return (
        <div className='flex flex-col gap-3'>
            {links.map((link, index) => {
                if (!!link.onClick) {
                    return (
                        <div
                            onClick={link.onClick}
                            key={index}
                            className='flex flex-row items-center gap-3 px-2 py-2 rounded-xl bg-slate-50 border-slate-200 border cursor-pointer text-slate-700 hover:bg-slate-100 hover:text-primary'>
                            {link.icon && React.createElement(link.icon, {className: 'w-4 h-4'})}
                            <p className='text-sm md:text-sm lg:text-md xl:text-md font-semibold'>
                                {link.label}
                            </p>
                        </div>
                    )
                }
                return (
                    <Link to={link.route}
                          onClick={() => window.analytics.track("Recruiter Click Quick Link", {linkType: link.label})}
                          key={index}
                          className='flex flex-row items-center gap-3 px-2 py-2 rounded-xl bg-slate-50 border-slate-200 border cursor-pointer text-slate-700 hover:bg-slate-100 hover:text-primary'>
                        {link.icon && React.createElement(link.icon, {className: 'w-4 h-4'})}
                        <p className='text-sm md:text-sm lg:text-md xl:text-md font-semibold'>
                            {link.label}
                        </p>
                    </Link>
                )
            })}
        </div>
    );
};

export default LinksList;
