import React, {Component} from "react";
import {connect} from "react-redux";
import SvgPlus from "../components/icons/SvgPlus";
import RecruiterJobPreviewCard from "./RecruiterJobPreviewCard";
import RecruiterEventPreviewCard from "./RecruiterEventPreviewCard";
import NewJobOrEventCard from "./atoms/NewJobOrEventCard";
import Spinner from "../components/Spinner";
import {getRecruiterJobs} from "../api/recruiter/jobs";
import {getRecruiterEvents} from "../api/recruiter/events";
import {Button} from "../components/atoms/Button";
import SectionHeader from "./SectionHeader";
import SvgMail from "../components/icons/SvgMail";
import SvgClock from "../components/icons/SvgClock";


function mapDispatchToProps(dispatch) {
    return {
        toggleCreateJobModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_JOB_MODAL",
            })
        },
        toggleCreateEventModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_EVENT_MODAL",
            })
        },
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

class RecruiterEngagePage extends Component {
    state = {
        jobs: [],
        events: [],
        jobsLoading: true
    }


    componentDidMount = async () => {
        const result = await getRecruiterJobs(this.props.authUser);
        const res = await getRecruiterEvents(this.props.authUser);

        this.setState({jobs: result.jobs, events: res.events, jobsLoading: false});
        window.analytics.page("Recruiter Engage Page");
    }

    navigateToEmail = () => {
        this.props.history.push({
            pathname: `/bulk-email`,
        })
    }

    navigateToEmailHistory = () => {
        this.props.history.push({
            pathname: `/email-history`,
        })
    }

    render() {
        const maxCardsPerScreen = window.innerWidth >= 1280
            ? 4  // xl: 5 columns
            : window.innerWidth >= 1024
                ? 3  // lg: 4 columns
                : window.innerWidth >= 768
                    ? 3  // md: 3 columns
                    : 2; // sm: 2 columns
        return (
            <div className={`flex flex-col flex-1 p-5 gap-3`}>
                <div className='flex flex-row items-center justify-between border-b border-slate-200 pb-5'>
                    <div>
                        <p className='text-slate-800 text-2xl font-semibold'>
                            Engage
                        </p>
                    </div>
                    <div className="gap-2 flex flex-row items-center">
                        <Button
                            variant={'default'}
                            onClick={(e) => {
                                e.stopPropagation();
                                this.navigateToEmail();
                            }}>
                            <SvgMail className={'w-4 h-4 mr-1'}/> Message Blast
                        </Button>
                        <Button
                            variant={'secondary'}
                            onClick={(e) => {
                                e.stopPropagation();
                                this.navigateToEmailHistory();
                            }}>
                            <SvgClock className={'w-4 h-4 mr-1'}/> Email History
                        </Button>
                        <div className='h-10 w-[1px] bg-slate-300 mx-2'/>
                        <Button
                            icon={SvgPlus}
                            onClick={this.props.toggleCreateJobModal}
                        >
                            New Job
                        </Button>
                        <Button
                            icon={SvgPlus}
                            onClick={this.props.toggleCreateEventModal}
                        >
                            New Event
                        </Button>
                    </div>
                </div>
                {this.state.jobsLoading ?
                    <div className='flex flex-col items-center justify-center flex-1 mb-[15vh]'>
                        <Spinner size={24}/>
                    </div>
                    :
                    <div>
                        <div className='flex flex-col gap-3'>
                            <SectionHeader label={'Events'} rightLabel={'View all'} route={'/events'}/>
                            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-2'>
                                {this.state.events.slice(0, maxCardsPerScreen).map(item =>
                                    <RecruiterEventPreviewCard item={item}/>
                                )}
                                {this.state.events.length < maxCardsPerScreen &&
                                    <NewJobOrEventCard
                                        index={0}
                                        label={'Post a new event'}
                                        onClick={this.props.toggleCreateEventModal}
                                    />
                                }
                            </div>
                        </div>
                        <div className='flex flex-col gap-3 mt-14'>
                            <SectionHeader label={'Jobs'} rightLabel={'View all'} route={'/jobs'}/>
                            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-2'>
                                {this.state.jobs.slice(0, maxCardsPerScreen).map((item, index) =>
                                    <RecruiterJobPreviewCard item={item}/>
                                )}
                                {this.state.jobs.length < maxCardsPerScreen &&
                                    <NewJobOrEventCard
                                        index={0}
                                        label={'Post a new job'}
                                        onClick={this.props.toggleCreateJobModal}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterEngagePage);
