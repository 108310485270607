import axios from '../axios';

export const getCompany = async (company_id) => {
    try {
        const config = {
            headers: {mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {company_id}
        };
        const res = await axios.get("/companies/public", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const editCompany = async (user, company_id, params_to_edit) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            updated_params: params_to_edit,
            company_id
        }
        const res = await axios.put("/admin/company", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getRecommendedCompanies = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/companies/recommended", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const getUserCompanies = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/companies/user", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

