import React from 'react';
import SvgCheck from "../../icons/SvgCheck";
import CompanyLogo from "../CompanyLogo"; // Ensure this path is correct

class CheckboxGroup extends React.Component {
    toggleCheckbox = (item) => {
        this.props.onChange(item);
    };

    switchContent = (option) => {
        switch (option.content) {
            case 'company_logo':
                return (
                    <CompanyLogo id={option.value} className={'w-8 h-8 mr-1'}/>
                )
            default:
                return null
        }
    }

    render() {
        const {options, value, orientation, outerLabel, gridClassName, className} = this.props;
        const containerClass = orientation === 'row' ? 'flex flex-row flex-wrap' : 'flex flex-col';

        return (
            <div className={`flex flex-col gap-2.5 ${className}`}>
                {outerLabel &&
                    <div className='text-xs font-medium text-slate-500'>
                        {outerLabel}
                    </div>
                }
                <div className={`${!gridClassName && containerClass} ${gridClassName} gap-4 gap-x-5`}>
                    {options.map(option => (
                        <label key={option.title} className="flex items-center cursor-pointer truncate">
                            <input
                                type="checkbox"
                                className="sr-only" // Visually hide the checkbox
                                checked={value.includes(option.title)}
                                onChange={() => {
                                    console.log(option)
                                    this.toggleCheckbox(this.props.useValue ? option : option.title)
                                }}
                            />
                            <div
                                className={`w-4 h-4 min-w-4 inline-flex justify-center items-center mr-2 rounded border-2 ${value.includes(option.title) ? 'bg-primary border-primary hover:border-primary/20 hover:bg-primary/80' : 'bg-transparent border-slate-400 hover:border-slate-500 focus:border-slate-500'}`}
                            >
                                {value.includes(option.title) && (
                                    <SvgCheck className="text-white"/> // Use SvgCheck for the checkmark
                                )}
                            </div>
                            <div className='flex flex-row items-center truncate flex-1'>
                                {this.switchContent(option)}
                                <span
                                    className={`text-sm truncate ${value.includes(option.title) ? 'text-slate-800' : 'text-slate-600'}`}>{option.title}</span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        );
    }
}

export default CheckboxGroup;
