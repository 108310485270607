import { useSelector, useDispatch } from 'react-redux';

export default function useUser() {
    const user = useSelector(state => state.userReducer.user);
    const authUser = useSelector(state => state.userReducer.authUser);
    const dispatch = useDispatch();
    const setUser = (user) => dispatch({
        type: 'SET_USER',
        user
    });

    return { user, setUser, authUser };
}
