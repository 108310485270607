import React, {Component} from "react";
import SvgSchool from "../components/icons/SvgSchool";
import FadeInOnScroll from "../components/atoms/FadeInOnScroll";
import CollegeSearchListItem from '../components/clubsearch/ClubSearchListItem';
import CollapseComponent from "../components/atoms/CollapseComponent";
import SchoolLogo from "./atoms/SchoolLogo";
import {connect} from "react-redux";

function mapDispatchToProps(dispatch) {
    return {
        toggleClubPeek: () => {
            dispatch({
                type: "TOGGLE_CLUB_PEEK",
            })
        },
        setClubPeekClub: (user) => {
            dispatch({
                type: "SET_CLUB_PEEK_CLUB",
                user,
            })
        },
    }
}

class RecruiterSchoolClubCollapse extends Component {
    state = {
        isOpen: false,
        clubs: [],
    }

    toggleOpen = async () => {
        this.setState({isOpen: !this.state.isOpen})
    }

    openClubPeek = () => {
        this.props.toggleClubPeek();
        this.props.setClubPeekClub(this.props.school);
    }

    render() {
        const school = this.props.school || {};
        const clubs = school.clubs || []
        return (
            <div className={`flex flex-col border border-slate-200 rounded-xl overflow-hidden`} key={school.id}>
                <div onClick={!this.props.clubs ? this.toggleOpen : this.openClubPeek}
                     className={`company-collapse flex flex-row items-center ${this.state.isOpen && 'border-b  border-slate-200'} justify-between cursor-pointer hover:bg-slate-50 p-3`}>
                    <div className='flex flex-row items-center gap-2'>
                        <SchoolLogo image={school.logo_url}/>
                        <p className='text-lg font-semibold text-slate-800'>
                            {school.name}
                        </p>
                    </div>
                    <div className='flex flex-row items-center gap-2'>
                        <div className='company-collapse__button body2-bold mr-12'>
                            {!this.state.isOpen ?
                                'View Clubs' : 'Hide Clubs'
                            }
                        </div>
                    </div>
                </div>
                {!this.props.clubs ? 
                <CollapseComponent onClick={!!this.props.clubs ? null : null} isOpen={this.state.isOpen}>
                    <>
                    {clubs.map((item, index) =>
                        <FadeInOnScroll key={item.id} triggerImmediately
                                        className={'border-b border-slate-200 last:border-none p-3'}
                                        delay={(index * 50) + 10}
                        >
                            <CollegeSearchListItem
                                key={index}
                                item={item}
                                previewClub={this.props.previewClub}
                                previewingID={this.props.clubPreview.id}
                            />
                        </FadeInOnScroll>
                    )}
                    </>
                </CollapseComponent>
                :
                    null
                }
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(RecruiterSchoolClubCollapse);
