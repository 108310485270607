import React, {useRef, useState} from 'react';
import SelectableItem from "../onboarding/SelectableItem";
import classnames from 'classnames';
import SelectedTag from "../onboarding/SelectedTag";
import useIsMobile from '../../hooks/isMobile';
import EditProfileWrapper from "./EditProfileWrapper";
import EditProfileCtaButton from "./EditProfileCtaButton";
import useUser from '../../hooks/useUser';
import { editUser, getUser } from '../../api/student/users';
import { toast } from 'react-toastify';
import * as Accordion from "@radix-ui/react-accordion";
import SvgChevronDown from "../icons/ChevronDown";

const isConsultingRole = (role) => {
    return role.endsWith('Consulting');
}


function EditProfileRoles({constants, goToNextSection, hasNextSection, loading, setLoading}) {
    
    const INDUSTRY_TAGS = constants?.industry_tags?.map(tag => ({title: tag, value: tag})) ?? [];
    const FINANCE_ROLES = constants?.secondary_tags?.filter(tag => !isConsultingRole(tag)).map(tag => ({
        title: tag,
        value: tag
    })) ?? [];
    const CONSULTING_ROLES = constants?.secondary_tags?.filter(tag => isConsultingRole(tag)).map(tag => ({
        title: tag,
        value: tag
    })) ?? [];
    const validRoles = [...FINANCE_ROLES, ...CONSULTING_ROLES].map(role => role.value);
    
    const { user, setUser, authUser } = useUser();
    const [activeIndustry, setActiveIndustry] = useState(() => {
        return user.industries.find(ind => INDUSTRY_TAGS.map(tag => tag.value).includes(ind));
    });

    const [selectedIndustries, setSelectedIndustries] = useState(() => {

        const validIndustries = INDUSTRY_TAGS.map(tag => tag.value);
       
        // If industries contains role interests, move them to roles
        const industries = user.industries ?? [];
        const rolesToMove = industries.filter(ind => validRoles.includes(ind));
        const cleanIndustries = industries.filter(ind => validIndustries.includes(ind));

        if (rolesToMove.length > 0) {
            // Map roles to Consulting/Finance categories
            return [
                ...new Set([
                    ...cleanIndustries,
                    ...rolesToMove.map(role => isConsultingRole(role) ? 'Consulting' : 'Finance')
                ])
            ];
        }

        // Handle normal role_interests case
        if (user.role_interests?.length) {
            return [
                ...new Set(
                    user.role_interests.map(role => isConsultingRole(role) ? 'Consulting' : 'Finance')
                )
            ];
        }

        return cleanIndustries;
    });

    const [selectedRoles, setSelectedRoles] = useState(() => {
        
        // If industries contains role interests, use those as initial roles
        const industries = user.industries ?? [];
        const rolesToMove = industries.filter(ind => validRoles.includes(ind));

        if (rolesToMove.length > 0) {
            return rolesToMove;
        }

        // Otherwise use role_interests if they exist
        return (user.role_interests ?? []).filter(role => validRoles.includes(role));
    });
    const roleContainerRef = useRef(null);
    const isMobile = useIsMobile();


    const handleIndustryClick = (label) => {
        if (selectedIndustries.includes(label)) {
            setSelectedIndustries(selectedIndustries.filter(item => item !== label));
        } else {
            setSelectedIndustries([...selectedIndustries, label]);
            setActiveIndustry(label);
        }
    };

    const handleRoleClick = (label, isFromSelectedTag = false) => {
        if (selectedRoles.includes(label)) {
            setSelectedRoles(selectedRoles.filter(item => item !== label));
        } else {
            setSelectedRoles([...selectedRoles, label]);
        }

        if (isMobile && roleContainerRef.current && !isFromSelectedTag) {
            roleContainerRef.current.scrollTo({
                left: roleContainerRef.current.scrollWidth,
                behavior: 'smooth'
            });
        }
    };


    

    const updateProfile = async () => {
       try {
        setLoading(true);
        await editUser(authUser, {
            role_interests: selectedRoles,
            industries: selectedIndustries
        });
        const { user: userUpdated } = await getUser(authUser);
        setUser(userUpdated);
        if (hasNextSection) {
            goToNextSection();
        }
       } catch (error) {
        throw error;
       } finally {
        setLoading(false);
       }
    }

    const handleSubmit = async () => {
       if (selectedRoles.length === 0) {
            toast.error('Please select at least one role');
            return;
       }
       toast.promise(updateProfile, {
            pending: 'Updating profile...',
            success: 'Profile updated successfully!',
            error: 'Failed to update profile'
        }); 
    }

    return (
        <>
            <EditProfileWrapper>
                <div className='flex flex-col gap-3 md:gap-5'>
                    <p className='text-lg md:text-xl font-semibold'>Your industry picks</p>
                    <div className='flex flex-wrap gap-2 max-h-[10vh] md:max-h-[auto] overflow-y-auto'>
                        <p className='text-slate-500 hidden only:flex text-sm md:text-base'>
                            Select industries below...
                        </p>
                        {selectedIndustries.map(industry => (
                            <SelectedTag key={industry} label={industry} onClose={handleIndustryClick}/>
                        ))}
                    </div>
                    <div className='flex flex-row items-center justify-between'>
                        <p className='text-lg md:text-xl font-semibold'>Industries</p>
                    </div>
                    <div>
                        <div
                            className='grid grid-cols-2 md:grid-cols-3 gap-2'>
                            {INDUSTRY_TAGS.map(industry => (
                                <SelectableItem
                                    key={industry.value}
                                    label={industry.title}
                                    isSelected={selectedIndustries.includes(industry.title)}
                                    onClick={handleIndustryClick}
                                />
                            ))}
                        </div>
                    </div>
                    {selectedIndustries.length > 0 && (
                        <>
                            <p className='text-lg md:text-xl font-semibold'>Your role picks</p>
                            <div className='flex md:flex-wrap gap-2 overflow-x-auto relative' ref={roleContainerRef}>
                                <p className='text-slate-500 hidden only:flex text-sm md:text-base'>
                                    Select roles below...
                                </p>
                                {selectedRoles.map(role => (
                                    <SelectedTag key={role} label={role} onClose={() => handleRoleClick(role, true)}/>
                                ))}
                            </div>
                        </>
                    )}
                    <div>
                        <Accordion.Root type="single" collapsible value={activeIndustry} onValueChange={setActiveIndustry}>
                            {selectedIndustries.map(industry => {
                                const industryOptions = industry === 'Finance' ? FINANCE_ROLES : CONSULTING_ROLES;
                                return (
                                    <Accordion.Item key={industry} value={industry}>
                                        <div key={industry} className='mb-4 relative'>
                                            <Accordion.Trigger className='w-full bg-slate-100 mb-2 rounded-xl flex items-center justify-between px-4 py-2'>
                                                <p className='text-md md:text-lg font-medium '>{industry} Roles</p>
                                                <SvgChevronDown className={`w-4 h-4 ${activeIndustry === industry ? 'rotate-180' : 'rotate-0'}`}/> 
                                            </Accordion.Trigger>
                                            <Accordion.Content className='md:overflow-y-auto'>
                                                {industry === 'Finance' && (
                                                    <div
                                                        className="absolute right-0 top-0 bottom-0 w-16 pointer-events-none bg-gradient-to-l from-white to-transparent md:hidden"/>
                                                )}
                                                <div className={classnames(
                                                    'flex flex-nowrap overflow-x-auto flex-row  md:grid md:grid-cols-3 gap-2 md:overflow-y-auto',
                                                )}>

                                                    {industryOptions.map(option => (
                                                        <SelectableItem
                                                            key={option.value}
                                                            label={option.title}
                                                            isSelected={selectedRoles.includes(option.value)}
                                                            onClick={handleRoleClick}
                                                        />
                                                    ))}
                                                </div>
                                            </Accordion.Content>
                                        </div>
                                    </Accordion.Item>
                                );
                            })}
                        </Accordion.Root>
                    </div>
                </div>
            </EditProfileWrapper>
            <EditProfileCtaButton onClick={handleSubmit} disabled={loading}>
                {hasNextSection ? 'Save & Continue' : 'Save'}
            </EditProfileCtaButton>
        </>
    );
}

export default EditProfileRoles;
