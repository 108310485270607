import React, {PureComponent} from "react";
import {connect} from "react-redux";
import ProfilePageTitleWidget from "../profilepage/ProfilePageTitleWidget";
import ProfilePageAboutWidget from "../profilepage/ProfilePageAboutWidget";
import ProfilePageExperienceWidget from "../profilepage/ProfilePageExperienceWidget";
import ProfilePageEducationWidget from "../profilepage/ProfilePageEducationWidget";
import ProfilePageClubsWidget from "../profilepage/ProfilePageClubsWidget";
import {getUser} from "../../api/student/users";
import PageContentWrapper from "../wrappers/PageContentWrapper";
import PageLTRWrapper from "../wrappers/PageLTRWrapper";
import PageRightWrapper from "../wrappers/PageRightWrapper";
import PageLeftWrapper from "../wrappers/PageLeftWrapper";
import JobBoardLoading from "../loading/JobBoardLoading";
import Spinner from "../Spinner";
import ProfilePageDemographics from "../profilepage/ProfilePageDemographics";

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
    }
}

class ProfilePage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            user: {},
            loading: true,
            recruiter: false,
        };
    }

    componentDidMount = async () => {
        const props = this.props.location.state || {};
        const queriedUser = props.userId;
        const user = await getUser(this.props.authUser, queriedUser);
        this.setState({user: user.user, loading: false, recruiter: props.recruiter});
    }


    render() {
        if (this.state.loading) {
            return <div className={"flex flex-row items-center justify-center my-auto"}>
                <Spinner size={24}/>
            </div>
        }
        const bodyHeight = this.state.height + 69
        const user = this.state.user || {};
        return (
            <PageContentWrapper>
                <PageLTRWrapper>
                    <PageLeftWrapper className={'overflow-y-scroll hide-scrollbar pt-5'}>
                        <ProfilePageTitleWidget user={user} recruiter={this.state.recruiter} studentProfilePage />
                        <ProfilePageAboutWidget user={user} recruiter={this.state.recruiter} studentProfilePage clubAdmin={this.props.clubAdmin}/>
                        <ProfilePageExperienceWidget experiences={user.experiences} studentProfilePage/>
                        <ProfilePageEducationWidget education={user.education} studentProfilePage/>
                    </PageLeftWrapper>
                    <PageRightWrapper open>
                        <div className='flex flex-col flex-1 overflow-y-scroll pt-5'>
                            {/* <ProfilePageSeekingRoleWidget user={user} editSeekingRole={this.editSeekingRole}/> */}
                            <ProfilePageClubsWidget user={user} recruiter={this.state.recruiter} studentProfilePage/>
                        </div>
                    </PageRightWrapper>
                </PageLTRWrapper>
            </PageContentWrapper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
