import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'} d="M479-120L189-279v-240L40-600l439-240 441 240v317h-60v-282l-91 46v240L479-120zm0-308l315-172-315-169-313 169 313 172zm0 240l230-127v-168L479-360 249-485v170l230 127zm1-240zm-1 74zm0 0z" />
        </svg>
    )
}

export default SvgComponent
