import React, {PureComponent} from "react";
import { COMPANY_FOLLOW_OPTIONS } from "../../utils/constants";
import CheckboxGroup from "../atoms/inputs/CheckboxGroup";


class PreferenceQuestions extends PureComponent {
    render() {
        return (
            <>
                <div className="flex flex-col gap-8">
                    <CheckboxGroup
                        className="w-full"
                        outerLabel={'What industry are you most interested in?'}
                        value={this.props.industry}
                        onChange={(newValue) => this.props.handleMultiSelectChange('industry', newValue)}
                        options={[{title: "Private Equity",}, {title: "Venture Capital / Growth Equity"}, {title: "Investment Banking"}, {title: "Equity Research"}, {title: "Asset Management"}, {title: "Sales and Trading"}, {title: "Risk Management"}, {title: "Corporate Finance"}, {title: "Wealth Management"}, {title: "Quantitative Trading"}, {title: "Consulting"}]}
                        orientation="row"
                    />
                    {/* <CheckboxGroup
                        className="w-full"
                        outerLabel={'Which of the following companies would you like to follow?'}
                        value={this.props.companies}
                        onChange={(e) => {
                            this.props.handleMultiSelectChange('companies', e.title);
                            this.props.handleMultiSelectChange('companyIds', e.value)
                        }}
                        useValue
                        options={COMPANY_FOLLOW_OPTIONS}
                        gridClassName={'grid grid-cols-4 gap-y-2'}
                    /> */}
                </div>
            </>
        )
    }
}

export default PreferenceQuestions
