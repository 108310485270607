import React, {PureComponent} from "react";
import AddJobCompany from "./SearchCompany";
import CollapseComponent from "../atoms/CollapseComponent";

class AddJobTitleDescription extends PureComponent {

    render() {
        return (
            <>
                <div className="col-plain w-full">
                    <p className="body3-bold text-neutral-400 mb-1">
                        Job Listing URL (*)
                    </p>
                    <input
                        name="url"
                        value={this.props.url}
                        onChange={this.props.handleInputChange}
                        placeholder={'Paste full URL to application here...'}
                        className='input-light px-3 body2 base-black-50 w-full'
                    />
                </div>
                <CollapseComponent isOpen={this.props.url?.length > 0}>
                    <div className='flex flex-col gap-3'>
                        <div className="col-plain w-full">
                            <p className="body3-bold text-neutral-400 mb-1">
                                Job Title (*)
                            </p>
                            <input
                                name="jobTitle"
                                value={this.props.jobTitle}
                                onChange={this.props.handleInputChange}
                                placeholder={'Job Title'}
                                className='input-light px-3 body2 base-black-50 w-full'
                            />
                        </div>
                        <AddJobCompany setCompany={this.props.setCompany}
                                       companyChanged={this.props.companyChanged}
                                       searchResults={this.props.searchResults}/>
                        <div className="col-plain w-full">
                            <p className="body3-bold text-neutral-400 mb-1">
                                Job Description
                            </p>
                            <textarea
                                name="jobDescription"
                                value={this.props.jobDescription}
                                onChange={this.props.handleInputChange}
                                placeholder={'Job Description'}
                                className='input-light text-sm px-3 py-3 body2 base-black-50 w-full min-h-[120px]'
                            />
                        </div>
                    </div>
                </CollapseComponent>
            </>
        )
    }
}

export default AddJobTitleDescription
