import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'}
                  d="M180-180h400v-200h200v-400H180v600zm0 60q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v420L600-120H180zm120-300v-60h170v60H300zm0-160v-60h360v60H300zM180-180v-600 600z"/>
        </svg>
    )
}

export default SvgComponent
