import React, {PureComponent} from "react";
import TextBlockShimmer from "../loading/TextBlockShimmer";
import SvgPlus from "../icons/SvgPlus";
import SvgEditFeather from "../icons/SvgEditFeather";
import FreeTextInputModal from "../modals/FreeTextInputModal";
import { editClub } from "../../api/student/clubs";
import SvgVisibility from "../icons/SvgVisibility";
import SvgEyeOff from "../icons/SvgEyeOff";
import Tooltip from "../atoms/modals/Tooltip";
import {connect} from "react-redux";
import HTMLRenderer from "../../components-club-admin/htmlRenderer";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}


class ClubPageAboutWidget extends PureComponent {
    state = {
        description: '',
        freeTextInputModalOpen: false,
    }

    componentDidMount = () => {
        this.setState({description: this.props.club.description})
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.club !== this.props.club) {
            this.setState({description: this.props.club.description})
        }
    }

    openFreeTextInputModal = () => {
        this.setState({freeTextInputModalOpen: true})
    }

    closeFreeTextInputModal = () => {
        this.setState({freeTextInputModalOpen: false})
    }

    handleInputChange = (e) => {
        this.setState({description: e.target.value});
    };

    save = async (value) => {
        await editClub(this.props.authUser, this.props.club.id, {
            description: value
        });
        this.props.getClubInfo();
        window.analytics.track("Club Edited", {
            description: value
        })
    }

    render() {
        const club = this.props.club;
        const hidden = this.props.hidden || [];
        return (
            <>
                    <div
                        className={`bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl mt-5 ${hidden.includes("description") && 'opacity-50'}`}>
                        <div className='flex flex-row items-center gap-2 mb-2'>
                            <p className='text-xl font-semibold text-slate-800'>
                                About {club?.name}
                            </p>
                            {!this.props.loading && this.props.isAdmin &&
                            <>
                                <button
                                    onClick={this.openFreeTextInputModal}
                                    className='text-slate-500 hover:text-primary cursor-pointer'>
                                    {club?.description && club?.description.length === 0 ?
                                        <SvgPlus className={'w-4 h-4'}/>
                                        :
                                        <SvgEditFeather className={'w-4 h-4'}/>
                                    }
                                </button>
                                <Tooltip
                                    toggleComponent={
                                        <button
                                            onClick={() => this.props.hide("description")}
                                            className='text-slate-500 hover:text-primary cursor-pointer'>
                                            {hidden.includes("description") ?
                                                <SvgVisibility className={'w-4 h-4'}/>
                                                :
                                                <SvgEyeOff className={'w-4 h-4'}/>
                                            }
                                        </button>
                                    }
                                    label={`${hidden.includes("description") ? "Show" : "Hide"} this section when recruiters are viewing your club.`}
                                />
                            </>
                            }
                        </div>
                        {this.props.loading ?
                            <>
                                <TextBlockShimmer/>
                            </>
                            :
                            club?.description ?
                            <HTMLRenderer htmlString={club.description} />
                                
                            :
                            <p className='text-slate-500'>
                                No description created yet
                            </p>
                        }
                    </div>
                <FreeTextInputModal
                    isOpen={this.state.freeTextInputModalOpen}
                    title={'Edit club about section'}
                    placeholder={'Tell recruiters and students more about your club'}
                    value={this.state.description}
                    handleInputChange={this.handleInputChange}
                    closeModal={this.closeFreeTextInputModal}
                    save={this.save}
                    disabled={!this.state.description}
                />
            </>
        )
    }
}

export default connect(mapStateToProps)(ClubPageAboutWidget)
