import {getAuth} from 'firebase/auth'
import {getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage'
import {initializeApp} from 'firebase/app'
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBnOV5iHEjM5Sdzvi8KyCXmyGiKkSBV_y8",
  authDomain: "recruit-u-f79a8.firebaseapp.com",
  projectId: "recruit-u-f79a8",
  storageBucket: "recruit-u-f79a8.appspot.com",
  messagingSenderId: "647665709774",
  appId: "1:647665709774:web:a0a8189b9ae693fff1d69f",
  measurementId: "G-6W1STDNX5C"
};



const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseStorage = getStorage(firebaseApp);

export const uploadDoc = async (folder, club_id, doc) => {
  const docRef = ref(firebaseStorage, `${folder}/${club_id}`);
  const snapshot = await uploadBytes(docRef, doc);
  console.log("Uploaded file:", snapshot);

  const docURL = await getDownloadURL(snapshot.ref);
  console.log("docurl", docURL)
  return docURL
};