import * as Dialog from '@radix-ui/react-dialog';
import SvgClose from "../../icons/SvgClose";
import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {sendHelpEmail} from "../../../api/student/admin";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

class GetRosterHelpModal extends PureComponent {
    state = {
        text: "",
    }
    textChanged = e => this.setState({text: e.target.value});

    getHelp = async () => {
        await sendHelpEmail(this.props.authUser, this.props.club.id, this.state.text);
    }

    render() {
        const saveEnabled = this.state.text.length !== 0;
        return (
            <Dialog.Root>
                <Dialog.Trigger asChild>
                    {this.props.children}
                </Dialog.Trigger>
                <Dialog.Portal>
                    <Dialog.Overlay className="DialogOverlay"/>
                    <Dialog.Content className="DialogContent">
                        <Dialog.Title className="title2 text-neutral-900 mb-3">Get help</Dialog.Title>
                        <div className='flex flex-col w-full gap-5 mb-[-16px] mt-5'>
                            <div className='flex flex-row justify-between items-center'>
                                <div className='flex flex-col gap-5 flex-1'>
                                    <div className="col-plain w-full">
                                        <p className="body3-bold text-neutral-400 mb-1">
                                            Tell us how we can help
                                        </p>
                                        <textarea
                                            value={this.state.firstName}
                                            onChange={this.textChanged}
                                            placeholder={'Explain your issue here...'}
                                            className='input-light text-sm px-3 body2 base-black-50 w-full min-h-[120px] pt-3'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex-row gap-3 pt-5'
                             style={{display: 'flex', marginTop: 25, justifyContent: 'flex-end'}}>
                            <Dialog.Close asChild onClick={this.props.close}>
                                <button className="secondary-button body1-bold">Cancel</button>
                            </Dialog.Close>
                            <Dialog.Close asChild onClick={this.props.close}>
                                <button
                                    disabled={!saveEnabled}
                                    onClick={() => this.getHelp()}
                                    className={`primary-button body1-bold ${!saveEnabled && 'opacity-50 cursor-not-allowed'}`}>
                                    Submit
                                </button>
                            </Dialog.Close>
                        </div>
                        <Dialog.Close asChild onClick={this.props.close}>
                            <button className="IconButton" aria-label="Close">
                                <SvgClose/>
                            </button>
                        </Dialog.Close>
                    </Dialog.Content>
                </Dialog.Portal>
            </Dialog.Root>
        )
    }
}

export default connect(mapStateToProps)(GetRosterHelpModal)
