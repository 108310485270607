import React, { useState, useEffect } from 'react';
import ManualContactEntry from '../atoms/modals/ManualContactEntry';
import StatusSelect from '../atoms/StatusSelect';
import SvgLinkedIn from '../icons/SvgLinkedIn';
import SvgChevronRight from '../icons/SvgChevronRight';
import { editNetwork } from '../../api/student/networks';
import { Link } from 'react-router-dom';
import { SIDEBAR_CARD } from '../../utils/dummy';
import { toast } from 'react-toastify';
import { TOAST_OPTIONS } from '../../utils/constants';

function EventPageYourNetworkWidget({authUser, history, listing, updateNetwork, networks, networksMap}) {

    const updateNetworkStatus = async (personId, status) => {
        try {
            const newNetwork = {...networksMap[personId]};
            newNetwork.status = status;
            updateNetwork(newNetwork);

            await editNetwork(authUser, networksMap[personId].id, {status});

            toast.promise(
                Promise.resolve('Status updated!'),
                {
                    pending: 'Updating status...',
                    success: 'Status updated!',
                    error: 'Failed to update status!',
                },
                TOAST_OPTIONS
            );
        } catch (error) {
            toast.error('Failed to update status!', TOAST_OPTIONS);
        }
    };

    
    return (
        <div className={`flex flex-col pt-3 w-full my-3 ${SIDEBAR_CARD}`}>
            <div className='flex flex-row items-center'>
                <Link to={{pathname: '/networking'}}
                      className='flex flex-row items-center text-primary cursor-pointer gap-0.5'>
                    <p className='body3-bold text-primary'>
                        Your Network
                    </p>
                    <SvgChevronRight className={'w-4 h-4'}/>
                </Link>
            </div>
            {networks.length === 0 ?
                <div
                    className='flex flex-col bg-slate-100 mt-3 rounded-lg gap-3 py-5 text-center items-center justify-center'>
                    <p className='body3 text-slate-500 px-5'>
                        You don't have any contacts in your network at this company. Most
                        successful applicants network with at least 3 people from a company.
                    </p>
                    <ManualContactEntry updateNetwork={updateNetwork} history={history}/>
                </div>
                :
                <>
                    {networks.map(personId => {
                            const info = networksMap[personId];
                            return (
                                <div
                                    className='flex flex-row items-center justify-between mt-3 gap-2'>
                                    <div
                                        className='text-md text-slate-800 flex flex-row items-center gap-0.5'>
                                        <p className='body2-bold text-slate-800'>
                                            {info.person.first_name} {info.person.last_name}
                                        </p>

                                        {!!info.linkedin ?
                                            <a href={info.linkedin}
                                               target={"_blank"}>

                                                <SvgLinkedIn
                                                    className={'base-black-30 h-base-black-70 pointer w-4 h-4 ml-1'}/>
                                            </a>
                                            : null}
                                    </div>
                                    <StatusSelect
                                        placeholder={'Not contacted'}
                                        value={info.status}
                                        setValue={(status) => updateNetworkStatus(personId, status)}
                                        options={[{title: "Not contacted",}, {title: "Contacted",}, {title: "Response received"}, {title: "Scheduled",}, {title: "Thank You Sent",}
                                        ]}
                                        sm
                                        light
                                    />
                                </div>
                            )
                        }
                    )}
                    <div className='my-3 flex justify-center'>
                            <ManualContactEntry updateNetwork={updateNetwork} history={history}/>
                    </div>
                </>
            }
        </div>
    )
}

export default EventPageYourNetworkWidget;
