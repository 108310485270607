import React, { PureComponent } from "react";
import SvgEdit from "../icons/SvgEdit";
import SvgRemove from "../icons/SvgRemove";
import SvgMoreVertical from "../icons/SvgMoreVertical";
import DropdownComponent from "../atoms/DropdownComponent";

class EducationListItem extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            finalLogoUrl: this.getInitialLogoUrl(props.education),
            imageError: false
        };
    }

    componentDidUpdate = (prevProps) => {
        // console.log("fhrtr",this.props.education)
        // if (this.props.education !== prevProps.education) {
            this.getInitialLogoUrl(this.props.education)
        // }
    }

    getInitialLogoUrl = (education) => {
        let logoUrl = education.logo_url ? education.logo_url : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fcover_image%2FdefaultCompanyLogo.jpg?alt=media";

        if (logoUrl.includes("replaceme:")) {
            logoUrl = logoUrl.replace("replaceme:", "");
        }

        return logoUrl;
    }

    handleImageError = () => {
        this.setState({
            finalLogoUrl: "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fcover_image%2FdefaultCompanyLogo.jpg?alt=media",
            imageError: true
        });
    }

    render() {
        const education = this.props.education || {};
        const startsAt = education.starts_at || {};
        const endsAt = education.ends_at || {};
        const ending = Object.keys(endsAt).length === 0 ? "Present" : endsAt.year;

        return (
            <>
                <div className='flex flex-row items-center justify-between py-4'>
                    <div className='flex flex-row'>
                        <img
                            src={this.state.finalLogoUrl}
                            onError={this.handleImageError}
                            className={`${this.props.sm ? 'w-12 h-12' : 'w-16 h-16'} rounded-md border-neutral-200 border bg-white object-contain`}
                        />
                        <div className='ml-3 flex flex-col flex-1'>
                            <p className={`${this.props.sm ? 'body2-bold' : 'body1-bold'} text-neutral-900`}>
                                {education.school}
                            </p>
                            <p className={`${this.props.sm ? 'body3' : 'body2'}  text-neutral-500 ${this.props.sm ? 'mb-0.5' : 'mb-2'} mt-[-4px]`}>
                                {education.degree_name} {education.field_of_study && education.degree_name ? ", " : null} {education.field_of_study}
                            </p>
                            <p className={`${this.props.sm ? 'body3' : 'body2'} text-neutral-400`}>
                                {startsAt.year} - {ending}
                            </p>
                            {education.activities_and_societies ?
                                <p className='body2 text-neutral-700'>
                                    {education.activities_and_societies}
                                </p>
                                : null }
                        </div>
                    </div>
                </div>
                {!this.props.last && <div className='border-b border-neutral-100 w-full'/>}
            </>
        );
    }
}

export default EducationListItem;
