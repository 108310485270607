import React, {PureComponent} from "react";
import {connect} from "react-redux";
import AddJobCompany from "../../addjobflow/SearchCompany";
import ModalComponent from "./ModalComponent";
import { editClub } from "../../../api/student/clubs";
import WrappedTextInput from "../WrappedTextInput";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateApplication: (application) => {
            dispatch({
                type: "UPDATE_APPLICATION",
                application,
            })
        },
    }
}

class EditPositionModal extends PureComponent {
    state = {
        position: '',

    }
    handleInputChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    };


    closeModal = () => {
        this.props.closeModal()
        this.setState({
            position: ''
        })
    }

    componentDidMount = () => {
        this.setPosition();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.selectedPerson !== prevProps.selectedPerson) {
            this.setPosition();
        }
    }

    setPosition = () => {
        const club = this.props.club || {};
        const leadership = club.leadership || {};
        const person = this.props.selectedPerson || {};
        this.setState({position: leadership[person.username] || "Member"});
    }

    submit = async () => {
        const club = this.props.club || {};
        const leadership = {...club.leadership};
        leadership[this.props.selectedPerson.id] = this.state.position;
        await editClub(this.props.authUser, club.id, {leadership: leadership});
        this.props.closeModal();
        this.props.getClubInfo();
    }

    render() {
        const numSteps = 5
        const progressWidth = (this.state.step / numSteps) * 100;
        return (
            <ModalComponent isOpen={this.props.isOpen}
                            header={`Edit ${this.props.selectedPerson.first_name}'s Position`}
                            backgroundColor={'white'}
                            headerToggle
                            size={'md'}
                            toggle={this.closeModal}
                            FooterComponent={
                                <div className='flex flex-row items-center justify-end gap-3'>
                                    <button onClick={this.closeModal} className='secondary-button body1-bold'>
                                        Cancel
                                    </button>
                                    <button onClick={this.submit}
                                            className={`primary-button body1-bold`}>
                                        Save
                                    </button>
                                </div>
                            }
                            footerAlignment={'right'}
                            id={'add-job-modal'}
            >
                <WrappedTextInput
                    type="text"
                    name={"position"}
                    placeholder={'Position title...'}
                    className='body2 input-light w-full px-4'
                    value={this.state.position}
                    onChange={this.handleInputChange}
                />
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPositionModal)
