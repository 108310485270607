import React, {PureComponent} from "react";
import SvgClose from "./icons/SvgClose";
import SvgNote from "./icons/SvgNote";
import {DateTime} from 'luxon';

class NoteComponent extends PureComponent {
    state = {
        hovering: false
    }

    render() {
        const note = this.props.note || {};
        return (
            <div
                onMouseEnter={() => this.setState({hovering: true})}
                onMouseLeave={() => this.setState({hovering: false})}
                className='hover:bg-neutral-50 flex flex-row py-1.5 px-2'>
                <div>
                    {!this.state.hovering ?
                        <SvgNote className={'w-4 h-4 mt-[2.5px] text-neutral-400'}/>
                        :
                        <div onClick={() => this.props.deleteNote(this.props.index)}>
                            <SvgClose className={'w-4 h-4 mt-[2.5px] cursor-pointer hover:text-red-500'}/>
                        </div>
                    }
                </div>
                {!!note.recruiter_name &&
                    <p className='ml-2 body2 text-neutral-700'>
                        <strong>{`${note.recruiter_name}:`}</strong>
                    </p>
                }
                <p className='ml-2 body2 text-neutral-700'>
                    {note.text}
                    <span className='text-neutral-400 body3 ml-1'>
                        {DateTime.fromISO(note.created_at).toRelative()}
                    </span>
                </p>
            </div>
        )
    }
}

export default NoteComponent
