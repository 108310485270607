import React, {PureComponent} from "react";
import CompanyPageAboutWidget from "../companypage/CompanyPageAboutWidget";
import {getCompany} from "../../api/student/companies";
import {getCompanyJobs} from "../../api/student/jobs";
import {getCompanyEvents} from "../../api/student/events";
import CompanyPageJobs from "../companypage/CompanyPageJobs";
import CompanyPageEvents from "../companypage/CompanyPageEvents";
import {connect} from "react-redux";
import PageContentWrapper from "../wrappers/PageContentWrapper";
import StudentUpgradedCompanyPage from "./StudentUpgradedCompanyPage";
import {filteredPersonSearch} from "../../utils/typesense";
import SvgUsers from "../icons/SvgUsers";
import CompanyPagePeople from "../companypage/CompanyPagePeople";
import Spinner from "../Spinner";
import AnimatedTabs from "../atoms/AnimatedTabs";
import CompanyProgressOverview from "../CompanyProgressOverview";
import GoBack from "../atoms/GoBack";
import SvgBriefcase from "../icons/SvgBriefcase";
import SvgCalendar from "../icons/SvgCalendar";
import SvgCompanyBuilding from "../icons/SvgCompanyBuilding";
import CompanyPageAboutTab from "../companypage/CompanyPageAboutTab";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        applications: state.userReducer.applications,
        networks: state.userReducer.networks,
        trackers: state.userReducer.trackers
    };
}


function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
        updateNetwork: (network) => {
            dispatch({
                type: "UPDATE_NETWORK",
                network,
            })
        },
        setPath: (path) => {
            dispatch({
                type: "SET_PATH",
                path,
            })
        },
        updateApplication: (application) => {
            dispatch({
                type: "UPDATE_APPLICATION",
                application,
            })
        },
        updateTracker: (tracker) => {
            dispatch({
                type: "UPDATE_TRACKER",
                tracker,
            })
        }
    }
}


class PublicCompanyPage extends PureComponent {
    state = {
        company: {},
        jobs: [],
        events: [],
        people: [],
        selectedEvent: {},
        eventModalOpen: false,
        loading: true,
        activeTab: "About"
    }

    componentDidMount() {
        this.props.setPath("");
        this.fetchCompany();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.fetchCompany();
        }
    }

    fetchCompany = async () => {
        this.setState({loading: true})
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const companyId = lastArg.split("?")[0];

        const result = await getCompany(companyId);
        const jobData = await getCompanyJobs(companyId);
        const eventData = await getCompanyEvents(companyId);
        const peopleResponse = await filteredPersonSearch("", [], 1, null, [], "recruitu.io", companyId);

        this.setState({
            company: result.company,
            jobs: jobData.jobs,
            people: peopleResponse.results,
            loading: false,
            events: eventData.events
        });
    }

    changeTab = (activeTab) => {
        this.setState({activeTab});
    }

    render() {
        const company = this.state.company || {};
        const previousPage = this.props.location.state?.previousPage?.location;
        if (this.state.loading) {
            return (
                <div className='flex flex-col items-center justify-center min-h-[60vh]'>
                    <Spinner size={24}/>
                </div>
            )
        }
        return (
            <PageContentWrapper>
                <>
                    <div className={'pt-2 w-full max-w-[1100px] mx-auto px-4 pt-5 overflow-y-scroll'}>
                        <div className='flex flex-row items-center gap-3 mb-3'>
                            <GoBack/>
                        </div>
                        <CompanyPageAboutWidget company={company} loading={this.state.loading}
                                                user={this.props.user}
                                                authUser={this.props.authUser} fetchCompany={this.fetchCompany}
                                                setUser={this.props.setUser} hideFollow/>
                        {!!this.props.trackers[company.id] &&
                            <div className='card'>
                                <p className='font-semibold text-sm text-slate-500 mb-2'>
                                    Your Progress
                                </p>
                                <CompanyProgressOverview
                                    companyTracker={this.props.trackers[company.id]}
                                    applications={this.props.applications}
                                    networks={this.props.networks}
                                    trackers={this.props.trackers}
                                    updateTracker={this.props.updateTracker}
                                    authUser={this.props.authUser}
                                    history={this.props.history}
                                    openApplicationPeek={() => null}
                                    openNetworkingPeek={() => null}
                                    headerOnly
                                />
                            </div>
                        }
                        <div className="mb-4">
                            <AnimatedTabs
                                activeTab={this.state.activeTab}
                                changeTab={this.changeTab}
                                tabs={[
                                    {title: 'About', path: '/', icon: SvgCompanyBuilding},
                                    {title: 'Jobs', path: '/', icon: SvgBriefcase},
                                    {title: 'Events', path: '/', icon: SvgCalendar},
                                    {title: 'People', path: '/', icon: SvgUsers},
                                ]}
                            />
                        </div>
                        {this.state.activeTab === 'About' ?
                            <CompanyPageAboutTab company={company} history={this.props.history}/>
                            :
                            this.state.activeTab === "Jobs" ?
                                <CompanyPageJobs jobs={this.state.jobs} previousPage={previousPage}/>
                                : this.state.activeTab === "Events" ?
                                    <CompanyPageEvents events={this.state.events} company={company}
                                                       history={this.props.history}/>
                                    :
                                    <CompanyPagePeople people={this.state.people} company={company}/>
                        }
                    </div>
                </>
            </PageContentWrapper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicCompanyPage);
