import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {connect} from "react-redux";
import {Button} from "../../components/atoms/Button";
import RichTextEditor from "../../components/atoms/inputs/RichTextEditor";
import {EditorState, ContentState, convertFromRaw} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class FreeTextInputModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
        };
    }

    componentDidMount() {
        this.initializeEditorState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.initializeEditorState();
        }
    }

    initializeEditorState = () => {
        const { value } = this.props;

        if (value) {
            try {
                const contentState = typeof value === 'string'
                    ? ContentState.createFromText(value)
                    : convertFromRaw(value);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({ editorState });
            } catch (error) {
                this.setState({ editorState: EditorState.createEmpty() });
            }
        } else {
            this.setState({ editorState: EditorState.createEmpty() });
        }
    }

    setEditorState = (editorState) => {
        this.setState({editorState});
    }

    save = async () => {
        const contentState = this.state.editorState.getCurrentContent();
        const htmlContent = stateToHTML(contentState);
        
        // Pass the HTML content to your save function, which handles saving to the database
        this.props.save(htmlContent); 
        this.props.closeModal();
    }

    render() {
        const hasText = this.state.editorState.getCurrentContent().hasText()
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={this.props.title}
                headerToggle
                size={'lg'}
                toggle={this.props.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={this.props.closeModal} variant='secondary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={!hasText}
                            onClick={this.save}>
                            Save Changes
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div className={`flex flex-col gap-1.5`}>
                    <RichTextEditor 
                        editorState={this.state.editorState} 
                        setEditorState={this.setEditorState}
                        initialHtml={this.props.value}
                    />
                </div>
            </ModalComponent>
        );
    }
}

export default connect(mapStateToProps)(FreeTextInputModal);
