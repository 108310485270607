import React, {Component} from "react";


class PageContentWrapper extends Component {
    render() {
        return (
            <div className='col-plain flex-1 overflow-hidden'>
                {this.props.children}
            </div>
        )
    }
}

export default PageContentWrapper
