import React, { useState } from 'react';
import ConfirmationModal from '../../modals/ConfirmationModal';

const AlertModal = ({
    children,
    title,
    description,
    onConfirm,
    confirmText = 'Confirm',
    disabled = false
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const closeModal = () => setIsOpen(false);

    const handleConfirm = () => {
        onConfirm();
        closeModal();
    }

    return (
        <>
            {React.cloneElement(children, {
                onClick: () => setIsOpen(true),
                disabled: disabled
            })}
            <ConfirmationModal
                isOpen={isOpen}
                closeModal={closeModal}
                title={title}
                description={description}
                onConfirm={handleConfirm}
                confirmText={confirmText}
            />
        </>
    );
};

export default AlertModal;