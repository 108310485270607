import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="currentColor"
            {...props}
        >
            <path d="M200-200q-17 0-28.5-11.5T160-240q0-17 11.5-28.5T200-280h40v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h40q17 0 28.5 11.5T800-240q0 17-11.5 28.5T760-200H200zm280-300zm0 420q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80zM320-280h320v-280q0-66-47-113t-113-47q-66 0-113 47t-47 113v280z" />
        </svg>
    )
}

export default SvgComponent
