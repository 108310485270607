import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'} d="M160-372v-60h640v60H160zm0 160v-60h640v60H160zm0-316v-60h640v60H160zm0-160v-60h640v60H160z" />
        </svg>
    )
}

export default SvgComponent
