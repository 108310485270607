export const filterUsers = (users, filters) => {
    return users.filter(user => {
        const nameMatch = (user.first_name + ' ' + user.last_name).toLowerCase().includes(filters.search.toLowerCase());
        const majorMatch = filters.majors.length === 0 || filters.majors.some(major => user.major.includes(major));
        const classYearMatch = filters.classYears.length === 0 || filters.classYears.includes(user.grade);
        const gpaMatch = !filters.gpa || parseFloat(user.gpa) >= parseFloat(filters.gpa);
        const genderMatch = !filters.gender || user.diversity?.gender === filters.gender;
        const raceMatch = !filters.race || user.diversity?.race?.includes(filters.race);
        const veteranMatch = !filters.veteran || user.diversity?.veteran === filters.veteran;
        const lgbtqMatch = !filters.lgbtq || user.diversity?.lgbtq === filters.lgbtq;
        const disabledMatch = !filters.disabled || user.diversity?.disabled === filters.disabled;
        const authorizedMatch = !filters.authorized || user.diversity?.authorized === filters.authorized;
        const collegeMatch = !filters.college || user.college.toLowerCase().includes(filters.college.toLowerCase());
        const clubMatch = !filters.selectedClubsMappedToIds.length || filters.selectedClubsMappedToIds.some(clubId => user.clubs.includes(clubId));

        return nameMatch && majorMatch && classYearMatch && gpaMatch && genderMatch && raceMatch && 
               veteranMatch && lgbtqMatch && disabledMatch && authorizedMatch && collegeMatch && clubMatch;
    });
};