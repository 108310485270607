export const formatJobDescription = (text) => {
    if (text) {
        const lines = text.split('\n');
        return lines.map((line, index) => {
                if (line[0] === "*" && line[line.length - 1] === "*") {
                    line = <b>{line.slice(2, line.length - 2)}</b>;
                } else if (line[line.length - 1] === ":") {
                    line = <b>{line}</b>;
                }
                return (
                    <p key={index}>{line}</p>
                )
            }
        );
    } else {
        return ""
    }
};

export function addOrdinalSuffix(number) {
    var suffix = 'th';
    if (number % 10 === 1 && number % 100 !== 11) {
        suffix = 'st';
    } else if (number % 10 === 2 && number % 100 !== 12) {
        suffix = 'nd';
    } else if (number % 10 === 3 && number % 100 !== 13) {
        suffix = 'rd';
    } 
    return number + suffix;
}

export function validateEmail(mail, recruiter = false, bypass = false) {
    if (recruiter || bypass) {
        return true
    }
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail) && domainExists(mail)) {
        return (true)
    }
    return (false)
}

export function domainExists(email) {
    const rawDomains = require('./domains.json');
    let domain = email.split("@")[1];

    const subdomains = domain.split(".");
    if (subdomains.length > 2) {
        const lastTwo = subdomains.slice(subdomains.length -2);
        domain = lastTwo.join(".");
    }
    
    console.log(rawDomains.includes(domain), domain);
    return rawDomains.includes(domain);
}

export function truncateString(text, maxLength) {
    if (!text) return "";
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.slice(0, maxLength - 3) + '...';
    }
};

export function truncateStringFullWord(inputString, charCount) {
    if (inputString.length <= charCount) {
      return inputString;
    }
  
    // Find the first whitespace character after the given character count
    let indexToCut = charCount;
    while (indexToCut > 0 && !/\s/.test(inputString.charAt(indexToCut))) {
      indexToCut--;
    }
  
    // If no whitespace is found, cut at the exact character count
    if (indexToCut === 0) {
      return inputString.substring(0, charCount);
    }
  
    // Otherwise, cut at the nearest whitespace
    return inputString.substring(0, indexToCut) + "...";
  }

export function rankColor(number) {
    if (number === 5) return 'bg-green-600'
    else if (number === 4) return 'bg-teal-500'
    else if (number === 3) return 'bg-yellow-500'
    else if (number === 2) return 'bg-orange-500'
    else if (number === 1) return 'bg-red-500'
    else return 'bg-neutral-300'
}

export const roundTo = (number, digits) => {
    if (number !== null && number !== undefined) {
        return parseFloat(number).toLocaleString('en-US', {
            maximumFractionDigits: digits,
            minimumFractionDigits: digits,
        });
    } else {
        return '';
    }
};


export const formatDateToString = (date) => {
    const year = date.year;
    const month = date.month < 10 ? "0" + String(date.month) : String(date.month);
    const day = date.day < 10 ? "0" + String(date.day) : String(date.day);
    return year + "-" + month + "-" + day;
}

export const formatStringToDate = (date) => {
    if (date === null || !date.includes("-")) {
        return null;
    }

    const split = date.split("-");
    const year = split[0];
    const month = split[1][0] == "0" ? split[1].slice(1,) : split[1];
    const day = 1
    return {year: Number(year), month: Number(month), day};
}

export const pluralizeString = (str, count) => {
    if (count === 1) {
      return str;
    }
  
    const irregularPlurals = {
      child: 'children',
      person: 'people',
      mouse: 'mice',
      foot: 'feet',
      tooth: 'teeth',
    };
  
    // Check for irregular plurals
    if (irregularPlurals[str]) {
      return irregularPlurals[str];
    }
  
    // Regular rules for pluralization
    if (str.endsWith('s') || str.endsWith('x') || str.endsWith('ch') || str.endsWith('sh')) {
      return `${str}es`;
    }
  
    // Handle words ending in 'y' preceded by a consonant
    if (str.endsWith('y') && !/[aeiou]y$/.test(str)) {
      return `${str.slice(0, -1)}ies`;
    }
  
    // Default case
    return `${str}s`;
  }
  