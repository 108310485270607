import React, {PureComponent} from "react";
import {connect} from "react-redux";
import PageContentWrapper from "../wrappers/PageContentWrapper";
import {withRouter} from "react-router-dom";
import PageHeaderWrapper from "../wrappers/PageHeaderWrapper";
import DiscoverJobs from "../discover/DiscoverJobs";
import DiscoverCompanies from "../discover/DiscoverCompanies";
import DiscoverPeople from "../discover/DiscoverPeople";
import DiscoverEvents from "../discover/DiscoverEvents";
import AnimatedTabs from "../atoms/AnimatedTabs";
import SvgGroup from "../icons/SvgGroup";
import SvgBriefcase from "../icons/SvgBriefcase";
import SvgCalendar from "../icons/SvgCalendar";
import SvgDomain from "../icons/SvgDomain";
import DiscoverFeed from "../discover/DiscoverFeed";
import SectionTabs from "../atoms/SectionTabs";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        applications: state.userReducer.applications,
        user: state.userReducer.user,
    };
}

class Discover extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            filter: "All",
            // activeTab: 'Feed',
            activeSubTab: 'Jobs',
            companyId: null,
            companyName: null,
        };
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const activeTab = queryParams.get("activeSubTab");
        const companyId = queryParams.get("companyId")
        const companyName = queryParams.get("companyName")
        if (!!activeTab) this.changeSubTab(activeTab, true);
        if (!!companyId) this.setState({companyId});
        if (!!companyName) this.setState({companyName})
    }

    changeSubTab = (tab, fullPath=false) => {
        window.analytics.track("Discover Tab Clicked", {
            "tab_type": tab
        })
        this.setState({activeSubTab: tab, activeTab: "Search", filter: "All"});
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("activeSubTab", tab);

        const params = fullPath ? queryParams.toString() : `activeSubTab${tab}`
        const newUrl = `${window.location.pathname}?${params}`;
        this.props.history.replace(newUrl);
    };


    render() {
        const activeTab = this.state.activeTab;
        return (
            <PageContentWrapper>
                <PageHeaderWrapper className={activeTab === 'Feed' ? 'pb-2' : 'pb-0'}>
                    <h1 className={`text-slate-800 text-2xl font-semibold capitalize mb-0`}>
                        <p>Discover</p>
                    </h1>
                    {/*<div className='mb-2'>*/}
                    {/*    <SectionTabs*/}
                    {/*        activeTab={activeTab}*/}
                    {/*        changeTab={this.changeTab}*/}
                    {/*        xl*/}
                    {/*        tabs={['Feed', 'Search']}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*{this.state.activeTab === 'Search' &&*/}
                        <AnimatedTabs
                            activeTab={this.state.activeSubTab}
                            changeTab={this.changeSubTab}
                            tabs={[
                                {title: 'Jobs', path: '/', icon: SvgBriefcase},
                                {title: 'People', path: '/', icon: SvgGroup},
                                {title: 'Events', path: '/', icon: SvgCalendar},
                                {title: 'Companies', path: '/', icon: SvgDomain},
                            ]}
                        />
                    {/*}*/}
                </PageHeaderWrapper>
                {/*{this.state.activeTab === 'Home' &&*/}
                {/*    <DiscoverHome changeTab={this.changeTab} history={this.props.history}/>*/}
                {/*}*/}
                {this.state.activeTab === 'Feed' ?
                    <DiscoverFeed/>
                    :
                    <>
                        {this.state.activeSubTab === 'Jobs' &&
                            <DiscoverJobs history={this.props.history} companyId={this.state.companyId} companyName={this.state.companyName}/>
                        }
                        {this.state.activeSubTab === 'Companies' &&
                            <DiscoverCompanies history={this.props.history}/>
                        }
                        {this.state.activeSubTab === 'Events' &&
                            <DiscoverEvents history={this.props.history} companyId={this.state.companyId} companyName={this.state.companyName}/>
                        }
                        {this.state.activeSubTab === 'People' &&
                            <DiscoverPeople companyId={this.state.companyId} companyName={this.state.companyName}/>
                        }
                    </>
                }
            </PageContentWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps)(Discover));
