import React, {PureComponent} from "react";
import SelectComponent from "../atoms/SelectComponent";

class AuthorizationSponsorshipQuestions extends PureComponent {

    render() {
        return (
            <>
                <div className="col-plain w-full">
                    <div className="col-plain flex-1 mb-5">
                        <SelectComponent
                            outerLabel={'Are you legally authorized to work in the United States?'}
                            className={'w-full'}
                            label={'...'}
                            value={this.props.authorized}
                            light
                            setValue={this.props.handleSelectChange}
                            clearFilter={() => this.props.handleSelectChange('authorized', null)}
                            stateName={'authorized'}
                            options={[{title: "Yes",}, {title: "No"}, {title: "Prefer Not to Specify"}]}
                            scrollable
                            node={document.getElementById('personalization-modal')}
                        />
                    </div>
                    <div className="col-plain flex-1">
                        <SelectComponent
                            outerLabel={'Will you, now or in the future, require sponsorship from your employer to work in the United States?'}
                            className={'w-full'}
                            label={'...'}
                            value={this.props.requireSponsorship}
                            light
                            setValue={this.props.handleSelectChange}
                            clearFilter={() => this.props.handleSelectChange('requireSponsorship', null)}
                            stateName={'requireSponsorship'}
                            options={[{title: "Yes",}, {title: "No"}, {title: "Prefer Not to Specify"}]}
                            scrollable
                            node={document.getElementById('personalization-modal')}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default AuthorizationSponsorshipQuestions;
