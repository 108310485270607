import SvgMoney from "../icons/SvgMoney";
import SvgUserCheck from "../icons/SvgUserCheck";
import SvgUsers from "../icons/SvgUsers";
import React from "react";
import TagComponent from "../atoms/TagComponent";
import SvgStar from "../icons/SvgStar";
import SvgPin from "../icons/SvgPin";

function CompanyAboutBlock({company, inPeek}) {
    return (
        <div className='flex flex-col flex-1 justify-between'>
            {company?.specialties?.length > 0 &&
                <div className='flex flex-col'>
                    {company?.specialties.length ?
                        <>
                            <p className='text-lg font-bold mb-1'>
                                Specialties
                            </p>
                            <div className='flex flex-row gap-1 mt-1 whitespace-nowrap flex-wrap'>
                                {company.specialties.map(specialty =>
                                    <TagComponent
                                        label={specialty}
                                        type={'primary'}
                                    />
                                )}
                                {company.secondary_specialties.map(specialty =>
                                    <TagComponent
                                        label={specialty}
                                        type={'random'}
                                    />
                                )}
                            </div>
                        </>
                        : null
                    }
                    {company?.sector_ranking ?
                        <div
                            className='text-primary flex flex-row items-center text-xs mt-2 font-semibold gap-1'>
                            <SvgStar className={'w-4 h-4 mb-0.5'}/>
                            <p>
                                {company.name} ranks in the
                                Top {company.sector_ranking} for {company.sector}
                            </p>
                        </div>
                        : null
                    }
                </div>
            }
            <div className={`flex flex-col ${company?.specialties?.length > 0 && 'mt-5'}`}>
                <p className='text-lg font-bold mb-1'>
                    Headquarters
                </p>
                <div className='flex flex-row items-center text-sm gap-1'>
                    <SvgPin className={'w-3 h-3'}/>
                    <p className={`${inPeek && 'flex-1 line-clamp-1'}`}>
                        {company.headquarters}
                    </p>
                </div>
            </div>
            {company?.cities?.length ?
                <div className='flex flex-col mt-5'>
                    <p className='text-lg font-bold mb-1'>
                        Cities
                    </p>
                    <div className='flex flex-row items-center text-sm gap-1'>
                        <p>
                            {company.cities.join(" | ")}
                        </p>
                    </div>
                </div>
                : null
            }
        </div>
    )
}

export default CompanyAboutBlock
