import React, {PureComponent} from "react";
import SvgChevronDown from "../icons/ChevronDown";
import SvgChevronUp from "../icons/ChevronUp";
import Tooltip from '../../components/atoms/modals/Tooltip'

class AssetTableHeader extends PureComponent {

    render() {
        return (
            <table className='w-full'>
                <thead>
                <tr className={`flex flex-row items-center mt-2 ${this.props.className}`}>
                    {this.props.headers.map(header =>
                        <th style={{flex: header.flex}}
                            className={`text-slate-500  flex text-left py-1.5 items-center truncate ${this.props.selectedSort === header.title ? 'sort-sorting body3-emphasized' : 'sort body3'}`}
                            onClick={() => this.props.changeSort(header.title)}
                            key={header.title}
                        >
                            {header.title.length > 13 ?
                                <Tooltip
                                    toggleComponent={
                                        <p className='text-xs mr-2 max-w-[70px] truncate'>
                                            {header.title}
                                        </p>
                                    }
                                    label={header.title}
                                />
                                :
                                <p className='text-xs mr-2 max-w-[70px] truncate'>
                                    {header.title}
                                </p>
                            }
                            <div className="flex items-center">
                                <div className="sort-arrow-up">
                                    <SvgChevronDown fill={'#00000030'}/>
                                </div>
                                <div className="sort-arrow-down">
                                    <SvgChevronUp fill={'#00000030'}/>
                                </div>
                            </div>
                        </th>
                    )}
                </tr>
                </thead>
            </table>
        )
    }
}

export default AssetTableHeader;
