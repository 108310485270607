// Libraries
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';

// Components
import EventPageAboutEventWidget from '../components/eventpage/EventPageAboutEventWidget';
import EventPageTitleWidget from '../components/eventpage/EventPageTitleWidget';
import JobPageAboutCompanyWidget from '../components/jobpage/JobPageAboutCompanyWidget';
import PageContentWrapper from '../components/wrappers/PageContentWrapper';
import Spinner from '../components/Spinner';
import GoBack from "../components/atoms/GoBack";
import { getEvent } from '../api/recruiter/events';


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        constants: state.userReducer.constants
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
    }
}


function PublicEventPage({authUser, user, setUser, history, constants}) {
    const {eventId} = useParams();
    const [loading, setLoading] = useState(true);
    const [event, setEvent] = useState({})
    const [validId, setValidId] = useState(false);


    useEffect(() => {
        fetchEvent();
    }, []);

    const fetchEvent = async () => {
        const e = await getEvent(authUser, eventId);
        const event = e.event;

        // If event out of scope go back
        if (!event || event.scope === "personal") {
            setLoading(false);
            return;
        }
        setValidId(true);
        setEvent(event);
        setLoading(false);
    }

    const company = event.company || {};
    if (loading) {
        return (
            <div className='flex flex-col items-center justify-center min-h-[60vh]'>
                <Spinner/>
            </div>
        )
    }
    return (
        <PageContentWrapper>
            {(!validId) ?
                    <div className='flex h-full justify-center items-center'>
                        <p>404: Event does not exist or you do not have access</p>
                    </div>
                    :
                    <div>
                        <div className={'pt-2 w-full max-w-[1100px] mx-auto px-4 pt-5 overflow-y-scroll'}>
                            <div className='flex flex-row items-center gap-3 mb-3'>
                                <GoBack/>
                            </div>
                            <EventPageTitleWidget listing={event} loading={loading} user={user} authUser={authUser}
                                                  fetchEvent={fetchEvent} constants={constants}/>
                            <div className='card flex flex-row gap-3 flex-1 w-full'>
                                <div className='flex-1 w-full'>
                                    <div className="w-full bg-zinc-100 text-center py-2 rounded-lg">
                                        <p>
                                        This is a preview of the event as it will appear to
                                        students.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <EventPageAboutEventWidget listing={event} loading={loading}/>
                            {!!company.name ? <JobPageAboutCompanyWidget authUser={authUser} listing={event} user={user} hideFollowButton
                                                                         setUser={setUser}/> : null}
                        </div>
                    </div>
            }
        </PageContentWrapper>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicEventPage);
