import React, {Component} from "react";


class PageLeftWrapper extends Component {
    render() {
        return (
            <div className={`py-2 pr-0 flex flex-col flex-1 ${this.props.className}`}>
                {this.props.children}
            </div>
        )
    }
}

export default PageLeftWrapper
