import React from "react";

export default function AuthFormFooter({
    currentTab,
    recruiter,
    mobile,
    setTab,
    history,
    path,
    showTermsAndConditions,
}) {
    return (
        <>
            {currentTab === "login" ? (
                <>
                    <div className="row-ac-jc mt-20">
                        <p className="body2 text-slate-500 mr-4">
                            Don't have an account?
                        </p>
                        <div
                            onClick={() => {
                                if (recruiter || mobile) {
                                    setTab("signup");
                                } else {
                                    history.replace(
                                        !!path ? "/signup/" + path : "/signup"
                                    );
                                }
                            }}
                            className="body2-bold text-slate-500 hover:text-primary pointer"
                        >
                            Sign up
                        </div>
                    </div>
                </>
            ) : (
                <div className="col-ac-jc">
                    {showTermsAndConditions && (
                        <p className="body4 text-slate-400 mb-2">
                            By signing up you agree to our
                            <a
                                href={
                                    "https://recruitu.com/terms-and-conditions"
                                }
                                target={"_blank"}
                                rel="noreferrer"
                                className="body4 text-slate-500 hover:text-blue-500 pointer"
                            >
                                {" "}
                                Terms of Use{" "}
                            </a>{" "}
                            and
                            <a
                                href={"https://recruitu.com/privacy-policy"}
                                target={"_blank"}
                                rel="noreferrer"
                                className="body4 text-slate-500 hover:text-blue-500 pointer"
                            >
                                {" "}
                                Privacy Policy
                            </a>
                            .
                        </p>
                    )}
                    <div className="row-ac-jc mt-8">
                        <p className="body2 text-slate-500 mr-4">
                            Already have an account?
                        </p>
                        <div
                            onClick={() => setTab("login")}
                            className="body2-bold text-slate-500 hover:text-blue-500 pointer"
                        >
                            Log in
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
