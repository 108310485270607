import ClubLogo from "../components-recruiter/atoms/ClubLogo";
import SvgEditFeather from "../components/icons/SvgEditFeather";
import Spinner from "../components/Spinner";
import React from "react";

export const ClubAdminAboutSection = ({ isAdmin, club, openEditHeaderModal, prependTitle }) => {

  if (!club) {
    return (
        <div className={"flex flex-row items-center justify-center my-auto"}>
          <Spinner size={24}/>
        </div>
    );
  }

  return (
      <div className="flex flex-row justify-between gap-3 w-full">
        <div className="bg-white shadow-lg flex-1 shadow-slate-200 p-5 mt-5 border border-slate-200 rounded-xl">
        <div className="flex flex-row gap-8">
          <ClubLogo
            clubId={club.id}
            backupUrl={club.logo_url}
            size={80}
            className={`border border-slate-200`}
          />
          <div className="flex flex-col gap-3">
            <div className="flex flex-row items-center gap-2">
              <p className="text-3xl text-slate-800 font-semibold">
                {prependTitle} {club.name}
              </p>
              {isAdmin && (
                <button
                  onClick={() => openEditHeaderModal()}
                  className="text-slate-500 mt-0.5 hover:text-primary cursor-pointer"
                >
                  <SvgEditFeather className={"w-5 h-5"} />
                </button>
              )}
            </div>
            <div className="flex flex-row flex-wrap items-center gap-6 gap-y-2">
              <div>
                <p className="text-sm text-slate-500">Website</p>
                <p className="text-sm font-semibold text-primary">
                  {club.website || "--"}
                </p>
              </div>
              <div>
                <p className="text-sm text-slate-500">Email</p>
                <p className="text-sm font-semibold text-primary">
                  {club.email || "--"}
                </p>
              </div>
              <div>
                <p className="text-sm text-slate-500">Industry</p>
                <p className="text-sm font-semibold text-primary">
                  {club?.specialties.join(", ") || "--"}
                </p>
              </div>
              <div>
                <p className="text-sm text-slate-500">Founded</p>
                <p className="text-sm font-semibold text-primary">
                  {club.founded || "--"}
                </p>
              </div>
              <div>
                <p className="text-sm text-slate-500">Members</p>
                <p className="text-sm font-semibold text-primary">
                  {club.num_members_range || "--"}
                </p>
              </div>
              <div>
                <p className="text-sm text-slate-500">Application only</p>
                <p className="text-sm font-semibold text-primary">
                  {club.application_only ? "Yes" : "No"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
