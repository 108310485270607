import axios from '../axios';

export const getClub = async (user, club_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {club_id}
        };
        const res = await axios.get("/recruiter/clubs", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getMembers = async (user, club_id, index, start_after) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {club_id, index, start_after}
        };
        const res = await axios.get("/recruiter/clubs/members", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

