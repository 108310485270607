import React, {Component} from "react";
import WrappedTextInput from "../../../components/atoms/WrappedTextInput";
import { Button } from "../../../components/atoms/Button";

class CreateJobApplicationQuestions extends Component {


    render() {
        return (
            <div className='flex flex-col gap-5'>
                {!this.props.hideTutorial &&
                    <p className='text-lg text-slate-800 font-semibold'>
                        Would you like to add any required questions in order to apply?
                    </p>
                }
                {this.props.applicationQuestions.map((question, i) => {
                    return (
                        <WrappedTextInput
                            type="text"
                            outerLabel={`Question ${i+1}.`}
                            placeholder={'ie. Why are you interested in working at our company?'}
                            className='input-light px-3 w-full'
                            value={question.text}
                            onChange={(e) => this.props.updateApplicationQuestions(e.target.value, i)}
                        />
                    )
                })}
                <Button onClick={() => this.props.updateApplicationQuestions("", this.props.applicationQuestions.length)} disabled={this.props.applicationQuestions.length === 5}>
                    Add New Question
                </Button>
            </div>
        )
    }
}

export default CreateJobApplicationQuestions
