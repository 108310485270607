import React from "react";
import SvgClose from "../icons/SvgClose";
export default function SelectedTag({ label, onClose }) {
    return (
        <div
            onClick={() => onClose(label)}
            className="px-2 py-1 md:px-2 md:py-1 bg-primary text-white whitespace-nowrap text-xs md:text-sm font-medium rounded-lg md:rounded-xl duration-150 flex hover:bg-red-100 hover:text-red-400 cursor-pointer flex-row items-center gap-1"
        >
            {label}
            <SvgClose className={"w-3 h-3 md:w-4 md:h-4"} />
        </div>
    );
}
