import React, { Component } from "react";
import { connect } from "react-redux";
import ClubMembersTab from "../../components/clubpage/tabs/ClubMembersTab";
import EditPositionModal from "../../components/atoms/modals/EditPositionModal";
import PaginationComponent from "../../components/atoms/PaginationComponent";
import WrappedTextInput from "../../components/atoms/WrappedTextInput";
import SvgSearch from "../../components/icons/SvgSearch";
import SvgAddCircle from "../../components/icons/SvgAddCircle";
import SelectComponent from "../../components/atoms/SelectComponent";
import SvgDownload from "../../components/icons/SvgDownload";
import DownloadResumesModal from "../modals/DownloadResumesModal";
import MemberInviteModal from "../../components/atoms/modals/MemberInviteModal";
import PageContentWrapper from "../../components/wrappers/PageContentWrapper";
import PageHeaderWrapper from "../../components/wrappers/PageHeaderWrapper"
import AnimatedTabs from "../../components/atoms/AnimatedTabs";
import AddClubMemberModal from "../../components/atoms/modals/AddClubMemberModal";
import { userClubSearch } from "../../utils/typesense";
import { ACTIVELY_LOOKING_OPTIONS } from "../../components/onboarding/onboarding-constants";
import { CLUB_ADMIN_MEMBERS_TABLE_HEADERS } from "../../utils/dummy";
import ClubStudentRequestListItem from "../ClubStudentRequestListItem";
import { getClubRequestsInfo } from "../../api/clubAdmin/clubs";

const START = 1;
const END = 20;

function mapStateToProps(state) {
  return {
    authUser: state.userReducer.authUser,
    user: state.userReducer.user,
    constants: state.userReducer.constants,
  };
}

class ClubAdminSearchPage extends Component {
  state = {
    members: [],
    page: 1,
    numMembers: 0,
    positionModalOpen: false,
    memberInviteModalOpen: false,
    addClubMemberModalOpen: false,
    selectedPerson: {},
    year: "",
    memberSearch: "",
    jobStatus: null,
    start: START,
    end: END,
    activeTab: "Members",
    requests: [],
  };

  componentDidMount = () => {
    this.refresh();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.club !== this.props.club) {
      this.refresh();
    }
  };

  refresh = async () => {
    this.memberSearch();

    if (this.props.club.private) {
      const res = await getClubRequestsInfo(this.props.authUser, this.props.club.id);
      this.setState({requests: res.requests});
    }
  }

  setBounds = (start, end) => {
    if (start > this.state.end) {
      this.memberSearch(
        this.state.memberSearch,
        this.state.year,
        this.state.jobStatus,
        this.state.page + 1,
        this.state.sortBy
      );
    } else if (this.state.members.length) {
      this.memberSearch(
        this.state.memberSearch,
        this.state.year,
        this.state.jobStatus,
        this.state.page - 1,
        this.state.sortBy
      );
    }
    this.setState({ start, end });
  };

  handleMemberSearchChange = (event) => {
    const text = event.target.value;
    this.setState({ memberSearch: text });

    this.memberSearch(
      text,
      this.state.year,
      this.state.jobStatus,
      this.state.page,
      this.state.sortBy
    );
  };

  memberSearch = (
    text = "",
    class_year = null,
    job_status = null,
    page = 1,
    sort_by = null
  ) => {
    let sb = null;
    if (!!sort_by) sb = sort_by === "GPA" ? "gpa:desc" : "grade:desc";
    userClubSearch(
      text,
      this.props.club.id,
      class_year,
      job_status,
      page,
      sb
    ).then((res) => {
      this.setState({
        members: res.results,
        loading: false,
        numMembers: res.total,
        page,
      });
    });
  };

  handleGradChange = (year) => {
    this.setState({ year });
    this.memberSearch(
      this.state.memberSearch,
      year,
      this.state.jobStatus,
      this.state.page,
      this.state.sortBy
    );
  };

  handleStatusChange = (jobStatus) => {
    this.setState({ jobStatus });
    this.memberSearch(
      this.state.memberSearch,
      this.state.year,
      jobStatus,
      this.state.page,
      this.state.sortBy
    );
  };

  handleSortByChange = (sortBy) => {
    this.setState({ sortBy });
    this.memberSearch(
      this.state.memberSearch,
      this.state.year,
      this.state.jobStatus,
      this.state.page,
      sortBy
    );
  };

  closePositionModal = () => {
    this.setState({ positionModalOpen: false });
  };

  openPositionModal = (selectedPerson) => {
    this.setState({ positionModalOpen: true, selectedPerson });
  };

  toggleAddClubMemberModal = () => {
    this.setState({
      addClubMemberModalOpen: !this.state.addClubMemberModalOpen,
    });
  };

  toggleMemberInviteModal = () => {
    this.setState({ memberInviteModalOpen: !this.state.memberInviteModalOpen });
  };

  changeTab = (tab) => {
    this.setState({activeTab: tab});
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("activeTab", tab);

    const params = `activeTab=${tab}`
    const newUrl = `${window.location.pathname}?${params}`;
    this.props.history.replace(newUrl);
};

  render() {
    const club = this.props.club || {};
    const {activeTab} = this.state;
    const leadership = club.leadership || {};
    const adminIds = this.props.user.admin.map((club) => club.id);
    const CLASS_YEARS = this.props.constants.class_years.map((tag) => ({
      title: tag,
      value: tag,
    }));
    return (
      <PageContentWrapper>
        {club.private &&
          <PageHeaderWrapper className={'pt-3 pb-0'}>
              <div className='relative -mb-2 -mt-2'>
                    <AnimatedTabs
                        activeTab={activeTab}
                        changeTab={this.changeTab}
                        xl
                        tabs={[
                            {title: 'Members', icon: SvgSearch},
                            {title: 'Requests', icon: SvgAddCircle},
                        ]}
                    />
                  
              </div>
          </PageHeaderWrapper>
        }
        <div
          className={`flex flex-col flex-1 p-5 gap-3 relative overflow-hidden`}
        >
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center justify-between">
              <p className="text-slate-800 my-1 text-2xl font-semibold">
                {club.name} {activeTab}
              </p>
            </div>
            {activeTab === "Members" &&
              <div className="body2-bold blacks-1 row-ac gap-6 mr-12">
                <DownloadResumesModal
                  clubId={club.id}
                  members={this.state.members}
                  memberName={this.state.memberSearch}
                  year={this.state.year}
                  status={this.state.jobStatus}
                >
                  <div className="row-ac bg-primary-light px-4 cursor-pointer py-1 rounded-full primary">
                    <SvgDownload className={"w-5 h-5"} />
                    <p className="ml-2">Generate Resume Booklet</p>
                  </div>
                </DownloadResumesModal>
                <div
                  onClick={this.toggleMemberInviteModal}
                  className="row-ac bg-primary-light px-4 cursor-pointer py-1 rounded-full primary"
                >
                  <SvgAddCircle className={"w-5 h-5"} />
                  <button className="m-0 ml-8">Invite Members</button>
                </div>
              </div>
            }
          </div>
          {activeTab === "Members" && 
              <>
                <div className="flex flex-row items-center w-full mb-4 gap-2">
                  <div className="flex flex-row items-center gap-2 flex-wrap">
                    <WrappedTextInput
                      type="text"
                      placeholder={"Search member name..."}
                      className="pl-44 input-light body2 w-[240px] lg:w-[330px]"
                      value={this.state.memberSearch}
                      onChange={this.handleMemberSearchChange}
                      icon={
                        <SvgSearch
                          width={24}
                          height={24}
                          className={"absolute base-black-50"}
                          style={{ left: 12, top: 10 }}
                        />
                      }
                    />
                  </div>
                  <div className="flex flex-row items-center gap-2 flex-wrap">
                    <SelectComponent
                      label={"Filter by class year..."}
                      className={"w-full"}
                      value={this.state.year}
                      light
                      setValue={this.handleGradChange}
                      clearFilter={() => this.handleGradChange(null)}
                      options={CLASS_YEARS}
                    />
                  </div>
                  <div className="flex flex-row items-center gap-2 flex-wrap">
                    <SelectComponent
                      label={"Filter by job status..."}
                      className={"w-full"}
                      value={this.state.jobStatus}
                      light
                      setValue={this.handleStatusChange}
                      clearFilter={() => this.handleStatusChange(null)}
                      options={ACTIVELY_LOOKING_OPTIONS}
                    />
                  </div>
                  {/* <div className='flex flex-row items-center gap-2 flex-wrap'>
                                <SelectComponent
                                    label={'Sort by...'}
                                    className={'w-full'}
                                    value={this.state.sortBy}
                                    light
                                    setValue={this.handleSortByChange}
                                    clearFilter={() => this.handleSortByChange(null)}
                                    options={[{title: "Class year"}, {title: "GPA"}]}
                                />
                            </div> */}
                </div>
                <div className="flex flex-col gap-3 flex-1 overflow-hidden">
                  <div className="flex flex-row items-center px-5">
                    {CLUB_ADMIN_MEMBERS_TABLE_HEADERS.map((item) => (
                      <div
                        style={{ flex: item.flex }}
                        className={`text-slate-500 text-xs ${!item.sm && "hidden lg:flex"}`}
                      >
                        {item.title}
                      </div>
                    ))}
                  </div>
                  <ClubMembersTab
                    loading={this.state.loading}
                    members={this.state.members}
                    club={club}
                    leadership={leadership}
                    authUser={this.props.authUser}
                    user={this.props.user}
                    adminIds={adminIds}
                    clubAdmin={true}
                    openPositionModal={this.openPositionModal}
                    getClubInfo={this.memberSearch}
                  />
                  <PaginationComponent
                    className={"px-5 bg-white"}
                    numResults={this.state.numMembers}
                    setBounds={this.setBounds}
                    pageLength={20}
                    loading={this.props.tableLoading}
                  />
                </div>
              </>
            }
            {activeTab === "Requests" && this.state.members.length &&
              <>
                {this.state.requests.length === 0 ?
                        <div
                            className='flex flex-col items-center justify-center py-32 body2 text-neutral-400'>
                            No active member requests
                        </div>
                    :
                    <div className="flex flex-col flex-1 overflow-scroll">
                      {this.state.requests.map(item => {
                            return <ClubStudentRequestListItem 
                              key={item.name} item={item}
                              authUser={this.props.authUser}
                              user={this.props.user}
                              refresh={this.refresh}
                              club={club}
                            />
                      })}
                    </div>
                }
              </>
            }
            <EditPositionModal
              isOpen={this.state.positionModalOpen}
              closeModal={this.closePositionModal}
              selectedPerson={this.state.selectedPerson}
              getClubInfo={this.props.getClubInfo}
              club={club}
            />
            <MemberInviteModal
              isOpen={this.state.memberInviteModalOpen}
              closeModal={this.toggleMemberInviteModal}
              club={club}
              adminIds={adminIds}
              toggleAddClubMemberModal={this.toggleAddClubMemberModal}
              changeTab={() => this.props.history.push("/clubadmin/settings")}
            />
            <AddClubMemberModal
              isOpen={this.state.addClubMemberModalOpen}
              closeModal={this.toggleAddClubMemberModal}
              club={club}
              getClubInfo={this.memberSearch}
            />
          </div>
        
      </PageContentWrapper>
    );
  }
}

export default connect(mapStateToProps)(ClubAdminSearchPage);
