import React, {Component} from "react";
import SvgChevronLeft from "../icons/SvgChevronLeft";
import {withRouter} from "react-router-dom";

class GoBack extends Component {
    render() {
        return (
            <div
                onClick={() => this.props.history.goBack()}
                className='ml-[-4px] flex flex-row items-center gap-1 text-slate-500 hover:text-primary cursor-pointer'>
                <SvgChevronLeft/>
                <p>
                    Back
                </p>
            </div>
        )
    }
}

export default withRouter(GoBack)
