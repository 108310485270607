import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="30px"
            height="30px"
            {...props}
            fill={'currentColor'}
        >
            <path d="M6 2a1 1 0 00-1 1v1H3c-.55 0-1 .45-1 1v1c0 .55.45 1 1 1h2v1a1 1 0 001 1h1v13H6a1 1 0 00-1 1v1H4a1 1 0 00-1 1v1a1 1 0 001 1h9a1 1 0 001-1v-1a1 1 0 00-1-1h-1v-1a1 1 0 00-1-1h-1V9h1a1 1 0 001-1V7h8v2h-3a1 1 0 000 2h8a1 1 0 000-2h-3V7h4c.55 0 1-.45 1-1V5c0-.55-.45-1-1-1H11.816A2.996 2.996 0 009 2H6zm1 2h2c.551 0 1 .449 1 1v2H7V4zm12 9a1 1 0 00-1 1v1h-1c-.55 0-1 .45-1 1v10a1 1 0 001 1h8a1 1 0 001-1v-8a1 1 0 00-1-1h-1v-3a1 1 0 00-2 0v3h-1l-1-2v-1a1 1 0 00-1-1zm-1 6h2v2h-2v-2zm4 0h2v2h-2v-2zm-4 4h2v2h-2v-2zm4 0h2v2h-2v-2z" />
        </svg>
    )
}

export default SvgComponent
