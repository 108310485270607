import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'}
                  d="M450-234h60v-129h130v-60H510v-130h-60v130H320v60h130v129zM220-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220zm331-554v-186H220v680h520v-494H551zM220-820v186-186 680-680z"/>
        </svg>
    )
}

export default SvgComponent
