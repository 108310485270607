import * as React from "react"
import {Slot} from "@radix-ui/react-slot"
import {cva} from "class-variance-authority"
import Spinner from "../Spinner";

const buttonVariants = cva(
    `inline-flex items-center justify-center text-sm font-bold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50`,
    {
        variants: {
            variant: {
                brand: "bg-brandCoral hover:bg-brandCoral/90 text-white",
                blue: "bg-indigo-500 hover:bg-indigo-500/90 text-white",
                green: "bg-emerald-500 hover:bg-emerald-500/90 text-white",
                blueLight: "bg-indigo-500/10 hover:bg-indigo-500/20 text-indigo-600",
                default: "bg-primary hover:bg-[#3d3dd1] text-white",
                white: "bg-white hover:bg-slate-100 text-primary",
                orange: 'bg-orange-500 hover:bg-orange-600 text-white',
                destructive:
                    "bg-red-500 hover:bg-red-600 text-white",
                outline:
                    "border border-input bg-background text-slate-500 hover:bg-accent hover:text-accent-foreground",
                secondary:
                    "bg-slate-200 text-slate-600 hover:bg-slate-300 hover:text-slate-800",
                secondaryOverlay: "bg-neutral-100/20 text-white hover:bg-neutral-200/40",
                ghost: "hover:bg-slate-200 text-slate-500 hover:text-slate-800",
                ghostOverlay: "text-white hover:bg-neutral-200/40",
                link: "text-primary hiver hover:underline",
            },
            size: {
                default: "h-10 px-4 py-2 rounded-lg",
                defaultRounded: "h-10 px-4 py-2 rounded-full pb-2.5",
                sm: "h-9 px-3 rounded-lg",
                xs: "h-7 px-2 rounded-lg",
                lg: "h-11 px-8 rounded-lg",
                icon: "h-10 w-10 rounded-full",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    }
)


const Button = React.forwardRef(
    ({className, variant, size, asChild = false, ...props}, ref) => {
        const Comp = asChild ? Slot : "button"
        if (props.loading) {
            return (
                <Comp
                    className={buttonVariants({variant, size, className})}
                    ref={ref}
                    {...props}
                >
                    <Spinner light={variant !== 'secondary' && variant !== 'ghost'} size={16}/>
                </Comp>
            )
        }
        return (
            <Comp
                className={
                    buttonVariants({
                        variant,
                        size,
                        className,
                    }) +
                    (props.disabled
                        ? ' opacity-50 pointer-events-none'
                        : '')
                }
                ref={ref}
                {...props}
            >
                <div className='flex flex-row items-center gap-1'>
                    {(props.icon && props.iconPosition === 'left') &&
                        <props.icon className={'w-4 h-4 mr-1'}/>
                    }
                    {props.children}
                    {(props.icon && props.iconPosition !== 'left') &&
                        <props.icon className={'w-5 h-5'}/>
                    }
                </div>
            </Comp>
        )
    }
)
Button.displayName = "Button"

export {Button, buttonVariants}
