import React, {PureComponent} from "react";
import {Link} from "react-router-dom";
import CompanyLogo from "../atoms/CompanyLogo";
import CompanyStatusTrackerComponent from "../atoms/CompanyStatusTrackerComponent";

class JobPageAboutCompanyWidget extends PureComponent {

    render() {
        const listing = this.props.listing || {};
        const company = listing.company || {};
        return (
            <div className='card mb-0'>
                <h3 className='mt-0 title2 text-neutral-900 mb-2'>
                    About the company
                </h3>
                <div className='row-ac-jb'>
                    <div className='row-ac gap-3'>
                        <Link className='row-ac gap-3'
                              to={{pathname: '/company/'+listing.company_id, state: {company_id: listing.company_id}}}>
                            <CompanyLogo id={listing.company_id} className={'w-12 h-12'}/>
                            <div className=''>
                                <p className='m-0 body1-bold'>
                                    {company.name}
                                </p>
                            </div>
                        </Link>
                        {!this.props.hideFollowButton &&
                            <div>
                                <CompanyStatusTrackerComponent companyId={company.id}/>
                            </div>
                        }                    
                    </div>
                    <div className='row-ac'>
                        <p className='m-0 base-black-70 body2 mr-24'>
                            {company.sector}
                        </p>
                        <p className='m-0 base-black-70 body2 mr-24'>
                            {company.num_employees} employees
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default JobPageAboutCompanyWidget
