import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 96 960 960"
            width={48}
            {...props}
        >
            <path
                fill={props.fill || 'currentColor'}
                d="M375 816l-43-43 198-198-198-198 43-43 241 241-241 241z" />
        </svg>
    )
}

export default SvgComponent
