import React, {Component} from "react";
import {page_h_padding_class} from "../../utils/constants";
import SvgSearch from "../icons/SvgSearch";
import WrappedTextInput from "../atoms/WrappedTextInput";
import SelectComponent from "../atoms/SelectComponent";
import SvgCheck from "../icons/SvgCheck";
import MultiSelectComponent from "../atoms/MultiSelectComponent";
import Checkbox from "../atoms/Checkbox";
import CollapseComponent from "../atoms/CollapseComponent";
import FadeInOnScroll from "../atoms/FadeInOnScroll";
import CompanyLogo from "../atoms/CompanyLogo";
import SvgSchool from "../icons/SvgSchool";
import {filteredCollegeSearch, searchColleges, searchCompanies} from "../../utils/typesense";
import ManualContactEntry from "../atoms/modals/ManualContactEntry";
import SvgSearchFeather from "../icons/SvgSearchFeather";


class PageLeftWrapper extends Component {
    state = {
        companySearch: '',
        companySearchResults: [],
        schoolSearch: '',
        schoolSearchResults: [],
    }

    handleSearchChange = (event) => {
        const text = event.target.value;
        if (!text) {
            this.setCompany({id: null, name: null})
        }

        this.setState({companySearch: text, openCollapse: true});

        this.companySearchChanged(text);
    }

    handleSchoolSearchChange = (event) => {
        const text = event.target.value;
        if (!text) {
            this.setSchool(null)
        }
        this.setState({schoolSearch: text, openSchoolCollapse: true});
        this.schoolSearchChanged(text);
    }

    companySearchChanged = async (text) => {
        const companySearchResults = await searchCompanies(text);
        this.setState({companySearchResults});
    }

    schoolSearchChanged = async (text) => {
        const schoolSearchResults = await filteredCollegeSearch(text);
        this.setState({schoolSearchResults})
    }

    setCompany = (result) => {
        this.setState({
            companySearchResults: [],
            companySearch: result.name
        })
        this.props.setCompany(result);
    }

    setSchool = (result) => {
        this.setState({
            schoolSearchResults: [],
            schoolSearch: result
        })
        this.props.setSchool(result);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.company?.name && !!this.props.company?.name) {
            this.setState({companySearch: this.props.company.name})
        }
    }


    render() {
        const companySearchResults = this.state.companySearchResults || [];
        return (
            <div
                className={`${page_h_padding_class} min-h-24 py-4 flex flex-row items-center justify-between ${this.props.className}`}>
                <div className='flex flex-col gap-2 flex-1'>
                    {this.props.nav}
                    {this.props.sub &&
                        <div className='flex flex-row items-center gap-1 text-slate-800/50'>
                            <p className='m-0 body2-bold font-semibold'>
                                {this.props.sub || ''}
                            </p>
                        </div>
                    }
                    {this.props.title &&
                        <div className='flex flex-row items-center justify-between'>
                            {this.props.title &&
                                <h1 className={`text-slate-800 heading4 font-semibold capitalize`}>
                                    <p>{this.props.title}</p>
                                </h1>
                            }
                            {this.props.actionButtonUpper}
                        </div>
                    }
                    {this.props.titleSecondary &&
                        <h1 className={`text-slate-800 body1-bold font-semibold`}>
                            <p>{this.props.titleSecondary}</p>
                        </h1>
                    }
                    <div className='flex flex-row items-center justify-between w-full'>
                        <div className='flex flex-row items-center gap-2 flex-wrap'>
                            {this.props.search &&
                                <WrappedTextInput
                                    type="text"
                                    placeholder={this.props.search.placeholder}
                                    className='pl-44 input-light w-[240px] lg:w-[240px]'
                                    value={this.props.search.value}
                                    onChange={this.props.search.onChange}
                                    icon={<SvgSearchFeather width={20} height={20} className={'absolute base-black-50'}
                                                     style={{left: 12, top: 10}}/>}
                                />
                            }
                            {!!this.props.setCompany &&
                                <div className='relative'>
                                    <WrappedTextInput
                                        type="text"
                                        placeholder={'Search by company...'}
                                        className='pl-44 input-light px-3 body2 w-full'
                                        value={this.state.companySearch}
                                        onChange={this.handleSearchChange}
                                        icon={<SvgSearchFeather width={20} height={20} className={'absolute base-black-50'}
                                                                style={{left: 12, top: 10}}/>}
                                    />
                                    <div className='absolute top-12 left-0 right-0 z-[99]'>
                                        <CollapseComponent
                                            isOpen={this.state.companySearch.length !== 0 && this.state.openCollapse}
                                            className='bg-white shadow-lg border border-neutral-200 rounded-lg'>
                                            {companySearchResults.map((res) =>
                                                <FadeInOnScroll triggerImmediately delay={10} key={res.id}>
                                                    <div
                                                        onClick={() => {
                                                            this.props.setCompany(res);
                                                            this.setState({
                                                                companySearch: res.name,
                                                                openCollapse: false
                                                            })
                                                        }}
                                                        className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2'>
                                                        <CompanyLogo id={res.id} className={'w-12 h-12'}/>
                                                        <div className='flex flex-col'>
                                                            <p className='text-sm text-slate-800 line-clamp-2'>
                                                                {res.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </FadeInOnScroll>
                                            )}
                                        </CollapseComponent>
                                    </div>
                                </div>
                            }
                            {!!this.props.setSchool &&
                                <div className='relative'>
                                    <WrappedTextInput
                                        type="text"
                                        placeholder={'Search by school...'}
                                        className='pl-44 input-light px-3 body2 w-full'
                                        value={this.state.schoolSearch}
                                        onChange={this.handleSchoolSearchChange}
                                        icon={<SvgSchool width={20} height={20}
                                                                  className={'absolute text-slate-500'}
                                                                  style={{left: 12, top: 10}}/>}
                                    />
                                    <div className='absolute top-12 left-0 right-0 z-[99]'>
                                        <CollapseComponent
                                            isOpen={this.state.schoolSearch.length !== 0 && this.state.openSchoolCollapse}
                                            className='bg-white shadow-lg border border-neutral-200 rounded-lg'>
                                            {this.state.schoolSearchResults.map((res) =>
                                                <div
                                                    onClick={() => {
                                                        this.props.setSchool(res.name);
                                                        this.setState({
                                                            schoolSearch: res.name,
                                                            openSchoolCollapse: false
                                                        })
                                                    }}
                                                    className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2'>
                                                    <div className='flex flex-col'>
                                                        <p className='text-sm text-slate-800 line-clamp-2'>
                                                            {res.name}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </CollapseComponent>
                                    </div>
                                </div>
                            }
                            {this.props.selectStatus &&
                                <SelectComponent
                                    light
                                    label={this.props.selectStatus.label}
                                    value={this.props.selectStatus.value}
                                    setValue={this.props.selectStatus.setValue}
                                    clearFilter={() => this.props.selectStatus.setValue(null)}
                                    options={this.props.selectStatus.options}
                                />
                            }
                            {this.props.selects && this.props.selects.map(select => {
                                if (!select.multiSelect) {
                                    return (
                                        <SelectComponent
                                            label={select.label}
                                            value={select.value}
                                            setValue={select.setValue}
                                            clearFilter={() => select.setValue(null)}
                                            options={select.options}
                                            light
                                        />
                                    )
                                } else return (
                                    <MultiSelectComponent
                                        label={select.label}
                                        value={select.value}
                                        setValue={select.setValue}
                                        clearFilter={() => select.setValue(null)}
                                        options={select.options}
                                        light
                                    />
                                )
                            })}
                            {this.props.checkbox &&
                                <Checkbox
                                    checked={this.props.checkbox.active}
                                    onClick={this.props.checkbox.onClick}
                                    label={this.props.checkbox.label}
                                />
                            }
                            {!!this.props.clearFilters && this.props.clearFilters}
                        </div>
                        {!!this.props.actionButton &&
                            <div className='flex flex-row'>
                                {this.props.actionButton}
                            </div>
                        }
                        {!!this.props.manualContact &&
                            <div className='flex flex-row justify-between flex-wrap gap-3 mb-3'>
                                <div className='flex flex-row items-center justify-center ml-auto mr-3'>
                                    <ManualContactEntry updateNetwork={this.props.updateNetwork} history={this.props.history}/>
                                </div>
                            </div>
                        }
                    </div>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default PageLeftWrapper
