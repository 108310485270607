import {PureComponent} from "react";

class SectionTabs extends PureComponent {
    render() {
        return (
            <div
                className={`flex flex-row items-center gap-6 border-b ${this.props.dark ? 'border-transparent' : ' border-slate-200'}`}>
                {this.props.tabs.map(tab => {
                        if (tab) {
                            return (
                                <div onClick={() => this.props.changeTab(tab)}
                                     key={tab}
                                     className={`cursor-pointer flex flex-col items-center border-b-2 ${this.props.xl && 'mr-3'} ${this.props.activeTab === tab ? 'primary' : 'text-neutral-500 hover:text-neutral-700'} ${this.props.activeTab === tab ? 'border-neutral-500' : 'border-white'} pb-2`}
                                     style={{borderColor: this.props.activeTab === tab && '#4848F2'}}
                                >
                                    <p className={this.props.xl ? 'text-lg font-semibold' : this.props.lg ? 'body1-bold' : 'body3-bold'}>
                                        {tab}
                                    </p>
                                </div>
                            )
                        }
                    }
                )}
            </div>
        )
    }
}

export default SectionTabs
