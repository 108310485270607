import { useEffect, useState } from "react";
import OnboardingStepHeader from "./OnboardingStepHeader";
import OnboardingStepWrapper from "./OnboardingStepWrapper";
import { HAS_JOB_OPTIONS } from "./onboarding-constants";
import OnboardingStepButtons from "./OnboardingStepButtons";
import { getClubInfo } from "../../api/clubAdmin/clubs";
import Spinner from "../../components/Spinner";
import SvgSchool from "../../components/icons/SvgSchool";
import CompanyLogo from "../atoms/CompanyLogo";
import TagComponent from "../atoms/TagComponent";
import { getCompany } from "../../api/student/companies";

function OnboardingHasJobStep({
  goNextStep,
  setHaveJob,
  goPreviousStep,
  showClubApplicationCta,
  clubId,
  companyId,
  showBackButton
}) {
  const [club, setClub] = useState(null);
  const [company, setCompany] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const select = (label) => {
    setHaveJob(label !== "I do not");
    goNextStep();
  };

  const fetchClubInfo = async (clubId) => {
    try {
      setIsLoading(true);
      const clubInfo = await getClubInfo(clubId);
      setClub(clubInfo.club);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCompanyInfo = async (companyId) => {
    if (!companyId) return;

    const res = await getCompany(companyId);
    setCompany(res.company);
  }

  useEffect(() => {
    if (showClubApplicationCta && clubId) {
      fetchClubInfo(clubId);
    }
  }, [clubId]);

  useEffect(() => {
    if (!!companyId) {
      fetchCompanyInfo(companyId);
    }
  }, [companyId]);

  if (isLoading || (!club && showClubApplicationCta)) {
    return (
      <OnboardingStepWrapper>
        <div className="flex justify-center items-center h-40">
          <Spinner size={48} />
        </div>
      </OnboardingStepWrapper>
    );
  }

  return (
    <OnboardingStepWrapper>
      {showClubApplicationCta && (
        <div className="w-full border-b border-slate-200 pb-28">
          <div className=" flex flex-row items-center justify-center">
            <div>
              <img
                src={
                  !!clubId
                    ? `https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/clubs%2F${clubId}?alt=media`
                    : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fcover_image%2FdefaultCompanyLogo.jpg?alt=media"
                }
                className={
                  "object-contain bg-slate-50 flex items-center justify-center h-24 w-24 mx-auto rounded-full"
                }
                alt="Club Logo"
              />
            </div>
            <div className="pl-24">
              <p className="text-slate-900 font-semibold text-2xl">
                {club.name}
              </p>
              <div className="mt-12">
                <div className="flex items-center">
                  <SvgSchool className={"w-5 h-5 text-slate-500"} />
                  <p className=" text-sm text-slate-500 ml-4">{club.college}</p>
                </div>
                {club.founded && (
                  <div className="">
                    <p className="text-slate-500 text-sm ml-24">
                      Founded {club.founded}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <p className="text-center text-slate-500 text-base mt-28">
            To continue with the application for <span>{club.name}</span> please
            continue with the signup process outlined below.
          </p>
        </div>
      )}
      {!!companyId && (
        <div className="w-full border-b border-slate-200 pb-28">
          <div className=" flex flex-row items-center justify-center">
            <div>
            <CompanyLogo id={companyId} className={'w-24 h-24 rounded mx-auto'}/>
            </div>
            <div className="pl-24">
              <p className="text-slate-900 font-semibold text-2xl">
                {company.name}
              </p>
              <div className="mt-12">
                {/* <div className="flex items-center">
                  <SvgSchool className={"w-5 h-5 text-slate-500"} />
                  <p className=" text-sm text-slate-500 ml-4">{club.college}</p>
                </div> */}
                {company.sector && (
                  <div className='flex flex-col items-start gap-2'>
                      <TagComponent
                          label={company.sector}
                      />
                  </div>
                )}
              </div>
            </div>
          </div>
          <p className="text-center text-slate-500 text-base mt-28">
            To RSVP to <span>{company.name}'s</span> event, please
            continue with the signup process outlined below.
          </p>
        </div>
      )}
      <OnboardingStepHeader
        title={"Do you have a job or internship?"}
        description={"Let us know if you already have an opportunity lined up"}
      />

      <div className="flex flex-col gap-5">
        {HAS_JOB_OPTIONS.map((item) => (
          <div
            key={item.label}
            onClick={() => select(item.label)}
            className="flex flex-row md:flex-col items-center gap-3 p-5 bg-slate-100 rounded-2xl hover:bg-slate-200 hover:text-primary cursor-pointer"
          >
            <item.Icon className="w-6 h-6 md:w-10 md:h-10" />
            <p>{item.label}</p>
          </div>
        ))}
        {showBackButton && (
          <OnboardingStepButtons
            goNextStep={goNextStep}
            goPreviousStep={goPreviousStep}
            hideNext
          />
        )}
      </div>
    </OnboardingStepWrapper>
  );
}

export default OnboardingHasJobStep;
