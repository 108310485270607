import CompanyLogo from "./atoms/CompanyLogo";
import {Link} from "react-router-dom";
import SvgChevronRight from "./icons/SvgChevronRight";
import React from "react";


function CompanyLabel({companyId, companyName, hideLogo}) {
    return (
        <>
            {companyId ?
                <div className='flex flex-row items-center gap-2 text-primary cursor-pointer'
                     style={{flex: .75}}>
                    {!hideLogo &&
                        <CompanyLogo id={companyId} className={'w-8 h-8'}/>
                    }
                    <Link to={{
                        pathname: '/company/' + companyId,
                        state: {company_id: companyId}
                    }}>
                        <div className='flex flex-row wrap items-center gap-0'>
                            <p className='body3-bold text-primary'>
                                {companyName > 15 ? companyName.slice(0, 15) + '...' : companyName}
                            </p>
                            <SvgChevronRight className={'w-5 h-5'}/>
                        </div>
                    </Link>
                </div>
                :
                <div className='row-ac' style={{flex: .75}}>
                    <p className='body3-bold text-slate-500'>
                        {companyName}
                    </p>
                </div>
            }
        </>
    )
}

export default CompanyLabel
