import * as React from "react"

function SvgComponent(props) {

    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7 20H4a1 1 0 01-1-1v-6a1 1 0 011-1h3a1 1 0 011 1v6a1 1 0 01-1 1z"
                fill="currentColor"
                fillOpacity={props.difficulty === 'Intermediate' || props.difficulty === 'Advanced' || props.difficulty === 'Basic' || props.difficulty === 'Easy' ? 1 : .2}
            />
            <path
                d="M14 20h-3a1 1 0 01-1-1V9a1 1 0 011-1h3a1 1 0 011 1v10a1 1 0 01-1 1z"
                fill="currentColor"
                fillOpacity={props.difficulty === 'Intermediate' || props.difficulty === 'Advanced' ? 1 : .2}
            />
            <path
                d="M21 20h-3a1 1 0 01-1-1V5a1 1 0 011-1h3a1 1 0 011 1v14a1 1 0 01-1 1z"
                fill="currentColor"
                fillOpacity={props.difficulty === 'Advanced' ? 1 : .2}
            />
        </svg>
    )
}

export default SvgComponent
