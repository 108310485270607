import React, {Component} from "react";
import {connect} from "react-redux";
import {getClub, getMembers} from "../api/recruiter/clubs";
import {getClubStatistics} from "../api/recruiter/statistics";
import ClubPageAboutHeader from "../components/clubpage/ClubPageAboutHeader";
import GoBack from "../components/atoms/GoBack";
import ClubPageMissionWidget from "../components/clubpage/ClubPageMissionWidget";
import ClubPageAboutWidget from "../components/clubpage/ClubPageAboutWidget";
import ClubPageRecentPlacements from "../components/clubpage/ClubPageRecentPlacements";
import ClubPageLifeAtSection from "../components/clubpage/ClubPageLifeAtSection";
import ClubPageStepsToApplyWidget from "../components/clubpage/ClubPageStepsToApplyWidget";
import ClubPageSponsorsWidget from "../components/clubpage/ClubPageSponsorsWidget";
import PointsOfContactWidget from "../components/clubpage/PointsOfContactWidget";
import AnimatedTabs from "../components/atoms/AnimatedTabs";
import SvgInfo from "../components/icons/SvgInfo";
import SvgUsers from "../components/icons/SvgUsers";
import ClubMembersTab from "../components/clubpage/tabs/ClubMembersTab";
import RecruiterPaywall from "./atoms/RecruiterPaywall";
import {Button} from "../components/atoms/Button";
import EditListModal from "./modals/EditListModal";
import SelectClubListModal from "./modals/SelectClubListModal";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        tier: state.userReducer.tier
    };
}


function mapDispatchToProps(dispatch) {
    return {
        setCandidates: (candidates) => {
            dispatch({
                type: "SET_CANDIDATES",
                candidates,
            })
        },
    }
}


class RecruiterClubPage extends Component {
    state = {
        club: {},
        statistics: {},
        loading: true,
        clubId: null,
        activeTab: 'About',
        isEditClubListModalOpen: false,
        isSelectClubListModalOpen: false
    }

    componentDidMount = () => {
        this.getClubInfo();
        this.getMembers()
    }

    getClubInfo = async () => {
        this.setState({loading: true});
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const clubId = lastArg.split("?")[0];

        if (this.props.tier !== "premium") return;

        // const clubId = this.props.match.params.clubId || this.props.location.state?.club_id || "bg6B7ReNaY4AIFKlpgnV";
        const result = await getClub(this.props.authUser, clubId);
        let statistics = await getClubStatistics(this.props.authUser, clubId);
        this.setState({
            club: result?.club,
            statistics: statistics,
            clubId: clubId,
            loading: false,
        });

    }

    getMembers = async (index = "0", start_after = true) => {
        this.setState({tableLoading: true});
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const clubId = lastArg.split("?")[0];
        const response = await getMembers(this.props.authUser, clubId, index, start_after);

        const members = start_after ? response?.members : response?.members.reverse();

        this.props.setCandidates(members)
        this.setState({members, tableLoading: false});
        if (index === "0" && start_after && !!response?.num_members) this.setState({numMembers: response.num_members})
    }


    changeTab = (activeTab) => {
        this.setState({activeTab})
    }

    render() {
        const {club, loading} = this.state;
        const hidden = club.hidden || [];
        const showPaywall = this.props.tiers !== 'premium';
        return (
            <div className={`px-5 pt-5 pb-5`}>
                <GoBack/>
                <div className='flex flex-row justify-between gap-3'>
                    <ClubPageAboutHeader
                        club={club}
                        loading={loading}
                        adminIds={[]}
                        getClubInfo={this.getClubInfo}
                        numMembers={this.props.numMembers}
                    />
                    <div
                        className='bg-white shadow-lg gap-2 justify-center flex flex-col items-start shadow-slate-200 p-5 mt-5 border border-slate-200 rounded-xl'>
                        <Button onClick={() => this.setState({isSelectClubListModalOpen: true})} className='w-full'>
                            Add to list
                        </Button>
                        <Button onClick={() => this.setState({isEditClubListModalOpen: true})}>
                            Create new list
                        </Button>
                    </div>
                </div>
                <div className='mt-5'>
                    <AnimatedTabs
                        activeTab={this.state.activeTab}
                        changeTab={this.changeTab}
                        tabs={[
                            {title: 'About', icon: SvgInfo},
                            {title: 'Members', icon: SvgUsers},
                        ]}
                    />
                </div>
                {this.state.activeTab === 'About' &&
                    <>
                        {!hidden.includes("mission") &&
                            <ClubPageMissionWidget club={club} loading={loading} getClubInfo={this.getClubInfo}
                                                   adminIds={[]}/>}
                        {!hidden.includes("description") &&
                            <ClubPageAboutWidget club={club} loading={loading} getClubInfo={this.getClubInfo}
                                                 adminIds={[]}/>}
                        {!hidden.includes("recent_placements") &&
                            <ClubPageRecentPlacements club={club} loading={loading} getClubInfo={this.getClubInfo}
                                                      isAdmin={false}
                                                      openRecentPlacementModal={this.props.openRecentPlacementModal}/>}
                        {!hidden.includes("images") &&
                            <ClubPageLifeAtSection club={club} loading={loading} getClubInfo={this.getClubInfo}
                                                   isAdmin={false}/>}
                        {!hidden.includes("application_process") &&
                            <ClubPageStepsToApplyWidget club={club} loading={loading} getClubInfo={this.getClubInfo}
                                                        adminIds={[]}/>}
                        {!hidden.includes("sponsors") &&
                            <ClubPageSponsorsWidget club={club} loading={loading} getClubInfo={this.getClubInfo}
                                                    adminIds={[]}/>}
                        {!hidden.includes("points_of_contact") &&
                            <PointsOfContactWidget club={club} loading={loading} getClubInfo={this.getClubInfo}
                                                   isAdmin={false}/>}
                    </>
                }
                {this.state.activeTab === 'Members' &&
                    <>
                        {!showPaywall ?
                            <div className='relative mt-5'>
                                <RecruiterPaywall title={'See club members'}/>
                            </div>
                            :
                            <div className='w-full mt-4'>
                                <ClubMembersTab
                                    loading={this.props.loading}
                                    members={this.state.members}
                                    club={club}
                                    leadership={this.props.leadership}
                                    authUser={this.props.authUser}
                                    user={this.props.user}
                                    recruiter={true}
                                    adminIds={[]}
                                    openPositionModal={this.openPositionModal}
                                    getClubInfo={this.getClubInfo}/>
                            </div>
                        }
                    </>
                }
                <EditListModal
                    isOpen={this.state.isEditClubListModalOpen}
                    isNewList
                    type={"club"}
                    getLists={() => {}}
                    club={club}
                    closeModal={() => this.setState({isEditClubListModalOpen: false})}
                />
                <SelectClubListModal
                    items={[club]}
                    type={"club"}
                    isOpen={this.state.isSelectClubListModalOpen}
                    authUser={this.props.authUser}
                    closeModal={() => this.setState({isSelectClubListModalOpen: false})}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterClubPage);
