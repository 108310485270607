import {PureComponent} from "react";

class VerticalTabs extends PureComponent {
    render() {
        return (
            <div className='flex flex-col gap-5'>
                {this.props.tabs.map((tab, index) =>
                    <div key={`verticalTab${index}`} onClick={() => this.props.changeTab(tab)}
                         className={`
                         cursor-pointer flex flex-col w-full
                         ${this.props.activeTab === tab ? 'text-primary font-semibold bg-slate-200 px-3 py-1 rounded-lg' : ' font-semibold text-neutral-500 hover:text-neutral-700 hover:bg-slate-100 px-3 py-1 rounded-lg'} 
                         `
                         }
                    >
                        <p className='text-3-500'>
                            {tab}
                        </p>
                    </div>
                )}
            </div>
        )
    }
}

export default VerticalTabs
