import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'} d="M180-180h44l443-443-44-44-443 443v44zm614-486L666-794l84-84 128 128-84 84zM120-120v-128l504-504 128 128-504 504H120zm525-525l-22-22 44 44-22-22z" />
        </svg>
    )
}

export default SvgComponent
