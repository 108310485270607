import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'} d="M633-80L472-241l43-43 118 118 244-244 43 43L633-80zM478-527l334-213H144l334 213zm0 60L140-684v452h256l60 60H80v-628h797v304l-60 60v-248L478-467zm1 9zm-1-69zm1 60z" />
        </svg>
    )
}

export default SvgComponent
