import React, {PureComponent} from "react";
import TextBlockShimmer from "../loading/TextBlockShimmer";
import ClubPageCompanyCard from "./ClubPageCompanyCard";
import {editClub} from "../../api/student/clubs";
import SvgPlus from "../icons/SvgPlus";
import SvgVisibility from "../icons/SvgVisibility";
import SvgEyeOff from "../icons/SvgEyeOff";
import Tooltip from "../atoms/modals/Tooltip";
import {connect} from "react-redux";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

class ClubPageRecentPlacements extends PureComponent {
    render() {
        const club = this.props.club || {};
        const hidden = this.props.hidden || [];
        const recentPlacements = club.recent_placements || [];
        return (
            <div
                className={`bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl mt-5 ${hidden.includes("recent_placements") && 'opacity-50'}`}>
                <div className='flex flex-row items-center gap-2 mb-3'>
                    <p className='text-xl font-semibold text-slate-800'>
                        Recent Placements
                    </p>
                    {!this.props.loading && this.props.isAdmin &&
                        <>
                            <button
                                onClick={this.props.openRecentPlacementModal}
                                className='text-slate-500 hover:text-primary cursor-pointer'>
                                <SvgPlus className={'w-4 h-4'}/>
                            </button>
                            <Tooltip
                                toggleComponent={
                                    <button
                                        onClick={() => this.props.hide("recent_placements")}
                                        className='text-slate-500 hover:text-primary cursor-pointer'>
                                        {hidden.includes("recent_placements") ?
                                            <SvgVisibility className={'w-4 h-4'}/>
                                            :
                                            <SvgEyeOff className={'w-4 h-4'}/>
                                        }
                                    </button>
                                }
                                label={`${hidden.includes("recent_placements") ? "Show" : "Hide"} this section when recruiters are viewing your club.`}
                            />
                        </>
                    }
                </div>
                <p className='text-slate-500 text-md mb-5'>
                    A glance at member placements from the last 2 years.
                </p>
                <div className='grid grid-cols-2 lg:grid-cols-3 gap-4'>
                    {this.props.loading ?
                        <>
                            <TextBlockShimmer/>
                        </>
                        :
                        (recentPlacements?.length === 0 ?
                                <p className='body2 base-black-70'>
                                    No recent placements have been added yet.
                                </p>
                                :
                                <>
                                    {recentPlacements?.map((item, index) =>
                                        <ClubPageCompanyCard key={item.company_id} item={item}
                                                             getClubInfo={this.props.getClubInfo} club={club}/>
                                    )}
                                </>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(ClubPageRecentPlacements)
