import React, {PureComponent} from "react";
import DiscoverPersonItem from "../discover/DiscoverPersonItem";
import {connect} from "react-redux";
import SvgEmpty from "../icons/SvgEmpty";
import CompanyStatusTrackerComponent from "../atoms/CompanyStatusTrackerComponent";
import ScrapedNetworkingPeek from "../networkingpage/ScrapedNetworkingPeek";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        applications: state.userReducer.applications,
        user: state.userReducer.user,
        networks: state.userReducer.networks
    };
}


function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
        updateNetwork: (network) => {
            dispatch({
                type: "UPDATE_NETWORK",
                network,
            })
        },
        deleteNetwork: (personId) => {
            dispatch({
                type: "DELETE_NETWORK",
                personId,
            })
        }
    }
}

class CompanyPagePeople extends PureComponent {
    state = {
        networkingPeekOpen: false,
        selectedPerson: {},
        selectedPersonId: "",
    }
    openEventPage = (selectedEvent) => {
        this.props.history.push(`/event/${selectedEvent.id}`);
    }

    openPeek = (personId, person) => {
        this.setState({selectedPersonId: personId,  selectedPerson:person,  networkingPeekOpen: true})
    }

    render() {
        const company = this.props.company || {};
        return (
            <>
            {this.props.people.length === 0 ?
                <div
                    className='flex flex-col items-center justify-center text-slate-500 text-sm gap-3 flex-1 mt-10'>
                    <SvgEmpty/>
                    <p className='text-lg text-slate-800 font-semibold'>
                        No people listed for this company.
                    </p>
                    <div>
                        <CompanyStatusTrackerComponent companyId={company.id}/>
                    </div>
                </div>
                :
                <div className='flex flex-col gap-3'>
                    {this.props.people.map((item, index) =>
                        <DiscoverPersonItem item={item} list={true} openPeek={this.openPeek}/>
                    )}
                </div>
            }
            
            <ScrapedNetworkingPeek
                    open={this.state.networkingPeekOpen}
                    network={this.props.networks[this.state.selectedPersonId]}
                    isSaved={(this.state.selectedPersonId in this.props.networks)}
                    person={this.state.selectedPerson}
                    close={() => this.setState({networkingPeekOpen: false})}
                    deleteNetwork={this.props.deleteNetwork}
                    updateNetwork={this.props.updateNetwork}
                />
        </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPagePeople);
